import { Button, Card, Col, Flex, Form, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Endorsement } from "@arbolus-technologies/api";

import { EndorsementCard } from "./EndorsementCard";
import { useRequestEndorsement } from "./useRequestEndorsement";

import styles from "./Endorsements.module.scss";

type EndorsementsForm = {
  fullName?: string;
  email?: string;
};

const { Title } = Typography;

export function Endorsements({
  expertId,
  endorsements
}: { expertId: string; endorsements: Endorsement[] }) {
  const { t } = useTranslation("endorsements");
  const [formValid, setFormValid] = useState(false);
  const { inProgress, requestEndorsement } = useRequestEndorsement(expertId);

  const handleFinish = (values: EndorsementsForm) =>
    requestEndorsement(values.fullName!, values.email!);

  return (
    <Flex className={styles.endorsements} vertical gap={32}>
      <Card>
        <Title level={5}>{t("title")}</Title>
        <p>{t("subtitle")}</p>
        <p>{t("description")}</p>

        <Form
          layout="vertical"
          onFieldsChange={(_, allFields) => {
            setFormValid(
              allFields.every(
                (field) => field.errors?.length === 0 && field.touched
              )
            );
          }}
          onFinish={handleFinish}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item<EndorsementsForm>
                label={t("nameInput")}
                name="fullName"
                rules={[{ required: true }]}
              >
                <Input placeholder={t("nameInputPlaceholder")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<EndorsementsForm>
                label={t("emailInput")}
                name="email"
                rules={[{ required: true }, { type: "email" }]}
              >
                <Input placeholder={t("emailInputPlaceholder")} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={inProgress || !formValid}
            >
              {t("submitButton")}
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {endorsements.map((endorsement) => (
        <EndorsementCard key={endorsement.created} endorsement={endorsement} />
      ))}
    </Flex>
  );
}
