import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { ExpertsListPageTab } from "@arbolus-technologies/api";
import { ScheduleProjectData } from "@arbolus-technologies/models/project";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectExpertsSelector,
  ProjectExpertsStoreActions
} from "@arbolus-technologies/stores/project-experts-store";

import { ExpertListTabs } from "./ExpertsListTabs";
import {
  ADMIN_ONLY_TABS,
  ADMIN_TABS,
  CLIENT_ONLY_TABS,
  CLIENT_TABS,
  TABS,
  TabsWithPage
} from "./tabDefinitions";

interface LocationState {
  referralState?: ExpertsListPageTab;
  reviewExperts?: string;
}

interface LinkLocationState {
  expertId: string;
  referralId: string;
  sortStatus: string;
}

interface ExpertsListPageBodyProps {
  project: ScheduleProjectData;
  expertProfilePath?: (expertId: string) => string;
  handleClickRow: (expertId: string, referralId: string) => void;
  handleSlidePanelExpertProfile?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
  handleEditTagline?: (
    referralId: string,
    projectId: string,
    tagline: string,
    expertTitle: string
  ) => void;
  handleSlidePanelAvailability?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
  onReviewNow?: () => void;
}

export const ExpertsListPageBody: React.FC<ExpertsListPageBodyProps> = ({
  project,
  handleClickRow,
  expertProfilePath,
  handleSlidePanelExpertProfile,
  handleEditTagline,
  handleSlidePanelAvailability,
  onReviewNow
}) => {
  const dispatch = useDispatch();
  const location = useLocation<LinkLocationState>();
  const history = useHistory();
  // Location state can be undefined if it does not come from dashboard redirection
  const { expertId, referralId, sortStatus } = location.state || {};

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const summaryList = useSelector(ProjectExpertsSelector.summaryList());

  const { referralState, reviewExperts }: LocationState = queryString.parse(
    window.location.search
  );

  const [activeTab, setActiveTab] = useState(
    referralState === ExpertsListPageTab.Review
      ? ExpertsListPageTab.AllExperts
      : referralState ?? ExpertsListPageTab.Book
  );

  const tabs = isAdmin ? ADMIN_TABS : CLIENT_TABS;
  const showReviewNow = !isAdmin && onReviewNow && !!summaryList?.review;

  const loadTableData = useCallback(
    (activeTab: ExpertsListPageTab) => {
      if (activeTab === ExpertsListPageTab.Book) {
        return;
      } else if (activeTab === ExpertsListPageTab.Recommendations) {
        dispatch(ProjectExpertsStoreActions.getRecommendations(project.id));
      } else {
        dispatch(
          ProjectExpertsStoreActions.getAllExperts(project.id, activeTab)
        );
      }
    },
    [dispatch, project.id]
  );

  useEffect(() => {
    dispatch(ProjectExpertsStoreActions.setExpertListTab(activeTab));
    loadTableData(activeTab);
  }, [activeTab, dispatch, loadTableData]);

  useEffect(() => {
    if (showReviewNow && reviewExperts === "true") {
      onReviewNow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewExperts]);

  useEffect(() => {
    dispatch(ProjectExpertsStoreActions.getProjectSummary(project.id));
  }, [dispatch, project.id]);

  useEffect(() => {
    if (expertId && referralId) {
      // Open side panel on availability tab. By default is profile tab.
      handleClickRow(expertId, referralId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertId && referralId]);

  const handleChangeTab = (tab: TabsWithPage) => {
    window.history.replaceState(
      null,
      "",
      `project/${project.id}/experts?referralState=${tab}`
    );
    setActiveTab(tab);
  };

  // Protect from accessing admin tabs by client
  if (
    (!isAdmin && ADMIN_ONLY_TABS.includes(activeTab)) ||
    (isAdmin && CLIENT_ONLY_TABS.includes(activeTab))
  ) {
    history.replace(PROJECT_ROUTE(project.id));
    return null;
  }

  const { Component } = TABS[activeTab];

  return (
    <>
      <ExpertListTabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={handleChangeTab}
        projectId={project.id}
        summaryList={summaryList}
        showReviewNow={showReviewNow}
      />
      {Component && (
        <Component
          project={project}
          handleClickRow={handleClickRow}
          hasCompliance={project.hasCompliance || project.isBain}
          expertProfilePath={expertProfilePath}
          handleSlidePanelExpertProfile={handleSlidePanelExpertProfile}
          handleEditTagline={handleEditTagline}
          handleSlidePanelAvailability={handleSlidePanelAvailability}
          sortStatus={sortStatus}
          projectId={project.id}
        />
      )}
    </>
  );
};
