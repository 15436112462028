import React from "react";
import { useTranslation } from "react-i18next";

import { PanelId } from "@arbolus-technologies/stores/panels";

import { SlidePanel } from "../../Panels/SlidePanel/SlidePanel";
import { RejectFeedback } from "./RejectFeedback";

export const RejectFeedbackPanel: React.FC = () => {
  const { t } = useTranslation("rejectExpert");

  return (
    <SlidePanel panelId={PanelId.RejectFeedback} title={t("feedback")}>
      <RejectFeedback />
    </SlidePanel>
  );
};
