import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { useExternalSourcing } from "../../Contexts/ExternalSourcingContext/ExternalSourcingContext";
import { ExternalExpertDetails } from "./ExternalExpertDetails/ExternalExpertDetails";

export const ExternalExpertSlidePanel = () => {
  const { t } = useTranslation("externalExpertSlidePanel");
  const dispatch = useDispatch();
  const { fetchExpertList, fetchStats, currentPage } = useExternalSourcing();

  const [isUpdated, setIsUpdated] = useState(false);

  const handleSlidePanelClose = (isShortlisted?: boolean) => {
    if (isShortlisted || isUpdated) {
      fetchExpertList(currentPage - 1);
      fetchStats();
    }
    dispatch(PanelStoreActions.closePanel(PanelId.ExternalExpert));
  };

  return (
    <SlidePanel
      panelId={PanelId.ExternalExpert}
      width={SIDE_PANEL_SIZE._720}
      title={t("title")}
      closeButtonDirection="right"
      customCloseRequest={handleSlidePanelClose}
    >
      <ExternalExpertDetails
        handleUpdated={(isUpdated: boolean) => setIsUpdated(isUpdated)}
        onSlidePanelClose={handleSlidePanelClose}
      />
    </SlidePanel>
  );
};
