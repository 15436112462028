import { Col, Row } from "antd";
import { useFeature } from "flagged";
import queryString from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { ProjectExpertManagementWrapper } from "@arbolus-technologies/features/common";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ExpertContainer } from "../../../Components/Discover/ExpertContainer/ExpertContainer";
import { ExpertList } from "../../../Components/Discover/ExpertList/ExpertList";
import { DncFilter } from "../../../Components/Discover/Filters/DncFilter/DncFilter";
import { SearchFilter } from "../../../Components/SearchFilter/SearchFilter";
import { useDiscoverExperts } from "../../../Hooks/Discover/useDiscoverExperts";
import { DiscoverFilters } from "../../../Modules/DiscoverFilters/DiscoverFilters";

import styles from "./DiscoverDesktopLayout.module.scss";

interface DiscoverDesktopLayoutProps {
  projectId: string;
  projectName: string;
}

export const DiscoverDesktopLayout: React.FC<DiscoverDesktopLayoutProps> = ({
  projectId,
  projectName
}) => {
  const location = useLocation();
  const newFindExpertCardFF = useFeature(FEATURE_FLAGS.NewFindExpertCard);

  const parameters = queryString.parse(location.search);
  const { query = "" }: { query?: string } = parameters;

  const { handleSearchTerm } = useDiscoverExperts({ projectId });

  const isAdmin = useSelector(CacheSelector.isAdmin());

  return (
    <ProjectExpertManagementWrapper>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <div className={styles.search}>
            <SearchFilter
              initialValue={isAdmin ? query : ""}
              onSearchTerm={handleSearchTerm}
            />
          </div>
          <DiscoverFilters />
          {isAdmin && (
            <div className={styles.hideExperts}>
              <DncFilter />
            </div>
          )}
        </Col>
        <Col span={18}>
          {isAdmin && newFindExpertCardFF ? (
            <ExpertContainer projectId={projectId} projectName={projectName} />
          ) : (
            <ExpertList projectId={projectId} projectName={projectName} />
          )}
        </Col>
      </Row>
    </ProjectExpertManagementWrapper>
  );
};
