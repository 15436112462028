import { xor } from "lodash";
import queryString from "query-string";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  DiscoverFilterOption,
  DiscoverFilterType
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { utilService } from "@arbolus-technologies/utils";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { useDiscoverFilters } from "./useDiscoverFilters";

export interface UseDiscoverKeywordFilter {
  handleSetKeywordsSearchOptions: (keywordSearchOption: string) => void;
  handleClearQueryClicked: () => void;
  handleKeyDown: (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => void;
  handleInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  searchQuery: string;
  urlChangeOnKeywordFilterUpdate: (options: DiscoverFilterOption[]) => void;
  handleClearFilter: () => void;
  handleKeywordEdit: () => void;
}

const MIN_KEYWORD_LENGTH = 2;

export const useDiscoverKeywordFilter = (): UseDiscoverKeywordFilter => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trackFiltered } = useArbolusTracking();

  const parameters = queryString.parse(location.search);
  const { keywords }: { keywords?: string } = parameters;

  const { setSelectedOptions, clearFilter, selectedOptions } =
    useDiscoverFilters({
      filterType: DiscoverFilterType.Keywords
    });

  const [searchQuery, setSearchQuery] = useState<string>("");

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());
  const keywordsSearchOptionsSelected = useSelector(
    ProjectNxSelector.keywordsSearchOptions()
  );

  const updateURLWithKeywordsOptions = (
    updatedKeywordsSearchOptions: string[]
  ) => {
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      const keywordsOptions = updatedKeywordsSearchOptions.join(",");
      if (keywordsOptions) {
        nonEmptyParams["keywordsOptions"] = keywordsOptions;
      } else {
        delete nonEmptyParams["keywordsOptions"];
      }

      if (!keywords) {
        delete nonEmptyParams["keywordsOptions"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleSetKeywordsSearchOptions = (keywordSearchOption: string) => {
    const updatedKeywordsSearchOptions = xor(keywordsSearchOptionsSelected, [
      keywordSearchOption
    ]);

    dispatch(
      ProjectNxStoreActions.setKeywordsSearchOptions(
        updatedKeywordsSearchOptions
      )
    );
    updateURLWithKeywordsOptions(updatedKeywordsSearchOptions);

    if (selectedOptions.length > 0) {
      trackFiltered(MixPanelEventNames.InternalSearchKeywordSearch, {
        keywordsSearchOptionsSelected: updatedKeywordsSearchOptions,
        Project: projectName,
        Client: projectClientName
      });
    }
  };

  const handleClearQueryClicked = () => {
    setSearchQuery("");
  };

  const handleKeyDown = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    const sanitizedSearchQuery = searchQuery.trim();
    if (
      event.key === "Enter" &&
      sanitizedSearchQuery.length >= MIN_KEYWORD_LENGTH
    ) {
      // Remove symbols and extra spaces from the searchQuery
      const keywordSelector: DiscoverFilterOption = {
        value: sanitizedSearchQuery,
        label: sanitizedSearchQuery
      };
      setSelectedOptions([keywordSelector]);
      urlChangeOnKeywordFilterUpdate([keywordSelector]);
      trackFiltered(MixPanelEventNames.InternalSearchKeywordSearch, {
        keywordsSearchOptionsSelected,
        Project: projectName,
        Client: projectClientName
      });
      setSearchQuery("");
    }
  };

  const handleInputChangeEvent = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
  };

  const handleKeywordEdit = () => {
    const selectedKeyword = selectedOptions[0].value;
    setSelectedOptions([]);
    urlChangeOnKeywordFilterUpdate([]);
    setSearchQuery(selectedKeyword);
  };

  const urlChangeOnKeywordFilterUpdate = (options: DiscoverFilterOption[]) => {
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      const keywordsOptions = keywordsSearchOptionsSelected.join(",");

      if (options.length > 0) {
        nonEmptyParams["keywords"] = options[0].value;
        nonEmptyParams["keywordsOptions"] = keywordsOptions;
      } else {
        delete nonEmptyParams["keywords"];
        delete nonEmptyParams["keywordsOptions"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleClearFilter = () => {
    clearFilter();
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      delete nonEmptyParams["keywords"];
      delete nonEmptyParams["keywordsOptions"];
      const params = new URLSearchParams(nonEmptyParams);

      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return {
    handleSetKeywordsSearchOptions,
    handleClearQueryClicked,
    handleKeyDown,
    handleInputChangeEvent,
    searchQuery,
    urlChangeOnKeywordFilterUpdate,
    handleClearFilter,
    handleKeywordEdit
  };
};
