import { Button, Col, Form, Input, Result, Row, Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { DefaultToasterService, UserService } from "@arbolus-technologies/api";
import {
  Loader,
  PhoneValue,
  SimplePhoneInput
} from "@arbolus-technologies/ui/components";
import { getQueryParams } from "@arbolus-technologies/utils";

import { useRegister } from "../../hooks/useRegister";
import { AuthPageBase } from "../AuthPageBase/AuthPageBase";
import { AuthPageHeader } from "../AuthPageHeader/AuthPageHeader";

import styles from "./CreateAccountWithLinkedIn.module.scss";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: PhoneValue;
  linkedinUrl: string;
}

export const CreateAccountWithLinkedIn: React.FC = () => {
  const { t } = useTranslation("register");
  const queryParams = getQueryParams();
  const { email, firstName, lastName, linkedinUrl, redirect } = queryParams;
  const registrationData = useRegister({ email });
  const [form] = Form.useForm<FormValues>();

  function handleSubmit() {
    form.validateFields().then(async (values) => {
      // Destructure to ignore immutable fields
      const { linkedinUrl, email, ...filteredValues } = values;
      try {
        await UserService.linkedinRegister({
          userId: queryParams.id,
          // The terms were accepted BEFORE LinkedIn redirect (and arriving here)
          termId: registrationData?.terms.id!,
          ...filteredValues,
          phoneNumber: values.phoneNumber.prefix + values.phoneNumber.number
        });
        DefaultToasterService.showSuccessFixed(t("accountCreated"));
        setTimeout(() => {
          window.location.assign(redirect ? redirect : "/");
        }, 2000);
      } catch (error) {
        console.error(error);
        DefaultToasterService.showErrorFixed(t("registrationFailure"));
      }
    });
  }

  if (!registrationData) return <Loader />;

  if (queryParams.failed) {
    return (
      <AuthPageBase>
        <Result
          status="error"
          title={t("genericError")}
          subTitle={t("registrationFailure")}
        />
      </AuthPageBase>
    );
  }

  return (
    <AuthPageBase>
      <div className={styles.createAccount}>
        <AuthPageHeader
          title={t("registerTitle")}
          subtitle={t("registerSubtitle")}
        />

        <Form
          form={form}
          initialValues={{
            email,
            firstName,
            lastName,
            linkedinUrl,
            phoneNumber: { prefix: "+1", number: "" }
          }}
        >
          <Form.Item<{ email: string }> name="email">
            <Input
              placeholder={t("email")}
              prefix={<AntDIcon name="mail" inline />}
              disabled
            />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item<{ firstName: string }>
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t("firstNameRequiredError")
                  }
                ]}
              >
                <Input
                  placeholder={t("firstName")}
                  prefix={<AntDIcon name="person" inline />}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<{ lastName: string }>
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t("lastNameRequiredError")
                  }
                ]}
              >
                <Input
                  placeholder={t("lastName")}
                  prefix={<AntDIcon name="person" inline />}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item<{ linkedinUrl: string }>
            name="linkedinUrl"
            rules={[{ required: true, message: t("linkedinUrlRequired") }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item<{ phoneNumber: PhoneValue }>
            name="phoneNumber"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value?.number) {
                    return Promise.reject(t("phoneNumberInvalidError"));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <SimplePhoneInput
              value={form.getFieldValue("phoneNumber")}
              onChange={(value) => form.setFieldValue("phoneNumber", value)}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" size="large" onClick={handleSubmit} block>
              {t("createAccountLi")}
            </Button>
          </Form.Item>
        </Form>

        <Typography.Text type="secondary" className={styles.footer}>
          <Trans
            ns="register"
            i18nKey="alreadyHaveAccount"
            // Goes back to the main page
            components={[<Link key="0" to="/" />]}
          />
        </Typography.Text>
      </div>
    </AuthPageBase>
  );
};
