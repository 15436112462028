import { Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

const { Title } = Typography;

interface SurveyTitleProps {
  to: string;
}

export const SurveyTitle: React.FC<SurveyTitleProps> = ({ to }) => {
  const { t } = useTranslation("surveyTitle");

  return (
    <Link to={to}>
      <Flex gap={4} align="center">
        <Title level={5}>{t("surveys")}</Title>
        <AntDIcon name="chevronRight" color={ARBOLUS_COLORS.colorIcon} />
      </Flex>
    </Link>
  );
};
