import React, { useCallback, useEffect, useState } from "react";

import {
  BaseSurvey,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import { SurveyGrayBox } from "@arbolus-technologies/features/common";
import { PROJECT_SURVEYS_ROUTE } from "@arbolus-technologies/routes";

interface ProjectSurveyProps {
  projectId: string;
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

const LIMIT = 3;

export const ProjectSurvey: React.FC<ProjectSurveyProps> = ({
  projectId,
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const [surveys, setSurveys] = useState<BaseSurvey[]>([]);
  const [isSurveysLoading, setIsSurveysLoading] = useState<boolean>(true);

  const getSurveys = useCallback(() => {
    surveyService
      .getAllSurveysForAProject({ projectId, limit: LIMIT })
      .subscribe(
        (response) => {
          setSurveys(response.items);
          setIsSurveysLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsSurveysLoading(false);
        }
      );
  }, [surveyService, projectId]);

  useEffect(() => {
    getSurveys();
  }, [getSurveys]);

  return (
    <SurveyGrayBox
      to={PROJECT_SURVEYS_ROUTE(projectId)}
      projectId={projectId}
      isSurveysLoading={isSurveysLoading}
      surveys={surveys}
    />
  );
};
