import { ReactComponent as AccountBalance } from "@material-symbols/svg-400/sharp/account_balance.svg";
import { ReactComponent as Add } from "@material-symbols/svg-400/sharp/add.svg";
import { ReactComponent as Apartment } from "@material-symbols/svg-400/sharp/apartment.svg";
import { ReactComponent as Apps } from "@material-symbols/svg-400/sharp/apps.svg";
import { ReactComponent as ArrowOutward } from "@material-symbols/svg-400/sharp/arrow_outward.svg";
import { ReactComponent as ArrowRange } from "@material-symbols/svg-400/sharp/arrow_range.svg";
import { ReactComponent as Assignment } from "@material-symbols/svg-400/sharp/assignment.svg";
import { ReactComponent as AttachFile } from "@material-symbols/svg-400/sharp/attach_file.svg";
import { ReactComponent as AttachMoney } from "@material-symbols/svg-400/sharp/attach_money.svg";
import { ReactComponent as AwardStar } from "@material-symbols/svg-400/sharp/award_star.svg";
import { ReactComponent as Block } from "@material-symbols/svg-400/sharp/block.svg";
import { ReactComponent as BusinessFilled } from "@material-symbols/svg-400/sharp/business_center-fill.svg";
import { ReactComponent as Business } from "@material-symbols/svg-400/sharp/business_center.svg";
import { ReactComponent as CalendarToday } from "@material-symbols/svg-400/sharp/calendar_today.svg";
import { ReactComponent as Call } from "@material-symbols/svg-400/sharp/call.svg";
import { ReactComponent as CallEndFilled } from "@material-symbols/svg-400/sharp/call_end-fill.svg";
import { ReactComponent as CancelFilled } from "@material-symbols/svg-400/sharp/cancel-fill.svg";
import { ReactComponent as Cancel } from "@material-symbols/svg-400/sharp/cancel.svg";
import { ReactComponent as ChartData } from "@material-symbols/svg-400/sharp/chart_data.svg";
import { ReactComponent as Check } from "@material-symbols/svg-400/sharp/check.svg";
import { ReactComponent as CheckCircleFilled } from "@material-symbols/svg-400/sharp/check_circle-fill.svg";
import { ReactComponent as CheckCircle } from "@material-symbols/svg-400/sharp/check_circle.svg";
import { ReactComponent as ChevronLeft } from "@material-symbols/svg-400/sharp/chevron_left.svg";
import { ReactComponent as ChevronRight } from "@material-symbols/svg-400/sharp/chevron_right.svg";
import { ReactComponent as Circle } from "@material-symbols/svg-400/sharp/circle.svg";
import { ReactComponent as Close } from "@material-symbols/svg-400/sharp/close.svg";
import { ReactComponent as ContactEmergency } from "@material-symbols/svg-400/sharp/contact_emergency.svg";
import { ReactComponent as Copy } from "@material-symbols/svg-400/sharp/content_copy.svg";
import { ReactComponent as Delete } from "@material-symbols/svg-400/sharp/delete.svg";
import { ReactComponent as DomainFilled } from "@material-symbols/svg-400/sharp/domain-fill.svg";
import { ReactComponent as Domain } from "@material-symbols/svg-400/sharp/domain.svg";
import { ReactComponent as FileDownload } from "@material-symbols/svg-400/sharp/download.svg";
import { ReactComponent as DragHandle } from "@material-symbols/svg-400/sharp/drag_handle.svg";
import { ReactComponent as Edit } from "@material-symbols/svg-400/sharp/edit.svg";
import { ReactComponent as Equal } from "@material-symbols/svg-400/sharp/equal.svg";
import { ReactComponent as ErrorFillIcon } from "@material-symbols/svg-400/sharp/error-fill.svg";
import { ReactComponent as ErrorIcon } from "@material-symbols/svg-400/sharp/error.svg";
import { ReactComponent as Face } from "@material-symbols/svg-400/sharp/face.svg";
import { ReactComponent as Factory } from "@material-symbols/svg-400/sharp/factory.svg";
import { ReactComponent as Flag } from "@material-symbols/svg-400/sharp/flag.svg";
import { ReactComponent as Folder } from "@material-symbols/svg-400/sharp/folder.svg";
import { ReactComponent as Forum } from "@material-symbols/svg-400/sharp/forum.svg";
import { ReactComponent as Group } from "@material-symbols/svg-400/sharp/group.svg";
import { ReactComponent as Groups } from "@material-symbols/svg-400/sharp/groups.svg";
import { ReactComponent as Help } from "@material-symbols/svg-400/sharp/help.svg";
import { ReactComponent as Info } from "@material-symbols/svg-400/sharp/info.svg";
import {
  ReactComponent as ArrowDown,
  ReactComponent as KeyboardArrowDown
} from "@material-symbols/svg-400/sharp/keyboard_arrow_down.svg";
import { ReactComponent as ArrowUp } from "@material-symbols/svg-400/sharp/keyboard_arrow_up.svg";
import { ReactComponent as Link } from "@material-symbols/svg-400/sharp/link.svg";
import { ReactComponent as Logout } from "@material-symbols/svg-400/sharp/logout.svg";
import { ReactComponent as Mail } from "@material-symbols/svg-400/sharp/mail.svg";
import { ReactComponent as ManageAccounts } from "@material-symbols/svg-400/sharp/manage_accounts.svg";
import { ReactComponent as ManageSearch } from "@material-symbols/svg-400/sharp/manage_search.svg";
import { ReactComponent as Menu } from "@material-symbols/svg-400/sharp/menu.svg";
import { ReactComponent as Mic } from "@material-symbols/svg-400/sharp/mic-fill.svg";
import { ReactComponent as MicOff } from "@material-symbols/svg-400/sharp/mic_off-fill.svg";
import { ReactComponent as ThreeDotsHorizontal } from "@material-symbols/svg-400/sharp/more_horiz.svg";
import { ReactComponent as Notes } from "@material-symbols/svg-400/sharp/notes.svg";
import { ReactComponent as Paid } from "@material-symbols/svg-400/sharp/paid.svg";
import { ReactComponent as Password } from "@material-symbols/svg-400/sharp/password.svg";
import { ReactComponent as Person } from "@material-symbols/svg-400/sharp/person.svg";
import { ReactComponent as PhoneDisabled } from "@material-symbols/svg-400/sharp/phone_disabled.svg";
import { ReactComponent as PhoneForwardedFilled } from "@material-symbols/svg-400/sharp/phone_forwarded-fill.svg";
import { ReactComponent as PDF } from "@material-symbols/svg-400/sharp/picture_as_pdf.svg";
import { ReactComponent as Stats } from "@material-symbols/svg-400/sharp/query_stats.svg";
import { ReactComponent as RadioButtonChecked } from "@material-symbols/svg-400/sharp/radio_button_checked.svg";
import { ReactComponent as ReceiptLong } from "@material-symbols/svg-400/sharp/receipt_long.svg";
import { ReactComponent as Repeat } from "@material-symbols/svg-400/sharp/repeat.svg";
import { ReactComponent as Schedule } from "@material-symbols/svg-400/sharp/schedule.svg";
import { ReactComponent as ScreenShare } from "@material-symbols/svg-400/sharp/screen_share.svg";
import { ReactComponent as Search } from "@material-symbols/svg-400/sharp/search.svg";
import { ReactComponent as Send } from "@material-symbols/svg-400/sharp/send.svg";
import { ReactComponent as Settings } from "@material-symbols/svg-400/sharp/settings.svg";
import { ReactComponent as StopScreenShare } from "@material-symbols/svg-400/sharp/stop_screen_share.svg";
import { ReactComponent as Undo } from "@material-symbols/svg-400/sharp/undo.svg";
import { ReactComponent as Upload } from "@material-symbols/svg-400/sharp/upload.svg";
import { ReactComponent as VerifiedUser } from "@material-symbols/svg-400/sharp/verified_user.svg";
import { ReactComponent as VideoCam } from "@material-symbols/svg-400/sharp/videocam.svg";
import { ReactComponent as VideoCamOff } from "@material-symbols/svg-400/sharp/videocam_off.svg";
import { ReactComponent as Visibility } from "@material-symbols/svg-400/sharp/visibility.svg";
import { ReactComponent as VisibilityOff } from "@material-symbols/svg-400/sharp/visibility_off.svg";
import { ReactComponent as Warning } from "@material-symbols/svg-400/sharp/warning.svg";
import { ReactComponent as Workspaces } from "@material-symbols/svg-400/sharp/workspaces.svg";
import { ReactComponent as ZoomInMap } from "@material-symbols/svg-400/sharp/zoom_in_map.svg";

// IconName, non-filled icons. IconFillName, filled Icons. Remember to import the correct version

export type IconName =
  | "account_balance"
  | "add"
  | "apartment"
  | "apps"
  | "arrowDown"
  | "arrowOutward"
  | "arrowRange"
  | "arrowUp"
  | "assignment"
  | "attach_file"
  | "attach_money"
  | "business"
  | "business_filled"
  | "calendar_today"
  | "call"
  | "call_end_filled"
  | "cancel"
  | "cancel_filled"
  | "chart_data"
  | "check"
  | "check_circle"
  | "check_circle_filled"
  | "chevronLeft"
  | "chevronRight"
  | "circle"
  | "close"
  | "copy"
  | "delete"
  | "domain"
  | "domain_filled"
  | "drag_handle"
  | "edit"
  | "equal"
  | "error"
  | "error_filled"
  | "face"
  | "file_download"
  | "flag"
  | "folder"
  | "forum"
  | "group"
  | "groups"
  | "help"
  | "info"
  | "keyboard_arrow_down"
  | "link"
  | "logout"
  | "mail"
  | "manage_accounts"
  | "manage_search"
  | "menu"
  | "mic"
  | "mic_off"
  | "more_horiz"
  | "notes"
  | "password"
  | "paid"
  | "person"
  | "phone_disabled"
  | "phone_forwarded_filled"
  | "picture_as_pdf"
  | "radioButtonChecked"
  | "receipt_long"
  | "repeat"
  | "schedule"
  | "screen_share"
  | "search"
  | "send"
  | "settings"
  | "stats"
  | "stop_screen_share"
  | "undo"
  | "verified_user"
  | "videocam"
  | "videocam_off"
  | "visibility"
  | "visibility_off"
  | "warning"
  | "workspaces"
  | "zoom_in_map"
  | "factory"
  | "contact_emergency"
  | "award_star"
  | "upload"
  | "block";

export const icons: Record<
  IconName,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  account_balance: AccountBalance,
  add: Add,
  apartment: Apartment,
  apps: Apps,
  arrowDown: ArrowDown,
  arrowOutward: ArrowOutward,
  arrowRange: ArrowRange,
  arrowUp: ArrowUp,
  assignment: Assignment,
  attach_file: AttachFile,
  attach_money: AttachMoney,
  business_filled: BusinessFilled,
  business: Business,
  calendar_today: CalendarToday,
  call: Call,
  call_end_filled: CallEndFilled,
  cancel: Cancel,
  cancel_filled: CancelFilled,
  chart_data: ChartData,
  check: Check,
  check_circle: CheckCircle,
  check_circle_filled: CheckCircleFilled,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  circle: Circle,
  close: Close,
  copy: Copy,
  delete: Delete,
  domain: Domain,
  domain_filled: DomainFilled,
  drag_handle: DragHandle,
  edit: Edit,
  equal: Equal,
  error: ErrorIcon,
  error_filled: ErrorFillIcon,
  face: Face,
  file_download: FileDownload,
  flag: Flag,
  folder: Folder,
  forum: Forum,
  group: Group,
  groups: Groups,
  help: Help,
  info: Info,
  keyboard_arrow_down: KeyboardArrowDown,
  link: Link,
  logout: Logout,
  mail: Mail,
  manage_accounts: ManageAccounts,
  manage_search: ManageSearch,
  menu: Menu,
  mic: Mic,
  mic_off: MicOff,
  more_horiz: ThreeDotsHorizontal,
  notes: Notes,
  password: Password,
  paid: Paid,
  person: Person,
  phone_disabled: PhoneDisabled,
  phone_forwarded_filled: PhoneForwardedFilled,
  picture_as_pdf: PDF,
  radioButtonChecked: RadioButtonChecked,
  receipt_long: ReceiptLong,
  repeat: Repeat,
  schedule: Schedule,
  screen_share: ScreenShare,
  search: Search,
  send: Send,
  settings: Settings,
  stats: Stats,
  stop_screen_share: StopScreenShare,
  undo: Undo,
  verified_user: VerifiedUser,
  videocam: VideoCam,
  videocam_off: VideoCamOff,
  visibility: Visibility,
  visibility_off: VisibilityOff,
  warning: Warning,
  workspaces: Workspaces,
  zoom_in_map: ZoomInMap,
  factory: Factory,
  contact_emergency: ContactEmergency,
  award_star: AwardStar,
  block: Block,
  upload: Upload
};
