import React from "react";

import { ProjectClientInfo } from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  CLIENT_DASHBOARD_ROUTE,
  PROJECT_DISCOVER_ROUTE
} from "@arbolus-technologies/routes";
import {
  ClientDashboardButton,
  DiscoverButton,
  LoaderOrComponent
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { ComplianceButton } from "../../Components/ComplianceButton/ComplianceButton";

import styles from "./AdminActionsMenu.module.scss";

interface AdminActionsMenuProps {
  projectId: string;
  client: ProjectClientInfo;
  isLoading: boolean;
  expertsCount: number;
}

export const AdminActionsMenu: React.FC<AdminActionsMenuProps> = ({
  projectId,
  client,
  isLoading,
  expertsCount
}) => {
  const { trackClick } = useArbolusTracking();

  return (
    <GrayBox fullWidth>
      <LoaderOrComponent isLoading={isLoading}>
        <div className={styles.container}>
          <DiscoverButton
            itemCount={expertsCount}
            to={PROJECT_DISCOVER_ROUTE(projectId)}
            trackClick={() =>
              trackClick(MixPanelEventNames.ProjectDashboardFindExperts)
            }
          />
          <ComplianceButton projectId={projectId} />
          <ClientDashboardButton
            clientName={client.name}
            to={CLIENT_DASHBOARD_ROUTE(client.id)}
          />
        </div>
      </LoaderOrComponent>
    </GrayBox>
  );
};
