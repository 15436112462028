import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Container } from "reactstrap";

import { SSOPage } from "@arbolus-technologies/features/common";
import {
  CanopyPaused,
  CanopyWelcome
} from "@arbolus-technologies/features/expert-canopy";

import { RouteComponentProps } from "react-router-dom";
import { PublicRouteConstants } from "../../constants";
import { RECAPTCHA_SITE_KEY } from "../../constants/auth";
import { SentryRoute } from "../../sentry";
import { withPublicAccess } from "../app/components";
import ExpertEndorsementPage from "./pages/endorsement/ExpertEndorsementPage";
import FederatedRedirectPage from "./pages/federate/FederatedRedirectPage";
import ForgotPasswordPage from "./pages/forgot/ForgotPasswordPage";
import LoginPage from "./pages/login/LoginPage";
import RegisterPage from "./pages/register/RegisterPage";
import ResetPasswordPage from "./pages/reset/ResetPasswordPage";

const AuthContainer: React.FC = () => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY!}>
    <Container className="login-container">
      <SentryRoute path={PublicRouteConstants.SSO} exact component={SSOPage} />
      <SentryRoute
        exact
        path={PublicRouteConstants.LOGIN}
        component={LoginPage}
      />
      <SentryRoute
        path={PublicRouteConstants.REGISTER}
        exact
        render={(props: RouteComponentProps): JSX.Element => (
          <RegisterPage {...props} />
        )}
      />
      <SentryRoute
        path={PublicRouteConstants.FORGOT_PASSWORD}
        exact
        // @ts-ignore
        component={ForgotPasswordPage}
      />
      <SentryRoute
        path={PublicRouteConstants.RESET_PASSWORD}
        exact
        component={ResetPasswordPage}
      />
      <SentryRoute
        path={PublicRouteConstants.FEDERATED_REDIRECT}
        exact
        component={FederatedRedirectPage}
      />
      <SentryRoute
        path={PublicRouteConstants.ENDORSEMENT}
        exact
        component={ExpertEndorsementPage}
      />
      <SentryRoute
        exact
        path={PublicRouteConstants.WELCOME_CANOPY}
        component={CanopyWelcome}
      />
      <SentryRoute
        exact
        path={PublicRouteConstants.CANOPY_PAUSED_PUBLIC}
        component={CanopyPaused}
      />
    </Container>
  </GoogleReCaptchaProvider>
);

export default withPublicAccess(AuthContainer);
