import {
  Alert,
  Button,
  Card,
  Flex,
  Modal,
  Select,
  Tag,
  Typography
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  ExpertUser,
  PROJECT_REFERRAL_STATE,
  ReferralDetail,
  Slot,
  ToasterService
} from "@arbolus-technologies/api";
import {
  APP_TRACKING_ROUTES,
  DO_NOT_CONTACT_STATUS,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { EXPERT_STATUS } from "@arbolus-technologies/models/expert";
import {
  AvailableSlot,
  DEFAULT_SLOTS_NUMBER,
  MAX_SLOTS_NUMBER
} from "@arbolus-technologies/models/project";
import { PROJECT_NEW_EVENT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { useFormatSlotsValues } from "@arbolus-technologies/utils";

import {
  MixPanelActions,
  MixPanelEventNames
} from "../../../../Mixpanel/enums";
import { trackEvent } from "../../../../Mixpanel/utils";
import { buildEventFromChat, generateEventName } from "../../../../utils";
import { ExpertProfileSidePanel } from "../../../ExpertProfileSidePanel/ExpertProfileSidePanel";

import styles from "./Scheduling.module.scss";

const { Text } = Typography;
const BAIN_ID = process.env.NX_PUBLIC_BAIN_ID;

interface SchedulingProps {
  referral: ReferralDetail;
  expert: ExpertDetail;
  requestSlotsForBain?: () => void;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const Scheduling: React.FC<SchedulingProps> = ({
  referral,
  expert,
  requestSlotsForBain,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralScheduling");
  const dispatch = useDispatch();
  const history = useHistory();
  const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [availableSlots, setAvailableSlots] = useState<AvailableSlot[]>([]);
  const [selectedTimezone, setSelectedTimezone] =
    useState<string>(systemTimezone);
  const [sliceSlots, setSliceSlots] = useState<number>(DEFAULT_SLOTS_NUMBER);
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
  const [
    isRequestTimeSlotsAlreadyClicked,
    setIsRequestTimeSlotsAlreadyClicked
  ] = useState(false);

  const {
    expertAvailabilitySlots = [],
    referralAvailability,
    status: referralStatus,
    application,
    compliance
  } = referral;

  const { getFormattedSlotsValues, sortFormattedSlotsValues } =
    useFormatSlotsValues(expertAvailabilitySlots, selectedTimezone);

  const projectTimezone = useSelector(ProjectNxSelector.displayTimezoneId());
  const clientId = useSelector(ProjectNxSelector.projectClientId());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const timezones = useSelector(CacheSelector.timezones());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  useEffect(() => {
    const slotsFormatted = getFormattedSlotsValues();
    const sortedSlots = sortFormattedSlotsValues(slotsFormatted);
    setAvailableSlots(sortedSlots);
  }, [
    getFormattedSlotsValues,
    sortFormattedSlotsValues,
    expertAvailabilitySlots
  ]);

  const handleBookEvent = (slot?: AvailableSlot) => {
    if (referralStatus === PROJECT_REFERRAL_STATE.CANDIDATE) {
      setIsCandidateModalOpen(true);
    } else {
      handleCreateEvent(slot);
    }
  };

  const handleCreateEvent = (slot?: AvailableSlot) => {
    if (application.subStatus !== REFERRAL_SUB_STATE.ACCEPT) {
      dispatch(
        ProjectExpertsStoreActions.updateReferralStatus(
          referral.projectId,
          referral.id,
          { review: REFERRAL_SUB_STATE.ACCEPT }
        )
      );
    }

    createEvent(slot, projectTimezone);
    trackEvent?.(MixPanelEventNames.CreateEvent, {
      action: MixPanelActions.Initialized,
      from: APP_TRACKING_ROUTES.EXPERT_AVAILABILITY
    });
  };

  const createEvent = (selectedSlot?: AvailableSlot, timezone?: string) => {
    if (referralStatus === PROJECT_REFERRAL_STATE.CANDIDATE) {
      dispatch(
        ProjectExpertsStoreActions.updateReferralStatus(
          referral.projectId,
          referral.id,
          { review: EXPERT_STATUS.ACCEPT }
        )
      );
    }

    const expertName = `${expert.user.firstName} ${expert.user.lastName}`;
    const title = generateEventName(expertName, projectName);

    const eventBuiltFromChat = buildEventFromChat({
      expert: {
        ...expert.user,
        hasMinimumCallTimeRate: expert.hasMinimumCallTimeRate
      } as ExpertUser,
      selectedSlot: selectedSlot
        ? {
            startDate: selectedSlot.slotStartValue,
            endDate: selectedSlot.slotEndValue
          }
        : undefined,
      workspaceId: "",
      timezone: timezone ?? "UTC",
      title
    });

    dispatch(PanelStoreActions.closePanel(PanelId.ReferralExpertPanel));
    history.push(
      PROJECT_NEW_EVENT_ROUTE(referral.projectId),
      eventBuiltFromChat
    );
  };

  const handleRequestTimeSlots = () => {
    setIsRequestTimeSlotsAlreadyClicked(true);

    if (isBainClient && isExpertUnapproved && !isAdmin && requestSlotsForBain) {
      requestSlotsForBain();
    } else {
      // This service approves the expert directly
      expertService
        .requestExpertAvailability(referral.projectId, expert.id)
        .subscribe(
          () => {
            dispatch(PanelStoreActions.closePanel(PanelId.ReferralExpertPanel));
            notificationService.showSuccess(t("requestTimeSlotSuccessMessage"));
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
          }
        );
    }
  };

  const isApplicationComplete =
    application.subStatus === REFERRAL_SUB_STATE.ACCEPT;

  const validAvailabilitySlots = expertAvailabilitySlots.filter(
    (slot: Slot) => !slot.isExpired
  );
  const areSlotsExpired =
    expertAvailabilitySlots.length > 0 && validAvailabilitySlots.length === 0;

  if (!isApplicationComplete) {
    return (
      <Alert
        message={t("visibleAfterApplicationCompletion")}
        type="warning"
        showIcon
      />
    );
  }

  if (areSlotsExpired) {
    return <Alert message={t("slotsExpired")} type="warning" showIcon />;
  }

  const {
    availabilityRequesterUser,
    availabilityRequiredDate,
    isRequiredMoreAvailabilitySlotsAllowed,
    waitingHours
  } = referralAvailability;

  const formattedDate = moment(availabilityRequiredDate).format("ll - h:mm a");
  const availabilityRequesterName = `${availabilityRequesterUser?.firstName} ${availabilityRequesterUser?.lastName}`;

  const isCompliancePending =
    compliance.subStatus === REFERRAL_COMPLIANCE_STATE.REJECT ||
    compliance.subStatus === REFERRAL_COMPLIANCE_STATE.PENDING;
  const isExpertRejectedOrShortlisted =
    referralStatus === PROJECT_REFERRAL_STATE.REJECT ||
    referralStatus === PROJECT_REFERRAL_STATE.SHORTLIST;

  const isExpertUnapproved = referralStatus !== PROJECT_REFERRAL_STATE.ACCEPT;
  const isBainClient = clientId === BAIN_ID;
  const isBainAndUnapprovedExpert =
    isAdmin && isBainClient && isExpertUnapproved;
  const isDnc = expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const disableTimeSlots =
    isDnc ||
    isCompliancePending ||
    isExpertRejectedOrShortlisted ||
    application.subStatus === REFERRAL_SUB_STATE.PENDING;

  const disableEventCreation =
    isDnc || disableTimeSlots || isBainAndUnapprovedExpert;

  return (
    <Flex vertical gap={24}>
      {isCompliancePending && (
        <Alert message={t("compliancePending")} type="warning" showIcon />
      )}
      <Card>
        <Flex gap={16} vertical>
          <Text strong>{t("expertAvailability")}</Text>
          <Text>{t("readyToConsult")}</Text>
          <Flex gap={8} align="center">
            <Text>{t("timeDisplayed")}</Text>
            <Select
              showSearch
              defaultValue={
                timezones.find((timezone) => timezone.value === systemTimezone)
                  ?.label
              }
              onChange={(timezone: string) => setSelectedTimezone(timezone)}
              options={timezones}
              style={{ flex: 1 }}
            />
          </Flex>
          <Flex gap={24} vertical>
            <div className={styles.slotsContainer}>
              {availableSlots.slice(0, sliceSlots).map((availableSlot) => (
                <Card key={availableSlot.slotId}>
                  <Flex gap={4} vertical>
                    <Text strong>
                      {availableSlot.slotTimeStart} -{" "}
                      {availableSlot.slotTimeEnd}
                    </Text>
                    <Flex gap={16} align="center" justify="space-between">
                      <Text type="secondary">{availableSlot.slotDate}</Text>
                      {availableSlot.slotScheduled ? (
                        <Tag
                          icon={<AntDIcon name="check" />}
                          color="success"
                          className={styles.tag}
                        >
                          {t("booked")}
                        </Tag>
                      ) : (
                        <Button
                          type="primary"
                          disabled={disableEventCreation}
                          onClick={() => handleBookEvent(availableSlot)}
                        >
                          {t("book")}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Card>
              ))}
            </div>
            <Flex gap={16} justify="space-between">
              {sliceSlots === DEFAULT_SLOTS_NUMBER &&
                availableSlots.length > DEFAULT_SLOTS_NUMBER && (
                  <Button
                    type="link"
                    icon={<AntDIcon name="keyboard_arrow_down" />}
                    iconPosition="end"
                    onClick={() => setSliceSlots(MAX_SLOTS_NUMBER)}
                  >
                    {t("showMore")}
                  </Button>
                )}
              <Flex gap={16} style={{ marginLeft: "auto" }}>
                <Button
                  disabled={disableEventCreation}
                  onClick={() => handleBookEvent()}
                >
                  {t("bookCustomTime")}
                </Button>
                <Button
                  type="primary"
                  onClick={handleRequestTimeSlots}
                  disabled={
                    isDnc ||
                    disableTimeSlots ||
                    isBainAndUnapprovedExpert ||
                    isRequestTimeSlotsAlreadyClicked ||
                    !isRequiredMoreAvailabilitySlotsAllowed
                  }
                >
                  {t("requestNewTimeSlot")}
                </Button>
              </Flex>
            </Flex>
            {!isRequiredMoreAvailabilitySlotsAllowed && (
              <DangerouslySetInnerHTML
                text={t("requireAvailabilityMessage", {
                  date: formattedDate,
                  name: availabilityRequesterName,
                  hours: waitingHours
                })}
                color={ARBOLUS_COLORS.bColorError}
              />
            )}
          </Flex>
        </Flex>
      </Card>
      <Modal
        title={t("expertCandidateTitle")}
        open={isCandidateModalOpen}
        cancelText={t("review")}
        onCancel={() => {
          setIsCandidateModalOpen(false);
          dispatch(PanelStoreActions.openPanel(PanelId.ExpertProfile));
        }}
        onOk={() => {
          setIsCandidateModalOpen(false);
          handleCreateEvent();
        }}
        okText={t("approve")}
        okType="primary"
      >
        {t("expertCandidateSubtitleArrangeCall")}
      </Modal>
      <ExpertProfileSidePanel expertId={expert.id} />
      {/* <InternalSlidePanel
        width={SIDE_PANEL_SIZE._720}
        title={t("expertProfile")}
        closeButtonDirection="right"
        customCloseRequest={() => setIsExpertProfileOpen(false)}
        isOpen={isExpertProfileOpen}
      >
        <ExpertProfile expertId={expertId} />
      </InternalSlidePanel> */}
    </Flex>
  );
};
