import { Button, Flex, Input, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AntDAvatar, AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  DefaultToasterService,
  EngagementService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  REPEATED_ENGAGEMENT_STATUS,
  RepeatedEngagementEventDetails
} from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { RepeatedEngagementTag } from "@arbolus-technologies/ui/components";
import {
  DAYJS_12_HOUR_TIME_FORMAT,
  DAYJS_DATE_FORMAT,
  DAYJS_DATE_TIME_FORMAT
} from "@arbolus-technologies/utils";

import styles from "./Compliance.module.scss";

const { Text } = Typography;
const { TextArea } = Input;

interface RepeatedEngagementEventProps {
  engagementNumber?: number;
  isPendingForComplianceManager?: boolean;
  repeatedEngagementEventDetails: RepeatedEngagementEventDetails;
  engagementService?: typeof EngagementService;
  notificationService?: ToasterService;
}

export const RepeatedEngagementEvent: React.FC<
  RepeatedEngagementEventProps
> = ({
  engagementNumber,
  isPendingForComplianceManager = false,
  repeatedEngagementEventDetails,
  engagementService = EngagementService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralCompliance");

  const [isSaving, setIsSaving] = useState(false);
  const [additionalComment, setAdditionalComment] = useState("");

  const {
    eventId,
    statusUpdatedByUser,
    statusUpdatedDate,
    projectName,
    eventStartDate,
    eventEndDate,
    timezone,
    status,
    comment
  } = repeatedEngagementEventDetails;

  const onUpdateEngagement = (status: REPEATED_ENGAGEMENT_STATUS) => {
    setIsSaving(true);

    engagementService
      .updateEventRepeatedEngagementStatus(eventId, additionalComment, status)
      .subscribe(
        () => {
          setIsSaving(false);
          notificationService.showSuccess(t("engagementUpdateSuccess"));
        },
        () => {
          setIsSaving(false);
          notificationService.showError(t("engagementUpdateFailed"));
        }
      );
  };

  return (
    <Flex vertical gap={16}>
      <Flex vertical gap={8}>
        <Flex gap={16} justify="space-between" align="center">
          <Text strong>{t("eventDetails")}</Text>
          {!isPendingForComplianceManager && (
            <Flex gap={4}>
              <RepeatedEngagementTag status={status} />
              <Tag className={styles.tag}>
                {t("engagement", { engagementNumber })}
              </Tag>
            </Flex>
          )}
        </Flex>
        <Tag icon={<AntDIcon name="folder" />} className={styles.tag}>
          {projectName}
        </Tag>
        <Flex gap={8} align="center">
          <AntDIcon name="calendar_today" />
          <Text>{dayjs(eventStartDate).format(DAYJS_DATE_FORMAT)}</Text>
        </Flex>
        <Flex gap={8} align="center">
          <AntDIcon name="schedule" />
          <Text>{`${dayjs(eventStartDate).format(DAYJS_12_HOUR_TIME_FORMAT)} - ${dayjs(eventEndDate).format(DAYJS_12_HOUR_TIME_FORMAT)}`}</Text>
          <Text>|</Text>
          <Text>{timezone}</Text>
        </Flex>
      </Flex>
      {isPendingForComplianceManager ? (
        <Flex vertical gap={16}>
          <Flex vertical gap={4}>
            <Text>{t("additionalComments")}</Text>
            <TextArea
              rows={4}
              placeholder={t("commentsPlaceholder")}
              value={additionalComment}
              onChange={(e) => setAdditionalComment(e.target.value)}
            />
          </Flex>
          <Flex gap={16} justify="flex-end">
            <Button
              danger
              type="primary"
              disabled={isSaving}
              onClick={() =>
                onUpdateEngagement(REPEATED_ENGAGEMENT_STATUS.REJECTED)
              }
            >
              {t("reject")}
            </Button>
            <Button
              type="primary"
              disabled={isSaving}
              onClick={() =>
                onUpdateEngagement(REPEATED_ENGAGEMENT_STATUS.PASSED)
              }
              style={{ backgroundColor: ARBOLUS_COLORS.bColorSuccess }}
            >
              {t("approve")}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Text>{comment}</Text>
          <Flex gap={16} justify="space-between" align="center">
            <AntDAvatar
              firstName={statusUpdatedByUser.firstName}
              lastName={statusUpdatedByUser.lastName}
              profileImageUrl={statusUpdatedByUser.profileImageUrl}
              size="small"
            />
            <Text code>
              {dayjs(statusUpdatedDate).format(DAYJS_DATE_TIME_FORMAT)}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
