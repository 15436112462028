import { ColDef, ITooltipParams } from "ag-grid-community";
import i18next from "i18next";

import { AdoptionRenderer } from "../../Components/CellComponents/AdoptionRenderer/AdoptionRenderer";
import { AlternativeCompaniesRenderer } from "../../Components/CellComponents/AlternativeCompaniesRenderer/AlternativeCompaniesRenderer";
import { ChurnRiskRenderer } from "../../Components/CellComponents/ChurnRiskRenderer/ChurnRiskRenderer";
import { CompanyDataRenderer } from "../../Components/CellComponents/CompanyDataRenderer/CompanyDataRenderer";
import { CustomersRenderer } from "../../Components/CellComponents/CustomersRenderer/CustomersRenderer";
import { LogoCompanyNameRenderer } from "../../Components/CellComponents/LogoCompanyNameRenderer/LogoCompanyNameRenderer";
import { MonitoringStatusSelectRenderer } from "../../Components/CellComponents/MonitoringStatusSelectRenderer/MonitoringStatusSelectRenderer";
import { NPSRenderer } from "../../Components/CellComponents/NPSRenderer/NPSRenderer";
import { RenewalRenderer } from "../../Components/CellComponents/RenewalRenderer/RenewalRenderer";
import { SpendRenderer } from "../../Components/CellComponents/SpendRenderer/SpendRenderer";
import { ExportCSVButton } from "../../Components/ExportCSVButton/ExportCSVButton";

import styles from "./UserMonitoringListTable.module.scss";

const maxWidth = 100;

export const getUserColumns = (clientUserId: string): ColDef[] => [
  {
    field: "vendorCompanyName",
    headerName: i18next.t("userMonitoringListPage:targetName"),
    cellRenderer: LogoCompanyNameRenderer,
    tooltipValueGetter: ({ data }: ITooltipParams) => data.vendorCompanyName
  },
  {
    field: "customersCount",
    headerName: i18next.t("userMonitoringListPage:customers"),
    maxWidth,
    cellRenderer: CustomersRenderer
  },
  {
    field: "churnRisk",
    headerName: i18next.t("userMonitoringListPage:churnRisk"),
    cellRenderer: ChurnRiskRenderer,
    maxWidth
  },
  {
    field: "nps",
    headerName: i18next.t("userMonitoringListPage:nps"),
    cellRenderer: NPSRenderer,
    maxWidth
  },
  {
    field: "spend",
    headerName: i18next.t("userMonitoringListPage:spend"),
    cellRenderer: SpendRenderer,
    maxWidth
  },
  {
    field: "renewalIntent",
    headerName: i18next.t("userMonitoringListPage:renewal"),
    cellRenderer: RenewalRenderer,
    maxWidth
  },
  {
    field: "adoption",
    headerName: i18next.t("userMonitoringListPage:adoption"),
    cellRenderer: AdoptionRenderer,
    maxWidth
  },
  {
    field: "alternativeCompanies",
    headerName: i18next.t("userMonitoringListPage:alternatives"),
    cellRenderer: AlternativeCompaniesRenderer,
    flex: 1
  },
  {
    field: "status",
    headerName: i18next.t("userMonitoringListPage:status"),
    cellRenderer: MonitoringStatusSelectRenderer,
    cellRendererParams: { clientUserId },
    maxWidth: 160
  },
  {
    headerName: "",
    flex: 1,
    headerComponent: ExportCSVButton,
    headerComponentParams: {
      tableName: i18next.t("userMonitoringListPage:monitoringList")
    },
    cellRenderer: CompanyDataRenderer,
    headerClass: styles.companyDataRendererHeader,
    pinned: "right"
  }
];
