import { UserMonitoringListModel } from "@arbolus-technologies/api";

export interface ClientIdParamUrlType {
  clientId: string;
}

export enum STEPS {
  SelectUser,
  SelectCompany,
  CreateCompany
}

export type CompanyData = Omit<
  UserMonitoringListModel,
  "alternativeCompanies" | "lastUpdate"
>;
