import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  WorkHistoryEditList,
  useArbolusTracking,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import { ProjectApplicationStepProps } from "@arbolus-technologies/models/project";

import { ProjectApplicationWrapper } from "../ProjectApplicationWrapper/ProjectApplicationWrapper";

import styles from "./CompanyValidationStep.module.scss";

interface CompanyValidationStepProps extends ProjectApplicationStepProps {
  expertId: string;
  onSaveProgress: () => void;
}

export const CompanyValidationStep: React.FC<CompanyValidationStepProps> = ({
  onSaveProgress,
  stepDetails,
  onBack,
  expertId
}: CompanyValidationStepProps) => {
  const { t } = useTranslation("companyValidationHeader");
  const { trackClick } = useArbolusTracking();
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.ExpertUserProjectApplicationWorkHistoryTime
  );

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime();
    };
  }, []);

  const handleSave = () => {
    trackClick(MixPanelEventNames.ExpertUserProjectApplicationWorkHistory);
    onSaveProgress();
  };

  return (
    <PageTracker
      page={MixpanelPages.ExpertUserProjectApplicationPageWorkHistory}
    >
      <ProjectApplicationWrapper
        stepDetails={stepDetails}
        onBack={onBack}
        isLoading
      >
        <div className={styles.container}>
          <h1 className={styles.title}>{t("title")}</h1>
          <p className={styles.description}>
            <span>{t("currentPositions")}</span>
            <span>{t("mandatoryTimeFrameWarning")}</span>
          </p>
        </div>
        <WorkHistoryEditList
          expertId={expertId}
          onSave={handleSave}
          mandatoryTimeFrame
        />
      </ProjectApplicationWrapper>
    </PageTracker>
  );
};
