import {
  DefaultToasterService,
  MonitoringListService,
  ToasterService
} from "@arbolus-technologies/api";
import { CLIENT_PAGE_ROUTE } from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";
import { GridApi } from "ag-grid-community";
import { Button } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { AddCompanySlidePanel } from "../Components/CellComponents/AddCompanySlidePanel/AddCompanySlidePanel";
import { RemoveCompanyModal } from "../Components/RemoveCompanyModal/RemoveCompanyModal";
import { MonitoringListTable } from "../Modules/MonitoringListTable/MonitoringListTable";
import { ClientIdParamUrlType } from "../models";

interface MonitoringListPageProps {
  notificationService?: ToasterService;
}

export const MonitoringListPage: React.FC<MonitoringListPageProps> = ({
  notificationService = DefaultToasterService
}) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState<string>("");

  const { t } = useTranslation("monitoringList");
  const { clientId } = useParams<ClientIdParamUrlType>();
  useDocumentTitle("monitoringList", "title");
  const dispatch = useDispatch();

  const openAddCompanyPanel = useCallback(() => {
    dispatch(PanelStoreActions.openPanel(PanelId.AddMonitoringListCompany));
  }, [dispatch]);

  const refreshGrid = useCallback(
    () => gridApi?.refreshServerSide({ purge: true }),
    [gridApi]
  );

  const handleOpenModal = (id: string) => {
    setDeleteCompanyId(id);
    setOpenModal(true);
  };

  const handleOk = async () => {
    try {
      await MonitoringListService.deleteCompanySignal(deleteCompanyId);
      notificationService.showSuccess(t("removeCompanySuccess"));
      refreshGrid();
      setOpenModal(false);
      setDeleteCompanyId("");
    } catch (error) {
      notificationService.showError(error.message);
    }
  };

  return (
    <MainPageLayout
      title={t("title")}
      backLink={CLIENT_PAGE_ROUTE(clientId).pathname}
      stickyHeader
      rightButtonContainer={
        <Button disabled={!gridApi} onClick={openAddCompanyPanel}>
          {t("addCompany")}
        </Button>
      }
    >
      <MonitoringListTable
        onGridApiReady={setGridApi}
        clientId={clientId}
        onClickAction={handleOpenModal}
      />
      <AddCompanySlidePanel clientId={clientId} refreshData={refreshGrid} />
      <RemoveCompanyModal
        isOpen={openModal}
        handleOk={handleOk}
        setIsOpen={setOpenModal}
      />
    </MainPageLayout>
  );
};
