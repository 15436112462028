import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  DefaultToasterService,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import { PublicRoutes } from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithBackground } from "@arbolus-technologies/ui/components";

import styles from "./ExpertInactive.module.scss";

interface ExpertInactiveProps {
  expertId: string;
  logout: () => void;
  notificationService?: ToasterService;
}

export const ExpertInactive = ({
  expertId,
  logout,
  notificationService = DefaultToasterService
}: ExpertInactiveProps): JSX.Element => {
  const { t } = useTranslation("expertInactive");
  const { push } = useHistory();

  const handleReactivateAccount = (): void => {
    ExpertService.reactivateExpert(expertId).subscribe({
      next: () => {
        notificationService.showSuccess(t("expertReactivationSuccess"));
        location.reload();
      },
      error: (error) => {
        notificationService.showApiErrors(error);
      }
    });
  };

  const handleCancelClick = (): void => {
    logout();
    push(PublicRoutes.LOGIN);
  };

  return (
    <div className={styles.expertInactiveContainer}>
      <Result
        icon={
          <div className={styles.expertInactiveIcon}>
            <IconWithBackground
              name={"published_with_changes"}
              fontSize="16px"
              color={ARBOLUS_COLORS.bColorBaseWhite}
              backgroundColor={ARBOLUS_COLORS.bColorBasePurple}
              size={"big"}
              circular={true}
            />
          </div>
        }
        title={t("inactiveTitle")}
        subTitle={t("inactiveMessage")}
        extra={[
          <Button key="cancel" onClick={handleCancelClick}>
            {t("cancel")}
          </Button>,
          <Button
            key="reactivate"
            type="primary"
            onClick={handleReactivateAccount}
          >
            {t("reactivationButton")}
          </Button>
        ]}
      />
    </div>
  );
};
export default ExpertInactive;
