import i18next from "i18next";

import { RecommendationResponse } from "@arbolus-technologies/api";
import { TableTextFilter } from "@arbolus-technologies/ui/components";
import { normalize } from "@arbolus-technologies/utils";

import { NameIconBorderRecommendation } from "../../NameIconAngleBorder/NameIconBorderRecommendation";
import { ColumnWithWidth, createTableDefinition } from "../../constants";
import { Companies } from "./Companies/Companies";
import { RecommendationsDropdown } from "./RecommendationsDropdown";

export enum RecommendationsTableColumns {
  EXPERT = "expert",
  CURRENT_COMPANIES = "currentCompanies",
  PAST_COMPANIES = "pastCompanies",
  MORE_OPTIONS = "moreOptions"
}

const currentCompaniesTranslation = i18next.t("expertsList:currentCompanies");
const pastCompaniesTranslation = i18next.t("expertsList:pastCompanies");
const recommendationsTable: ColumnWithWidth[] = [
  {
    name: RecommendationsTableColumns.EXPERT,
    component: (props) => <TableTextFilter {...props} displayName="Expert" />,
    cellComponent: NameIconBorderRecommendation,
    filterFn: "includesString",
    accessorFn: (row: RecommendationResponse) =>
      normalize(`${row.firstName} ${row.lastName}`),
    gridColumnWidth: "240px"
  },
  {
    name: RecommendationsTableColumns.CURRENT_COMPANIES,
    component: () => <div>{currentCompaniesTranslation}</div>,
    cellComponent: ({ row }: { row: RecommendationResponse }) => {
      const currentCompanies = row.companies.filter(
        (company) => company.isCurrentWork
      );
      return (
        <Companies id={`current-${row.id}`} companies={currentCompanies} />
      );
    },
    gridColumnWidth: "minmax(0, 1fr)"
  },
  {
    name: RecommendationsTableColumns.PAST_COMPANIES,
    component: () => <div>{pastCompaniesTranslation}</div>,
    cellComponent: ({ row }: { row: RecommendationResponse }) => {
      const pastCompanies = row.companies.filter(
        (company) => !company.isCurrentWork
      );
      return <Companies id={`past-${row.id}`} companies={pastCompanies} />;
    },
    gridColumnWidth: "minmax(0, 1fr)"
  },
  {
    name: RecommendationsTableColumns.MORE_OPTIONS,
    cellComponent: RecommendationsDropdown,
    gridColumnWidth: "40px"
  }
];

export const recommendationsTableDefinition =
  createTableDefinition(recommendationsTable);
