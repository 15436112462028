import { Card, Skeleton } from "antd";
import React from "react";
import styles from "./SkeletonLoader.module.scss";

export const SkeletonLoader: React.FC = () => {
  return (
    <Card>
      <Skeleton active paragraph={{ rows: 10 }} />
    </Card>
  );
};
