import { ColDef } from "ag-grid-community";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { LIST_PROJECTS_ORDER_BY } from "@arbolus-technologies/api";
import {
  ClassificationType,
  ProjectState
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  fixedWidth,
  floatingFilter
} from "@arbolus-technologies/ui/components";
import { formatDate } from "@arbolus-technologies/utils";

import { ClientRenderer } from "./CellRenderers/ClientRenderer";
import { ContactRenderer } from "./CellRenderers/ContactRenderer";
import { DraftActionsRenderer } from "./CellRenderers/DraftActionsRenderer";
import { InProjectCellRenderer } from "./CellRenderers/InProjectCellRenderer";
import { LinksRenderer } from "./CellRenderers/LinksRenderer";
import {
  ActiveProjectName,
  DraftProjectName
} from "./CellRenderers/NameRenderer";
import { StatusRenderer } from "./CellRenderers/StatusRenderer";

type columnName =
  | "status"
  | "activeName"
  | "draftName"
  | "classification"
  | "client"
  | "inProject"
  | "created"
  | "arbolusContact"
  | "links"
  | "draftActions";

const ColumnDefsDictionary: Record<columnName, ColDef> = {
  status: {
    field: "status",
    colId: LIST_PROJECTS_ORDER_BY.STATE,
    headerName: "",
    maxWidth: 50,
    sortable: false,
    cellRenderer: StatusRenderer,
    filter: "agSetColumnFilter",
    filterParams: {
      suppressSelectAll: true,
      suppressMiniFilter: true,
      values: [ProjectState.Active, ProjectState.Archive],
      valueFormatter: ({ value }: { value: ProjectState }) =>
        i18next.t(`projectState:${value}`)
    }
  },
  activeName: {
    field: "name",
    tooltipField: "name",
    colId: LIST_PROJECTS_ORDER_BY.NAME,
    headerName: i18next.t("projectsTable:name"),
    minWidth: 400,
    cellRenderer: ActiveProjectName,
    filter: "agTextColumnFilter",
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ["contains"]
    },
    ...floatingFilter
  },
  draftName: {
    field: "name",
    tooltipField: "name",
    colId: LIST_PROJECTS_ORDER_BY.NAME,
    headerName: i18next.t("projectsTable:name"),
    minWidth: 400,
    cellRenderer: DraftProjectName,
    filter: "agTextColumnFilter",
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ["contains"]
    },
    ...floatingFilter
  },
  classification: {
    field: "classification",
    tooltipValueGetter: ({ data }) =>
      data.classification === ClassificationType.Other &&
      data.classificationDescription
        ? data?.classificationDescription
        : i18next.t(`classificationTypes:${data.classification}`),
    colId: LIST_PROJECTS_ORDER_BY.CLASSIFICATION,
    headerName: i18next.t("projectsTable:classification"),
    minWidth: 136,
    maxWidth: 160,
    filter: "agSetColumnFilter",
    filterParams: {
      values: Object.values(ClassificationType),
      suppressMiniFilter: true,
      valueFormatter: ({ value }: { value: ClassificationType }) =>
        i18next.t(`classificationTypes:${value}`)
    },
    valueGetter: ({ data }) =>
      i18next.t(`classificationTypes:${data.classification}`)
  },
  client: {
    field: "client",
    colId: LIST_PROJECTS_ORDER_BY.CLIENT_NAME,
    tooltipField: "client.name",
    headerName: i18next.t("projectsTable:client"),
    filterValueGetter: ({ data }) => data.client.name,
    minWidth: 120,
    maxWidth: 180,
    cellRenderer: ClientRenderer,
    filter: "agTextColumnFilter",
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ["contains"]
    },
    ...floatingFilter
  },
  inProject: {
    field: "inProject",
    colId: LIST_PROJECTS_ORDER_BY.IN_PROJECT,
    headerName: i18next.t("projectsTable:inProject"),
    sortable: false,
    filter: "agSetColumnFilter",
    filterParams: {
      suppressSelectAll: true,
      suppressFilterSearch: true,
      suppressMiniFilter: true,
      values: [true],
      valueFormatter: () => i18next.t(`projectsTable:onlyMyProjects`)
    },
    cellRenderer: InProjectCellRenderer,
    ...fixedWidth(90)
  },
  created: {
    field: "created",
    colId: LIST_PROJECTS_ORDER_BY.CREATED,
    headerName: i18next.t("projectsTable:created"),
    sort: "desc",
    valueGetter: ({ data }) => formatDate(data.created, "DD MMM YYYY"),
    suppressHeaderMenuButton: true,
    ...fixedWidth(130)
  },
  arbolusContact: {
    field: "arbolusContact",
    colId: LIST_PROJECTS_ORDER_BY.CONTACT,
    tooltipField: "arbolusContact.email",
    headerName: i18next.t("projectsTable:contact"),
    filterValueGetter: ({ data }) =>
      `${data.arbolusContact.name} ${data.arbolusContact.lastName}}`,
    maxWidth: 250,
    cellRenderer: ContactRenderer,
    suppressHeaderMenuButton: true
  },
  links: {
    field: "links",
    headerName: i18next.t("projectsTable:links"),
    filter: false,
    sortable: false,
    cellRenderer: LinksRenderer,
    suppressHeaderMenuButton: true,
    ...fixedWidth(148)
  },
  draftActions: {
    field: "draftActions",
    headerName: "",
    filter: false,
    sortable: false,
    cellRenderer: DraftActionsRenderer,
    suppressHeaderMenuButton: true,
    ...fixedWidth(50)
  }
};

export const useProjectTableColumnDefs = (): ColDef[] => {
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const columnDefs: ColDef[] = React.useMemo(() => {
    if (isAdmin) {
      return [
        ColumnDefsDictionary["status"],
        ColumnDefsDictionary["activeName"],
        ColumnDefsDictionary["classification"],
        ColumnDefsDictionary["client"],
        ColumnDefsDictionary["created"],
        ColumnDefsDictionary["inProject"],
        ColumnDefsDictionary["links"]
      ];
    }

    const statusDef = cloneDeep(ColumnDefsDictionary["status"]);
    statusDef.filterParams.values = [ProjectState.Active, ProjectState.Archive];
    return [
      statusDef,
      ColumnDefsDictionary["activeName"],
      ColumnDefsDictionary["arbolusContact"],
      ColumnDefsDictionary["created"],
      ColumnDefsDictionary["links"]
    ];
  }, [isAdmin]);
  return columnDefs;
};

export const useProjectDraftsTableColumnDefs = (): ColDef[] =>
  useMemo(
    () => [
      ColumnDefsDictionary["draftName"],
      ColumnDefsDictionary["client"],
      ColumnDefsDictionary["created"],
      ColumnDefsDictionary["inProject"],
      ColumnDefsDictionary["draftActions"]
    ],
    []
  );
