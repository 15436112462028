export const SURVEY = "/survey/:surveyId";
export const SURVEY_COMPLIANCE = "/survey/:surveyId/compliance";
export const SURVEY_FEEDBACK = "/survey/:surveyId/feedback";
export const SURVEY_PAYMENT_METHOD = "/payment-method";
export const SURVEYS = "/surveys";
export const SURVEY_LANDING_QUALIFIED = "/qualified";
export const SURVEY_LANDING_NOT_QUALIFIED = "/not-qualified";

export const SURVEY_ROUTE = (surveyId: string): string => `/survey/${surveyId}`;
export const SURVEY_COMPLIANCE_ROUTE = (surveyId: string): string =>
  `/survey/${surveyId}/compliance`;
export const SURVEY_FEEDBACK_ROUTE = (surveyId: string): string =>
  `/survey/${surveyId}/feedback`;

export const SURVEY_ROUTES = [
  SURVEY,
  SURVEY_COMPLIANCE,
  SURVEY_FEEDBACK,
  SURVEY_PAYMENT_METHOD,
  SURVEYS,
  SURVEY_LANDING_QUALIFIED,
  SURVEY_LANDING_NOT_QUALIFIED
];
export const SURVEY_SHARED_ROUTES = [
  SURVEY,
  SURVEY_COMPLIANCE,
  SURVEY_FEEDBACK,
  SURVEY_LANDING_QUALIFIED,
  SURVEY_LANDING_NOT_QUALIFIED
];
