import { differenceBy } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Toaster } from "sonner";

import { CustomerSurveyCompany } from "@arbolus-technologies/models/common";
import { Loader, PageTemplate } from "@arbolus-technologies/ui/components";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { ChooseVendorsStep } from "../../modules/SurveySteps/ChooseVendorsStep/ChooseVendorsStep";
import { DoneStep } from "../../modules/SurveySteps/DoneStep/DoneStep";
import { GiveDetailsStep } from "../../modules/SurveySteps/GiveDetailsStep/GiveDetailsStep";
import { useSurveyTemplate } from "./useSurveyData";

import styles from "./ExpertSurveyPage.module.scss";

enum SurveyStep {
  ChooseVendors,
  GiveDetails,
  Done
}

export const ExpertSurveyPage: React.FC = () => {
  const { t } = useTranslation("expertSurvey");
  useDocumentTitle("expertSurvey", "browserTabTitle");
  const [surveyStep, setSurveyStep] = useState(SurveyStep.ChooseVendors);

  const { surveyId } = useParams<{ surveyId: string }>();

  const surveyTemplate = useSurveyTemplate(surveyId);

  if (surveyTemplate === undefined) return <Loader isFull isCentered />;

  const defaultVendors = surveyTemplate.companies.filter(
    (company) => company.visible === true
  );

  return (
    <PageTemplate>
      {surveyStep === SurveyStep.Done ? null : (
        <div className={styles.header}>
          <h1>{t("pageTitle")}</h1>
          <p>
            {t("pageSubtitle1")}
            <br />
            <b>{t("pageSubtitle2")}</b>
          </p>
        </div>
      )}

      <SurveyPageSteps
        defaultVendors={defaultVendors}
        surveyStep={surveyStep}
        setSurveyStep={setSurveyStep}
      />

      <Toaster visibleToasts={5} />
    </PageTemplate>
  );
};

function SurveyPageSteps({
  defaultVendors,
  surveyStep,
  setSurveyStep
}: {
  defaultVendors: CustomerSurveyCompany[];
  surveyStep: SurveyStep;
  setSurveyStep: (step: SurveyStep) => void;
}) {
  const [vendors, setVendors] =
    useState<CustomerSurveyCompany[]>(defaultVendors);

  if (surveyStep === SurveyStep.ChooseVendors) {
    return (
      <ChooseVendorsStep
        next={() => {
          setSurveyStep(SurveyStep.GiveDetails);
        }}
        vendors={vendors}
        setVendors={setVendors}
      />
    );
  }
  if (surveyStep === SurveyStep.GiveDetails) {
    return (
      <GiveDetailsStep
        next={() => setSurveyStep(SurveyStep.Done)}
        vendors={vendors}
        defaultAlternatives={differenceBy(defaultVendors, vendors, "id")}
      />
    );
  }
  if (surveyStep === SurveyStep.Done) {
    return <DoneStep />;
  }
  return null;
}
