import { ICellRendererParams } from "ag-grid-community";
import { Flex } from "antd";
import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  BookedReferral,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { OpenEventParams } from "@arbolus-technologies/models/project";
import { ReferralGridContext } from "../../Models/ReferralsTable";
import { DefaultFormatDateService } from "../../Services/Referrals/FormatDateService";

export const JoinCallRenderer: React.FC<
  ICellRendererParams<BookedReferral, never, ReferralGridContext>
> = ({ data, context }) => {
  const { t } = useTranslation("referrals");
  const dispatch = useDispatch();

  const handleJoinCall = React.useCallback(() => {
    dispatch(
      PanelStoreActions.openPanel<OpenEventParams>(PanelId.EventPanel, {
        eventId: data!.latestEvent.id
      })
    );
  }, [data, dispatch]);

  if (!data) {
    return null;
  }

  return (
    <Flex gap={8} align="center">
      <Button
        type="tertiary"
        endIcon="chevron_right"
        text={DefaultFormatDateService.formatDayTime(
          data.latestEvent.startTime,
          context.timezone
        )}
        onClick={handleJoinCall}
        disabled={data.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC}
      />
      {(data.pendingGateKeepingTypes ?? []).length > 0 && (
        <AntDIcon
          name="error_filled"
          color={ARBOLUS_COLORS.bColorError}
          tooltip={
            <ul style={{ marginBottom: 0, paddingLeft: 24 }}>
              {data.pendingGateKeepingTypes?.map((type) => (
                <li key={`${type}`}>{t(type)}</li>
              ))}
            </ul>
          }
          tooltipPlacement="left"
        />
      )}
    </Flex>
  );
};
