import { Button, Modal } from "antd";
import dompurify from "dompurify";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { useIsMobile } from "@arbolus-technologies/utils";

import { TrainingTitle } from "./TrainingTitle";
import { useTrainings } from "./useTrainings";

export function ExpertTraining() {
  const { t } = useTranslation("expertTrainingModal");
  const isMobile = useIsMobile();

  const { trainings, acceptTraining } = useTrainings();

  const [currentStep, setCurrentStep] = useState(0);
  function nextStep() {
    acceptTraining(trainings[currentStep].id).then(() => {
      setCurrentStep((prev) => prev + 1);
    });
  }

  if (trainings.length === 0) return null;
  if (currentStep >= trainings.length) return null;

  const { title, text, downloadUrl } = trainings[currentStep];

  return (
    <Modal
      title={
        <TrainingTitle
          title={title}
          current={currentStep}
          total={trainings.length}
          pending={trainings.length - currentStep}
        />
      }
      width={720}
      style={isMobile ? { top: 0 } : undefined}
      footer={[
        <Button
          key="download"
          onClick={() => window.open(downloadUrl, "_blank")}
          icon={<AntDIcon name="file_download" />}
          iconPosition="end"
          type="link"
        >
          {t("downloadMaterial")}
        </Button>,
        <Button key="nextStep" onClick={nextStep} type="primary">
          {t("ok")}
        </Button>
      ]}
      open
      closable={false}
      destroyOnClose={true}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(text)
        }}
      />
    </Modal>
  );
}
