import { Observable } from "rxjs";

import {
  DNC_CATEGORY_ENUM,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";

import { MAX_PAGE_SIZE, PROJECT_EXPERTS_API } from "../constants/api";
import { ADMIN_API } from "../endpoints/admin";
import { EXPERTS_API } from "../endpoints/experts";
import { SORT_DIRECTION } from "../enums/apiEnums";
import {
  ApiNonPaginatedResponse,
  CreatedResponse,
  ListResponse,
  PagedListResponse,
  SuccessResponse
} from "../models/api";
import {
  CandidateProject,
  Compliance,
  CreateBaseExpert,
  DeleteExpertResponse,
  Expert,
  ExpertCandidateProjectsRequest,
  ExpertCandidateProjectsResponse,
  ExpertDetail,
  ExpertEngagementStatus,
  ExpertInviteLink,
  ExpertListProject,
  ExpertRates,
  ExpertTrainingModel,
  ExpertUser,
  InternalCommentsResponse,
  InternalExpertResponse,
  NewInternalComment,
  ProjectNote,
  RateCard,
  ScreeningQuestion,
  UpdateAnswerListItem,
  UpdateComplianceAnswer,
  WorkHistory,
  WorkHistoryUpdate
} from "../models/expert";
import {
  ExpertApplicationProject,
  ProjectAnswersResponse,
  ProjectExpertAvailability,
  Slot
} from "../models/projects";
import { restService } from "../restService";

export const ExpertService = {
  getExpertWorkHistory: (
    expertId: string
  ): Observable<ApiNonPaginatedResponse<WorkHistory>> =>
    restService.get(EXPERTS_API.GET_WORK_HISTORY(expertId)),

  createExpert: (expert: CreateBaseExpert): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(EXPERTS_API.CREATE_EXPERT(), expert),

  getExperts: (
    projectId: "",
    Offset = 0,
    SearchTerm = "",
    OrderBy = "",
    OrderDirection: string = SORT_DIRECTION.ASCENDING,
    Limit: number = MAX_PAGE_SIZE
  ): Observable<PagedListResponse<Expert>> =>
    restService.get<PagedListResponse<Expert>>(
      EXPERTS_API.GET_EXPERT(projectId),
      {
        Offset,
        SearchTerm,
        OrderBy,
        OrderDirection,
        Limit
      }
    ),

  getExpertsList: (
    Offset = 0,
    SearchTerm = "",
    OrderBy = "",
    ExperienceLevel = "",
    Limit: number = MAX_PAGE_SIZE,
    includeReferralCount = false,
    OrderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<PagedListResponse<ExpertUser>> =>
    restService.get<PagedListResponse<ExpertUser>>(EXPERTS_API.GET_EXPERTS(), {
      Offset,
      SearchTerm,
      OrderBy,
      OrderDirection,
      ExperienceLevel,
      Limit,
      includeReferralCount
    }),

  getExpert: (
    expertId: string,
    clientId?: string,
    projectId?: string,
    includeRateCard?: boolean
  ): Observable<ExpertDetail> =>
    restService.get<ExpertDetail>(EXPERTS_API.GET_EXPERT(expertId), {
      clientId,
      projectId,
      includeRateCard
    }),

  getExpertById: ({
    expertId,
    clientId,
    projectId,
    includeRateCard = false
  }: {
    expertId: string;
    clientId?: string;
    projectId?: string;
    includeRateCard?: boolean;
  }): Observable<ExpertDetail> =>
    restService.get<ExpertDetail>(EXPERTS_API.GET_EXPERT(expertId), {
      clientId,
      projectId,
      includeRateCard
    }),

  getExpertEngagementStatus: (
    expertId: string
  ): Observable<ExpertEngagementStatus> =>
    restService.get<ExpertEngagementStatus>(
      EXPERTS_API.GET_EXPERT_ENGAGEMENT_REQUEST(expertId)
    ),

  getExpertRates: (
    expertId: string,
    clientId?: string,
    projectId?: string
  ): Observable<ExpertRates> =>
    restService.get<ExpertRates>(EXPERTS_API.GET_EXPERT_RATES(expertId), {
      clientId,
      projectId
    }),

  getExpertProjects: (
    expertId: string,
    referralStatus = ""
  ): Observable<ListResponse<ExpertListProject>> =>
    restService.get<ListResponse<ExpertListProject>>(
      EXPERTS_API.GET_EXPERT_PROJECTS(expertId),
      { referralStatus }
    ),

  getFrequentCandidateProjects: (
    expertId: string,
    apiParams: ExpertCandidateProjectsRequest
  ): Observable<ExpertCandidateProjectsResponse<CandidateProject>> =>
    restService.get<ExpertCandidateProjectsResponse<CandidateProject>>(
      EXPERTS_API.GET_CANDIDATE_PROJECTS(expertId),
      apiParams
    ),

  getCandidateProjects: (
    expertId: string,
    apiParams: ExpertCandidateProjectsRequest
  ): Observable<ExpertCandidateProjectsResponse<CandidateProject>> =>
    restService.get<ExpertCandidateProjectsResponse<CandidateProject>>(
      EXPERTS_API.GET_CANDIDATE_PROJECTS(expertId),
      apiParams
    ),

  updateExpertProfile: (
    expertId: string,
    overview: string,
    experienceLevel: string,
    quickFacts: string[],
    linkedinProfile?: string,
    funFact?: string,
    background?: string,
    preferredContactMethod?: string,
    hasMinimumCallTimeRate?: boolean,
    isVerified?: boolean
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(EXPERTS_API.UPDATE_EXPERT(expertId), {
      overview,
      background,
      experienceLevel,
      linkedinProfile,
      quickFacts,
      funFact,
      preferredContactMethod,
      hasMinimumCallTimeRate,
      isVerified
    }),

  updateExpertIsVerified: (
    expertId: string,
    isVerified?: boolean
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(EXPERTS_API.UPDATE_EXPERT(expertId), {
      isVerified
    }),

  updateExpertRateCard: (
    expertId: string,
    { id, ...rateCardValues }: RateCard
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      EXPERTS_API.UPDATE_RATE_CARD(expertId, id),
      {
        ...rateCardValues
      }
    ),

  updateExpertStarred: (
    expertId: string,
    isStarred: boolean
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(EXPERTS_API.UPDATE_STARRED(expertId), {
      isStarred
    }),

  addExpertRateCard: (
    expertId: string,
    { id, ...values }: RateCard
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      EXPERTS_API.SAVE_RATE_CARD(expertId),
      values
    ),

  updateExpertWorkHistory: (
    expertId: string,
    workHistory: WorkHistoryUpdate[]
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      EXPERTS_API.UPDATE_WORK_HISTORY(expertId),
      { workHistory }
    ),

  updateExpertCountries: (
    expertId: string,
    threeLetterCodes: string[]
  ): Observable<CreatedResponse> =>
    restService.post(EXPERTS_API.UPDATE_EXPERT_COUNTRIES(expertId), {
      threeLetterCodes
    }),

  updateExpertIndustries: (
    expertId: string,
    industries: string[]
  ): Observable<CreatedResponse> =>
    restService.post(EXPERTS_API.UPDATE_EXPERT_INDUSTRIES(expertId), {
      industries
    }),

  updateExpertCompanies: (
    expertId: string,
    companyIds: string[]
  ): Observable<CreatedResponse> =>
    restService.post(EXPERTS_API.UPDATE_EXPERT_COMPANIES(expertId), {
      companyIds
    }),

  expertReinvite: (expertId: string): Observable<SuccessResponse> =>
    restService.post(EXPERTS_API.REINVITE(expertId), {}),

  getExpertInviteLink: (expertId: string): Observable<ExpertInviteLink> =>
    restService.get(EXPERTS_API.GET_INVITE_LINK(expertId)),

  getAnswers: (
    expertId: string,
    projectId: string
  ): Observable<ProjectAnswersResponse> =>
    restService.get(EXPERTS_API.GET_PROJECT_ANSWERS_V2(expertId), {
      expertId,
      projectId
    }),

  getComplianceAnswers: (
    expertId: string,
    projectId: string
  ): Observable<ApiNonPaginatedResponse<Compliance>> =>
    restService.get(EXPERTS_API.GET_COMPLIANCE_ANSWERS(expertId), {
      projectId
    }),

  updateComplianceAnswers: (
    expertId: string,
    projectId: string,
    answers: UpdateComplianceAnswer[]
  ): Observable<ApiNonPaginatedResponse<Compliance>> =>
    restService.post(EXPERTS_API.UPDATE_COMPLIANCE_ANSWERS(expertId), {
      expertId,
      projectId,
      answers
    }),

  updateAnswers: (
    expertId: string,
    projectId: string,
    answers: UpdateAnswerListItem[]
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      EXPERTS_API.UPDATE_PROJECT_ANSWERS(expertId),
      {
        projectId,
        answers
      }
    ),

  deleteExpert: (
    userId: string,
    action = "Delete"
  ): Observable<DeleteExpertResponse> =>
    restService.delete<DeleteExpertResponse>(ADMIN_API.DELETE_EXPERT(userId), {
      action
    }),

  getProjectExpertAvailability: (
    projectId: string,
    expertId: string
  ): Observable<ProjectExpertAvailability> =>
    restService.get(
      PROJECT_EXPERTS_API.GET_PROJECT_EXPERT_AVAILABILITY(projectId, expertId)
    ),

  addExpertAvailability: (
    projectId: string,
    expertId: string,
    expertAvailabilitySlots: Slot[],
    expertTimezone: string
  ): Observable<SuccessResponse> =>
    restService.post(
      PROJECT_EXPERTS_API.ADD_PROJECT_EXPERT_AVAILABILITY(projectId, expertId),
      {
        expertAvailabilitySlots,
        expertTimezone
      }
    ),

  requestExpertAvailability: (
    projectId: string,
    expertId: string
  ): Observable<SuccessResponse> =>
    restService.post(
      PROJECT_EXPERTS_API.REQUEST_PROJECT_EXPERT_AVAILABILITY(
        projectId,
        expertId
      ),
      {
        sourcePhoneCountry: "US",
        availabilityCalendarUrl: `${process.env.NX_PUBLIC_BASE_URL}/project/${projectId}/availability?requireAdditionalTimeSlots=true`
      }
    ),

  getInternalComments: (
    expertId: string
  ): Observable<InternalCommentsResponse> =>
    restService.get(EXPERTS_API.GET_INTERNAL_COMMENTS(expertId)),

  addInternalNewComment: (
    expertId: string,
    data: NewInternalComment
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      EXPERTS_API.ADD_INTERNAL_COMMENTS(expertId),
      data
    ),

  updateInternalComment: (
    expertCommentId: string,
    data: NewInternalComment
  ): Observable<SuccessResponse> =>
    restService.patch<SuccessResponse>(
      EXPERTS_API.UPDATE_INTERNAL_COMMENT(expertCommentId),
      data
    ),

  deleteInternalComment: (
    expertCommentId: string
  ): Observable<SuccessResponse> =>
    restService.delete<SuccessResponse>(
      EXPERTS_API.DELETE_INTERNAL_COMMENT(expertCommentId)
    ),

  getScreeningQuestions: (
    expertId: string,
    searchTerms?: string[]
  ): Observable<ListResponse<ScreeningQuestion>> =>
    restService.post(EXPERTS_API.GET_SCREENING_QUESTIONS(expertId), {
      searchTerms
    }),

  getExpertSharedProjects: (
    expertId: string,
    projectStates: string[]
  ): Observable<ListResponse<ExpertApplicationProject>> =>
    restService.get(EXPERTS_API.GET_EXPERT_SHARED_PROJECTS(expertId), {
      projectStates
    }),

  getProjectNotes: (
    expertId: string,
    searchTerms?: string[]
  ): Observable<ListResponse<ProjectNote>> =>
    restService.post(EXPERTS_API.GET_PROJECT_NOTES(expertId), { searchTerms }),

  getExpertTraining: (): Observable<{
    expertTraining: ExpertTrainingModel | null;
  }> => restService.get(EXPERTS_API.GET_EXPERT_TRAINING()),

  acknowledgeExpertTraining: (
    expertTrainingId: string
  ): Observable<SuccessResponse> =>
    restService.post(
      EXPERTS_API.ACKNOWLEDGE_EXPERT_TRAINING(expertTrainingId),
      {}
    ),

  requestEndorsement: (
    expertEndorsers: { name: string; email: string }[],
    userId: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(EXPERTS_API.REQUEST_ENDORSEMENT(userId), {
      expertEndorsers
    }),

  addProjectAnswers: (
    expertId: string,
    answers: {
      projectId: string;
      answers: {
        questionId: string;
        answer?: string;
      }[];
    }
  ): Observable<SuccessResponse> =>
    restService.post(EXPERTS_API.ADD_PROJECT_ANSWER(expertId), answers),

  addComplianceAnswers: (
    expertId: string,
    answers: {
      projectId: string;
      answers: {
        questionId: string;
        answer: string;
        textAnswer?: string;
      }[];
    }
  ): Observable<SuccessResponse> =>
    restService.post(EXPERTS_API.ADD_COMPLIANCE_ANSWERS(expertId), answers),

  updateExpertsSettings: (
    expertId: string,
    doNotContactStatus: DO_NOT_CONTACT_STATUS,
    doNotContactCategory?: DNC_CATEGORY_ENUM | null,
    doNotContactStatusDescription?: string
  ): Observable<CreatedResponse> =>
    restService.put<CreatedResponse>(
      EXPERTS_API.UPDATE_EXPERTS_SETTINGS(expertId),
      {
        doNotContactStatus,
        doNotContactCategory,
        doNotContactStatusDescription
      }
    ),

  updateExpertsDNCNote: (
    dncHistoryId: string,
    description: string
  ): Observable<CreatedResponse> =>
    restService.patch<CreatedResponse>(
      EXPERTS_API.UPDATE_EXPERTS_DNC_HISTORY_NOTE(dncHistoryId),
      { description }
    ),

  checkInternalByEmail: (
    email: string,
    phoneNumber?: string
  ): Observable<InternalExpertResponse> =>
    restService.get<InternalExpertResponse>(
      EXPERTS_API.CHECK_INTERNAL_BY_EMAIL(email),
      { phoneNumber }
    ),

  deactivateExpert: (expertId: string): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      EXPERTS_API.DEACTIVATE_EXPERT(expertId),
      {}
    ),

  reactivateExpert: (expertId: string): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      EXPERTS_API.REACTIVATE_EXPERT(expertId),
      {}
    )
};
