import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";

import { INBOX_NOTIFICATION_CATEGORY } from "../enums/notificationEnums";
import { LoggedInUserClient, UserClient } from "./client";
import { ProjectNotificationSubscription } from "./projects";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  profileImageUrl?: string;
  isoCountryCode?: string;
  phoneNumber?: string;
  timezone?: string;
  isCiqManager?: boolean;
  clientId?: string;
  linkedinProfile?: string;
}

export interface LoggedInUser extends Omit<User, "firstName" | "lastName"> {
  firstName?: string;
  lastName?: string;
  client?: LoggedInUserClient;
  clientUserRepositoryUrl?: string;
  expertId?: string;
  userRoles: string[];
  culture: string;
  termUpdateRequired: boolean;
  termId: string;
  timezone: string;
  complianceManager: boolean;
  dismissNotificationBanner: boolean;
  defaultNotificationSettings: ProjectNotificationSubscription;
  accessLevel?: string;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  isActive?: boolean;
}

export interface UserPasswordReset {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface UserProfile extends User {
  culture: string;
  userRoles: string[];
  clientId: string;
  client: UserClient;
  timezone: string;
  complianceManager: boolean;
  dismissNotificationBanner: boolean;
  defaultNotificationSettings: UserNotificationSubscription;
}

export interface BasicUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface UserCredentials {
  email: string;
  password: string;
  remember: boolean;
  validateAdminRole?: boolean;
}

export interface UserLoginResponse {
  userId: string;
  name: string;
  userRoles: string[];
}

export interface UserLoginOpenIdResponse {
  url: string;
}

export type FederateLoginResponse = UserLoginResponse;

export interface RegisterUser {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  isoCountryCode?: string;
}

export interface ResetPasswordRequestEmail {
  email: string;
}

export interface ResetPasswordWithToken {
  email: string;
  password: string;
  token: string;
}

export interface Session {
  userId: string;
  ciqManager: boolean;
}

export interface PusherBatchAuthenticateRequest {
  socketId: string;
  channelNames: string[];
}

export interface PusherAuthenticateResponse {
  auth: string;
  channelData: string;
}

export interface PusherBatchAuthenticateResponse {
  authData: {
    [channelName: string]: PusherAuthenticateResponse;
  };
}

export interface BaseClientMember extends User {
  emailConfirmed: boolean;
  accessLevel?: string;
  clientUserProfile?: {
    priorities: string;
    contactingPreferences: string;
    general: string;
    relationshipOwnerId: string;
    relationshipOwner: User | null;
    displayName?: string;
    isRealNameEnabled: boolean;
    industryId: string;
    repositoryUrl?: string;
  };
  ciqManager?: boolean;
  country: {
    id: string;
    name: string;
    city: {
      id: string;
      name: string;
    };
  };
}

export interface ListClientMember extends User {
  emailConfirmed: boolean;
  accessLevel: string;
  activeProjectCount: number;
}

export interface ClientUser extends BaseClientMember {
  activeProjectCount: number;
}

export interface CIQUser extends User {
  emailConfirmed: boolean;
  activeProjectCount: number;
}

export interface UserNotificationSettingsUpdateRequest {
  dismissNotificationBanner?: boolean;
  notificationSubscription?: UserNotificationSubscription;
}

export interface UserNotificationSubscription {
  Messages: boolean;
  NewJoins: boolean;
  Approvals: boolean;
  Compliance: boolean;
  SharedDocs: boolean;
  ThingsTodo: boolean;
  Applications: boolean;
  EventInvites: boolean;
  NewReferrals: boolean;
  MobileMessage: boolean;
}

export interface UserNotificationSettings {
  dismissNotificationBanner: boolean;
  notificationSubscription: UserNotificationSubscription;
}

export interface UserEntryRequest {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  title: string;
  timezone: string;
  linkedinProfile?: string;
}

export interface CreateUserRequest extends UserEntryRequest {
  email: string;
  clientUserProfile?: {
    relationshipOwnerId?: string | null;
    priorities?: string | null;
    contactingPreferences?: string | null;
    general?: string | null;
    isRealNameEnabled: boolean;
    displayName?: string | null;
    industryId: string;
  };
  countryId?: string;
  cityId?: string;
}

export interface UpdateUserRequest extends UserEntryRequest {
  countryId?: string;
  cityId?: string;
}

export interface CreateUserResponse {
  id: string;
}

export interface UpdateUserResponse {
  message: string;
  code: string;
}

export interface UserNotificationCounts {
  NewReferrals: number;
  EventInvites: number;
  ThingsTodo: number;
  Approvals: number;
  Messages: number;
  Undefined: number;
  SharedDocs: number;
  NewJoins: number;
  Applications: number;
  [key: string]: number;
}

export interface UserNotificationCountsResponse {
  notificationCounts: UserNotificationCounts;
}

export interface NotificationsGetRequest {
  offset: number;
  limit: number;
  notificationType: INBOX_NOTIFICATION_CATEGORY[];
  adminNotification: boolean;
  projectGuid?: string | null;
  seen?: boolean;
}

export interface ProjectWithNotifications {
  id: string;
  name: string;
}

export interface UpdatedUserProfileResponse {
  expertId: string;
  userId: string;
  isDuplicated: boolean;
}

export interface UpdateUserPayload {
  firstName?: string;
  lastName?: string;
  title?: string;
  isoCountryCode?: string;
  phoneNumber?: string;
  timezone?: string;
  email?: string;
  linkedinProfile?: string;
}

export interface RegisterRequest {
  token?: string;
  email?: string;
  recaptchaToken: string;
  invitationCode?: string;
}

export interface Terms {
  id: string;
  text: string;
  created: Date;
}

enum REFERRER_TYPE {
  EXPERT = "Expert"
}

enum USER_INVITATION_TYPE {
  PROJECT = "Project",
  NETWORK = "Network",
  CLIENT_TEAM = "ClientTeam"
}

export interface Invitation {
  invitationType: USER_INVITATION_TYPE;
  clientName?: string;
  referrerType: REFERRER_TYPE;
  referrerName: string;
}

export interface UserRegisterResponse {
  terms: Terms;
  accountType?: string;
  user?: User;
  client?: {
    id: string;
    name: string;
    logoUrl: string;
    subdomain: string;
  };
  invitation?: Invitation;
}

export interface LinkedInRegisterRequest {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  // Terms & conditions
  termId: string;
}
