import { Button, Card, Col, Flex, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";

import { UploadProfileImage } from "@arbolus-technologies/antDComponents";
import {
  BaseClientMember,
  CityResponse,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  CitySelector,
  CountrySelector,
  TimezoneSelector
} from "@arbolus-technologies/features/common";
import {
  SelectOption,
  TimezoneOption
} from "@arbolus-technologies/models/common";
import {
  INPUT_DEBOUNCE,
  LINKEDIN_URL_REGEX,
  UserConstraints
} from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

import styles from "./AddEditUser.module.scss";

export const ADD_ON_BEFORE = "https://"; // Part of the URL (protocol)

export interface UserForm {
  firstName: string;
  lastName: string;
  profileImageUrl: File;
  title: string;
  country: SelectOption;
  city: CityResponse;
  timezone: string;
  linkedinProfile?: string;
  email: string;
  phoneNumber: string;
}

interface AddEditUserFormProps {
  userDetails?: BaseClientMember;
  isSaving: boolean;
  onSubmit: (data: UserForm) => void;
  notificationService?: ToasterService;
}

export const AddEditUserForm: React.FC<AddEditUserFormProps> = ({
  userDetails,
  isSaving = false,
  onSubmit,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("addEditUserForm");

  const [form] = Form.useForm();
  const {
    firstName,
    lastName,
    profileImageUrl,
    country,
    city,
    timezone,
    phoneNumber
  } = Form.useWatch([], form) ?? {};

  const [imageUrl, setImageUrl] = useState<string>();

  const handleCountryChange = (country?: SelectOption) => {
    form.setFieldsValue({ ["country"]: country });
  };

  const handleCityChange = (city?: CityResponse) => {
    form.setFieldsValue({ ["city"]: city });
  };

  const handleTimezoneChange = (timezone: TimezoneOption) => {
    form.setFieldsValue({ ["timezone"]: timezone.value });
  };

  const onFinishFailed = () => {
    notificationService.showError(t("invalidForm"));
  };

  return (
    <Row>
      <Col span={16}>
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          initialValues={{
            ...userDetails,
            phoneNumber: userDetails?.phoneNumber?.split("+")[1],
            linkedinProfile: userDetails?.linkedinProfile?.replace(
              ADD_ON_BEFORE,
              ""
            ),
            country: userDetails?.country
              ? {
                  label: userDetails.country.name,
                  value: userDetails.country.id
                }
              : null,
            city: userDetails?.country ? userDetails.country.city : null
          }}
          className={styles.form}
          onFinish={onSubmit}
          onFinishFailed={onFinishFailed}
          disabled={isSaving}
        >
          <Card>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              name="profileImageUrl"
              className={styles.formItem}
            >
              <UploadProfileImage
                expert={{
                  firstName,
                  lastName,
                  profileImageUrl
                }}
                handleUploadSelectedImage={(userImage: File): void => {
                  setImageUrl(URL.createObjectURL(userImage));
                  form.setFieldsValue({ ["profileImageUrl"]: userImage });
                }}
                defaultImageUrl={imageUrl}
              />
            </Form.Item>
            <Flex gap={24}>
              <Form.Item
                validateDebounce={INPUT_DEBOUNCE}
                label={t("name")}
                name="firstName"
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("nameRequired")
                  },
                  {
                    max: UserConstraints.MAX_FIRST_NAME_LENGTH,
                    message: t("nameMaxLength", {
                      length: UserConstraints.MAX_FIRST_NAME_LENGTH
                    })
                  }
                ]}
              >
                <Input placeholder={t("name")} />
              </Form.Item>
              <Form.Item
                validateDebounce={INPUT_DEBOUNCE}
                label={t("surName")}
                name="lastName"
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("surNameRequired")
                  },
                  {
                    max: UserConstraints.MAX_LAST_NAME_LENGTH,
                    message: t("surNameMaxLength", {
                      length: UserConstraints.MAX_LAST_NAME_LENGTH
                    })
                  }
                ]}
              >
                <Input placeholder={t("surName")} />
              </Form.Item>
            </Flex>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("jobTitle")}
              name="title"
              rules={[
                {
                  required: true,
                  message: t("jobTitleRequired")
                },
                {
                  max: UserConstraints.MAX_TITLE_LENGTH,
                  message: t("jobTitleMaxLength", {
                    length: UserConstraints.MAX_TITLE_LENGTH
                  })
                }
              ]}
            >
              <Input placeholder={t("jobTitle")} />
            </Form.Item>
            <Flex gap={24}>
              <Form.Item
                validateDebounce={INPUT_DEBOUNCE}
                label={t("country")}
                name="country"
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("countryRequired")
                  }
                ]}
              >
                <CountrySelector
                  selectedCountry={country}
                  onChange={handleCountryChange}
                  disabled={isSaving}
                />
              </Form.Item>
              <Form.Item
                validateDebounce={INPUT_DEBOUNCE}
                label={t("city")}
                name="city"
                className={styles.formItem}
              >
                <CitySelector
                  countryId={country?.value}
                  selectedCity={city}
                  onChange={handleCityChange}
                  disabled={isSaving}
                />
              </Form.Item>
            </Flex>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("timezone")}
              name="timezone"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: t("timezoneRequired")
                }
              ]}
            >
              <TimezoneSelector
                selectedTimezone={timezone}
                onChange={handleTimezoneChange}
              />
            </Form.Item>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("linkedinUrl")}
              name="linkedinProfile"
              rules={[
                () => ({
                  validator(_, value) {
                    if (
                      !value ||
                      `${ADD_ON_BEFORE}${value}`.match(LINKEDIN_URL_REGEX)
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(t("invalidLinkedinUrl")));
                  }
                })
              ]}
            >
              <Input
                placeholder={t("linkedinUrl")}
                addonBefore={ADD_ON_BEFORE}
              />
            </Form.Item>
            <Flex gap={24}>
              <Form.Item
                validateDebounce={INPUT_DEBOUNCE}
                label={t("email")}
                name="email"
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("emailRequired")
                  },
                  {
                    type: "email",
                    message: t("invalidEmail")
                  },
                  {
                    max: UserConstraints.MAX_EMAIL_LENGTH,
                    message: t("maxEmailLength", {
                      length: UserConstraints.MAX_EMAIL_LENGTH
                    })
                  }
                ]}
              >
                <Input
                  placeholder={t("emailPlaceholder")}
                  disabled={!!userDetails?.id || isSaving}
                />
              </Form.Item>
              <Form.Item
                label={t("phoneNumber")}
                name="phoneNumber"
                className={styles.formItem}
                rules={[
                  () => ({
                    validator(_, value) {
                      const isValid = value
                        ? utilService.validateContactNumber(`+${value}`)
                        : true;
                      if (isValid) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error(t("invalidPhoneNumber")));
                    }
                  })
                ]}
              >
                <PhoneInput
                  inputProps={{
                    name: "phoneNumber",
                    autoComplete: "off"
                  }}
                  value={phoneNumber ?? ""}
                  placeholder={t("phoneNumberPlaceholder")}
                  onChange={(value) => {
                    form.setFieldValue("phoneNumber", value ?? "");
                  }}
                  enableSearch
                  country="us"
                  disableSearchIcon
                  prefix=""
                  disabled={isSaving}
                />
              </Form.Item>
            </Flex>

            <Button type="primary" htmlType="submit" disabled={isSaving}>
              {userDetails ? t("updateUser") : t("createNewUser")}
            </Button>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};
