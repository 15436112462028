// Environment Variables
export const GOOGLE_TAG_MANAGER_ID =
  process.env.NX_PUBLIC_GOOGLE_TAG_MANAGER_KEY;
export const GOOGLE_TAG_MANAGER_AUTH =
  process.env.NX_PUBLIC_GOOGLE_TAG_MANAGER_AUTH;
export const GOOGLE_TAG_MANAGER_PREVIEW =
  process.env.NX_PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW;
export const INSPECTLET_WEB_ID = process.env.NX_PUBLIC_INSPECTLET_ID;
export const GOOGLE_ANALYTICS_ID = process.env.NX_PUBLIC_GOOGLE_ANALYTICS_KEY;
export const MIXPANEL_PROJECT_ID = process.env.NX_PUBLIC_MIXPANEL_PROJECT_ID;

// Content panels
export const CONTENT_PANEL = {
  COUNTRY_PICKER: "COUNTRY_PICKER",
  INDUSTRY_PICKER: "INDUSTRY_PICKER",
  DOCUMENT_VIEWER: "DOCUMENT_VIEWER",
  EVENT_VIEWER: "EVENT_VIEWER",
  EXPERT_PROFILE_VIEWER: "EXPERT_PROFILE_VIEWER",
  CANDIDATE_PICKER: "CANDIDATE_PICKER",
  FILE_PICKER: "FILE_PICKER",
  EVENT_GUEST_INVITE: "EVENT_GUEST_INVITE",
  PROJECT_BRIEF: "PROJECT_BRIEF",
  BILLING_USER_PICKER: "BILLING_USER_PICKER",
  TEAM_MANAGER: "TEAM_MANAGER",
  COMPANY_PICKER: "COMPANY_PICKER",
  PROJECTS_ASSIGN: "PROJECTS_ASSIGN",
  REFER_EXPERT: "REFER_EXPERT",
  PROJECT_BASE_NEW_EXPERT: "PROJECT_BASE_NEW_EXPERT",
  VIEW_AVAILABILITY_PANEL: "VIEW_AVAILABILITY_PANEL",
  USERS_PROFILE_PANEL: "USERS_PROFILE_PANEL",
  PROJECT_NOTIFICATION_SETTINGS: "PROJECT_NOTIFICATION_SETTINGS",
  CLIENT_TEAM_INVITE: "CLIENT_TEAM_INVITE",
  WORK_WITH_ME: "WORK_WITH_ME",
  EXPERT_CONFIRMATION_PANEL: "EXPERT_CONFIRMATION_PANEL",
  CREATE_CANOPY: "CREATE_CANOPY",
  EDIT_CANOPY: "EDIT_CANOPY"
};

// User roles
export const APP_USER_ROLES = {
  guest: "Guest",
  expert: "Expert",
  admin: "Admin",
  client: "Manager",
  adminClient: "AdminUser",
  CIQManager: "CIQManager"
};

// LocalStorage Keys
export const LOCALSTORAGE = {
  CULTURE: "ciq_user_culture",
  APP_VERSION: "ciq_app_version",
  LAST_LOGIN: `ciq_last_login`
};

export enum LISTING_TYPES {
  CANOPIES = "canopies",
  PROJECTS = "projects",
  EVENTS = "events",
  DOCUMENTS = "documents",
  USERS = "users",
  EXPERT_APPROVED = "expert-approved",
  EXPERT_CANDIDATE = "expert-candidate",
  EXPERT_REJECTED = "expert-rejected",
  CLIENT_NETWORK = "client-network",
  FIND_EXPERTS = "find-experts",
  INBOX_THINGS_TO_DO = "inbox-things-do",
  INBOX_MESSAGES = "inbox-messages",
  INBOX_EVENT_INVITES = "inbox-event-invites",
  INBOX_SHARED_DOCS = "inbox-shared-docs",
  INBOX_NEW_JOINS = "inbox-new-joins",
  INBOX_NEW_REFERRALS = "inbox-new-referrals",
  INBOX_APPROVALS = "inbox-approvals",
  INBOX_APPLICATIONS = "inbox-applications",
  INBOX_COMPLIANCE = "inbox-compliance"
}

export enum REDIRECT_STATES {
  IDLE = "IDLE",
  AUTH_SUBDOMAIN_SWITCH = "AUTH_SUBDOMAIN_SWITCH",
  GUEST_SUBDOMAIN_SWITCH = "GUEST_SUBDOMAIN_SWITCH"
}

export const APP_DEFAULT_TIMEZONE = "UTC";
