import { LocationDescriptorObject } from "history";

export const CLIENT_BASE_ROUTE = "/client";

export const NEW_CLIENT_CREATED = "/new-client-created";
export const CLIENT_SETTINGS_DETAILS = `${CLIENT_BASE_ROUTE}/:clientId/details`;
export const CLIENT_SETTINGS_COMPLIANCE = `${CLIENT_BASE_ROUTE}/:clientId/compliance`;
export const CLIENT_SETTINGS_COMPLIANCE_COMMS = `${CLIENT_BASE_ROUTE}/:clientId/compliance-comms`;
export const CLIENT_SETTINGS_GATEKEEPING = `${CLIENT_BASE_ROUTE}/:clientId/gatekeeping`;
export const CLIENT_SETTINGS_BUSINESS_ENTITIES = `${CLIENT_BASE_ROUTE}/:clientId/business-entities`;

export const CLIENT_PAGE_ROUTE = (
  clientId: string
): LocationDescriptorObject => ({
  pathname: `/client/${clientId}`
});

export const CLIENT_SETTINGS_DETAILS_ROUTE = (
  clientId: string
): LocationDescriptorObject => ({
  pathname: `/client/${clientId}/details`
});

export const CLIENT_SETTINGS_BUSINESS_ENTITIES_ROUTE = (
  clientId: string
): LocationDescriptorObject => ({
  pathname: `/client/${clientId}/business-entities`
});

export const CLIENT_SETTINGS_COMPLIANCE_ROUTE = (
  clientId: string
): LocationDescriptorObject => ({
  pathname: `/client/${clientId}/compliance`
});

export const CLIENT_SETTINGS_COMPLIANCE_COMMS_ROUTE = (
  clientId: string
): LocationDescriptorObject => ({
  pathname: `/client/${clientId}/compliance-comms`
});

export const CLIENT_SETTINGS_GATEKEEPING_ROUTE = (
  clientId: string
): LocationDescriptorObject => ({
  pathname: `/client/${clientId}/gatekeeping`
});

export const CLIENT_DRAFT_PROJECT_ROUTE = (
  clientId: string,
  draftId: string
): LocationDescriptorObject => ({
  pathname: `/client/${clientId}/draft/${draftId}`
});

export const CLIENT_ACCOUNT_EDIT_BUSINESS_ENTITY_ROUTE = (
  clientId: string,
  businessEntityId: string
): LocationDescriptorObject => ({
  pathname: `${CLIENT_BASE_ROUTE}/${clientId}/account/business-entity/${businessEntityId}/details`
});

export const CLIENT_USERS_ROUTE = (clientId: string): string =>
  `${CLIENT_BASE_ROUTE}/${clientId}/users`;

export const CLIENT_EDIT_USER_ROUTE = (
  clientId: string,
  userId: string
): string => `${CLIENT_BASE_ROUTE}/${clientId}/users/${userId}`;
