import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  CIQUser,
  ClientGetRequest,
  ClientService,
  ErrorResponse,
  SORT_DIRECTION,
  ToasterService,
  USER_PAGE_SIZE
} from "@arbolus-technologies/api";
import { CLIENT_EDIT_USER_ROUTE } from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  SEARCH_DEBOUNCE_TIMEOUT,
  useDebounce
} from "@arbolus-technologies/utils";

import { ClientUserSlidePanel } from "../ClientUserSlidePanel/ClientUserSlidePanel";
import { ProjectSlidePanel } from "../ProjectSlidePanel/ProjectSlidePanel";
import { UserTable } from "../UserTable/UserTable";

import styles from "./ClientTeamMembers.module.scss";

export interface ClientTeamMembersProps {
  onSendOnboardingEmail: (userId: string) => void;
  clientId: string;
  clientName: string;
  clientService?: typeof ClientService;
}

const notification = new ToasterService();
export const ClientTeamMembers: React.FC<ClientTeamMembersProps> = ({
  onSendOnboardingEmail,
  clientId,
  clientName,
  clientService = ClientService
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [usersList, setUsersList] = useState<CIQUser[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<CIQUser | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [isProjectsSlidePanelOpen, setIsProjectsSlidePanelOpen] =
    useState<boolean>(false);

  const debouncedValue = useDebounce<string>(
    searchTerm,
    SEARCH_DEBOUNCE_TIMEOUT
  );

  const fetchUsers = useCallback(
    (nextPage = 0) => {
      const nextOffset = nextPage * USER_PAGE_SIZE;
      setCurrentPage(nextPage);

      const apiParams: ClientGetRequest = {
        searchTerm: debouncedValue,
        offset: nextOffset,
        limit: USER_PAGE_SIZE,
        orderBy: "firstName",
        orderDirection: SORT_DIRECTION.ASCENDING
      };

      setIsLoading(true);
      clientService.getTeamMembers(clientId, apiParams).subscribe(
        ({ items, pagination }) => {
          setUsersList(items);
          setTotalCount(pagination.count);
          setIsLoading(false);
        },
        (err: ErrorResponse<CIQError>) => {
          notification.showError(err.message);
          setIsLoading(false);
        }
      );
    },
    [debouncedValue, clientService, clientId]
  );

  useEffect(() => {
    fetchUsers();
  }, [debouncedValue, fetchUsers]);

  const openClientUserSlidePanel = () => {
    dispatch(PanelStoreActions.openPanel(PanelId.ClientProfile));
  };

  const showAssignProjectSlidePanel = (userId: string): void => {
    setSelectedUserId(userId);
    setIsProjectsSlidePanelOpen(true);
  };

  const handleCloseProjectsSlidePanel = (refreshUsers: boolean) => {
    if (refreshUsers) {
      fetchUsers(currentPage);
    }
    setIsProjectsSlidePanelOpen(false);
  };

  const handleUserNameClick = (user: CIQUser) => {
    openClientUserSlidePanel();
    setSelectedUser(user);
  };

  return (
    <div className={styles.clientUsersContainer}>
      <UserTable
        onSendOnboardingEmail={onSendOnboardingEmail}
        onAssignSlidePanel={showAssignProjectSlidePanel}
        onSearch={setSearchTerm}
        onPageChange={fetchUsers}
        onUserNameClick={handleUserNameClick}
        isLoading={isLoading}
        totalCount={totalCount}
        users={usersList}
        isClientTeamMember
      />
      <ProjectSlidePanel
        clientId={clientId}
        userId={selectedUserId}
        handleCloseProjectsSlidePanel={handleCloseProjectsSlidePanel}
        openPanel={isProjectsSlidePanelOpen}
      />
      <ClientUserSlidePanel
        goToClientEditPage={() =>
          history.push(CLIENT_EDIT_USER_ROUTE(clientId, selectedUser?.id!))
        }
        user={selectedUser}
        clientName={clientName}
        clientId={clientId}
        showBackTitle
      />
    </div>
  );
};
