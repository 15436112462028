import {
  MonitoringListService,
  UserMonitoringListModel,
  UserMonitoringListRequest
} from "@arbolus-technologies/api";
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

export class UserMonitoringListDataSource implements IServerSideDatasource {
  private readonly _userId: string;

  constructor(userId: string) {
    this._userId = userId;
  }

  getRows(
    params: IServerSideGetRowsParams<UserMonitoringListModel, never>
  ): void {
    const { success, fail, request } = params;

    const queryParams = this.getQueryParams(request);

    MonitoringListService.getUserCompanySignal({
      ...queryParams,
      userId: this._userId
    })
      .then((response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      })
      .catch(fail);
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): UserMonitoringListRequest {
    const { startRow, endRow } = request;

    const queryParams: UserMonitoringListRequest = {
      limit: endRow! - startRow!,
      offset: startRow!
    };

    return queryParams;
  }
}
