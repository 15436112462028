import { CIQMediaQueryResults } from "../containers/app/components/DeviceMonitor";

export const UI_WINDOW_HEIGHT = "var(--vh100)";

export const APP_DEVICE_MEDIA_QUERIES = {
  small: "(max-width: 768px)",
  medium: "(min-width: 768px) and (max-width: 1023px)",
  large: "(min-width: 1024px)"
};

export const UI_USER_SELECTION_PANEL = {
  PANEL_LIST_HEIGHT: `calc(${UI_WINDOW_HEIGHT} - 138px)`,
  USER_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 48 : 44
};

export const UI_TEAM_MANAGEMENT_PANEL = {
  TEAM_MEMBER_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 48 : 44,
  PANEL_LIST_HEIGHT: `calc(${UI_WINDOW_HEIGHT} - 138px)`
};

export const UI_HOME_PAGE = {
  PROJECT_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 106 : 103,
  CONTAINER_HEIGHT: (
    { large }: CIQMediaQueryResults,
    isClientFromNetwork = false
  ): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${
      (large ? 196 : 120) +
      (isClientFromNetwork
        ? UI_HOME_PAGE.CLIENT_TAG_CONTAINER_HEIGHT({
            large
          } as CIQMediaQueryResults)
        : 0)
    }px)`,
  CLIENT_TAG_CONTAINER_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 72 : 50
};

export const UI_EXPERT_NETWORK_PAGE = {
  EXPERT_ROW_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 244 : 103,
  CONTAINER_HEIGHT: ({ large }: CIQMediaQueryResults): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${large ? 195 : 140}px)`
};

export const UI_CLIENT_NETWORK_PAGE = {
  CLIENT_ROW_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 244 : 103,
  CONTAINER_HEIGHT: ({ large }: CIQMediaQueryResults): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${large ? 195 : 140}px)`
};

export const UI_REVIEWAL_PANEL = {
  PANEL_HEIGHT: `calc(${UI_WINDOW_HEIGHT} - 109px)`
};

export const UI_ATTACHMENT_PANEL = {
  SINGLE_UPLOADING_ELEMENT_HEIGHT: 73.4,
  ATTACHMENT_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 73 : 73,
  PANEL_EXCESS_HEIGHT: 230
};

export const UI_CALENDAR_PAGE = {
  HEADER_HEIGHT: ({ large }: CIQMediaQueryResults): number => (large ? 0 : 48),
  TABS_ELEMENT_HEIGHT_MOBILE: 48,
  CALENDER_EXCESS_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 80 : 104
};

export const UI_FILES_PAGE = {
  HEADER_HEIGHT: ({ large }: CIQMediaQueryResults): number => (large ? 0 : 48),
  SINGLE_UPLOADING_ELEMENT_HEIGHT: 80,
  SINGLE_UPLOADING_ELEMENT_MARGIN_WHILE_UPLOADING: 9,
  CONTAINER_EXCESS_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 196 : 176,
  SINGLE_FILE_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 80 : 80
};

export const UI_USER_PROFILE_PAGE = {
  USER_PROFILE_HEIGHT: ({ large }: CIQMediaQueryResults): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${large ? 80 : 48}px)`
};

export const UI_SETTINGS_PAGE = {
  CONTAINER_HEIGHT: ({ large }: CIQMediaQueryResults): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${large ? 193 : 160}px)`
};

export const UI_INVITE_GUESTS = {
  CONTAINER_HEIGHT: `calc(${UI_WINDOW_HEIGHT} - 138px)`,
  LIST_ITEM_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 48 : 44
};

export const UI_PROJECT_BRIEF = {
  CONTAINER_EXCESS_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 80 : 104,
  TABS_ELEMENT_HEIGHT_MOBILE: 48
};

export const UI_REFER_EXPERT = {
  PROJECT_ACTIONS_EXPAND_HEIGHT: 48
};

export const UI_INBOX_PAGE = {
  CATEGORY_LIST_HEIGHT: ({ large }: CIQMediaQueryResults): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${large ? 94 : 96}px)`,
  NOTIFICATIONS_LIST_HEIGHT: ({ large }: CIQMediaQueryResults): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${large ? 80 : 128}px)`,
  NOTIFICATION_ITEM_HEIGHT: 66
};

export const UI_TRANSCRIPT_PAGE = {
  TOP_CONTAINER_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 80 : 64,
  CONTAINER_HEIGHT: (excessHeight: number): string =>
    `calc(${UI_WINDOW_HEIGHT} -  ${excessHeight}px)`,
  BOTTOM_CONTAINER_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 0 : 110
};
