import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { Route } from "react-router-dom";

const history = createBrowserHistory();

// LOCAL_DEV_SERVER provided by webpack.config.js
// @ts-ignore
if (!LOCAL_DEV_SERVER) {
  Sentry.init({
    environment: process.env.NX_PUBLIC_ENV,
    dsn: process.env.NX_PUBLIC_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration(),
      // Extracts attributes from errors (e.g. throw new Error()) and attaches them to the event.
      // If the error object has a .toJSON() method, the integration will run it to extract  information.
      Sentry.extraErrorDataIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
      Sentry.httpClientIntegration()
    ],

    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing
    tracesSampleRate: 1.0,

    // For connection with the BE (when it also implements Sentry)
    tracePropagationTargets: [],

    // Capture Replay for 0% of all sessions, plus for 25% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.25
  });
}

export const SentryRoute = Sentry.withSentryRouting(Route);
