import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  ExpertsListPageTab,
  ResponseAllExpertsTable
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectExpertsSelector } from "@arbolus-technologies/stores/project-experts-store";
import { Loader } from "@arbolus-technologies/ui/components";

import { ReferralsTable } from "../ComponentsTable/ReferralsTable/ReferralsTable";
import { configAcceptTabColumns } from "../ComponentsTable/constants";
import { NoData } from "./NoData";

interface AcceptListProps {
  hasCompliance: boolean;
  handleClickRow: (expertId: string, referralId: string) => void;
  expertProfilePath?: (expertId: string) => string;
  handleSlidePanelExpertProfile?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
  handleEditTagline?: (
    referralId: string,
    projectId: string,
    tagline: string,
    expertTitle: string
  ) => void;
  handleSlidePanelAvailability?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
  sortStatus?: string;
}

export const AcceptList: React.FC<AcceptListProps> = ({
  hasCompliance,
  handleClickRow,
  expertProfilePath,
  handleSlidePanelExpertProfile,
  handleEditTagline,
  handleSlidePanelAvailability,
  sortStatus
}) => {
  const data = useSelector(ProjectExpertsSelector.allExpertsList());
  const [tableData, setTableData] = useState<ResponseAllExpertsTable[]>([]);
  const [sortValue, setSortValue] = useState<string>("");
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const isAllExpertsListLoading = useSelector(
    ProjectExpertsSelector.isAllExpertsListLoading()
  );

  const threeDotsName: "action" | "moreOptions" = useMemo(
    () => (isAdmin ? "action" : "moreOptions"),
    [isAdmin]
  );

  useEffect(() => {
    setTableData(data || []);
    isAllExpertsListLoading ? setSortValue("") : setSortValue(sortStatus ?? "");
  }, [isAllExpertsListLoading, sortStatus]);

  useEffect(() => {
    const unscheduledResults = tableData.filter(
      (d) => d.callStatus?.toLowerCase() === "unscheduled"
    );
    const callDoneResults = tableData.filter(
      (d) => d.callStatus?.toLowerCase() === "done"
    );
    const scheduledResults = tableData.filter(
      (d) => d.callStatus?.toLowerCase() === "scheduled"
    );

    if (sortValue.toLowerCase() === "done") {
      return setTableData([
        ...callDoneResults,
        ...unscheduledResults,
        ...scheduledResults
      ]);
    } else if (sortValue.toLowerCase() === "scheduled") {
      return setTableData([
        ...scheduledResults,
        ...callDoneResults,
        ...unscheduledResults
      ]);
    } else {
      return setTableData([
        ...unscheduledResults,
        ...scheduledResults,
        ...callDoneResults
      ]);
    }
    // tableData dependency is causing some problems so it's not needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  const tanStackSortFn = (s: string) => {
    setSortValue(s);
  };

  if (isAllExpertsListLoading) {
    return <Loader />;
  }

  return data && data.length > 0 ? (
    <ReferralsTable
      defaultData={tableData}
      columnsOptions={configAcceptTabColumns(
        hasCompliance,
        threeDotsName,
        tanStackSortFn
      )}
      handleClickRow={handleClickRow}
      expertProfilePath={expertProfilePath}
      handleSlidePanelExpertProfile={handleSlidePanelExpertProfile}
      handleEditTagline={handleEditTagline}
      handleSlidePanelAvailability={handleSlidePanelAvailability}
    />
  ) : (
    <NoData tabActive={ExpertsListPageTab.Accept} />
  );
};
