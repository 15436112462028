import React from "react";
import { useDispatch } from "react-redux";

import { ProjectService } from "@arbolus-technologies/api";
import { EXPERT_STATUS } from "@arbolus-technologies/models/expert";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";
import { UserPortrait } from "@arbolus-technologies/ui/components";

import {
  DefaultRejectFeedbackExpert,
  RejectFeedbackExpert
} from "./RejectFeedbackExpert";
import { RejectFeedbackForm } from "./RejectFeedbackForm";
import {
  REJECT_REASON,
  REJECT_TEXT,
  RejectFeedbackFormObject
} from "./RejectFeedbackSchema";

import styles from "./RejectFeedback.module.scss";

export interface RejectFeedbackProps {
  rejectExpert?: RejectFeedbackExpert;
  projectService?: typeof ProjectService;
}

export const RejectFeedback: React.FC<RejectFeedbackProps> = ({
  rejectExpert = DefaultRejectFeedbackExpert
}) => {
  const dispatch = useDispatch();
  const { projectId, referralId, expert } = rejectExpert.data!;

  const sendReject = (feedback?: RejectFeedbackFormObject) => {
    dispatch(
      ProjectExpertsStoreActions.updateReferralStatus(projectId, referralId, {
        review: EXPERT_STATUS.REJECT,
        rejectReason: feedback?.[REJECT_REASON] || undefined,
        rejectText: feedback?.[REJECT_TEXT]
      })
    );

    dispatch(PanelStoreActions.closePanel(PanelId.RejectFeedback));
  };

  return (
    <div className={styles.container}>
      <div className={styles.portrait}>
        <UserPortrait user={expert} avatarSize="bigXSCircle" />
      </div>
      <hr />
      <RejectFeedbackForm submit={sendReject} skip={() => sendReject()} />
    </div>
  );
};
