type BlobCsv = "text/csv";
type BlobXlsx =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
type BlobType = BlobCsv | BlobXlsx;

export const string64toBlob = (base64String: string, type: BlobType): Blob => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], {
    type
  });
};

export const blobTypeExtension =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
