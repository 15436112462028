import { useMemo } from "react";

import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { useCustomerSurveyGridContext } from "../../../modules/CustomerSurveyTable/hooks/useCustomerSurveyGridContext";
import { SurveyAnswersDataSource } from "./SurveyAnswersDataSource";
import {
  COLUMN_DEFS,
  DEFAULT_COL_DEF,
  FIELD_NAMES
} from "./companyDataTableDefinition";

import styles from "../../../styles/CommonCustomerDataTable.module.scss";
import { STATUS_BAR } from "../../../utils/statusBarPanelsTable/statusBar";

export const CompanyDataTable = () => {
  const dataSource = useMemo(() => new SurveyAnswersDataSource(), []);

  const customerSurveyGridContext = useCustomerSurveyGridContext();

  return (
    <>
      <ArbolusGrid
        className={styles.arbolusGridDataTable}
        statusBar={STATUS_BAR}
        getRowId={({ data }) => data.id}
        defaultColDef={DEFAULT_COL_DEF}
        columnDefs={COLUMN_DEFS}
        rowModelType="serverSide"
        serverSideDatasource={dataSource}
        context={customerSurveyGridContext}
        pagination
        paginationAutoPageSize
        cacheBlockSize={200} // good for export
        rowSelection="multiple"
        suppressRowClickSelection
        autoSizeStrategy={{
          type: "fitCellContents",
          colIds: [
            FIELD_NAMES.PERSONA,
            FIELD_NAMES.SPEND,
            FIELD_NAMES.NPS,
            FIELD_NAMES.RENEWAL_INTENT,
            FIELD_NAMES.IMPLEMENTATION_STAGE,
            FIELD_NAMES.ANSWER_STATUS,
            FIELD_NAMES.MODIFIED
          ]
        }}
      />
    </>
  );
};
