import { Flex, Image } from "antd";
import React from "react";

import { ARBOLUS_PAGE } from "@arbolus-technologies/features/common";
import { arbolusLogo } from "@arbolus-technologies/theme";
import { LinkWrapper } from "@arbolus-technologies/ui/components";

import styles from "./SurveyHeader.module.scss";

export const SurveyHeader: React.FC = () => {
  return (
    <Flex align="center" className={styles.header}>
      <LinkWrapper to={ARBOLUS_PAGE} isExternal target="_blank">
        <Image src={arbolusLogo} alt="Arbolus logo" preview={false} />
      </LinkWrapper>
    </Flex>
  );
};
