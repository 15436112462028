// CANOPY CLIENT V2
import { LocationDescriptorObject } from "history";

export const CANOPY_DETAILS = "/canopy/:canopyId/details";
export const CANOPY_QUESTIONS = "/canopy/:canopyId/questions";
export const CANOPY_SINGLE_QUESTION =
  "/canopy/:canopyId/questions/:canopyQuestionId";
export const CANOPY_SINGLE_EXPERT = "/canopy/:canopyId/experts/:expertId";
export const CANOPY_EXPERTS = "/canopy/:canopyId/experts";
export const CANOPY_SUMMARY = "/canopy/:canopyId/summary";
export const CANOPY_MOBILE_MENU = "/canopy/:canopyId/menu";
export const CANOPY_INDIVIDUAL_RESPONSE = "/canopy/:canopyId/answers/:answerId";
export const CANOPY_LIST = "/canopies";
export const CANOPY_PAUSED = "/canopy/paused";
export const CANOPY_APPLICATION_PAGE = "/canopy/:canopyId/application";

export const CANOPY_QUESTION_PAGE_VIDEO =
  "/canopy/:canopyId/question/:questionId/video";
export const CANOPY_QUESTION_PAGE_MULTI =
  "/canopy/:canopyId/question/:questionId/multiple-choice";
export const CANOPY_QUESTION_PAGE_VALUE =
  "/canopy/:canopyId/question/:questionId/value";
export const CANOPY_QUESTION_PAGE_SHORT_TEXT =
  "/canopy/:canopyId/question/:questionId/short-text";
export const CANOPY_QUESTION_PAGE_NPS =
  "/canopy/:canopyId/question/:questionId/nps";

// Canopies
export const EXPERT_CANOPY = "/expert-canopies";

export const CANOPY_ROUTE = (canopyId: string): string => `/canopy/${canopyId}`;
export const CANOPY_DETAILS_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/details`;
export const CANOPY_QUESTIONS_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/questions`;
export const CANOPY_EXPERTS_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/experts`;
export const CANOPY_SUMMARY_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/summary`;
export const CANOPY_MOBILE_MENU_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/menu`;
export const PAUSED_CANOPY_ROUTE = (): string => CANOPY_PAUSED;
export const CANOPY_APPLICATION_PAGE_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/application`;

export const CANOPY_SINGLE_QUESTION_ROUTE = (
  canopyId: string,
  canopyQuestionId: string
): string => `/canopy/${canopyId}/questions/${canopyQuestionId}`;

export const CANOPY_SINGLE_EXPERT_ROUTE = (
  canopyId: string,
  expertId: string
): string => `/canopy/${canopyId}/experts/${expertId}`;

// SHARE INTO A SEPARATED PAGE

export const CANOPY_SINGLE_QUESTION_ROUTE_CLIENT = (
  canopyId: string,
  canopyQuestionId: string
): string => `/canopy/${canopyId}/questions/${canopyQuestionId}`;

export const CANOPY_INDIVIDUAL_RESPONSE_FROM_SUMMARY_ROUTE = (
  canopyId: string,
  answerId: string
): string => `/canopy/${canopyId}/answers/${answerId}?from=summary`;

export const CANOPY_INDIVIDUAL_RESPONSE_FROM_EXPERT_ROUTE = (
  canopyId: string,
  answerId: string
): string =>
  `${process.env.NX_PUBLIC_BASE_URL}/canopy/${canopyId}/answers/${answerId}?from=expert`;

export const CANOPY_INDIVIDUAL_RESPONSE_FROM_QUESTION_ROUTE = (
  canopyId: string,
  answerId: string,
  questionId: string
): string =>
  `${process.env.NX_PUBLIC_BASE_URL}/canopy/${canopyId}/answers/${answerId}?from=question&questionId=${questionId}`;

export const CANOPY_DETAILS_ROUTE_ADMIN = (canopyId: string): string =>
  `${process.env.NX_PUBLIC_ADMIN_BASE_URL}/canopy/${canopyId}/details`;

export const CANOPY_LIST_ROUTE = (): LocationDescriptorObject => ({
  pathname: "/canopies"
});

export const CANOPY_QUESTION_VIDEO_ROUTE = (
  canopyId: string,
  questionId: string
): string => `/canopy/${canopyId}/question/${questionId}/video`;
export const CANOPY_QUESTION_MULTI_ROUTE = (
  canopyId: string,
  questionId: string
): string => `/canopy/${canopyId}/question/${questionId}/multiple-choice`;
export const CANOPY_QUESTION_VALUE_ROUTE = (
  canopyId: string,
  questionId: string
): string => `/canopy/${canopyId}/question/${questionId}/value`;
export const CANOPY_QUESTION_SHORT_TEXT_ROUTE = (
  canopyId: string,
  questionId: string
): string => `/canopy/${canopyId}/question/${questionId}/short-text`;
export const CANOPY_QUESTION_NPS_ROUTE = (
  canopyId: string,
  questionId: string
): string => `/canopy/${canopyId}/question/${questionId}/nps`;

export const CANOPY_SEND_ANSWERS_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/send-answers`;
export const CANOPY_DETAILS_PAGE_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/details`;

export const CLIENT_ACTIVE_CANOPY_QUESTIONS_V2_ROUTES = [
  CANOPY_QUESTIONS,
  CANOPY_SINGLE_QUESTION
];

export const CLIENT_ACTIVE_CANOPY_EXPERTS_V2_ROUTES = [
  CANOPY_EXPERTS,
  CANOPY_SINGLE_EXPERT
];

export const CANOPY_CLIENT_V2 = [
  CANOPY_DETAILS,
  ...CLIENT_ACTIVE_CANOPY_QUESTIONS_V2_ROUTES,
  ...CLIENT_ACTIVE_CANOPY_EXPERTS_V2_ROUTES,
  CANOPY_MOBILE_MENU,
  CANOPY_INDIVIDUAL_RESPONSE,
  CANOPY_LIST
];

export const EXPERT_CANOPY_ROUTES = [
  CANOPY_DETAILS,
  CANOPY_QUESTION_PAGE_VIDEO,
  CANOPY_QUESTION_PAGE_MULTI,
  CANOPY_QUESTION_PAGE_VALUE,
  CANOPY_QUESTION_PAGE_SHORT_TEXT,
  CANOPY_QUESTION_PAGE_NPS
];
