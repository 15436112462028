import clsx from "clsx";
import { useFeature } from "flagged";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import {
  EXTERNAL_SOURCING_ROUTE,
  PROJECT_DISCOVER_ROUTE,
  PROJECT_EXPERTS_ROUTE,
  PROJECT_SHORTLIST_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import styles from "./ProjectExpertManagementLinks.module.scss";

const INTERNAL_SOURCING = {
  title: "internalSourcing",
  route: PROJECT_DISCOVER_ROUTE,
  regex:
    /project\/[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}\/discover/i
};
const EXTERNAL_SOURCING = {
  title: "externalSourcing",
  route: EXTERNAL_SOURCING_ROUTE,
  regex:
    /project\/[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}\/external-sourcing/i
};
const ADMIN_APPLICATION = {
  title: "application",
  route: PROJECT_SHORTLIST_ROUTE,
  regex:
    /project\/[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}\/(shortlist|candidate|scheduling|booked|rejected)/i
};
const CLIENT_APPLICATION = {
  title: "application",
  route: PROJECT_EXPERTS_ROUTE,
  regex:
    /project\/[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}\/experts/i
};

export const ProjectExpertManagementLinks = () => {
  const { t } = useTranslation("projectExpertManagementLinks");
  const externalSourcingFF = useFeature(FEATURE_FLAGS.ExternalSourcing);
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const applicationRoute = isAdmin ? ADMIN_APPLICATION : CLIENT_APPLICATION;
  const LINKS =
    externalSourcingFF && isAdmin
      ? [INTERNAL_SOURCING, EXTERNAL_SOURCING, ADMIN_APPLICATION]
      : [INTERNAL_SOURCING, applicationRoute];

  return (
    <div className={styles.linksContainer}>
      {LINKS.map((link) => (
        <div
          key={link.title}
          onClick={() => history.push(link.route(projectId))}
          className={clsx(styles.link, {
            [styles.active]: link.regex.test(history.location.pathname)
          })}
        >
          {t(link.title)}
        </div>
      ))}
    </div>
  );
};
