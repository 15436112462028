import { UserManagerSettings } from "oidc-client-ts";

import { LINKEDIN_AUTH_CLIENT_ID } from "@arbolus-technologies/utils";
import { BASE_URL } from "@arbolus-technologies/utils";

export const REDIRECT_URI = `${BASE_URL}/api/v1/users/linkedin-authentication`;

export const OPEN_ID_CONFIG: UserManagerSettings = {
  authority: "https://www.linkedin.com/oauth/",
  client_id: LINKEDIN_AUTH_CLIENT_ID,
  response_type: "code", // Use authorization code flow
  response_mode: "query",
  // cspell:disable-next-line
  scope: "openid profile email r_basicprofile",

  // LinkedIn does not fully support standard OIDC
  metadata: {
    authorization_endpoint: "https://www.linkedin.com/oauth/v2/authorization",
    token_endpoint: "https://www.linkedin.com/oauth/v2/accessToken",
    end_session_endpoint: "https://www.linkedin.com/oauth/v2/logout"
  },

  redirect_uri: REDIRECT_URI
};
