import { ReactComponent as AccountBalance } from "@material-symbols/svg-400/sharp/account_balance.svg";
import { ReactComponent as Add } from "@material-symbols/svg-400/sharp/add.svg";
import { ReactComponent as Apartment } from "@material-symbols/svg-400/sharp/apartment.svg";
import { ReactComponent as Apps } from "@material-symbols/svg-400/sharp/apps.svg";
import { ReactComponent as Assignment } from "@material-symbols/svg-400/sharp/assignment.svg";
import { ReactComponent as AttachFile } from "@material-symbols/svg-400/sharp/attach_file.svg";
import { ReactComponent as AttachMoney } from "@material-symbols/svg-400/sharp/attach_money.svg";
import { ReactComponent as Bookmarks } from "@material-symbols/svg-400/sharp/bookmarks.svg";
import { ReactComponent as Business } from "@material-symbols/svg-400/sharp/business_center.svg";
import { ReactComponent as CalendarToday } from "@material-symbols/svg-400/sharp/calendar_today.svg";
import { ReactComponent as Check } from "@material-symbols/svg-400/sharp/check.svg";
import { ReactComponent as CheckCircleFilled } from "@material-symbols/svg-400/sharp/check_circle-fill.svg";
import { ReactComponent as CheckCircle } from "@material-symbols/svg-400/sharp/check_circle.svg";
import { ReactComponent as ChevronLeft } from "@material-symbols/svg-400/sharp/chevron_left.svg";
import { ReactComponent as ChevronRight } from "@material-symbols/svg-400/sharp/chevron_right.svg";
import { ReactComponent as Close } from "@material-symbols/svg-400/sharp/close.svg";
import { ReactComponent as Copy } from "@material-symbols/svg-400/sharp/content_copy.svg";
import { ReactComponent as Delete } from "@material-symbols/svg-400/sharp/delete.svg";
import { ReactComponent as FileDownload } from "@material-symbols/svg-400/sharp/download.svg";
import { ReactComponent as Edit } from "@material-symbols/svg-400/sharp/edit.svg";
import { ReactComponent as ErrorIcon } from "@material-symbols/svg-400/sharp/error.svg";
import { ReactComponent as Face } from "@material-symbols/svg-400/sharp/face.svg";
import { ReactComponent as Flag } from "@material-symbols/svg-400/sharp/flag.svg";
import { ReactComponent as Folder } from "@material-symbols/svg-400/sharp/folder.svg";
import { ReactComponent as Forum } from "@material-symbols/svg-400/sharp/forum.svg";
import { ReactComponent as Group } from "@material-symbols/svg-400/sharp/group.svg";
import { ReactComponent as Groups } from "@material-symbols/svg-400/sharp/groups.svg";
import { ReactComponent as Help } from "@material-symbols/svg-400/sharp/help.svg";
import { ReactComponent as Info } from "@material-symbols/svg-400/sharp/info.svg";
import {
  ReactComponent as ArrowDown,
  ReactComponent as KeyboardArrowDown
} from "@material-symbols/svg-400/sharp/keyboard_arrow_down.svg";
import { ReactComponent as ArrowUp } from "@material-symbols/svg-400/sharp/keyboard_arrow_up.svg";
import { ReactComponent as Link } from "@material-symbols/svg-400/sharp/link.svg";
import { ReactComponent as Logout } from "@material-symbols/svg-400/sharp/logout.svg";
import { ReactComponent as Mail } from "@material-symbols/svg-400/sharp/mail.svg";
import { ReactComponent as ManageAccounts } from "@material-symbols/svg-400/sharp/manage_accounts.svg";
import { ReactComponent as ManageSearch } from "@material-symbols/svg-400/sharp/manage_search.svg";
import { ReactComponent as Menu } from "@material-symbols/svg-400/sharp/menu.svg";
import { ReactComponent as Mic } from "@material-symbols/svg-400/sharp/mic-fill.svg";
import { ReactComponent as MicOff } from "@material-symbols/svg-400/sharp/mic_off-fill.svg";
import { ReactComponent as ThreeDotsHorizontal } from "@material-symbols/svg-400/sharp/more_horiz.svg";
import { ReactComponent as Paid } from "@material-symbols/svg-400/sharp/paid.svg";
import { ReactComponent as PDF } from "@material-symbols/svg-400/sharp/picture_as_pdf.svg";
import { ReactComponent as Stats } from "@material-symbols/svg-400/sharp/query_stats.svg";
import { ReactComponent as RadioButtonChecked } from "@material-symbols/svg-400/sharp/radio_button_checked.svg";
import { ReactComponent as ReceiptLong } from "@material-symbols/svg-400/sharp/receipt_long.svg";
import { ReactComponent as Repeat } from "@material-symbols/svg-400/sharp/repeat.svg";
import { ReactComponent as Schedule } from "@material-symbols/svg-400/sharp/schedule.svg";
import { ReactComponent as ScreenShare } from "@material-symbols/svg-400/sharp/screen_share.svg";
import { ReactComponent as Search } from "@material-symbols/svg-400/sharp/search.svg";
import { ReactComponent as Settings } from "@material-symbols/svg-400/sharp/settings.svg";
import { ReactComponent as StopScreenShare } from "@material-symbols/svg-400/sharp/stop_screen_share.svg";
import { ReactComponent as VerifiedUser } from "@material-symbols/svg-400/sharp/verified_user.svg";
import { ReactComponent as VideoCam } from "@material-symbols/svg-400/sharp/videocam.svg";
import { ReactComponent as VideoCamOff } from "@material-symbols/svg-400/sharp/videocam_off.svg";
import { ReactComponent as Warning } from "@material-symbols/svg-400/sharp/warning.svg";
import { ReactComponent as Workspaces } from "@material-symbols/svg-400/sharp/workspaces.svg";

// IconName, non-filled icons. IconFillName, filled Icons. Remember to import the correct version

export type IconName =
  | "chevronRight"
  | "chevronLeft"
  | "face"
  | "folder"
  | "group"
  | "workspaces"
  | "business"
  | "mail"
  | "bookmarks"
  | "assignment"
  | "settings"
  | "manage_accounts"
  | "manage_search"
  | "paid"
  | "receipt_long"
  | "apartment"
  | "flag"
  | "logout"
  | "help"
  | "apps"
  | "groups"
  | "account_balance"
  | "menu"
  | "close"
  | "arrowUp"
  | "arrowDown"
  | "search"
  | "add"
  | "stats"
  | "forum"
  | "repeat"
  | "edit"
  | "warning"
  | "link"
  | "mic"
  | "mic_off"
  | "videocam"
  | "videocam_off"
  | "screen_share"
  | "stop_screen_share"
  | "radioButtonChecked"
  | "info"
  | "more_horiz"
  | "keyboard_arrow_down"
  | "copy"
  | "delete"
  | "schedule"
  | "attach_money"
  | "calendar_today"
  | "error"
  | "file_download"
  | "picture_as_pdf"
  | "attach_file"
  | "check"
  | "check_circle"
  | "check_circle_filled"
  | "error"
  | "verified_user";

export const icons: Record<
  IconName,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  face: Face,
  folder: Folder,
  group: Group,
  workspaces: Workspaces,
  business: Business,
  mail: Mail,
  bookmarks: Bookmarks,
  assignment: Assignment,
  settings: Settings,
  manage_accounts: ManageAccounts,
  manage_search: ManageSearch,
  paid: Paid,
  receipt_long: ReceiptLong,
  apartment: Apartment,
  flag: Flag,
  logout: Logout,
  help: Help,
  apps: Apps,
  groups: Groups,
  account_balance: AccountBalance,
  menu: Menu,
  close: Close,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  search: Search,
  add: Add,
  stats: Stats,
  forum: Forum,
  repeat: Repeat,
  edit: Edit,
  warning: Warning,
  link: Link,
  mic: Mic,
  mic_off: MicOff,
  videocam: VideoCam,
  videocam_off: VideoCamOff,
  screen_share: ScreenShare,
  stop_screen_share: StopScreenShare,
  radioButtonChecked: RadioButtonChecked,
  info: Info,
  more_horiz: ThreeDotsHorizontal,
  keyboard_arrow_down: KeyboardArrowDown,
  copy: Copy,
  delete: Delete,
  schedule: Schedule,
  attach_money: AttachMoney,
  calendar_today: CalendarToday,
  error: ErrorIcon,
  file_download: FileDownload,
  picture_as_pdf: PDF,
  attach_file: AttachFile,
  check: Check,
  check_circle: CheckCircle,
  check_circle_filled: CheckCircleFilled,
  verified_user: VerifiedUser
};
