import queryString from "query-string";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { zip } from "rxjs";

import {
  ApiPaginatedRequest,
  CIQError,
  CompanyResponse,
  DefaultToasterService,
  ErrorResponse,
  MetaService,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  ADOPTION_STAGE,
  CustomerFilters,
  CustomerSubFilters,
  DiscoverFilterOption,
  DiscoverFilterType,
  NPS,
  PERSONA,
  RENEWAL_INTENT,
  SPEND
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { utilService } from "@arbolus-technologies/utils";

import { SEARCH_LIMIT } from "../../Modules/DiscoverFilters/DiscoverFilters";
import { useDiscoverFilters } from "./useDiscoverFilters";

interface UseDiscoverCustomerFilterProps {
  metaService?: typeof MetaService;
  notificationService?: ToasterService;
}

export interface UseDiscoverCustomerFilter {
  filterOptions: DiscoverFilterOption[];
  handleUpdateList: (data: DiscoverFilterOption[]) => void;
  handleClearFilters: () => void;
  updateCustomerInfoFromUrl: () => Promise<DiscoverFilterOption[]>;
  updateCustomerSubFiltersInfoFromUrl: () => CustomerSubFilters;
  handleCustomerFiltersChange: (customerSubFilters: CustomerFilters) => void;
  getCustomersSearchResults: (query?: string) => void;
  urlChangeOnCustomerFiltersUpdate: (customerFilters: CustomerFilters) => void;
}

export const useDiscoverCustomerFilter = ({
  metaService = MetaService,
  notificationService = DefaultToasterService
}: UseDiscoverCustomerFilterProps = {}): UseDiscoverCustomerFilter => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trackFiltered } = useArbolusTracking();

  const { setSelectedOptions, clearFilter } = useDiscoverFilters({
    filterType: DiscoverFilterType.Customers
  });

  const [filterOptions, setFilterOptions] = useState<DiscoverFilterOption[]>(
    []
  );

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());

  const getCustomersSearchResults = (query?: string) => {
    const queryParams: ApiPaginatedRequest = {
      searchTerm: query,
      offset: filterOptions?.length ?? 0,
      limit: SEARCH_LIMIT,
      orderBy: "Name",
      orderDirection: SORT_DIRECTION.ASCENDING
    };

    metaService.getCompanies(queryParams).subscribe(
      (customersResponse) => {
        const filterOptionsArray = customersResponse.items.map((company) => ({
          value: company.id,
          label: company.name
        }));

        setFilterOptions(filterOptionsArray);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    );
  };

  const handleUpdateList = (data: DiscoverFilterOption[]) => {
    setFilterOptions(data);
  };

  const parameters = queryString.parse(location.search);
  const {
    customers,
    persona,
    spend,
    adoptionStage,
    nps,
    renewalIntent
  }: {
    customers?: string;
    persona?: string;
    spend?: string;
    adoptionStage?: string;
    nps?: string;
    renewalIntent?: string;
  } = parameters;

  const updateCustomerInfoFromUrl = () => {
    const customerCompanyIds = customers ? customers.split(",") : [];
    if (!customerCompanyIds.length) {
      return Promise.resolve([]);
    }

    return new Promise<DiscoverFilterOption[]>((resolve, reject) => {
      const promises = customerCompanyIds.map((value) =>
        metaService.getCompanyById(value)
      );
      const subscription = zip(...promises).subscribe({
        next: (companies) => {
          const filterOptionsArray = companies.map(
            (company: CompanyResponse) => ({
              value: company.id,
              label: company.name
            })
          );

          resolve(filterOptionsArray);
          subscription.unsubscribe();
        },
        error: (error) => {
          reject(error);
          subscription.unsubscribe();
        }
      });
    });
  };

  const updateCustomerSubFiltersInfoFromUrl = () => {
    return {
      persona: (persona ? persona.split(",") : []) as PERSONA[],
      spend: (spend ? spend.split(",") : []) as SPEND[],
      adoptionStage: (adoptionStage
        ? adoptionStage.split(",")
        : []) as ADOPTION_STAGE[],
      nps: (nps ? nps.split(",") : []) as NPS[],
      renewalIntent: (renewalIntent
        ? renewalIntent.split(",")
        : []) as RENEWAL_INTENT[]
    };
  };

  const urlChangeOnCustomerFiltersUpdate = (
    customerFilters: CustomerFilters
  ) => {
    if (!isAdmin) return;

    const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);

    const filtersToUpdate = {
      customers: customerFilters.customers
        .map((customer) => customer.value)
        .join(","),
      persona: customerFilters.persona.join(","),
      spend: customerFilters.spend.join(","),
      adoptionStage: customerFilters.adoptionStage.join(","),
      nps: customerFilters.nps.join(","),
      renewalIntent: customerFilters.renewalIntent.join(",")
    };

    Object.entries(filtersToUpdate).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        nonEmptyParams[filterKey] = filterValue;
      } else {
        delete nonEmptyParams[filterKey];
      }
    });

    const params = new URLSearchParams(nonEmptyParams);
    history.replace({
      pathname: location.pathname,
      search: params.toString()
    });
  };

  const handleCustomerFiltersChange = (filters: CustomerFilters) => {
    const { customers, ...subFilters } = filters;
    setSelectedOptions(customers);
    dispatch(ProjectNxStoreActions.setCustomerSubFilters(subFilters));

    let combinedCustomerSubFilter: string[] = [];
    Object.keys(subFilters).forEach((key) => {
      combinedCustomerSubFilter = [
        ...combinedCustomerSubFilter,
        ...(subFilters[key as keyof CustomerSubFilters] || [])
      ];
    });

    urlChangeOnCustomerFiltersUpdate(filters);
    trackFiltered(MixPanelEventNames.InternalSearchCustomerSearch, {
      Customers: filters.customers.map((customer) => customer.label),
      SubFilters: combinedCustomerSubFilter,
      Project: projectName,
      Client: projectClientName
    });
  };

  const handleClearFilters = () => {
    clearFilter();

    if (!isAdmin) return;

    const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);

    const filterKeys = [
      "customers",
      "persona",
      "spend",
      "adoptionStage",
      "nps",
      "renewalIntent"
    ];

    filterKeys.forEach((key) => delete nonEmptyParams[key]);

    const params = new URLSearchParams(nonEmptyParams);
    history.replace({
      pathname: location.pathname,
      search: params.toString()
    });
  };

  return {
    filterOptions,
    handleUpdateList,
    updateCustomerInfoFromUrl,
    updateCustomerSubFiltersInfoFromUrl,
    handleCustomerFiltersChange,
    handleClearFilters,
    getCustomersSearchResults,
    urlChangeOnCustomerFiltersUpdate
  };
};
