/* eslint-disable @nx/enforce-module-boundaries */
import { OutputSelector, createSelector } from "reselect";

import {
  AllCanopyAnswersByExpert,
  CanopyAnswersByQuestion,
  CanopyVideoAnswers,
  ExpertInACanopy,
  ExpertInACanopyProfile
} from "@arbolus-technologies/api";

import {
  CanopyClientAppState,
  ClientCanopyReducerState
} from "../models/definitions";
import {
  CanopyClientsActiveTab,
  CanopyClientsAnswerState
} from "../models/enums";

const canopyStateSelector = (
  state: CanopyClientAppState
): ClientCanopyReducerState => state.canopyClient;

const canopyClientsAnswerState = (): OutputSelector<
  CanopyClientAppState,
  CanopyClientsAnswerState,
  (res: ClientCanopyReducerState) => CanopyClientsAnswerState
> =>
  createSelector<
    CanopyClientAppState,
    ClientCanopyReducerState,
    CanopyClientsAnswerState
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyClientAnswersState
  );

const canopyAnswersByQuestion = (): OutputSelector<
  CanopyClientAppState,
  CanopyAnswersByQuestion | null,
  (res: ClientCanopyReducerState) => CanopyAnswersByQuestion | null
> =>
  createSelector<
    CanopyClientAppState,
    ClientCanopyReducerState,
    CanopyAnswersByQuestion | null
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyAnswersByQuestion
  );
const canopyAnswersByQuestionLoading = (): OutputSelector<
  CanopyClientAppState,
  boolean,
  (res: ClientCanopyReducerState) => boolean
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.getCanopyAnswersByQuestionLoading
  );

const videoTranscript = (): OutputSelector<
  CanopyClientAppState,
  string | null,
  (res: ClientCanopyReducerState) => string | null
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, string | null>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.videoTranscript
  );

const isSmartTranscript = (): OutputSelector<
  CanopyClientAppState,
  boolean,
  (res: ClientCanopyReducerState) => boolean
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isSmartTranscript
  );

const videoTranscriptLoading = (): OutputSelector<
  CanopyClientAppState,
  boolean,
  (res: ClientCanopyReducerState) => boolean
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.videoTranscriptLoading
  );

const editVideoTranscriptLoading = (): OutputSelector<
  CanopyClientAppState,
  boolean,
  (res: ClientCanopyReducerState) => boolean
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.editVideoTranscriptLoading
  );

const canopyAnswerSelected = (): OutputSelector<
  CanopyClientAppState,
  CanopyVideoAnswers | null,
  (res: ClientCanopyReducerState) => CanopyVideoAnswers | null
> =>
  createSelector<
    CanopyClientAppState,
    ClientCanopyReducerState,
    CanopyVideoAnswers | null
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyAnswerSelected
  );

// Canopy experts list
const expertsSelector = (): OutputSelector<
  CanopyClientAppState,
  ExpertInACanopy[] | null,
  (res: ClientCanopyReducerState) => ExpertInACanopy[] | null
> =>
  createSelector<
    CanopyClientAppState,
    ClientCanopyReducerState,
    ExpertInACanopy[] | null
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyExperts
  );

// Canopy experts list
const isCanopyExpertsLoading = (): OutputSelector<
  CanopyClientAppState,
  boolean,
  (res: ClientCanopyReducerState) => boolean
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.loadingCanopyExperts
  );

// All experts answers
const allCanopyAnswersByExpert = (): OutputSelector<
  CanopyClientAppState,
  AllCanopyAnswersByExpert | null,
  (res: ClientCanopyReducerState) => AllCanopyAnswersByExpert | null
> =>
  createSelector<
    CanopyClientAppState,
    ClientCanopyReducerState,
    AllCanopyAnswersByExpert | null
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.allCanopyAnswersByExpert
  );

// Canopy experts list
const loadingAllCanopyAnswersByExpert = (): OutputSelector<
  CanopyClientAppState,
  boolean,
  (res: ClientCanopyReducerState) => boolean
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.loadingAllCanopyAnswersByExperts
  );
// Expert selected
const expertSelected = (): OutputSelector<
  CanopyClientAppState,
  ExpertInACanopyProfile | null,
  (res: ClientCanopyReducerState) => ExpertInACanopyProfile | null
> =>
  createSelector<
    CanopyClientAppState,
    ClientCanopyReducerState,
    ExpertInACanopyProfile | null
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.expertSelected
  );

const activeTabSelector = (): OutputSelector<
  CanopyClientAppState,
  CanopyClientsActiveTab,
  (res: ClientCanopyReducerState) => CanopyClientsActiveTab
> =>
  createSelector<
    CanopyClientAppState,
    ClientCanopyReducerState,
    CanopyClientsActiveTab
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyClientActiveTab
  );
// showRightComponent
const showRightComponent = (): OutputSelector<
  CanopyClientAppState,
  boolean,
  (res: ClientCanopyReducerState) => boolean
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.showRightComponent
  );
// getIsExpertInProject
const getIsExpertInProject = (): OutputSelector<
  CanopyClientAppState,
  boolean,
  (res: ClientCanopyReducerState) => boolean
> =>
  createSelector<CanopyClientAppState, ClientCanopyReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.hasExpertClientVisibleReferral
  );
export {
  activeTabSelector,
  allCanopyAnswersByExpert,
  canopyAnswerSelected,
  canopyAnswersByQuestion,
  canopyAnswersByQuestionLoading,
  canopyClientsAnswerState,
  editVideoTranscriptLoading,
  expertSelected,
  expertsSelector,
  getIsExpertInProject,
  isCanopyExpertsLoading,
  isSmartTranscript,
  loadingAllCanopyAnswersByExpert,
  showRightComponent,
  videoTranscript,
  videoTranscriptLoading
};
