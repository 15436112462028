import { Button, Flex, Form, Input, Radio } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  CIQError,
  Compliance,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";

const { TextArea } = Input;

interface ComplianceFormProps {
  complianceInfo: Compliance[];
  expertId: string;
  onUpdateComplianceAnswers: (answers: Compliance[]) => void;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ComplianceForm: React.FC<ComplianceFormProps> = ({
  complianceInfo,
  expertId,
  onUpdateComplianceAnswers,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralCompliance");
  const [form] = Form.useForm();
  const { projectId } = useParams<{ projectId: string }>();
  const { complianceQuestions } = Form.useWatch([], form) ?? {
    complianceQuestions: []
  };

  const [isSaving, setIsSaving] = useState(false);

  const options = [
    { label: t("yes"), value: "Yes" },
    { label: t("no"), value: "No" }
  ];

  const handleSubmit = (values: { complianceQuestions: Compliance[] }) => {
    const answeredCompliances = values.complianceQuestions.filter(
      ({ answer }) => answer?.answer ?? answer?.textAnswer
    );

    const newAnswers = answeredCompliances.map((item) => ({
      questionId: item.questionId,
      answer: item.answer?.answer ?? "",
      textAnswer: item.answer?.textAnswer
    }));

    setIsSaving(true);
    expertService
      .updateComplianceAnswers(expertId, projectId, newAnswers)
      .subscribe(
        () => {
          notificationService.showSuccess(t("updateSuccess"));
          onUpdateComplianceAnswers(values.complianceQuestions);
          setIsSaving(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          setIsSaving(false);
        }
      );
  };

  const onFinishFailed = () => {
    notificationService.showError(t("invalidForm"));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      initialValues={{
        complianceQuestions: complianceInfo
      }}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      disabled={isSaving}
    >
      <Form.List name="complianceQuestions">
        {(fields) =>
          fields.map((field, index) => {
            const { question, displayModel } = complianceInfo[index].question;

            return (
              <Flex key={field.key} vertical>
                <Form.Item
                  label={`${index + 1}. ${question}`}
                  name={[field.name, "answer", "answer"]}
                >
                  <Radio.Group
                    options={options}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
                {displayModel.textRequiredValue ===
                  complianceQuestions[index]?.answer?.answer && (
                  <Form.Item name={[field.name, "answer", "textAnswer"]}>
                    <TextArea
                      rows={4}
                      placeholder={
                        displayModel.defaultText ||
                        t("detailsPlaceholder", {
                          question: question.replace("?", "")
                        })
                      }
                    />
                  </Form.Item>
                )}
              </Flex>
            );
          })
        }
      </Form.List>
      <Form.Item>
        <Flex justify="flex-end">
          <Button type="primary" htmlType="submit" disabled={isSaving}>
            {t("save")}
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
