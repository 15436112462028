import { LandingSurvey, SurveyExpert } from "@arbolus-technologies/api";
import { SURVEY_PAYMENT_PAGE } from "@arbolus-technologies/models/survey";
import { Col, Row } from "antd";
import React from "react";
import { PaymentMethodInformation } from "./PaymentMethodInformation/PaymentMethodInformation";
import { PreferredPaymentMethod } from "./PreferredPaymentMethod/PreferredPaymentMethod";
import { SurveyDescription } from "./SurveyDescription/SurveyDescription";
import { SurveyStatistics } from "./SurveyStatistics/SurveyStatistics";

interface PaymentMethodSelectionProps {
  survey: LandingSurvey;
  surveyExpert: SurveyExpert;
  handleScreenSelection: (screen: SURVEY_PAYMENT_PAGE) => void;
}

export const PaymentMethodSelection: React.FC<PaymentMethodSelectionProps> = ({
  surveyExpert,
  survey,
  handleScreenSelection
}) => {
  return (
    <Row gutter={[24, 24]} style={{ padding: "24px 12px", margin: 0 }}>
      <Col xs={24} md={16}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <SurveyDescription survey={survey} />
          </Col>
          <Col span={24}>
            <PreferredPaymentMethod
              surveyExpert={surveyExpert}
              handleScreenSelection={handleScreenSelection}
            />
          </Col>
          <Col span={24}>
            <PaymentMethodInformation surveyExpert={surveyExpert} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={8}>
        <SurveyStatistics survey={survey} />
      </Col>
    </Row>
  );
};
