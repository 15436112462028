import { FieldArray, Formik, FormikProps } from "formik";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  useArbolusTracking,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import {
  Answer,
  ProjectApplicationStepProps
} from "@arbolus-technologies/models/project";

import QuestionItem from "../../questionItem/QuestionItem";
import { projectQuestionsValidationSchema } from "../ProjectApplicationSchema";
import { ProjectApplicationWrapper } from "../ProjectApplicationWrapper/ProjectApplicationWrapper";

import styles from "./ProjectQuestions.module.scss";

export interface ProjectQuestionsFormValues {
  questions: Answer[];
}

interface ProjectQuestionsProps extends ProjectApplicationStepProps {
  questions: Answer[];
}

export const ProjectQuestions: React.FC<ProjectQuestionsProps> = ({
  questions,
  onSaveProgress,
  stepDetails,
  isLoading,
  onBack
}) => {
  const { t } = useTranslation("projectApplication");
  const formikRef = useRef<FormikProps<ProjectQuestionsFormValues> | null>(
    null
  );
  const { trackClick } = useArbolusTracking();
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.ExpertUserProjectApplicationScreeningTime
  );

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime();
    };
  }, []);

  const handleSubmit = (values: ProjectQuestionsFormValues): void => {
    if (formikRef.current) {
      trackClick(MixPanelEventNames.ExpertUserProjectApplicationScreenings);
      onSaveProgress(values, false);
    }
  };

  const renderForm = ({
    setFieldValue,
    setFieldTouched,
    values
  }: FormikProps<ProjectQuestionsFormValues>): JSX.Element => (
    <FieldArray name="questions">
      {(): JSX.Element => (
        <div className={styles.projectQuestions}>
          {values.questions.map((question, index) => {
            const name = `questions[${index}].answer`;

            const handleAnswersChange = (text: string) => {
              setFieldValue(name, text);
              setFieldTouched(name, true);
            };

            return (
              <QuestionItem
                key={name}
                name={name}
                label={question.question}
                placeholder={t("answer")}
                value={question.answer ?? ""}
                onAnswersChange={handleAnswersChange}
              />
            );
          })}
        </div>
      )}
    </FieldArray>
  );

  return (
    <PageTracker
      page={MixpanelPages.ExpertUserProjectApplicationPageScreenings}
    >
      <Formik<ProjectQuestionsFormValues>
        initialValues={{ questions }}
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
        validateOnMount
        validationSchema={projectQuestionsValidationSchema}
        innerRef={formikRef}
      >
        {(
          formikProps: FormikProps<ProjectQuestionsFormValues>
        ): JSX.Element => {
          const { isValid, values, handleSubmit, dirty } = formikProps;

          return (
            <ProjectApplicationWrapper
              isValid={isValid}
              dirty={dirty}
              stepDetails={stepDetails}
              onSaveProgress={() => onSaveProgress(values, true, dirty)}
              onNext={handleSubmit}
              onBack={onBack}
              isLoading={isLoading}
            >
              <div className={styles.projectQuestionsContainer}>
                <h1>{t("screeningQuestions")}</h1>
                {renderForm(formikProps)}
              </div>
            </ProjectApplicationWrapper>
          );
        }}
      </Formik>
    </PageTracker>
  );
};
