import {
  CellEditRequestEvent,
  FilterModel,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";
import i18next from "i18next";

import {
  CompanyService,
  DefaultToasterService,
  MONITORING_LIST_ORDER_BY,
  MonitoringListModel,
  MonitoringListRequest,
  MonitoringListService,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

export class MonitoringListDataSource implements IServerSideDatasource {
  private readonly _clientId: string;

  constructor(clientId: string) {
    this._clientId = clientId;
  }

  getRows(params: IServerSideGetRowsParams<MonitoringListModel, never>): void {
    const { success, fail, request } = params;

    const queryParams = this.getQueryParams(request);

    MonitoringListService.getCompanySignals({
      ...queryParams,
      clientId: this._clientId
    })
      .then((response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      })
      .catch(fail);
  }

  editWebsite = (event: CellEditRequestEvent) => {
    const {
      api,
      newValue,
      data: { companyId }
    } = event;

    const companyBodyRequest = {
      linkedinUrl: null,
      website: { value: newValue }
    };

    CompanyService.patchCompany(companyId, companyBodyRequest).subscribe({
      next: () => {
        const companyWebsiteServerFormatted = newValue.replace(
          "https://www.",
          ""
        );
        api.applyServerSideTransaction({
          update: [
            {
              ...event.data,
              companyWebsite: companyWebsiteServerFormatted
            }
          ]
        });
        api.redrawRows({ rowNodes: [event.node] });
        DefaultToasterService.showSuccess(
          i18next.t("companiesTable:websiteUpdatedSuccess")
        );
      },
      error: (error) => {
        DefaultToasterService.showApiErrors(error);
      }
    });
  };

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): MonitoringListRequest {
    const { startRow, endRow, sortModel } = request;
    const filterModel = request.filterModel as FilterModel;

    const queryParams: MonitoringListRequest = {
      limit: endRow! - startRow!,
      offset: startRow!
    };
    if (sortModel?.length) {
      queryParams.sort = sortModel.map(({ colId, sort }) => ({
        orderBy: colId as MONITORING_LIST_ORDER_BY,
        orderDirection: sort as SORT_DIRECTION
      }));
    }

    if (filterModel[MONITORING_LIST_ORDER_BY.COMPANY_NAME]) {
      queryParams.companyName =
        filterModel[MONITORING_LIST_ORDER_BY.COMPANY_NAME].filter;
    }

    if (filterModel[MONITORING_LIST_ORDER_BY.COMPANY_WEBSITE]) {
      queryParams.companyWebsite =
        filterModel[MONITORING_LIST_ORDER_BY.COMPANY_WEBSITE].filter;
    }

    if (filterModel[MONITORING_LIST_ORDER_BY.CLIENT_USER]) {
      queryParams.clientUserName =
        filterModel[MONITORING_LIST_ORDER_BY.CLIENT_USER].filter;
    }

    if (filterModel[MONITORING_LIST_ORDER_BY.SOURCE]) {
      queryParams.sources = filterModel[MONITORING_LIST_ORDER_BY.SOURCE].values;
    }

    return queryParams;
  }
}
