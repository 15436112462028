import { Client, User } from "@arbolus-technologies/api";

export enum EXPERT_PROFILE_TABS {
  PROFILE = "profile",
  PROJECTS = "projects",
  SURVEYS = "surveys",
  INTERNAL_COMMENTS = "internalComments",
  SCREENING = "screening",
  PROJECT_NOTES = "projectNotes",
  PAYMENTS = "payments"
}

export const INTERNAL_COMMENT_TITLE = "title";
export const INTERNAL_COMMENT_COMMENT = "note";
export const INTERNAL_COMMENT_SELECT_CLIENT = "selectClient";

export interface CreateNewCommentBase {
  [INTERNAL_COMMENT_TITLE]: string;
  [INTERNAL_COMMENT_COMMENT]: string;
  [INTERNAL_COMMENT_SELECT_CLIENT]: Client | null;
}

export interface NotificationSubscription {
  Messages: boolean;
  NewJoins: boolean;
  Approvals: boolean;
  Compliance: boolean;
  SharedDocs: boolean;
  ThingsTodo: boolean;
  Applications: boolean;
  EventInvites: boolean;
  NewReferrals: boolean;
  MobileMessage: boolean;
}

export interface NotificationSettingsItems {
  title: string;
  subTitle: string;
  value: boolean;
}

export interface ExpertNotificationDetails {
  isActive?: boolean;
  expertName: string;
  expertId: string | null;
}

export interface ExpertProfileFormDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | undefined;
  linkedinProfile: string | undefined;
  profileImageUrl: string | undefined;
  title: string;
  overview: string | undefined;
  experienceLevel: string | undefined;
  hourlyRate?: number;
  isoCurrencyCode?: string;
  background?: string;
  user: User;
}
