import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ScreeningQuestion } from "@arbolus-technologies/api";
import { KEYWORDS_SEARCH_OPTIONS } from "@arbolus-technologies/models/project";
import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { ScreeningAnswersCard } from "./ScreeningAnswersCard";

import { EXPERT_PROFILE_TABS } from "@arbolus-technologies/utils";
import { Button, Card, Divider, Flex } from "antd";

interface ScreeningAnswersProps {
  expertId: string;
  keywordsList: string[];
  screeningQuestions: ScreeningQuestion[];
}
export const ScreeningAnswers: React.FC<ScreeningAnswersProps> = ({
  expertId,
  keywordsList,
  screeningQuestions
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");

  const hasKeywords = keywordsList.length > 0;

  const isProjectAnswersOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.PROJECTS_ANSWERS
    )
  );

  return (
    <Card
      title={t("screeningAnswers")}
      size="small"
      extra={
        <Link
          to={{
            pathname: EXPERT_PROFILE(expertId).pathname,
            search: `?defaultTab=${EXPERT_PROFILE_TABS.SCREENING}`
          }}
          target="_blank"
        >
          <Button type="link">{t("allAnswers")}</Button>
        </Link>
      }
    >
      <Flex vertical gap={16}>
        {screeningQuestions.length > 0 &&
        isProjectAnswersOptionSelected &&
        hasKeywords ? (
          <>
            {screeningQuestions.map(
              (screeningQuestion: ScreeningQuestion, index) => (
                <>
                  <ScreeningAnswersCard
                    key={screeningQuestion.project.id}
                    screeningQuestion={screeningQuestion}
                    keywordsList={keywordsList}
                  />
                  {index < screeningQuestions.length - 1 && (
                    <Divider style={{ margin: "8px 0" }} />
                  )}
                </>
              )
            )}
          </>
        ) : (
          t("noScreeningAnswers")
        )}
      </Flex>
    </Card>
  );
};
