import { CellClickedEvent, ColDef, ITooltipParams } from "ag-grid-community";
import i18next from "i18next";

import {
  floatingFilter,
  floatingSetFilter
} from "@arbolus-technologies/ui/components";
import { DATE_AND_TIME_FORMAT, formatDate } from "@arbolus-technologies/utils";
import { convertValueToCurrencyFormat } from "@arbolus-technologies/utils";

import { NPSCellRenderer } from "../../../components/NPSCellRenderer/NPSCellRenderer";
import { AnswerStatus } from "./cellRenderers/AnswerStatus";

const CELL_VALUE_UNDEFINED_TEXT = i18next.t(
  "companyDataTable:cellValueUndefined"
);

export const DEFAULT_COL_DEF: ColDef = {
  resizable: false,
  suppressMovable: true,
  sortable: false,
  suppressFloatingFilterButton: false
} as const;

export const FIELD_NAMES = {
  VENDOR_COMPANY_NAME: "vendorCompanyName",
  EXPERT_CURRENT_COMPANY_NAME: "expertCurrentCompanyName",
  EXPERT_NAME: "expertName",
  PERSONA: "persona",
  SPEND: "spend",
  NPS: "nps",
  RENEWAL_INTENT: "renewalIntent",
  IMPLEMENTATION_STAGE: "implementationStage",
  COMPETITORS_NAMES: "competitorsNames",
  ANSWER_STATUS: "answerStatus",
  MODIFIED: "modified"
} as const;

const PERSONA_TRANSLATION: { [key: string]: string } = {
  User: i18next.t("companyDataTable:personaUser"),
  Influencer: i18next.t("companyDataTable:personaInfluencer"),
  Other: i18next.t("companyDataTable:personaOther")
} as const;

const RENEWAL_INTENT_TRANSLATION: { [key: string]: string } = {
  Yes: i18next.t("companyDataTable:renewalIntentYes"),
  No: i18next.t("companyDataTable:renewalIntentNo"),
  NotSure: i18next.t("companyDataTable:renewalIntentUnsure")
} as const;

const STAGE_TRANSLATION: { [key: string]: string } = {
  Pilot: i18next.t("companyDataTable:implementationStagePilot"),
  LessThan1Year: i18next.t("companyDataTable:implementationStageLessThan1Year"),
  MoreThan1Year: i18next.t("companyDataTable:implementationStageMoreThan1Year"),
  OffBoarding: i18next.t("companyDataTable:implementationStageOffBoarding")
} as const;

export const ANSWER_STATUS_TRANSLATION: { [key: string]: string } = {
  Pending: i18next.t("companyDataTable:answerStatusPending"),
  Approved: i18next.t("companyDataTable:answerStatusApproved"),
  Rejected: i18next.t("companyDataTable:answerStatusRejected"),
  Submitted: i18next.t("companyDataTable:answerStatusSubmitted")
} as const;

export const COLUMN_DEFS: ColDef[] = [
  {
    field: FIELD_NAMES.VENDOR_COMPANY_NAME,
    tooltipField: FIELD_NAMES.VENDOR_COMPANY_NAME,
    headerName: i18next.t("companyDataTable:columnHeaderVendor"),
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      defaultJoinOperator: "OR",
      filterOptions: ["contains"],
      maxNumConditions: 20
    },
    ...floatingSetFilter,
    checkboxSelection: true
  },
  {
    field: FIELD_NAMES.EXPERT_CURRENT_COMPANY_NAME,
    tooltipField: FIELD_NAMES.EXPERT_CURRENT_COMPANY_NAME,
    headerName: i18next.t("companyDataTable:columnHeaderCurrentCompany"),
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: { maxNumConditions: 1, filterOptions: ["contains"] },
    ...floatingFilter,
    valueGetter: ({ data: { expertCurrentCompanyName } }) =>
      expertCurrentCompanyName ?? CELL_VALUE_UNDEFINED_TEXT
  },
  {
    field: FIELD_NAMES.EXPERT_NAME,
    headerName: i18next.t("companyDataTable:columnHeaderExpert"),
    flex: 1,
    filter: "agTextColumnFilter",
    cellClass: "cursor-pointer",
    onCellClicked: ({ context, data }: CellClickedEvent) => {
      context?.openExpertProfile({
        expertId: data.expertId
      });
    },
    filterParams: { maxNumConditions: 1, filterOptions: ["contains"] },
    ...floatingFilter
  },
  {
    field: FIELD_NAMES.PERSONA,
    headerName: i18next.t("companyDataTable:columnHeaderPersona"),
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      values: [
        { code: "Other", name: PERSONA_TRANSLATION.Other },
        { code: "User", name: PERSONA_TRANSLATION.User },
        { code: "Influencer", name: PERSONA_TRANSLATION.Influencer }
      ],
      keyCreator,
      valueFormatter
    },
    ...floatingSetFilter,
    valueGetter: ({ data: { persona } }: { data: { persona: string } }) =>
      PERSONA_TRANSLATION[persona] ?? persona ?? CELL_VALUE_UNDEFINED_TEXT
  },
  {
    field: FIELD_NAMES.SPEND,
    headerName: i18next.t("companyDataTable:columnHeaderSpend"),
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    valueGetter: ({ data: { spend } }) => {
      if (!Number.isFinite(spend)) {
        return CELL_VALUE_UNDEFINED_TEXT;
      }
      return convertValueToCurrencyFormat(spend, "USD");
    }
  },
  {
    field: FIELD_NAMES.NPS,
    headerName: "NPS",
    filter: "agTextColumnFilter",
    filterParams: { maxNumConditions: 1, filterOptions: ["equals"] },
    ...floatingFilter,
    cellRenderer: NPSCellRenderer
  },
  {
    field: FIELD_NAMES.RENEWAL_INTENT,
    headerName: i18next.t("companyDataTable:columnHeaderRenewalIntent"),
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      values: [
        { code: "NotSure", name: RENEWAL_INTENT_TRANSLATION.NotSure },
        { code: "Yes", name: RENEWAL_INTENT_TRANSLATION.Yes },
        { code: "No", name: RENEWAL_INTENT_TRANSLATION.No }
      ],
      keyCreator,
      valueFormatter
    },
    ...floatingSetFilter,
    valueGetter: ({ data: { renewalIntent } }) => {
      if (!renewalIntent) {
        return CELL_VALUE_UNDEFINED_TEXT;
      }
      return RENEWAL_INTENT_TRANSLATION[renewalIntent] ?? renewalIntent;
    }
  },
  {
    field: FIELD_NAMES.IMPLEMENTATION_STAGE,
    headerName: i18next.t("companyDataTable:columnHeaderImplementationStage"),
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      values: [
        {
          code: "Pilot",
          name: STAGE_TRANSLATION.Pilot
        },
        {
          code: "LessThan1Year",
          name: STAGE_TRANSLATION.LessThan1Year
        },
        {
          code: "MoreThan1Year",
          name: STAGE_TRANSLATION.MoreThan1Year
        },
        {
          code: "OffBoarding",
          name: STAGE_TRANSLATION.OffBoarding
        }
      ],
      keyCreator,
      valueFormatter
    },
    ...floatingSetFilter,
    valueGetter: ({
      data: { implementationStage }
    }: { data: { implementationStage: string } }) => {
      if (!implementationStage) {
        return CELL_VALUE_UNDEFINED_TEXT;
      }
      return STAGE_TRANSLATION[implementationStage] ?? implementationStage;
    }
  },
  {
    field: FIELD_NAMES.COMPETITORS_NAMES,
    tooltipField: FIELD_NAMES.COMPETITORS_NAMES,
    headerName: i18next.t("companyDataTable:columnHeaderCompetitors"),
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: { maxNumConditions: 1, filterOptions: ["contains"] },
    tooltipValueGetter: ({ data }: ITooltipParams) => data.competitorsNames,
    ...floatingFilter
  },
  {
    field: FIELD_NAMES.ANSWER_STATUS,
    headerName: i18next.t("companyDataTable:columnHeaderAnswerStatus"),
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      values: [
        {
          code: "Pending",
          name: ANSWER_STATUS_TRANSLATION.Pending
        },
        {
          code: "Submitted",
          name: ANSWER_STATUS_TRANSLATION.Submitted
        },
        {
          code: "Approved",
          name: ANSWER_STATUS_TRANSLATION.Approved
        },
        {
          code: "Rejected",
          name: ANSWER_STATUS_TRANSLATION.Rejected
        }
      ],
      keyCreator,
      valueFormatter
    },
    ...floatingSetFilter,
    cellRenderer: AnswerStatus
  },
  {
    field: FIELD_NAMES.MODIFIED,
    headerName: i18next.t("companyDataTable:columnHeaderModified"),
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ["lessThan", "greaterThan", "inRange"],
      maxNumConditions: 1,
      buttons: ["apply"]
    },
    ...floatingSetFilter,
    valueFormatter: ({ value }) => {
      if (!value) return CELL_VALUE_UNDEFINED_TEXT;
      return formatDate(value, DATE_AND_TIME_FORMAT);
    }
  }
];

interface TableFilterParams {
  value: {
    code: string;
    name: string;
  };
}

function keyCreator(params: TableFilterParams) {
  return params.value.code;
}

function valueFormatter(params: TableFilterParams) {
  return params.value.name;
}
