import { Flex, Form, Switch, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationSettingsItems } from "../../../helpers/types";

const { Text } = Typography;

interface NotificationSettingsItemProps extends NotificationSettingsItems {
  index: number;
}

export const NotificationSettingsItem: React.FC<
  NotificationSettingsItemProps
> = ({ title, subTitle, index }) => {
  const { t } = useTranslation("notificationSettings");

  return (
    <Flex justify="space-between">
      <Flex vertical>
        <Text>{t(title)}</Text>
        <Text type="secondary">{t(subTitle)}</Text>
      </Flex>
      <Form.Item name={[index, "value"]} valuePropName="checked">
        <Switch />
      </Form.Item>
    </Flex>
  );
};
