import React from "react";
import { useTranslation } from "react-i18next";

import { CustomerCompanyInfo } from "@arbolus-technologies/models/project";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { Avatar, Card, Divider, Flex, Tag } from "antd";
import { CUSTOMER_OF_FILTER_KEYS } from "../../../enums";
import { CustomerOfFilterTag } from "./CustomerOfFilterTag";

interface CustomerOfProps {
  customers: CustomerCompanyInfo[];
}

const { ADOPTION_STAGE, NPS, PERSONA, RENEWAL_INTENT, SPEND } =
  CUSTOMER_OF_FILTER_KEYS;
const FILTER_KEYS = [ADOPTION_STAGE, NPS, PERSONA, RENEWAL_INTENT, SPEND];

export const CustomerOf: React.FC<CustomerOfProps> = ({ customers }) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");

  if (!customers.length) return null;

  return (
    <Card title={t("customerOf", { count: customers.length })} size="small">
      {customers.map((customer, index) => (
        <Flex key={customer.companyId} vertical>
          <Flex gap={4} align="center" wrap>
            <Tag
              title={customer.companyName}
              color="success"
              style={{
                display: "flex",
                alignItems: "center",
                marginInlineEnd: 0
              }}
            >
              <Avatar
                icon={<AntDIcon name="domain_filled" fontSize="10px" />}
                style={{ marginRight: "4px", width: 16, height: 16 }}
              />
              {customer.companyName}
            </Tag>
            {FILTER_KEYS.filter((key) => key in customer && customer[key]).map(
              (key) => (
                <CustomerOfFilterTag
                  key={key}
                  label={`${t(key)}: ${t(customer[key])}`}
                />
              )
            )}
          </Flex>
          {index < customers.length - 1 && (
            <Divider style={{ margin: "8px 0" }} />
          )}
        </Flex>
      ))}
    </Card>
  );
};
