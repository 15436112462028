import React from "react";

import { SentryRoute } from "../../sentry";

import {
  BOOKMARKS,
  BOOKMARK_COLLECTIONS,
  BookmarkCollectionsPage,
  CollectionPage
} from "@arbolus-technologies/features/common";

export const BookmarksRouter = [
  <SentryRoute
    path={BOOKMARK_COLLECTIONS}
    key={BOOKMARK_COLLECTIONS}
    component={BookmarkCollectionsPage}
    exact
  />,
  <SentryRoute
    path={BOOKMARKS}
    key={BOOKMARKS}
    component={CollectionPage}
    exact
  />
];
