import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AntDSelectMenu,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import {
  AdminService,
  ApiErrorResponse,
  ApiPaginatedRequest,
  CIQError,
  DefaultToasterService,
  SORT_DIRECTION,
  ToasterService,
  User
} from "@arbolus-technologies/api";

interface ManagerSelectorProps {
  mode?: "multiple" | "tags";
  initialValue: AntDSelectValueType | null;
  onChange: (values: AntDSelectValueType, selectedManagers: User[]) => void;
  adminService?: typeof AdminService;
  notificationService?: ToasterService;
}

export const ManagerSelector: React.FC<ManagerSelectorProps> = ({
  mode,
  initialValue,
  onChange,
  adminService = AdminService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("companySelector");

  const [TManagers, setTManagers] = useState<User[]>([]);

  const handleGetManagers = (query: string, offset = 0) => {
    const pagination: ApiPaginatedRequest = {
      offset,
      searchTerm: query,
      limit: 5,
      orderBy: "firstName",
      orderDirection: SORT_DIRECTION.ASCENDING
    };

    adminService
      .getAdminUser(
        true,
        pagination.offset,
        pagination.limit,
        pagination.searchTerm,
        pagination.orderBy,
        pagination.orderDirection
      )
      .subscribe(
        ({ items }) => {
          const newList = items.map((item) => ({
            ...item,
            value: item.id,
            key: item.id,
            label: `${item.firstName} ${item.lastName}`
          }));
          setTManagers((prevState) =>
            offset === 0 ? newList : prevState.concat(newList)
          );
        },
        (error: ApiErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  const handleOnChange = (values: AntDSelectValueType) => {
    onChange(values, TManagers);
  };

  return (
    <AntDSelectMenu
      options={TManagers}
      placeholder={t("placeholder")}
      optionFilterProp="name"
      labelInValue
      showLoadMoreButton
      mode={mode}
      value={initialValue}
      onChange={handleOnChange}
      onGetItems={handleGetManagers}
      handleClearList={() => setTManagers([])}
    />
  );
};
