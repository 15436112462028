import React from "react";

import { arbolusLogo } from "@arbolus-technologies/theme";

import styles from "./AuthPageHeader.module.scss";

export function AuthPageHeader({
  title,
  subtitle
}: {
  title: string;
  subtitle?: string;
}): React.ReactElement {
  return (
    <div className={styles.header}>
      <div>
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <img className={styles.arbolusLogo} src={arbolusLogo} alt="Arbolus" />
    </div>
  );
}
