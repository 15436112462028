import { MouseEventHandler, useState } from "react";

import { Loader } from "@arbolus-technologies/ui/components";

import { useRegister } from "../../hooks/useRegister";
import { registerWithLi } from "../../utils/linkedInAuth";
import { EmailOrLI } from "../EmailOrLI/EmailOrLI";
import { TermsConditions } from "../TermsConditions/TermsConditions";

export function ExpertRegisterMethodSelector({
  onEmailRegister,
  footer
}: { onEmailRegister: MouseEventHandler; footer: React.ReactNode }) {
  const registrationData = useRegister();
  const [termsVisible, setTermsVisible] = useState(false);

  if (!registrationData) return <Loader />;

  if (termsVisible) {
    return (
      <TermsConditions
        terms={registrationData.terms.text}
        acceptTerms={registerWithLi}
      />
    );
  }

  return (
    <EmailOrLI
      onLI={() => setTermsVisible(true)}
      onEmail={onEmailRegister}
      footer={footer}
    />
  );
}
