import { ICellRendererParams } from "ag-grid-enterprise";
import { Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  MONITORING_LIST_COMPANY_STATUS,
  MonitoringListService,
  UserMonitoringListModel
} from "@arbolus-technologies/api";

import { useReport } from "../../../Hooks/useReport";
import { SelectLabelRenderer } from "./SelectLabelRenderer";

export function MonitoringStatusSelectRenderer(
  params: ICellRendererParams<
    UserMonitoringListModel,
    MONITORING_LIST_COMPANY_STATUS
  >
) {
  const { t } = useTranslation("userMonitoringListPage");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clientUserId } = params.colDef?.cellRendererParams ?? {};
  const { openReportRequestModal } = useReport(
    params.data?.vendorCompanyId!,
    params.data?.vendorCompanyName!
  );

  async function handleOnChange(status: MONITORING_LIST_COMPANY_STATUS) {
    setIsLoading(true);
    try {
      await MonitoringListService.updateCompanySignal({
        companyId: params.data?.vendorCompanyId as string,
        clientUserId,
        status
      });
      params.setValue!(status);

      const companyName = params.data?.vendorCompanyName;
      if (status === MONITORING_LIST_COMPANY_STATUS.MONITORING) {
        DefaultToasterService.showSuccess(
          t("statusChangedToMonitoring", { companyName })
        );
      } else if (status === MONITORING_LIST_COMPANY_STATUS.PASSIVE) {
        DefaultToasterService.showSuccess(
          t("statusChangedToPassive", { companyName })
        );
      } else if (status === MONITORING_LIST_COMPANY_STATUS.PRIORITY) {
        openReportRequestModal();
      }
    } catch (error) {
      DefaultToasterService.showApiErrors(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Select
      value={params.value}
      options={Object.entries(MONITORING_LIST_COMPANY_STATUS).map(
        ([key, value]) => ({ value, label: value })
      )}
      onChange={handleOnChange}
      loading={isLoading}
      labelRender={({ value }) => (
        <SelectLabelRenderer value={value as MONITORING_LIST_COMPANY_STATUS} />
      )}
    />
  );
}
