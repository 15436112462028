import { Col, Flex, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  Survey,
  SurveyService,
  ToasterService,
  UpdateSurvey
} from "@arbolus-technologies/api";
import { SurveyParamUrlType } from "@arbolus-technologies/models/survey";

import { CloseSurveyCard } from "./CloseSurveyCard";
import { LandingLinkCard } from "./LandingLinkCard";
import { SurveyDetailsForm } from "./SurveyDetailsForm";

interface EditSurveyProps {
  surveyDetails: Survey;
  isSurveyClosed: boolean;
  onSurveyClose: () => void;
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const EditSurvey: React.FC<EditSurveyProps> = ({
  surveyDetails,
  isSurveyClosed = false,
  onSurveyClose,
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("editSurvey");
  const { surveyId } = useParams<SurveyParamUrlType>();

  const [isSaving, setIsSaving] = useState(false);

  const updateSurvey = (values: UpdateSurvey) => {
    setIsSaving(true);
    surveyService.updateSurvey(values).subscribe(
      () => {
        setIsSaving(false);
        notificationService.showSuccess(t("surveyUpdated"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsSaving(false);
      }
    );
  };

  return (
    <Row gutter={24}>
      <Col span={16}>
        <SurveyDetailsForm
          isSaving={isSaving}
          surveyDetails={surveyDetails}
          isSurveyClosed={isSurveyClosed}
          saveSurvey={updateSurvey}
        />
      </Col>
      <Col span={8}>
        <Flex vertical gap={24}>
          <LandingLinkCard
            surveyId={surveyId}
            isSurveyClosed={isSurveyClosed}
          />
          <CloseSurveyCard
            surveyId={surveyId}
            isSurveyClosed={isSurveyClosed}
            onSurveyClose={onSurveyClose}
          />
        </Flex>
      </Col>
    </Row>
  );
};
