export enum REFERRAL_STAGE {
  SHORTLIST = "ShortList",
  CANDIDATE = "Candidate",
  APPROVED = "Accept",
  REJECTED = "Reject"
}

export enum REFERRAL_SUB_STATUS {
  PENDING = "Pending",
  ACCEPT = "Accept",
  REJECT = "Reject"
}

export enum COMPLIANCE_STATUS {
  PENDING = "Pending",
  ACCEPT = "Accept",
  REJECT = "Reject",
  UNAVAILABLE = "Unavailable"
}

export enum APPLICATION_STATUS {
  PENDING = "Pending",
  SENT = "Sent",
  ACCEPT = "Accept",
  REJECT = "Reject"
}

export enum REJECT_REASON {
  ALREADY_ENGAGED = "AlreadyEngaged",
  WRONG_GEOGRAPHY = "WrongGeography",
  COMPANY_COVERED = "CompanyCovered",
  NOT_SENIOR = "NotSenior",
  ANGLE_COMPLETED = "AngleCompleted",
  OTHER = "Other"
}

export enum BOOKED_CALL_STATUS {
  UPCOMING_CALLS = "upcomingCalls",
  CALLS_DONE = "callsDone",
  ATTENTION_REQUIRED = "attentionRequired"
}

export enum COMPLIANCE_QUESTION_APPROVAL_FORM {
  COMPLIANCE = "compliance",
  CHAPERONE_CALL = "chaperoneCall",
  COMPLIANCE_NOTE = "complianceNote"
}

export enum REPEATED_ENGAGEMENT_STATUS {
  PENDING = "Pending",
  PASSED = "Passed",
  REJECTED = "Rejected"
}
