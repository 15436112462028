import { ColDef } from "ag-grid-community";
import moment from "moment";

import { CustomerSurvey } from "@arbolus-technologies/models/common";
import {
  fixedWidth,
  floatingFilter
} from "@arbolus-technologies/ui/components";
import {
  TRANSACTION_FORMAT,
  displayUserName
} from "@arbolus-technologies/utils";

import i18next from "i18next";
import { ActionsCellRenderer } from "./ActionsCellRenderer";
import { AddCustomerSurveyStatusPanel } from "./AddCustomerSurveyStatusPanel/AddCustomerSurveyStatusPanel";
import StatusCellRenderer from "./StatusCellRenderer";

export const defaultColDef: ColDef = {
  resizable: false,
  sortable: false,
  suppressMovable: true,
  suppressHeaderContextMenu: true,
  suppressHeaderMenuButton: true
};

export const statusBar = {
  statusPanels: [
    {
      statusPanel: AddCustomerSurveyStatusPanel,
      align: "right"
    }
  ]
};

export const colDefs: ColDef[] = [
  {
    field: "title",
    cellRenderer: "agGroupCellRenderer",
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: { maxNumConditions: 1, filterOptions: ["contains"] },
    ...floatingFilter
  },
  {
    field: "owner",
    valueGetter: ({ data }) => ownerValueGetter(data),
    ...fixedWidth(170),
    filter: "agTextColumnFilter",
    filterParams: { maxNumConditions: 1, filterOptions: ["contains"] },
    ...floatingFilter
  },
  {
    field: "created",
    ...fixedWidth(120),
    valueGetter: ({ data }) =>
      moment.utc(data.created).format(TRANSACTION_FORMAT)
  },
  {
    field: "completionStatus",
    headerName: i18next.t("customerSurveyTable:status"),
    ...fixedWidth(150),
    cellRenderer: StatusCellRenderer
  },
  {
    field: undefined,
    ...fixedWidth(220),
    headerName: i18next.t("customerSurveyTable:actions"),
    cellRenderer: ActionsCellRenderer
  }
];

function ownerValueGetter(customerSurvey: CustomerSurvey): string {
  return displayUserName(customerSurvey.owner);
}
