export enum SURVEY_ORDER_BY {
  CREATED = "Created"
}

export enum SURVEY_STATUS {
  ACTIVE = "Active",
  CLOSED = "Closed"
}

export const SURVEY_STATUS_COLORS = {
  [SURVEY_STATUS.ACTIVE]: "green",
  [SURVEY_STATUS.CLOSED]: "red"
};

export enum SURVEY_PROVIDERS {
  INC_QUERY = "IncQuery",
  QUALTRICS = "Qualtrics",
  INTELLI_SURVEY = "IntelliSurvey",
  GOOGLE_FORMS = "GoogleForms",
  OTHER = "Other"
}

export enum SURVEY_EXPERT_STATUS {
  IN_PROGRESS = "InProgress",
  COMPLETED = "Completed"
}

export enum SURVEY_EXPERT_PAYMENT_METHOD {
  GIFT_CARD = "GiftCard",
  BANK_TRANSFER = "BankTransfer"
}

export enum SURVEY_PAYMENT_PAGE {
  METHOD_SELECTION = "MethodSelection",
  GIFT_CARD_SELECTED = "GiftCardSelected"
}

export const SURVEY_EXPERT_STATUS_COLORS = {
  [SURVEY_EXPERT_STATUS.IN_PROGRESS]: "success",
  [SURVEY_EXPERT_STATUS.COMPLETED]: "processing"
};

export enum SURVEY_TABS {
  DETAILS = "details",
  EXPERTS = "experts"
}
