import i18next from "i18next";

import {
  ADOPTION_STAGE,
  ChatUserAPIRole,
  ChatUserRole,
  NPS,
  PERSONA,
  RENEWAL_INTENT,
  SPEND
} from "./enums";

// The order of the steps is important
export const PROJECT_CREATION_STEPS = {
  PROJECT_DETAILS: "projectDetails",
  CREATE_ANGLE: "createAngle",
  ANGLES_OVERVIEW: "anglesOverview",
  CHAT: "chat",
  AGREEMENT: "agreement"
} as const;

export type ProjectCreationStep =
  (typeof PROJECT_CREATION_STEPS)[keyof typeof PROJECT_CREATION_STEPS];

export const DEFAULT_SLOTS_NUMBER = 6;
export const MAX_SLOTS_NUMBER = 100;

export enum COMPLIANCE_TYPE {
  NONE = "None",
  INTERVENTION = "Intervention",
  STRICT = "Strict"
}

export const ANGLE_SEARCH_PARAM = "angleId";

export enum AdminChatMode {
  ClientChat = "ClientChat",
  ExpertSupport = "ExpertSupport"
}

// Used as translation keys
export enum ChatType {
  // PATTERN: Sender [me (current user)] to recipient

  // support chat with arbolus team
  // reverse: AdminToProject
  ClientToProject = "clientToProjectChatType",

  // reverse: AdminToAngle
  ClientToAngle = "clientToAngleChatType",

  // reverse: AdminToExpertAndClient, ExpertToProject
  ClientToExpertAndAdmin = "clientToExpertAndAdminChatType",

  // support chat with arbolus team
  // reverse: ClientToProject
  AdminToProject = "adminToProjectChatType",

  // reverse: ClientToAngle
  AdminToAngle = "adminToAngleChatType",

  // reverse: ClientToExpertAndAdmin, ExpertToProject
  AdminToExpertAndClient = "adminToExpertAndClientChatType",

  // reverse: ExpertToAdminTeam
  AdminToExpertSupport = "adminToExpertSupportChatType",

  // support chat for experts
  // called "Arbolus Team" in UI
  // reverse: AdminToExpertSupport
  ExpertToAdminTeam = "expertToAdminTeamChatType",

  // chat for one expert and arbolus team + client team
  // called "Project Chat" in UI
  // reverse: AdminToExpertAndClient, ClientToExpertAndAdmin
  ExpertToProject = "expertToProjectChatType"
}

export const CHAT_API_USER_TO_CHAT_USER = {
  [ChatUserAPIRole.Admin]: ChatUserRole.Admin,
  [ChatUserAPIRole.Client]: ChatUserRole.Client,
  [ChatUserAPIRole.Expert]: ChatUserRole.Expert
};

export const PERSONA_OPTIONS = [
  {
    label: i18next.t("customerOfFilter:decisionMaker"),
    value: PERSONA.Decision_Maker
  },
  { label: i18next.t("customerOfFilter:user"), value: PERSONA.User },
  { label: i18next.t("customerOfFilter:other"), value: PERSONA.Other }
];

export const SPEND_OPTIONS = [
  { label: i18next.t("customerOfFilter:small"), value: SPEND.Small },
  { label: i18next.t("customerOfFilter:medium"), value: SPEND.Medium },
  { label: i18next.t("customerOfFilter:large"), value: SPEND.Large }
];

export const ADOPTION_STAGE_OPTIONS = [
  {
    label: i18next.t("customerOfFilter:pilot"),
    value: ADOPTION_STAGE.Pilot
  },
  {
    label: i18next.t("customerOfFilter:moreThan1Year"),
    value: ADOPTION_STAGE.MoreThan1Year
  },
  {
    label: i18next.t("customerOfFilter:lessThan1Year"),
    value: ADOPTION_STAGE.LessThan1Year
  },
  {
    label: i18next.t("customerOfFilter:offboarding"),
    value: ADOPTION_STAGE.Offboarding
  }
];

export const NPS_OPTIONS = [
  { label: i18next.t("customerOfFilter:promoters"), value: NPS.Promoters },
  { label: i18next.t("customerOfFilter:passives"), value: NPS.Passives },
  { label: i18next.t("customerOfFilter:detractors"), value: NPS.Detractors }
];

export const RENEWAL_INTENT_OPTIONS = [
  { label: i18next.t("customerOfFilter:yes"), value: RENEWAL_INTENT.Yes },
  { label: i18next.t("customerOfFilter:no"), value: RENEWAL_INTENT.No },
  { label: i18next.t("customerOfFilter:unsure"), value: RENEWAL_INTENT.Unsure }
];
