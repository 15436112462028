import { GridReadyEvent } from "ag-grid-community";
import React from "react";

import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { Flex } from "antd";
import { ExpertSurveysTableDataSource } from "./SurveyExpertTableDataSource";
import { COLUMN_DEFINITIONS, DEFAULT_COLUMN_DEFINITION } from "./constants";

interface SurveysProps {
  expertId: string;
}

export const Surveys: React.FC<SurveysProps> = ({ expertId }) => {
  const onGridReady = (event: GridReadyEvent) => {
    const { api } = event;
    api.setGridOption(
      "serverSideDatasource",
      new ExpertSurveysTableDataSource(expertId)
    );
    api.sizeColumnsToFit();
  };

  return (
    <Flex className="d-flex h-100 flex-column">
      <ArbolusGrid
        rowModelType="serverSide"
        columnDefs={COLUMN_DEFINITIONS}
        defaultColDef={DEFAULT_COLUMN_DEFINITION}
        onGridReady={onGridReady}
        getRowId={({ data }) => data.surveyId}
        suppressRowClickSelection
        suppressMenuHide
        tooltipShowDelay={500}
        skipHeaderOnAutoSize
        pagination
        paginationAutoPageSize
      />
    </Flex>
  );
};
