import { Button, Flex, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  DateString,
  ExpertWorkHistory
} from "@arbolus-technologies/models/common";
import { ExpertDiscoverFilters } from "@arbolus-technologies/models/project";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { WorkHistoryItem } from "./WorkHistoryItem";

import styles from "./WorkHistory.module.scss";

const VISIBLE_WORK_HISTORY_ITEMS = 3;

const { Text } = Typography;

interface WorkHistoryCollapseProps {
  workHistories: ExpertWorkHistory[];
  discoverFilters?: ExpertDiscoverFilters;
  keywordsList?: string[] | null;
  highlightWorkHistoryTitle?: boolean;
  highlightWorkHistoryCompany?: boolean;
  workHistoryLastUpdateDate?: DateString | null;
}

export const WorkHistoryCollapse: React.FC<WorkHistoryCollapseProps> = ({
  workHistories,
  discoverFilters,
  keywordsList,
  highlightWorkHistoryCompany = false,
  highlightWorkHistoryTitle = false,
  workHistoryLastUpdateDate
}) => {
  const { t } = useTranslation("workHistory");
  const [isWorkHistoryOpen, setIsWorkHistoryOpen] = useState(false);

  const getWorkHistoryItem = (workHistoryItem: ExpertWorkHistory) => {
    return (
      <li key={workHistoryItem.id}>
        <WorkHistoryItem
          {...workHistoryItem}
          discoverFilters={discoverFilters}
          keywordsList={keywordsList}
          highlightWorkHistoryCompany={highlightWorkHistoryCompany}
          highlightWorkHistoryTitle={highlightWorkHistoryTitle}
        />
      </li>
    );
  };

  return (
    <>
      <ul className={styles.workHistoryList}>
        {workHistories
          .slice(0, VISIBLE_WORK_HISTORY_ITEMS)
          .map((workHistoryItem) => getWorkHistoryItem(workHistoryItem))}
        {workHistories.length > VISIBLE_WORK_HISTORY_ITEMS && (
          <Collapse isOpen={isWorkHistoryOpen}>
            {workHistories
              .slice(VISIBLE_WORK_HISTORY_ITEMS)
              .map((workHistoryItem) => getWorkHistoryItem(workHistoryItem))}
          </Collapse>
        )}
      </ul>
      <Flex vertical gap={8}>
        {workHistoryLastUpdateDate && (
          <Text code>
            {t("updatedDateWH", {
              date: formatDate(workHistoryLastUpdateDate, APP_DATE_FORMAT)
            })}
          </Text>
        )}
        {workHistories.length > VISIBLE_WORK_HISTORY_ITEMS && (
          <Flex>
            <Button
              size="small"
              type="link"
              icon={
                <AntDIcon name={isWorkHistoryOpen ? "arrowUp" : "arrowDown"} />
              }
              iconPosition="end"
              onClick={() => setIsWorkHistoryOpen((prev) => !prev)}
            >
              {t(isWorkHistoryOpen ? "showLess" : "showMore")}
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  );
};
