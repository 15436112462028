import { ICellRendererParams } from "ag-grid-community";
import moment from "moment";

import { SlotRow } from "../../Modules/ReferralsTable/scheduling/SlotRow";

export function GroupSlotsRenderer({
  node,
  context: { timezone }
}: ICellRendererParams<SlotRow>) {
  const slotRow = node.allLeafChildren[0].data;

  if (node.group && node.level === 1) {
    return formatSlot(slotRow!.startTime, slotRow!.endTime, timezone);
  }

  if (node.group && node.level === 0) {
    return formatDay(slotRow!.startTime, timezone);
  }

  return null;
}

function formatDay(date: Date, timezone: string): string {
  const startMoment = moment.utc(date).tz(timezone);
  const todayMoment = moment.utc().tz(timezone);
  const tomorrowMoment = moment.utc().add(1, "day").tz(timezone);
  if (startMoment.isSame(todayMoment, "day")) {
    return "Today";
  }

  if (startMoment.isSame(tomorrowMoment, "day")) {
    return "Tomorrow";
  }

  return startMoment.format("ddd Do");
}

function formatSlot(start: Date, end: Date, timezone: string): string {
  const startMoment = moment.utc(start).tz(timezone);
  const endMoment = moment.utc(end).tz(timezone);

  return `${startMoment.format("LT")} - ${endMoment.format("LT")}`;
}
