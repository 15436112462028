import { Button, Card, Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ContentTextItem } from "./ContentTextItem";
import styles from "./NoMonitoringListPage.module.scss";

interface NoMonitoringListPageProps {
  showModal: () => void;
}

export const NoMonitoringListPage: React.FC<NoMonitoringListPageProps> = ({
  showModal
}) => {
  const { t } = useTranslation("noMonitoringListPage");

  return (
    <Card className={styles.card}>
      <Flex justify="space-between">
        <Flex vertical gap={16}>
          <Typography.Text strong className={styles.title}>
            {t("title")}
          </Typography.Text>
          <ContentTextItem id={1} title={t(`contentText${1}`)} />
          <ContentTextItem id={2} title={t(`contentText${2}`)} />
          <ContentTextItem id={3} title={t(`contentText${3}`)} />
        </Flex>
        <Flex align="self-end">
          <Button type="primary" onClick={showModal}>
            {t("addCompanies")}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
