import i18next from "i18next";

import { ExpertsListPageTab } from "@arbolus-technologies/api";
import {
  EVENT_PANEL_ROUTE,
  EXPERT_SELF_PROFILE_EDIT,
  PROJECT_APPLICATION_ROUTE,
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_CHAT_ROUTE,
  PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE
} from "@arbolus-technologies/routes";
import {
  APP_DATE_CHAT_EVENT_END_FORMAT,
  APP_DATE_CHAT_EVENT_START_FORMAT,
  WORKSPACE_TYPES
} from "@arbolus-technologies/utils";

import {
  EX_LINK_EXPERT_WELCOME_BANNER,
  EX_LINK_WELCOME_BANNER
} from "../constants/externalLink";
import {
  DRAFT_PROJECT_ROUTE,
  NEW_PROJECT,
  PROJECTS,
  USERS
} from "../constants/navigation/authRoutes";
import { CANOPY_DETAILS_PAGE_ROUTE } from "../constants/navigation/canopyRoutes";
import {
  APPLICATION_DECLINED_PANEL_ROUTE,
  CANDIDATE_PICKER_PANEL_ROUTE,
  COMPLIANCE_ISSUE_PANEL_ROUTE,
  DOCUMENT_PANEL_ROUTE,
  EXPERT_INVITE_PANEL_ROUTE,
  REFERRAL_PANEL_ROUTE
} from "../constants/navigation/panelRoutes";
import {
  PROJECT_BRIEF_ROUTE,
  PROJECT_EXPERTS_ROUTE,
  PROJECT_ROUTE
} from "../constants/navigation/projectRoutes";
import {
  EVENT_NOTIFICATION_STATUS,
  EXPERT_NOTIFICATION_REJECTION_TYPE,
  INBOX_NOTIFICATION_ACTION,
  INBOX_NOTIFICATION_TYPE,
  NOTIFICATION_ICON
} from "../constants/notifications";
import { notificationTypeLabel } from "../containers/dashboard/components/inbox/categoryItem/CategoryItem";
import { FILE_TYPE } from "../models/documents";
import {
  AdminCreatesProjectNotificationData,
  ApplicationCompletionNotificationData,
  CanopyLaunchedNotificationData,
  DocumentSharedWithClientNotificationData,
  DocumentSharedWithExpertNotificationData,
  EventInvitesNotificationData,
  ExpertAddedToProjectClientNotificationData,
  ExpertAddedToProjectExpertNotificationData,
  ExpertAssignedToProjectExpertNotificationData,
  ExpertCandidateRejectedExpertNotificationData,
  ExpertCandidateRejectedTeamNotificationData,
  ExpertMessagesClientTeamNotificationData,
  ExpertSubmittedCanopyNotificationData,
  ExpertsAssignedToProjectNotificationData,
  InboxNotification,
  MemberAcceptInvitationClientNotificationData,
  NewProjectMemberNotificationData,
  NotificationDataType,
  ProjectBriefUpdatedNotificationData,
  ProjectDraftReminderForUserNotificationData,
  ProjectLiveUserNotificationData,
  ProjectUnreadMessagesNotificationData,
  ReferralComplianceForClientNotificationData,
  ReferralResetExpertNotificationData,
  TranscriptAvailableNotificationData
} from "../models/notifications";
import { NotificationView } from "../models/view/notifications";
import { eventService as EventService } from "./EventService";

export const inboxService = {
  generateInboxView: ({
    notificationData
  }: InboxNotification): NotificationView => {
    const dataToView =
      notificationDataToViewFunctionMap[notificationData.type] ||
      notificationDataToViewFunctionMap.default;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return dataToView(notificationData as any);
  }
};

function createNotificationView(
  title: string,
  subTitle = "",
  subInfo = "",
  icon = NOTIFICATION_ICON.GENERIC,
  link?: string,
  hideTime = false,
  openNewTab = false,
  data?: boolean,
  linkToInboxSidePanel = false
): NotificationView {
  return {
    title,
    subTitle,
    subInfo,
    icon,
    link,
    openNewTab,
    hideTime,
    data,
    linkToInboxSidePanel
  };
}

type NotificationDataToViewType = {
  [K in INBOX_NOTIFICATION_TYPE | "default"]: (
    notificationData: Extract<NotificationDataType, { type: K }>
  ) => NotificationView;
};

const notificationDataToViewFunctionMap: NotificationDataToViewType = {
  [INBOX_NOTIFICATION_TYPE.CanopyLaunchedNotification]: (
    notificationData: CanopyLaunchedNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:canopyIsLaunchedNotification"),
      notificationData.canopyTitle,
      notificationData.projectName,
      NOTIFICATION_ICON.ROCKET,
      CANOPY_DETAILS_PAGE_ROUTE(notificationData.canopyId)
    ),
  [INBOX_NOTIFICATION_TYPE.ExpertSubmittedCanopyNotification]: (
    notificationData: ExpertSubmittedCanopyNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:newCanopyExpertSubmitted", {
        expertName: notificationData.expertName
      }),
      notificationData.canopyTitle,
      notificationData.projectName,
      NOTIFICATION_ICON.TICK,
      CANOPY_DETAILS_PAGE_ROUTE(notificationData.canopyId)
    ),

  [INBOX_NOTIFICATION_TYPE.AdminCreatesProjectNotification]: (
    notificationData: AdminCreatesProjectNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:adminCreatesProjectNotification"),
      notificationData.projectName,
      i18next.t("inbox:newProject"),
      NOTIFICATION_ICON.STAR,
      DRAFT_PROJECT_ROUTE(notificationData.draftId)
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectClientNotification]: (
    notificationData: ExpertAddedToProjectClientNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:expertAddedToProjectClientNotification", {
        count: +notificationData.numOfApprovedCandidates,
        numOfApprovedCandidates: notificationData.numOfApprovedCandidates
      }),
      notificationData.projectName,
      "",
      NOTIFICATION_ICON.TICK,
      PROJECT_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.ProjectBriefUpdatedNotification]: (
    notificationData: NotificationDataType
  ) => {
    const { projectId, projectName } =
      notificationData as ProjectBriefUpdatedNotificationData;

    return createNotificationView(
      i18next.t("inbox:projectBriefUpdateNotification"),
      projectName,
      i18next.t("inbox:brief"),
      NOTIFICATION_ICON.PROJECT_BRIEF,
      PROJECT_BRIEF_ROUTE(projectId)
    );
  },

  [INBOX_NOTIFICATION_TYPE.ProjectDraftReminderForUserNotification]: (
    notificationData: ProjectDraftReminderForUserNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:projectDraftReminderForUserNotification"),
      notificationData.projectName,
      i18next.t("inbox:brief"),
      NOTIFICATION_ICON.PROJECT_BRIEF,
      DRAFT_PROJECT_ROUTE(notificationData.draftId)
    ),

  [INBOX_NOTIFICATION_TYPE.ProjectLiveUserNotification]: (
    notificationData: ProjectLiveUserNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:projectLiveUserNotification"),
      notificationData.projectName,
      i18next.t("inbox:newProject"),
      NOTIFICATION_ICON.TICK,
      PROJECT_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertCandidateRejectedExpertNotification]: (
    notificationData: ExpertCandidateRejectedExpertNotificationData
  ) => {
    const isReviewReject =
      notificationData.rejectionType ===
      EXPERT_NOTIFICATION_REJECTION_TYPE.Review;

    return createNotificationView(
      isReviewReject
        ? i18next.t("inbox:reviewRejectNotification")
        : i18next.t("inbox:complianceRejectNotification"),
      notificationData.projectName,
      i18next.t("inbox:applications"),
      NOTIFICATION_ICON.CROSS,
      isReviewReject
        ? APPLICATION_DECLINED_PANEL_ROUTE()
        : COMPLIANCE_ISSUE_PANEL_ROUTE(),
      false,
      false,
      false,
      true
    );
  },

  [INBOX_NOTIFICATION_TYPE.ReferralResetExpertNotification]: (
    notificationData: ReferralResetExpertNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:referralResetExpertNotification"),
      notificationData.projectName,
      i18next.t("inbox:applications"),
      NOTIFICATION_ICON.PROJECT_BRIEF,
      PROJECT_APPLICATION_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.ApplicationCompletionNotification]: (
    notificationData: ApplicationCompletionNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:applicationCompletionNotification"),
      notificationData.projectName,
      i18next.t("inbox:applications"),
      NOTIFICATION_ICON.APPLICATION_REVIEWED,
      PROJECT_APPLICATION_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.DocumentSharedWithClientNotification]: (
    notificationData: DocumentSharedWithClientNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:documentSharedWithClientNotification", {
        workspaceName: notificationData.expertFirstName
      }),
      notificationData.projectName,
      notificationData.workspaceTitle,
      NOTIFICATION_ICON.DOCUMENT,
      DOCUMENT_PANEL_ROUTE(
        notificationData.documentId,
        notificationData.projectId
      )
    ),

  [INBOX_NOTIFICATION_TYPE.DocumentSharedWithExpertNotification]: (
    notificationData: DocumentSharedWithExpertNotificationData
  ) => {
    const isTranscript = notificationData.documentType === FILE_TYPE.TRANSCRIPT;

    return createNotificationView(
      i18next.t("inbox:documentSharedWithExpertNotification", {
        workspaceTitle: notificationData.workspaceTitle
      }),
      notificationData.projectName,
      notificationData.workspaceTitle,
      NOTIFICATION_ICON.DOCUMENT,
      isTranscript
        ? PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE(
            notificationData.projectId,
            notificationData.documentId
          )
        : DOCUMENT_PANEL_ROUTE(
            notificationData.documentId,
            notificationData.projectId
          ),
      false,
      false,
      isTranscript
    );
  },

  [INBOX_NOTIFICATION_TYPE.TranscriptAvailableNotification]: (
    notificationData: TranscriptAvailableNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:transcriptAvailableNotificationData", {
        eventName: notificationData.eventName
      }),
      notificationData.projectName,
      "",
      NOTIFICATION_ICON.DOCUMENT,
      DOCUMENT_PANEL_ROUTE(
        notificationData.documentId,
        notificationData.projectId
      )
    ),

  [INBOX_NOTIFICATION_TYPE.MemberAcceptInvitationClientNotification]: (
    notificationData: MemberAcceptInvitationClientNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:memberAcceptInvitationClientNotification", {
        memberName: notificationData.memberName
      }),
      i18next.t("inbox:users"),
      "",
      NOTIFICATION_ICON.USER,
      USERS
    ),

  [INBOX_NOTIFICATION_TYPE.NewProjectMemberNotification]: (
    notificationData: NewProjectMemberNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:newProjectMemberNotification", {
        inviteeFirstName: notificationData.inviteeFirstName
      }),
      notificationData.projectName,
      i18next.t("inbox:clientTeam"),
      NOTIFICATION_ICON.NEW_PROJECT_MEMBER,
      PROJECT_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertsAssignedToProjectNotification]: (
    notificationData: ExpertsAssignedToProjectNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:expertsAssignedToProjectNotification", {
        count: Number(notificationData.numOfCandidates)
      }),
      notificationData.projectName,
      i18next.t("inbox:experts"),
      NOTIFICATION_ICON.CANDIDATE,
      CANDIDATE_PICKER_PANEL_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertCandidateRejectedTeamNotification]: (
    notificationData: ExpertCandidateRejectedTeamNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:expertCandidateRejectNotification", {
        expertName: notificationData.expertName
      }),
      notificationData.projectName,
      i18next.t("inbox:applications"),
      NOTIFICATION_ICON.CROSS,
      REFERRAL_PANEL_ROUTE(
        {
          expertId: notificationData.expertId,
          referralId: notificationData.referralId,
          projectId: notificationData.projectId
        },
        PROJECT_EXPERTS_ROUTE(
          notificationData.projectId,
          ExpertsListPageTab.Reject
        )
      ),
      false,
      false
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertMessagesClientTeamNotification]: (
    notificationData: ExpertMessagesClientTeamNotificationData
  ) => {
    let icon = NOTIFICATION_ICON.MESSAGE;
    if (notificationData.workspaceType === WORKSPACE_TYPES.WORKSPACE) {
      icon = NOTIFICATION_ICON.SUPPORT_CHAT;
    }

    return createNotificationView(
      i18next.t("inbox:expertMessagesClientTeamNotification", {
        expertFirstName: notificationData.expertFirstName
      }),
      notificationData.projectName,
      i18next.t("inbox:chat"),
      icon,
      PROJECT_CHAT_ROUTE(
        notificationData.projectId,
        notificationData.workspaceId
      )
    );
  },

  [INBOX_NOTIFICATION_TYPE.ReferralComplianceForClientNotification]: (
    notificationData: ReferralComplianceForClientNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:complianceCheckNotification", {
        expertName: notificationData.expertName
      }),
      notificationData.projectName,
      i18next.t("inbox:experts"),
      NOTIFICATION_ICON.COMPLIANCE,
      REFERRAL_PANEL_ROUTE({
        expertId: notificationData.expertId,
        referralId: notificationData.referralId,
        projectId: notificationData.projectId,
        action: INBOX_NOTIFICATION_ACTION.COMPLIANCE
      }),
      false,
      false,
      false,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertWelcomeNotification]: () =>
    createNotificationView(
      i18next.t("inbox:expertWelcomeNotification"),
      i18next.t("inbox:guides"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      EX_LINK_EXPERT_WELCOME_BANNER.HOW_TO_USE_SYSTEM,
      true,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertCompleteProfileNotification]: () =>
    createNotificationView(
      i18next.t("inbox:expertCompleteProfileNotification"),
      i18next.t("inbox:profile"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      EXPERT_SELF_PROFILE_EDIT,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertPrepareProjectNotification]: () =>
    createNotificationView(
      i18next.t("inbox:expertPrepareProjectNotification"),
      i18next.t("inbox:guides"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      EX_LINK_EXPERT_WELCOME_BANNER.USEFUL_TIPS,
      true,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertBankingInfoUpdNotification]: () =>
    createNotificationView(
      i18next.t("inbox:expertBankingInfoUpdNotification"),
      i18next.t("inbox:profile"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      undefined,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.UserWelcomeNotification]: () =>
    createNotificationView(
      i18next.t("inbox:userWelcomeNotification"),
      i18next.t("inbox:guides"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      EX_LINK_WELCOME_BANNER.HOW_TO_USE_SYSTEM,
      true,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.UserInviteTeamNotification]: () =>
    createNotificationView(
      i18next.t("inbox:userInviteTeamNotification"),
      i18next.t("inbox:account"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      USERS,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.UserCreateProjectNotification]: () =>
    createNotificationView(
      i18next.t("inbox:userCreateProjectNotification"),
      i18next.t("inbox:projects"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      NEW_PROJECT,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.UserBuildNetworkNotification]: () =>
    createNotificationView(
      i18next.t("inbox:userBuildNetworkNotification"),
      i18next.t("inbox:network"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      EXPERT_INVITE_PANEL_ROUTE,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.UserViewProjectNotification]: () =>
    createNotificationView(
      i18next.t("inbox:userViewProjectNotification"),
      i18next.t("inbox:projects"),
      i18next.t("inbox:gettingStarted"),
      NOTIFICATION_ICON.ON_BOARDING,
      PROJECTS,
      true
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertAssignedToProjectNotification]: (
    notificationData: ExpertAssignedToProjectExpertNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:expertAssignedToProjectNotification"),
      notificationData.projectName,
      i18next.t("inbox:applications"),
      NOTIFICATION_ICON.NEW_PROJECT,
      PROJECT_APPLICATION_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectExpertNotification]: (
    notificationData: ExpertAddedToProjectExpertNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:expertAddedToProjectNotification"),
      notificationData.projectName,
      i18next.t("inbox:applications"),
      NOTIFICATION_ICON.TICK,
      PROJECT_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.ProjectUnreadMessagesNotification]: (
    notificationData: ProjectUnreadMessagesNotificationData
  ) =>
    createNotificationView(
      i18next.t("inbox:projectUnreadMessagesNotification"),
      notificationData.projectName,
      i18next.t("inbox:chat"),
      NOTIFICATION_ICON.UNREAD_MESSAGE,
      PROJECT_CHAT_BASE_ROUTE(notificationData.projectId)
    ),

  [INBOX_NOTIFICATION_TYPE.EventNotification]: (
    notificationData: EventInvitesNotificationData
  ) => {
    const formattedDuration = EventService.generateEventMessageTime(
      new Date(notificationData.startTime),
      new Date(notificationData.endTime),
      APP_DATE_CHAT_EVENT_START_FORMAT,
      APP_DATE_CHAT_EVENT_END_FORMAT,
      "-"
    );

    const isNew =
      notificationData.eventStatus === EVENT_NOTIFICATION_STATUS.New;

    return createNotificationView(
      i18next.t(
        isNew
          ? "inbox:newEventInvitesNotification"
          : "inbox:modifiedEventInvitesNotification",
        {
          eventName: notificationData.eventName,
          formattedDuration
        }
      ),
      notificationData.projectName,
      i18next.t("inbox:calendar"),
      NOTIFICATION_ICON.EVENT,
      EVENT_PANEL_ROUTE(notificationData.eventId, notificationData.projectId)
    );
  },

  default: (notificationData: NotificationDataType) =>
    createNotificationView(
      `${i18next.t(
        `inbox:${notificationTypeLabel[notificationData.notificationType]}`
      )} ${i18next.t("inbox:genericNotification")}`
    )
};
