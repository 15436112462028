import { INPUT_DEBOUNCE } from "@arbolus-technologies/ui/components";
import { Form, FormInstance, Input, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const requiredRule = { required: true };
const { Text } = Typography;

interface CreateCompanyFormProps {
  form: FormInstance;
}
export const CreateCompanyForm: React.FC<CreateCompanyFormProps> = ({
  form
}) => {
  const { t } = useTranslation("createCompanyMonitoringList");

  return (
    <>
      <Form form={form} layout="vertical" name="create-company">
        <Form.Item
          validateDebounce={INPUT_DEBOUNCE}
          label={t("newCompanyName")}
          name="companyName"
          rules={[requiredRule]}
        >
          <Input placeholder={t("newCompanyPlaceholder")} />
        </Form.Item>
        <Form.Item
          validateDebounce={INPUT_DEBOUNCE}
          label={t("website")}
          name="website"
          rules={[{ ...requiredRule, type: "url" }]}
        >
          <Input placeholder={t("websitePlaceholder")} />
        </Form.Item>
        <Text type="secondary">{t("warningNewCompany")}</Text>
      </Form>
    </>
  );
};
