import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { loginWithLi } from "../../utils/linkedInAuth";

import linkedinWhiteLogo from "../../assets/linkedin-white-logo.png";

import styles from "./LinkedInLoginButton.module.scss";

export function LinkedInLoginButton() {
  const { t } = useTranslation("login");

  return (
    <Button
      type="primary"
      onClick={() => loginWithLi()}
      block
      size="large"
      className={styles.linkedinButton}
      icon={
        <img
          src={linkedinWhiteLogo}
          alt="LinkedIn"
          className={styles.linkedinLogo}
        />
      }
    >
      {t("withLI")}
    </Button>
  );
}
