import { OutputSelector, createSelector } from "reselect";

import { APP_USER_ROLES } from "../../../constants/app";
import { UserClient } from "../../../models/client";
import { LoggedInUser, UserNotificationSettings } from "../../../models/user";
import { CIQUserFeatures } from "../../../models/view/user";
import { UserService, UtilsService } from "../../../services";
import { AppState } from "../../../store/reducers";
import { AuthReducerState, initialState } from "./reducer";

const authStateSelector = (state: AppState): AuthReducerState =>
  state.auth || initialState;

const authIsUserAuthenticatedSelector = (): OutputSelector<
  AppState,
  boolean,
  (res: AuthReducerState) => boolean
> =>
  createSelector<AppState, AuthReducerState, boolean>(
    authStateSelector,
    (authReducerState) => authReducerState.currentRole !== APP_USER_ROLES.guest
  );

const authUserIdSelector = (): OutputSelector<
  AppState,
  string,
  (res: AuthReducerState) => string
> =>
  createSelector<AppState, AuthReducerState, string>(
    authStateSelector,
    (authReducerState) => authReducerState.user.id ?? ""
  );

const authUserRoleSelector = (): OutputSelector<
  AppState,
  string,
  (res: AuthReducerState) => string
> =>
  createSelector<AppState, AuthReducerState, string>(
    authStateSelector,
    ({ roles }) => UserService.generateActiveUserRole(roles)
  );

const authUserSelector = (): OutputSelector<
  AppState,
  LoggedInUser,
  (res: AuthReducerState) => LoggedInUser
> =>
  createSelector<AppState, AuthReducerState, LoggedInUser>(
    authStateSelector,
    (authReducerState) => authReducerState.user
  );

const authClientLogoUrlSelector = (): OutputSelector<
  AppState,
  string | undefined,
  (res: AuthReducerState) => string | undefined
> =>
  createSelector<AppState, AuthReducerState, string | undefined>(
    authStateSelector,
    (authReducerState) => authReducerState.user.client?.logoUrl
  );

const authClientSelector = (): OutputSelector<
  AppState,
  UserClient | undefined,
  (res: AuthReducerState) => UserClient | undefined
> =>
  createSelector(
    authStateSelector,
    (authReducerState) => authReducerState.user.client
  );

const authClientIdSelector = (): OutputSelector<
  AppState,
  string | undefined,
  (res: AuthReducerState) => string | undefined
> =>
  createSelector<AppState, AuthReducerState, string | undefined>(
    authStateSelector,
    (authReducerState) => authReducerState.user.client?.id
  );

const authUserProfileLoading = (): OutputSelector<
  AppState,
  boolean,
  (res: AuthReducerState) => boolean
> =>
  createSelector<AppState, AuthReducerState, boolean>(
    authStateSelector,
    (authReducerState) => authReducerState.isProfileLoading
  );

const authClientNameSelector = (): OutputSelector<
  AppState,
  string | undefined,
  (res: AuthReducerState) => string | undefined
> =>
  createSelector<AppState, AuthReducerState, string | undefined>(
    authStateSelector,
    (authReducerState) => authReducerState.user.client?.name
  );

const authExpertIdSelector = (): OutputSelector<
  AppState,
  string | undefined,
  (res: AuthReducerState) => string | undefined
> =>
  createSelector<AppState, AuthReducerState, string | undefined>(
    authStateSelector,
    (authReducerState) => authReducerState.user.expertId
  );

const currentUserIsInactiveExpert = () =>
  createSelector(
    authStateSelector,
    (authReducerState) =>
      (authReducerState.user?.expertId ?? "").length > 0 &&
      authReducerState.user.isActive === false
  );

const authClientUserFeaturesSelector = (): OutputSelector<
  AppState,
  CIQUserFeatures,
  (res: AuthReducerState) => CIQUserFeatures
> =>
  createSelector<AppState, AuthReducerState, CIQUserFeatures>(
    authStateSelector,
    ({ user }) => {
      const { client, complianceManager } = user;
      if (client) {
        const { marketplaceEnabled, networkEnabled } = client;
        return { marketplaceEnabled, networkEnabled, complianceManager };
      }
      return {};
    }
  );

const authClientNetworkInvitationURLSelector = (): OutputSelector<
  AppState,
  string | undefined,
  (res: AuthReducerState) => string | undefined
> =>
  createSelector<AppState, AuthReducerState, string | undefined>(
    authStateSelector,
    (authReducerState) => authReducerState.user.client?.invitationUrl
  );

const authClientTeamInvitationURLSelector = (): OutputSelector<
  AppState,
  string | undefined,
  (res: AuthReducerState) => string | undefined
> =>
  createSelector<AppState, AuthReducerState, string | undefined>(
    authStateSelector,
    (authReducerState) => authReducerState.user.client?.teamInvitationUrl
  );

const authUsernameSelector = (
  isFullName = true
): OutputSelector<AppState, string, (res: AuthReducerState) => string> =>
  createSelector<AppState, AuthReducerState, string>(
    authStateSelector,
    (authReducerState) =>
      UtilsService.displayUserName(authReducerState.user, isFullName)
  );

const authUserNotificationSettingsSelector = (): OutputSelector<
  AppState,
  UserNotificationSettings,
  (res: AuthReducerState) => UserNotificationSettings
> =>
  createSelector<AppState, AuthReducerState, UserNotificationSettings>(
    authStateSelector,
    ({ user }) => {
      const { defaultNotificationSettings, dismissNotificationBanner } = user;
      return {
        dismissNotificationBanner,
        notificationSubscription: defaultNotificationSettings
      };
    }
  );

const authIsClientUserSelector = (): OutputSelector<
  AppState,
  boolean,
  (res: AuthReducerState) => boolean
> =>
  createSelector<AppState, AuthReducerState, boolean>(
    authStateSelector,
    (authReducerState) =>
      [APP_USER_ROLES.client, APP_USER_ROLES.adminClient].includes(
        authReducerState.currentRole
      )
  );

const authClientIsTranscriptEnabledSelector = (): OutputSelector<
  AppState,
  boolean | undefined,
  (res: AuthReducerState) => boolean | undefined
> =>
  createSelector<AppState, AuthReducerState, boolean | undefined>(
    authStateSelector,
    (authReducerState) => authReducerState.user.client?.isTranscriptEnabled
  );

const authClientSubDomainSelector = (): OutputSelector<
  AppState,
  string | undefined,
  (res: AuthReducerState) => string | undefined
> =>
  createSelector<AppState, AuthReducerState, string | undefined>(
    authStateSelector,
    (authReducerState) => authReducerState.user.client?.subdomain
  );

export {
  authClientIdSelector,
  authClientLogoUrlSelector,
  authClientNameSelector,
  authClientUserFeaturesSelector,
  authClientNetworkInvitationURLSelector,
  authClientTeamInvitationURLSelector,
  authExpertIdSelector,
  currentUserIsInactiveExpert,
  authUserIdSelector,
  authUserProfileLoading,
  authUserRoleSelector,
  authUserSelector,
  authUsernameSelector,
  authUserNotificationSettingsSelector,
  authIsClientUserSelector,
  authIsUserAuthenticatedSelector,
  authClientIsTranscriptEnabledSelector,
  authClientSubDomainSelector,
  authClientSelector
};
