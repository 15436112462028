import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  ErrorResponse,
  ExpertService,
  ExpertSlot,
  ExpertSlotDetails,
  WorkHistory
} from "@arbolus-technologies/api";
import {
  ExpertProfile,
  InternalSlidePanel,
  MixPanelActions,
  MixPanelEventNames,
  useMixpanel
} from "@arbolus-technologies/features/common";
import {
  APP_TRACKING_ROUTES,
  DO_NOT_CONTACT_STATUS,
  REFERRAL_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { ScheduleProjectData } from "@arbolus-technologies/models/project";
import { PROJECT_CREATE_EVENT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";
import {
  ArbolusModal,
  ChevronButton,
  DNCTag,
  HR
} from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE, formatDateToUTC } from "@arbolus-technologies/utils";

import { ComplianceWarning } from "./ComplianceWarning";

import styles from "./TimeSlot.module.scss";

interface TimeSlotProps {
  slot: ExpertSlot;
  index: number;
  isCompliancePending: boolean;
  expert: ExpertSlotDetails;
  project: ScheduleProjectData;
}

export const TimeSlot: React.FC<TimeSlotProps> = ({
  slot,
  index,
  isCompliancePending,
  expert,
  project
}) => {
  const { t } = useTranslation("scheduleTab");
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useMixpanel();

  const { reviewStatus, referralId, expertId, hasBeenCalled, status } = expert;
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const isBainCIDPending = project.isBain && reviewStatus === "Pending";
  const [isBainModalOpen, setIsBainModalOpen] = useState(false);
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
  const [isExpertProfileOpen, setIsExpertProfileOpen] = useState(false);
  const [expertCompanyNames, setExpertCompanyNames] = useState<string>();

  const isApplicationPending =
    [REFERRAL_STATUS.ACCEPT, REFERRAL_STATUS.CANDIDATE].includes(
      expert.status
    ) && expert.applicationStatus === REFERRAL_SUB_STATE.PENDING;

  const handleBookButton = () => {
    if (isBainCIDPending) {
      getExpertWorkHistory();
    } else if (status === REFERRAL_STATUS.CANDIDATE) {
      setIsCandidateModalOpen(true);
    } else {
      createEvent();
    }
  };

  const createEvent = () => {
    // Close Bain Modal if we are on Bain process
    if (project.isBain) {
      setIsBainModalOpen(false);
    }

    // Approve the expert directly when creating the event if it's not already accepted
    if (reviewStatus !== REFERRAL_SUB_STATE.ACCEPT) {
      dispatch(
        ProjectExpertsStoreActions.updateReferralStatus(
          project.id,
          referralId,
          {
            review: REFERRAL_SUB_STATE.ACCEPT
          }
        )
      );
    }

    //Track the create event on MixPanel
    trackEvent?.(MixPanelEventNames.CreateEvent, {
      action: MixPanelActions.Initialized,
      from: APP_TRACKING_ROUTES.BOOK
    });

    // Redirect to create event page
    history.push(
      PROJECT_CREATE_EVENT_ROUTE(
        project.id,
        `referralId=${expert.referralId}&startingDate=${formatDateToUTC(slot.startTime)}&endingDate=${formatDateToUTC(slot.endTime)}`
      )
    );
  };

  const getExpertWorkHistory = () => {
    ExpertService.getExpertWorkHistory(expertId).subscribe(
      (response) => {
        const currentCompanyNames = renderCompanyNames(response.items);
        setExpertCompanyNames(currentCompanyNames);
        setIsBainModalOpen(true);
      },
      (error: ErrorResponse<CIQError>) => {
        console.error(error);
      }
    );
  };

  const renderCompanyNames = (expertWorkHistory: WorkHistory[]): string => {
    const currentCompanies = expertWorkHistory.filter(
      (workHistory) => workHistory.isCurrentWork === true
    );
    const companyNames = currentCompanies.map(
      (workHistoryItem) => workHistoryItem.company?.name
    );
    return companyNames.length > 0
      ? companyNames.join(", ")
      : t("expertNotEmployedCurrently");
  };

  return (
    <>
      <ArbolusModal
        title={t("companiesCID")}
        subtitle={expertCompanyNames}
        toggle={() => setIsBainModalOpen(false)}
        isOpen={isBainModalOpen}
        leftButton={{
          text: t("reviewLater"),
          type: "secondary",
          onClick: () => setIsBainModalOpen(false)
        }}
        rightButton={{
          text: t("passCID"),
          type: "primary",
          onClick: createEvent
        }}
      />
      <ArbolusModal
        title={t("expertCandidateTitle")}
        subtitle={t("expertCandidateSubtitle")}
        toggle={() => setIsCandidateModalOpen(false)}
        isOpen={isCandidateModalOpen}
        leftButton={{
          text: t("review"),
          type: "secondary",
          onClick: () => {
            setIsCandidateModalOpen(false);
            setIsExpertProfileOpen(true);
          }
        }}
        rightButton={{
          text: t("approve"),
          type: "confirmation",
          onClick: createEvent
        }}
      />
      <InternalSlidePanel
        width={SIDE_PANEL_SIZE._720}
        title={t("expertProfile")}
        closeButtonDirection="right"
        customCloseRequest={() => setIsExpertProfileOpen(false)}
        isOpen={isExpertProfileOpen}
      >
        <ExpertProfile expertId={expertId} />
      </InternalSlidePanel>

      <div className={styles.container}>
        {index === 0 && <HR margin={{ top: 0, bottom: 0 }} />}
        <div className={styles.timeAndButtonContainer}>
          <span className={styles.time}>
            {slot.startTime instanceof Object
              ? slot.startTime.format("LT")
              : slot.startTime}
            {" - "}
            {slot.endTime instanceof Object
              ? slot.endTime.format("LT")
              : slot.endTime}
          </span>
          <div className={styles.buttonContainer}>
            {expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC ? (
              <DNCTag dncStatus={expert.doNotContactStatus} />
            ) : (
              <ChevronButton
                text={hasBeenCalled ? t("followUp") : t("book")}
                onClick={handleBookButton}
                icon={{ position: "end", direction: "right" }}
                disabled={
                  isApplicationPending ||
                  isCompliancePending ||
                  (isBainCIDPending && isAdmin)
                }
              />
            )}
            <ComplianceWarning
              isApplicationPending={isApplicationPending}
              isCompliancePending={isCompliancePending}
              isBainCIDPending={isBainCIDPending}
            />
          </div>
        </div>
      </div>
    </>
  );
};
