import { SurveyExpert } from "@arbolus-technologies/api";
import { Alert } from "antd";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./PaymentMethodInformation.module.scss";

interface PaymentMethodInformationProps {
  surveyExpert: SurveyExpert;
}

export const PaymentMethodInformation: React.FC<
  PaymentMethodInformationProps
> = ({ surveyExpert }) => {
  const { t } = useTranslation("paymentMethodInformation");
  const paymentMethodIsAlreadySelected = surveyExpert.paymentMethod !== null;

  const paymentMethodInfoDescription = () => {
    return (
      <Trans
        i18nKey="paymentMethodInformation:paymentMethodDescription_Selected"
        components={{ u: <u /> }}
      />
    );
  };

  return (
    <>
      {paymentMethodIsAlreadySelected ? (
        <Alert
          message={t("paymentMethodTitle_Selected")}
          description={paymentMethodInfoDescription()}
          type="warning"
          showIcon
          className={styles.infoContainer}
        />
      ) : (
        <Alert
          message={t("paymentMethodTitle_NotSelected")}
          description={t("paymentMethodDescription_NotSelected")}
          type="info"
          showIcon
          className={styles.infoContainer}
        />
      )}
    </>
  );
};
