import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";

import "./i18n";

import packageJson from "../package.json";
import App from "./app/App";
import { AppConstants } from "./app/constants";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine-no-font.min.css";
import "@ag-grid-community/styles/agGridMaterialFont.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icon-css/css/flag-icon.min.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "material-symbols";
import "react-phone-input-2/lib/style.css";
import "simplebar/dist/simplebar.min.css";

import "./app/styles/index.scss";

import antTheme from "../../../light.json";

const container = document.getElementById("root");

const root = createRoot(container!);

if (navigator.cookieEnabled) {
  const currentVersion = packageJson.version;
  const lastAppVersion =
    localStorage.getItem(AppConstants.LOCALSTORAGE.APP_VERSION) ??
    currentVersion;

  if (lastAppVersion === currentVersion) {
    root.render(
      <ConfigProvider theme={antTheme}>
        <App />
      </ConfigProvider>
    );
    localStorage.setItem(AppConstants.LOCALSTORAGE.APP_VERSION, currentVersion);
  } else {
    localStorage.setItem(AppConstants.LOCALSTORAGE.APP_VERSION, currentVersion);
    window.location.reload();
  }
} else {
  root.render(
    <ConfigProvider theme={antTheme}>
      <App />
    </ConfigProvider>
  );
}
