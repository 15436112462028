import {
  Angle,
  AngleDraftResponse,
  AngleSingleDraft,
  AngleToSend,
  BusinessEntitiesItem,
  CIQError,
  CreatedResponse,
  DeletedResponse,
  Document,
  EngagementAgreement,
  ErrorResponse,
  IChatMessage,
  PagedListResponse,
  PaginatedRequest,
  Project,
  ProjectNx,
  User
} from "@arbolus-technologies/api";
import { ISearchTerm } from "@arbolus-technologies/models/common";
import {
  ChatUserRole,
  OnlineMembers,
  SimplifiedCreateProjectPayload,
  SimplifiedEditProjectPayload,
  UpdatedMember
} from "@arbolus-technologies/models/project";

import { DashboardAngleFilter } from "../models/definitions";

export const PREFIX = "PROJECT_NX";

export const LOAD_PROJECT = `${PREFIX}_LOAD_PROJECT`;
export const LOAD_PROJECT_SUCCESS = `${PREFIX}_LOAD_PROJECT_SUCCESS`;
export const LOAD_PROJECT_FAILURE = `${PREFIX}_LOAD_PROJECT_FAILURE`;

export const GET_PROJECT_ENGAGEMENT_AGREEMENTS = `${PREFIX}_GET_PROJECT_ENGAGEMENT_AGREEMENTS`;
export const GET_PROJECT_ENGAGEMENT_AGREEMENTS_SUCCESS = `${PREFIX}_GET_PROJECT_ENGAGEMENT_AGREEMENTS_SUCCESS`;
export const GET_PROJECT_ENGAGEMENT_AGREEMENTS_FAILURE = `${PREFIX}_GET_PROJECT_ENGAGEMENT_AGREEMENTS_FAILURE`;

export const SAVE_PROJECT_MANAGERS = `${PREFIX}_SAVE_PROJECT_MANAGERS`;
export const SAVE_ARBOLUS_TEAM = `${PREFIX}_SAVE_ARBOLUS_TEAM`;
export const CLEAR_PROJECT_MANAGERS = `${PREFIX}_CLEAR_PROJECT_MANAGERS`;

export const SAVE_ANGLE = `${PREFIX}_SAVE_ANGLE`;
export const SAVE_ANGLE_SUCCESS = `${PREFIX}_SAVE_ANGLE_SUCCESS`;
export const SAVE_ANGLE_FAILURE = `${PREFIX}_SAVE_ANGLE_FAILURE`;

export const GET_BUSINESS_ENTITIES = `${PREFIX}_GET_BUSINESS_ENTITIES`;
export const GET_BUSINESS_ENTITIES_SUCCESS = `${PREFIX}_GET_BUSINESS_ENTITIES_SUCCESS`;
export const GET_BUSINESS_ENTITIES_FAILURE = `${PREFIX}_GET_BUSINESS_ENTITIES_FAILURE`;

export const CREATE_ANGLE = `${PREFIX}_CREATE_ANGLE`;
export const CREATE_ANGLE_SUCCESS = `${PREFIX}_CREATE_ANGLE_SUCCESS`;
export const CREATE_ANGLE_FAILURE = `${PREFIX}_CREATE_ANGLE_FAILURE`;

export const GET_ANGLES_LIST = `${PREFIX}_GET_ANGLES_LIST`;
export const GET_ANGLES_LIST_SUCCESS = `${PREFIX}_GET_ANGLES_LIST_SUCCESS`;
export const GET_ANGLES_LIST_FAILURE = `${PREFIX}_GET_ANGLES_LIST_FAILURE`;

export const DELETE_ANGLE = `${PREFIX}_DELETE_ANGLE`;
export const DELETE_ANGLE_SUCCESS = `${PREFIX}_DELETE_ANGLE_SUCCESS`;
export const DELETE_ANGLE_FAILURE = `${PREFIX}_DELETE_ANGLE_FAILURE`;

export const GET_ANGLE = `${PREFIX}_GET_ANGLE`;
export const GET_ANGLE_SUCCESS = `${PREFIX}_GET_ANGLE_SUCCESS`;
export const GET_ANGLE_FAILURE = `${PREFIX}_GET_ANGLE_FAILURE`;

export const UPDATE_ANGLE = `${PREFIX}_UPDATE_ANGLE`;
export const UPDATE_ANGLE_SUCCESS = `${PREFIX}_UPDATE_ANGLE_SUCCESS`;
export const UPDATE_ANGLE_FAILURE = `${PREFIX}_UPDATE_ANGLE_FAILURE`;

// CREATE PROJECT V2

export const CREATE_PROJECT_OLD = `${PREFIX}_CREATE_PROJECT_OLD`;
export const CREATE_PROJECT_SUCCESS = `${PREFIX}_CREATE_PROJECT_SUCCESS`;
export const CREATE_PROJECT_FAILURE = `${PREFIX}_CREATE_PROJECT_FAILURE`;

// CREATE PROJECT V3 - SIMPLIFIED

export const SIMPLIFIED_CREATE_PROJECT = `${PREFIX}_SIMPLIFIED_CREATE_PROJECT`;
export const SIMPLIFIED_CREATE_PROJECT_SUCCESS = `${PREFIX}_SIMPLIFIED_CREATE_PROJECT_SUCCESS`;
export const SIMPLIFIED_CREATE_PROJECT_FAILURE = `${PREFIX}_SIMPLIFIED_CREATE_PROJECT_FAILURE`;

export const SIMPLIFIED_EDIT_PROJECT = `${PREFIX}_SIMPLIFIED_EDIT_PROJECT`;
export const SIMPLIFIED_EDIT_PROJECT_SUCCESS = `${PREFIX}_SIMPLIFIED_EDIT_PROJECT_SUCCESS`;
export const SIMPLIFIED_EDIT_PROJECT_FAILURE = `${PREFIX}_SIMPLIFIED_EDIT_PROJECT_FAILURE`;

// DELETE PROJECT DRAFT

export const DELETE_PROJECT_DRAFT = `${PREFIX}_DELETE_PROJECT_DRAFT`;
export const DELETE_PROJECT_DRAFT_SUCCESS = `${PREFIX}_DELETE_PROJECT_DRAFT_SUCCESS`;
export const DELETE_PROJECT_DRAFT_FAILURE = `${PREFIX}_DELETE_PROJECT_DRAFT_FAILURE`;

export const FORCE_RELOAD_PROJECTS = `${PREFIX}_FORCE_RELOAD_PROJECTS`;

export const GET_CLIENT_ID_SUCCESS = `${PREFIX}_GET_CLIENT_ID_SUCCESS`;

// FILTER DASHBOARD BY ANGLE
export const FILTER_DASHBOARD_BY_ANGLE = `${PREFIX}_FILTER_DASHBOARD_BY_ANGLE`;

// GET_CHAT_MESSAGES
export const GET_CHAT_MESSAGES = `${PREFIX}_GET_CHAT_MESSAGES`;
export const GET_CHAT_MESSAGES_SUCCESS = `${PREFIX}_GET_CHAT_MESSAGES_SUCCESS`;
export const GET_CHAT_MESSAGES_FAILURE = `${PREFIX}_GET_CHAT_MESSAGES_FAILURE`;

// RESET CHAT MESSAGES
export const RESET_CHAT_MESSAGES = `${PREFIX}_RESET_CHAT_MESSAGES`;

// SCROLL_CHAT_VIEW
export const SCROLL_CHAT_VIEW = `${PREFIX}_SCROLL_CHAT_VIEW`;

// GET_CHAT_MESSAGES
export const SEND_CHAT_MESSAGE = `${PREFIX}_SEND_CHAT_MESSAGE`;
export const SEND_CHAT_MESSAGE_SUCCESS = `${PREFIX}_SEND_CHAT_MESSAGE_SUCCESS`;
export const SEND_CHAT_MESSAGE_FAILURE = `${PREFIX}_SEND_CHAT_MESSAGE_FAILURE`;

// RECEIVE_NEW_CHAT_MESSAGE
export const RECEIVE_NEW_CHAT_MESSAGE = `${PREFIX}_RECEIVE_NEW_CHAT_MESSAGE`;

// GET_CHAT_FILES
export const GET_CHAT_FILES = `${PREFIX}_GET_CHAT_FILES`;
export const GET_CHAT_FILES_SUCCESS = `${PREFIX}_GET_CHAT_FILES_SUCCESS`;
export const GET_CHAT_FILES_FAILURE = `${PREFIX}_GET_CHAT_FILES_FAILURE`;

// RESET_CHAT_FILES
export const RESET_CHAT_FILES = `${PREFIX}_RESET_CHAT_FILES`;

// SELECT_FILE
export const SELECT_FILE_TO_SHARE = `${PREFIX}_SELECT_FILE_TO_SHARE`;

// SHARE_CHAT_FILES
export const SHARE_CHAT_FILES = `${PREFIX}_SHARE_CHAT_FILES`;
export const SHARE_CHAT_FILES_SUCCESS = `${PREFIX}_SHARE_CHAT_FILES_SUCCESS`;
export const SHARE_CHAT_FILES_FAILURE = `${PREFIX}_SHARE_CHAT_FILES_FAILURE`;

// DELETE_CHAT_MESSAGE
export const DELETE_CHAT_MESSAGE_SUCCESS = `${PREFIX}_DELETE_CHAT_MESSAGE_SUCCESS`;

// DISPLAY TIMEZONE
export const SET_DISPLAY_TIMEZONE = `${PREFIX}_SET_DISPLAY_TIMEZONE`;

// ONLINE CHAT MEMBERS
export const STORE_ONLINE_CHAT_MEMBERS = `${PREFIX}_STORE_ONLINE_CHAT_MEMBERS`;
export const UPDATE_ONLINE_CHAT_MEMBERS = `${PREFIX}_UPDATE_ONLINE_CHAT_MEMBERS`;

export interface LoadProjectAction {
  type: typeof LOAD_PROJECT;
  payload: {
    projectId: string;
  };
}

export interface LoadProjectSuccessAction {
  type: typeof LOAD_PROJECT_SUCCESS;
  payload: {
    projectData: Project;
  };
}

export interface LoadProjectFailureAction {
  type: typeof LOAD_PROJECT_FAILURE;
}

//GET ENGAGEMENT AGREEMENTS
export interface GetProjectEngagementAgreementsAction {
  type: typeof GET_PROJECT_ENGAGEMENT_AGREEMENTS;
  payload: {
    clientId: string;
  };
}

export interface GetProjectEngagementAgreementsSuccessAction {
  type: typeof GET_PROJECT_ENGAGEMENT_AGREEMENTS_SUCCESS;
  payload: {
    responseEngagementAgreement: EngagementAgreement;
  };
}

export interface GetProjectEngagementAgreementsFailureAction {
  type: typeof GET_PROJECT_ENGAGEMENT_AGREEMENTS_FAILURE;
  error: ErrorResponse<CIQError>;
}

// Save project managers
export interface SaveProjectManagersAction {
  type: typeof SAVE_PROJECT_MANAGERS;
  payload: {
    projectManagers: User[];
    primaryProjectLead: User | undefined;
    secondaryProjectLead: User | undefined;
  };
}

export interface SaveArbolusTeamAction {
  type: typeof SAVE_ARBOLUS_TEAM;
  payload: {
    projectManagers: User[];
    primaryProjectLead: User | null;
    secondaryProjectLead: User | null;
  };
}

// SAVE ANGLE DATA
export interface SaveAngleAction {
  type: typeof SAVE_ANGLE;
  payload: {
    angleData: Angle;
  };
}

export interface SaveAngleSuccessAction {
  type: typeof SAVE_ANGLE_SUCCESS;
}

export interface SaveAngleFailureAction {
  type: typeof SAVE_ANGLE_FAILURE;
  error: ErrorResponse<CIQError>;
}

// GET BUSINESS ENTITIES

export interface GetBusinessEntitiesProjectAction {
  type: typeof GET_BUSINESS_ENTITIES;
  payload: {
    clientId: string;
  };
}

export interface GetBusinessEntitiesProjectSuccessAction {
  type: typeof GET_BUSINESS_ENTITIES_SUCCESS;
  payload: {
    responseBusinessEntities: BusinessEntitiesItem[];
  };
}

export interface GetBusinessEntitiesProjectFailureAction {
  type: typeof GET_BUSINESS_ENTITIES_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// CREATE UPDATE ANGLE

export interface CreateAngleAction {
  type: typeof CREATE_ANGLE;
  payload: {
    angleData: AngleToSend;
    projectDraftId: string;
  };
}
export interface CreateAngleSuccessAction {
  type: typeof CREATE_ANGLE_SUCCESS;
}
export interface CreateAngleFailureAction {
  type: typeof CREATE_ANGLE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// CREATE PROJECT V2

export interface CreateProjectAction {
  type: typeof CREATE_PROJECT_OLD;
  payload: { projectDraft: ProjectNx };
}

export interface CreateProjectSuccessAction {
  type: typeof CREATE_PROJECT_SUCCESS;
}
export interface CreateProjectFailureAction {
  type: typeof CREATE_PROJECT_FAILURE;
}

// CREATE PROJECT V3 - SIMPLIFIED

export interface SimplifiedCreateProjectAction {
  type: typeof SIMPLIFIED_CREATE_PROJECT;
  payload: { apiPayload: SimplifiedCreateProjectPayload };
}
export interface SimplifiedCreateProjectSuccessAction {
  type: typeof SIMPLIFIED_CREATE_PROJECT_SUCCESS;
}
export interface SimplifiedCreateProjectFailureAction {
  type: typeof SIMPLIFIED_CREATE_PROJECT_FAILURE;
}

export interface SimplifiedEditProjectAction {
  type: typeof SIMPLIFIED_EDIT_PROJECT;
  payload: { apiPayload: SimplifiedEditProjectPayload };
}
export interface SimplifiedEditProjectSuccessAction {
  type: typeof SIMPLIFIED_EDIT_PROJECT_SUCCESS;
  payload: { updatedProject: SimplifiedEditProjectPayload };
}
export interface SimplifiedEditProjectFailureAction {
  type: typeof SIMPLIFIED_EDIT_PROJECT_FAILURE;
}

// DELETE PROJECT DRAFT

export interface DeleteProjectDraftAction {
  type: typeof DELETE_PROJECT_DRAFT;
  payload: { projectDraftId: string };
}
export interface DeleteProjectDraftSuccessAction {
  type: typeof DELETE_PROJECT_DRAFT_SUCCESS;
}
export interface DeleteProjectDraftFailureAction {
  type: typeof DELETE_PROJECT_DRAFT_FAILURE;
}

// GET ANGLES LIST

export interface GetAnglesListAction {
  type: typeof GET_ANGLES_LIST;
  payload: {
    projectDraftId: string;
  };
}

export interface GetAnglesListSuccessAction {
  type: typeof GET_ANGLES_LIST_SUCCESS;
  payload: {
    angleDraftsList: AngleDraftResponse[];
  };
}

export interface GetAnglesListFailureAction {
  type: typeof GET_ANGLES_LIST_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

//DELETE ANGLE

export interface DeleteAngleAction {
  type: typeof DELETE_ANGLE;
  payload: {
    angleDraftId: string;
  };
}

export interface DeleteAngleSuccessAction {
  type: typeof DELETE_ANGLE_SUCCESS;
  payload: {
    angleDraftDeletedId: string;
  };
}

export interface DeleteAngleFailureAction {
  type: typeof DELETE_ANGLE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// GET ANGLE

export interface GetAngleAction {
  type: typeof GET_ANGLE;
  payload: {
    angleDraftId: string;
  };
}

export interface GetAngleSuccessAction {
  type: typeof GET_ANGLE_SUCCESS;
  payload: {
    angleResponse: AngleSingleDraft;
  };
}
export interface GetAngleFailureAction {
  type: typeof GET_ANGLE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// UPDATE ANGLE

export interface UpdateAngleAction {
  type: typeof UPDATE_ANGLE;
  payload: {
    angleDraft: AngleToSend;
    projectDraftId: string;
  };
}

export interface UpdateAngleSuccessAction {
  type: typeof UPDATE_ANGLE_SUCCESS;
}
export interface UpdateAngleFailureAction {
  type: typeof UPDATE_ANGLE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ForceReloadProjectsAction {
  type: typeof FORCE_RELOAD_PROJECTS;
}

export interface ClearProjectManagersAction {
  type: typeof CLEAR_PROJECT_MANAGERS;
}

export interface GetProjectClientIDSuccessAction {
  type: typeof GET_CLIENT_ID_SUCCESS;
  payload: {
    clientId: string;
  };
}

export interface FilterDashboardByAngleAction {
  type: typeof FILTER_DASHBOARD_BY_ANGLE;
  payload: DashboardAngleFilter;
}

// GET CHAT MESSAGES

export interface GetChatMessagesAction {
  type: typeof GET_CHAT_MESSAGES;
  payload: {
    projectId: string;
    chatId: string;
    isInitialFetch: boolean;
    queryParams: PaginatedRequest<"Created">;
  };
}
export interface GetChatMessagesSuccessAction {
  type: typeof GET_CHAT_MESSAGES_SUCCESS;
  payload: {
    response: PagedListResponse<IChatMessage>;
  };
}
export interface GetChatMessagesFailureAction {
  type: typeof GET_CHAT_MESSAGES_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ResetChatMessagesAction {
  type: typeof RESET_CHAT_MESSAGES;
}

// SEND CHAT MESSAGE

export interface SendChatMessageAction {
  type: typeof SEND_CHAT_MESSAGE;
  payload: {
    projectId: string;
    chatId: string;
    message: string;
  };
}
export interface SendChatMessageSuccessAction {
  type: typeof SEND_CHAT_MESSAGE_SUCCESS;
}
export interface SendChatMessageFailureAction {
  type: typeof SEND_CHAT_MESSAGE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ReceiveNewChatMessageAction {
  type: typeof RECEIVE_NEW_CHAT_MESSAGE;
  payload: {
    message: IChatMessage;
  };
}

// GET CHAT FILES

export interface GetChatFilesAction {
  type: typeof GET_CHAT_FILES;
  payload: {
    projectId: string;
    queryParams: PaginatedRequest<"Created"> & ISearchTerm;
    userRole: ChatUserRole;
  };
}
export interface GetChatFilesSuccessAction {
  type: typeof GET_CHAT_FILES_SUCCESS;
  payload: {
    response: PagedListResponse<Document>;
  };
}
export interface GetChatFilesFailureAction {
  type: typeof GET_CHAT_FILES_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// RESET CHAT FILES
export interface ResetChatFilesAction {
  type: typeof RESET_CHAT_FILES;
}

// SELECT_FILE_TO_SHARE
export interface SelectFileToShareAction {
  type: typeof SELECT_FILE_TO_SHARE;
  payload: {
    fileId: string;
  };
}

// SHARE CHAT FILES
export interface ShareChatFilesAction {
  type: typeof SHARE_CHAT_FILES;
  payload: {
    projectId: string;
    chatId: string;
    documentIds: string[];
  };
}
export interface ShareChatFilesSuccessAction {
  type: typeof SHARE_CHAT_FILES_SUCCESS;
  payload: {
    response: CreatedResponse;
  };
}
export interface ShareChatFilesFailureAction {
  type: typeof SHARE_CHAT_FILES_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// DELETE CHAT MESSAGE

export interface DeleteChatMessageSuccessAction {
  type: typeof DELETE_CHAT_MESSAGE_SUCCESS;
  payload: {
    response: DeletedResponse;
  };
}

// ONLINE CHAT MEMBERS

export interface StoreOnlineMembersByChatAction {
  type: typeof STORE_ONLINE_CHAT_MEMBERS;
  payload: {
    channelName: string;
    onlineMembersByChat: OnlineMembers;
  };
}

export interface UpdateOnlineMembersByChatAction {
  type: typeof UPDATE_ONLINE_CHAT_MEMBERS;
  payload: {
    channelName: string;
    updatedMember: UpdatedMember;
  };
}

// DISPLAY TIMEZONE
export interface SetDisplayTimezoneAction {
  type: typeof SET_DISPLAY_TIMEZONE;
  payload: {
    timezoneId: string;
  };
}
