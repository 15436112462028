import { UserManagerSettings } from "oidc-client-ts";

import { LINKEDIN_AUTH_CLIENT_ID } from "@arbolus-technologies/utils";
import { BASE_URL } from "@arbolus-technologies/utils";

const OPEN_ID_CONFIG: Omit<UserManagerSettings, "redirect_uri"> = {
  authority: "https://www.linkedin.com/oauth/",
  client_id: LINKEDIN_AUTH_CLIENT_ID,
  response_type: "code", // Use authorization code flow
  response_mode: "query",
  scope: "openid profile email",

  // LinkedIn does not fully support standard OIDC
  metadata: {
    authorization_endpoint: "https://www.linkedin.com/oauth/v2/authorization",
    token_endpoint: "https://www.linkedin.com/oauth/v2/accessToken",
    end_session_endpoint: "https://www.linkedin.com/oauth/v2/logout"
  }
};

export const REGISTER_CONFIG: UserManagerSettings = {
  ...OPEN_ID_CONFIG,
  redirect_uri: `${BASE_URL}/api/v1/users/register-with-linkedin`
};

export const LOGIN_CONFIG: UserManagerSettings = {
  ...OPEN_ID_CONFIG,
  redirect_uri: `${BASE_URL}/api/v1/users/login-with-linkedin`
};
