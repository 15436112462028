import { Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Project, ReferralDetail } from "@arbolus-technologies/api";
import {
  EVENT_FORM,
  EventFormInterface
} from "@arbolus-technologies/models/project";
import { MAX_EVENT_TITLE_LENGTH, fullName } from "@arbolus-technologies/utils";

const { TITLE } = EVENT_FORM;

interface EventTitleProps {
  project: Project;
  referral: ReferralDetail | null;
}

export const EventTitle: React.FC<EventTitleProps> = ({
  project,
  referral
}) => {
  const { t } = useTranslation("eventForm");

  const form = Form.useFormInstance<EventFormInterface>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (referral) {
      const expertFullName = fullName(
        referral.expert.firstName,
        referral.expert.lastName
      );
      form.setFieldsValue({ [TITLE]: `${expertFullName}, ${project.name}` });
    }
  }, [project, referral]);

  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label={t("eventTitle")}
          name={TITLE}
          rules={[
            {
              required: true,
              message: t("eventTitleRequired")
            },
            {
              max: MAX_EVENT_TITLE_LENGTH,
              message: t("eventTitleMaxLength", {
                maxLength: MAX_EVENT_TITLE_LENGTH
              })
            }
          ]}
        >
          <Input placeholder={t("eventTitlePlaceholder")} />
        </Form.Item>
      </Col>
    </Row>
  );
};
