import { Card, Divider, Flex } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import {
  SURVEY_PROVIDERS,
  SurveyParamUrlType
} from "@arbolus-technologies/models/survey";
import { SURVEY_ROUTE } from "@arbolus-technologies/routes";

import { ComplianceStatement } from "../../Components/ComplianceStatement/ComplianceStatement";
import { SurveyFooter } from "../../Components/SurveyFooter";
import { SurveyHeader } from "../../Components/SurveyHeader/SurveyHeader";

import styles from "./SurveyCompliancePage.module.scss";

interface LocationState {
  surveyLink: string;
  surveyProvider: SURVEY_PROVIDERS;
}

export const SurveyCompliancePage: React.FC = () => {
  const { t } = useTranslation("surveyCompliancePage");
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { surveyId } = useParams<SurveyParamUrlType>();
  useDocumentTitle(t("survey"));

  const { surveyLink, surveyProvider } = location.state ?? {};

  if (!surveyLink) {
    history.push(SURVEY_ROUTE(surveyId));
    return null;
  }

  return (
    <div className={styles.container}>
      <SurveyHeader />
      <div className={styles.content}>
        <Card>
          <Flex vertical gap={24}>
            <ComplianceStatement
              surveyId={surveyId}
              surveyLink={surveyLink}
              surveyProvider={surveyProvider}
            />
            <Divider />
            <SurveyFooter />
          </Flex>
        </Card>
      </div>
    </div>
  );
};
