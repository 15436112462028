import { Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon, IconName } from "@arbolus-technologies/antDComponents";
import { REPEATED_ENGAGEMENT_STATUS } from "@arbolus-technologies/models/common";

import styles from "./RepeatedEngagementTag.module.scss";

interface RepeatedEngagementTagProps {
  status: REPEATED_ENGAGEMENT_STATUS;
}

export const RepeatedEngagementTag: React.FC<RepeatedEngagementTagProps> = ({
  status
}) => {
  const { t } = useTranslation("repeatedEngagementTag");

  const statusMap: Record<
    REPEATED_ENGAGEMENT_STATUS,
    { color: string; icon: IconName; text: string }
  > = {
    [REPEATED_ENGAGEMENT_STATUS.PENDING]: {
      color: "warning",
      icon: "error",
      text: "pending"
    },
    [REPEATED_ENGAGEMENT_STATUS.PASSED]: {
      color: "success",
      icon: "check_circle",
      text: "passed"
    },
    [REPEATED_ENGAGEMENT_STATUS.REJECTED]: {
      color: "error",
      icon: "cancel",
      text: "rejected"
    }
  };

  const statusInfo = statusMap[status];

  if (!statusInfo) return null;

  return (
    <Tag
      color={statusInfo.color}
      icon={<AntDIcon name={statusInfo.icon} />}
      className={styles.tag}
    >
      {t(statusInfo.text)}
    </Tag>
  );
};
