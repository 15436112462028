import React from "react";

import { ExpertSlotsByDate } from "@arbolus-technologies/api";
import { ScheduleProjectData } from "@arbolus-technologies/models/project";

import { NoAvailabilityCard } from "../../NoAvailabilityCard/NoAvailabilityCard";
import { ScheduleCard } from "../../ScheduleCard/ScheduleCard";

import styles from "./ScheduleColumnBody.module.scss";

interface ScheduleColumnBodyProps {
  expertSlotsByDate?: ExpertSlotsByDate;
  project: ScheduleProjectData;
  handleClickRow: (expertId: string, referralId: string) => void;
}

export const ScheduleColumnBody: React.FC<ScheduleColumnBodyProps> = ({
  expertSlotsByDate,
  project,
  handleClickRow
}) => (
  <div className={styles.scheduleColumnBody}>
    {typeof expertSlotsByDate === "undefined" ? (
      <NoAvailabilityCard />
    ) : (
      expertSlotsByDate.experts.map((expertAvailability) => (
        <ScheduleCard
          key={expertAvailability.expert.id}
          expertAvailability={expertAvailability}
          project={project}
          handleClickRow={handleClickRow}
        />
      ))
    )}
  </div>
);
