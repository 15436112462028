import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  AdminService,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ObjectResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import {
  INCIDENT_CATEGORY,
  INCIDENT_FORM,
  INCIDENT_PRIORITY
} from "../../../enums";
import { IncidentFormInterface } from "../../../interfaces";
import { ReportIncidentForm } from "./ReportIncidentForm";
import { reportIncidentFormSchema } from "./ReportIncidentFormSchema";

const INCLUDED_CATEGORIES = [
  INCIDENT_CATEGORY.EXPERTS,
  INCIDENT_CATEGORY.NOTIFICATIONS,
  INCIDENT_CATEGORY.USERS,
  INCIDENT_CATEGORY.TRANSACTIONS,
  INCIDENT_CATEGORY.DASHBOARDS
];

interface ReportIncidentFormProviderProps {
  adminService?: typeof AdminService;
  notificationService?: ToasterService;
}
export const ReportIncidentFormProvider: React.FC<
  ReportIncidentFormProviderProps
> = ({
  adminService = AdminService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("incidentForm");
  const dispatch = useDispatch();
  const currentUrl: string = window.location.href;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const defaultValues = {
    [INCIDENT_FORM.TITLE]: "",
    [INCIDENT_FORM.OVERVIEW]: "",
    [INCIDENT_FORM.PRIORITY]: INCIDENT_PRIORITY.Lowest,
    [INCIDENT_FORM.ATTACHMENTS]: [],
    [INCIDENT_FORM.CATEGORY]: INCIDENT_CATEGORY.OTHER,
    [INCIDENT_FORM.ENTITY]: undefined,
    [INCIDENT_FORM.ENTITY_ID]: ""
  };

  const methods = useForm<IncidentFormInterface>({
    resolver: yupResolver(reportIncidentFormSchema),
    mode: "all",
    defaultValues
  });

  const handleSuccessSubmit = () => {
    setIsLoading(false);
    notificationService.showSuccess(t("incidentSubmitted"));
    dispatch(PanelStoreActions.closePanel(PanelId.ReportIncident));
  };

  const onSubmit = (data: IncidentFormInterface) => {
    setIsLoading(true);
    const { title, overview, priority, category } = data;
    const isUsingEmailInput = INCLUDED_CATEGORIES.includes(category);

    const entityId = isUsingEmailInput
      ? data.entityId
      : (data.entity?.id ?? data.entityId);

    adminService
      .reportIncident(title, overview, priority, category, currentUrl, entityId)
      .subscribe(
        (response: ObjectResponse<string>) => {
          if (data.attachments.length > 0) {
            submitAttachments(response.item, data.attachments);
          } else {
            handleSuccessSubmit();
          }
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      );
  };

  const submitAttachments = (incidentKey: string, attachments: File[]) => {
    const failedAttachments: string[] = [];
    attachments.forEach((attachment) => {
      const formData = new FormData();
      formData.append("attachment", attachment);
      adminService
        .addIncidentAttachment(incidentKey, formData)
        .toPromise()
        .catch(() => failedAttachments.push(attachment.name))
        .finally(() => {
          if (failedAttachments.length > 0) {
            setIsLoading(false);
            notificationService.showError(t("incidentSubmittedWithErrors"));
            dispatch(PanelStoreActions.closePanel(PanelId.ReportIncident));
          } else {
            handleSuccessSubmit();
          }
        });
    });
  };

  const { handleSubmit } = methods;

  const onCancel = () => {
    dispatch(PanelStoreActions.closePanel(PanelId.ReportIncident));
  };

  return (
    <FormProvider {...methods}>
      <ReportIncidentForm
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onCancel}
        isLoading={isLoading}
      />
    </FormProvider>
  );
};
