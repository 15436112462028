import { MainInputModal } from "./MainInputModal";
import { MainListModal } from "./MainListModal";
import { MainModal } from "./MainModal";

export const AllMainModals: React.FC = () => (
  <>
    <MainModal />
    <MainListModal />
    <MainInputModal />
  </>
);
