import { Collapse, Flex } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import { EmployedStatus } from "@arbolus-technologies/models/common";
import { LoaderOrComponent } from "@arbolus-technologies/ui/components";
import { getFirstDayOfMonth } from "@arbolus-technologies/utils";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { TimeGap, WorkHistoryItemModel } from "../../interfaces";
import { WhBoxFooter } from "./WhBoxFooter/WhBoxFooter";
import { WorkHistoryForm } from "./WorkHistoryForm/WorkHistoryForm";
import { WorkHistoryTitle } from "./WorkHistoryTitle/WorkHistoryTitle";
import {
  mapModelToWorkHistoryUpdate,
  mapWorkHistoryToModel,
  orderWorkHistoryItems
} from "./helpers/utils";

interface WorkHistoryEditListProps {
  expertId: string;
  onSave?: () => void;
  notificationService?: ToasterService;
  expertService?: typeof ExpertService;
  mandatoryTimeFrame?: boolean;
}

export const WorkHistoryEditList: React.FC<WorkHistoryEditListProps> = ({
  expertId,
  onSave,
  notificationService = DefaultToasterService,
  expertService = ExpertService,
  mandatoryTimeFrame = false
}) => {
  const { t } = useTranslation("workHistoryEditList");
  const [workHistory, setWorkHistory] = useState<WorkHistoryItemModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    expertService.getExpertWorkHistory(expertId).subscribe(
      (response) => {
        const workHistoryModels = response.items
          .map(mapWorkHistoryToModel)
          .sort(orderWorkHistoryItems);
        setWorkHistory(workHistoryModels);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsLoading(false);
      }
    );
  }, [expertId]);

  const handleSave = useCallback(
    (workHistoryParam?: WorkHistoryItemModel[]) => {
      setIsSaving(true);
      const workHistoryMap = workHistoryParam ?? workHistory;
      const workHistoryUpdate = workHistoryMap.map(mapModelToWorkHistoryUpdate);

      expertService
        .updateExpertWorkHistory(expertId, workHistoryUpdate)
        .subscribe(
          () => {
            setIsSaving(false);
            if (onSave) {
              onSave();
            }
            notificationService.showSuccess(
              t("workHistoryUpdatedSuccessfully")
            );
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsSaving(false);
          }
        );
    },
    [workHistory, expertId, expertService, notificationService, onSave]
  );

  const handleUpdateWorkHistoryItem = useCallback(
    (updatedItem: WorkHistoryItemModel, index: number) => {
      const updatedWorkHistory = workHistory.map((item, i) =>
        i === index
          ? {
              ...updatedItem,
              from: new Date(updatedItem.from),
              to: updatedItem.to ? new Date(updatedItem.to) : updatedItem.to,
              id: updatedItem.id || `${index + 1}`
            }
          : item
      );
      const orderedWorkHistory = updatedWorkHistory.sort(orderWorkHistoryItems);
      setWorkHistory(orderedWorkHistory);
      setIsEditing(false);
      if (!onSave) {
        handleSave(orderedWorkHistory);
      }
    },
    [workHistory, onSave, handleSave]
  );

  const deleteWorkHistoryItem = useCallback(
    (index: number) => {
      const newWorkHistory = [...workHistory];
      newWorkHistory.splice(index, 1);
      setWorkHistory(newWorkHistory);
      setIsEditing(false);
      if (workHistory[index].id) {
        handleSave(newWorkHistory);
      }
    },
    [workHistory]
  );

  const handleAddNewWorkHistoryItem = useCallback(() => {
    setWorkHistory([
      ...workHistory,
      {
        id: "",
        employedStatus: EmployedStatus.Employed,
        title: "",
        jobDescription: "",
        from: getFirstDayOfMonth(),
        to: null,
        isCurrentWork: false,
        company: null,
        country: null
      }
    ]);
  }, [workHistory]);

  const handleCancel = useCallback(
    (index: number, itemId?: string) => {
      if (!itemId) {
        const newWorkHistory = [...workHistory];
        newWorkHistory.splice(index, 1);
        setWorkHistory(newWorkHistory);
      } else {
        setIsEditing(false);
      }
    },
    [workHistory]
  );

  const workHistoryTimeGaps: TimeGap[] = useMemo(
    () =>
      workHistory.map((wh) => ({
        from: wh.from,
        to: wh.to ?? getFirstDayOfMonth()
      })),
    [workHistory]
  );

  const newWorkHistory = workHistory.find((wh) => wh.id === "");

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <LoaderOrComponent isLoading={isLoading}>
        {workHistory.length > 0 && (
          <Flex vertical gap="24px">
            {workHistory.map((workItem, index) => (
              <Collapse
                key={`${dayjs(workItem.from).format("llll")}_${index}`}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <AntDIcon name={isActive ? "arrowUp" : "arrowDown"} />
                )}
                defaultActiveKey={
                  workItem.id
                    ? undefined
                    : `${dayjs(workItem.from).format("llll")}_${index}_0`
                }
                items={[
                  {
                    key: `${dayjs(workItem.from).format("llll")}_${index}_0`,
                    label: <WorkHistoryTitle workItem={workItem} />,
                    children: (
                      <WorkHistoryForm
                        workHistoryItem={workItem}
                        isSaving={isSaving}
                        isAddingNew={
                          !!newWorkHistory && newWorkHistory !== workItem
                        }
                        onDiscard={() => handleCancel(index, workItem.id)}
                        onDelete={
                          workHistory.length > 1
                            ? () => deleteWorkHistoryItem(index)
                            : undefined
                        }
                        onFormEdit={() => setIsEditing(true)}
                        onSubmit={(item: WorkHistoryItemModel) =>
                          handleUpdateWorkHistoryItem(item, index)
                        }
                      />
                    )
                  }
                ]}
              />
            ))}
          </Flex>
        )}
        <WhBoxFooter
          onAddNewWorkHistoryItem={handleAddNewWorkHistoryItem}
          onSave={onSave ? handleSave : undefined}
          mandatoryTimeFrame={mandatoryTimeFrame}
          isDisabled={isEditing || isSaving || !!newWorkHistory}
          workHistoryGaps={workHistoryTimeGaps}
        />
      </LoaderOrComponent>
    </div>
  );
};
