import { Flex } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  FindExpertStatus,
  FindExpertStatusEnum
} from "@arbolus-technologies/models/project";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import {
  eyes,
  hourglassIcon,
  magnifyingGlass
} from "@arbolus-technologies/theme";
import { LoaderOrComponent } from "@arbolus-technologies/ui/components";

import { useDiscoverExperts } from "../../../Hooks/Discover/useDiscoverExperts";
import { EmptyPlaceholder } from "./EmptyPlaceholder";
import { ExpertList } from "./ExpertList";
import { ExpertListHeader } from "./ExpertListHeader";

interface ExpertContainerProps {
  projectId: string;
  projectName: string;
}

export const ExpertContainer: React.FC<ExpertContainerProps> = ({
  projectId,
  projectName
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();

  const {
    expertListPaginated,
    handleSelectExpert,
    selectedExpertsIds,
    expertsCount,
    angles,
    fetchExpertList,
    selectedCautionExperts,
    handleToggleSelectAllExperts,
    areAllExpertsSelected,
    areSomeExpertsSelected,
    handleSetIsConfirmReferralsLoading,
    isConfirmReferralsLoading,
    shortlistedExpertsIds,
    handleShortlistConfirmed
  } = useDiscoverExperts({ projectId });

  const isDiscoverExpertLoading = useSelector(
    ProjectNxSelector.getIsDiscoverExpertLoading()
  );

  useEffect(() => {
    projectName && fetchExpertList();

    return () => {
      dispatch(ProjectNxStoreActions.setDiscoverExpertList(null));
    };
  }, [projectName, dispatch]);

  const totalExpertsFound = expertListPaginated?.pagination.count ?? 0;
  const expertListPaginatedStatus =
    expertListPaginated?.resultStatus as FindExpertStatus;
  const hasNoExperts = expertListPaginated === null;

  const expertListScreens = {
    [FindExpertStatusEnum.OK]: (
      <ExpertList
        angles={angles}
        projectId={projectId}
        projectName={projectName}
        selectedExpertsIds={selectedExpertsIds}
        shortlistedExpertsIds={shortlistedExpertsIds}
        expertListPaginated={expertListPaginated!}
        totalExpertsFound={totalExpertsFound}
        selectedCautionExperts={selectedCautionExperts}
        isConfirmReferralsLoading={isConfirmReferralsLoading}
        handleSelectExpert={handleSelectExpert}
        handleShortlistConfirmed={handleShortlistConfirmed}
        handleSetIsConfirmReferralsLoading={handleSetIsConfirmReferralsLoading}
      />
    ),
    [FindExpertStatusEnum.RESULTS_NOT_FOUND]: (
      <EmptyPlaceholder
        icon={<img src={eyes} height="24px" width="24px" alt="eyes" />}
        title={t("noResultsTitle")}
        subTitle={t("noResultsText")}
      />
    ),
    [FindExpertStatusEnum.EXPERT_RECOMMENDATIONS_NOT_GENERATED]: (
      <EmptyPlaceholder
        icon={
          <img src={hourglassIcon} height="24px" width="24px" alt="hourglass" />
        }
        title={t("recommendationsTitle")}
        subTitle={t("recommendationsText")}
      />
    )
  };

  return (
    <Flex vertical gap={16}>
      <ExpertListHeader
        angles={angles}
        totalExpertsAvailable={expertsCount}
        totalExpertsFound={totalExpertsFound}
        selectedExpertsIds={selectedExpertsIds}
        selectedCautionExperts={selectedCautionExperts}
        areAllExpertsSelected={areAllExpertsSelected}
        areSomeExpertsSelected={areSomeExpertsSelected}
        isConfirmReferralsLoading={isConfirmReferralsLoading}
        onShortlistConfirmed={handleShortlistConfirmed}
        handleToggleSelectAllExperts={handleToggleSelectAllExperts}
        handleSetIsConfirmReferralsLoading={handleSetIsConfirmReferralsLoading}
      />
      <LoaderOrComponent isLoading={isDiscoverExpertLoading} isFull>
        {hasNoExperts ? (
          <EmptyPlaceholder
            icon={
              <img
                src={magnifyingGlass}
                height="24px"
                width="24px"
                alt="magnifyingGlass"
              />
            }
            title={t("initialSearchTitle")}
            subTitle={t("initialSearchText")}
          />
        ) : (
          expertListScreens[expertListPaginatedStatus]
        )}
      </LoaderOrComponent>
    </Flex>
  );
};
