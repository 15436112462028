import { Alert, Divider, Flex, Skeleton, Typography } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CIQError,
  DefaultToasterService,
  EngagementService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import { RepeatedEngagementDetails } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { RepeatedEngagementEvent } from "./RepeatedEngagementEvent";

const { Text } = Typography;

interface RepeatedEngagementProps {
  expertId: string;
  engagementService?: typeof EngagementService;
  notificationService?: ToasterService;
}

export const RepeatedEngagement: React.FC<RepeatedEngagementProps> = ({
  expertId,
  engagementService = EngagementService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralCompliance");

  const [repeatedEngagementDetails, setRepeatedEngagementDetails] =
    useState<RepeatedEngagementDetails>();

  const clientId = useSelector(ProjectNxSelector.projectClientId());
  const loggedInUser = useSelector(CacheSelector.loggedInUser());

  useEffect(() => {
    fetchRepeatedEngagementDetails();
  }, []);

  const fetchRepeatedEngagementDetails = () => {
    engagementService
      .getRepeatedEngagementDetails(clientId, expertId)
      .subscribe(
        (data: RepeatedEngagementDetails) => {
          setRepeatedEngagementDetails(data);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  if (!repeatedEngagementDetails) {
    return <Skeleton paragraph />;
  }

  const { totalEngagements, timeframe, pendingEngagements, pastEngagements } =
    repeatedEngagementDetails;

  const isComplianceManager = !!loggedInUser.complianceManager;
  const hasPendingEngagements = pendingEngagements.length > 0;

  return (
    <Flex vertical gap={16}>
      {isComplianceManager && hasPendingEngagements && (
        <Alert
          message={t("repeatedEngagementWarning", {
            totalEngagements,
            timeframe
          })}
          type="warning"
          showIcon
          style={{ alignItems: "flex-start" }}
        />
      )}
      {pendingEngagements.map((repeatedEngagementEventDetails, index) => (
        <Fragment key={repeatedEngagementEventDetails.eventId}>
          <RepeatedEngagementEvent
            engagementNumber={
              pendingEngagements.length + pastEngagements.length - index
            }
            isPendingForComplianceManager={isComplianceManager}
            repeatedEngagementEventDetails={repeatedEngagementEventDetails}
          />
          <Divider style={{ margin: 0 }} />
        </Fragment>
      ))}
      {pastEngagements.length > 0 && (
        <>
          <Text strong>{t("pastEngagements")}</Text>
          {pastEngagements.map((repeatedEngagementEventDetails, index) => (
            <Fragment key={repeatedEngagementEventDetails.eventId}>
              <RepeatedEngagementEvent
                engagementNumber={pastEngagements.length - index}
                repeatedEngagementEventDetails={repeatedEngagementEventDetails}
              />
              {index < pastEngagements.length - 1 && (
                <Divider style={{ margin: 0 }} />
              )}
            </Fragment>
          ))}
        </>
      )}
    </Flex>
  );
};
