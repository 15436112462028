import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { User } from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./TransactionOwnerRenderer.module.scss";

interface IExpert {
  transactionOwner: User;
}

export const TransactionOwnerRenderer: React.FC<
  ICellRendererParams<IExpert, never, never>
> = ({ data }) => {
  if (!data?.transactionOwner) {
    return null;
  }

  const fullName = displayUserName(data.transactionOwner);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Avatar
          avatar={{
            name: fullName,
            imageUrl: data.transactionOwner.profileImageUrl
          }}
        />
        <span className={styles.name}>{fullName}</span>
      </div>
    </div>
  );
};
