import {
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  BusinessEntityDataRequest,
  CLIENT_PRICING_TYPE,
  ClientService,
  CompanyService,
  DefaultToasterService,
  Survey,
  ToasterService,
  UpdateSurvey
} from "@arbolus-technologies/api";
import { AddTargetCompany } from "@arbolus-technologies/features/common";
import {
  SURVEY_PROVIDERS,
  SURVEY_STATUS
} from "@arbolus-technologies/models/survey";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { TiptapEditorMemoized } from "@arbolus-technologies/ui/components";
import {
  SURVEY_DATE_FORMAT,
  getCurrenciesSymbols
} from "@arbolus-technologies/utils";

import { MAX_SURVEY_DESCRIPTION_LENGTH } from "../constants";
import { SurveyLeadUserSelector } from "./SurveyLeadUserSelector";

const { Text } = Typography;

export interface SurveyForm extends Omit<Survey, "deadlineDate"> {
  deadlineDate: Dayjs;
  companyWebsite?: string;
}

interface SurveyDetailsFormProps {
  isSaving: boolean;
  surveyDetails?: Survey;
  isSurveyClosed?: boolean;
  saveSurvey: (values: UpdateSurvey) => void;
  companyService?: typeof CompanyService;
  clientService?: typeof ClientService;
  notificationService?: ToasterService;
}

export const SurveyDetailsForm: React.FC<SurveyDetailsFormProps> = ({
  isSaving,
  surveyDetails,
  isSurveyClosed = false,
  saveSurvey,
  companyService = CompanyService,
  clientService = ClientService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("surveyDetailsForm");
  const { projectId } = useParams<{ projectId: string }>();
  const [form] = Form.useForm();

  const isEditMode = !!surveyDetails;

  const [businessEntity, setBusinessEntity] =
    useState<BusinessEntityDataRequest>();
  const isCreditPricingModel =
    businessEntity?.pricingType === CLIENT_PRICING_TYPE.CreditModel;

  const projectBusinessEntityId = useSelector(
    ProjectNxSelector.projectBusinessEntityId()
  );

  useEffect(() => {
    if (projectBusinessEntityId) {
      clientService.getBusinessEntity(projectBusinessEntityId).subscribe(
        (businessEntity) => {
          setBusinessEntity(businessEntity);
        },
        () => {
          notificationService.showError(t("businessEntityError"));
        }
      );
    }
  }, [projectBusinessEntityId]);

  const handleSubmit = (values: SurveyForm) => {
    const data = {
      id: surveyDetails?.id,
      projectId,
      title: values.title,
      description: values.description,
      deadlineDate: values.deadlineDate.toString(),
      minDuration: Number(values.minDuration),
      maxDuration: Number(values.maxDuration),
      compensationAmount: Number(values.compensationAmount),
      provider: values.provider,
      link: values.link ?? null,
      targetCompanyId: values.targetCompany?.id ?? "",
      markUpAmount: Number(values.markUpAmount),
      clientInvoiceAmount: Number(values.clientInvoiceAmount),
      minimumPoolSize: Number(values.minimumPoolSize),
      surveyLeadUserId: values.surveyLeadUser.id,
      status: SURVEY_STATUS.ACTIVE
    };

    saveSurvey(data);

    if (
      values.targetCompany &&
      values.targetCompany.website !== values.companyWebsite
    ) {
      companyWebsitePatch(values.targetCompany.id, values.companyWebsite!);
    }
  };

  const companyWebsitePatch = (companyId: string, website: string): void => {
    companyService
      .patchCompany(companyId, {
        website: { value: website },
        linkedinUrl: null
      })
      .subscribe();
  };

  const onFinishFailed = () => {
    notificationService.showError(t("invalidForm"));
  };

  return (
    <Card>
      <Flex vertical gap={24}>
        <Flex vertical gap={8}>
          <Text strong>{t("title")}</Text>
          <Text>{t("formDescription")}</Text>
        </Flex>

        <Flex vertical gap={16}>
          <Text strong>{t("visibleToExperts")}</Text>

          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            initialValues={{
              ...surveyDetails,
              deadlineDate: surveyDetails?.deadlineDate
                ? dayjs(surveyDetails.deadlineDate)
                : undefined,
              companyWebsite: surveyDetails?.targetCompany?.website,
              provider: surveyDetails?.provider ?? SURVEY_PROVIDERS.INC_QUERY
            }}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            disabled={isSaving || isSurveyClosed}
          >
            <Form.Item
              label={t("surveyTitle")}
              name="title"
              validateDebounce={500}
              rules={[
                {
                  required: true,
                  message: t("surveyTitleRequired")
                }
              ]}
            >
              <Input placeholder={t("surveyTitlePlaceholder")} />
            </Form.Item>

            <Form.Item
              label={t("description")}
              name="description"
              validateDebounce={500}
              rules={[
                {
                  required: true,
                  message: t("descriptionRequired")
                },
                {
                  max: MAX_SURVEY_DESCRIPTION_LENGTH,
                  message: t("descriptionLimitError", {
                    length: MAX_SURVEY_DESCRIPTION_LENGTH
                  })
                }
              ]}
              style={{ cursor: isSurveyClosed ? "not-allowed" : "inherit" }}
            >
              <TiptapEditorMemoized
                placeholder={t("descriptionPlaceholder")}
                onChange={(content: string): void => {
                  form.setFieldsValue({ internalDescription: content });
                }}
                initialEditorState={surveyDetails?.description ?? ""}
                hasError={false}
                disabled={isSurveyClosed}
              />
            </Form.Item>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  label={t("deadline")}
                  name="deadlineDate"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: t("deadlineRequired")
                    }
                  ]}
                >
                  <DatePicker
                    format={SURVEY_DATE_FORMAT}
                    placeholder={t("selectDate")}
                    disabledDate={(date) =>
                      date.isBefore(dayjs().subtract(1, "day"))
                    }
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t("minDuration")}
                  name="minDuration"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: t("minDurationRequired")
                    },
                    {
                      type: "integer",
                      message: t("mustBeANumber"),
                      transform: (value) => (value ? Number(value) : "")
                    },
                    {
                      validator(_, value) {
                        if (value > 0) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("mustBeGreaterThanZero"))
                        );
                      }
                    }
                  ]}
                >
                  <Input placeholder={t("minDurationPlaceholder")} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t("maxDuration")}
                  name="maxDuration"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: t("maxDurationRequired")
                    },
                    {
                      type: "integer",
                      message: t("mustBeANumber"),
                      transform: (value) => (value ? Number(value) : "")
                    },
                    {
                      validator(_, value) {
                        if (value > 0) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("mustBeGreaterThanZero"))
                        );
                      }
                    }
                  ]}
                >
                  <Input placeholder={t("maxDurationPlaceholder")} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t("compensation")}
                  name="compensationAmount"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: t("compensationRequired")
                    },
                    {
                      type: "number",
                      message: t("mustBeANumber"),
                      transform: (value) => (value ? Number(value) : "")
                    },
                    {
                      validator(_, value) {
                        if (value > 0) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("mustBeGreaterThanZero"))
                        );
                      }
                    }
                  ]}
                >
                  <Input
                    placeholder={t("compensationPlaceholder")}
                    addonAfter="$"
                    disabled={isEditMode}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Flex gap={24} vertical>
              <Text strong>{t("internalDetails")}</Text>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    label={t("surveyProvider")}
                    name="provider"
                    validateDebounce={500}
                    rules={[
                      {
                        required: true,
                        message: t("surveyProviderRequired")
                      }
                    ]}
                  >
                    <Select
                      options={Object.values(SURVEY_PROVIDERS).map((value) => ({
                        value: value,
                        label: t(value)
                      }))}
                      disabled={isEditMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    label={t("surveyLink")}
                    name="link"
                    validateDebounce={500}
                    rules={[
                      {
                        type: "url",
                        message: t("surveyLinkFormatError")
                      }
                    ]}
                  >
                    <Input
                      placeholder={t("surveyLinkPlaceholder")}
                      disabled={!!surveyDetails?.link}
                      onBlur={(e) =>
                        form.setFieldsValue({
                          ["link"]: e.target.value.trim()
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Flex>

            <AddTargetCompany disabled={isSurveyClosed} />

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label={t("markup")}
                  name="markUpAmount"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: t("markupRequired")
                    },
                    {
                      type: "number",
                      message: t("mustBeANumber"),
                      transform: (value) => (value ? parseFloat(value) : "")
                    },
                    {
                      validator(_, value) {
                        if (value > 0) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("mustBeGreaterThanZero"))
                        );
                      }
                    }
                  ]}
                >
                  <Input placeholder={t("markupPlaceholder")} addonAfter="$" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t("clientInvoiceAmount")}
                  name="clientInvoiceAmount"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: t("clientInvoiceAmountRequired")
                    },
                    {
                      type: "number",
                      message: t("mustBeANumber"),
                      transform: (value) => (value ? parseFloat(value) : "")
                    },
                    {
                      validator(_, value) {
                        if (value > 0) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("mustBeGreaterThanZero"))
                        );
                      }
                    }
                  ]}
                >
                  <Input
                    addonAfter={
                      isCreditPricingModel
                        ? t("credits")
                        : getCurrenciesSymbols(businessEntity?.isoCurrencyCode!)
                    }
                    placeholder={t("clientInvoiceAmountPlaceholder")}
                    disabled={isEditMode}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t("minPoolSize")}
                  name="minimumPoolSize"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: t("minPoolSizeRequired")
                    },
                    {
                      type: "integer",
                      message: t("mustBeANumber"),
                      transform: (value) => (value ? Number(value) : "")
                    },
                    {
                      validator(_, value) {
                        if (value > 0) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("mustBeGreaterThanZero"))
                        );
                      }
                    }
                  ]}
                >
                  <Input placeholder={t("minPoolSizePlaceholder")} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t("surveyLeadUser")}
                  name="surveyLeadUser"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: t("surveyLeadUserRequired")
                    }
                  ]}
                >
                  <SurveyLeadUserSelector disabled={isSurveyClosed} />
                </Form.Item>
              </Col>
            </Row>

            <Flex justify="flex-end">
              <Button type="primary" htmlType="submit">
                {isEditMode ? t("updateSurvey") : t("generateLandingPage")}
              </Button>
            </Flex>
          </Form>
        </Flex>
      </Flex>
    </Card>
  );
};
