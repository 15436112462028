export const generateEventName = (
  expertName: string,
  projectName: string
): string => `${expertName}, ${projectName}`;

export const convertToPercentage = (
  rangeMin: number,
  rangeMax: number,
  answersAverage: number
): number => {
  // Calculate the range of values
  const range = rangeMax - rangeMin;

  // Calculate the distance of answersAverage from the minimum value
  const distanceFromMin = answersAverage - rangeMin;

  // Calculate the percentage
  const percentage = (distanceFromMin / range) * 100;

  return percentage;
};

export function noop(): void {
  // For rare cases where we're forced to pass a function but don't want to do anything
}
