import {
  Button,
  Card,
  Divider,
  Flex,
  Skeleton,
  Tooltip,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  ClipboardService,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { ExpertRate } from "@arbolus-technologies/models/common";
import { EXPERT_EXPERIENCE } from "@arbolus-technologies/models/expert";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { getCurrenciesSymbols } from "@arbolus-technologies/utils";

const { Text } = Typography;

interface CallPriceProps {
  expert: ExpertDetail;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const CallPrice: React.FC<CallPriceProps> = ({
  expert,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralCallPrice");
  const { projectId } = useParams<{ projectId: string }>();

  const [uiExpertRate, setUiExpertRate] = useState<ExpertRate | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const timezones = useSelector(CacheSelector.timezones());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  useEffect(() => {
    if (projectId && expert.id) {
      getExpertRate(projectId, expert.id);
    }
  }, [expert.id, projectId]);

  const getExpertRate = (projectId: string, expertId: string) => {
    setIsLoading(true);
    projectService.getRate(projectId, expertId).subscribe(
      (rate) => {
        setUiExpertRate(rate);
        setIsLoading(false);
      },
      (err: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(err);
        setIsLoading(false);
      }
    );
  };

  const pricingText = (): string => {
    if (uiExpertRate?.pricing?.rate) {
      return `${uiExpertRate.pricing.rate.amount} ${getCurrenciesSymbols(
        uiExpertRate.pricing.rate.isoCurrencyCode
      )}`;
    }

    if (uiExpertRate?.pricing?.creditRate) {
      return t("credit", { count: uiExpertRate.pricing.creditRate });
    }

    return "";
  };

  const pricing = pricingText();

  return (
    <Card>
      <Skeleton loading={isLoading}>
        <Flex justify="space-between" align="flex-start">
          <Flex vertical gap={4}>
            <Text strong>{t("callPrice")}</Text>
            <Flex gap={8}>
              <Text>{t("followUpDiscount")}</Text>
              {uiExpertRate?.pricing?.hasMinimumCallTimeRate && (
                <Text>{t("minimumCall")}</Text>
              )}
            </Flex>
          </Flex>
          <Flex gap={16} align="center">
            {pricing ? (
              <Button
                type="primary"
                style={{
                  backgroundColor: ARBOLUS_COLORS.bColorSuccess,
                  pointerEvents: "none"
                }}
              >
                {pricing}
              </Button>
            ) : (
              <Button
                type="default"
                style={{
                  backgroundColor: ARBOLUS_COLORS.bColorFillQuaternary,
                  pointerEvents: "none"
                }}
              >
                {t("notAssigned")}
              </Button>
            )}
          </Flex>
        </Flex>
        {isAdmin && (
          <>
            <Divider />
            <Flex vertical gap={4}>
              <Text strong>{t("details")}</Text>
              <Flex wrap style={{ rowGap: 8, columnGap: 24 }}>
                <Tooltip title={expert.user.email}>
                  <Text
                    ellipsis
                    style={{ maxWidth: "300px" }}
                    onClick={() =>
                      ClipboardService.copyToClipboard(expert.user.email)
                    }
                  >
                    {expert.user.email}
                  </Text>
                </Tooltip>
                {expert.user.phoneNumber && (
                  <Text
                    onClick={() =>
                      ClipboardService.copyToClipboard(expert.user.phoneNumber!)
                    }
                  >
                    {expert.user.phoneNumber}
                  </Text>
                )}
                {expert.experienceLevel && (
                  <Text>
                    {EXPERT_EXPERIENCE.get(expert.experienceLevel)?.label}
                  </Text>
                )}
                <Text>
                  {
                    timezones.find((tz) => tz.value === expert.user.timezone)
                      ?.label
                  }
                </Text>
              </Flex>
            </Flex>
          </>
        )}
      </Skeleton>
    </Card>
  );
};
