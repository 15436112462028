import { ColDef } from "ag-grid-community";
import i18next from "i18next";

import { TAGLINE_MAX_LENGTH } from "@arbolus-technologies/api";
import {
  BookedReferral,
  Referral,
  SlotsReferral
} from "@arbolus-technologies/models/common";
import {
  fixedWidth,
  floatingFilter
} from "@arbolus-technologies/ui/components";

import { AngleCellRenderer } from "../../../Components/CellRenderers/AngleCellRenderer";
import { EarliestAvailabilityRenderer } from "../../../Components/CellRenderers/EarliestAvailabilityRenderer";
import { FollowUpRenderer } from "../../../Components/CellRenderers/FollowUpRenderer";
import { JoinCallRenderer } from "../../../Components/CellRenderers/JoinCallRenderer";
import { MultilineCellRenderer } from "../../../Components/CellRenderers/MultilineCellRenderer";
import { RequestAvailabilityRenderer } from "../../../Components/CellRenderers/RequestAvailabilityRenderer";

export const TAGLINE_COL_ID = "tagline";

export const taglineCol: ColDef<Referral> = {
  colId: TAGLINE_COL_ID,
  field: "expert.tagline",
  tooltipField: "expert.tagline",
  headerName: i18next.t("referrals:headers:tagline"),
  headerTooltip: i18next.t("referrals:headers:tagline"),
  cellRenderer: MultilineCellRenderer,
  cellClass: "d-flex align-items-center cursor-pointer",
  flex: 1,
  minWidth: 250,
  editable: true,
  cellEditor: "agTextCellEditor",
  cellEditorParams: {
    maxLength: TAGLINE_MAX_LENGTH
  },
  filter: "agTextColumnFilter",
  filterParams: {
    maxNumConditions: 1,
    filterOptions: ["contains"],
    buttons: ["reset"]
  },
  ...floatingFilter
};

export const angleCol: ColDef<Referral> = {
  field: "angle.title",
  headerName: i18next.t("referrals:headers:angle"),
  headerTooltip: i18next.t("referrals:headers:angle"),
  cellRenderer: AngleCellRenderer,
  filter: "agSetColumnFilter",
  filterParams: {
    buttons: ["reset"]
  },
  floatingFilter: true,
  suppressHeaderMenuButton: true,
  minWidth: 150
};

export const slotsColumn: ColDef<SlotsReferral> = {
  field: "hasSlots",
  rowGroup: true,
  hide: true,
  valueFormatter: ({ value }) =>
    value === "true"
      ? i18next.t("referrals:gridGroups:slots")
      : i18next.t("referrals:gridGroups:noSlots")
};

export const earliestAvailabilityCol: ColDef<SlotsReferral> = {
  colId: "earliestAvailability",
  sort: "asc",
  headerName: i18next.t("referrals:headers:earliest"),
  cellClass: "p-0",
  cellRendererSelector: ({ value }) => ({
    component: value
      ? EarliestAvailabilityRenderer
      : RequestAvailabilityRenderer
  }),
  valueGetter: ({ data }) => data?.getEarliestSlot()?.startTime,
  ...fixedWidth(195)
};

export const bookedGroupCol: ColDef<BookedReferral> = {
  field: "latestEvent.callStatus",
  rowGroup: true,
  hide: true,
  valueFormatter: ({ value }) => i18next.t(`referrals:gridGroups:${value}`)
};

export const callStatusCol: ColDef<BookedReferral> = {
  colId: "callStatus",
  sort: "asc",
  headerName: i18next.t("referrals:headers:callStatus"),
  cellClass: "p-0",
  cellRendererSelector: ({ data }) => ({
    component: data?.latestEvent.isInFuture
      ? JoinCallRenderer
      : FollowUpRenderer
  }),
  valueGetter: ({ data }) => data?.latestEvent.startTime,
  ...fixedWidth(195)
};
