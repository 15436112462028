import { Input, Select } from "antd";
import React, { useCallback, useState } from "react";

import { PREFIXES, PrefixKey } from "./prefixes";

import styles from "./SimplePhoneInput.module.scss";

export interface PhoneValue {
  // Short prefix, without country name
  prefix: string;
  number: string;
}

export function SimplePhoneInput({
  value,
  onChange
}: {
  value: PhoneValue;
  onChange: (value: PhoneValue) => void;
}) {
  const [phoneNumber, setPhoneNumber] = useState(value.number);
  // Long prefix (includes country name) because it's a unique value
  const [prefix, setPrefix] = useState<PrefixKey>("United States (+1)");

  const handleNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Allow only digits (0-9)
      const newNumber = e.target.value;
      if (!/^\d*$/.test(newNumber)) {
        e.preventDefault();
        return;
      }
      setPhoneNumber(newNumber);
      onChange({ prefix: PREFIXES[prefix], number: newNumber });
    },
    [prefix, onChange]
  );

  const handlePrefixChange = useCallback(
    (newPrefix: PrefixKey) => {
      setPrefix(newPrefix);
      onChange({ prefix: PREFIXES[newPrefix], number: phoneNumber });
    },
    [phoneNumber, onChange]
  );

  return (
    <Input
      addonBefore={
        <PrefixSelector value={prefix} onChange={handlePrefixChange} />
      }
      placeholder="44 20 030 040 050"
      onChange={handleNumberChange}
      value={phoneNumber}
    />
  );
}

// Example: { value: "Bahrain (+973)", label: "+973" }
const FORMATTED_PREFIXES = Object.entries(PREFIXES).map(([value, label]) => ({
  label,
  value
}));

/*
 * "value" is long prefix name, that includes the country name
 * that's because it's easier to display and helps with countries sharing the same prefix
 */
function PrefixSelector({
  value,
  onChange
}: {
  value: PrefixKey;
  onChange: (prefix: PrefixKey) => void;
}) {
  return (
    <Select
      className={styles.prefixSelector}
      popupClassName={styles.prefixDropdown}
      value={value}
      onChange={onChange}
      showSearch
      options={FORMATTED_PREFIXES}
      optionRender={(option) => option.value}
    />
  );
}
