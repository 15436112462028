import clsx from "clsx";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { INBOX } from "@arbolus-technologies/routes";
import { useCookie } from "@arbolus-technologies/utils";

export const NotificationBanner: React.FC = () => {
  const { t } = useTranslation("notificationSettings");
  const { setCookie, cookieStored } = useCookie("dismissNotificationBanner");

  return (
    <div className="user-notification-settings">
      {!cookieStored && (
        <div className="user-info-container">
          <div className="header">
            <div className="bell-icon ciq-icon ciq-notification" />
            <h3>{t("howNotificationWorks")}</h3>
            <div
              className={clsx("ciq-icon ciq-close", {
                disabled: cookieStored
              })}
              onClick={setCookie}
              data-testid="close-icon-button"
            />
          </div>
          <div className="info-container">
            <p>
              <Trans
                i18nKey="notificationSettings:message_1"
                components={[<Link to={INBOX} key={1} />, <br key={1} />]}
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
