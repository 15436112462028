import { Observable } from "rxjs";

import { Permission } from "@arbolus-technologies/models/common";

import { CLIENTS_API } from "../constants/api";
import {
  ApiNonPaginatedResponse,
  ApiPaginatedResponse,
  DeletedResponse,
  ListResponse,
  SuccessResponse,
  UserSpecialPermissionsResponse
} from "../models/api";
import {
  BusinessEntity,
  BusinessEntityDataRequest
} from "../models/businessEntity";
import {
  ClientGetRequest,
  ClientInviteLink,
  ClientSavedExpertsGetRequest
} from "../models/client";
import { SavedExpertStatusResponse } from "../models/event";
import { Expert } from "../models/expert";
import { Country } from "../models/meta";
import { ListProjectBase, ProjectHistory } from "../models/projects";
import {
  BaseClientMember,
  ClientUser,
  CreateUserRequest,
  CreateUserResponse,
  UpdateUserRequest,
  UpdateUserResponse
} from "../models/user";
import { restService } from "../restService";

export const ClientService = {
  getUser: (clientId: string, userId: string): Observable<BaseClientMember> =>
    restService.get<BaseClientMember>(CLIENTS_API.GET_USER(clientId, userId)),

  createUser: (
    clientId: string,
    createUser: CreateUserRequest
  ): Observable<CreateUserResponse> =>
    restService.post(CLIENTS_API.CREATE_USER(clientId), createUser),

  updateUser: (
    clientId: string,
    userId: string,
    updateUser: UpdateUserRequest
  ): Observable<UpdateUserResponse> =>
    restService.put<UpdateUserResponse>(
      CLIENTS_API.UPDATE_USER(clientId, userId),
      updateUser
    ),

  getTeamMember: (
    clientId: string,
    userId: string
  ): Observable<BaseClientMember> =>
    restService.get<BaseClientMember>(
      CLIENTS_API.GET_TEAM_MEMBER(clientId, userId)
    ),

  getTeamMembers: (
    clientId: string,
    apiParams: ClientGetRequest
  ): Observable<ApiPaginatedResponse<ClientUser>> =>
    restService.get<ApiPaginatedResponse<ClientUser>>(
      CLIENTS_API.GET_TEAM_MEMBERS(clientId),
      apiParams
    ),

  getClientHistoryProjects: (
    clientId: string,
    userId: string
  ): Observable<ApiNonPaginatedResponse<ProjectHistory>> =>
    restService.get<ApiNonPaginatedResponse<ProjectHistory>>(
      CLIENTS_API.GET_TEAM_MEMBER_PROJECTS_HISTORY(clientId, userId)
    ),

  getTeamMembersCandidateProjects: (
    clientId: string,
    userId: string
  ): Observable<ApiNonPaginatedResponse<ListProjectBase>> =>
    restService.get<ApiNonPaginatedResponse<ListProjectBase>>(
      CLIENTS_API.GET_TEAM_MEMBERS_CANDIDATE_PROJECTS(clientId),
      {
        userId
      }
    ),

  getFrequentUsedCountries: (
    clientId: string,
    limit = 3
  ): Observable<ApiNonPaginatedResponse<Country>> =>
    restService.get<ApiNonPaginatedResponse<Country>>(
      CLIENTS_API.GET_FREQUENT_USED_COUNTRIES(clientId),
      { limit }
    ),

  getSavedExperts: (
    clientId: string,
    apiParams: ClientSavedExpertsGetRequest
  ): Observable<ApiPaginatedResponse<Expert>> =>
    restService.get<ApiPaginatedResponse<Expert>>(
      CLIENTS_API.GET_SAVED_EXPERTS(clientId),
      apiParams
    ),

  getSavedExpertStatus: (
    clientId: string,
    expertId: string
  ): Observable<SavedExpertStatusResponse> =>
    restService.get<SavedExpertStatusResponse>(
      CLIENTS_API.GET_SAVED_EXPERT_STATUS(clientId),
      {
        expertId
      }
    ),

  removeSavedExpert: (
    clientId: string,
    expertId: string
  ): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      CLIENTS_API.DELETE_SAVED_EXPERT(clientId),
      { expertId }
    ),

  sendOnboardingEmail: (
    clientId: string,
    userId: string
  ): Observable<SuccessResponse> =>
    restService.post(CLIENTS_API.SEND_ONBOARDING_EMAIL(clientId), { userId }),

  getInviteLink: (
    clientId: string,
    userId: string
  ): Observable<ClientInviteLink> =>
    restService.get(CLIENTS_API.GET_INVITE_LINK(clientId, userId)),

  getBusinessEntity: (
    businessEntityId: string
  ): Observable<BusinessEntityDataRequest> =>
    restService.get<BusinessEntityDataRequest>(
      CLIENTS_API.GET_BUSINESS_ENTITY(businessEntityId)
    ),
  updateBusinessEntity: (
    businessEntityDataRequest: BusinessEntityDataRequest,
    businessEntityId: string
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      CLIENTS_API.UPDATE_BUSINESS_ENTITY(businessEntityId),
      businessEntityDataRequest
    ),
  createBusinessEntity: (
    businessEntityDataRequest: BusinessEntityDataRequest,
    clientId: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      CLIENTS_API.CREATE_BUSINESS_ENTITY(clientId),
      businessEntityDataRequest
    ),
  getBusinessEntities: (
    clientId: string
  ): Observable<ApiNonPaginatedResponse<BusinessEntity>> =>
    restService.get<ApiNonPaginatedResponse<BusinessEntity>>(
      CLIENTS_API.GET_BUSINESS_ENTITIES(clientId)
    ),
  getTeamMembersProjects: (
    clientId: string,
    userId: string
  ): Observable<ApiNonPaginatedResponse<ListProjectBase>> =>
    restService.get<ApiNonPaginatedResponse<ListProjectBase>>(
      CLIENTS_API.GET_TEAM_MEMBERS_PROJECTS(clientId),
      {
        userId
      }
    ),
  getTeamMemberPermissions: (
    userId: string
  ): Observable<UserSpecialPermissionsResponse> =>
    restService.get<UserSpecialPermissionsResponse>(
      CLIENTS_API.GET_TEAM_MEMBER_PERMISSIONS(userId)
    ),
  updateTeamMemberPermissions: (
    userId: string,
    permissions: Permission[]
  ): Observable<SuccessResponse> =>
    restService.put(CLIENTS_API.UPDATE_TEAM_MEMBER_PERMISSIONS(userId), {
      permissions
    }),
  getSpecialPermissions: (): Observable<ListResponse<Permission>> =>
    restService.get<ListResponse<Permission>>(
      CLIENTS_API.GET_SPECIAL_PERMISSIONS()
    )
};
