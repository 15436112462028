import { Col, Radio, RadioChangeEvent, Row } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useLocation } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  Endorsement,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  ToasterService,
  UserService
} from "@arbolus-technologies/api";
import {
  EXPERT_SELF_PROFILE_EDIT,
  EXPERT_DETAILS_EDIT_TABS_PATH as ROUTES,
  EXPERT_DETAILS_EDIT_TABS as TABS
} from "@arbolus-technologies/routes";
import { AntDHeader } from "@arbolus-technologies/ui/layout";

import {
  Details,
  ExpertProfileFormDetails
} from "../../Components/Details/Details";
import { Endorsements } from "../../Components/Endorsements/Endorsements";
import { Insights } from "../../Components/Insights/Insights";
import { NotificationSettings } from "../../Components/NotificationSettings/NotificationSettings";
import { SecuritySettings } from "../../Components/SecuritySettings/SecuritySettings";
import { SkeletonLoader } from "../../Components/SkeletonLoader/SkeletonLoader";
import { EditExpertExperience } from "../../Modules/EditExpertExperience/EditExpertExperience";
import { ExpertNotificationDetails } from "../../helpers/types";

import styles from "./EditProfile.module.scss";

const { Group } = Radio;

export interface EditProfileProps {
  expertDetails: ExpertNotificationDetails;
  userId: string;
  logOut: () => void;
  userService?: typeof UserService;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const EditProfile: React.FC<EditProfileProps> = ({
  expertDetails,
  userId,
  logOut,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const [expertProfile, setExpertProfile] =
    useState<ExpertProfileFormDetails | null>(null);
  const [endorsements, setEndorsements] = useState<Endorsement[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation("expertEditProfile");
  const location = useLocation();
  const history = useHistory();

  const activeTab = getActiveTab(location);

  useEffect(function getExpertDetails() {
    expertService
      .getExpertById({
        expertId: expertDetails.expertId!,
        includeRateCard: true
      })
      .subscribe(
        (expert: ExpertDetail) => {
          const expertForm = getExpertFormFormatted(expert);
          setEndorsements(expert.endorsements);
          setExpertProfile(expertForm);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          setIsLoading(false);
        }
      );
  }, []);

  const handleTabChange = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      history.push(`${EXPERT_SELF_PROFILE_EDIT}/${value}`);
    },
    []
  );

  const updateExpertExperience = useCallback((background: string) => {
    const { id, overview, linkedinProfile, experienceLevel } = expertProfile!;

    expertService
      .updateExpertProfile(
        id,
        overview!,
        experienceLevel!,
        ["", ""],
        linkedinProfile,
        "",
        background
      )
      .subscribe(
        () => {
          setExpertProfile((prevState) => ({ ...prevState!, background }));
          notificationService.showSuccess(t("experienceUpdateSuccess"));
        },
        () => {
          notificationService.showError(t("unableToSaveDetails"));
        }
      );
  }, []);

  const TABS_ROUTES = useMemo(
    () => [
      {
        label: t("experience"),
        value: TABS.EXPERIENCE,
        path: ROUTES.EXPERIENCE,
        component: () => (
          <EditExpertExperience
            expertId={expertDetails.expertId!}
            background={expertProfile?.background}
            updateExpertExperience={updateExpertExperience}
          />
        )
      },
      {
        label: t("insights"),
        value: TABS.INSIGHTS,
        path: ROUTES.INSIGHTS,
        component: () => <Insights expertId={expertDetails.expertId!} />
      },
      {
        label: t("endorsements"),
        value: TABS.ENDORSEMENTS,
        path: ROUTES.ENDORSEMENTS,
        component: () => (
          <Endorsements
            expertId={expertDetails.expertId!}
            endorsements={endorsements!}
          />
        )
      },
      {
        label: t("security"),
        value: TABS.PASSWORD,
        path: ROUTES.PASSWORD,
        component: SecuritySettings
      },
      {
        label: t("settings"),
        value: TABS.SETTINGS,
        path: ROUTES.SETTINGS,
        component: () => (
          <NotificationSettings expertDetails={expertDetails} logOut={logOut} />
        )
      }
    ],
    [expertProfile, expertDetails, userId]
  );

  if (isLoading) {
    return (
      <div className={styles.page}>
        <AntDHeader title={t("editProfile")} />
        <div className={styles.pageContent}>
          <SkeletonLoader />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <AntDHeader title={t("editProfile")} />

      <div className={styles.pageContent}>
        <Group
          options={[
            { label: t("details"), value: TABS.DETAILS },
            ...TABS_ROUTES
          ]}
          value={activeTab}
          onChange={handleTabChange}
          optionType="button"
          buttonStyle="solid"
        />
        <Row>
          <Col span={18} pull={0}>
            <Switch>
              {TABS_ROUTES.map(({ path, component: Component }) => (
                <Route key={path} path={path}>
                  <Component />
                </Route>
              ))}
              {/* Manually defined at the end because it's a default one */}
              <Route
                path={[ROUTES.DETAILS, "*"]}
                component={() => (
                  <Details expertProfile={expertProfile} userId={userId} />
                )}
              />
            </Switch>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const getExpertFormFormatted = (
  expert: ExpertDetail
): ExpertProfileFormDetails => {
  const {
    id,
    user: { firstName, lastName, phoneNumber, profileImageUrl, email, title },
    linkedinProfile,
    overview,
    experienceLevel,
    rateCards,
    background
  } = expert;

  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    profileImageUrl,
    linkedinProfile,
    title,
    background,
    overview,
    experienceLevel,
    hourlyRate: rateCards && rateCards[0]?.price,
    isoCurrencyCode: rateCards && rateCards[0]?.isoCurrencyCode
  };
};

// any because of type mismatch between native Location and react-router
function getActiveTab(location: any) {
  const tabFromLocation = location.pathname.split("/").pop();
  if (tabFromLocation === "" || tabFromLocation === "profile-edit") {
    return TABS.DETAILS;
  }
  return tabFromLocation;
}
