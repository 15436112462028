import {
  ANSWER_PREFERENCES_TYPE_VALUE,
  EXPERT_CANOPY_STATUS
} from "@arbolus-technologies/models/canopy";
import {
  DNC_CATEGORY_ENUM,
  DO_NOT_CONTACT_STATUS,
  DateString
} from "@arbolus-technologies/models/common";
import { WorkHistory } from "@arbolus-technologies/models/expert";

import { ANSWER_TYPE } from "./enums";

export interface ExpertAnswers {
  expertId: string;
  profileImageUrl?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
}

export interface QuestionModel {
  id: string;
  text: string;
  details?: string;
  experts?: ExpertAnswers[];
  expertsCount?: number;
  answersCount?: number;
}

export interface CanopyQuestionModel extends QuestionModel {
  sortOrder: number;
  lastAnswerDate?: Date;
  type: ANSWER_TYPE;
}

export interface ExpertProfile {
  expertId: string;
  profileImageUrl?: string;
  title: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  status?: EXPERT_CANOPY_STATUS;
  isSubmitted?: boolean;
  id: string;
  lastPublicCompanyExpDate: DateString | null;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  doNotContactStatusDescription?: string;
  doNotContactStatusCategory?: DNC_CATEGORY_ENUM | null;
}

interface CanopySummaryQuotes {
  quote: string;
  expert: CanopySummaryQuotesExpert;
  canopyAnswerId: string;
}

export interface CanopySummaryQuotesExpert {
  expertId: string;
  title: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string | null;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
}

export interface CanopyAnswerSummaryItem {
  totalAnswersCount: number;
  canopyQuestionId: string;
  questionText: string;
  questionDetails: string;
  sortOrder: number;
  summary: string;
  modified: string;
  questionType: ANSWER_TYPE;
  isOpen?: boolean;
}

export interface CanopyAnswerSummaryItemVideo extends CanopyAnswerSummaryItem {
  summaryQuotes: CanopySummaryQuotes[];
}

export interface CanopyAnswerSummaryItemMultipleChoice
  extends CanopyAnswerSummaryItem {
  choiceAnswers: ChoiceAnswer[];
  otherAnswers: OtherAnswersExpertSummary[];
}

export interface CanopyAnswerSummaryItemValue extends CanopyAnswerSummaryItem {
  rangeMin: number;
  rangeMax: number;
  valuePreferencesType: ANSWER_PREFERENCES_TYPE_VALUE;
  valueAnswers: ChoiceValueAnswer[];
}

export interface CanopyAnswerSummaryItemShortText
  extends CanopyAnswerSummaryItem {
  shortTextAnswers: ShortTextSummaryAnswer[];
}

export interface CanopyAnswerSummaryItemNps extends CanopyAnswerSummaryItem {
  netPromoterScore: number;
  npsAnswers: AnswerNps[];
  nullAnswersCount: number;
}

export interface ShortTextSummaryAnswer {
  canopyAnswerId: string;
  expert: AnswerExpert;
  shortText: string;
}

export enum CanopySummaryStatus {
  FAILED = "Failed",
  COMPLETED = "Completed",
  IN_PROGRESS = "InProgress"
}

export type CanopyAnswersSummariesTypes =
  | CanopyAnswerSummaryItemVideo
  | CanopyAnswerSummaryItemMultipleChoice
  | CanopyAnswerSummaryItemValue
  | CanopyAnswerSummaryItemShortText
  | CanopyAnswerSummaryItemNps;

export interface CanopySummary {
  numExpertsInSummary: number;
  numExpertsInCanopyAnswers: number;
  isSummaryRequestAllowed: boolean;
  projectName: string;
  lastUpdate: string;
  canopySummaryStatus?: CanopySummaryStatus;
  canopyAnswersSummaries: CanopyAnswersSummariesTypes[];
}

export interface ExpertCanopyV2 {
  status: EXPERT_CANOPY_STATUS;
  expert: {
    expertId: string;
    fullName?: string;
    profileImageUrl?: string;
    title: string;
    lastPublicCompanyExpDate: DateString | null;
    doNotContactStatus: DO_NOT_CONTACT_STATUS;
  };
}

interface AnswerModel {
  answerId: string;
  transcript?: string;
  optionChoices?: OptionChoiceAnswer[];
  type: ANSWER_TYPE;
  downloadUrl: string;
  question: QuestionDetails;
  created: string;
  isSmartTranscript?: boolean;
  multiRatingChoices?: MultiRatingChoice[] | null;
  value?: number | null;
  valuePreferencesType?: ANSWER_PREFERENCES_TYPE_VALUE;
  valuePreferencesRange?: RangeBetween;
  shortText?: string;
  npsValue?: number | null;
  expert: ExpertProfile;
}

export interface OptionChoiceAnswer extends OptionChoice {
  isSelected: boolean;
  otherText: string | null;
}

export interface VideoAnswer extends AnswerModel {
  question: VideoQuestionDetails;
  transcript?: string;
  downloadUrl: string;
  isSmartTranscript: boolean;
}

export interface MultiChoiceAnswer extends AnswerModel {
  question: MultipleChoiceQuestionDetails;
  selectedOptionChoicesIds: string[];
  otherText: string | null;
}

export interface MultiRatingChoice {
  multiRatingChoiceId: string;
  multiRatingChoiceText: string;
  multiRatingValue?: number | null;
  sortOrder: number;
}

export interface ValueAnswer extends AnswerModel {
  question: ValueQuestionDetails;
  multiRatingChoices: MultiRatingChoice[] | null;
  value: number | null;
  valuePreferencesType: ANSWER_PREFERENCES_TYPE_VALUE;
  valuePreferencesRange: RangeBetween;
}

export interface ShortTextAnswer extends AnswerModel {
  email: string;
  expertId: string;
  firstName: string;
  id: string;
  lastName: string;
  profileImageUrl: string;
  shortText: string;
  title: string;
  responseDate: string;
}

export interface NpsAnswer extends AnswerModel {
  npsValue: number | null;
}

export type Answer =
  | VideoAnswer
  | MultiChoiceAnswer
  | ValueAnswer
  | ShortTextAnswer
  | NpsAnswer;

export interface ExpertAnswerModel {
  expert: ExpertProfile;
  answers: Answer[];
  status: EXPERT_CANOPY_STATUS;
}

export interface ChoiceAnswer {
  answersCount: number;
  isOther: boolean;
  optionChoiceId: string;
  optionChoiceText: string;
}

export interface AnswerMultiChoice {
  choiceAnswers: ChoiceAnswer[];
  lastResponseDate: Date;
  rangeMax: number;
  rangeMin: number;
  totalAnswersCount: number;
}

export interface AnswerValue {
  questionId: string;
  rangeMin: number;
  rangeMax: number;
  lastResponseDate: string;
  valuePreferencesType: ANSWER_PREFERENCES_TYPE_VALUE;
  valueAnswers: ChoiceValueAnswer[];
}

export interface ChoiceValueAnswer {
  answersAverage: number | null;
  multiRatingChoiceId: string | null;
  multiRatingChoiceText: string | null;
}

export interface NpsAnswers {
  lastResponseDate?: string;
  netPromoterScore: number;
  nullAnswersCount: number;
  npsAnswers: AnswerNps[];
}

export interface AnswerNps {
  expertCount: number;
  npsValue: number;
  npsPercentage: number;
}

export interface CanopySelectedQuestionDetails {
  value?: string | null;
  percentage?: number;
  choice?: { id: string | null; text: string | null };
  npsValue?: number | null;
}

export interface MultiChoiceSelectedQuestion
  extends CanopySelectedQuestionDetails {
  percentage: number;
  choice: { id: string; text: string };
}

export interface ValueSelectedQuestion extends CanopySelectedQuestionDetails {
  value: string | null;
  percentage: number;
  choice: { id: string | null; text: string | null };
}

export interface NpsSelectedQuestion extends CanopySelectedQuestionDetails {
  npsValue: number | null;
  totalExperts: number;
}

interface QuestionDetailsModel {
  questionId: string;
  title: string;
  text: string;
  details: string;
  sortOrder: number;
  topicName?: string;
  topicId?: string;
}

export type VideoQuestionDetails = QuestionDetailsModel;

export interface RangeBetween {
  minimum: number;
  maximum: number;
}

export interface OptionChoice {
  id: string;
  text: string;
  sortOrder: number;
  isOther: boolean;
}

export interface MultipleChoiceQuestionDetails extends QuestionDetailsModel {
  answersRange: RangeBetween;
  optionChoices: OptionChoice[];
}

export type ValueQuestionDetails = QuestionDetailsModel;

export type QuestionDetails =
  | VideoQuestionDetails
  | MultipleChoiceQuestionDetails
  | ValueQuestionDetails;

export interface AnswerDetails {
  id: string;
  videoUrl: string;
  transcript?: string;
  created: string;
}

export interface ExpertUser {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  profileImageUrl?: string;
  isoCountryCode?: string;
  phoneNumber?: string;
  timezone?: string;
}

export interface AnswerExpert {
  otherResponse?: string | null;
  title: string;
  expertId: string;
  id: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string | null;
  value?: number;
  lastPublicCompanyExpDate: DateString | null;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
}

export interface OtherAnswersExpertSummary {
  canopyAnswerId: string;
  expert: AnswerExpert;
  otherResponse: string | null;
}

export interface Endorsement {
  endorserId: string;
  endorser: ExpertUser;
  quote: string;
}

export interface EndorsementWithId extends Endorsement {
  id: string;
}
export interface IExpertProfile {
  id: string;
  endorsements: Endorsement[];
  user: ExpertUser;
  workHistories: WorkHistory[];
  background?: string;
  linkedinProfile?: string;
  overview?: string;
  isInClientNetwork: boolean;
  twoLetterCode?: string;
  countryName?: string;
  lastPublicCompanyExpDate: DateString | null;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  doNotContactStatusDescription?: string;
  doNotContactStatusCategory?: DNC_CATEGORY_ENUM | null;
}
