import { Button, Flex } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReferralDetail } from "@arbolus-technologies/api";
import { AvailableSlot } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";

interface ButtonsWrapperProps {
  referral: ReferralDetail;
  disableEventCreation: boolean;
  children?: React.ReactNode;
  onBookEvent: (slot?: AvailableSlot) => void;
  onRequestTimeSlots: () => void;
}

export const ButtonsWrapper: React.FC<ButtonsWrapperProps> = ({
  referral,
  disableEventCreation,
  children,
  onBookEvent,
  onRequestTimeSlots
}) => {
  const { t } = useTranslation("referralScheduling");

  const [
    isRequestTimeSlotsAlreadyClicked,
    setIsRequestTimeSlotsAlreadyClicked
  ] = useState(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const handleRequestTimeSlots = () => {
    setIsRequestTimeSlotsAlreadyClicked(true);
    onRequestTimeSlots();
  };

  const {
    availabilityRequesterUser,
    availabilityRequiredDate,
    isRequiredMoreAvailabilitySlotsAllowed,
    waitingHours
  } = referral.referralAvailability;

  const formattedDate = moment(availabilityRequiredDate).format("ll - h:mm a");
  const availabilityRequesterName = `${availabilityRequesterUser?.firstName} ${availabilityRequesterUser?.lastName}`;

  return (
    <>
      <Flex gap={16} justify="space-between">
        {children}
        <Flex gap={16} style={{ marginLeft: "auto" }}>
          {isAdmin && (
            <Button
              disabled={disableEventCreation}
              onClick={() => onBookEvent()}
            >
              {t("bookCustomTime")}
            </Button>
          )}
          <Button
            type="primary"
            onClick={handleRequestTimeSlots}
            disabled={
              disableEventCreation ||
              isRequestTimeSlotsAlreadyClicked ||
              !isRequiredMoreAvailabilitySlotsAllowed
            }
          >
            {t("requestNewTimeSlot")}
          </Button>
        </Flex>
      </Flex>
      {!isRequiredMoreAvailabilitySlotsAllowed && (
        <DangerouslySetInnerHTML
          text={t("requireAvailabilityMessage", {
            date: formattedDate,
            name: availabilityRequesterName,
            hours: waitingHours
          })}
          color={ARBOLUS_COLORS.bColorError}
        />
      )}
    </>
  );
};
