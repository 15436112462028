import { ExpertPaymentsPage } from "@arbolus-technologies/features/payments";
import { PAYMENTS } from "@arbolus-technologies/routes";

import { SentryRoute } from "../../sentry";

export const ExpertPaymentsRouter = [
  <SentryRoute
    path={PAYMENTS}
    key={PAYMENTS}
    component={ExpertPaymentsPage}
    exact
  />
];
