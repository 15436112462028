import { Button } from "arbolus-ui-components";
import { useTranslation } from "react-i18next";

import styles from "./GiveDetailsFooter.module.scss";

export function GiveDetailsFooter({
  onSubmitSurvey
}: {
  onSubmitSurvey: () => void;
}) {
  const { t } = useTranslation("expertSurvey");

  return (
    <div className={styles.footer}>
      <Button onClick={onSubmitSurvey} text={t("submitSurveyButton")} />
    </div>
  );
}
