// Same names as in secrets repository
export const DEFAULT_FEATURE_FLAGS = {
  ArbolusGpt: false,
  CreateCanopAI: false,
  CanopiesPage: false,
  CompaniesPage: false,
  CanopyApplication: false,
  CanopyBuilderV2: false,
  CanopySummaries: false,
  CanopyFeedbackCollector: false,
  ClientAccountPageTabs: false,
  Chat: false,
  ExpertDiscover: false, // Admin
  OldExpertDiscover: false, // Client
  FeatureFlagOverride: false,
  NewCompliance: false,
  NewDashboard: false,
  NewExpertApplication: false,
  NewProjectDashboard: false,
  MultiRatingAnswer: false,
  NPSAnswer: false,
  ProjectsTable: false,
  SimplifiedProjectCreation: false,
  ShortTextAnswer: false,
  Tipalti: false,
  WorkWithMe: false,
  ExternalSourcing: false,
  CustomerInsights: false,
  ExpertSearchCustomerFilter: false,
  AntDUI: false,
  ProjectCreationV1: false,
  ProjectCreationV3: false,
  NewProjectEditPage: false,
  Surveys: false,
  NewCalendarAntD: false,
  NewReferralTableSidePanel: false,
  Industry: false,
  CompaniesIndustry: false,
  MicrosoftAppleSSO: false,
  NewAddEditUsers: false,
  LinkedInAuth: false,
  NewFindExpertCard: false,
  Gatekeeping: false,
  NewEventPage: false,
  GatekeepingLinkedinRequired: false,
  GatekeepingApprovalRequired: false,
  EventCreationGatekeeping: false,
  CanopyGatekeeping: false,
  WebinarBanner: true
} as const;

// Unfortunately "FeatureFlags" is already taken by the "flagged" library
// in this way we'll avoid wrong auto imports
export type ArbolusFeatureFlags = typeof DEFAULT_FEATURE_FLAGS;

export type ArbolusFeatureFlag = keyof typeof DEFAULT_FEATURE_FLAGS;

// String representation
export const FEATURE_FLAGS = Object.fromEntries(
  Object.keys(DEFAULT_FEATURE_FLAGS).map((key) => [key, key])
) as Record<ArbolusFeatureFlag, ArbolusFeatureFlag>;

export enum FeatureFlagsAppType {
  CLIENT = "Client",
  ADMIN = "Admin"
}
