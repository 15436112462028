export enum PUBLIC_COMPANIES_PERIOD {
  Current = 0,
  ThreeMonths = 3,
  SixMonths = 6,
  TwelveMonths = 12
}

export enum TRANSCRIPT_RETENTION_PERIOD {
  ThirtyDays = 30,
  SixtyDays = 60,
  NinetyDays = 90,
  SixMonths = 182, // 182 days ~= 6 months
  OneYear = 365, // 365 days ~= 1 year
  TwentyYears = 7300 // 7300 days ~= 20 years
}

export enum COMPLIANCE_VALUE_ANSWER {
  Yes = "Yes",
  No = "No",
  NotApplicable = "NotApplicable"
}

export enum COMPLIANCE_QUESTION_TYPE {
  YesNo = "YesNo",
  YesNoText = "YesNoText",
  YesNoNa = "YesNoNa", // Should not be used
  Text = "Text" // Should not be used
}

export enum AUTOMATIC_MESSAGE_RECEIVER_ROLE {
  Expert = "Expert",
  Client = "Manager"
}

export enum AUTOMATIC_MESSAGE_CHANNEL {
  Email = "Email",
  Chat = "Chat"
}

export enum CALL_EVENT_TYPE {
  BEFORE = "before",
  AFTER = "after"
}

export enum DO_NOT_CONTACT_STATUS {
  OK = "Ok",
  CAUTION = "Caution",
  DNC = "DoNotContact"
}

export enum DNC_CATEGORY_ENUM {
  MISREPRESENTATION = "Misrepresentation",
  TRANSACTION_FRAUD = "TransactionFraud",
  ADVERSE_MEDIA_REPORTS = "AdverseMediaReports",
  POLITICALLY_EXPOSED_PERSON = "PEP",
  CLIENT_REQUEST_TO_DNC = "ClientRequestDnc",
  BREACH_OF_TERMS_AND_CONDITIONS = "BreachOfTermsAndConditions",
  EXPERT_REQUEST_NOT_TO_CONTACT = "ExpertRequestDnc",
  FICTITIOUS_ACCOUNT = "FictitiousAccount",
  DUPLICATE_PROFILE = "DuplicateProfile",
  INTERNAL_DEMO_PROFILE = "InternalDemoProfile",
  COMPANY_DNC = "CompanyDnc",
  OTHER = "Other"
}

export enum SETTINGS_PAGE_TABS_ENUM {
  DETAILS = "details",
  BUSINESS_ENTITIES = "businessEntities",
  COMPLIANCE = "compliance",
  COMPLIANCE_COMMS = "complianceComms",
  GATEKEEPING = "gatekeeping"
}
