import { Flex } from "antd";
import React from "react";

import { ARBOLUS_PAGE } from "@arbolus-technologies/features/common";
import {
  arbolusLogo,
  fastestEurope,
  fastestUK
} from "@arbolus-technologies/theme";
import { LinkWrapper } from "@arbolus-technologies/ui/components";

import styles from "./GrowingCompanies.module.scss";

export const GrowingCompanies: React.FC = () => {
  return (
    <Flex
      justify="space-between"
      align="center"
      className={styles.bottomLogosContainer}
    >
      <LinkWrapper to={ARBOLUS_PAGE} isExternal target="_blank">
        <img
          src={arbolusLogo}
          alt="Arbolus logo"
          className={styles.arbolusLogoBottom}
        />
      </LinkWrapper>
      <Flex
        gap={16}
        align="center"
        justify="center"
        className={styles.growingCompaniesContainer}
      >
        <img
          src={fastestEurope}
          alt="1000 growing companies Europe"
          width={220}
        />
        <img src={fastestUK} alt="100 growing companies UK" width={220} />
      </Flex>
    </Flex>
  );
};
