import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AdminExpertListResponseItem } from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { DncInfoBox, ExpertHeader } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { DNCHistoryList } from "../../Components/DNC/DNCHistoryList/DNCHistoryList";
import { SlidePanel } from "../../Panels/SlidePanel/SlidePanel";

import styles from "./ExpertDncHistorySlidePanel.module.scss";

interface ExpertDncHistorySlidePanelProps {
  expert: AdminExpertListResponseItem;
  isModalOpen: boolean;
  handleClosePanel: () => void;
}

export const ExpertDncHistorySlidePanel: React.FC<
  ExpertDncHistorySlidePanelProps
> = ({ expert, isModalOpen, handleClosePanel }) => {
  const { t } = useTranslation("dnc");

  const metadataCountries = useSelector(CacheSelector.metadataCountries());

  const expertCountry = metadataCountries.find(
    (metadataCountry) =>
      metadataCountry.threeLetterCode === expert.isoCountryCode
  );

  return (
    <SlidePanel
      panelId={PanelId.ExpertDncHistory}
      width={SIDE_PANEL_SIZE._720}
      title={t("expertStatus")}
      closeButtonDirection="right"
      customCloseRequest={handleClosePanel}
      isMapped
      isOpenInternally={isModalOpen}
      noPadding
    >
      <div className={styles.container}>
        <ExpertHeader
          expertUser={expert}
          countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
          lastPublicCompanyDate={expert.lastPublicCompanyExpDate}
        />
        <DncInfoBox
          doNotContactStatus={expert.doNotContactStatus}
          doNotContactStatusCategory={expert.doNotContactStatusCategory}
          isAdmin
        />
        <DNCHistoryList expertId={expert.id} />
      </div>
    </SlidePanel>
  );
};
