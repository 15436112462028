import { LocationDescriptorObject } from "history";
import { ActionType } from "typesafe-actions";

import { PanelStoreActions } from "../..";

interface Panel {
  id: PanelId;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export interface ModalData {
  title: string;
  subtitle: string;
  confirmText?: string;
  cancelText?: string;
  type?: "confirmation" | "primary" | "rejection";
  onConfirm: () => void;
  onlyConfirm?: boolean;
}

export interface ListModalData extends Omit<ModalData, "subtitle"> {
  messages: { text: string; to?: LocationDescriptorObject }[];
}

export interface InputModalData
  extends Omit<ModalData, "onConfirm" | "subtitle"> {
  placeholder?: string;
  // FIXME: never put a function in redux
  onConfirm: (value: string) => void;
}

export interface PanelReducerState {
  panels: Panel[];
  mainModal: ModalData & { isOpen: boolean };
  listMainModal: ListModalData & { isOpen: boolean };
  inputMainModal: InputModalData & { isOpen: boolean };
}

export interface PanelAppState {
  panels: PanelReducerState;
}

export enum PanelId {
  AddEditCompany = "addEditCompany",
  ArbolusGpt = "ArbolusGpt",
  AssignExperts = "AssignExperts",
  AssignManagers = "assignManagers",
  AssignProject = "assignProject",
  AttachFiles = "AttachFiles",
  CanopyAgreement = "canopyAgreement",
  ClientList = "clientList",
  ClientProfile = "clientProfile",
  ClientTeamInvite = "clientTeamInvite",
  Compliance = "Compliance",
  CreateAngle = "createAngle",
  CreateSimpleCanopy = "CreateSimpleCanopy",
  CreateTransaction = "createTransaction",
  DetailsTransaction = "detailsTransaction",
  DiscoverWorkWithMe = "DiscoverWorkWithMe",
  EarliestAvailabilities = "earliestAvailabilities",
  EditCanopyQuestion = "editCanopyQuestion",
  EditTransaction = "editTransaction",
  ExpertAvailability = "expertAvailability",
  ExpertEngageWithMe = "expertEngageWithMe",
  ExpertProfile = "expertProfile,",
  ExpertStatus = "expertStatus",
  GroupCompanies = "groupCompanies",
  ManageAngles = "ManageAngles",
  MergeCompanies = "mergeCompanies",
  MoveToShortlist = "moveToShortlist",
  NewComment = "newComment",
  NotificationSettings = "notificationSettings",
  ProjectNotes = "ProjectNotes",
  ProjectNotifications = "projectNotifications",
  ProjectSpend = "projectSpend",
  ReferExpert = "ReferExpert",
  ReferralExpertPanel = "ReferralExpertPanel",
  ReferralEventPanel = "ReferralEventPanel",
  RejectFeedback = "rejectFeedback",
  RejectFeedbackPanel = "RejectFeedbackPanel",
  ReportIncident = "incidentReporting",
  UpdateExpertAngle = "UpdateExpertAngle",
  ExpertDncHistory = "expertDncHistory",
  ExternalExpert = "externalExpert",
  AssignExpertToProject = "assignProjectToExpert",
  AddVendor = "addVendor",
  DiscoverMatchingFilters = "discoverMatchingFilters",
  ReportCompany = "reportCompany",
  AddMonitoringListCompany = "addMonitoringListCompany",
  ModifySurveyExpertOwner = "modifySurveyExpertOwner"
}

export const panels: Panel[] = Object.values(PanelId).map((id) => ({
  id,
  isOpen: false
}));

export type PanelStoreAction = ActionType<typeof PanelStoreActions>;
