import React from "react";
import { isMobile } from "react-device-detect";

import {
  DiscoverFilterOperator,
  DiscoverFilterOption
} from "@arbolus-technologies/models/project";

import { FilterPill } from "../FilterPill/FilterPill";
import { MobileFilterPill } from "../MobileFilterPill/MobileFilterPill";

import styles from "./SelectedFilterPills.module.scss";

interface SelectedFilterPillsProps {
  disablePillToggle?: boolean;
  selectedOptions: DiscoverFilterOption[];
  setSelectedOptions: (options: DiscoverFilterOption[]) => void;
  preSelectedOptions?: DiscoverFilterOption[];
  setPreSelectedOptions?: (options: DiscoverFilterOption[]) => void;
  urlChangeOnFilterUpdate: (options: DiscoverFilterOption[]) => void;
  showFlagIcon?: boolean;
  isEditable?: boolean;
  onEdit?: () => void;
}

export const SelectedFilterPills: React.FC<SelectedFilterPillsProps> = ({
  selectedOptions,
  preSelectedOptions,
  setSelectedOptions,
  disablePillToggle = false,
  setPreSelectedOptions,
  urlChangeOnFilterUpdate,
  showFlagIcon = false,
  isEditable = false,
  onEdit
}) => {
  const handleOptionRemoved = (removedOption: DiscoverFilterOption) => {
    const filteredSelectedOptions = selectedOptions.filter(
      (option: DiscoverFilterOption) => option.value !== removedOption.value
    );
    setSelectedOptions(filteredSelectedOptions);
    urlChangeOnFilterUpdate(filteredSelectedOptions);

    if (preSelectedOptions && setPreSelectedOptions) {
      const filteredPreSelectedOptions = preSelectedOptions.filter(
        (option: DiscoverFilterOption) => option.value !== removedOption.value
      );
      setPreSelectedOptions(filteredPreSelectedOptions);
    }
  };

  const toggleIncludeExclude = (option: DiscoverFilterOption) => {
    const currentOption = selectedOptions.find((o) => o.value === option.value);
    if (currentOption) {
      const updatedOption = { ...currentOption };
      updatedOption.operator =
        currentOption.operator === DiscoverFilterOperator.Exclude
          ? DiscoverFilterOperator.Include
          : DiscoverFilterOperator.Exclude;

      // to handle reference issue
      const newOptions = [
        ...selectedOptions.filter((o) => o.value !== updatedOption.value),
        updatedOption
      ];
      setSelectedOptions(newOptions);
      urlChangeOnFilterUpdate(newOptions);
    }
  };

  const PillComponent = isMobile ? MobileFilterPill : FilterPill;

  if (selectedOptions.length < 1) {
    return null;
  }

  return (
    <div className={styles.selectedOptions}>
      {selectedOptions.map((option: DiscoverFilterOption) => (
        <PillComponent
          key={option.value}
          option={option}
          onRemove={() => handleOptionRemoved(option)}
          onToggleIncludeExclude={() => toggleIncludeExclude(option)}
          disablePillToggle={disablePillToggle}
          isEditable={isEditable}
          onEdit={onEdit}
          flagIcon={
            showFlagIcon &&
            option.twoLetterCode && (
              <span
                className={`flag-icon flag-icon-${option.twoLetterCode.toLowerCase()}`}
                id="countryFlag"
              />
            )
          }
        />
      ))}
    </div>
  );
};
