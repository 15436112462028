import { Button, Card, Skeleton } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { BaseSurvey } from "@arbolus-technologies/api";
import { PROJECT_CREATE_SURVEY_ROUTE } from "@arbolus-technologies/routes";

import { EmptySurveyCard } from "./components/EmptySurveyCard";
import { SurveyCard } from "./components/SurveyCard";
import { SurveyTitle } from "./components/SurveyTitle";

import styles from "./SurveyGrayBox.module.scss";

interface SurveyGrayBoxProps {
  to: string;
  projectId?: string;
  isSurveysLoading: boolean;
  surveys: BaseSurvey[];
}

export const SurveyGrayBox: React.FC<SurveyGrayBoxProps> = ({
  to,
  projectId,
  isSurveysLoading,
  surveys
}) => {
  const { t } = useTranslation("surveyGrayBox");

  return (
    <Card
      title={<SurveyTitle to={to} />}
      extra={
        projectId && (
          <Link to={PROJECT_CREATE_SURVEY_ROUTE(projectId)}>
            <Button type="primary">{t("newSurvey")}</Button>
          </Link>
        )
      }
    >
      <Skeleton active loading={isSurveysLoading}>
        {surveys.length === 0 && projectId && (
          <EmptySurveyCard projectId={projectId} />
        )}
        {surveys.length > 0 && (
          <div className={styles.cardsContainer}>
            {surveys.map((survey) => (
              <SurveyCard key={survey.id} {...survey} />
            ))}
          </div>
        )}
      </Skeleton>
    </Card>
  );
};
