import { xorWith } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  CIQError,
  Company,
  CompanyService,
  DefaultToasterService,
  ErrorResponse,
  LIST_COMPANIES_ORDER_BY,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  Button,
  HR,
  LoaderOrComponent,
  SearchInput
} from "@arbolus-technologies/ui/components";
import {
  SEARCH_DEBOUNCE_TIMEOUT_COMMON,
  SIDE_PANEL_SIZE,
  isNonEmptyArray
} from "@arbolus-technologies/utils";

import { SlidePanel } from "../SlidePanel/SlidePanel";
import { VendorCardItem } from "./VendorCardItem";

import styles from "./AddCreateVendorsPanel.module.scss";

const initialQueryParams = {
  offset: 0,
  limit: 50,
  sort: [
    {
      orderBy: LIST_COMPANIES_ORDER_BY.Name,
      orderDirection: SORT_DIRECTION.ASCENDING
    }
  ]
};

export type ConfirmedVendors = Array<{
  companyId: string; // where one worked
  vendorCompanyId: string; // which one used
  vendorCompanyName: string;
}>;

export interface AddCreateVendorsPanelData {
  companyId?: string;
  rowId?: string;
  workHistoryId?: string;
  vendorIds?: string[];
}

interface AddCreateVendorsPanelProps {
  onVendorsConfirmed: (
    vendors: ConfirmedVendors,
    rowId?: string,
    workHistoryId?: string
  ) => void;
  companyService?: typeof CompanyService;
  notificationService?: ToasterService;
}

export const AddCreateVendorsPanel: React.FC<AddCreateVendorsPanelProps> = ({
  onVendorsConfirmed,
  companyService = CompanyService,
  notificationService = DefaultToasterService
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedVendors, setSelectedVendors] = useState<Company[]>([]);
  const [vendors, setVendors] = useState<Company[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation("addCreateVendorsPanel");
  const dispatch = useDispatch();
  const isAddVendorPanelOpen = useSelector(
    PanelSelector.isPanelOpen(PanelId.AddVendor)
  );
  const panelData = useSelector(
    PanelSelector.panelData<AddCreateVendorsPanelData>(PanelId.AddVendor)
  );

  const getListVendors = useCallback(() => {
    const queryParams = {
      searchTerm,
      ...initialQueryParams
    };
    setIsLoading(true);
    companyService.listCompanies(queryParams).subscribe(
      (res) => {
        setVendors(res.items);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    );
  }, [companyService, searchTerm, notificationService]);

  useEffect(() => {
    if (isAddVendorPanelOpen) {
      getListVendors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, isAddVendorPanelOpen]);

  useEffect(
    function resetSelection() {
      if (isAddVendorPanelOpen) {
        setSelectedVendors([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isAddVendorPanelOpen]
  );

  const onQueryChange = (searchValue: string) => {
    setSearchTerm(searchValue);
  };

  const displayCreateVendorButton = useMemo(
    () =>
      searchTerm &&
      vendors?.every((v) => v.name.toLowerCase() !== searchTerm.toLowerCase()),
    [vendors, searchTerm]
  );

  const handleOnCreateVendor = () => {
    companyService.createCompany({ name: searchTerm }).subscribe(
      (response) => {
        onVendorsConfirmed(
          [
            {
              companyId: panelData.companyId ?? "",
              vendorCompanyId: response.id,
              vendorCompanyName: searchTerm
            }
          ],
          panelData.rowId,
          panelData.workHistoryId
        );
        notificationService.showSuccess(t("vendorCreated"));
        closePanel();
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  const closePanel = () =>
    dispatch(PanelStoreActions.closePanel(PanelId.AddVendor));

  const handleConfirm = () => {
    onVendorsConfirmed(
      selectedVendors.map((vendor) => ({
        companyId: panelData.companyId ?? "",
        vendorCompanyId: vendor.id,
        vendorCompanyName: vendor.name
      })),
      panelData.rowId,
      panelData.workHistoryId
    );
    closePanel();
  };

  const disabledSelectButton = isLoading;

  // Don't show companies that are already selected
  const currentVendorsIds = panelData?.vendorIds ?? [];
  const filteredVendors = vendors?.filter(
    (vendor) => currentVendorsIds.find((id) => id === vendor.id) === undefined
  );

  function toggleVendor(vendor: Company) {
    setSelectedVendors((previousVendors) =>
      xorWith(
        [...previousVendors],
        [vendor],
        (first, second) => first.id === second.id
      )
    );
  }

  return (
    <SlidePanel
      panelId={PanelId.AddVendor}
      title={t("title")}
      width={SIDE_PANEL_SIZE._400}
    >
      <div className={styles.panelBody}>
        <div className={styles.searchInput}>
          <SearchInput
            onQueryChange={onQueryChange}
            isDebounced
            debouncingTime={SEARCH_DEBOUNCE_TIMEOUT_COMMON}
            placeholder={t("searchVendorPlaceholder")}
            hasSearchIcon
          />
        </div>

        <div className={styles.companies}>
          <LoaderOrComponent isLoading={isLoading}>
            <div className={styles.list}>
              {isNonEmptyArray(filteredVendors) &&
                filteredVendors!.map((vendor) => {
                  const { id } = vendor;
                  const isSelected = !!selectedVendors.find(
                    (vendor) => vendor.id === id
                  );
                  return (
                    <VendorCardItem
                      key={id}
                      vendor={vendor}
                      isSelected={isSelected}
                      onClick={() => toggleVendor(vendor)}
                    />
                  );
                })}
              {displayCreateVendorButton && vendors?.length === 0 && (
                <span className={styles.noResults}>{t("noResults")}</span>
              )}
            </div>
          </LoaderOrComponent>
        </div>

        <div className={styles.footer}>
          <HR margin={{ top: 3, bottom: 3 }} />
          <div className={styles.vendorBottom}>
            {displayCreateVendorButton && (
              <Button
                text={t("createVendorButton")}
                onClick={handleOnCreateVendor}
                endIcon="add"
                disabled={isLoading}
              />
            )}
            <Button
              text={t("confirm")}
              onClick={handleConfirm}
              disabled={disabledSelectButton}
            />
          </div>
        </div>
      </div>
    </SlidePanel>
  );
};
