import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  Endorsement,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  UserService
} from "@arbolus-technologies/api";

import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { useHistory } from "react-router";
import { EMPTY } from "rxjs";
import {
  ExpertNotificationDetails,
  ExpertProfileFormDetails
} from "../../helpers/types";

export function useProfileDetails(expertDetails: ExpertNotificationDetails) {
  const { t } = useTranslation("expertEditProfile");
  const history = useHistory();

  const [expertProfile, setExpertProfile] =
    useState<ExpertProfileFormDetails | null>(null);
  const [endorsements, setEndorsements] = useState<Endorsement[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getExpertDetails = useCallback(() => {
    ExpertService.getExpertById({
      expertId: expertDetails.expertId!,
      includeRateCard: true
    }).subscribe(
      (expert: ExpertDetail) => {
        const expertForm = getExpertFormFormatted(expert);
        setEndorsements(expert.endorsements);
        setExpertProfile(expertForm);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        DefaultToasterService.showError(error.message);
        setIsLoading(false);
      }
    );
  }, [expertDetails.expertId]);

  const updateExpertExperience = useCallback(
    (background: string) => {
      const { id, overview, experienceLevel, user } = expertProfile!;

      UserService.updateProfile(user).subscribe(
        (userResponse) => {
          if (userResponse.isDuplicated) {
            DefaultToasterService.showAction(t("profileAlreadyExists"), {
              label: t("profileButton"),
              onClick: () => history.push(EXPERT_PROFILE(userResponse.expertId))
            });
            return EMPTY;
          }
          ExpertService.updateExpertProfile(
            id,
            overview!,
            experienceLevel!,
            ["", ""],
            "",
            background
          ).subscribe(
            (_) => {
              setExpertProfile((prevState) => ({ ...prevState!, background }));
              DefaultToasterService.showSuccess(t("experienceUpdateSuccess"));
            },
            (error: ErrorResponse<CIQError>) => {
              DefaultToasterService.showError(t("unableToSaveDetails"));
            }
          );
        },
        (error: ErrorResponse<CIQError>) => {
          DefaultToasterService.showError(t("unableToSaveDetails"));
        }
      );
    },
    [expertProfile, t]
  );

  return {
    getExpertDetails,
    updateExpertExperience,
    isLoading,
    endorsements,
    expertProfile
  };
}

const getExpertFormFormatted = (
  expert: ExpertDetail
): ExpertProfileFormDetails => {
  const {
    id,
    user: { firstName, lastName, phoneNumber, profileImageUrl, email, title },
    linkedinProfile,
    overview,
    experienceLevel,
    rateCards,
    background,
    user
  } = expert;

  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    profileImageUrl,
    linkedinProfile,
    title,
    background,
    overview,
    experienceLevel,
    hourlyRate: rateCards && rateCards[0]?.price,
    isoCurrencyCode: rateCards && rateCards[0]?.isoCurrencyCode,
    user
  };
};
