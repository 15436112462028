import { Flex } from "antd";
import { UserManager } from "oidc-client-ts";

import { linkedin } from "@arbolus-technologies/theme";

import { LOGIN_CONFIG } from "../../constants/LinkedInAuthConfig";

import styles from "./LinkedInLoginButton.module.scss";

const userManager = new UserManager(LOGIN_CONFIG);

function signIn() {
  userManager.signinRedirect();
}

export function LinkedInLoginButton() {
  return (
    <Flex vertical gap={8} align="center">
      <button onClick={signIn} className={styles.button}>
        <img src={linkedin} alt="linkedin" width={20} height={20} />
      </button>
      <span className={styles.name}>LinkedIn</span>
    </Flex>
  );
}
