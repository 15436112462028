import {
  BasicUser,
  EventUserModel,
  Project,
  ReferralDetail
} from "@arbolus-technologies/api";
import { Card, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { EventDate } from "./EventDate/EventDate";
import { EventGuests } from "./EventGuests";
import { EventNotes } from "./EventNotes";
import { EventTimezone } from "./EventTimezone";
import { EventTitle } from "./EventTitle";

const { Title } = Typography;

interface EventDetailsProps {
  project: Project;
  organizer?: EventUserModel;
  currentBasicUser: BasicUser;
  referral: ReferralDetail | null;
}

export const EventDetails: React.FC<EventDetailsProps> = ({
  project,
  organizer,
  currentBasicUser,
  referral
}) => {
  const { t } = useTranslation("eventForm");

  return (
    <Card>
      <Flex vertical gap={16}>
        <Title level={5}>{t("details")}</Title>
        <Flex vertical>
          <EventTitle project={project} referral={referral} />
          <EventDate project={project} referral={referral} />
          <EventTimezone />
          <EventGuests
            projectId={project.id}
            organizer={organizer}
            currentBasicUser={currentBasicUser}
          />
          <EventNotes />
        </Flex>
      </Flex>
    </Card>
  );
};
