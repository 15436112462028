import { ArbolusGrid } from "@arbolus-technologies/ui/components";
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { useCallback, useMemo } from "react";
import { UserMonitoringListDataSource } from "../UserMonitoringListDataSource";
import { getUserColumns } from "./UserMonitoringListTableColumnDefs";

interface UserMonitoringListTableProps {
  userId: string;
  onGridApiReady?: (api: GridApi) => void;
}

export const UserMonitoringListTable: React.FC<
  UserMonitoringListTableProps
> = ({ userId, onGridApiReady }) => {
  const dataSource = useMemo(
    () => new UserMonitoringListDataSource(userId),
    [userId]
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      const { api } = event;
      onGridApiReady?.(api);
    },
    [onGridApiReady]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: false,
      resizable: true,
      sortable: false,
      menuTabs: []
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo<ColDef[]>(
    () => getUserColumns(userId),
    [userId]
  );

  return (
    <ArbolusGrid
      rowModelType="serverSide"
      serverSideDatasource={dataSource}
      getRowId={({ data }) => data.vendorCompanyId}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      readOnlyEdit
      suppressRowClickSelection
      skipHeaderOnAutoSize
      suppressContextMenu
      suppressMenuHide
      pagination
      paginationAutoPageSize
    />
  );
};
