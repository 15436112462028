import { ICellRendererParams } from "ag-grid-community";
import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import { ExpertProfileSurvey } from "@arbolus-technologies/api";
import {
  PROJECT_EDIT_SURVEY_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";

import styles from "./NameRenderer.module.scss";

type NameRendererComponent = React.FC<
  ICellRendererParams<ExpertProfileSurvey, never, never>
>;

interface NameRendererProps {
  name: string;
  to: LocationDescriptorObject | string;
}

const NameRenderer: React.FC<NameRendererProps> = ({ name, to }) => (
  <Link to={to} className={styles.link} target="_blank">
    {name}
  </Link>
);

export const ProjectName: NameRendererComponent = ({ data }) => (
  <NameRenderer to={PROJECT_ROUTE(data!.projectId)} name={data!.projectName} />
);

export const SurveyTitle: NameRendererComponent = ({ data }) => (
  <NameRenderer
    to={PROJECT_EDIT_SURVEY_ROUTE(data!.projectId, data!.surveyId)}
    name={data!.surveyTitle}
  />
);
