import React from "react";

import { Project, ReferralDetail } from "@arbolus-technologies/api";
import { useLocation } from "react-router-dom";
import { useEventDate } from "../../../../Hooks/Event/useEventDate";
import { EventDatePickers } from "./EventDatePickers";
import { ExpertAvailabilitySlots } from "./ExpertAvailabilitySlots";

interface EventDateProps {
  project: Project;
  referral: ReferralDetail | null;
}

export const EventDate: React.FC<EventDateProps> = ({ project, referral }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startingDate = queryParams.get("startingDate");
  const endingDate = queryParams.get("endingDate");

  const {
    disabledEndDate,
    disabledStartDate,
    handleSetEndingDate,
    handleSetEndingTime,
    handleSetStartingDate,
    handleSetStartingTime,
    handleSelectSlot,
    selectedSlot
  } = useEventDate({ project, startingDate, endingDate });

  const expertHasAvailabilitySlots =
    referral &&
    referral.expertAvailabilitySlots &&
    referral.expertAvailabilitySlots.length > 0;

  return (
    <>
      {expertHasAvailabilitySlots && (
        <ExpertAvailabilitySlots
          project={project}
          referral={referral}
          handleSelectSlot={handleSelectSlot}
          selectedSlot={selectedSlot}
        />
      )}
      <EventDatePickers
        disabledEndDate={disabledEndDate}
        disabledStartDate={disabledStartDate}
        handleSetEndingDate={handleSetEndingDate}
        handleSetEndingTime={handleSetEndingTime}
        handleSetStartingDate={handleSetStartingDate}
        handleSetStartingTime={handleSetStartingTime}
      />
    </>
  );
};
