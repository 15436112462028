import React from "react";
import { Redirect, Switch } from "react-router";

import { CanopyBuilder } from "@arbolus-technologies/features/canopy-builder";
import {
  CanopyClientWrapper,
  CanopyListPage,
  MobileMenu
} from "@arbolus-technologies/features/canopyV2";
import {
  CANOPY_DETAILS_ROUTE,
  CANOPY_MOBILE_MENU
} from "@arbolus-technologies/routes";

import {
  CANOPY_LIST,
  CANOPY_MAIN_PAGE,
  CLIENT_ACTIVE_CANOPY_V2_ROUTES
} from "../../constants/navigation/canopyRoutes";
import { SentryRoute } from "../../sentry";

interface CanopyClientV2RouterProps {
  canopyId: string;
}

export const CanopyClientV2Router: React.FC<CanopyClientV2RouterProps> = ({
  canopyId
}) => (
  <Switch>
    <SentryRoute path={CANOPY_MOBILE_MENU} component={MobileMenu} exact />
    <SentryRoute
      path={CLIENT_ACTIVE_CANOPY_V2_ROUTES}
      component={CanopyClientWrapper}
      exact
    />
    <SentryRoute path={CANOPY_MAIN_PAGE} component={CanopyBuilder} exact />
    <SentryRoute path={CANOPY_LIST} component={CanopyListPage} exact />
    <Redirect to={CANOPY_DETAILS_ROUTE(canopyId)} />
  </Switch>
);
