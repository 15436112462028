import {
  CIQError,
  CompleteSurveyResponse,
  DefaultToasterService,
  ErrorResponse,
  LandingSurvey,
  SurveyExpert,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import { SURVEY_PAYMENT_PAGE } from "@arbolus-technologies/models/survey";
import { DASHBOARD, SURVEYS } from "@arbolus-technologies/routes";
import { Loader } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router";
import { GiftCardThanksScreen } from "../../Components/GiftCardThanksScreen/GiftCardThanksScreen";
import { PaymentMethodSelection } from "../../Components/PaymentMethodSelection/PaymentMethodSelection";
import styles from "./SurveyPaymentMethodPage.module.scss";

interface SurveyPaymentMethodPageProps {
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const SurveyPaymentMethodPage: React.FC<
  SurveyPaymentMethodPageProps
> = ({
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("surveyPaymentMethodPage");
  useDocumentTitle("surveyPaymentMethodPage", "title");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const surveyExpertId = searchParams.get("surveyExpertId");

  const [survey, setSurvey] = useState<LandingSurvey | null>(null);
  const [surveyExpert, setSurveyExpert] = useState<SurveyExpert | null>(null);
  const [screenSelection, setScreenSelection] = useState<SURVEY_PAYMENT_PAGE>(
    SURVEY_PAYMENT_PAGE.METHOD_SELECTION
  );

  const handleScreenSelection = (screen: SURVEY_PAYMENT_PAGE) => {
    setScreenSelection(screen);
  };

  const getSurvey = (surveyId: string) => {
    surveyService.getSurveyPublic(surveyId).subscribe(
      (surveyResponse: LandingSurvey) => {
        setSurvey(surveyResponse);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  const getSurveyExpert = (surveyExpertId: string) => {
    surveyService.getSurveyExpert(surveyExpertId).subscribe(
      (surveyExpertResponse: SurveyExpert) => {
        setSurveyExpert(surveyExpertResponse);
        getSurvey(surveyExpertResponse.surveyId);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  const completeSurvey = (surveyExpertId: string) => {
    surveyService.completeSurveyExpert(surveyExpertId).subscribe(
      (completeSurveyResponse: CompleteSurveyResponse) => {
        getSurveyExpert(completeSurveyResponse.surveyExpertId);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  useEffect(() => {
    if (surveyExpertId) {
      completeSurvey(surveyExpertId);
    }
  }, [surveyExpertId]);

  if (!surveyExpertId) {
    notificationService.showInfo(t("surveyNotFound"));
    return <Redirect to={{ pathname: DASHBOARD }} />;
  }

  if (survey === null || surveyExpert === null) {
    return <Loader isFull />;
  }

  const paymentMethodSelectionStatus = {
    [SURVEY_PAYMENT_PAGE.METHOD_SELECTION]: (
      <PaymentMethodSelection
        survey={survey}
        surveyExpert={surveyExpert}
        handleScreenSelection={handleScreenSelection}
      />
    ),
    [SURVEY_PAYMENT_PAGE.GIFT_CARD_SELECTED]: <GiftCardThanksScreen />
  };

  return (
    <div className={styles.container}>
      <AntDHeader title={survey.title} backLink={SURVEYS} />
      {paymentMethodSelectionStatus[screenSelection]}
    </div>
  );
};
