import {
  SurveyCompliancePage,
  SurveyFeedbackPage,
  SurveyLandingNotQualifiedPage,
  SurveyLandingPage,
  SurveyLandingQualifiedPage
} from "@arbolus-technologies/features/surveys";
import {
  SURVEY,
  SURVEY_COMPLIANCE,
  SURVEY_FEEDBACK,
  SURVEY_LANDING_NOT_QUALIFIED,
  SURVEY_LANDING_QUALIFIED
} from "@arbolus-technologies/routes";

import { SentryRoute } from "../../../sentry";

export const SharedRouter: React.FC = () => {
  return (
    <>
      <SentryRoute exact path={SURVEY} component={SurveyLandingPage} />
      <SentryRoute
        exact
        path={SURVEY_COMPLIANCE}
        component={SurveyCompliancePage}
      />
      <SentryRoute
        exact
        path={SURVEY_FEEDBACK}
        component={SurveyFeedbackPage}
      />
      <SentryRoute
        exact
        path={SURVEY_LANDING_QUALIFIED}
        component={SurveyLandingQualifiedPage}
      />
      <SentryRoute
        exact
        path={SURVEY_LANDING_NOT_QUALIFIED}
        component={SurveyLandingNotQualifiedPage}
      />
    </>
  );
};
