import { Handlers, createReducer } from "reduxsauce";

import {
  ANSWER_TYPE,
  CANOPY_STATUS,
  VIDEO_MODE_ENUM
} from "@arbolus-technologies/models/canopy";

import {
  ADD_EXPERT_CANOPY_AGREEMENT_FAILURE,
  ADD_EXPERT_CANOPY_AGREEMENT_SUCCESS,
  ADD_RETRY,
  AddRetryAction,
  CANOPY_RESET_CAMERA,
  DELETE_AND_UPLOAD_VIDEO,
  ENABLE_EXPERT_QUESTIONS,
  EnableExpertQuestionsAction,
  GET_CANOPY_ANSWER_DATA,
  GET_CANOPY_ANSWER_DATA_FAILURE,
  GET_CANOPY_ANSWER_DATA_SUCCESS,
  GET_CANOPY_EXPERT,
  GET_CANOPY_EXPERT_FAILURE,
  GET_CANOPY_EXPERT_QUESTIONS,
  GET_CANOPY_EXPERT_QUESTIONS_FAILURE,
  GET_CANOPY_EXPERT_QUESTIONS_SUCCESS,
  GET_CANOPY_EXPERT_SUCCESS,
  GetCanopyAnswerDataSuccessAction,
  GetCanopyExpertQuestionsSuccessAction,
  GetCanopyExpertSuccessAction,
  MARK_QUESTION_AS_ANSWERED,
  MarkQuestionAsAnsweredAction,
  PLAYING_VIDEO,
  PlayingVideoAction,
  RECORDING_VIDEO,
  RECORD_QUESTION,
  RESET_CANOPY_ANSWER_DATA,
  RESET_MOBILE,
  RETAKE_VIDEO,
  RecordQuestionAction,
  RecordingVideoAction,
  SEND_ANSWERS_SUCCESS,
  SET_CANOPY_VIDEO,
  SHOW_RIGHT_COMPONENT,
  SetCanopyVideoAction,
  ShowRightComponentAction,
  UPLOAD_CANOPY_VIDEO,
  UPLOAD_CANOPY_VIDEO_ERROR,
  UPLOAD_CANOPY_VIDEO_SUCCESS,
  UploadCanopyVideoErrorAction,
  UploadCanopyVideoSuccessAction,
  VIDEO_MODE,
  VideoModeAction
} from "./actions/CanopyExpertActionTypes";
import { CanopyExpertReducerState } from "./models/definitions";

export const initialState: CanopyExpertReducerState = {
  canopyAnswerState: "details",
  forceResetCam: false,
  isVideoRecording: false,
  isVideoPlaying: false,
  isVideoAlreadyUploaded: false,
  canopyVideo: {
    videoURL: null,
    videoBlob: null,
    time: 0,
    supportedVideoType: null
  },
  questionToRecord: null,
  recordQuestion: false,
  isVideoSaving: false,
  isVideoUploaded: false,
  canopyAnswerLoading: false,
  canopyAnswerData: null,
  videoMode: null,
  questionIdRecentlyUploaded: null,
  isSubmitAnswersComplete: false,
  addRetry: 0,
  isCanopyLoading: true,
  loadingCanopyQuestions: false,
  canopy: {
    id: "",
    status: CANOPY_STATUS.DRAFT,
    title: "",
    overview: "",
    links: [],
    projectId: "",
    hasComplianceCheck: false,
    isCanopyAgreementAccepted: false,
    isAutomaticallyCreated: false,
    questionsCount: 0,
    canopyExpertStatus: null,
    targetCompanyId: null
  },
  canopyQuestions: [],
  showRightComponent: false,
  hasAcceptedAgreement: false,
  isExpertsQuestionsEnabled: false
};

// Get canopy Expert
const handleGetCanopyExpert = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  isCanopyLoading: true
});

const handleGetCanopyExpertSuccess = (
  state = initialState,
  { payload }: GetCanopyExpertSuccessAction
): CanopyExpertReducerState => ({
  ...state,
  canopy: payload.canopyData,
  isCanopyLoading: false
});

const handleGetCanopyExpertFailure = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  isCanopyLoading: false
});

const getCanopyExpertQuestions = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  loadingCanopyQuestions: true
});

const getCanopyExpertQuestionsSuccess = (
  state = initialState,
  { payload }: GetCanopyExpertQuestionsSuccessAction
): CanopyExpertReducerState => ({
  ...state,
  loadingCanopyQuestions: false,
  canopyQuestions: payload.canopyQuestions
});

const getCanopyExpertQuestionsFailure = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  loadingCanopyQuestions: false
});

const addRetry = (
  state = initialState,
  { payload }: AddRetryAction
): CanopyExpertReducerState => ({
  ...state,
  addRetry: payload.addRetry
});

// Reset Canopy Camera
const resetCanopyCamera = (state = initialState): CanopyExpertReducerState => ({
  ...state,
  forceResetCam: !state.forceResetCam,
  canopyVideo: {
    ...initialState.canopyVideo
  }
});

// RECORD CANOPY QUESTION
const handleRecordQuestion = (
  state = initialState,
  { payload }: RecordQuestionAction
): CanopyExpertReducerState => ({
  ...state,
  questionToRecord: payload.question,
  recordQuestion: true,
  canopyAnswerData: null
});

const setCanopyVideo = (
  state = initialState,
  { payload }: SetCanopyVideoAction
): CanopyExpertReducerState => ({
  ...state,
  canopyVideo: {
    videoURL: payload.videoURL,
    videoBlob: payload.videoBlob,
    time: payload.time,
    supportedVideoType: payload.supportedVideoType
  },
  isVideoSaving: payload.isVideoSaving,
  isVideoUploaded: payload.isVideoUploaded
});

const deleteAndUploadCanopyVideo = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  isVideoSaving: true
});

const uploadCanopyVideo = (state = initialState): CanopyExpertReducerState => ({
  ...state,
  isVideoSaving: true
});

const uploadCanopyVideoSuccess = (
  state = initialState,
  { payload }: UploadCanopyVideoSuccessAction
): CanopyExpertReducerState => {
  const filteredDataSource = state.canopyQuestions.filter((question) => {
    if (question.id === payload.questionId) {
      question.isAnswered = true;
    }

    return question;
  });
  return {
    ...state,
    isVideoSaving: false,
    isVideoUploaded: true,
    questionIdRecentlyUploaded: payload.questionId,
    canopyQuestions: filteredDataSource,
    canopyAnswerData: {
      expertId: state.canopyAnswerData?.expertId!,
      id: payload.answerId,
      type: ANSWER_TYPE.VIDEO,
      questionId: state.canopyAnswerData?.questionId!,
      questionText: state.canopyAnswerData?.questionText!,
      details: state.canopyAnswerData?.details!
    }
  };
};

const uploadCanopyVideoError = (
  state = initialState,
  { payload }: UploadCanopyVideoErrorAction
): CanopyExpertReducerState => ({
  ...state,
  isVideoSaving: false,
  isVideoUploaded: false
});

const setVideoRecording = (
  state = initialState,
  { payload }: RecordingVideoAction
): CanopyExpertReducerState => ({
  ...state,
  isVideoRecording: payload.isVideoRecording
});

const setVideoPlaying = (
  state = initialState,
  { payload }: PlayingVideoAction
): CanopyExpertReducerState => ({
  ...state,
  isVideoPlaying: payload.isVideoPlaying
});

const getCanopyAnswerData = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  canopyAnswerLoading: true
});

const getCanopyAnswerDataSuccess = (
  state = initialState,
  { payload }: GetCanopyAnswerDataSuccessAction
): CanopyExpertReducerState => ({
  ...state,
  canopyAnswerLoading: false,
  canopyAnswerData: payload.canopyAnswerData,
  isVideoAlreadyUploaded: true,
  questionToRecord: {
    id: payload.canopyAnswerData.questionId,
    text: payload.canopyAnswerData.questionText,
    details: payload.canopyAnswerData.details,
    order: 0,
    type: ANSWER_TYPE.VIDEO,
    canopyId: state.canopy.id,
    nextQuestionId: null,
    previousQuestionId: null
  },
  canopyVideo: {
    videoBlob: null,
    videoURL: null,
    time: state.canopyVideo.time,
    supportedVideoType: null
  },
  isVideoUploaded: true
});

const getCanopyAnswerDataFailure = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  canopyAnswerLoading: false
});

const retakeVideo = (state = initialState): CanopyExpertReducerState => ({
  ...state,
  canopyAnswerData: {
    questionId: state.canopyAnswerData?.questionId!,
    expertId: state.canopyAnswerData?.expertId!,
    questionText: state.canopyAnswerData?.questionText!,
    details: state.canopyAnswerData?.details!,
    type: ANSWER_TYPE.VIDEO,
    id: state.canopyAnswerData?.id!
  },
  canopyVideo: {
    ...initialState.canopyVideo
  },
  videoMode: VIDEO_MODE_ENUM.RECORD
});

const changeVideoMode = (
  state = initialState,
  { payload }: VideoModeAction
): CanopyExpertReducerState => ({
  ...state,
  videoMode: payload.mode
});

const sendCanopyAnswersSuccess = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  isSubmitAnswersComplete: true
});

const resetMobile = (state = initialState): CanopyExpertReducerState => ({
  ...state,
  canopyAnswerData: null,
  canopyVideo: {
    ...initialState.canopyVideo
  },
  videoMode: VIDEO_MODE_ENUM.RECORD,
  questionToRecord: null,
  questionIdRecentlyUploaded: null
});

const resetCanopyAnswerData = (): CanopyExpertReducerState => initialState;

const showRightComponent = (
  state = initialState,
  { payload }: ShowRightComponentAction
): CanopyExpertReducerState => ({
  ...state,
  showRightComponent: payload.showRightComponent
});

const handleGetExpertCanopyAgreementSuccess = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  hasAcceptedAgreement: true
});

const handleGetExpertCanopyAgreementFailure = (
  state = initialState
): CanopyExpertReducerState => ({
  ...state,
  hasAcceptedAgreement: false
});

const enableExpertQuestions = (
  state = initialState,
  { payload }: EnableExpertQuestionsAction
): CanopyExpertReducerState => ({
  ...state,
  isExpertsQuestionsEnabled: payload.enabled
});

const markQuestionAsAnswered = (
  state = initialState,
  { payload }: MarkQuestionAsAnsweredAction
): CanopyExpertReducerState => ({
  ...state,
  canopyQuestions: state.canopyQuestions.map((question) => {
    if (question.id === payload.questionId) {
      question.isAnswered = true;
    }

    return question;
  })
});

// Current `Handlers` definition not good enough for proper typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<CanopyExpertReducerState, any> = {
  [GET_CANOPY_EXPERT]: handleGetCanopyExpert,
  [GET_CANOPY_EXPERT_SUCCESS]: handleGetCanopyExpertSuccess,
  [GET_CANOPY_EXPERT_FAILURE]: handleGetCanopyExpertFailure,
  [GET_CANOPY_EXPERT_QUESTIONS]: getCanopyExpertQuestions,
  [GET_CANOPY_EXPERT_QUESTIONS_SUCCESS]: getCanopyExpertQuestionsSuccess,
  [GET_CANOPY_EXPERT_QUESTIONS_FAILURE]: getCanopyExpertQuestionsFailure,
  [CANOPY_RESET_CAMERA]: resetCanopyCamera,
  [RECORDING_VIDEO]: setVideoRecording,
  [SET_CANOPY_VIDEO]: setCanopyVideo,
  [RECORD_QUESTION]: handleRecordQuestion,
  [DELETE_AND_UPLOAD_VIDEO]: deleteAndUploadCanopyVideo,
  [UPLOAD_CANOPY_VIDEO]: uploadCanopyVideo,
  [UPLOAD_CANOPY_VIDEO_SUCCESS]: uploadCanopyVideoSuccess,
  [UPLOAD_CANOPY_VIDEO_ERROR]: uploadCanopyVideoError,
  [GET_CANOPY_ANSWER_DATA]: getCanopyAnswerData,
  [GET_CANOPY_ANSWER_DATA_SUCCESS]: getCanopyAnswerDataSuccess,
  [GET_CANOPY_ANSWER_DATA_FAILURE]: getCanopyAnswerDataFailure,
  [SEND_ANSWERS_SUCCESS]: sendCanopyAnswersSuccess,
  [RETAKE_VIDEO]: retakeVideo,
  [PLAYING_VIDEO]: setVideoPlaying,
  [VIDEO_MODE]: changeVideoMode,
  [RESET_MOBILE]: resetMobile,
  [ADD_RETRY]: addRetry,
  [RESET_CANOPY_ANSWER_DATA]: resetCanopyAnswerData,
  [SHOW_RIGHT_COMPONENT]: showRightComponent,
  [ADD_EXPERT_CANOPY_AGREEMENT_SUCCESS]: handleGetExpertCanopyAgreementSuccess,
  [ADD_EXPERT_CANOPY_AGREEMENT_FAILURE]: handleGetExpertCanopyAgreementFailure,
  [ENABLE_EXPERT_QUESTIONS]: enableExpertQuestions,
  [MARK_QUESTION_AS_ANSWERED]: markQuestionAsAnswered
};

export const CanopyExpertReducer = createReducer(initialState, handlers);
