import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  UserRegisterResponse,
  UserService
} from "@arbolus-technologies/api";

import { RECAPTCHA_ACTIONS } from "../constants/ReCaptcha";

export function useRegister(registerParams: Record<string, any> = {}) {
  const { t } = useTranslation("register");

  const captcha = useGoogleReCaptcha();

  const [registrationData, setRegistrationData] =
    useState<UserRegisterResponse>();

  useEffect(() => {
    async function callRegister() {
      if (!captcha.executeRecaptcha) return;

      const recaptchaToken = await captcha.executeRecaptcha(
        RECAPTCHA_ACTIONS.CREATE_ACCOUNT
      );

      const response = await UserService.userRegistration({
        ...registerParams,
        recaptchaToken
      }).toPromise();
      setRegistrationData(response);
    }

    callRegister().catch((error) => {
      console.error({ error });
      DefaultToasterService.showError(t("initRegisterError"));
    });
  }, [captcha.executeRecaptcha]);

  return registrationData;
}
