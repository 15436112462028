import { Flex, Typography } from "antd";
import React from "react";
import { Trans } from "react-i18next";

import {
  PLATFORM_AGREEMENT,
  PRIVACY_POLICY
} from "@arbolus-technologies/features/common";

const { Text } = Typography;

export const PrivacyAndTerms: React.FC = () => {
  const privacyPolicyText = () => (
    <Text type="secondary">
      <Trans i18nKey="privacyPolicyText">
        By participating in this survey, you agree to the collection and
        processing of your data in line with our{" "}
        <a
          href={PRIVACY_POLICY}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          Privacy Policy
        </a>
        {""}.
      </Trans>
    </Text>
  );

  const termsOfUseText = () => (
    <Text type="secondary">
      <Trans i18nKey="termsOfUseText">
        *We offer several payment options including bank transfers, PayPal or
        gift cards - Payment time may vary based on your choice. In order for us
        to process payment a sign up to our platform and agreement to our{" "}
        <a
          href={PLATFORM_AGREEMENT}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          Terms of use
        </a>{" "}
        is required.
      </Trans>
    </Text>
  );

  return (
    <Flex vertical gap={8}>
      <Text type="secondary">{privacyPolicyText()}</Text>
      <Text type="secondary">{termsOfUseText()}</Text>
    </Flex>
  );
};
