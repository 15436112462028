import React, { ErrorInfo } from "react";
import { Subscription } from "rxjs";

import { ERROR_TYPE } from "@arbolus-technologies/utils";

import { MetaService, UtilsService } from "../../../../services";

interface ErrorBoundaryState {
  hasError: boolean;
  crashId: string;
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  ErrorBoundaryState
> {
  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true, crashId: UtilsService.generateUUID() };
  }

  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = {
      hasError: false,
      crashId: ""
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { crashId } = this.state;

    if (process.env.NODE_ENV === "production") {
      const errorData = UtilsService.getCrashAnalyticsData({
        crashId,
        errorMessage: error.message,
        errorType: ERROR_TYPE.APP_CRASH
      });

      this.appCrashSubscription =
        MetaService.sendAppCrashData(errorData).subscribe();
    } else {
      console.error(error);
    }
  }

  componentWillUnmount(): void {
    this.appCrashSubscription?.unsubscribe();
  }

  private appCrashSubscription?: Subscription;

  render(): JSX.Element {
    const { hasError, crashId } = this.state;
    const { children } = this.props;

    return (
      <>
        {hasError ? (
          <div className="error-boundary-container">
            <div className="error-boundary-message">
              <h1>Something went wrong</h1>
              <p>
                Seems like the web app is not compatible with your browser or
                crashed
              </p>
              <div className="error-cases">
                <ul>
                  <li>Make sure you are connected to internet</li>
                  <li>Update your browser to latest version</li>
                </ul>
              </div>
              <p className="error-boundary-crash-id">{crashId}</p>
            </div>
          </div>
        ) : (
          children
        )}
      </>
    );
  }
}

export default ErrorBoundary;
