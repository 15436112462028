import clsx from "clsx";
import React from "react";

import { AntDHeader } from "../AntDLayout/AntDHeader/AntDHeader";

import styles from "./MainPageLayout.module.scss";

interface MainPageLayoutProps {
  title?: string;
  children: React.ReactNode;
  stickyHeader?: boolean;
  rightButtonContainer?: React.ReactNode;
  backLink?: string;
}

export const MainPageLayout: React.FC<MainPageLayoutProps> = ({
  title,
  children,
  stickyHeader = false,
  rightButtonContainer,
  backLink
}) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <AntDHeader
          stickyHeader={stickyHeader}
          title={title ?? ""}
          rightContainer={rightButtonContainer}
          backLink={backLink}
        />
        <div
          className={clsx(
            styles.childrenContainer,
            stickyHeader && styles.fullHeight
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
