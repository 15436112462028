import moment from "moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  DefaultReferralsService,
  DefaultToasterService,
  IReferralsService,
  ToasterService
} from "@arbolus-technologies/api";
import { useModalService } from "@arbolus-technologies/features/common";
import { Referral, Slot } from "@arbolus-technologies/models/common";

import { PROJECT_CREATE_EVENT_ROUTE } from "@arbolus-technologies/routes";
import { formatDateToUTC } from "@arbolus-technologies/utils";
import { SlotRow } from "../../Modules/ReferralsTable/scheduling/SlotRow";

interface BookingProps {
  slot?: Slot | SlotRow;
  timezone: string;
  referral: Referral;
  project: {
    id: string;
    name: string;
  };
  render: (
    timezone: string,
    onClick: () => void,
    slot?: Slot | SlotRow
  ) => React.ReactNode;
  referralsService?: IReferralsService;
  toasterService?: ToasterService;
}

export const Booking: React.FC<BookingProps> = ({
  slot,
  timezone,
  project,
  referral,
  render,
  referralsService = DefaultReferralsService,
  toasterService = DefaultToasterService
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "actions" });
  const { push } = useHistory();
  const modalService = useModalService();

  const goToEventPage = useCallback(() => {
    push(
      PROJECT_CREATE_EVENT_ROUTE(
        project.id,
        `referralId=${referral.id}&startingDate=${formatDateToUTC(slot?.startTime)}&endingDate=${formatDateToUTC(slot?.endTime)}`
      )
    );
  }, [referral, project, push, slot]);

  const createEvent = React.useCallback(() => {
    if (referral.isBookable) {
      goToEventPage();
      return;
    }

    if (!referral.onlyMissingReview) {
      toasterService.showError(t("approveAndBookError"));
      return;
    }

    modalService.openMainModal({
      title: t("approveAndBookTitle"),
      subtitle: t("approveAndBookSubtitle"),
      type: "confirmation",
      confirmText: t("approveAndBookConfirmText"),
      onConfirm: () => {
        referralsService
          .approve(project.id, referral.expert.expertId)
          .subscribe({
            next: () => goToEventPage(),
            error: toasterService.showApiErrors
          });
      }
    });
  }, [
    goToEventPage,
    modalService,
    referral,
    toasterService,
    project,
    referralsService,
    t
  ]);

  return (
    <div className="cursor-pointer">{render(timezone, createEvent, slot)}</div>
  );
};

function getDate(date: Date, timezone: string): Date {
  return new Date(moment.utc(date).tz(timezone).format("YYYY-MM-DD HH:mm:ss"));
}
