import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ICellRendererParams } from "ag-grid-community";
import { Avatar, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./LogoCompanyNameRenderer.module.scss";

interface LogoCompanyNameRendererProps
  extends Partial<
    ICellRendererParams<{
      vendorCompanyLogoUrl?: string | null;
      vendorCompanyName?: string;
      customerCompanyLogoUrl?: string | null;
      customerCompanyName?: string;
      lastUpdate?: string | null;
    }>
  > {}

const hadUpdatesInLastFiveDays = (dateStr: string) => {
  if (!dateStr) {
    return false;
  }
  const [day, month, year] = dateStr.split("-").map(Number);
  const inputDate = new Date(year, month - 1, day);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const thresholdDate = new Date();
  thresholdDate.setDate(today.getDate() - 5);

  // Compare dates
  return inputDate >= thresholdDate;
};

export function LogoCompanyNameRenderer({
  data
}: LogoCompanyNameRendererProps) {
  const { t } = useTranslation("userMonitoringListPage");

  const companyLogoUrl =
    data?.vendorCompanyLogoUrl || data?.customerCompanyLogoUrl;
  const companyName = data?.vendorCompanyName || data?.customerCompanyName;

  const hasUpdates =
    data?.lastUpdate && hadUpdatesInLastFiveDays(data?.lastUpdate);

  if (!companyName)
    return <Typography.Text type="secondary">-</Typography.Text>;

  return (
    <div className={styles.logoCompanyNameContainer}>
      <div className={styles.logoCompanyName}>
        <Avatar size="small" src={companyLogoUrl}>
          {companyLogoUrl ?? <AntDIcon name="business" />}
        </Avatar>
        <Typography.Text className={styles.companyName}>
          {companyName}
        </Typography.Text>
      </div>
      {hasUpdates && (
        <Tag className={styles.tag} color="blue">
          {t("new")}
        </Tag>
      )}
    </div>
  );
}
