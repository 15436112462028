import { UserManager } from "oidc-client-ts";

import {
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import {
  CANOPY_DETAILS_PAGE_ROUTE,
  PublicRoutes,
  SURVEY_PAYMENT_METHOD
} from "@arbolus-technologies/routes";
import {
  BASE_URL,
  getQueryParams,
  parseParams
} from "@arbolus-technologies/utils";

import { OPEN_ID_CONFIG, REDIRECT_URI } from "../constants/LinkedInAuthConfig";

const userManager = new UserManager(OPEN_ID_CONFIG);

export function registerWithLi() {
  return redirectToLinkedIn(
    `${BASE_URL}${PublicRoutes.REGISTER_WITH_LI}?redirect=`
  );
}

export function loginWithLi() {
  return redirectToLinkedIn(BASE_URL);
}

function redirectToLinkedIn(baseURL: string) {
  const internalRedirect = getPostLinkedInRedirect(getQueryParams(), baseURL);

  if (internalRedirect?.includes("surveyExpertId")) {
    trackEvent(MixPanelEventNames.SurveySignUp, {
      action: MixPanelActions.Redirect
    });
  }

  userManager.signinRedirect({
    redirect_uri: internalRedirect
      ? `${REDIRECT_URI}?internalRedirect=${internalRedirect}`
      : REDIRECT_URI
  });
}

function getPostLinkedInRedirect(
  { canopyId = "", redirect = "", search = "" } = {},
  baseURL: string
) {
  if (canopyId) {
    const canopyRedirect = CANOPY_DETAILS_PAGE_ROUTE(canopyId);
    return `${baseURL}${canopyRedirect}`;
  }

  if (search) {
    // Yes, there are query params inside query params.
    const { surveyExpertId } = parseParams(search);
    if (surveyExpertId) {
      const surveyRedirect = `${SURVEY_PAYMENT_METHOD}?surveyExpertId=${surveyExpertId}`;
      return `${baseURL}${surveyRedirect}`;
    }
  }

  // Used only when more specific ones are not present
  // expecting non-empty string, a single char like "/" is also invalid
  if (redirect?.length > 1) {
    return `${baseURL}${redirect}`;
  }

  return undefined;
}
