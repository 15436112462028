import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./Header.module.scss";

interface HeaderProps {
  title?: string;
  subtitle?: string;
  text?: string;
  shareOnClick?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  text,
  shareOnClick,
  title,
  subtitle
}) => (
  <div className={styles.container}>
    <div className={styles.titleContainer}>
      {title && <span className={styles.title}>{title}</span>}
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      {text && <span className={styles.text}>{text}</span>}
    </div>
    {shareOnClick && (
      <div className={styles.buttonsContainer}>
        <Icon
          fontSize="24px"
          name="share"
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          onClick={shareOnClick}
        />
      </div>
    )}
  </div>
);
