import { Card, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ToasterService,
  UserProfile,
  UserService
} from "@arbolus-technologies/api";

import {
  ExpertNotificationDetails,
  NotificationSettingsItems,
  NotificationSubscription
} from "../../helpers/types";
import { ExpertDeactivation } from "../ExpertDeactivation/ExpertDeactivation";
import { SkeletonLoader } from "../SkeletonLoader/SkeletonLoader";
import { NotificationBanner } from "./NotificationBanner/NotificationBanner";
import { NotificationSettingsForm } from "./NotificationSettingsForm";

import styles from "./NotificationSettings.module.scss";

const { Title, Text } = Typography;

interface UserNotificationSettingsProps {
  logOut: () => void;
  expertDetails?: ExpertNotificationDetails;
  notificationService?: ToasterService;
}

export const NotificationSettings: React.FC<UserNotificationSettingsProps> = ({
  expertDetails,
  logOut,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettingsItems[]>();
  const { t } = useTranslation("notificationSettings");

  useEffect(function getNotificationSettings() {
    UserService.getUserProfile().subscribe({
      next: ({ defaultNotificationSettings }: UserProfile) => {
        const notificationSettingsFormatted =
          getNotificationSettingsFormFormatted(defaultNotificationSettings);

        setNotificationSettings(notificationSettingsFormatted);
        setIsLoading(false);
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    });
  }, []);

  const handleNotificationSettingsState = (
    notificationSettings: NotificationSettingsItems[]
  ) => {
    setNotificationSettings(notificationSettings);
  };

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <>
      <Card>
        <NotificationBanner />
        <div className={styles.titleWrapper}>
          <Title level={5}>{t("notifications")}</Title>
          <Text>{t("subtitle")}</Text>
        </div>
        {notificationSettings && (
          <NotificationSettingsForm
            isLoading={isLoading}
            notificationSettings={notificationSettings}
            setNotificationSettings={handleNotificationSettingsState}
          />
        )}
      </Card>

      <Card>
        {expertDetails && (
          <ExpertDeactivation
            expertId={expertDetails.expertId!}
            expertName={expertDetails.expertName}
            isActive={expertDetails.isActive!}
            logOut={logOut}
          />
        )}
      </Card>
    </>
  );
};

function getNotificationSettingsFormFormatted(
  defaultNotificationSettings: NotificationSubscription
) {
  return Object.keys(defaultNotificationSettings).map((key) => {
    const itemName = key.charAt(0).toLowerCase() + key.slice(1);
    return {
      title: itemName,
      subTitle: `${itemName}Description`,
      value: defaultNotificationSettings[key as keyof NotificationSubscription]
    };
  });
}
