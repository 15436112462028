import { OutputSelector, Selector, createSelector } from "reselect";

import {
  CanopyExpertBaseAnswer,
  CanopyQuestion,
  ExpertCanopy
} from "@arbolus-technologies/api";
import {
  CanopyAnswerState,
  VIDEO_MODE_ENUM
} from "@arbolus-technologies/models/canopy";

import {
  CanopyExpertAppState,
  CanopyExpertReducerState
} from "../models/definitions";

const canopyStateSelector: Selector<
  CanopyExpertAppState,
  CanopyExpertReducerState
> = (state: CanopyExpertAppState): CanopyExpertReducerState =>
  state.canopyExpert;

// Canopy selected
const canopySelected = (): OutputSelector<
  CanopyExpertAppState,
  ExpertCanopy | null,
  (res: CanopyExpertReducerState) => ExpertCanopy | null
> =>
  createSelector<
    CanopyExpertAppState,
    CanopyExpertReducerState,
    ExpertCanopy | null
  >(canopyStateSelector, (canopyReducerState) => canopyReducerState.canopy);

// Expert Canopy
const canopySelector = (): OutputSelector<
  CanopyExpertAppState,
  ExpertCanopy,
  (res: CanopyExpertReducerState) => ExpertCanopy
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, ExpertCanopy>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopy
  );

const canopyLoadingSelector = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isCanopyLoading
  );

// Canopy questions list
const questionsSelector = (): OutputSelector<
  CanopyExpertAppState,
  CanopyQuestion[],
  (res: CanopyExpertReducerState) => CanopyQuestion[]
> =>
  createSelector<
    CanopyExpertAppState,
    CanopyExpertReducerState,
    CanopyQuestion[]
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyQuestions
  );

// get canopy questions loading
const canopyListLoadingSelector = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.loadingCanopyQuestions
  );

const canopyAnswerState = (): OutputSelector<
  CanopyExpertAppState,
  CanopyAnswerState,
  (res: CanopyExpertReducerState) => CanopyAnswerState
> =>
  createSelector<
    CanopyExpertAppState,
    CanopyExpertReducerState,
    CanopyAnswerState
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyAnswerState
  );

const forceResetCam = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.forceResetCam
  );

const canopyVideoActive = (): OutputSelector<
  CanopyExpertAppState,
  { videoURL: string | null; videoBlob: Blob | null },
  (res: CanopyExpertReducerState) => {
    videoURL: string | null;
    videoBlob: Blob | null;
  }
> =>
  createSelector<
    CanopyExpertAppState,
    CanopyExpertReducerState,
    { videoURL: string | null; videoBlob: Blob | null }
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyVideo
  );

const canopyVideoURL = (): OutputSelector<
  CanopyExpertAppState,
  string | null,
  (res: CanopyExpertReducerState) => string | null
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, string | null>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyVideo.videoURL
  );

// is Recording
const isVideoRecording = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isVideoRecording
  );

// Record Canopy question
const questionToRecordSelector = (): OutputSelector<
  CanopyExpertAppState,
  CanopyQuestion | null,
  (res: CanopyExpertReducerState) => CanopyQuestion | null
> =>
  createSelector<
    CanopyExpertAppState,
    CanopyExpertReducerState,
    CanopyQuestion | null
  >(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.questionToRecord
  );

const canopyVideoBlob = (): OutputSelector<
  CanopyExpertAppState,
  Blob | null,
  (res: CanopyExpertReducerState) => Blob | null
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, Blob | null>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyVideo.videoBlob
  );

const supportedVideoType = (): OutputSelector<
  CanopyExpertAppState,
  string | null,
  (res: CanopyExpertReducerState) => string | null
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, string | null>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyVideo.supportedVideoType
  );

// Canopy create loading
const isCanopyVideoSaving = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isVideoSaving
  );

// Canopy video uploaded
const isVideoUploaded = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isVideoUploaded
  );

// Canopy Answer State
const canopyAnswerData = <T extends CanopyExpertBaseAnswer>(): OutputSelector<
  CanopyExpertAppState,
  T | null,
  (res: CanopyExpertReducerState) => T | null
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, T | null>(
    [canopyStateSelector],
    (canopyReducerState) => canopyReducerState.canopyAnswerData as T
  );

const videoTime = (): OutputSelector<
  CanopyExpertAppState,
  number,
  (res: CanopyExpertReducerState) => number
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, number>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyVideo.time
  );

const isVideoPlaying = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isVideoPlaying
  );

const isCanopyAnswerLoading = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopyAnswerLoading
  );

const videoMode = (): OutputSelector<
  CanopyExpertAppState,
  VIDEO_MODE_ENUM | null,
  (res: CanopyExpertReducerState) => VIDEO_MODE_ENUM | null
> =>
  createSelector<
    CanopyExpertAppState,
    CanopyExpertReducerState,
    VIDEO_MODE_ENUM | null
  >(canopyStateSelector, (canopyReducerState) => canopyReducerState.videoMode);

const isVideoAlreadyUploaded = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isVideoAlreadyUploaded
  );

// questionIdRecentlyUploaded
const questionIdRecentlyUploaded = (): OutputSelector<
  CanopyExpertAppState,
  string | null,
  (res: CanopyExpertReducerState) => string | null
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, string | null>(
    canopyStateSelector,
    (CanopyExpertReducerState) =>
      CanopyExpertReducerState.questionIdRecentlyUploaded
  );

// Send answers has been completed successfully
const isSubmitAnswersComplete = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isSubmitAnswersComplete
  );

const questionToRecordInfo = (): OutputSelector<
  CanopyExpertAppState,
  { title: string; details: string } | undefined,
  (
    res: CanopyExpertReducerState
  ) => { title: string; details: string } | undefined
> =>
  createSelector<
    CanopyExpertAppState,
    CanopyExpertReducerState,
    { title: string; details: string } | undefined
  >(canopyStateSelector, (canopyReducerState) => {
    const infoRec = canopyReducerState.canopyQuestions.find(
      (e: CanopyQuestion) => e.id === canopyReducerState.questionToRecord?.id
    );
    return infoRec
      ? { title: infoRec.text, details: infoRec.details }
      : undefined;
  });

// showRightComponent
const showRightComponent = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.showRightComponent
  );

const isExpertCanopyAgreementAccepted = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.hasAcceptedAgreement
  );

const isExpertsQuestionsEnabled = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) => canopyReducerState.isExpertsQuestionsEnabled
  );

const isEverythingAnswered = (): OutputSelector<
  CanopyExpertAppState,
  boolean,
  (res: CanopyExpertReducerState) => boolean
> =>
  createSelector<CanopyExpertAppState, CanopyExpertReducerState, boolean>(
    canopyStateSelector,
    (canopyReducerState) =>
      canopyReducerState.canopyQuestions.every(
        (question) => question.isAnswered === true
      )
  );

export const CanopyExpertSelector = {
  canopyAnswerState,
  forceResetCam,
  canopyVideoActive,
  canopyVideoURL,
  isVideoRecording,
  questionToRecordSelector,
  canopyVideoBlob,
  isCanopyVideoSaving,
  isVideoUploaded,
  canopyAnswerData,
  videoTime,
  isVideoPlaying,
  videoMode,
  isCanopyAnswerLoading,
  isVideoAlreadyUploaded,
  questionIdRecentlyUploaded,
  isSubmitAnswersComplete,
  canopySelected,
  canopySelector,
  canopyListLoadingSelector,
  questionsSelector,
  canopyLoadingSelector,
  supportedVideoType,
  questionToRecordInfo,
  showRightComponent,
  isExpertCanopyAgreementAccepted,
  isExpertsQuestionsEnabled,
  isEverythingAnswered
};
