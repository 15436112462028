import { Button, Flex } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface LoadMoreButtonProps {
  onClick: () => void;
}
export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ onClick }) => {
  const { t } = useTranslation("addCompaniesModal");
  return (
    <Flex justify="center" align="center">
      <Button type="link" onClick={onClick}>
        {t("loadMore")}
      </Button>
    </Flex>
  );
};
