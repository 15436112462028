import dompurify from "dompurify";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./DangerouslySetInnerHTML.module.scss";

interface DangerouslySetInnerHTMLProps {
  text: string;
  fontSize?: string;
  fontWeight?: number;
  color?: string;
  title?: string;
}

export const DangerouslySetInnerHTML: React.FC<
  DangerouslySetInnerHTMLProps
> = ({
  text,
  fontSize = "14px",
  fontWeight = 400,
  color = ARBOLUS_COLORS.bColorText,
  title
}) => {
  const sanitizer = dompurify.sanitize;

  return (
    <span
      title={title}
      style={{ fontSize, fontWeight, color }}
      className={styles.container}
      dangerouslySetInnerHTML={{
        __html: sanitizer(text)
      }}
    />
  );
};
