import { Button, Card, Col, Flex, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import { User } from "@arbolus-technologies/api";
import { EXPERT_PROFILE_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

interface ExpertInfoProps {
  expertId: string;
  lastPublicCompanyExpDate?: string | null;
  linkedinProfile: string;
  expertUser: User;
  isVerified: boolean;
}

export const ExpertInfo: React.FC<ExpertInfoProps> = ({
  expertId,
  lastPublicCompanyExpDate,
  linkedinProfile,
  expertUser,
  isVerified
}) => {
  const { t } = useTranslation("customerSurveyExpertProfileSidePanel");

  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const expertCountry = metadataCountries.find(
    (country) => country.threeLetterCode === expertUser.isoCountryCode
  );

  return (
    <Card>
      <Row gutter={24}>
        <Col span={18}>
          <AntDAvatar
            firstName={expertUser.firstName}
            lastName={expertUser.lastName}
            profileImageUrl={expertUser.profileImageUrl}
            size={64}
            title={expertUser.title}
            isVerified={isVerified}
            lastPublicCompanyExpDate={lastPublicCompanyExpDate}
            linkedInProfile={linkedinProfile}
            countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
            countryName={expertCountry?.name ?? ""}
            mainGap={16}
          />
        </Col>
        <Col span={6}>
          <Flex gap={16} justify="flex-end">
            <Button
              type="primary"
              onClick={() =>
                window.open(EXPERT_PROFILE_ROUTE(expertId), "_blank")
              }
            >
              {t("editProfile")}
            </Button>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};
