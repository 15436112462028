import { Col, Form, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { TimezoneSelector } from "@arbolus-technologies/features/common";
import { TimezoneOption } from "@arbolus-technologies/models/common";
import {
  EVENT_FORM,
  EventFormInterface
} from "@arbolus-technologies/models/project";

const { TIMEZONE } = EVENT_FORM;

export const EventTimezone: React.FC = () => {
  const { t } = useTranslation("eventForm");

  const form = Form.useFormInstance<EventFormInterface>();
  const { timezone } = Form.useWatch([], form) ?? {};

  const handleTimezoneChange = (selectedTimezone: TimezoneOption) => {
    form.setFieldsValue({ [TIMEZONE]: selectedTimezone });
  };

  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label={t("eventTimezone")}
          name={TIMEZONE}
          rules={[
            {
              required: true,
              message: t("eventTimezoneRequired")
            }
          ]}
        >
          <TimezoneSelector
            selectedTimezone={timezone}
            onChange={(timezoneOption) => handleTimezoneChange(timezoneOption)}
            placeholder={t("eventTimezonePlaceholder")}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
