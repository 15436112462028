import { IEntity } from "./interfaces";

export interface ClientModel extends IEntity {
  name: string;
  logoUrl?: string;
}

export enum RULE_TYPE_ENUM {
  Email_Notification = "NotificationEmail",
  Approval_Required = "ApprovalRequired",
  LinkedIn_Verification = "LinkedInVerification"
}
