import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AntDAvatar,
  AntDSelectMenu,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import { User } from "@arbolus-technologies/api";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

interface SurveyLeadUserSelectorProps {
  disabled: boolean;
}

export const SurveyLeadUserSelector: React.FC<SurveyLeadUserSelectorProps> = ({
  disabled = false
}) => {
  const { t } = useTranslation("surveyLeadUserSelector");
  const form = Form.useFormInstance();

  const [surveyLeadUserList, setSurveyLeadUserList] = useState<User[]>([]);
  const [filteredSurveyLeadUserList, setFilteredSurveyLeadUserList] = useState<
    User[]
  >([]);

  const arbolusTeam = useSelector(ProjectNxSelector.arbolusTeam());

  const allUsers: User[] = [
    ...(arbolusTeam?.projectPrimaryLead
      ? [arbolusTeam.projectPrimaryLead]
      : []),
    ...(arbolusTeam?.projectSecondaryLead
      ? [arbolusTeam.projectSecondaryLead]
      : []),
    ...(arbolusTeam?.standardManagers ? arbolusTeam.standardManagers : [])
  ];

  useEffect(() => {
    const surveyOwners = allUsers.map((user) => ({ ...user, value: user.id }));
    setSurveyLeadUserList(surveyOwners);
  }, []);

  const handleGetItems = (query: string) => {
    const filteredLead = surveyLeadUserList.filter(
      (surveyLeadUser) =>
        surveyLeadUser.firstName.toLowerCase().includes(query.toLowerCase()) ||
        surveyLeadUser.lastName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredSurveyLeadUserList(filteredLead);
  };

  const surveyLeadUser = form.getFieldValue("surveyLeadUser");
  const handleOnChange = (value: AntDSelectValueType) => {
    const surveyLead = surveyLeadUserList.find((item) => item.id === value);
    form.setFieldsValue({ ["surveyLeadUser"]: surveyLead });
  };

  return (
    <AntDSelectMenu
      options={filteredSurveyLeadUserList}
      placeholder={t("placeholder")}
      optionFilterProp="children"
      value={
        surveyLeadUser
          ? `${surveyLeadUser.firstName} ${surveyLeadUser.lastName}`
          : undefined
      }
      optionRenderer={(option) => (
        <AntDAvatar
          firstName={option.data.firstName}
          lastName={option.data.lastName}
          profileImageUrl={option.data.profileImageUrl}
          size="small"
        />
      )}
      onChange={handleOnChange}
      onGetItems={handleGetItems}
      handleClearList={() => setFilteredSurveyLeadUserList([])}
      disabled={disabled}
    />
  );
};
