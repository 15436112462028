import { Button, Typography } from "antd";
import { LocationDescriptor } from "history";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";

import { AuthPageHeader } from "../AuthPageHeader/AuthPageHeader";

import styles from "./TermsConditions.module.scss";

export function TermsConditions({
  terms,
  acceptTerms,
  alreadyHaveAccountLink,
  children
}: {
  terms: string;
  acceptTerms: VoidFunction;
  alreadyHaveAccountLink?: LocationDescriptor;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation("register");

  return (
    <div className={styles.termsConditions}>
      <AuthPageHeader title={t("ourTerms")} />
      {/* Used as a subtitle in the legacy flow */}
      {children}

      <DangerouslySetInnerHTML text={terms} />

      <div className={styles.submit}>
        <Button type="primary" onClick={acceptTerms}>
          {t("accept&Proceed")}
        </Button>
      </div>

      {alreadyHaveAccountLink && (
        <Typography.Text type="secondary" className={styles.footer}>
          <Trans
            ns="register"
            i18nKey="alreadyHaveAccount"
            components={[<Link key="0" to={alreadyHaveAccountLink} />]}
          />
        </Typography.Text>
      )}
    </div>
  );
}
