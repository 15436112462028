import { sample } from "lodash";

import { AngleAntDColors } from "@arbolus-technologies/models/project";

export const angleColors: AngleAntDColors[] = [
  "#722ED1",
  "#1677FF",
  "#EB2F96",
  "#13C2C2",
  "#389E0D",
  "#FADB14",
  "#597EF7",
  "#FA8C16",
  "#FA541C",
  "#BAE637"
];

export const getRandomAngleColor = (): AngleAntDColors =>
  sample(angleColors) as AngleAntDColors;
