import { ColDef } from "ag-grid-community";
import i18next from "i18next";

import { floatingFilter } from "@arbolus-technologies/ui/components";
import { DATE_AND_TIME_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { ProjectName, SurveyTitle } from "./NameRenderer";

const COLUMN_IDS = {
  CLIENT_NAME: "clientName",
  SURVEY_TITLE: "surveyTitle",
  PROJECT_NAME: "projectName",
  COMPLETION_DATE: "completionDate"
} as const;

export const COLUMN_DEFINITIONS: ColDef<any>[] = [
  {
    field: COLUMN_IDS.CLIENT_NAME,
    headerName: i18next.t("expertProfileSurveysTable:client"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      maxNumConditions: 1,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 150,
    tooltipField: COLUMN_IDS.CLIENT_NAME
  },
  {
    field: COLUMN_IDS.SURVEY_TITLE,
    headerName: i18next.t("expertProfileSurveysTable:surveyName"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      maxNumConditions: 1,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 150,
    cellRenderer: SurveyTitle,
    tooltipField: COLUMN_IDS.SURVEY_TITLE
  },
  {
    field: COLUMN_IDS.PROJECT_NAME,
    headerName: i18next.t("expertProfileSurveysTable:project"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      maxNumConditions: 1,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 150,
    cellRenderer: ProjectName,
    tooltipField: COLUMN_IDS.PROJECT_NAME
  },
  {
    field: COLUMN_IDS.COMPLETION_DATE,
    headerName: i18next.t("expertProfileSurveysTable:date"),
    sort: "desc",
    valueGetter: ({ data }) =>
      formatDate(data.completionDate, DATE_AND_TIME_FORMAT),
    suppressHeaderMenuButton: true,
    minWidth: 150,
    tooltipValueGetter: (params) =>
      formatDate(params.data?.completionDate, DATE_AND_TIME_FORMAT)
  }
];

export const DEFAULT_COLUMN_DEFINITION: ColDef = {
  suppressMovable: true,
  menuTabs: ["filterMenuTab"],
  minWidth: 150
};
