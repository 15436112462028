import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { UserCompanyItem } from "@arbolus-technologies/api";
import { Avatar, Checkbox, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslation } from "react-i18next";

import styles from "./CompanyList.module.scss";

const { Text } = Typography;

interface CompanyItemProps {
  item: UserCompanyItem;
  isChecked: boolean;
  onCheckboxChange: (company: UserCompanyItem, checked: boolean) => void;
}

export const CompanyItem: React.FC<CompanyItemProps> = ({
  item,
  isChecked,
  onCheckboxChange
}) => {
  const { id, logoUrl, name, insightsCount } = item;
  const { t } = useTranslation("addCompaniesModal");

  const handleChange = (e: CheckboxChangeEvent) => {
    onCheckboxChange(item, e.target.checked);
  };

  return (
    <div key={id} className={styles.companyItem}>
      <Checkbox checked={isChecked} onChange={handleChange}>
        <Avatar size="small" src={logoUrl} className={styles.avatar}>
          {!logoUrl && <AntDIcon name="business" />}
        </Avatar>
        <Text className={styles.companyName} ellipsis>
          {name}
        </Text>
      </Checkbox>
      <Text type="secondary">{t("insight", { count: insightsCount })}</Text>
    </div>
  );
};
