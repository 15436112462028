import { WithTranslation, withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../../contexts/contentPanel/ContentPanelContext";
import { CIQInviteTag } from "../../../../app/components";

interface ExpertInvitePanelProps extends WithTranslation {
  invitationURL?: string;
}

const ExpertInvitePanel = ({
  invitationURL: inviteLink,
  t
}: ExpertInvitePanelProps): JSX.Element => (
  <SimpleBar className="content-panel-body expert-network-invite-container simplebar-light">
    <div className="top-container">
      <div className="panel-header">
        <h2>{t("inviteExperts")}</h2>
        <ContentPanelContextConsumer>
          {(contentPanelContext: ContentPanelContextProps): JSX.Element => (
            <div className="btn-close" onClick={contentPanelContext.closePanel}>
              <i className="ciq-icon ciq-close" />
            </div>
          )}
        </ContentPanelContextConsumer>
      </div>

      <hr />
    </div>

    <div className="panel-body">
      <div className="invite-container">
        <div className="info-container">
          <h3>{t("title")}</h3>
          <p>{t("description")}</p>
        </div>
        <div className="link-container">
          <h3>{t("linkTitle")}</h3>
          {inviteLink && (
            <div className="invite-tag-item">
              <CIQInviteTag url={inviteLink} />
            </div>
          )}
        </div>

        <hr />
      </div>
    </div>
  </SimpleBar>
);

export default withTranslation("expertInvitePanel")(ExpertInvitePanel);
