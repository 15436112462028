import { replace } from "connected-react-router";
import i18next from "i18next";
import queryString from "query-string";
import React from "react";
import {
  IGoogleReCaptchaConsumerProps,
  IWithGoogleReCaptchaProps,
  withGoogleReCaptcha
} from "react-google-recaptcha-v3";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";

import { Invitation, ToasterService } from "@arbolus-technologies/api";
import {
  AuthPageBase,
  ConfirmAccount,
  RECAPTCHA_ACTIONS
} from "@arbolus-technologies/auth";
import {
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import {
  APP_TRACKING_ROUTES,
  ArbolusFeatureFlags
} from "@arbolus-technologies/models/common";
import { AppSelector } from "@arbolus-technologies/stores/app";
import { Loader } from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

import { REST_ERROR } from "../../../../constants/api";
import { RegisterStages } from "../../../../constants/auth";
import { REGISTER } from "../../../../constants/navigation/publicRoutes";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { InviteQueryParams, User } from "../../../../models/user";
import { UserService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import AcceptTerms from "../../components/onboarding/acceptTerm/AcceptTerm";
import CreateAccount from "../../components/onboarding/createAccount/CreateAccount";
import CompanyEnquiry from "../../components/onboarding/inquire/CompanyEnquiry";
import OnboardSelection from "../../components/onboarding/onboardingSelection/OnboardSelection";
import ReInviteModal from "../../components/reinviteModal/ReinviteModal";

const notification = new ToasterService();

interface RegisterPageProps
  extends WithTranslation,
    IWithGoogleReCaptchaProps,
    RouteComponentProps {
  resetToInitial: () => void;
  featureFlags: ArbolusFeatureFlags;
}

interface RegisterPageState {
  stage: RegisterStages;
  isInviteModalOpen: boolean;
  isReinviteLoading: boolean;
  from?: string;
}

interface ExpiredInvite {
  email: string;
  token: string;
}

class RegisterPage extends React.Component<
  RegisterPageProps,
  RegisterPageState
> {
  static defaultProps = {
    resetToInitial: () => {},
    googleReCaptchaProps: {} as IGoogleReCaptchaConsumerProps
  };

  private invitedUser: User | undefined;
  private isExpert = true;
  private termsId = "";
  private isEmailInvite = false;
  private isCanopySignUp? = false;
  private invitation?: Invitation;
  private invitationCode?: string;
  private expiredInviteData?: ExpiredInvite;
  private reInviteSubscription?: Subscription;
  private clientName?: string;

  constructor(props: RegisterPageProps) {
    super(props);

    const { from } = (props.history.location?.state || {}) as {
      from: string;
    };

    const confirmationFlowStart = props.featureFlags.LinkedInAuth
      ? RegisterStages.CONFIRM_ACCOUNT
      : RegisterStages.TERMS;
    this.state = {
      isInviteModalOpen: false,
      isReinviteLoading: false,
      // If user details are in the query params it will go to account confirmation flow
      stage: areURLParamsValid(window.location)
        ? confirmationFlowStart
        : RegisterStages.SELECTION,
      from
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const { from } = this.state;
    document.title = t("register");

    const queryParams = queryString.parse(window.location.search);
    const surveyExpertId = queryString.parse(
      queryParams.search as string
    ).surveyExpertId;

    const isFromSurveyPage =
      surveyExpertId ||
      from === APP_TRACKING_ROUTES.SURVEY_QUALIFIED ||
      from === APP_TRACKING_ROUTES.SURVEY_NOT_QUALIFIED ||
      from === APP_TRACKING_ROUTES.SURVEY_FEEDBACK;

    // Access directly terms page if it comes from survey flux
    if (isFromSurveyPage) {
      if (this.props.featureFlags.LinkedInAuth) {
        // Do nothing - default page, OnboardSelection, will ask to choose LI or email before accepting terms
      } else {
        this.handleOptionSelection(RegisterStages.TERMS, true);
      }
    }
  }

  componentWillUnmount() {
    this.reInviteSubscription?.unsubscribe();
  }

  extractParams(): InviteQueryParams {
    const { email, token, invitation, signUpType, canopyId } =
      queryString.parse(window.location.search);
    return {
      email,
      token,
      invitation,
      signUpType,
      canopyId
    } as InviteQueryParams;
  }

  handleInviteError(email?: string, token?: string): void {
    const { resetToInitial } = this.props;
    this.handleOptionSelection(RegisterStages.SELECTION);
    resetToInitial();

    if (email && token) {
      this.expiredInviteData = { email, token };
      this.setState({
        isInviteModalOpen: true
      });
    }
  }

  async handleReinviteExpiredInvitation(): Promise<void> {
    const { googleReCaptchaProps, t } = this.props;
    const { executeRecaptcha } = googleReCaptchaProps;

    try {
      const recaptchaToken = await executeRecaptcha?.(
        RECAPTCHA_ACTIONS.REINVITE
      );
      this.setState({
        isReinviteLoading: true
      });

      const { email, token } = this.expiredInviteData!;

      this.reInviteSubscription = UserService.reinviteExpiredInvitation({
        email,
        oldToken: token,
        recaptchaToken: recaptchaToken!
      }).subscribe(
        () => {
          this.expiredInviteData = undefined;
          this.setState({
            isReinviteLoading: false,
            isInviteModalOpen: false
          });

          notification.showSuccess(i18next.t("register:reinviteSuccess"));
        },
        (error: ErrorResponse<CIQError>) => {
          const isNetworkError = error.status === REST_ERROR.NETWORK_ERROR;

          this.setState({
            isReinviteLoading: false,
            isInviteModalOpen: isNetworkError
          });

          notification.showError(error.message);
        }
      );
    } catch (err) {
      this.setState({
        isReinviteLoading: false
      });
      notification.showError(t("reinviteFailed"));
    }
  }

  handleTermsAccept(
    isClient: boolean,
    termsId: string,
    isEmailInvite: boolean,
    user?: User,
    invitation?: Invitation,
    clientName?: string,
    isCanopySignUp?: boolean
  ): void {
    this.invitedUser = user;
    this.isExpert = !isClient;
    this.termsId = termsId;
    this.invitation = invitation;
    this.clientName = clientName;
    this.isEmailInvite = isEmailInvite;
    this.isCanopySignUp = isCanopySignUp;

    this.handleOptionSelection(RegisterStages.CREATE_WITH_EMAIL);
  }

  handleOptionSelection(
    newStage: RegisterStages,
    isTermsFirstPage = false
  ): void {
    this.setState({
      stage: newStage
    });

    if (newStage === RegisterStages.TERMS) {
      const { from } = this.state;
      !isTermsFirstPage &&
        trackEvent(
          MixPanelEventNames.ExpertUserRegistrationCreateAccountStart,
          {
            action: MixPanelActions.Clicked,
            from
          }
        );
    }
  }

  handleReinviteModalDismiss(): void {
    this.expiredInviteData = undefined;
    this.setState({
      isInviteModalOpen: false
    });
  }

  renderStages(): JSX.Element {
    const { googleReCaptchaProps } = this.props;
    const { stage, from } = this.state;
    const { executeRecaptcha } = googleReCaptchaProps;

    const { email, token, invitation, signUpType, canopyId } =
      this.extractParams();
    const { TERMS, CREATE_WITH_EMAIL, CONFIRM_ACCOUNT, INQUIRY } =
      RegisterStages;

    this.invitationCode = invitation;

    switch (stage) {
      case TERMS:
        return (
          <AcceptTerms
            email={email}
            token={token}
            inviteToken={invitation}
            signUpType={signUpType}
            onError={() => this.handleInviteError()}
            onTermsAccept={(...params) => this.handleTermsAccept(...params)}
            executeRecaptcha={executeRecaptcha!}
            from={from}
          />
        );
      case CREATE_WITH_EMAIL:
        return (
          <CreateAccount
            termId={this.termsId}
            isExpert={this.isExpert}
            user={this.invitedUser}
            token={token}
            isEmailInvite={this.isEmailInvite}
            invitationCode={this.invitationCode}
            executeRecaptcha={executeRecaptcha!}
            invitation={this.invitation}
            onError={this.handleInviteError}
            clientName={this.clientName}
            isCanopySignUp={this.isCanopySignUp}
            canopyId={canopyId}
            from={from}
          />
        );
      case INQUIRY:
        return (
          <CompanyEnquiry executeRecaptcha={executeRecaptcha!} from={from} />
        );
      // Before confirming account the user will pick either LinkedIn or Email
      case CONFIRM_ACCOUNT:
        return (
          <ConfirmAccount
            onEmail={() =>
              this.handleOptionSelection(RegisterStages.TERMS, true)
            }
          />
        );
      // RegisterStages.SELECTION
      default:
        return (
          <OnboardSelection
            onOptionSelect={(newStage: RegisterStages) =>
              this.handleOptionSelection(newStage)
            }
            from={from}
          />
        );
    }
  }

  render(): JSX.Element {
    const { googleReCaptchaProps } = this.props;
    const { isInviteModalOpen, isReinviteLoading } = this.state;

    return (
      <AuthPageBase>
        <ReInviteModal
          isRequestLoading={isReinviteLoading}
          isModalOpen={isInviteModalOpen}
          onRequestInvite={this.handleReinviteExpiredInvitation}
          onModalDismiss={this.handleReinviteModalDismiss}
        />
        {googleReCaptchaProps?.executeRecaptcha ? (
          this.renderStages()
        ) : (
          <Loader isFull />
        )}
      </AuthPageBase>
    );
  }
}

function areURLParamsValid(location: Location): boolean {
  const { email, token, invitation, signUpType } = queryString.parse(
    location.search
  );
  if (email && token) {
    return utilService.validateEmail(email as string);
  }
  if (invitation) {
    return true;
  }
  if (signUpType) {
    return true;
  }
  return false;
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  resetToInitial: (): AppAction => dispatch(replace(REGISTER, { search: "" }))
});

const mapStateToProps = createStructuredSelector<
  AppState,
  { featureFlags: Record<string, boolean> }
>({
  featureFlags: AppSelector.getFeatureFlags()
});

export default compose<React.ComponentType<RouteComponentProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("register"),
  withGoogleReCaptcha
)(RegisterPage);
