import i18next from "i18next";
import { omit } from "lodash";

import {
  ExpertsListPageTab,
  ResponseAllExpertsTable
} from "@arbolus-technologies/api";
import {
  TableTextFilter,
  TanStackTableColumn
} from "@arbolus-technologies/ui/components";
import { normalize } from "@arbolus-technologies/utils";

import { ActivityDate } from "./ActivityDate/ActivityDate";
import { ActivityTime } from "./ActivityTime/ActivityTime";
import { Application } from "./Application/Application";
import { ApproveRejectAction } from "./ApproveRejectAction/ApproveRejectAction";
import { AssignedAngleComponent } from "./AssignedAngle/AssignedAngle";
import { Compliance } from "./Compliance/Compliance";
import { EditableTagline } from "./EditableTagline/EditableTagline";
import { AngleFilter } from "./Filters/AngleFilter/AngleFilter";
import { StatusFilter } from "./Filters/StatusFilter/StatusFilter";
import NameIconAngleBorder from "./NameIconAngleBorder/NameIconAngleBorder";
import { StatusCall } from "./StatusCall/StatusCall";
import { ThreeDots } from "./ThreeDots/ThreeDots";

export type ReferralsTableDefinition = {
  columnDefinitions: TanStackTableColumn[];
  gridTemplate: string;
};

export type ColumnWithWidth = TanStackTableColumn & {
  gridColumnWidth: string;
  sortingFn?: string;
};

const columnDefinitions: Record<string, ColumnWithWidth> = {
  expert: {
    name: "expert",
    component: (props) => <TableTextFilter {...props} displayName="Expert" />,
    cellComponent: NameIconAngleBorder,
    filterFn: "includesString",
    accessorFn: (row: ResponseAllExpertsTable) =>
      normalize(`${row.expert.firstName} ${row.expert.lastName}`),
    gridColumnWidth: "240px"
  },
  status: {
    name: "status",
    component: StatusFilter,
    cellComponent: StatusCall,
    accessorFn: (row: ResponseAllExpertsTable) =>
      normalize(row.callStatus ?? ""),
    gridColumnWidth: "minmax(56px, auto)"
  },
  tagline: {
    name: "tagline",
    component: (props) => <TableTextFilter {...props} displayName="Tagline" />,
    cellComponent: EditableTagline,
    filterFn: "includesString",
    accessorFn: (row: ResponseAllExpertsTable) =>
      normalize(row.tagline ?? row.expert.title),
    gridColumnWidth: "minmax(256px, 1fr)"
  },
  assignedAngle: {
    name: "assignedAngle",
    component: AngleFilter,
    cellComponent: AssignedAngleComponent,
    gridColumnWidth: "minmax(192px, 256px)"
  },
  date: {
    name: "date",
    component: () => <div>{i18next.t("expertsList:date") as string}</div>,
    cellComponent: ActivityDate,
    gridColumnWidth: "auto"
  },
  application: {
    name: "application",
    component: () => (
      <div>{i18next.t("expertsList:application") as string}</div>
    ),
    cellComponent: Application,
    gridColumnWidth: "auto"
  },
  compliance: {
    name: "compliance",
    component: () => <div>{i18next.t("expertsList:compliance") as string}</div>,
    cellComponent: Compliance,
    gridColumnWidth: "auto"
  },
  action: {
    name: "action",
    component: () => <div>{i18next.t("expertsList:action") as string}</div>,
    cellComponent: ApproveRejectAction,
    gridColumnWidth: "auto"
  },
  time: {
    name: "time",
    component: () => <div>{i18next.t("expertsList:time") as string}</div>,
    cellComponent: ActivityTime,
    gridColumnWidth: "auto"
  },
  moreOptions: {
    name: "moreOptions",
    cellComponent: ThreeDots,
    gridColumnWidth: "40px"
  }
};

const definitionsArr = Object.values(columnDefinitions);

const adminConfigCandidate = (includeCompliance: boolean) => {
  const newDefinitions = definitionsArr.filter((d) => d.name !== "status");
  return createTableDefinition(newDefinitions, includeCompliance);
};

export const configAcceptTabColumns = (
  includeCompliance: boolean,
  actionsColumnName: "action" | "moreOptions",
  tanStackSortFn: (sortValue: string) => void
): ReferralsTableDefinition => {
  const newDefinitions = definitionsArr
    .filter(
      (column) => column.name !== "date" && column.name !== actionsColumnName
    )
    .map((d) => {
      if (d.name === "status") {
        return {
          ...d,
          component: () => <StatusFilter tanStackSortFn={tanStackSortFn} />
        };
      }
      return d;
    });
  return createTableDefinition(newDefinitions, includeCompliance);
};

export const adminExpertColumns = (
  tabActive: ExpertsListPageTab,
  includeCompliance = true
): ReferralsTableDefinition => {
  if (tabActive === ExpertsListPageTab.Candidate) {
    return adminConfigCandidate(includeCompliance);
  }

  const newDefinitions = definitionsArr.filter(
    (d) => d.name !== "status" && d.name !== "action"
  );
  return createTableDefinition(newDefinitions, includeCompliance);
};

export const clientExpertColumns = (
  includeCompliance: boolean
): ReferralsTableDefinition => {
  const newDefinitions = definitionsArr.filter(
    (column) => column.name !== "status" && column.name !== "moreOptions"
  );
  return createTableDefinition(newDefinitions, includeCompliance);
};

export function createTableDefinition(
  definitions: ColumnWithWidth[],
  includeCompliance = false
): {
  columnDefinitions: Omit<ColumnWithWidth, "gridColumnWidth">[];
  gridTemplate: string;
} {
  const newDefinitions = includeCompliance
    ? definitions
    : definitions.filter((d) => d.name !== "compliance");
  return {
    columnDefinitions: newDefinitions.map((d) => omit(d, "gridColumnWidth")),
    gridTemplate: newDefinitions.map((d) => d.gridColumnWidth).join(" ")
  };
}
