import { Flex, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDAngleTag, AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  AngleModel,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";
import { RateCard } from "@arbolus-technologies/models/expert";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { DNCTag } from "@arbolus-technologies/ui/components";
import { convertValueToCurrencyFormat } from "@arbolus-technologies/utils";

import styles from "./ExpertCard.module.scss";

interface ExpertTagsProps {
  isRecommended: boolean;
  recommendedAngle: AngleModel | null;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  rateCard?: RateCard;
}

export const ExpertTags: React.FC<ExpertTagsProps> = ({
  isRecommended,
  recommendedAngle,
  doNotContactStatus,
  rateCard
}) => {
  const { t } = useTranslation("discoverPage");

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const getRateCardText = () =>
    convertValueToCurrencyFormat(rateCard?.price!, rateCard?.isoCurrencyCode!);

  return (
    <Flex gap={8} justify="space-between" align="center">
      <div>
        {isAdmin && isRecommended && recommendedAngle && (
          <Flex gap={4} align="center">
            <Tag
              icon={<AntDIcon name="award_star" />}
              color="success"
              className={styles.tag}
            >
              {t("recommendedFor")}
            </Tag>
            <AntDAngleTag angle={recommendedAngle} />
          </Flex>
        )}
      </div>
      <Flex gap={4} justify="end" align="center">
        <DNCTag dncStatus={doNotContactStatus} showTooltip isAdmin={isAdmin} />
        {isAdmin &&
          (rateCard ? (
            <Tag
              color="processing"
              style={{ marginInlineEnd: 0 }}
              className={styles.tag}
            >
              {getRateCardText()}
            </Tag>
          ) : (
            <Tag style={{ marginInlineEnd: 0 }} className={styles.tag}>
              {t("noRateCard")}
            </Tag>
          ))}
      </Flex>
    </Flex>
  );
};
