import {
  ANSWER_PREFERENCES_TYPE,
  ANSWER_PREFERENCES_TYPE_VALUE,
  ANSWER_TYPE,
  AnswersRange,
  CANOPY_STATUS,
  CanopyAgreement,
  Choice,
  EXPERT_CANOPY_STATUS
} from "@arbolus-technologies/models/canopy";
import {
  ConfirmedEmailExpertModel,
  DateString
} from "@arbolus-technologies/models/common";

import { IEntity, UserModel } from "./common";

export interface DownloadCanopyInterface {
  id: string;
  fileName: string;
  fileKey: string;
  downloadUrl: string;
}

export interface ListCanopy {
  id: string;
  status: CANOPY_STATUS;
  title: string;
  overview: string;
  modified: string;
  expertsCount: number;
  experts: ConfirmedEmailExpertModel[];
}

export interface Canopy {
  id: string;
  projectId: string;
  status: CANOPY_STATUS;
  title: string;
  overview?: string;
  responses?: "Old" | "New" | "Empty";
  links?: string[];
  documents?: DownloadCanopyInterface[];
  hasComplianceCheck: boolean;
  defaultOwner?: UserModel;
  isAutomaticallyCreated: boolean;
  questionsCount: number;
  brief?: string;
  targetCompanyId: null | string;
}

export interface CanopyExpertsCount {
  incomplete: number;
  inReview: number;
  complete: number;
  rejected: number;
}

export interface AllCanopy {
  id: string;
  status: CANOPY_STATUS;
  title: string;
  projectId?: string;
  projectName?: string;
  lastAnswerDate: string;
  expertCount: number;
  created: string;
}

export interface ExpertCanopy extends Canopy {
  isCanopyAgreementAccepted: boolean;
  canopyExpertStatus: EXPERT_CANOPY_STATUS | null;
}

export interface CanopyPublic {
  id: string;
  title: string;
  overview: string;
}

enum ICanopyCreationType {
  MANUAL = "Manual",
  CANOPAI = "Canopai",
  TEMPLATE = "Template"
}

export interface CreateCanopyDataRequest {
  title: string;
  overview?: string;
  brief?: string;
  projectId?: string;
  type?: ICanopyCreationType;
  canopyTemplateId?: string;
}

export interface EditCanopyDataRequest {
  canopyId: string;
  title: string;
  overview: string;
  links: string[];
  hasComplianceCheck: boolean;
}

export interface DownloadInterface {
  file: string;
  fileId: string;
}

export interface FileInterface {
  url: string;
  id?: string;
}

export interface FormInterface {
  isAdmin: boolean;
  title: string;
  overview: string;
  links: FileInterface[];
  documents: DownloadCanopyInterface[];
  hasComplianceCheck: boolean;
  defaultOwner: UserModel | null;
}

export interface UploadingAttachment {
  attachmentId: string;
  attachment: File;
}

export interface CanopyDeleteRequest {
  canopyId: string;
}

export interface CanopyDocumentRemove {
  documentId: string;
}

export interface LinksUrlProps {
  url: string;
}

export interface MultiRatingChoice {
  multiRatingChoiceId: string;
  multiRatingChoiceText: string;
  multiRatingValue?: number | null;
  sortOrder: number;
}

export interface CanopyQuestion {
  id: string;
  canopyId: string;
  text: string;
  details: string;
  type: ANSWER_TYPE;
  order: number;
  isAnswered?: boolean;
  preferenceType?: ANSWER_PREFERENCES_TYPE;
  answersRange?: AnswersRange;
  exactNumber?: number;
  optionChoices?: Choice[];
  valuePreferencesType?: ANSWER_PREFERENCES_TYPE_VALUE;
  valuePreferencesRange?: AnswersRange;
  multiRatingChoices?: MultiRatingChoice[];
  nextQuestionId: string | null;
  previousQuestionId: string | null;
}

export interface OptionChoice {
  text: string;
  sortOrder: number;
  isOther: boolean;
  id: string;
  isSelected: boolean;
  otherText?: string;
}

export interface ValueAnswersInterface {
  value?: number | null;
  multiRatingAnswers?: MultiRatingChoice[] | null;
}

export interface CanopyQuestionForExpert {
  id: string;
  canopyId: string;
  text: string;
  details: string;
  type: string;
  order: number;
  questionText?: string;
  answersRange?: AnswersRange;
  optionChoices?: OptionChoice[];
  valuePreferencesType?: ANSWER_PREFERENCES_TYPE_VALUE;
  valuePreferencesRange?: AnswersRange;
  multiRatingChoices: MultiRatingChoice[] | null;
  value: number | null;
  shortText: string | null;
  npsValue: number | null;
}

export interface CanopyBaseAnswer {
  id: string;
  expert: {
    expertId: string;
    title: string;
    firstName: string;
    lastName: string;
    profileImageUrl: string | null;
  };
  type: ANSWER_TYPE;
}

export interface CanopyVideoAnswers extends CanopyBaseAnswer {
  type: ANSWER_TYPE.VIDEO;
  downloadUrl: string;
  questionText?: string;
  questionDetails?: string;
  transcriptPreview: string;
  status: EXPERT_CANOPY_STATUS;
}

export interface CanopyVideoAnswersByExpert extends CanopyBaseAnswer {
  type: ANSWER_TYPE.VIDEO;
  downloadUrl: string;
  id: string;
  questionText: string;
  questionDetails: string;
  transcriptPreview: string;
  questionId: string;
  status: EXPERT_CANOPY_STATUS;
}

export interface CanopyAnswersByQuestion {
  pagination: {
    count: number;
    offset: number;
    limit: number;
  };
  order: string;
  questionId: string;
  questionDetails: string;
  questionText: string;
  items: CanopyVideoAnswers[];
}

export interface AllCanopyAnswersByExpert {
  pagination: {
    count: number;
    offset: number;
    limit: number;
  };
  expertId: string;
  canopyId: string;
  items: CanopyVideoAnswersByExpert[];
}

export interface CanopyVideoTranscript {
  answerId: string;
  content: string;
  isSmartTranscript: boolean;
}

export interface CanopyExpertBaseAnswer {
  id: string;
  questionId: string;
  expertId: string;
  type: ANSWER_TYPE;
  questionText: string;
  details: string;
}

export interface GetCanopyExpertVideoAnswer extends CanopyExpertBaseAnswer {
  type: ANSWER_TYPE.VIDEO;
  downloadUrl: string;
  durationInSeconds: number;
}

export interface FormCanopyQuestionVideo {
  type: ANSWER_TYPE;
  text: string;
  details: string;
}

export interface FormCanopyQuestionMultipleChoice {
  type: ANSWER_TYPE;
  text: string;
  details: string;
  minimumRange: number;
  maximumRange: number;
  optionChoices: Choice[];
}

export interface FormCanopyValue {
  type: ANSWER_TYPE;
  text: string;
  details: string;
  minimumRange: number;
  maximumRange: number;
  optionChoices: Choice[];
}

export interface CreateEditCanopyQuestion {
  canopyId: string;
  order: number;
  type: ANSWER_TYPE;
  text: string;
  details: string;
  questionId?: string;
  minimumRange?: number;
  maximumRange?: number;
  valuePreferencesType?: ANSWER_PREFERENCES_TYPE_VALUE;
  optionChoices?: Choice[];
  multiRatingChoices?: Choice[];
}

export interface ExpertInACanopyProfile {
  expertId: string;
  title: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
}

export interface ExpertInACanopy {
  canopyId: string;
  isSubmitted: boolean;
  expert: ExpertInACanopyProfile;
  status: EXPERT_CANOPY_STATUS;
}

export interface ExpertDetailCanopyV2 {
  id: string;
  user: {
    firstName: string;
    lastName: string;
    profileImageUrl?: string;
    title: string;
    id: string;
  };
  lastPublicCompanyExpDate: DateString | null;
}

export interface ListTransactionCanopy extends IEntity {
  title: string;
  status: CANOPY_STATUS;
  projectId: string;
  projectName: string;
  clientId: string;
  businessEntityId: string;
}

export interface CanopyExpertAgreement {
  id: string;
  canopyAgreement: CanopyAgreement;
}

interface Answer {
  transcript?: string;
  multiChoiceAnswer?: string[];
  valueAnswers?: ValueAnswers[];
  valuePreferencesType?: ValueQuestionType;
  shortText?: string;
  otherText?: string;
  npsValue?: number;
  expert: {
    title: string;
    expertId: string;
    id: string;
    firstName: string;
    lastName: string;
    profileImageUrl: string | null;
    email: string | null;
  };
}

export interface ValueAnswers {
  multiRatingChoiceId: string;
  multiRatingChoiceText: string;
  sortOrder: number;
  value: number;
  valueAnswerId: string;
}

export interface CanopyQuestionPDF {
  questionText: string;
  type: QuestionType;
  sortOrder: number;
  answers: Answer[];
  netPromoterScore?: number;
  questionDetails: string;
}

export type CanopyQuestionsPDF = CanopyQuestionPDF[];

export enum ValueQuestionType {
  Number = "Number",
  Percentage = "Percentage",
  MultiRating = "MultiRating"
}
export enum QuestionType {
  Video = "Video",
  MultiChoice = "MultipleChoice",
  Value = "Value",
  ShortText = "ShortText",
  Nps = "Nps"
}

export enum FeedbackCollectorType {
  CANOPY = "canopy",
  CALL = "call"
}

export interface CanopyFeedbackPostData {
  email: string;
  rate: number;
  type: FeedbackCollectorType;
  canopyId: string;
  question: string;
}

export interface CanopyFeedbackPutData {
  rate: number;
  comment?: string;
  id?: string;
  question: string;
  type: FeedbackCollectorType;
}

export interface RequestCanopyTemplateResponse {
  items: CanopyTemplateItem[];
}

export interface CanopyTemplateItem {
  canopyTemplateId: string;
  templateDescription: string;
  templateName: string;
}
