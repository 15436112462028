import React, { useEffect } from "react";

import {
  DefaultExpertPaymentsService,
  DefaultToasterService,
  ExpertPaymentsService,
  TipaltiIframes,
  ToasterService
} from "@arbolus-technologies/api";
import { LoaderOrComponent } from "@arbolus-technologies/ui/components";
import { PublicInterface } from "@arbolus-technologies/utils";

interface PaymentsHistoryProps {
  expertId: string;
  expertPaymentsService?: PublicInterface<ExpertPaymentsService>;
  notificationService?: PublicInterface<ToasterService>;
}

export const PaymentsHistory: React.FC<PaymentsHistoryProps> = ({
  expertId,
  expertPaymentsService = DefaultExpertPaymentsService,
  notificationService = DefaultToasterService
}) => {
  const [iframes, setIframes] = React.useState<TipaltiIframes>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (expertId) {
      expertPaymentsService.getIframes(expertId).subscribe({
        next: setIframes,
        error: notificationService.showApiErrors
      });
    }
  }, [expertId]);

  return (
    <LoaderOrComponent isLoading={!iframes}>
      <iframe
        src={iframes?.paymentsUrl}
        height="500"
        width="100%"
        title="tipalti-payments"
      />
    </LoaderOrComponent>
  );
};
