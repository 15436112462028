import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ProjectNote } from "@arbolus-technologies/api";
import { KEYWORDS_SEARCH_OPTIONS } from "@arbolus-technologies/models/project";
import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { ProjectNoteCard } from "./ProjectNoteCard";

import { EXPERT_PROFILE_TABS } from "@arbolus-technologies/utils";
import { Button, Card, Divider, Flex } from "antd";

interface ProjectNotesProps {
  expertId: string;
  keywordsList: string[];
  projectNotes: ProjectNote[];
}
export const ProjectNotes: React.FC<ProjectNotesProps> = ({
  expertId,
  keywordsList,
  projectNotes
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");

  const hasKeywords = keywordsList.length > 0;

  const isProjectNotesOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.PROJECTS_NOTES
    )
  );

  return (
    <Card
      title={t("projectNotes")}
      size="small"
      extra={
        <Link
          to={{
            pathname: EXPERT_PROFILE(expertId).pathname,
            search: `?defaultTab=${EXPERT_PROFILE_TABS.PROJECT_NOTES}`
          }}
          target="_blank"
        >
          <Button type="link">{t("allNotes")}</Button>
        </Link>
      }
    >
      {projectNotes.length > 0 && isProjectNotesOptionSelected && hasKeywords
        ? projectNotes.map((projectNote: ProjectNote, index) => (
            <Flex vertical key={projectNote.project.id}>
              <ProjectNoteCard
                projectNote={projectNote}
                keywordsList={keywordsList}
              />
              {index < projectNotes.length - 1 && (
                <Divider style={{ margin: "12px 0" }} />
              )}
            </Flex>
          ))
        : t("noProjectNotes")}
    </Card>
  );
};
