import { Card, Result } from "antd";
import React from "react";

interface EmptyPlaceholderProps {
  icon: React.ReactNode;
  title: string;
  subTitle: string;
}

export const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = ({
  icon,
  title,
  subTitle
}) => {
  return (
    <Card
      size="small"
      styles={{ body: { display: "flex", justifyContent: "center" } }}
    >
      <Result
        icon={icon}
        title={title}
        subTitle={subTitle}
        style={{ width: "70%" }}
      />
    </Card>
  );
};
