import React, { ForwardedRef, ReactNode, forwardRef } from "react";

interface Props {
  children: ReactNode;
}

export const PrinterWrapper = forwardRef(
  ({ children }: Props, ref: ForwardedRef<null>) => (
    <div ref={ref}>{children}</div>
  )
);
