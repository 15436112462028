import { Tag, Typography } from "antd";
import React from "react";

const { Text } = Typography;

interface CustomerOfFilterTagProps {
  label: string;
}

export const CustomerOfFilterTag: React.FC<CustomerOfFilterTagProps> = ({
  label
}) => (
  <>
    <Text>·</Text>
    <Tag
      title={label}
      color="success"
      style={{ display: "flex", alignItems: "center", marginInlineEnd: 0 }}
    >
      {label}
    </Tag>
  </>
);
