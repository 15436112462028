import { Button, Icon } from "arbolus-ui-components";
import dompurify from "dompurify";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  DefaultToasterService,
  ExpertService,
  ExpertUser,
  ToasterService
} from "@arbolus-technologies/api";
import { EXPERT_TABLE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ArbolusModalDanger,
  Loader
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./DeleteExpertSection.module.scss";

interface DeleteExpertSectionProps {
  expertUser: ExpertUser;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

const DeleteExpertSection: React.FC<DeleteExpertSectionProps> = ({
  expertUser,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}): JSX.Element => {
  const { t } = useTranslation("deleteExpertSection");
  const history = useHistory();

  const [deleteExpertModal, setDeleteExpertModal] = useState(false);
  const [showDeleteExpertWarning, setShowDeleteExpertWarning] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const handleNavigateBack = useCallback(() => {
    isAdmin && history.push(EXPERT_TABLE);
  }, [history, isAdmin]);

  const handleDeleteExpertConfirm = () => {
    setDeleteExpertModal(false);
    setIsDeleting(true);
    expertService.deleteExpert(expertUser.id).subscribe(
      (response) => {
        if (response.deleted) {
          notificationService.showSuccess(t("expertUserDeleted"));
          handleNavigateBack();
        } else {
          notificationService.showError(t("contactSupport"));
        }
      },
      (error) => {
        //1337 is a custom BE error code when user has pending transactions
        const isTransactionPendingError = error.status === "1337";
        return notificationService.showError(
          isTransactionPendingError
            ? error.message
            : t("restService:somethingWrong")
        );
      }
    );
  };

  const expertName = displayUserName(expertUser);

  return (
    <>
      <div className={styles.deleteContainer}>
        {isDeleting && <Loader isFull />}
        {!showDeleteExpertWarning && (
          <div
            className={styles.container}
            onClick={() => setShowDeleteExpertWarning(true)}
            data-testid="deleteAccountSection"
          >
            <div className={styles.textContainer}>
              <h1 className={styles.title}>{t("deleteAccount")}</h1>
              <p className={styles.subtitle}>{t("removeUser")}</p>
            </div>
            <Icon name="chevron_right" fontSize="24px" />
          </div>
        )}
        {showDeleteExpertWarning && (
          <div
            className={styles.warningContainer}
            data-testid="warningContainer"
          >
            <h1 className={styles.warningTitle}>{t("warningTitle")}</h1>
            <h2 className={styles.warningSubtitle}>
              {t("warningSubtitle", { expert: expertName })}
            </h2>
            <ul className={styles.warningList}>
              <li
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(t("warningMessage"))
                }}
              />
            </ul>
            <div className={styles.btnContainer}>
              <Button
                type="rejection"
                onClick={() => setDeleteExpertModal(true)}
                data-testid="openDeleteModalButton"
                text={t("deleteAccount")}
              />
            </div>
          </div>
        )}
      </div>
      <ArbolusModalDanger
        toggle={() => setDeleteExpertModal(false)}
        confirmationText={expertName}
        isOpen={deleteExpertModal}
        rightButton={{
          onClick: handleDeleteExpertConfirm,
          text: t("deleteAccount"),
          type: "rejection"
        }}
        leftButton={{
          onClick: () => setDeleteExpertModal(false),
          text: t("cancel"),
          type: "tertiary"
        }}
      />
    </>
  );
};

export default DeleteExpertSection;
