import { useTranslation } from "react-i18next";
import styles from "./WebinarBanner.module.scss";
import register from "./register.png";
import speaker1 from "./speaker1.png";
import speaker2 from "./speaker2.png";

export const WebinarBanner = () => {
  const { t } = useTranslation("webinarBanner");

  return (
    <div className={styles.bannerContent}>
      <div className={styles.titles}>
        <span className={styles.header2}>{t("header1")}</span>
        <span className={styles.header1}>
          THE OFFICE <br />
          OF THE CFO:
        </span>
        <span className={styles.header2}>{t("header2")}</span>
      </div>
      <div className={styles.speakers}>
        <img src={speaker1} className={styles.speaker} alt="Mikaël Zakarian" />
        <img src={speaker2} className={styles.speaker} alt="Mina Mutafchieva" />
      </div>
      <div className={styles.footer}>
        <span className={styles.date}>{t("date")}</span>

        <a
          href="https://bit.ly/43BF6ik"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.registerButton}
        >
          <img src={register} alt={t("register")} />
        </a>
      </div>
    </div>
  );
};
