import { Card, Flex, Form, Switch, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { EVENT_FORM } from "@arbolus-technologies/models/project";

const { Title, Text } = Typography;
const { FREE_TRANSCRIPT, SMART_TRANSCRIPT } = EVENT_FORM;

export const EventTranscriptOptions: React.FC = () => {
  const { t } = useTranslation("eventForm");

  return (
    <Card>
      <Flex vertical gap={16}>
        <Title level={5}>{t("transcriptOptions")}</Title>

        <Flex gap={16} align="center">
          <Form.Item
            name={FREE_TRANSCRIPT}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Switch />
          </Form.Item>
          <Text>{t("freeTranscript")}</Text>
        </Flex>

        <Text>{t("freeTranscriptDescription")}</Text>

        <Flex gap={16} align="center">
          <Form.Item
            name={SMART_TRANSCRIPT}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Switch />
          </Form.Item>
          <Text>{t("smartTranscript")}</Text>
        </Flex>

        <Text>{t("smartTranscriptDescription")}</Text>
      </Flex>
    </Card>
  );
};
