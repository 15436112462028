import { Button, Icon } from "arbolus-ui-components";
import clsx from "clsx";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useLocation } from "react-router";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";

import {
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { Alert } from "../..";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { User } from "../../../../../models/user";
import { CIQFormInput, CIQPasswordGuide } from "../../../../app/components";
import CustomPasswordInput from "../../../../app/components/CustomPasswordInput";
import { CreateAccountFormValues } from "../createAccount/CreateAccount";
import {
  ClientRegisterSchema,
  ExpertRegisterSchema
} from "./RegisterFormSchema";

import styles from "./RegisterForm.module.scss";

interface RegisterFormProps {
  user?: User;
  isLoading: boolean;
  isEmailInvite: boolean;
  signUpError?: ErrorResponse<CIQError>;
  isCanopySignUp?: boolean;
  isExpert: boolean;
  onCreateAccountClicked: (formValues: CreateAccountFormValues) => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  user,
  isLoading,
  signUpError,
  isCanopySignUp,
  onCreateAccountClicked,
  isEmailInvite,
  isExpert
}) => {
  const { t } = useTranslation("register");
  const location = useLocation();
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.ExpertUserRegistrationUserForm
  );

  const { from } = (location?.state || {}) as {
    from: string;
  };

  useEffect(() => {
    startTrackingTime();

    return () => endTrackingTime({ from });
  }, [startTrackingTime, endTrackingTime, from]);

  const initialPhoneNumber = user?.phoneNumber?.split("+")[1] ?? "";

  const renderCreateAccountForm = ({
    values,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched
  }: FormikProps<CreateAccountFormValues>): JSX.Element => {
    const { phoneNumber, isRealNameEnabled } = values;

    const handlePhoneChange = (value: string): void => {
      setFieldTouched("phoneNumber", true);
      setFieldValue("phoneNumber", value);
    };

    const hasErrors = Object.keys(errors).length > 0;

    return (
      <Form>
        {signUpError && <Alert feedback={signUpError} isSuccess={false} />}
        <FormGroup>
          <Field
            className={clsx({
              "email-input": !isEmailInvite
            })}
            name="email"
            placeholder={t("email")}
            disabled={isEmailInvite}
            component={CIQFormInput}
          />
        </FormGroup>
        <FormGroup
          className={clsx({
            "is-invalid": errors.password && touched.password
          })}
        >
          <InputGroup className="append">
            <Field
              name="password"
              placeholder={t("password")}
              type="password"
              disableErrorMessage
              component={CustomPasswordInput}
            />
          </InputGroup>
          {errors.password && touched.password && (
            <Label className="invalid-feedback">{errors.password}</Label>
          )}
        </FormGroup>
        <FormGroup
          className={clsx({
            "is-invalid": errors.password2 && touched.password2
          })}
        >
          <InputGroup className="append">
            <Field
              name="password2"
              placeholder={t("confirmPassword")}
              type="password"
              disableErrorMessage
              component={CustomPasswordInput}
            />
          </InputGroup>
          {errors.password2 && touched.password2 && (
            <Label className="invalid-feedback">{errors.password2}</Label>
          )}
        </FormGroup>
        <CIQPasswordGuide password={values.password} />

        <div className={styles.name}>
          <div>
            <Field
              name="firstName"
              placeholder={t("firstName")}
              type="text"
              component={CIQFormInput}
            />
          </div>
          <div>
            <Field
              name="lastName"
              placeholder={t("lastName")}
              type="text"
              component={CIQFormInput}
            />
          </div>
        </div>
        {isExpert && (
          <FormGroup
            className={clsx({
              "is-invalid": errors.linkedinUrl && touched.linkedinUrl
            })}
          >
            <Field
              name="linkedinUrl"
              placeholder={t("linkedinURL")}
              type="text"
              component={CIQFormInput}
            />
          </FormGroup>
        )}
        {!isExpert && !user?.isCiqManager && (
          <FormGroup
            className={clsx(
              {
                "is-invalid":
                  touched.isRealNameEnabled && errors.isRealNameEnabled
              },
              styles.realNameWrapped
            )}
            check
            inline
          >
            <Input
              type="checkbox"
              name="isRealNameEnabled"
              checked={isRealNameEnabled}
              onChange={() =>
                setFieldValue("isRealNameEnabled", !isRealNameEnabled)
              }
            />
            <Label check className={styles.displayNameCheckLabel}>
              {t("displayNameCheckLabel")}
            </Label>
            <span
              className={styles.displayNameCheckboxIcon}
              title={t("displayNameTooltip")}
            >
              <Icon
                name="info"
                fontSize="16px"
                color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
              />
            </span>
          </FormGroup>
        )}
        {!isRealNameEnabled && (
          <FormGroup
            className={clsx({
              "is-invalid": touched.displayName && errors.displayName
            })}
          >
            <Label>{t("displayNameLabel")}</Label>
            <Field
              autoComplete="off"
              name="displayName"
              type="text"
              component={CIQFormInput}
            />
          </FormGroup>
        )}
        <FormGroup
          className={clsx({
            "is-invalid": errors.phoneNumber && touched.phoneNumber
          })}
        >
          <PhoneInput
            inputProps={{
              name: "phoneNumber"
            }}
            placeholder={t("phoneNumber")}
            enableSearch
            country="us"
            value={phoneNumber || ""}
            onBlur={handleBlur("phoneNumber")}
            onChange={handlePhoneChange}
            disableSearchIcon
            prefix=""
          />
          {errors.phoneNumber && touched.phoneNumber && (
            <Label className="invalid-feedback">{errors.phoneNumber}</Label>
          )}
        </FormGroup>
        {isCanopySignUp && (
          <Field
            name="title"
            placeholder={t("title")}
            type="text"
            component={CIQFormInput}
          />
        )}
        <div className={styles.submit}>
          <Button
            disabled={isLoading || hasErrors}
            nativeType="submit"
            text={t("createAccount")}
          />
        </div>
      </Form>
    );
  };

  return (
    <PageTracker
      page={MixpanelPages.ExpertUserRegistrationPageUserForm}
      from={from}
    >
      <Formik<CreateAccountFormValues>
        initialValues={{
          email: user?.email ?? "",
          password: "",
          password2: "",
          phoneNumber: initialPhoneNumber,
          firstName: user?.firstName ?? "",
          lastName: user?.lastName ?? "",
          title: "",
          isRealNameEnabled: isExpert
            ? true
            : (user?.isRealNameEnabled ?? true),
          displayName: user?.displayName ?? "",
          linkedinUrl: user?.linkedInProfile ?? ""
        }}
        validateOnMount={false}
        validateOnBlur
        validateOnChange
        validationSchema={
          isExpert ? ExpertRegisterSchema : ClientRegisterSchema
        }
        onSubmit={onCreateAccountClicked}
      >
        {renderCreateAccountForm}
      </Formik>
    </PageTracker>
  );
};

export default RegisterForm;
