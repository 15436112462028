import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import { Checkbox, Flex, Typography } from "antd";
import React from "react";
import styles from "./Item.module.scss";

interface ItemProps {
  id: string;
  firstName: string;
  lastName?: string;
  profileImageUrl?: string;
  description?: string;
  isSelected: boolean;
  onClick: (id: string, isUserSelected: boolean, isUser: boolean) => void;
  isUser?: boolean;
}
export const Item: React.FC<ItemProps> = ({
  id,
  firstName,
  lastName,
  profileImageUrl,
  description,
  isSelected,
  onClick,
  isUser = false
}) => {
  return (
    <div
      className={styles.item}
      onClick={() => onClick(id, isSelected, isUser)}
    >
      {isUser ? (
        <AntDAvatar
          firstName={firstName}
          lastName={lastName}
          profileImageUrl={profileImageUrl}
        />
      ) : (
        <Flex vertical>
          <Typography.Text strong ellipsis className={styles.firstName}>
            {firstName}
          </Typography.Text>
          {description && (
            <Typography.Text
              type="secondary"
              ellipsis
              className={styles.description}
            >
              {description}
            </Typography.Text>
          )}
        </Flex>
      )}
      <Checkbox checked={isSelected} />
    </div>
  );
};
