import {
  ExpertDetail,
  PROJECT_REFERRAL_STATE,
  ReferralDetail,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import {
  EXPERT_PROFILE_ROUTE,
  EXPERT_PROJECT_AVAILABILITY_ROUTE
} from "@arbolus-technologies/routes";

import { ClipboardManager } from "../../../classes/ClipboardManager";

interface ExpertActionsParams {
  t: (key: string) => string;
  referral: ReferralDetail;
  expert: ExpertDetail;
  projectId: string;
  isCandidate: boolean;
  clipboardManager: ClipboardManager;
  onEditNote: () => void;
  onRejectExpert: () => void;
  onApproveExpert: () => void;
  setShowResetModal: (value: boolean) => void;
  setShowDeleteModal: (value: boolean) => void;
  onUpdateReferralState: (
    state: UpdateReferralRequest,
    isFastTrack?: boolean
  ) => void;
  setShowReminderModal: (value: boolean) => void;
  handleAvailabilityButtonPressed: () => void;
}

export const buildExpertActions = ({
  t,
  referral,
  expert,
  projectId,
  isCandidate,
  clipboardManager,
  onEditNote,
  onRejectExpert,
  onApproveExpert,
  setShowResetModal,
  setShowDeleteModal,
  onUpdateReferralState,
  setShowReminderModal,
  handleAvailabilityButtonPressed
}: ExpertActionsParams) => {
  const { SHORTLIST, REJECT } = PROJECT_REFERRAL_STATE;
  const { ACCEPT: ACCEPT_STATE, REJECT: REJECT_STATE } = REFERRAL_SUB_STATE;

  const {
    note,
    allowResetApplication,
    fastTrack,
    application,
    status: referralStatus
  } = referral;

  const { id: expertId, doNotContactStatus } = expert;

  const applicationStatus = application.subStatus;
  const isFastTrack = fastTrack.fastTrack;
  const isAdminNoted = Boolean(note?.note);
  const isDnc = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  const isShortlist = referralStatus === SHORTLIST;
  const isRejected = referralStatus === REJECT;

  const isApplicationDeclined = applicationStatus === REJECT_STATE;
  const isApplicationAccepted = applicationStatus === ACCEPT_STATE && !isDnc;

  const showReviewOptions = isCandidate && !isApplicationDeclined && !isDnc;
  const showResetOption = allowResetApplication && !isShortlist && !isDnc;
  const isNotFastTrack = (isCandidate || isShortlist) && !isFastTrack;
  const showFastTrackOption = isNotFastTrack && !isDnc;
  const showSendApplicationReminder =
    !isNotFastTrack && !isRejected && !isApplicationAccepted && !isDnc;

  const actions = [];

  if (showFastTrackOption) {
    actions.push({
      key: "sendApplication",
      label: t("sendApplication"),
      style: { gap: 8 },
      onClick: () => onUpdateReferralState({ fastTrack: true }, true)
    });
  }

  if (showSendApplicationReminder) {
    actions.push({
      key: "sendReminder",
      label: t("sendReminder"),
      style: { gap: 8 },
      onClick: () => setShowReminderModal(true)
    });
  }

  if ((showSendApplicationReminder || isApplicationAccepted) && !isRejected) {
    actions.push({
      key: "editAvailability",
      label: t("editAvailability"),
      style: { gap: 8 },
      onClick: handleAvailabilityButtonPressed
    });
  }

  if (isApplicationAccepted && !isRejected) {
    actions.push({
      key: "shareCalendarLink",
      label: t("shareCalendarLink"),
      style: { gap: 8 },
      onClick: () => {
        clipboardManager.copy(EXPERT_PROJECT_AVAILABILITY_ROUTE(projectId));
      }
    });
  }

  if (
    showFastTrackOption ||
    showSendApplicationReminder ||
    (isApplicationAccepted && !isRejected)
  ) {
    actions.push({ type: "divider" });
  }

  actions.push({
    key: "editProfile",
    label: t("editProfile"),
    style: { gap: 8 },
    onClick: () => {
      window.open(EXPERT_PROFILE_ROUTE(expertId), "_blank");
    }
  });

  if (!isAdminNoted) {
    actions.push({
      key: "addNote",
      label: t("addNote"),
      style: { gap: 8 },
      onClick: onEditNote
    });
  }

  if (showResetOption) {
    actions.push({
      key: "resetApplication",
      label: t("resetApplication"),
      style: { gap: 8 },
      onClick: () => setShowResetModal(true)
    });
  }

  if (showReviewOptions) {
    actions.push(
      ...[
        { type: "divider" },
        {
          key: "approve",
          label: t("approve"),
          style: { gap: 8 },
          onClick: onApproveExpert
        },
        {
          key: "reject",
          label: t("reject"),
          style: { gap: 8 },
          onClick: onRejectExpert
        }
      ]
    );
  }

  if (allowResetApplication) {
    actions.push(
      ...[
        { type: "divider" },
        {
          key: "remove",
          label: t("remove"),
          style: { gap: 8 },
          danger: true,
          onClick: () => setShowDeleteModal(true)
        }
      ]
    );
  }

  return actions;
};
