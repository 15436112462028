import { ColDef, ITooltipParams } from "ag-grid-community";
import i18next from "i18next";
import { AdoptionRenderer } from "../../Components/CellComponents/AdoptionRenderer/AdoptionRenderer";
import { ChurnRiskRenderer } from "../../Components/CellComponents/ChurnRiskRenderer/ChurnRiskRenderer";
import { CompetitorsNamesRenderer } from "../../Components/CellComponents/CompetitorsCompaniesRenderer/CompetitorsCompaniesRenderer";
import { LastUpdateRenderer } from "../../Components/CellComponents/LastUpdateRenderer/LastUpdateRenderer";
import { LogoCompanyNameRenderer } from "../../Components/CellComponents/LogoCompanyNameRenderer/LogoCompanyNameRenderer";
import { NPSCompanyDataRenderer } from "../../Components/CellComponents/NPSRenderer/NPSCompanyDataRenderer";
import { PersonaRenderer } from "../../Components/CellComponents/PersonaRenderer/PersonaRenderer";
import { RenewalRenderer } from "../../Components/CellComponents/RenewalRenderer/RenewalRenderer";
import { SpendRenderer } from "../../Components/CellComponents/SpendRenderer/SpendRenderer";
import { ExportCSVButton } from "../../Components/ExportCSVButton/ExportCSVButton";
import styles from "./CompanyDataTable.module.scss";

const maxWidth = 100;

export const COLUMN_DEFS: ColDef[] = [
  {
    field: "customerCompanyName",
    headerName: i18next.t("companyData:customersCount"),
    cellRenderer: LogoCompanyNameRenderer,
    tooltipValueGetter: ({ data }: ITooltipParams) => data.customerCompanyName
  },
  {
    field: "churnRisk",
    headerName: i18next.t("companyData:churnRisk"),
    cellRenderer: ChurnRiskRenderer,
    maxWidth
  },
  {
    field: "persona",
    headerName: i18next.t("companyData:user"),
    cellRenderer: PersonaRenderer,
    maxWidth
  },
  {
    field: "nps",
    headerName: i18next.t("companyData:nps"),
    cellRenderer: NPSCompanyDataRenderer,
    maxWidth: 80
  },
  {
    field: "spend",
    headerName: i18next.t("companyData:spend"),
    cellRenderer: SpendRenderer,
    maxWidth
  },
  {
    field: "renewalIntent",
    headerName: i18next.t("companyData:renewalIntent"),
    cellRenderer: RenewalRenderer,
    maxWidth
  },
  {
    field: "adoption",
    headerName: i18next.t("companyData:adoption"),
    cellRenderer: AdoptionRenderer,
    width: 140
  },
  {
    field: "competitorsNames",
    headerName: i18next.t("companyData:evaluated"),
    cellRenderer: CompetitorsNamesRenderer,
    flex: 1
  },
  {
    field: "lastUpdate",
    headerComponent: ExportCSVButton,
    headerComponentParams: {
      tableName: i18next.t("companyData:companyData")
    },
    cellRenderer: LastUpdateRenderer,
    flex: 1,
    maxWidth: 180,
    headerClass: styles.lastUpdateHeader,
    pinned: "right"
  }
];
