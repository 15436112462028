import { Button, Dropdown, Flex, Modal } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ProjectService,
  ReferralDetail,
  ToasterService,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import { PROJECT_EXPERT_AVAILABILITY_ROUTE } from "@arbolus-technologies/routes";
import { displayUserName } from "@arbolus-technologies/utils";

import {
  ClipboardManager,
  DefaultClipboardManager
} from "../../../../classes/ClipboardManager";
import ProjectExpertEvents from "../../constants/ProjectExpertEvents";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { buildExpertActions } from "../../expertActionsBuilder";
import styles from "./ExpertInfo.module.scss";

interface HeaderActionsProps {
  expert: ExpertDetail;
  referral: ReferralDetail;
  onAddNote: () => void;
  onReferralRemoval: (referralId: string) => void;
  onRejectExpert: () => void;
  updateReferralState: (
    state: UpdateReferralRequest,
    isFastTrack?: boolean
  ) => void;
  onMoveToAvailabilityTab: () => void;
  projectService?: typeof ProjectService;
  clipboardManager?: ClipboardManager;
  notificationService?: ToasterService;
}

export const HeaderActions: React.FC<HeaderActionsProps> = ({
  referral,
  expert,
  onAddNote,
  onReferralRemoval,
  onRejectExpert,
  updateReferralState,
  onMoveToAvailabilityTab,
  projectService = ProjectService,
  clipboardManager = DefaultClipboardManager,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralActions");
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();

  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const { id: referralId } = referral;
  const { id: expertId, user } = expert;

  const sendApplicationReminder = useCallback(() => {
    setShowReminderModal(false);
    projectService.sendApplicationReminder(projectId, expertId).subscribe(
      () => {
        notificationService.showSuccess(t("applicationReminderSent"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    );
    setShowReminderModal(false);
  }, [projectId, expertId, notificationService, t]);

  const handleAvailabilityButtonPressed = (): void => {
    history.push(PROJECT_EXPERT_AVAILABILITY_ROUTE(projectId, expertId), {
      expertName: displayUserName(
        user ?? { firstName: "", lastName: "", email: "" }
      )
    });
  };

  const handleRemoveReferral = (): void => {
    setShowDeleteModal(false);

    onReferralRemoval(referralId);
  };

  const handleResetApplication = (): void => {
    setShowResetModal(false);

    projectService
      .updateReferralState(projectId, referralId, { reset: true })
      .subscribe(
        (res) => {
          ProjectExpertEvents.resetApplication(referralId, res);
          notificationService.showSuccess(t("resetSuccess"));
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  const expertActions = buildExpertActions({
    t,
    referral,
    expert,
    projectId,
    clipboardManager,
    updateReferralState,
    setShowReminderModal,
    handleAvailabilityButtonPressed,
    onAddNote,
    onRejectExpert,
    setShowResetModal,
    setShowDeleteModal
  });

  const actionWithIcons = expertActions.map((action) => {
    // Dynamically assign icons based on the action key
    switch (action.key) {
      case "sendApplication":
        return { ...action, icon: <AntDIcon name="send" /> };
      case "sendReminder":
        return { ...action, icon: <AntDIcon name="send" /> };
      case "editAvailability":
        return { ...action, icon: <AntDIcon name="edit" /> };
      case "shareCalendarLink":
        return { ...action, icon: <AntDIcon name="link" /> };
      case "editProfile":
        return { ...action, icon: <AntDIcon name="edit" /> };
      case "addNote":
        return { ...action, icon: <AntDIcon name="notes" /> };
      case "resetApplication":
        return { ...action, icon: <AntDIcon name="undo" /> };
      case "approve":
        return {
          ...action,
          icon: (
            <AntDIcon
              name="check_circle_filled"
              color={ARBOLUS_COLORS.bColorSuccess}
            />
          )
        };
      case "reject":
        return {
          ...action,
          icon: (
            <AntDIcon name="cancel_filled" color={ARBOLUS_COLORS.bColorError} />
          )
        };
      case "remove":
        return { ...action, icon: <AntDIcon name="delete" /> };
      default:
        return action;
    }
  });

  return (
    <>
      <Flex gap={16} justify="flex-end">
        <Button type="primary" onClick={onMoveToAvailabilityTab}>
          {t("book")}
        </Button>
        <Dropdown
          // @ts-ignore
          menu={{ items: actionWithIcons }}
          className={styles.dropdown}
        >
          <Button icon={<AntDIcon name="more_horiz" />} />
        </Dropdown>
      </Flex>
      <Modal
        title={t("resend")}
        open={showReminderModal}
        onCancel={() => setShowReminderModal((prev) => !prev)}
        cancelText={t("cancel")}
        onOk={sendApplicationReminder}
        okText={t("confirm")}
      >
        {t("notified")}
      </Modal>
      <Modal
        title={t("removeExpert")}
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal((prev) => !prev)}
        cancelText={t("cancel")}
        onOk={handleRemoveReferral}
        okText={t("delete")}
        okButtonProps={{ type: "primary", danger: true }}
      >
        {t("areYouSure")}
      </Modal>
      <Modal
        title={t("resetApplication")}
        open={showResetModal}
        onCancel={() => setShowResetModal((prev) => !prev)}
        cancelText={t("cancel")}
        onOk={handleResetApplication}
        okText={t("reset")}
      >
        {t("resetApplicationDescription")}
      </Modal>
    </>
  );
};
