import { Observable } from "rxjs";

import {
  REPEATED_ENGAGEMENT_STATUS,
  RepeatedEngagementDetails
} from "@arbolus-technologies/models/common";

import { ENGAGEMENTS_API } from "../constants/api";
import { SuccessResponse } from "../models/api";
import { restService } from "../restService";

export const EngagementService = {
  getRepeatedEngagementDetails: (
    clientId: string,
    expertId: string
  ): Observable<RepeatedEngagementDetails> =>
    restService.delete<RepeatedEngagementDetails>(
      ENGAGEMENTS_API.GET_REPEATED_ENGAGEMENT_DETAILS(clientId, expertId)
    ),
  updateEventRepeatedEngagementStatus: (
    eventId: string,
    comment: string,
    status: REPEATED_ENGAGEMENT_STATUS
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      ENGAGEMENTS_API.UPDATE_EVENT_REPEATED_ENGAGEMENT_STATUS(eventId),
      { comment, status }
    )
};
