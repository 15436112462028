import { Observable } from "rxjs";

import { TRANSACTIONS_API } from "../constants/api";
import {
  TransactionStatFilter,
  TransactionStatus
} from "../enums/transactionEnums";
import {
  ApiPaginatedRequest,
  ApiPaginatedResponse,
  ListResponse,
  ObjectResponse,
  Response,
  SuccessResponse
} from "../models/api";
import {
  BaseCreateTransaction,
  BaseUpdateTransaction,
  GetTransactionBusinessEntityRequest,
  GetTransactionProjectRequest,
  ListTransaction,
  TransactionBusinessEntity,
  TransactionDetails,
  TransactionProject,
  TransactionStats
} from "../models/transactions";
import { restService } from "../restService";

export interface GetTransactionsParams extends ApiPaginatedRequest {
  expertSearchTerm?: string;
  clientSearchTerm?: string;
  projectSearchTerm?: string;
  invoiceSearchTerm?: string;
  ownerSearchTerm?: string;
  projectId?: string;
  expertPaymentStatus?: string;
  transactionsStatsFilter?: TransactionStatFilter;
}

export const TransactionsService = {
  getTransactions: (
    params: GetTransactionsParams
  ): Observable<ApiPaginatedResponse<ListTransaction>> =>
    restService.get<ApiPaginatedResponse<ListTransaction>>(
      TRANSACTIONS_API.GET_TRANSACTIONS(),
      { ...params }
    ),
  getTransactionsStats: (): Observable<TransactionStats> =>
    restService.get<TransactionStats>(
      TRANSACTIONS_API.GET_TRANSACTIONS_STATS()
    ),
  updateTransactionsStatus: (
    transactionIds: string[],
    transactionStatus: TransactionStatus
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      TRANSACTIONS_API.UPDATE_TRANSACTIONS_STATUS(),
      {
        transactionIds,
        transactionStatus
      }
    ),
  createTransaction: (
    request: BaseCreateTransaction
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(TRANSACTIONS_API.CREATE_TRANSACTION(), {
      ...request
    }),
  editTransaction: (
    transactionId: string,
    request: BaseUpdateTransaction
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      TRANSACTIONS_API.EDIT_TRANSACTION(transactionId),
      {
        ...request
      }
    ),
  deleteTransaction: (transactionId: string): Observable<SuccessResponse> =>
    restService.delete<SuccessResponse>(
      TRANSACTIONS_API.DELETE_TRANSACTION(transactionId)
    ),
  getTransaction: (transactionId: string): Observable<TransactionDetails> =>
    restService.get<TransactionDetails>(
      TRANSACTIONS_API.GET_TRANSACTION(transactionId)
    ),
  getTransactionProjects: (
    params: GetTransactionProjectRequest
  ): Observable<ApiPaginatedResponse<TransactionProject>> =>
    restService.get<ApiPaginatedResponse<TransactionProject>>(
      TRANSACTIONS_API.GET_TRANSACTION_PROJECTS(),
      {
        ...params
      }
    ),
  getTransactionBusinessEntities: (
    params: GetTransactionBusinessEntityRequest
  ): Observable<ApiPaginatedResponse<TransactionBusinessEntity>> =>
    restService.get<ApiPaginatedResponse<TransactionBusinessEntity>>(
      TRANSACTIONS_API.GET_TRANSACTION_BUSINESS_ENTITIES(),
      {
        ...params
      }
    ),
  putInvoice: (transactionIds: string[]): Observable<ObjectResponse<string>> =>
    restService.put<ObjectResponse<string>>(TRANSACTIONS_API.INVOICE(), {
      transactionIds
    }),
  invoiceProjects: (
    ids: string[],
    includeCurrentMonth: boolean
  ): Observable<ListResponse<Response<string>>> =>
    restService.put<ListResponse<Response<string>>>(
      TRANSACTIONS_API.INVOICE_PROJECTS(),
      {
        ids
      },
      {
        includeCurrentMonth
      }
    ),
  invoiceBusinessEntities: (
    ids: string[],
    includeCurrentMonth: boolean
  ): Observable<ListResponse<Response<string>>> =>
    restService.put<ListResponse<Response<string>>>(
      TRANSACTIONS_API.INVOICE_BUSINESS_ENTITIES(),
      { ids },
      { includeCurrentMonth }
    ),

  modifyOwner: (
    ownerUserId: string,
    transactionsIds: string[]
  ): Observable<SuccessResponse> =>
    restService.patch<SuccessResponse>(
      TRANSACTIONS_API.MODIFY_OWNER(ownerUserId),
      { transactionsIds }
    )
};
