import { RouterState } from "connected-react-router";
import { OutputSelector, createSelector } from "reselect";

import { APP_DEFAULT_TIMEZONE } from "../../../../constants/app";
import { EventsDuration } from "../../../../models/event";
import { Member } from "../../../../models/project";
import { User } from "../../../../models/user";
import { StatusReferral } from "../../../../models/view/candidatePicker";
import { CreateEventRouterData } from "../../../../models/view/event";
import { ProjectMember } from "../../../../models/view/project";
import { AppState } from "../../../../store/reducers";
import { ProjectReducerState, initialState } from "../reducer";

const projectReducerStateSelector = (state: AppState): ProjectReducerState =>
  state.project || initialState;
const routerStateSelector = (state: AppState): RouterState => state.router;

const projectNameSelector = (): OutputSelector<
  AppState,
  string,
  (res: ProjectReducerState) => string
> =>
  createSelector<AppState, ProjectReducerState, string>(
    projectReducerStateSelector,
    (projectReducerState) =>
      projectReducerState.projectName ? projectReducerState.projectName : ""
  );

const projectIdSelector = (): OutputSelector<
  AppState,
  string,
  (res: ProjectReducerState) => string
> =>
  createSelector<AppState, ProjectReducerState, string>(
    projectReducerStateSelector,
    (projectReducerState) =>
      projectReducerState.projectId ? projectReducerState.projectId : ""
  );

const projectHasComplianceSelector = (): OutputSelector<
  AppState,
  boolean,
  (res: ProjectReducerState) => boolean
> =>
  createSelector<AppState, ProjectReducerState, boolean>(
    projectReducerStateSelector,
    (projectReducerState) => projectReducerState.hasCompliance
  );

const projectLoadingSelector = (): OutputSelector<
  AppState,
  boolean,
  (res: ProjectReducerState) => boolean
> =>
  createSelector<AppState, ProjectReducerState, boolean>(
    projectReducerStateSelector,
    ({ isProjectLoading, isClientDataLoading }) =>
      isProjectLoading || isClientDataLoading
  );

const projectReferralsSelector = (): OutputSelector<
  AppState,
  StatusReferral[],
  (res: ProjectReducerState) => StatusReferral[]
> =>
  createSelector<AppState, ProjectReducerState, StatusReferral[]>(
    projectReducerStateSelector,
    (projectReducerState) => projectReducerState.referrals
  );

const projectEventsDurationSelector = (): OutputSelector<
  AppState,
  EventsDuration,
  (res: ProjectReducerState) => EventsDuration
> =>
  createSelector<AppState, ProjectReducerState, EventsDuration>(
    projectReducerStateSelector,
    (projectReducerState) => projectReducerState.eventsDuration
  );

const projectMembersSelector = (): OutputSelector<
  AppState,
  Member[],
  (res: ProjectReducerState) => Member[]
> =>
  createSelector<AppState, ProjectReducerState, Member[]>(
    projectReducerStateSelector,
    (projectReducerState) =>
      projectReducerState.members.filter((m) => !m.deleted)
  );

const createEventInitDataSelector = (): OutputSelector<
  AppState,
  CreateEventRouterData,
  (res: RouterState) => CreateEventRouterData
> =>
  createSelector<AppState, RouterState, CreateEventRouterData>(
    routerStateSelector,
    (routerReducerState) =>
      routerReducerState.location.state as CreateEventRouterData
  );

const projectTeamMembersSelector = (): OutputSelector<
  AppState,
  ProjectMember[],
  (res: ProjectReducerState) => ProjectMember[]
> =>
  createSelector<AppState, ProjectReducerState, ProjectMember[]>(
    projectReducerStateSelector,
    (projectReducerState) =>
      projectReducerState.members
        .filter((m) => m.deleted !== true)
        .map(
          ({
            user: {
              email,
              lastName,
              firstName,
              profileImageUrl,
              emailConfirmed,
              title,
              isCiqManager,
              clientId
            },
            id,
            userId
          }) => ({
            email,
            firstName: firstName!,
            lastName: lastName!,
            profileImageUrl,
            emailConfirmed,
            memberId: id,
            userId,
            title,
            isCiqManager,
            clientId
          })
        )
  );

const projectTimezoneSelector = (): OutputSelector<
  AppState,
  string,
  (res: ProjectReducerState) => string
> =>
  createSelector<AppState, ProjectReducerState, string>(
    projectReducerStateSelector,
    (projectReducerState) =>
      projectReducerState.timezone || APP_DEFAULT_TIMEZONE
  );

const defaultEventGuestsSelector = (): OutputSelector<
  AppState,
  User[],
  (res: ProjectReducerState) => User[]
> =>
  createSelector<AppState, ProjectReducerState, User[]>(
    projectReducerStateSelector,
    (projectReducerState) => projectReducerState.defaultEventGuests
  );

export {
  createEventInitDataSelector,
  projectEventsDurationSelector,
  projectIdSelector,
  projectLoadingSelector,
  projectMembersSelector,
  projectNameSelector,
  projectReferralsSelector,
  projectTeamMembersSelector,
  projectHasComplianceSelector,
  projectTimezoneSelector,
  defaultEventGuestsSelector
};
