export enum SurveyQuestionType {
  SingleChoiceRadio,
  MoneyTextInput,
  ScoreSelect
}

export interface SurveyQuestion {
  title: string;
  fieldName: string;
  type: SurveyQuestionType;
  options?: { label: string; value: string }[];
}

export interface SurveyAnswerModel {
  answerStatus: string;
  competitorsNames: string[];
  expertCurrentCompanyName: string;
  expertName: string;
  expertId: string;
  id: string;
  implementationStage: string | null;
  nps: number | null;
  persona: string | null;
  renewalIntent: string | null;
  spend: number | null;
  modified: string;
  vendorCompanyName: string;
}
