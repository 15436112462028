import { ArbolusFeatureFlags } from "@arbolus-technologies/models/common";

export interface Country {
  id: string;
  name: string;
  twoLetterCode: string;
  threeLetterCode: string;
  sortOrder: number;
}

export interface Industry {
  id: string;
  name: string;
  naicsId: number;
  parentId?: string;
}

export interface Currency {
  name: string;
  isoCurrencyCode: string;
  symbol: string;
}

export interface AgreementTerm {
  termTitle: string;
  termText: string;
}

export interface EngagementAgreement {
  id: string;
  headerTitle: string;
  headerText: string;
  footerTitle: string;
  footerText: string;
  agreementTerms: AgreementTerm[];
}

export enum AgreementType {
  CLIENT = "ClientAgreement",
  EXPERT = "ExpertAgreement"
}

export interface UserPermissions {
  featureFlags: ArbolusFeatureFlags;
  featureFlagsDefaults: ArbolusFeatureFlags;
}
