import { Button, Divider, Flex, Typography } from "antd";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";

import { AuthPageHeader } from "../AuthPageHeader/AuthPageHeader";
import { LinkedInRegisterButton } from "../LinkedInRegisterButton/LinkedInRegisterButton";

export function EmailOrLI({
  onLI,
  onEmail,
  footer
}: {
  onLI: VoidFunction;
  onEmail: MouseEventHandler;
  footer?: React.ReactNode;
}) {
  const { t } = useTranslation("register");

  return (
    <Flex vertical gap={24} align="center">
      <AuthPageHeader title={t("createAccountHeader")} />
      <LinkedInRegisterButton onClick={onLI} />

      <Typography.Text type="secondary">{t("alternative")}</Typography.Text>

      <Button
        size="large"
        block
        onClick={onEmail}
        icon={<AntDIcon name="mail" />}
      >
        {t("emailRegisterButton")}
      </Button>

      {footer && (
        <>
          <Divider />
          <Typography.Text type="secondary">{footer}</Typography.Text>
        </>
      )}
    </Flex>
  );
}
