import { Card } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ExpertWorkHistory } from "@arbolus-technologies/models/common";
import {
  FILTERS_SEARCH_MATCHES,
  MatchedCompaniesWithIndustry
} from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";

interface FilterMatchProps {
  searchMatchColumns: string[];
  workHistories: ExpertWorkHistory[];
}

export const FilterMatch: React.FC<FilterMatchProps> = ({
  searchMatchColumns,
  workHistories
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");

  const industriesFilter = useSelector(
    ProjectNxSelector.discoverFilters()
  ).industries;

  const {
    FILTER_PROJECTS,
    FILTER_COMPANIES,
    FILTER_WORK_HISTORY_LOCATIONS,
    FILTER_EXPERT_CURRENT_LOCATIONS,
    FILTER_PROJECTS_ANSWERS,
    FILTER_PROJECTS_NOTES,
    FILTER_USER_TITLE,
    FILTER_WORK_HISTORIES_COMPANY,
    FILTER_WORK_HISTORIES_TITLE,
    FILTER_SEARCH_TERM,
    FILTER_CUSTOMERS,
    FILTER_INDUSTRIES
  } = FILTERS_SEARCH_MATCHES;

  const filterTranslations = {
    [FILTER_SEARCH_TERM]: t("filterSearchTerm"),
    [FILTER_PROJECTS]: t("filterProjects"),
    [FILTER_COMPANIES]: t("filterCompanies"),
    [FILTER_WORK_HISTORY_LOCATIONS]: t("filterWorkHistoryLocations"),
    [FILTER_EXPERT_CURRENT_LOCATIONS]: t("filterExpertCurrentLocation"),
    [FILTER_PROJECTS_ANSWERS]: t("filterScreeningAnswers"),
    [FILTER_PROJECTS_NOTES]: t("filterProjectNotes"),
    [FILTER_USER_TITLE]: t("filterHeadline"),
    [FILTER_WORK_HISTORIES_COMPANY]: t("filterCompanyName"),
    [FILTER_WORK_HISTORIES_TITLE]: t("filterWorkHistoryJobTitle"),
    [FILTER_CUSTOMERS]: "",
    [FILTER_INDUSTRIES]: ""
  };

  const companiesWithMatchedIndustries = industriesFilter.reduce(
    (result, industry) => {
      const matchedCompanies = workHistories.reduce<string[]>(
        (companies, history) => {
          if (history.industryId === industry.value && history.companyName) {
            companies.push(history.companyName);
          }
          return companies;
        },
        []
      );

      if (matchedCompanies.length > 0) {
        result.push({ industry: industry.label, companies: matchedCompanies });
      }
      return result;
    },
    [] as MatchedCompaniesWithIndustry[]
  );

  const buildFiltersText = (): string => {
    const filterTextArray: string[] = [];
    companiesWithMatchedIndustries.forEach(({ industry, companies }) => {
      filterTextArray.push(
        t("industryRelatedTo", {
          industry,
          companies: companies.join(", ")
        })
      );
    });

    searchMatchColumns.forEach((searchMatch) => {
      if (
        searchMatch !== FILTER_PROJECTS_ANSWERS &&
        searchMatch !== FILTER_PROJECTS_NOTES &&
        searchMatch !== FILTER_CUSTOMERS &&
        searchMatch !== FILTER_INDUSTRIES
      ) {
        filterTextArray.push(
          filterTranslations[searchMatch as FILTERS_SEARCH_MATCHES]
        );
      }
    });
    if (filterTextArray.length === 0) {
      return t("noMatchesFound");
    }
    return filterTextArray.join(", ");
  };

  return (
    <Card title={t("filterMatch")} size="small">
      <DangerouslySetInnerHTML text={buildFiltersText()} />
    </Card>
  );
};
