import { OutputSelector, createSelector } from "reselect";

import {
  Angle,
  AngleDraftResponse,
  CIQError,
  Document,
  EngagementAgreement,
  ErrorResponse,
  IChatMessage,
  Project,
  ProjectNx,
  User
} from "@arbolus-technologies/api";
import {
  PaginatedStoreHasNextPage,
  SelectOption
} from "@arbolus-technologies/models/common";
import {
  OnlineMembersByChat,
  ProjectCreationStep
} from "@arbolus-technologies/models/project";

import {
  DashboardAngleFilter,
  ProjectNxAppState,
  ProjectNxReducerState
} from "../models/definitions";
import { ProjectStateSelector } from "./ProjectStateSelector";

export const stepSelector = (): OutputSelector<
  ProjectNxAppState,
  ProjectCreationStep,
  (res: ProjectNxReducerState) => ProjectCreationStep
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, ProjectCreationStep>(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.projectStepState
  );

export const angleColors = (): OutputSelector<
  ProjectNxAppState,
  string[],
  (res: ProjectNxReducerState) => string[]
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, string[]>(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.angleColors
  );

export const projectData = (): OutputSelector<
  ProjectNxAppState,
  Project | null,
  (res: ProjectNxReducerState) => Project | null
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, Project | null>(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.projectData
  );

export const projectName = (): OutputSelector<
  ProjectNxAppState,
  string,
  (res: ProjectNxReducerState) => string
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, string>(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.projectData?.name ?? ""
  );

export const isChatEnabled = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (projectReducerState) =>
      projectReducerState.projectData?.enableWorkspaces ?? false
  );

export const projectDraft = (): OutputSelector<
  ProjectNxAppState,
  ProjectNx,
  (res: ProjectNxReducerState) => ProjectNx
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, ProjectNx>(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.projectDraft
  );

export const engagementAgreement = (): OutputSelector<
  ProjectNxAppState,
  EngagementAgreement | null,
  (res: ProjectNxReducerState) => EngagementAgreement | null
> =>
  createSelector<
    ProjectNxAppState,
    ProjectNxReducerState,
    EngagementAgreement | null
  >(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.engagementAgreement
  );

export const isEngagementAgreementsLoading = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.isEngagementAgreementsLoading
  );

export const angleData = (): OutputSelector<
  ProjectNxAppState,
  Angle,
  (res: ProjectNxReducerState) => Angle
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, Angle>(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.selectedAngle
  );

export const isProjectDraftLoading = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (projectReducerState) => projectReducerState.isProjectDraftLoading
  );

export const businessEntities = (): OutputSelector<
  ProjectNxAppState,
  SelectOption[] | null,
  (res: ProjectNxReducerState) => SelectOption[] | null
> =>
  createSelector<
    ProjectNxAppState,
    ProjectNxReducerState,
    SelectOption[] | null
  >(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.businessEntities
  );

export const anglesList = (): OutputSelector<
  ProjectNxAppState,
  AngleDraftResponse[] | null,
  (res: ProjectNxReducerState) => AngleDraftResponse[] | null
> =>
  createSelector<
    ProjectNxAppState,
    ProjectNxReducerState,
    AngleDraftResponse[] | null
  >(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.anglesList
  );

export const isLoadingAnglesList = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.isLoadingAnglesList
  );

export const isAngleInEditMode = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.selectedAngle.isEdit
  );

export const createAngleError = (): OutputSelector<
  ProjectNxAppState,
  ErrorResponse<CIQError>,
  (res: ProjectNxReducerState) => ErrorResponse<CIQError>
> =>
  createSelector<
    ProjectNxAppState,
    ProjectNxReducerState,
    ErrorResponse<CIQError>
  >(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.createAngleError
  );

export const isLoadingProjectData = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.isLoadingProjectData
  );

export const isCreatingProjectLoading = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.isCreatingProjectLoading
  );

export const forceReloadProjects = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.forceReloadProjects
  );

export const projectManagersList = (): OutputSelector<
  ProjectNxAppState,
  User[],
  (res: ProjectNxReducerState) => User[]
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, User[]>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.projectManagers
  );

export const primaryProjectLead = (): OutputSelector<
  ProjectNxAppState,
  User | undefined,
  (res: ProjectNxReducerState) => User | undefined
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, User | undefined>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.primaryProjectLead
  );

export const secondaryProjectLead = (): OutputSelector<
  ProjectNxAppState,
  User | undefined,
  (res: ProjectNxReducerState) => User | undefined
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, User | undefined>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.secondaryProjectLead
  );

export const projectClientId = (): OutputSelector<
  ProjectNxAppState,
  string,
  (res: ProjectNxReducerState) => string
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, string>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.clientId ?? ""
  );

export const projectClientName = (): OutputSelector<
  ProjectNxAppState,
  string,
  (res: ProjectNxReducerState) => string
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, string>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.projectData?.client.name ?? ""
  );

export const isCreatingSimplifiedProject = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.isCreatingSimplifiedProject
  );

export const dashboardAngleFilter = (): OutputSelector<
  ProjectNxAppState,
  DashboardAngleFilter | undefined,
  (res: ProjectNxReducerState) => DashboardAngleFilter | undefined
> =>
  createSelector<
    ProjectNxAppState,
    ProjectNxReducerState,
    DashboardAngleFilter | undefined
  >(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.dashboardAngleFilter
  );

// CHAT MESSAGES

export const chatMessages = (): OutputSelector<
  ProjectNxAppState,
  IChatMessage[] | null,
  (res: ProjectNxReducerState) => IChatMessage[] | null
> =>
  createSelector<
    ProjectNxAppState,
    ProjectNxReducerState,
    IChatMessage[] | null
  >(
    ProjectStateSelector,
    (ProjectReducerState) =>
      ProjectReducerState.chatMessages.store?.items ?? null
  );

export const chatMessagesHasNextPage = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) =>
      PaginatedStoreHasNextPage(ProjectReducerState.chatMessages.store)
  );

export const chatMessagesLoading = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.chatMessages.isLoading
  );

export const isSendingMessage = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.isSendingMessage
  );

export const isGetMessagesInitialFetch = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.isGetMessagesInitialFetch
  );

// CHAT FILES

export const chatFiles = (): OutputSelector<
  ProjectNxAppState,
  Document[] | null,
  (res: ProjectNxReducerState) => Document[] | null
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, Document[] | null>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.chatFiles.store?.items ?? null
  );

export const chatFilesHasNextPage = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) =>
      PaginatedStoreHasNextPage(ProjectReducerState.chatFiles.store)
  );

export const chatFilesIsLoading = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.chatFiles.isLoading
  );

export const selectedFilesToShare = (): OutputSelector<
  ProjectNxAppState,
  string[],
  (res: ProjectNxReducerState) => string[]
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, string[]>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.selectedFilesToShare
  );

export const isSharingFiles = (): OutputSelector<
  ProjectNxAppState,
  boolean,
  (res: ProjectNxReducerState) => boolean
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, boolean>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.isSharingFiles
  );

export const displayTimezoneId = (): OutputSelector<
  ProjectNxAppState,
  string,
  (res: ProjectNxReducerState) => string
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, string>(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.displayTimezoneId
  );

export const onlineMembersByChat = (): OutputSelector<
  ProjectNxAppState,
  OnlineMembersByChat | null,
  (res: ProjectNxReducerState) => OnlineMembersByChat | null
> =>
  createSelector<
    ProjectNxAppState,
    ProjectNxReducerState,
    OnlineMembersByChat | null
  >(
    ProjectStateSelector,
    (ProjectReducerState) => ProjectReducerState.onlineMembersByChat
  );

export const projectBusinessEntityId = (): OutputSelector<
  ProjectNxAppState,
  string,
  (res: ProjectNxReducerState) => string
> =>
  createSelector<ProjectNxAppState, ProjectNxReducerState, string>(
    ProjectStateSelector,
    (ProjectReducerState) =>
      ProjectReducerState.projectData?.businessEntity?.id ?? ""
  );

export * from "./ExpertDiscoverSelector";
export * from "./ReferralSelector";
