import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { getFileExtension } from "@arbolus-technologies/utils";
import { Button, Card, Flex, Typography } from "antd";
import clsx from "clsx";
import React from "react";
import { AntDIcon } from "../Icon/AntDIcon";
import styles from "./AntDDownloadButton.module.scss";

interface AntDDownloadButtonProps {
  fileName: string;
  handleOnClick: () => void;
}

enum DocumentType {
  PDF = "pdf"
}

export const AntDDownloadButton: React.FC<AntDDownloadButtonProps> = ({
  fileName,
  handleOnClick
}) => {
  const { Text } = Typography;

  const fileType = getFileExtension(fileName);

  return (
    <Card
      style={{ width: "100%" }}
      styles={{
        body: {
          padding: "4px 6px"
        }
      }}
    >
      <Flex align="center" gap={8}>
        <div
          className={clsx(
            styles.roundSquare,
            fileType === DocumentType.PDF ? styles.red : styles.gray
          )}
        >
          <AntDIcon
            name={
              fileType === DocumentType.PDF ? "picture_as_pdf" : "attach_file"
            }
            color={ARBOLUS_COLORS.bColorBaseWhite}
          />
        </div>
        <Text className={styles.filename}>{fileName}</Text>
        <Button
          type="default"
          style={{ marginLeft: "auto" }}
          icon={<AntDIcon name="file_download" />}
          className={styles.roundSquare}
          onClick={handleOnClick}
        />
      </Flex>
    </Card>
  );
};
