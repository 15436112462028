import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  BookedReferral,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";
import { LinkButton } from "@arbolus-technologies/ui/components";

import { PROJECT_CREATE_EVENT_ROUTE } from "@arbolus-technologies/routes";
import { ReferralGridContext } from "../../Models/ReferralsTable";

export const FollowUpRenderer: React.FC<
  ICellRendererParams<BookedReferral, never, ReferralGridContext>
> = ({ data, context }) => {
  const { t } = useTranslation("referrals");

  if (!data) {
    return null;
  }

  return (
    <LinkButton
      type="tertiary"
      endIcon="chevron_right"
      text={t("followUp")}
      to={PROJECT_CREATE_EVENT_ROUTE(
        context.project.id,
        `referralId=${data.id}`
      )}
      disabled={data.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC}
    />
  );
};
