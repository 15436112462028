import { Badge, Button, Flex } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  PROJECT_REFERRAL_STATE,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

interface StatusesProps {
  applicationStatus: REFERRAL_SUB_STATE;
  complianceStatus: REFERRAL_COMPLIANCE_STATE;
  referralStatus: PROJECT_REFERRAL_STATE;
  reviewStatus: REFERRAL_SUB_STATE;
  fastTrack: boolean;
  complianceRequired: boolean;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  onRejectExpert: () => void;
  updateReferralState: (state: UpdateReferralRequest) => void;
}

export const Statuses: React.FC<StatusesProps> = ({
  applicationStatus,
  complianceStatus,
  referralStatus,
  reviewStatus,
  fastTrack,
  complianceRequired,
  doNotContactStatus,
  onRejectExpert,
  updateReferralState
}) => {
  const { t } = useTranslation("referralStatuses");

  const { REJECT, ACCEPT, PENDING } = REFERRAL_SUB_STATE;
  const {
    ACCEPT: ACCEPT_STATE,
    REJECT: REJECT_STATE,
    PENDING: PENDING_STATE
  } = REFERRAL_COMPLIANCE_STATE;

  const isDnc = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  const isApplicationAccepted = applicationStatus === ACCEPT;
  const isApplicationRejected = applicationStatus === REJECT;
  const isApplicationSent = applicationStatus === PENDING && fastTrack;
  const isApplicationNotSent = applicationStatus === PENDING && !fastTrack;

  const isComplianceAccepted = complianceStatus === ACCEPT_STATE;
  const isComplianceRejected = complianceStatus === REJECT_STATE;
  const isCompliancePending = complianceStatus === PENDING_STATE;

  const isApproved = reviewStatus === ACCEPT;
  const isRejected = reviewStatus === REJECT;
  const isPending = reviewStatus === PENDING;

  const isCandidate = referralStatus === PROJECT_REFERRAL_STATE.CANDIDATE;
  const showReviewOptions = isCandidate && !isApplicationRejected && !isDnc;

  if (isApplicationRejected) {
    return (
      <Badge
        color={ARBOLUS_COLORS.bColorError}
        text={t("applicationDeclined")}
      />
    );
  }

  return (
    <Flex justify="space-between">
      <Flex gap={12} align="center">
        {isApplicationNotSent && (
          <Badge
            color={ARBOLUS_COLORS.colorTextPlaceholder}
            text={t("applicationNotSent")}
          />
        )}
        {isApplicationSent && (
          <Badge
            color={ARBOLUS_COLORS.bColorWarning}
            text={t("applicationPending")}
          />
        )}
        {isApplicationAccepted && (
          <Badge
            color={ARBOLUS_COLORS.bColorSuccess}
            text={t("applicationCompleted")}
          />
        )}
        <AntDIcon name="chevronRight" />
        {complianceRequired && (
          <>
            {isCompliancePending && (
              <Badge
                color={ARBOLUS_COLORS.bColorWarning}
                text={t("pendingCompliance")}
              />
            )}
            {isComplianceAccepted && (
              <Badge
                color={ARBOLUS_COLORS.bColorSuccess}
                text={t("compliancePassed")}
              />
            )}
            {isComplianceRejected && (
              <Badge
                color={ARBOLUS_COLORS.bColorError}
                text={t("complianceFailed")}
              />
            )}
            <AntDIcon name="chevronRight" />
          </>
        )}
        {isPending && (
          <Badge
            color={ARBOLUS_COLORS.bColorWarning}
            text={t("pendingApproval")}
          />
        )}
        {isApproved && (
          <Badge color={ARBOLUS_COLORS.bColorSuccess} text={t("approved")} />
        )}
        {isRejected && (
          <Badge color={ARBOLUS_COLORS.bColorError} text={t("rejected")} />
        )}
      </Flex>
      {showReviewOptions && (
        <Flex gap={16}>
          <Button
            icon={<AntDIcon name="close" />}
            type="primary"
            danger
            onClick={onRejectExpert}
          />
          <Button
            icon={<AntDIcon name="check" />}
            type="primary"
            onClick={() => updateReferralState({ review: ACCEPT_STATE })}
            style={{ backgroundColor: ARBOLUS_COLORS.bColorSuccess }}
          />
        </Flex>
      )}
    </Flex>
  );
};
