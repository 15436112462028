import clsx from "clsx";
import { useField } from "formik";
import React from "react";
import { Button, FormGroup } from "reactstrap";

import {
  DangerouslySetInnerHTML,
  ScreeningQuestionConstraints,
  TiptapEditorMemoized
} from "@arbolus-technologies/ui/components";

interface QuestionItemProps {
  placeholder: string;
  label: string;
  name: string;
  value: string;
  onRemove?: () => void;
  onAnswersChange: (text: string) => void;
}

const QuestionItem: React.FC<QuestionItemProps> = ({
  label,
  name,
  placeholder,
  value,
  onRemove,
  onAnswersChange
}: QuestionItemProps) => {
  const [, questionFieldMeta] = useField<string>(name);

  return (
    <>
      {onRemove && (
        <Button
          className="question-delete"
          type="button"
          color="danger"
          size="sm"
          onClick={onRemove}
        >
          <span className="ciq-icon ciq-trash" />
        </Button>
      )}

      <FormGroup
        className={clsx({
          "is-invalid": questionFieldMeta.error && questionFieldMeta.touched
        })}
      >
        <DangerouslySetInnerHTML text={label} />
        <TiptapEditorMemoized
          initialEditorState={value}
          placeholder={placeholder}
          hasError={!!questionFieldMeta.error && questionFieldMeta.touched}
          onChange={onAnswersChange}
          maxLength={ScreeningQuestionConstraints.MAX_ANSWER_LENGTH}
        />
        {questionFieldMeta.error && questionFieldMeta.touched && (
          <div className="invalid-feedback">{questionFieldMeta.error}</div>
        )}
      </FormGroup>
    </>
  );
};

export default QuestionItem;
