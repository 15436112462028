import { Button, Flex, Typography } from "antd";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ExpertsListPageTab } from "@arbolus-technologies/api";
import {
  OpenExpertProfileParams,
  StatusReferral
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SlidePanel } from "../../SlidePanel/SlidePanel";
import { ReferralsTableExpertProfile } from "./components/ReferralsTableExpertProfile";

import styles from "./ReferralsTableExpertProfileSidePanel.module.scss";

const { Text } = Typography;

interface LocationState {
  referralState?: ExpertsListPageTab;
  reviewExperts?: string;
  showComplianceTab?: string;
}

interface ReferralsTableExpertProfileSidePanelProps {
  inReviewReferralsList?: StatusReferral[];
  onReferralRemoval?: (referralId: string) => void;
}

export const ReferralsTableExpertProfileSidePanel: React.FC<
  ReferralsTableExpertProfileSidePanelProps
> = ({ inReviewReferralsList = [], onReferralRemoval }) => {
  const { t } = useTranslation("referralsTableExpertProfileSidePanel");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { projectId } = useParams<{ projectId: string }>();

  const [panelInfo, setPanelInfo] = useState<OpenExpertProfileParams>();
  const [currentEvaluationIndex, setCurrentEvaluationIndex] = useState(0);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const panelData = useSelector(
    PanelSelector.panelData<OpenExpertProfileParams | undefined>(
      PanelId.ReferralExpertPanel
    )
  );

  const { referralState, reviewExperts, showComplianceTab }: LocationState =
    queryString.parse(window.location.search);
  const isReviewPage = reviewExperts === "true";

  useEffect(() => {
    if (panelData) {
      setPanelInfo(panelData);
    }
  }, [panelData]);

  useEffect(() => {
    if (
      isReviewPage &&
      inReviewReferralsList &&
      inReviewReferralsList.length > 0 &&
      currentEvaluationIndex >= 0
    ) {
      const currentReferral = inReviewReferralsList[currentEvaluationIndex];
      setPanelInfo({
        projectId,
        expertId: currentReferral.expertId,
        referralId: currentReferral.referralId
      });
    }
  }, [currentEvaluationIndex]);

  const handleClosePanel = () => {
    if (isAdmin) {
      history.replace(location.pathname);
    } else {
      const newUrl = `${location.pathname}?referralState=${referralState}`;
      history.replace(newUrl);
    }
    setCurrentEvaluationIndex(0);
    dispatch(PanelStoreActions.closePanel(PanelId.ReferralExpertPanel));
  };

  return (
    <SlidePanel
      panelId={PanelId.ReferralExpertPanel}
      width={SIDE_PANEL_SIZE._720}
      customCloseRequest={handleClosePanel}
      classnames={{ overlay: styles.fullWidthTitle }}
      title={
        <Flex
          justify="space-between"
          align="center"
          style={{ maxWidth: "100%" }}
        >
          <Text>{t("profile")}</Text>
          {isReviewPage &&
            inReviewReferralsList &&
            inReviewReferralsList.length > 0 && (
              <Flex gap={8}>
                <Button
                  type="link"
                  icon={<AntDIcon name="chevronLeft" />}
                  disabled={currentEvaluationIndex === 0}
                  onClick={() => setCurrentEvaluationIndex((prev) => prev - 1)}
                >
                  {t("prev")}
                </Button>
                <Button
                  type="link"
                  icon={<AntDIcon name="chevronRight" />}
                  iconPosition="end"
                  disabled={
                    currentEvaluationIndex === inReviewReferralsList.length - 1
                  }
                  onClick={() => setCurrentEvaluationIndex((prev) => prev + 1)}
                >
                  {t("next")}
                </Button>
              </Flex>
            )}
        </Flex>
      }
    >
      {panelInfo && (
        <ReferralsTableExpertProfile
          panelInfo={panelInfo}
          showComplianceTab={showComplianceTab === "true"}
          onClosePanel={handleClosePanel}
          onReferralRemoval={onReferralRemoval}
          onMoveToNextExpert={() =>
            setCurrentEvaluationIndex((prev) => prev + 1)
          }
        />
      )}
    </SlidePanel>
  );
};
