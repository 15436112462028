import React from "react";

import { ComplianceWarningModal } from "@arbolus-technologies/features/common";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { Flex, Spin } from "antd";
import { useFeature } from "flagged";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { EventForm } from "../../Components/EventForm/EventForm";
import { useEvent } from "../../Hooks/Event/useEvent";

export const EventPage: React.FC = () => {
  const { t } = useTranslation("newEventPage");
  const history = useHistory();
  const location = useLocation();

  const { eventId } = useParams<{
    eventId?: string;
  }>();

  const queryParams = new URLSearchParams(location.search);
  const referralId = queryParams.get("referralId");
  const workspaceId = queryParams.get("workspaceId");

  const eventCreationGatekeepingFeatureFlag = useFeature(
    FEATURE_FLAGS.EventCreationGatekeeping
  );

  const {
    form,
    eventDetails,
    isLoading,
    isSaving,
    isEditMode,
    isComplianceWarningModalOpen,
    handleSubmit,
    handleCancelComplianceWarningModal,
    handleConfirmComplianceWarningModal,
    handleComplianceBeforeSubmit,
    onFinishFailed,
    project,
    currentBasicUser,
    getDefaultEventGuests,
    getDefaultTimezone,
    referral
  } = useEvent({
    eventId,
    referralId,
    workspaceId
  });

  if (!project || isLoading || !currentBasicUser) {
    return (
      <Flex align="center" justify="center" style={{ height: "100%" }}>
        <Spin />
      </Flex>
    );
  }

  return (
    <Flex
      vertical
      style={{
        backgroundColor: ARBOLUS_COLORS.colorBgLayout,
        overflowY: "auto"
      }}
    >
      <ComplianceWarningModal
        isComplianceWarningModalOpen={isComplianceWarningModalOpen}
        onCancel={handleCancelComplianceWarningModal}
        onOk={handleConfirmComplianceWarningModal}
      />
      <AntDHeader title={t("newEvent")} backButton={() => history.goBack()} />
      <Flex vertical gap={24} style={{ padding: 24 }}>
        <EventForm
          eventDetails={eventDetails}
          project={project}
          currentBasicUser={currentBasicUser}
          referral={referral}
          isSaving={isSaving}
          form={form}
          getDefaultEventGuests={getDefaultEventGuests}
          getDefaultTimezone={getDefaultTimezone}
          handleSubmit={
            eventCreationGatekeepingFeatureFlag
              ? handleComplianceBeforeSubmit
              : handleSubmit
          }
          isEditMode={isEditMode}
          onFinishFailed={onFinishFailed}
        />
      </Flex>
    </Flex>
  );
};
