import { ExportStatusBar } from "../../pages/CustomerSurveysPage/CompanyDataTable/ExportStatusBar";

export const STATUS_BAR = {
  statusPanels: [
    {
      statusPanel: ExportStatusBar,
      align: "right"
    }
  ]
};
