import { FilterChangedEvent, GridApi, GridReadyEvent } from "ag-grid-community";
import clsx from "clsx";
import React from "react";
import { useParams } from "react-router";

import { SurveyExpert } from "@arbolus-technologies/api";
import { SurveyParamUrlType } from "@arbolus-technologies/models/survey";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { ModifySurveyExpertOwnerSidePanel } from "../ModifySurveyExpertOwnerSidePanel/ModifySurveyExpertOwnerSidePanel";
import { BulkActionsStatusPanel } from "./BulkActionsStatusPanel";
import {
  DefaultSurveyExpertMenuService,
  SurveyExpertMenuService
} from "./SurveyExpertMenuService";
import { SurveyExpertTableDataSource } from "./SurveyExpertTableDataSource";
import { COLUMN_DEFINITIONS, DEFAULT_COLUMN_DEFINITION } from "./constants";
import { useSurveyExpertGridContext } from "./useSurveyExpertGridContext";

import styles from "./SurveyExpertTable.module.scss";

interface SurveyExpertTable {
  onFilterChange: (event: FilterChangedEvent) => void;
  contextMenuService?: SurveyExpertMenuService;
}

export const SurveyExpertTable: React.FC<SurveyExpertTable> = ({
  onFilterChange,
  contextMenuService = DefaultSurveyExpertMenuService
}) => {
  const { surveyId } = useParams<SurveyParamUrlType>();

  const [api, setApi] = React.useState<GridApi<SurveyExpert>>();

  const onGridReady = ({ api }: GridReadyEvent<SurveyExpert>) => {
    setApi(api);
    api.setGridOption(
      "serverSideDatasource",
      new SurveyExpertTableDataSource(surveyId)
    );
    api.sizeColumnsToFit();
  };

  const gridContext = useSurveyExpertGridContext(api);

  const statusBar = React.useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: BulkActionsStatusPanel,
          align: "right"
        }
      ]
    }),
    []
  );

  const handleUpdateOwner = () => {
    api?.refreshServerSide();
  };

  return (
    <div className={clsx(styles.statusBarOnTop, "h-100")}>
      <ArbolusGrid
        rowModelType="serverSide"
        columnDefs={COLUMN_DEFINITIONS}
        defaultColDef={DEFAULT_COLUMN_DEFINITION}
        onGridReady={onGridReady}
        getRowId={({ data }) => data.expertId}
        suppressRowClickSelection
        suppressMenuHide
        tooltipShowDelay={500}
        skipHeaderOnAutoSize
        pagination
        paginationAutoPageSize
        onFilterChanged={onFilterChange}
        rowSelection="multiple"
        context={gridContext}
        getContextMenuItems={contextMenuService.getItems}
        statusBar={statusBar}
      />
      <ModifySurveyExpertOwnerSidePanel onUpdateOwner={handleUpdateOwner} />
    </div>
  );
};
