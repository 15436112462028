import { ColDef } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";

import { SurveyExpert } from "@arbolus-technologies/api";
import {
  fixedWidth,
  floatingFilter
} from "@arbolus-technologies/ui/components";
import { DATE_AND_TIME_FORMAT } from "@arbolus-technologies/utils";

import { TransactionOwnerRenderer } from "./TransactionOwnerRenderer/TransactionOwnerRenderer";

const COLUMN_IDS = {
  EXPERT_NAME: "fullName",
  EMAIL: "email",
  ID: "surveyExpertId",
  COMPLETION_DATE: "completionDate",
  PAYMENT_METHOD: "paymentMethod",
  AMOUNT: "amount",
  TRANSACTION_OWNER: "transactionOwner"
} as const;

export const COLUMN_DEFINITIONS: ColDef<SurveyExpert>[] = [
  {
    field: COLUMN_IDS.EXPERT_NAME,
    headerName: i18next.t("surveyExpertTable:expert"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      suppressAndOrCondition: true,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 300,
    tooltipField: COLUMN_IDS.EXPERT_NAME,
    checkboxSelection: true
  },
  {
    field: COLUMN_IDS.EMAIL,
    headerName: i18next.t("surveyExpertTable:email"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      suppressAndOrCondition: true,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 256,
    tooltipField: COLUMN_IDS.EMAIL
  },
  {
    field: COLUMN_IDS.ID,
    headerName: i18next.t("surveyExpertTable:surveyExpertId"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      suppressAndOrCondition: true,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 150
  },
  {
    field: COLUMN_IDS.COMPLETION_DATE,
    headerName: i18next.t("surveyExpertTable:completionDate"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      suppressAndOrCondition: true,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 140,
    valueFormatter: ({ value }) => moment(value).format(DATE_AND_TIME_FORMAT)
  },
  {
    field: COLUMN_IDS.PAYMENT_METHOD,
    headerName: i18next.t("surveyExpertTable:paymentMethod"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      suppressAndOrCondition: true,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 100,
    valueFormatter: ({ value }) =>
      value
        ? i18next.t(`surveyPaymentType:${value}`)
        : i18next.t(`surveyPaymentType:notSet`)
  },
  {
    field: COLUMN_IDS.TRANSACTION_OWNER,
    headerName: i18next.t("surveyExpertTable:transactionOwner"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      suppressAndOrCondition: true,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 256,
    cellRenderer: TransactionOwnerRenderer,
    tooltipField: "transactionOwner.firstName"
  },
  {
    field: COLUMN_IDS.AMOUNT,
    headerName: i18next.t("surveyExpertTable:amount"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      suppressAndOrCondition: true,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    ...fixedWidth(128),
    valueFormatter: ({ value }) => `$ ${value}`
  }
];

export const DEFAULT_COLUMN_DEFINITION: ColDef = {
  suppressMovable: true,
  menuTabs: ["filterMenuTab"],
  minWidth: 70
};
