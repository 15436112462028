/* eslint-disable react/no-unused-state */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/static-property-placement */
import { replace } from "connected-react-router";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext } from "react-router";
import { Button } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import {
  DASHBOARD,
  EXPERT_SELF_PROFILE_EDIT,
  PROJECT_APPLICATION_ROUTE
} from "@arbolus-technologies/routes";
import { Loader } from "@arbolus-technologies/ui/components";

import { REFERRER_TYPE } from "../../../../constants/auth";
import { PROJECTS } from "../../../../constants/navigation/authRoutes";
import { USER_INVITATION_TYPE } from "../../../../constants/user";
import AccessManager from "../../../../contexts/roleBasedAccess/AccessManager";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { ProjectsPaginatedRequest } from "../../../../models/project";
import { Invitation, LoggedInUser } from "../../../../models/user";
import { ProjectService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { AuthSelector } from "../../../auth/store";

import arbolusLogo from "../../../../assets/images/logos/logo-tree-blue.png";

const notification = new ToasterService();

interface WelcomePageStoreProps {
  user: LoggedInUser;
}

interface WelcomePageProps {
  navigateToProfile: () => void;
  navigateToProjects: () => void;
  navigateToDashboard: () => void;
  navigateToProjectBrief: (projectId: string) => void;
}

type WelcomePageIntersectProps = WelcomePageProps &
  WelcomePageStoreProps &
  RouteComponentProps<{}, StaticContext, Invitation | undefined> &
  WithTranslation;

interface WelcomePageState {
  isLoading: boolean;
}

class WelcomePage extends React.PureComponent<
  WelcomePageIntersectProps,
  WelcomePageState
> {
  static defaultProps = {
    user: {} as LoggedInUser,
    navigateToProfile: (): void => {},
    navigateToProjects: (): void => {}
  };

  constructor(props: WelcomePageIntersectProps) {
    super(props);

    this.state = {
      isLoading: true
    };

    const {
      location: { state: ExpertInvite }
    } = props;

    this.isProjectInvite =
      ExpertInvite?.invitationType === USER_INVITATION_TYPE.PROJECT;

    this.isExpertReferred = ExpertInvite?.referrerType === REFERRER_TYPE.EXPERT;
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("title");
    this.getProjects();
  }

  componentWillUnmount(): void {
    this.fetchExpertProjectsSubscription?.unsubscribe();
  }

  private isProjectInvite: boolean;

  private isExpertReferred: boolean;

  private fetchExpertProjectsSubscription?: Subscription;

  private invitedProjectId = "";

  private expertProjectCount = 0;

  getProjects = (): void => {
    const apiParams: ProjectsPaginatedRequest = {
      searchTerm: "",
      limit: 2, // To identify whether there are more than one project available
      offset: 0,
      orderBy: "",
      orderDirection: "",
      projectState: ""
    };

    this.fetchExpertProjectsSubscription = ProjectService.getExpertProjects(
      apiParams
    ).subscribe(
      ({ items }) => {
        if (items.length) {
          this.invitedProjectId = items[0].id;
          this.expertProjectCount = items.length;
        }
        this.setState({
          isLoading: false
        });
      },
      (error: ErrorResponse<CIQError>) => {
        this.setState({
          isLoading: false
        });
        notification.showError(error.message);
      }
    );
  };

  handleGoToProjects = (): void => {
    const { navigateToProjects } = this.props;
    navigateToProjects();
  };

  handleGoToDashboard = () => {
    this.props.navigateToDashboard();
    return <></>;
  };

  handleGoToExpertProject = (): void => {
    const { navigateToProjectBrief } = this.props;
    navigateToProjectBrief(this.invitedProjectId);
  };

  handleGoToProfile = (): void => {
    const { navigateToProfile } = this.props;

    navigateToProfile();
  };

  renderExpertWelcomeInstruction = (): JSX.Element => {
    const {
      location: { state: ExpertInvite },
      t
    } = this.props;

    if (ExpertInvite) {
      if (!this.isProjectInvite) {
        const { clientName } = ExpertInvite;

        return (
          <div className="welcome-info">
            <h4>
              {t("networkWelcome", { clientName })}
              <br />
              <br />
              {t("whatNow")}
            </h4>
            <p>
              <span>1. </span>
              {t("networkInviteInfo1")}
            </p>
            <p>
              <span>2. </span> {t("networkInviteInfo2")}
            </p>
            <p>
              <span>3. </span>
              {t("networkInviteInfo3")}
            </p>
          </div>
        );
      }

      if (!this.isExpertReferred && this.isProjectInvite) {
        const { clientName } = ExpertInvite;

        return (
          <div className="welcome-info">
            <h4>
              {t("welcomeToInvite")}
              <br />
              <br />
              {t("welcomeInviteInfo", { clientName })}
            </h4>
            <p>
              <span>1. </span>
              {t("expertInviteInfo1")}
            </p>
            <p>
              <span>2. </span> {t("expertInviteInfo2")}
            </p>
            <p>
              <span>3. </span>
              {t("expertInviteInfo3")}
            </p>
          </div>
        );
      }
    }

    return (
      <div className="welcome-info">
        <h4>{t("welcomeTo")} </h4>

        <p>
          <span>1. </span>
          {t("expertInfo1")}
        </p>
        <p>
          <span>2. </span> {t("expertInfo2")}
        </p>
        <p>
          <span>3. </span>
          {t("expertInfo3")}
        </p>
      </div>
    );
  };

  renderWelcomeBanner = (): JSX.Element => {
    const { t } = this.props;

    if (this.isProjectInvite) {
      if (this.isExpertReferred) {
        return t("allDone");
      }
      return t("allInviteDone");
    }

    return t("youAreAllSet");
  };

  renderExpertProjectsButton = (): JSX.Element => {
    const { isLoading } = this.state;
    const { t } = this.props;

    const isProjectBtnVisible = this.expertProjectCount > 0;

    const hasOneProject = this.expertProjectCount === 1;

    const handleActionClick = (): void => {
      if (isProjectBtnVisible) {
        hasOneProject
          ? this.handleGoToExpertProject()
          : this.handleGoToProjects();
      } else {
        this.handleGoToProfile();
      }
    };

    return (
      <AccessManager permission="welcome:expertActions">
        <Button
          type="submit"
          size="lg"
          color="primary"
          className="btn-bold"
          onClick={handleActionClick}
          disabled={isProjectBtnVisible && isLoading}
        >
          {isProjectBtnVisible
            ? t("goToProject", { count: this.expertProjectCount })
            : t("completeMyProfile")}
        </Button>
      </AccessManager>
    );
  };

  render(): JSX.Element {
    const { user, t } = this.props;
    const { isLoading } = this.state;

    return isLoading ? (
      <Loader isFull />
    ) : (
      <div className="welcome-page-container">
        <div className="full-page-logo">
          <img src={arbolusLogo} height="30px" width="30px" alt="logo" />
        </div>
        <div className="message-banner green">
          <p className="message">{this.renderWelcomeBanner()}</p>
          <span className="ciq-icon ciq-check" />
        </div>

        <div className="welcome-message-container">
          <h1>{t("letsGetStarted")}</h1>
          <AccessManager permission="welcome:expertWelcome">
            {this.renderExpertWelcomeInstruction()}
          </AccessManager>
          <AccessManager permission="welcome:clientWelcome">
            <div className="welcome-info">
              <h4>{t("clientWelcomeTo", { clientName: user.client?.name })}</h4>
              <p>{t("clientInfo")}</p>
            </div>
          </AccessManager>
        </div>

        <div className="btn-container">
          {this.renderExpertProjectsButton()}
          <AccessManager permission="welcome:clientActions">
            {this.handleGoToDashboard()}
          </AccessManager>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  WelcomePageProps,
  WelcomePageStoreProps
>({
  user: AuthSelector.authUserSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToProfile: (): AppAction => {
    dispatch(replace(EXPERT_SELF_PROFILE_EDIT));
  },
  navigateToDashboard: (): AppAction => {
    dispatch(replace(DASHBOARD));
  },
  navigateToProjects: (): AppAction => {
    dispatch(replace(PROJECTS));
  },
  navigateToProjectBrief: (projectId: string): AppAction => {
    dispatch(replace(PROJECT_APPLICATION_ROUTE(projectId)));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("welcomePage")(WelcomePage));
