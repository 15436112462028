import { Flex } from "antd";
import React, { useEffect, useState } from "react";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import { DncInfoBox, Loader } from "@arbolus-technologies/ui/components";

import { WorkHistories } from "../../shared/WorkHistories/WorkHistories";
import { CallPrice } from "./CallPrice";
import { ExpertInfo } from "./ExpertInfo";

interface CustomerSurveyExpertProfileProps {
  expertId: string;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const CustomerSurveyExpertProfile: React.FC<
  CustomerSurveyExpertProfileProps
> = ({
  expertId,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [expertInfo, setExpertInfo] = useState<ExpertDetail | null>(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (expertId) {
      expertService.getExpertById({ expertId }).subscribe(
        (expert: ExpertDetail) => {
          setExpertInfo(expert);
          setIsVerified(expert.isVerified);
          setIsLoading(false);
        },
        (err: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(err);
          setIsLoading(false);
        }
      );
    }
  }, [expertService, notificationService, expertId]);

  if (isLoading) {
    return <Loader isFull />;
  }

  if (!expertInfo) {
    return null;
  }

  const {
    doNotContactStatus,
    doNotContactStatusCategory,
    doNotContactStatusDescription,
    experienceLevel,
    lastPublicCompanyExpDate,
    linkedinProfile,
    user,
    workHistories
  } = expertInfo;

  return (
    <Flex vertical gap={24} style={{ padding: 24 }}>
      <ExpertInfo
        expertId={expertInfo.id}
        expertUser={user}
        linkedinProfile={linkedinProfile}
        lastPublicCompanyExpDate={lastPublicCompanyExpDate}
        isVerified={isVerified}
      />

      <DncInfoBox
        doNotContactStatus={doNotContactStatus}
        doNotContactStatusDescription={doNotContactStatusDescription}
        doNotContactStatusCategory={doNotContactStatusCategory}
        isAdmin
      />

      <CallPrice
        email={user.email}
        phoneNumber={user.phoneNumber}
        experienceLevel={experienceLevel}
        timezone={user.timezone}
      />

      {workHistories.length > 0 && (
        <WorkHistories
          workHistories={workHistories}
          lastPublicCompanyExpDate={lastPublicCompanyExpDate}
        />
      )}
    </Flex>
  );
};
