import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  ClientUser,
  Company,
  CompanyService,
  DefaultToasterService,
  MonitoringListService,
  ToasterService
} from "@arbolus-technologies/api";
import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  SIDE_PANEL_SIZE,
  useDebouncedSearch
} from "@arbolus-technologies/utils";
import { Empty, Flex, Form, Input, Spin } from "antd";
import { SearchProps } from "antd/es/input";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies, getUsers } from "../../../Services/services";
import { STEPS } from "../../../models";
import styles from "./AddCompanySlidePanel.module.scss";
import { CompaniesList } from "./CompaniesList";
import { CompanyNotFound } from "./CompanyNotFound";
import { CreateCompanyForm } from "./CreateCompanyForm/CreateCompanyForm";
import { SlidePanelTitle } from "./SlidePanelTitle/SlidePanelTitle";
import { UsersList } from "./UsersList";

const { Search } = Input;

interface AddCompanySlidePanelProps {
  clientId: string;
  refreshData: () => void;
  companyService?: typeof CompanyService;
  notificationService?: ToasterService;
}

export const AddCompanySlidePanel: React.FC<AddCompanySlidePanelProps> = ({
  clientId,
  refreshData,
  companyService = CompanyService,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [slidePanelStep, setSlidePanelStep] = useState<STEPS>(STEPS.SelectUser);
  const [users, setUsers] = useState<ClientUser[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [usersSelected, setUsersSelected] = useState<string[]>([]);
  const [companiesSelected, setCompaniesSelected] = useState<string[]>([]);
  const [form] = Form.useForm();

  const isSelectUserStep = slidePanelStep === STEPS.SelectUser;
  const isSelectCompanyStep = slidePanelStep === STEPS.SelectCompany;
  const isCreateCompanyStep = slidePanelStep === STEPS.CreateCompany;

  const { t } = useTranslation("createCompanyMonitoringList");
  const dispatch = useDispatch();
  const isAddMonitoringListPanelOpen = useSelector(
    PanelSelector.isPanelOpen(PanelId.AddMonitoringListCompany)
  );

  const onQueryChange = (searchValue: string) => {
    setSearchTerm(searchValue);
  };

  const { handleInputChangeEvent, searchQuery } = useDebouncedSearch({
    onQueryChange,
    isDebounced: true
  });

  async function handleUsersRequest() {
    setIsLoading(true);
    const response = await getUsers(clientId, searchTerm);
    setUsers(response.items);
    setIsLoading(false);
  }

  async function handleCompaniesRequest() {
    setIsLoading(true);
    const response = await getCompanies(searchTerm);
    setCompanies(response.items);
    setIsLoading(false);
  }

  useEffect(() => {
    if (isAddMonitoringListPanelOpen) {
      if (isSelectUserStep) {
        handleUsersRequest();
      } else if (isSelectCompanyStep) {
        handleCompaniesRequest();
      }
    }
  }, [isAddMonitoringListPanelOpen, searchTerm, slidePanelStep]);

  const handleItemSelect = (
    id: string,
    isItemSelected: boolean,
    userSelection = false
  ) => {
    const selectedItems = userSelection ? usersSelected : companiesSelected;
    const setSelectedItems = userSelection
      ? setUsersSelected
      : setCompaniesSelected;

    const updatedItems = isItemSelected
      ? selectedItems.filter((selectedId) => selectedId !== id)
      : [...selectedItems, id];

    setSelectedItems(updatedItems);
  };

  const restartSearchTerm = () =>
    handleInputChangeEvent({
      target: { value: "" }
    } as React.ChangeEvent<HTMLInputElement>);

  const onSearch: SearchProps["onSearch"] = (_, __, info) => {
    if (info?.source === "clear") {
      restartSearchTerm();
    }
  };

  const setInitialState = () => {
    setSlidePanelStep(STEPS.SelectUser);
    setUsersSelected([]);
    setCompaniesSelected([]);
    restartSearchTerm();
  };

  const handleOnCreateCompany = () => {
    form.validateFields().then((values) => {
      const { companyName, website } = values;
      companyService.createCompany({ name: companyName, website }).subscribe(
        (response) => {
          setCompaniesSelected((prevState) => [...prevState, response.id]);
          handlePostRequest(response.id);
        },
        (error) => {
          notificationService.showApiErrors(error);
        }
      );
    });
  };

  async function handlePostRequest(newIdCreated?: string) {
    setIsLoading(true);
    try {
      await MonitoringListService.addCompanies({
        companyIds: newIdCreated
          ? [...companiesSelected, newIdCreated]
          : companiesSelected,
        userIds: usersSelected
      });
      setIsLoading(false);
      notificationService.showSuccess(
        t(newIdCreated ? "successCreated" : "successAdded")
      );
      dispatch(PanelStoreActions.closePanel(PanelId.AddMonitoringListCompany));
      onQueryChange("");
      restartSearchTerm();
      setInitialState();
      refreshData();
    } catch (error) {
      setIsLoading(false);
      notificationService.showApiErrors(error);
    }
  }

  const handleCloseComponent = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!isSelectUserStep) {
      evt.stopPropagation();
      if (isSelectCompanyStep) {
        setSlidePanelStep(STEPS.SelectUser);
      } else if (isCreateCompanyStep) {
        setSlidePanelStep(STEPS.SelectCompany);
      }
    }
  };

  const companyNotFoundCase =
    isSelectCompanyStep && searchTerm !== "" && companies.length === 0;

  const showInputSearch = slidePanelStep !== STEPS.CreateCompany;
  const disabledSubmitButton =
    isLoading ||
    (isSelectUserStep && usersSelected.length === 0) ||
    (isSelectCompanyStep && companiesSelected.length === 0);

  return (
    <SlidePanel
      panelId={PanelId.AddMonitoringListCompany}
      width={SIDE_PANEL_SIZE._400}
      closeComponent={
        <AntDIcon
          name={isSelectUserStep ? "close" : "chevronLeft"}
          onClick={handleCloseComponent}
        />
      }
      customCloseRequest={setInitialState}
      noMarginTitle
      title={
        <SlidePanelTitle
          slidePanelStep={slidePanelStep}
          setSlidePanelStep={setSlidePanelStep}
          cleanSearchTerm={() => {
            onQueryChange("");
            restartSearchTerm();
          }}
          setInitialState={setInitialState}
          submitDisabled={disabledSubmitButton}
          postRequest={
            isCreateCompanyStep ? handleOnCreateCompany : handlePostRequest
          }
        />
      }
    >
      <>
        {showInputSearch && (
          <Search
            placeholder={t("searchPlaceholder")}
            value={searchQuery}
            allowClear
            onChange={handleInputChangeEvent}
            onSearch={onSearch}
            style={{ marginBottom: "24px" }}
            disabled={isLoading}
          />
        )}
        {isLoading ? (
          <div className={styles.loadingWrapper}>
            <Spin size="large" />
          </div>
        ) : (
          <Flex gap={16} vertical>
            {isSelectUserStep && (
              <UsersList
                users={users}
                usersSelected={usersSelected}
                handleItemSelect={handleItemSelect}
              />
            )}
            {isSelectUserStep && users.length === 0 && <Empty />}
            {isSelectCompanyStep && (
              <CompaniesList
                companies={companies}
                companiesSelected={companiesSelected}
                handleItemSelect={handleItemSelect}
              />
            )}
            {companyNotFoundCase && (
              <CompanyNotFound
                setSlidePanelStep={() => setSlidePanelStep(STEPS.CreateCompany)}
              />
            )}
            {isCreateCompanyStep && <CreateCompanyForm form={form} />}
          </Flex>
        )}
      </>
    </SlidePanel>
  );
};
