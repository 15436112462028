import React from "react";
import { useTranslation } from "react-i18next";

import {
  AdminService,
  ApiPaginatedRequest,
  TransactionSurvey
} from "@arbolus-technologies/api";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

export const SurveySelector = ({
  placeholderText,
  ...props
}: TypeaheadSelectorControllerProps<TransactionSurvey>): React.ReactElement => {
  const { t } = useTranslation();
  const placeholder = placeholderText ?? t("common:surveySelection:findSurvey");

  const renderer = ({
    surveyTitle,
    projectName
  }: TransactionSurvey): JSX.Element => (
    <span>{`${surveyTitle} (${projectName})`}</span>
  );

  const getItems = (pagination: ApiPaginatedRequest) =>
    AdminService.getAllSurveys({
      searchTerm: pagination.searchTerm,
      limit: 10,
      offset: pagination.offset
    });

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={t("common:surveySelection:findingSurveys")}
      searchText={t("common:surveySelection:findingSurveys")}
      paginationText={t("common:surveySelection:loadMore")}
      orderBy="surveyTitle"
      renderer={renderer}
      getLabelKey={(survey) => `${survey.surveyTitle} (${survey.projectName})`}
      getItems={getItems}
    />
  );
};
