import { acceptPlatformAgreement } from "./actions/CacheAcceptPlatformAgreement/CacheAcceptPlatformAgreementActions";
import { getAdminUserProfile } from "./actions/CacheGetAdminUserProfile/CacheGetAdminUserProfileActions";
import {
  getAppData,
  getAppDataFailure,
  getAppDataSuccess
} from "./actions/CacheGetAppData/CacheGetAppDataActions";
import { getAPPType } from "./actions/CacheGetAppType/CacheGetAppTypeActions";
import { getLoggedInUser } from "./actions/CacheGetLoggedInUser/CacheGetLoggedInUserActions";

export const CacheStoreActions = {
  getAppData,
  getAppDataSuccess,
  getAppDataFailure,
  getLoggedInUser,
  getAPPType,
  getAdminUserProfile,
  acceptPlatformAgreement
};
