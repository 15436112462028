import { Col, Flex, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  SurveyService,
  ToasterService,
  UpdateSurvey
} from "@arbolus-technologies/api";
import { PROJECT_EDIT_SURVEY_ROUTE } from "@arbolus-technologies/routes";

import { CloseSurveyCard } from "./CloseSurveyCard";
import { LandingLinkCard } from "./LandingLinkCard";
import { SurveyDetailsForm } from "./SurveyDetailsForm";

interface CreateSurveyProps {
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const CreateSurvey: React.FC<CreateSurveyProps> = ({
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("createSurvey");
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();

  const [isSaving, setIsSaving] = useState(false);

  const createSurvey = (values: UpdateSurvey) => {
    setIsSaving(true);
    surveyService.createSurvey(values).subscribe(
      (res) => {
        setIsSaving(false);
        history.push(PROJECT_EDIT_SURVEY_ROUTE(projectId, res.id));
        notificationService.showSuccess(t("surveyCreated"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsSaving(false);
      }
    );
  };

  return (
    <Row gutter={24}>
      <Col span={16}>
        <SurveyDetailsForm saveSurvey={createSurvey} isSaving={isSaving} />
      </Col>
      <Col span={8}>
        <Flex vertical gap={24}>
          <LandingLinkCard />
          <CloseSurveyCard />
        </Flex>
      </Col>
    </Row>
  );
};
