import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AntDAvatar,
  AntDSelectMenu,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import {
  AdminService,
  ApiErrorResponse,
  CIQError,
  DefaultToasterService,
  ToasterService,
  User
} from "@arbolus-technologies/api";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

interface SurveyLeadUserSelectorProps {
  disabled: boolean;
  adminService?: typeof AdminService;
  notificationService?: ToasterService;
}

export const SurveyLeadUserSelector: React.FC<SurveyLeadUserSelectorProps> = ({
  disabled = false,
  adminService = AdminService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("surveyLeadUserSelector");
  const form = Form.useFormInstance();

  const [surveyLeadUserList, setSurveyLeadUserList] = useState<User[]>([]);

  const clientId = useSelector(ProjectNxSelector.projectClientId());

  const handleGetItems = (query: string, offset = 0) => {
    adminService
      .getClientAdminUsers(clientId, true, offset, 5, query)
      .subscribe(
        ({ items }) => {
          const newList = items.map((item) => ({ ...item, value: item.id }));
          setSurveyLeadUserList((prevState) =>
            (offset === 0 ? [] : prevState).concat(newList)
          );
        },
        (error: ApiErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  const surveyLeadUser = form.getFieldValue("surveyLeadUser");
  const handleOnChange = (value: AntDSelectValueType) => {
    const surveyLead = surveyLeadUserList.find((item) => item.id === value);
    form.setFieldsValue({ ["surveyLeadUser"]: surveyLead });
  };

  return (
    <AntDSelectMenu
      options={surveyLeadUserList}
      placeholder={t("placeholder")}
      optionFilterProp="children"
      value={
        surveyLeadUser
          ? `${surveyLeadUser.firstName} ${surveyLeadUser.lastName}`
          : undefined
      }
      optionRenderer={(option) => (
        <AntDAvatar
          firstName={option.data.firstName}
          lastName={option.data.lastName}
          profileImageUrl={option.data.profileImageUrl}
          size="small"
        />
      )}
      onChange={handleOnChange}
      onGetItems={handleGetItems}
      handleClearList={() => setSurveyLeadUserList([])}
      disabled={disabled}
    />
  );
};
