import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  LandingSurvey,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { SurveyParamUrlType } from "@arbolus-technologies/models/survey";
import { Loader } from "@arbolus-technologies/ui/components";

import { AnyQuestions } from "../../Components/AnyQuestions/AnyQuestions";
import { DetailsAndInformation } from "../../Components/DetailsAndInformation/DetailsAndInformation";
import { LegalInformation } from "../../Components/LegalInformation/LegalInformation";
import { SurveyHeader } from "../../Components/SurveyHeader/SurveyHeader";

import styles from "./SurveyLandingPage.module.scss";

const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

interface SurveyLandingPageProps {
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const SurveyLandingPage: React.FC<SurveyLandingPageProps> = ({
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("surveyLandingPage");
  const { surveyId } = useParams<SurveyParamUrlType>();
  const { trackRender } = useArbolusTracking();
  useDocumentTitle(t("survey"));

  const [survey, setSurvey] = useState<LandingSurvey | null>(null);

  const getExpertSurvey = (surveyId: string) => {
    surveyService.getSurveyPublic(surveyId).subscribe(
      (surveyResponse: LandingSurvey) => {
        setSurvey(surveyResponse);
        handleMixpanelTracking();
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  const handleMixpanelTracking = () => {
    const survey = localStorage.getItem(`survey_${surveyId}`);
    const surveyObj = JSON.parse(survey ?? "{}");

    if (!surveyObj.loaded || new Date().getTime() > surveyObj.expiresAt) {
      localStorage.setItem(
        `survey_${surveyId}`,
        JSON.stringify({
          loaded: true,
          expiresAt: new Date().getTime() + MILLISECONDS_IN_A_DAY
        })
      );

      trackRender(MixPanelEventNames.SurveyLandingPage);
    }
  };

  useEffect(() => {
    getExpertSurvey(surveyId);
  }, [surveyId]);

  if (survey === null) {
    return <Loader isFull />;
  }

  return (
    <div className={styles.container}>
      <SurveyHeader />
      <div className={styles.content}>
        <DetailsAndInformation survey={survey} />
        <AnyQuestions />
        <LegalInformation />
      </div>
    </div>
  );
};
