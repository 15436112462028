import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  DefaultToasterService,
  MovingToShortlistError,
  PROJECT_REFERRAL_STATE,
  ProjectService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import {
  EXPERT_PROFILE,
  PROJECT_SHORTLISTED_REFERRALS
} from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ArbolusListModal } from "@arbolus-technologies/ui/components";

import { Button } from "antd";
import { MoveToShortlistPanel } from "./MoveToShortlistPanel";

interface MoveToShortlistButtonProps {
  disabled: boolean;
  selectedExpertsIds: string[];
  selectedCautionExperts: { id: string; name: string }[];
  onShortlistConfirmed: (selectedExpertsIds: string[]) => void;
  handleSetIsConfirmReferralsLoading: (isLoading: boolean) => void;
  isConfirmReferralsLoading: boolean;
}

export const MoveToShortlistButton: React.FC<MoveToShortlistButtonProps> = ({
  disabled,
  selectedExpertsIds,
  selectedCautionExperts,
  onShortlistConfirmed,
  handleSetIsConfirmReferralsLoading,
  isConfirmReferralsLoading
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackClick } = useArbolusTracking();

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isCautionModalOpen, setIsCautionModalOpen] = useState(false);
  const [selectedAngle, setSelectedAngle] = useState<AngleModel>();
  const [expertErrors, setExpertErrors] = useState<MovingToShortlistError[]>(
    []
  );

  const projectData = useSelector(ProjectNxSelector.projectData());

  function openPanel(): void {
    dispatch(PanelStoreActions.openPanel(PanelId.MoveToShortlist));
    trackClick(MixPanelEventNames.MoveToShortlist);
  }

  async function handleConfirm(angle: AngleModel) {
    setIsCautionModalOpen(false);
    handleSetIsConfirmReferralsLoading(true);
    const expertErrors = await requestMovingToShortlist(
      projectData!.id,
      angle.id,
      selectedExpertsIds
    );
    handleSetIsConfirmReferralsLoading(false);
    setExpertErrors(expertErrors);
    if (expertErrors.length > 0) {
      setIsErrorModalOpen(true);
    }

    const skippedExpertsIds = expertErrors.map(({ expertId }) => expertId);
    const expertsWithoutErrors = selectedExpertsIds.filter(
      (id) => !skippedExpertsIds.includes(id)
    );
    onShortlistConfirmed(expertsWithoutErrors);

    trackClick(MixPanelEventNames.SelectAngleSidePanelConfirm);
  }

  const handleCautionExpertsModal = (angle: AngleModel) => {
    setSelectedAngle(angle);
    if (selectedCautionExperts.length > 0) {
      setIsCautionModalOpen(true);
    } else {
      handleConfirm(angle);
    }
  };

  async function requestMovingToShortlist(
    projectId: string,
    angleId: string,
    expertIds: string[]
  ) {
    try {
      const { expertErrors } = await ProjectService.addMultipleReferrals({
        projectId,
        angleId,
        expertIds,
        state: PROJECT_REFERRAL_STATE.SHORTLIST
      }).toPromise();

      if (expertErrors.length === 0) {
        DefaultToasterService.showSuccess(
          i18next.t("discoverPage:moveToShortlistSuccess")
        );
      }
      return expertErrors;
    } catch (error) {
      DefaultToasterService.showError(error.message);
    }

    return [];
  }

  return (
    <>
      <Button
        disabled={disabled}
        onClick={openPanel}
        loading={isConfirmReferralsLoading}
        type="primary"
      >
        {t("moveToShortlist")}
      </Button>

      <MoveToShortlistPanel
        onConfirm={handleCautionExpertsModal}
        selectedExpertAmount={selectedExpertsIds.length}
      />

      {selectedCautionExperts.length > 0 && selectedAngle && (
        <ArbolusListModal
          title={t("moveToShortlistCautionModalTitle")}
          subtitle={t("moveToShortlistCautionModalSubtitle")}
          messages={selectedCautionExperts.map(({ id, name }) => ({
            text: name,
            to: EXPERT_PROFILE(id)
          }))}
          leftButton={{
            text: t("moveToShortlistCautionModalCancelButton"),
            type: "tertiary",
            onClick: () => setIsCautionModalOpen(false)
          }}
          rightButton={{
            text: t("moveToShortlistCautionModalApplyButton"),
            type: "primary",
            onClick: () => {
              handleConfirm(selectedAngle);
            }
          }}
          isOpen={isCautionModalOpen}
          toggle={() =>
            setIsCautionModalOpen((previousValue) => !previousValue)
          }
        />
      )}

      {expertErrors.length > 0 && (
        <ArbolusListModal
          title={t("moveToShortlistModalTitle")}
          messages={expertErrors.map(({ fullName, error }) => ({
            text: `${fullName} - ${error}`
          }))}
          leftButton={{
            text: t("moveToShortlistModalCancelButton"),
            type: "tertiary",
            onClick: () => setIsErrorModalOpen(false)
          }}
          rightButton={{
            text: t("moveToShortlistModalApplyButton"),
            type: "primary",
            onClick: () =>
              history.push(PROJECT_SHORTLISTED_REFERRALS(projectData!.id)),
            disabled: selectedExpertsIds.length === expertErrors.length
          }}
          isOpen={isErrorModalOpen}
          toggle={() => setIsErrorModalOpen((previousValue) => !previousValue)}
        />
      )}
    </>
  );
};
