import { Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { GrowingCompanies } from "./GrowingCompanies/GrowingCompanies";
import { LinksAndSocials } from "./LinksAndSocials/LinksAndSocials";

const { Text } = Typography;

export const SurveyFooter: React.FC = () => {
  const { t } = useTranslation("surveyFooter");

  return (
    <Flex vertical gap={24}>
      <GrowingCompanies />
      <LinksAndSocials />
      <Flex vertical>
        <Text>{t("copyrightText1")}</Text>
        <Text>{t("copyrightText2")}</Text>
      </Flex>
    </Flex>
  );
};
