import React from "react";
import { useSelector } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { PanelId, PanelSelector } from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { Content } from "./Sections/Content/Content";
import { Header } from "./Sections/Header/Header";
import { DiscoverMatchingFiltersPanelData } from "./interfaces";

import { Flex, Spin } from "antd";

export const DiscoverMatchingFiltersPanel: React.FC = () => {
  const panelData: DiscoverMatchingFiltersPanelData = useSelector(
    PanelSelector.panelData(PanelId.DiscoverMatchingFilters)
  );

  return (
    <SlidePanel
      panelId={PanelId.DiscoverMatchingFilters}
      width={SIDE_PANEL_SIZE._720}
      hideHeader
      noPadding
    >
      {panelData ? (
        <Flex vertical>
          <Header panelData={panelData} />
          <Content panelData={panelData} />
        </Flex>
      ) : (
        <Flex align="center" justify="center" style={{ height: "100%" }}>
          <Spin />
        </Flex>
      )}
    </SlidePanel>
  );
};
