import {
  Card,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Typography
} from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import { Project, ReferralDetail } from "@arbolus-technologies/api";
import { Slot } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  EVENT_DAY_SHORT_READABLE_DATE_WITHOUT_COMMA_FORMAT,
  EVENT_DAY_SHORT_READABLE_DATE_WITH_COMMA_FORMAT,
  EVENT_TWELVE_HOUR_TIME_FORMAT,
  getMetadataCountryFromThreeLetterCode
} from "@arbolus-technologies/utils";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";

dayjs.extend(utc);
dayjs.extend(timezone);

interface ExpertAvailabilitySlotsProps {
  project: Project;
  referral: ReferralDetail;
  handleSelectSlot: (slot: Slot) => void;
  selectedSlot: string | null;
}

const { Text } = Typography;

export const ExpertAvailabilitySlots: React.FC<
  ExpertAvailabilitySlotsProps
> = ({ project, referral, handleSelectSlot, selectedSlot }) => {
  const { t } = useTranslation("eventForm");
  const metadataCountries = useSelector(CacheSelector.metadataCountries());

  const expertCountry = getMetadataCountryFromThreeLetterCode(
    metadataCountries,
    referral?.expert.isoCountryCode
  );

  const formatTime = (utcTime: string) => {
    return dayjs
      .utc(utcTime)
      .tz(project.timezone)
      .format(EVENT_TWELVE_HOUR_TIME_FORMAT);
  };

  const formatDate = (utcTime: string) => {
    const date = dayjs.utc(utcTime).tz(project.timezone);
    const isToday = date.isSame(dayjs().tz(project.timezone), "day");

    return isToday
      ? t("timeSlotToday", {
          date: date.format(EVENT_DAY_SHORT_READABLE_DATE_WITHOUT_COMMA_FORMAT)
        })
      : date.format(EVENT_DAY_SHORT_READABLE_DATE_WITH_COMMA_FORMAT);
  };

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Form.Item>
          <Card>
            <AntDAvatar
              firstName={referral.expert.firstName}
              lastName={referral.expert.lastName}
              profileImageUrl={referral.expert.profileImageUrl}
              size={44}
              title={referral.expert.title}
              lastPublicCompanyExpDate={
                referral.expert.lastPublicCompanyExpDate
              }
              countryTwoLetterCode={expertCountry?.twoLetterCode}
              countryName={expertCountry?.name}
            />
            <Divider style={{ margin: "16px 0" }} />
            <Row gutter={[16, 16]}>
              {referral.expertAvailabilitySlots?.map((slot) => {
                const isSelected = selectedSlot === slot.startTime;

                return (
                  <Col key={slot.startTime} span={12}>
                    <Card
                      onClick={() => handleSelectSlot(slot)}
                      style={{
                        borderColor: isSelected
                          ? ARBOLUS_COLORS.bColorBasePurple
                          : ARBOLUS_COLORS.colorBorder,
                        cursor: "pointer",
                        transition: "border-color 0.2s ease-in-out"
                      }}
                      styles={{
                        body: { padding: "16px 24px" }
                      }}
                    >
                      <Flex align="flex-start" gap={8}>
                        <Checkbox
                          checked={isSelected}
                          style={{ pointerEvents: "none" }}
                        />
                        <Flex vertical>
                          <Text strong>{`${formatTime(
                            slot.startTime
                          )} - ${formatTime(slot.endTime)}`}</Text>
                          <Text>{formatDate(slot.startTime)}</Text>
                        </Flex>
                      </Flex>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Form.Item>
      </Col>
    </Row>
  );
};
