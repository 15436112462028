import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ResponseAllExpertsTable,
  StatusExpertTable
} from "@arbolus-technologies/api";
import {
  BainConfirmation,
  DefaultRejectFeedbackExpert,
  RejectFeedbackExpert
} from "@arbolus-technologies/features/common";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { EXPERT_STATUS } from "@arbolus-technologies/models/expert";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  ProjectExpertsSelector,
  ProjectExpertsStoreActions
} from "@arbolus-technologies/stores/project-experts-store";
import {
  ApproveRejectButtons,
  DeleteModal as Modal,
  ModalWithChildren
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./ApproveRejectAction.module.scss";

interface ApproveRejectActionProps {
  row: ResponseAllExpertsTable;
  feedbackExpert?: RejectFeedbackExpert;
}

const bainId = process.env.NX_PUBLIC_BAIN_ID;

export const ApproveRejectAction: React.FC<ApproveRejectActionProps> = ({
  row,
  feedbackExpert = DefaultRejectFeedbackExpert
}) => {
  const { t } = useTranslation("expertsList");
  const dispatch = useDispatch();

  const [isApproveModal, setIsApproveModal] = useState(false);
  const [bainModalOpen, setBainModalOpen] = useState(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const expertCurrentCompanies = useSelector(
    ProjectExpertsSelector.expertCurrentCompanies()
  );
  const loggedInUserClientId = useSelector(
    CacheSelector.loggedInUserClientId()
  );
  const isBain = loggedInUserClientId === bainId;

  if (row.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC) {
    return null;
  }

  const handleOnReject = () => {
    feedbackExpert.setData({
      projectId: row.projectId,
      referralId: row.id,
      expert: {
        ...row.expert,
        id: "",
        expertId: row.expert.id,
        profileImageUrl: row.expert.profileImageUrl || ""
      }
    });
    dispatch(PanelStoreActions.openPanel(PanelId.RejectFeedback));
  };

  const openBainModal = () => {
    setBainModalOpen(true);
  };

  const openApproveModal = () => {
    setIsApproveModal(true);
  };

  const renderStatus = () => {
    const isApplicationRejected =
      row.applicationStatus === StatusExpertTable.Reject;

    switch (row.reviewStatus) {
      case StatusExpertTable.Pending:
        if (row.fastTrack && isApplicationRejected) {
          return <span className={styles.status}>{t("Declined")}</span>;
        }

        return (
          <ApproveRejectButtons
            handleOnApprove={isBain ? openBainModal : openApproveModal}
            handleOnReject={handleOnReject}
            disabled={isBain && isAdmin}
          />
        );
      case StatusExpertTable.Accept:
        return (
          <span className={styles.status}>
            {t(isApplicationRejected ? "Declined" : "Approved")}
          </span>
        );
      case StatusExpertTable.Reject:
        return (
          <span className={styles.status}>
            {t(isApplicationRejected ? "Declined" : "Rejected")}
          </span>
        );
    }
  };

  return (
    <div className={styles.container}>
      {renderStatus()}
      <Modal
        toggleModal={isApproveModal}
        messageTitle={`${t("approveExpert")} ${displayUserName({
          firstName: row.expert.firstName,
          lastName: row.expert.lastName,
          email: row.expert.email
        })}`}
        warningMessage={t("areYouSure")}
        deleteActionText={t("approve")}
        onConfirmButtonColor
        onCancel={() => setIsApproveModal(false)}
        onConfirm={() => {
          dispatch(
            ProjectExpertsStoreActions.updateReferralStatus(
              row.projectId,
              row.id,
              { review: EXPERT_STATUS.ACCEPT }
            )
          );
          setIsApproveModal(false);
        }}
      />
      <ModalWithChildren
        toggleModal={bainModalOpen}
        messageTitle={t("companiesCID")}
        confirmActionText={t("passCID")}
        cancelActionText={t("reviewLater")}
        onCancel={() => setBainModalOpen(false)}
        onConfirm={() => {
          dispatch(
            ProjectExpertsStoreActions.updateReferralStatus(
              row.projectId,
              row.id,
              { review: EXPERT_STATUS.ACCEPT }
            )
          );
          setBainModalOpen(false);
        }}
      >
        <BainConfirmation
          expertId={row?.expert.id}
          expertCurrentCompanies={expertCurrentCompanies}
        />
      </ModalWithChildren>
    </div>
  );
};
