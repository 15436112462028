export const EX_LINK_WELCOME_BANNER = {
  USEFUL_TIPS:
    "https://help.arbolus.com/articles/56337-useful-tips-for-companies",
  HOW_TO_USE_SYSTEM:
    "https://help.arbolus.com/articles/55488-get-started-with-the-platform",
  HOW_TO_ADD_EXPERTS:
    "https://help.arbolus.com/articles/58909-how-do-i-invite-my-own-experts"
};

export const EX_LINK_EXPERT_WELCOME_BANNER = {
  USEFUL_TIPS:
    "https://help.arbolus.com/articles/55345-useful-tips-for-experts",
  HOW_TO_USE_SYSTEM:
    "https://help.arbolus.com/articles/56030-get-started-with-the-platform-as-an-expert"
};

export const EX_LINK_NOT_FOUND_PAGE = {
  ARBOLUS_HELP: "https://help.arbolus.com/",
  ARBOLUS_FOR_EXPERT: "https://www.arbolus.com/for-experts",
  ARBOLUS_FOR_COMPANIES: "https://www.arbolus.com/for-companies"
};
