import { Button } from "antd";
import { UserManager } from "oidc-client-ts";

import { REGISTER_CONFIG } from "../../constants/LinkedInAuthConfig";

const userManager = new UserManager(REGISTER_CONFIG);

function signIn() {
  userManager.signinRedirect();
}

export function LinkedInRegisterButton() {
  return (
    <Button type="primary" onClick={signIn}>
      LinkedIn
    </Button>
  );
}
