export const EXPERT_ENDPOINT = "/experts";

export const EXPERTS_API = {
  GET_WORK_HISTORY: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/work-history`,
  GET_COMPLIANCE_ANSWERS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/compliance-answers`,
  UPDATE_COMPLIANCE_ANSWERS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/compliance-answers`,
  GET_PROJECT_ANSWERS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/project-answers`,
  GET_PROJECT_ANSWERS_V2: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/angle-questions/answers`,
  UPDATE_PROJECT_ANSWERS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/project-answers`,
  CREATE_EXPERT: (): string => `${EXPERT_ENDPOINT}`,
  GET_EXPERTS: (): string => `${EXPERT_ENDPOINT}`,
  GET_EXPERT: (expertId: string): string => `${EXPERT_ENDPOINT}/${expertId}`,
  GET_EXPERT_RATES: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/rate`,
  UPDATE_EXPERT: (expertId: string): string => `${EXPERT_ENDPOINT}/${expertId}`,
  SAVE_RATE_CARD: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/rate-cards`,
  UPDATE_RATE_CARD: (expertId: string, rateCardId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/rate-cards/${rateCardId}`,
  UPDATE_STARRED: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/starred`,
  UPDATE_WORK_HISTORY: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/work-history`,
  UPDATE_EXPERT_COUNTRIES: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/countries`,
  UPDATE_EXPERT_INDUSTRIES: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/industries`,
  UPDATE_EXPERT_COMPANIES: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/companies`,
  GET_EXPERT_PROJECTS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/projects`,
  GET_CANDIDATE_PROJECTS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/candidate-projects`,
  REINVITE: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/resend-invitation`,
  GET_INVITE_LINK: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/invitation-link`,
  GET_EXPERT_ENGAGEMENT_REQUEST: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/engagement-request`,
  GET_INTERNAL_COMMENTS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/comments`,
  ADD_INTERNAL_COMMENTS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/comment`,
  UPDATE_INTERNAL_COMMENT: (expertCommentId: string): string =>
    `${EXPERT_ENDPOINT}/comments/${expertCommentId}`,
  DELETE_INTERNAL_COMMENT: (expertCommentId: string): string =>
    `${EXPERT_ENDPOINT}/comments/${expertCommentId}`,
  GET_SCREENING_QUESTIONS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/profile/project-questions`,
  GET_EXPERT_SHARED_PROJECTS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/shared-projects`,
  GET_PROJECT_NOTES: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/profile/project-notes`,
  GET_EXPERT_TRAINING: (): string => `${EXPERT_ENDPOINT}/training`,
  GET_EXPERT_PENDING_TRAINING: (): string =>
    `${EXPERT_ENDPOINT}/pending-trainings`,
  ACKNOWLEDGE_EXPERT_TRAINING: (expertTrainingId: string): string =>
    `${EXPERT_ENDPOINT}/training/${expertTrainingId}`,
  REQUEST_ENDORSEMENT: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/endorsements/request`,
  ADD_PROJECT_ANSWER: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/project-answers`,
  ADD_COMPLIANCE_ANSWERS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/compliance-answers`,
  GET_SAVED_CLIENTS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/saved-clients`,
  UPDATE_EXPERT_ACCOUNT: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}`,
  ADD_RATE_CARDS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/rate-cards`,
  UPDATE_RATE_CARDS: (expertId: string, rateCardId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/rate-cards/${rateCardId}`,
  DELETE_RATE_CARDS: (expertId: string, rateCardId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/rate-cards/${rateCardId}`,
  UPDATE_EXPERTS_SETTINGS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/settings`,
  UPDATE_EXPERTS_DNC_HISTORY_NOTE: (dncHistoryId: string): string =>
    `${EXPERT_ENDPOINT}/settings/expert-do-not-contact-history/${dncHistoryId}`,
  CHECK_INTERNAL_BY_EMAIL: (email: string): string =>
    `${EXPERT_ENDPOINT}/internal/check-by-email/${email}`,
  DEACTIVATE_EXPERT: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/deactivate`,
  REACTIVATE_EXPERT: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/reactivate`,
  GET_PROFILE_SURVEYS: (expertId: string): string =>
    `${EXPERT_ENDPOINT}/${expertId}/profile/surveys`
};
