import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router";
import { useDocumentTitle } from "usehooks-ts";
import { SurveyAlreadyCompletedFeedback } from "../../Components/SurveyAlreadyCompletedFeedback/SurveyAlreadyCompletedFeedback";

import { DASHBOARD } from "@arbolus-technologies/routes";
import { SurveyClosedFeedback } from "../../Components/SurveyClosedFeedback/SurveyClosedFeedback";
import { SurveyHeader } from "../../Components/SurveyHeader/SurveyHeader";
import styles from "./SurveyFeedbackPage.module.scss";

interface LocationState {
  isSurveyClosed: boolean;
  isSurveyCompleted: boolean;
}

export const SurveyFeedbackPage: React.FC = () => {
  const { t } = useTranslation("surveyFeedbackPage");
  useDocumentTitle(t("survey"));
  const location = useLocation<LocationState>();

  const { isSurveyClosed, isSurveyCompleted } = location.state || {};

  if (!location.state) {
    return <Redirect to={{ pathname: DASHBOARD }} />;
  }

  return (
    <div className={styles.container}>
      <SurveyHeader />
      {isSurveyClosed && !isSurveyCompleted && <SurveyClosedFeedback />}
      {isSurveyCompleted && <SurveyAlreadyCompletedFeedback />}
    </div>
  );
};
