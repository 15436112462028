import { Persona } from "@arbolus-technologies/models/common";
import { ICellRendererParams } from "ag-grid-community";
import { Tag, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";

export interface PersonaRendererProps
  extends Partial<ICellRendererParams<{ persona: Persona }>> {}

export function PersonaRenderer({ data }: PersonaRendererProps) {
  const { t } = useTranslation("");

  if (!data?.persona)
    return <Typography.Text type="secondary">-</Typography.Text>;

  if (data.persona === Persona.Influencer) {
    return (
      <Tooltip title={t("companyData:KDM")}>
        <Tag>{t("companyData:Influencer")}</Tag>
      </Tooltip>
    );
  }

  return <Tag>{t(`companyData:${data.persona}`)}</Tag>;
}
