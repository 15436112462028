import { GetContextMenuItemsParams, GridApi } from "ag-grid-community";
import i18next from "i18next";

import { SurveyExpert } from "@arbolus-technologies/api";
import { AgGridCustomIcons } from "@arbolus-technologies/ui/components";

import { BULK_ACTIONS } from "./BulkActionsStatusPanel";
import { SurveyExpertGridContext } from "./useSurveyExpertGridContext";

export type IBulkItemActionParams = {
  api: GridApi<SurveyExpert>;
} & SurveyExpertGridContext;

const t = (key: string) => i18next.t(`surveyExpertTable:${key}`);

export class SurveyExpertMenuService {
  modifySurveyExpertOwner = (params: IBulkItemActionParams) => {
    const { openBulkModifyOwner, api } = params;
    const surveyExperts = api.getSelectedRows();

    const action = () => {
      api.deselectAll();
      openBulkModifyOwner(surveyExperts);
    };

    return {
      action,
      name: t("modifyOwner"),
      icon: AgGridCustomIcons.sync,
      disabled: surveyExperts.length === 0
    };
  };

  getItems = ({
    api,
    context,
    value
  }: GetContextMenuItemsParams<SurveyExpert, SurveyExpertGridContext>) => {
    if (value === BULK_ACTIONS) {
      const bulkParams: IBulkItemActionParams = {
        api,
        ...context
      };

      return [this.modifySurveyExpertOwner(bulkParams)];
    }

    return [];
  };
}

export const DefaultSurveyExpertMenuService = new SurveyExpertMenuService();
