import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ToasterService,
  UserNotificationSubscription,
  UserService
} from "@arbolus-technologies/api";

import { NotificationSettingsItems } from "../../helpers/types";
import { NotificationSettingsItem } from "./NotificationSettingsItem/NotificationSettingsItem";

import styles from "./NotificationSettingsForm.module.scss";

interface SettingsFormProps {
  isLoading: boolean;
  notificationSettings: NotificationSettingsItems[];
  setNotificationSettings: (
    notificationSettings: NotificationSettingsItems[]
  ) => void;
  notificationService?: ToasterService;
}

const getNotificationSettingsToUpdateRequest = (
  notificationSettingsForm: NotificationSettingsItems[]
) =>
  notificationSettingsForm.reduce(
    (acc, item) => {
      const key = item.title.charAt(0).toUpperCase() + item.title.slice(1);
      acc[key] = item.value;
      return acc;
    },
    {} as { [key: string]: boolean }
  );

export const NotificationSettingsForm: React.FC<SettingsFormProps> = ({
  isLoading,
  notificationSettings,
  setNotificationSettings,
  notificationService = DefaultToasterService
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t } = useTranslation("notificationSettings");
  const [form] = Form.useForm();

  const { notificationSettingsForm } = Form.useWatch([], form) ?? {
    notificationSettingsForm: []
  };
  const submitButtonDisabled = isSaving || !form.isFieldsTouched();

  useEffect(() => {
    form.setFieldsValue({
      notificationSettingsForm: notificationSettings
    });
  }, [notificationSettings]);

  const handleUpdateNotificationSettings = (values: {
    notificationSettingsForm: NotificationSettingsItems[];
  }) => {
    setIsSaving(true);

    const notificationSettingsUpdated: { [key: string]: boolean } =
      getNotificationSettingsToUpdateRequest(values.notificationSettingsForm);

    UserService.updateUserNotificationSettings({
      notificationSubscription: {
        ...(notificationSettingsUpdated as unknown as UserNotificationSubscription),
        MobileMessage: !!notificationSettingsUpdated.MobileMessage
      }
    }).subscribe(
      () => {
        setIsSaving(false);
        setNotificationSettings(values.notificationSettingsForm);
        notificationService.showSuccess(t("profileSettingsSaveSuccess"));
      },
      (error: ErrorResponse<CIQError>) => {
        setIsSaving(false);
        notificationService.showError(error.message);
      }
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ notificationSettingsForm }}
      disabled={isLoading}
      onFinish={handleUpdateNotificationSettings}
      className={styles.itemsWrapper}
    >
      <Form.List name="notificationSettingsForm">
        {(fields) =>
          fields.map((field, index) => {
            const item = notificationSettings[index];
            return (
              <NotificationSettingsItem
                key={field.key}
                index={index}
                {...item}
              />
            );
          })
        }
      </Form.List>
      <div>
        <Button
          type="primary"
          htmlType="submit"
          disabled={submitButtonDisabled}
          loading={isSaving}
        >
          {t("saveChanges")}
        </Button>
      </div>
    </Form>
  );
};
