import { Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { EVENT_FORM } from "@arbolus-technologies/models/project";
import { MAX_EVENT_NOTES_LENGTH } from "@arbolus-technologies/utils";

const { TextArea } = Input;
const { NOTES } = EVENT_FORM;

export const EventNotes: React.FC = () => {
  const { t } = useTranslation("eventForm");

  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label={t("eventNotes")}
          name={NOTES}
          rules={[
            {
              max: MAX_EVENT_NOTES_LENGTH,
              message: t("eventNotesMaxLength", {
                maxLength: MAX_EVENT_NOTES_LENGTH
              })
            }
          ]}
        >
          <TextArea rows={2} placeholder={t("eventNotesPlaceholder")} />
        </Form.Item>
      </Col>
    </Row>
  );
};
