import { Col, Form, Input, Row } from "antd";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import { TextWithGreenTick } from "@arbolus-technologies/ui/components";

import styles from "./NewPasswordField.module.scss";

export const PASSWORD_REQUIREMENTS = {
  MIN_8_CHARS: i18next.t("newExpertProfileEditPage:passwordRule8Chars"),
  SPECIAL_CHAR: i18next.t("newExpertProfileEditPage:passwordRuleSpecialChars"),
  MIX_LOWER_UPPER: i18next.t("newExpertProfileEditPage:passwordRuleLowerUpper"),
  INCLUDE_DIGIT: i18next.t("newExpertProfileEditPage:passwordRule1digit")
};

export function NewPasswordField({
  value,
  onChange
}: { value: string; onChange: React.ChangeEventHandler<HTMLInputElement> }) {
  const { t } = useTranslation("newExpertProfileEditPage");
  const { status, errors } = Form.Item.useStatus();
  const otherErrors = errors.filter(
    (e) => !Object.values(PASSWORD_REQUIREMENTS).includes(e as string)
  );

  return (
    <Row>
      <Col span={12}>
        <Input.Password
          value={value}
          onChange={onChange}
          placeholder={t("newPasswordPlaceholder")}
        />
        {otherErrors.length > 0 &&
          otherErrors.map((error) => (
            <p key={error as string} className={styles.error}>
              {error}
            </p>
          ))}
      </Col>

      <Col span={12}>
        <div className={styles.passwordRules}>
          <Row gutter={[8, 8]}>
            {Object.values(PASSWORD_REQUIREMENTS).map((errorType) => {
              return (
                <Col key={errorType} span={12}>
                  <TextWithGreenTick
                    content={errorType}
                    checked={
                      status === "" ? false : !errors.includes(errorType)
                    }
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </Col>
    </Row>
  );
}
