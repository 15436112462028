import {
  Modal as AntDModal,
  Button,
  Card,
  Flex,
  Radio,
  RadioChangeEvent,
  Tag,
  Typography
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  SurveyExpert,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  SURVEY_EXPERT_PAYMENT_METHOD,
  SURVEY_PAYMENT_PAGE
} from "@arbolus-technologies/models/survey";
import { PAYMENTS } from "@arbolus-technologies/routes";

import styles from "./PreferredPaymentMethod.module.scss";

interface PreferredPaymentMethodProps {
  handleScreenSelection: (screen: SURVEY_PAYMENT_PAGE) => void;
  surveyExpert: SurveyExpert;
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

const { Text, Title } = Typography;

export const PreferredPaymentMethod: React.FC<PreferredPaymentMethodProps> = ({
  handleScreenSelection,
  surveyExpert,
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("preferredPaymentMethod");
  const history = useHistory();
  const { trackClick, trackRedirect } = useArbolusTracking();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] =
    useState<SURVEY_EXPERT_PAYMENT_METHOD>(
      surveyExpert.paymentMethod ?? SURVEY_EXPERT_PAYMENT_METHOD.BANK_TRANSFER
    );
  const isPaymentMethodAlreadySelected = surveyExpert.paymentMethod !== null;

  const onPaymentMethodChange = (event: RadioChangeEvent) => {
    setPaymentMethod(event.target.value);
  };

  const handleProceed = () => {
    trackClick(MixPanelEventNames.SurveyPaymentProceed);

    surveyService
      .selectSurveyExpertPaymentMethod(
        surveyExpert.surveyExpertId,
        paymentMethod
      )
      .subscribe(
        () => {
          if (paymentMethod === SURVEY_EXPERT_PAYMENT_METHOD.BANK_TRANSFER) {
            trackRedirect(
              MixPanelEventNames.SurveyBankTransferPayments,
              PAYMENTS
            );
            history.push(PAYMENTS);
          }
          if (paymentMethod === SURVEY_EXPERT_PAYMENT_METHOD.GIFT_CARD) {
            handleScreenSelection(SURVEY_PAYMENT_PAGE.GIFT_CARD_SELECTED);
          }
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  const normalizePaymentMethod = (
    paymentMethod: SURVEY_EXPERT_PAYMENT_METHOD
  ) =>
    paymentMethod === SURVEY_EXPERT_PAYMENT_METHOD.BANK_TRANSFER
      ? t("bankTransferNorm")
      : t("giftCardNorm");

  return (
    <Card className={styles.paymentMethodContainer} bordered={false}>
      <Flex vertical gap={16}>
        <Title level={5}>{t("choosePaymentMethod")}</Title>
        <Text>{t("selectPaymentType")}</Text>
        <Radio.Group
          onChange={onPaymentMethodChange}
          value={paymentMethod}
          disabled={isPaymentMethodAlreadySelected}
        >
          <Flex vertical gap={8}>
            <Flex vertical>
              <Radio value={SURVEY_EXPERT_PAYMENT_METHOD.BANK_TRANSFER}>
                {t("bankTransfer")}
              </Radio>
              <Text type="secondary" className={styles.radioButtonText}>
                {t("bankTransferSubtitle")}
              </Text>
            </Flex>
            <Flex vertical>
              <Radio value={SURVEY_EXPERT_PAYMENT_METHOD.GIFT_CARD}>
                {t("giftCard")}
              </Radio>
              <Text type="secondary" className={styles.radioButtonText}>
                {t("giftCardSubtitle")}
              </Text>
            </Flex>
          </Flex>
        </Radio.Group>
        <Flex justify="flex-end">
          <AntDModal
            title={t("confirmPaymentMethod")}
            open={isModalOpen}
            onOk={handleProceed}
            onCancel={() => setIsModalOpen(false)}
            cancelText={t("cancelPaymentMethodButton")}
            okText={t("confirmPaymentMethodButton")}
          >
            {t("confirmPaymentMethodMessage", {
              paymentMethod: normalizePaymentMethod(paymentMethod)
            })}
          </AntDModal>
          {isPaymentMethodAlreadySelected && (
            <Tag
              style={{ height: "24px", marginRight: "auto", alignSelf: "end" }}
              color="success"
            >
              {t("paymentMethodSaved")}
            </Tag>
          )}
          <Button
            type="primary"
            onClick={() => setIsModalOpen(true)}
            className={styles.proceedButton}
            disabled={isPaymentMethodAlreadySelected}
          >
            {t("proceed")}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
