import { Card, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  PROJECT_REFERRAL_STATE,
  ProjectAnswer
} from "@arbolus-technologies/api";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";

import { ScreeningForm } from "./ScreeningForm";

import styles from "./Screening.module.scss";

const { Text } = Typography;

interface ScreeningProps {
  screeningInfo: ProjectAnswer[];
  expertId: string;
  referralStatus: PROJECT_REFERRAL_STATE;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  onUpdateScreeningAnswers: (answers: ProjectAnswer[]) => void;
}

export const Screening: React.FC<ScreeningProps> = ({
  screeningInfo,
  expertId,
  referralStatus,
  doNotContactStatus,
  onUpdateScreeningAnswers
}) => {
  const { t } = useTranslation("referralScreening");

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const canEdit =
    referralStatus !== PROJECT_REFERRAL_STATE.REJECT &&
    doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC;

  return (
    <Card>
      <Flex vertical gap={16}>
        <Text strong>{t("screeningQuestions")}</Text>
        {isAdmin && canEdit ? (
          <ScreeningForm
            screeningInfo={screeningInfo}
            expertId={expertId}
            onUpdateScreeningAnswers={onUpdateScreeningAnswers}
          />
        ) : (
          <ol className={styles.screeningList}>
            {screeningInfo.map((item) => (
              <li key={item.questionId}>
                <Flex key={item.questionId} gap={8} vertical>
                  <DangerouslySetInnerHTML
                    fontWeight={600}
                    text={item.question}
                  />
                  <DangerouslySetInnerHTML text={item.answer ?? "-"} />
                </Flex>
              </li>
            ))}
          </ol>
        )}
      </Flex>
    </Card>
  );
};
