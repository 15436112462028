import clsx from "clsx";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";

import { INBOX } from "@arbolus-technologies/routes";

import { CONTENT_PANEL } from "../../../../constants/app";
import {
  DASHBOARD,
  EXPERT_NETWORK,
  USERS
} from "../../../../constants/navigation/authRoutes";
import {
  CANDIDATE_PICKER_HASH,
  DOCUMENT_PANEL_HASH,
  EXPERT_APPLICATION_COMPLIANCE_FAILED_HASH,
  EXPERT_APPLICATION_DECLINED_HASH,
  EXPERT_INVITE_PANEL_HASH,
  INBOX_COMPLIANCE_CHECK_HASHES,
  PROJECT_EXPERTS_HASHES,
  PROJECT_MEMBER_PANEL_HASH,
  USER_PROFILE_PANEL_HASH
} from "../../../../constants/navigation/panelRoutes";
import { PROJECT_ROUTES } from "../../../../constants/navigation/projectRoutes";
import { EXPERT_NOTIFICATION_REJECTION_TYPE } from "../../../../constants/notifications";
import useContentPanelContext from "../../../../contexts/contentPanel/UseContentPanelContext";
import useAccessControlContext from "../../../../contexts/roleBasedAccess/UseAccessControlContext";
import accessControlRules from "../../../../contexts/roleBasedAccess/rbac-rules";
import { AuthSelector } from "../../../auth/store";
import { CIQExpertNetworkInvitePanel } from "../../../expertNetwork/components/panels";
import {
  CIQCandidatePicker,
  CIQDocViewer,
  CIQExpertProfile,
  CIQProjectTeam
} from "../../../project/components/panels";
import { CIQClientProfile, CIQExpertApplicationRejection } from "../panels";

const ContentPanelRouter: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { role } = useAccessControlContext();
  const { complianceManager } = useSelector(
    AuthSelector.authClientUserFeaturesSelector()
  );
  const invitationURL = useSelector(
    AuthSelector.authClientNetworkInvitationURLSelector()
  );

  const { setContent, closePanel, isPanelExpand, content, panelClass } =
    useContentPanelContext();

  useEffect(() => {
    const { pathname, hash } = location;
    const projectRouteMatch = matchPath<{ projectId: string }>(
      pathname,
      PROJECT_ROUTES
    );
    const usersRouteMatch = matchPath(pathname, USERS);
    const networkRouteMatch = matchPath(pathname, EXPERT_NETWORK);
    const inboxRouteMatch = matchPath(pathname, [DASHBOARD, INBOX]);
    const parsedHash = queryString.parse(hash);

    if (projectRouteMatch) {
      const { projectId } = projectRouteMatch.params;

      if (
        hash === `#${PROJECT_MEMBER_PANEL_HASH}` &&
        accessControlRules[role].includes("projectBase:clientMembers")
      ) {
        setContent(
          // @ts-ignore
          <CIQProjectTeam
            key={CONTENT_PANEL.TEAM_MANAGER}
            onPanelClose={closePanel}
          />,
          true
        );
      } else if (
        hash === `#${CANDIDATE_PICKER_HASH}` &&
        accessControlRules[role].includes("projectBase:candidatePicker")
      ) {
        setContent(
          // @ts-ignore
          <CIQCandidatePicker
            key={CONTENT_PANEL.CANDIDATE_PICKER}
            onPanelClose={closePanel}
          />,
          true,
          "panel-large"
        );
      } else if (parsedHash[`${DOCUMENT_PANEL_HASH}`]) {
        const docId = parsedHash[`${DOCUMENT_PANEL_HASH}`];
        setContent(
          <CIQDocViewer
            key={CONTENT_PANEL.DOCUMENT_VIEWER}
            documentId={docId as string}
            projectId={projectId}
            onPanelClose={closePanel}
          />,
          true
        );
      } else if (
        accessControlRules[role].includes("projectExperts:referralView") &&
        PROJECT_EXPERTS_HASHES.every((hashKey) => parsedHash[`${hashKey}`])
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { expertId, referralId } = parsedHash as any;

        setContent(
          <CIQExpertProfile
            key={CONTENT_PANEL.EXPERT_PROFILE_VIEWER}
            projectId={projectId}
            expertId={expertId}
            referralId={referralId}
          />,
          true,
          "panel-large"
        );
      }
    } else if (networkRouteMatch) {
      if (hash === `#${EXPERT_INVITE_PANEL_HASH}`) {
        setContent(
          <CIQExpertNetworkInvitePanel invitationURL={invitationURL} />,
          true
        );
      }
    } else if (usersRouteMatch) {
      if (parsedHash[`${USER_PROFILE_PANEL_HASH}`]) {
        const userId = parsedHash[`${USER_PROFILE_PANEL_HASH}`];

        setContent(
          <CIQClientProfile
            key={CONTENT_PANEL.USERS_PROFILE_PANEL}
            userId={userId as string}
            onPanelClose={closePanel}
          />,
          true,
          "panel-large"
        );
      }
    } else if (inboxRouteMatch) {
      if (accessControlRules[role].includes("inbox:applicationRejection")) {
        if (hash === `#${EXPERT_APPLICATION_DECLINED_HASH}`) {
          setContent(
            <CIQExpertApplicationRejection
              onClose={closePanel}
              rejectionType={EXPERT_NOTIFICATION_REJECTION_TYPE.Review}
            />,
            true
          );
        } else if (hash === `#${EXPERT_APPLICATION_COMPLIANCE_FAILED_HASH}`) {
          setContent(
            <CIQExpertApplicationRejection
              onClose={closePanel}
              rejectionType={EXPERT_NOTIFICATION_REJECTION_TYPE.Compliance}
            />,
            true
          );
        }
      } else if (
        complianceManager &&
        INBOX_COMPLIANCE_CHECK_HASHES.every(
          (hashKey) => parsedHash[`${hashKey}`]
        )
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { expertId, referralId, action, projectId } = parsedHash as any;

        setContent(
          <CIQExpertProfile
            key={CONTENT_PANEL.EXPERT_PROFILE_VIEWER}
            projectId={projectId}
            initialAction={action}
            expertId={expertId}
            referralId={referralId}
          />,
          true,
          "panel-large"
        );
      }
    }
  }, [
    location,
    setContent,
    closePanel,
    role,
    invitationURL,
    complianceManager
  ]);

  return (
    <>
      <div
        id="overlay-right-panel"
        className={clsx("overlay-right-panel", {
          disabled: !isPanelExpand
        })}
        onClick={closePanel}
      />
      <div
        className={clsx(`content-panel-container ${panelClass}`, {
          toggled: isPanelExpand
        })}
      >
        {content}
      </div>
    </>
  );
};

export default ContentPanelRouter;
