export const DOCUMENT_NAME_REGEX = /^[^!#$%^&*()[\]{};'",<>?\\|~`]+$/;
export const DOCUMENT_NAME_SANITIZE_REGEX = /[!#$%^&*()[\]{};'",<>?\\|~`]/;

// Password Guide
export const LOWER_UPPERCASE_REGEX = /(?=.*[a-z])(?=.*[A-Z])[A-Za-z]/s;
export const SPECIAL_CHARACTERS_REGEX =
  /^(?=.*[!@#$%^&*()_+=\-[\]{};':",.<>/?\\|~`])/;
export const DIGIT_REGEX_REQUIRED = /^.*\d/;

// Linkedin URL
export const LINKEDIN_URL_REGEX =
  /^https?:\/\/(?:[a-z]{2,3}\.)?linkedin\.com\/(?:in|pub|company|school)\/[\w\p{L}\-.'%&=]+\/?$/iu;

//Expert Linkedin URL
export const EXPERT_LINKEDIN_URL_REGEX =
  /^https:\/\/((www|[a-z]{2,3})\.)?linkedin\.com\/in\/[^\s]+$/u;

// Linkedin URN
export const LINKEDIN_URN_REGEX = /^\d+$/;

// canopy welcome pathname
export const CANOPY_WELCOME_ROUTE = /^\/canopy\/([\s\S]*.)\/welcome/;
export const MINIMUM_SEARCH_TERM_LENGTH = 3;
export const MAXIMUM_FRACTION_DIGITS = 2;
export const MINIMUM_OWNER_SEARCH_TERM_LENGTH = 1;

export const MAX_DOCUMENT_NAME_LENGTH = 100;

export const EventConstraints = {
  MIN_EVENT_NAME_LENGTH: 3,
  MAX_EVENT_NAME_LENGTH: 250,
  MAX_EVENT_NOTE_LENGTH: 2000,
  MAX_LOCATION_LENGTH: 300,
  MAX_EVENT_DURATION_IN_DAYS: 5
};

export const ProjectDetailConstraints = {
  MIN_PROJECT_NAME_LENGTH: 3,
  MAX_PROJECT_NAME_LENGTH: 500,
  MIN_OVERVIEW_LENGTH: 30,
  MAX_OVERVIEW_LENGTH: 8000,
  MIN_INTERNAL_OVERVIEW_LENGTH: 30,
  MAX_INTERNAL_OVERVIEW_LENGTH: 8000,
  MAX_CASE_CODE_LENGTH: 30
};

export const ClientSettingsConstraints = {
  MAX_COMPANY_NAME_LENGTH: 200,
  MAX_EXTERNAL_NAME_LENGTH: 200
};

export const UserConstraints = {
  MAX_NAME_LENGTH: 200,
  MAX_FIRST_NAME_LENGTH: 100,
  MAX_LAST_NAME_LENGTH: 100,
  MAX_TITLE_LENGTH: 200,
  MIN_PASSWORD_LENGTH: 8,
  MAX_EMAIL_LENGTH: 128
};

export const ExpertBriefConstraints = {
  MIN_NUMBER_OF_COUNTRY_SELECTION: 1,
  MIN_EXPERT_DETAILS_LENGTH: 20,
  MAX_EXPERT_DETAILS_LENGTH: 800
};

export const AuthConstraints = {
  MIN_PASSWORD_LENGTH: 8
};

export const ExpertProfileConstraints = {
  MAX_INTRODUCTION_LENGTH: 8000,
  MAX_BACKGROUND_LENGTH: 8000,
  MAX_COMPANY_NAME_LENGTH: 200,
  MAX_QUICK_FACT_LENGTH: 30,
  MAX_FUN_FACT_LENGTH: 30,
  MAX_HOURLY_RATE: 1e27,
  MIN_HOURLY_RATE: 0,
  MAX_ENDORSEMENT_LENGTH: 300
};

export const ExpertWorkConstraints = {
  MAX_POSITIONS: 10,
  MAX_JOB_DESCRIPTION_LENGTH: 4000
};

export const ScreeningQuestionConstraints = {
  MAX_QUESTION_LENGTH: 200,
  MIN_QUESTION_LENGTH: 5,
  MAX_QUESTIONS_NUMBER: 15,
  MAX_ANSWER_LENGTH: 1500
};

export const CompanyEnquiryConstraints = {
  MAX_COMPANY_NAME_LENGTH: 1000,
  ENQUIRER_NAME_MAX_LENGTH: 1000,
  ENQUIRY_MESSAGE_MAX_LENGTH: 10000
};

export const ProjectComplianceConstraints = {
  MAX_COMPLIANCE_ANSWER_LENGTH: 8000
};

export const CanopyConstraints = {
  MAX_CANOPY_TITLE_LENGTH: 100,
  MAX_CANOPY_OVERVIEW_LENGTH: 2000,
  MAX_INVOICE_VALUE: 100000000,
  MIN_INVOICE_VALUE: -100000000
};

export const GUEST_INVITE_MAX_COUNT = 50;

export const MAX_COMPLIANCE_COMMENT_LENGTH = 5000;

export const MAX_MESSAGE_LENGTH = 5000;

export const INPUT_DEBOUNCE = 500;
