import { Button, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

interface ShareThoughtsProps {
  onStartSurvey: () => void;
}

export const ShareThoughts: React.FC<ShareThoughtsProps> = ({
  onStartSurvey
}) => {
  const { t } = useTranslation("surveyShareThoughts");

  return (
    <Flex vertical gap={32} align="center">
      <Title level={3}>{t("shareThoughts")}</Title>
      <Button type="primary" size="large" onClick={onStartSurvey}>
        {t("startSurvey")}
      </Button>
    </Flex>
  );
};
