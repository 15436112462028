import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { User } from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  Avatar,
  IconWithText,
  PublicCompanyBadge
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./ExpertHeader.module.scss";

export interface ExpertHeaderProps {
  user: User;
  platformAgreementAccepted?: boolean;
  lastPublicCompanyExperienceDate?: Date;
  isLinkedinVerified: boolean;
}

export const ExpertHeader: React.FC<ExpertHeaderProps> = ({
  user,
  platformAgreementAccepted,
  lastPublicCompanyExperienceDate = null,
  isLinkedinVerified = false
}) => {
  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const timezones = useSelector(CacheSelector.timezones());
  const name = displayUserName(user);

  const { isoCountryCode, profileImageUrl, title, timezone } = user;

  const expertTimezone = timezones.find((t) => t.value === timezone)
    ?.label as string;
  const userTwoLetterCountryCode = metadataCountries.find(
    (metadataCountry) => metadataCountry.threeLetterCode === isoCountryCode
  );

  const twoLetterCode = userTwoLetterCountryCode?.twoLetterCode.toLowerCase();
  const countryName = userTwoLetterCountryCode?.name;

  return (
    <div className={styles.expertHeader}>
      <div className={styles.avatarContainer}>
        <Avatar
          avatar={{
            name: name,
            imageUrl: profileImageUrl
          }}
          type="bigCircle"
        />
        {platformAgreementAccepted && (
          <div className="referral-stat-indicator accepted" />
        )}
      </div>
      <div className={styles.userDetails}>
        <div className={styles.nameContainer}>
          <h1>{name}</h1>
          {isLinkedinVerified && (
            <AntDIcon
              name="verified_user"
              fontSize="24px"
              color={ARBOLUS_COLORS.bColorAccentGreenDark}
            />
          )}
          {lastPublicCompanyExperienceDate && (
            <PublicCompanyBadge date={lastPublicCompanyExperienceDate} />
          )}
        </div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.iconContainer}>
        {countryName && twoLetterCode && (
          <IconWithText text={{ content: countryName }}>
            <span
              className={clsx(
                `flag-icon flag-icon-${twoLetterCode}`,
                styles.icon
              )}
            />
          </IconWithText>
        )}
        {expertTimezone && (
          <IconWithText
            text={{ content: expertTimezone }}
            icon={{ name: "public" }}
          />
        )}
      </div>
    </div>
  );
};
