import { Button, Divider, Flex, Input } from "antd";
import React, { useEffect, useState } from "react";

import { AntDIcon } from "../Icon/AntDIcon";

interface AddNewItemProps {
  query: string;
  placeholder: string;
  buttonName: string;
  onAddItem: (name: string) => void;
}

export const AddNewItem: React.FC<AddNewItemProps> = ({
  query,
  placeholder,
  buttonName,
  onAddItem
}) => {
  const [name, setName] = useState("");

  useEffect(() => {
    setName(query);
  }, [query]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleAddItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    onAddItem(name);
    setName("");
  };

  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Flex justify="space-between" style={{ padding: "0 8px 4px" }}>
        <Input
          placeholder={placeholder}
          value={name}
          onChange={onNameChange}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <Button
          type="link"
          icon={<AntDIcon name="add" />}
          onClick={handleAddItem}
          disabled={!name}
        >
          {buttonName}
        </Button>
      </Flex>
    </>
  );
};
