export enum INBOX_NOTIFICATION_CATEGORY {
  ThingsTodo = "ThingsTodo",
  Messages = "Messages",
  EventInvites = "EventInvites",
  SharedDocs = "SharedDocs",
  NewJoins = "NewJoins",
  NewReferrals = "NewReferrals",
  Approvals = "Approvals",
  Applications = "Applications",
  Compliance = "Compliance",
  Canopies = "Surveys"
}

export enum INBOX_NOTIFICATION_TYPE {
  // Approvals
  AdminCreatesProjectNotification = "AdminCreatesProjectNotification",
  ExpertAddedToProjectClientNotification = "ExpertAddedToProjectClientNotification",
  ProjectBriefUpdatedNotification = "ProjectBriefUpdatedNotification",
  ProjectDraftReminderForUserNotification = "ProjectDraftReminderForUserNotification",
  ProjectLiveUserNotification = "ProjectLiveUserNotification",
  // Applications
  ExpertCandidateRejectedExpertNotification = "ExpertCandidateRejectedExpertNotification",
  ReferralResetExpertNotification = "ReferralResetExpertNotification",
  ApplicationCompletionNotification = "ApplicationCompletionNotification",
  // SharedDoc
  DocumentSharedWithClientNotification = "DocumentSharedWithClientNotification",
  DocumentSharedWithExpertNotification = "DocumentSharedWithExpertNotification",
  TranscriptAvailableNotification = "TranscriptAvailableNotification",
  // NewJoins
  MemberAcceptInvitationClientNotification = "MemberAcceptInvitationClientNotification",
  NewProjectMemberNotification = "NewProjectMemberNotification",
  // NewReferrals
  ExpertsAssignedToProjectNotification = "ExpertsAssignedToProjectNotification",
  ExpertCandidateRejectedTeamNotification = "ExpertCandidateRejectedTeamNotification",
  // Messages
  ExpertMessagesClientTeamNotification = "ExpertMessagesClientTeamNotification",
  // ThingsTodo
  ReferralComplianceForClientNotification = "ReferralComplianceForClientNotification",
  ExpertWelcomeNotification = "ExpertWelcomeNotification",
  ExpertCompleteProfileNotification = "ExpertCompleteProfileNotification",
  ExpertPrepareProjectNotification = "ExpertPrepareProjectNotification",
  ExpertBankingInfoUpdNotification = "ExpertBankingInfoUpdNotification",
  UserWelcomeNotification = "UserWelcomeNotification",
  UserInviteTeamNotification = "UserInviteTeamNotification",
  UserCreateProjectNotification = "UserCreateProjectNotification",
  UserBuildNetworkNotification = "UserBuildNetworkNotification",
  UserViewProjectNotification = "UserViewProjectNotification",
  ExpertAssignedToProjectNotification = "ExpertAssignedToProjectNotification",
  ExpertAddedToProjectExpertNotification = "ExpertAddedToProjectExpertNotification",
  ProjectUnreadMessagesNotification = "ProjectUnreadMessagesNotification",
  // Canopies
  CanopyLaunchedNotification = "CanopyLaunchedNotification",
  ExpertSubmittedCanopyNotification = "ExpertSubmittedCanopyNotification",
  // EventInvites
  EventNotification = "EventNotification"
}

export enum INBOX_NOTIFICATION_ACTION {
  COMPLIANCE = "COMPLIANCE"
}

export enum NOTIFICATION_ICON {
  APPLICATION_REVIEWED = "application-reviewed",
  CANDIDATE = "candidate",
  COMPLIANCE = "compliance",
  CROSS = "cross",
  DOCUMENT = "document",
  EVENT = "event",
  GENERIC = "generic",
  MESSAGE = "message",
  NEW_PROJECT = "new-project",
  NEW_PROJECT_MEMBER = "new-project-member",
  ON_BOARDING = "on-boarding",
  PROJECT_BRIEF = "project-brief",
  ROCKET = "rocket",
  STAR = "star",
  SUPPORT_CHAT = "support-chat",
  TICK = "tick",
  UNREAD_MESSAGE = "unread-message",
  USER = "user"
}

export enum EVENT_NOTIFICATION_STATUS {
  New = "New",
  Modified = "Modified"
}

export enum EXPERT_NOTIFICATION_REJECTION_TYPE {
  Review = "Review",
  Compliance = "Compliance"
}

export const clientCategories = [
  INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
  INBOX_NOTIFICATION_CATEGORY.Messages,
  INBOX_NOTIFICATION_CATEGORY.EventInvites,
  INBOX_NOTIFICATION_CATEGORY.SharedDocs,
  INBOX_NOTIFICATION_CATEGORY.NewReferrals,
  INBOX_NOTIFICATION_CATEGORY.Compliance,
  INBOX_NOTIFICATION_CATEGORY.Approvals,
  INBOX_NOTIFICATION_CATEGORY.NewJoins,
  INBOX_NOTIFICATION_CATEGORY.Canopies
];

export const expertCategories = [
  INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
  INBOX_NOTIFICATION_CATEGORY.Messages,
  INBOX_NOTIFICATION_CATEGORY.EventInvites,
  INBOX_NOTIFICATION_CATEGORY.SharedDocs,
  INBOX_NOTIFICATION_CATEGORY.NewReferrals,
  INBOX_NOTIFICATION_CATEGORY.Applications
];

export const MultipleInboxCategoryMap = new Map<
  INBOX_NOTIFICATION_TYPE,
  INBOX_NOTIFICATION_CATEGORY[]
>([
  // Client Inbox Notifications
  [
    INBOX_NOTIFICATION_TYPE.AdminCreatesProjectNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.Approvals
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.DocumentSharedWithClientNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.SharedDocs
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectClientNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.NewReferrals
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.ExpertsAssignedToProjectNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.NewReferrals
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.ProjectDraftReminderForUserNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.Approvals,
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo
    ]
  ],
  // Expert Inbox Notifications
  [
    INBOX_NOTIFICATION_TYPE.ExpertAssignedToProjectNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.Approvals
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectExpertNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.Approvals
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.ReferralResetExpertNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.Approvals
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.ProjectUnreadMessagesNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.Messages
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.DocumentSharedWithExpertNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.SharedDocs
    ]
  ],
  [
    INBOX_NOTIFICATION_TYPE.TranscriptAvailableNotification,
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      INBOX_NOTIFICATION_CATEGORY.SharedDocs
    ]
  ]
]);

export enum NOTIFICATION_VERSION_TYPE {
  V1 = "V1",
  V2 = "V2" // CIQ-1365 FE - Inbox APIs - Allow messages to be in two categories
}
