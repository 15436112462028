import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  AgreementType,
  CIQError,
  ErrorResponse,
  MetaService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  useArbolusTracking,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import { ProjectApplicationStepProps } from "@arbolus-technologies/models/project";
import { EXPERT_PROJECT_APPLICATION_IDLE_TIMEOUT_HOURS } from "@arbolus-technologies/utils";

import { EngagementAgreement } from "../../../../../models/meta";
import AgreementPane from "../../createProject/agreement/AgreementPane";
import { ProjectApplicationWrapper } from "../ProjectApplicationWrapper/ProjectApplicationWrapper";

import styles from "./ProjectAgreement.module.scss";

const notification = new ToasterService();

interface ProjectAgreementProps extends ProjectApplicationStepProps {
  engagementAgreement: EngagementAgreement;
  onSaveProgress: () => void;
}

const ProjectAgreement: React.FC<ProjectAgreementProps> = ({
  engagementAgreement,
  onSaveProgress,
  stepDetails,
  onNext,
  onBack,
  isLoading
}: ProjectAgreementProps) => {
  const { t } = useTranslation("projectApplication");
  const { projectId } = useParams<{ projectId: string }>();
  const { trackClick } = useArbolusTracking();
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.ExpertUserProjectApplicationEngagementTime
  );

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime();
    };
  }, []);

  const accessedTime: Moment = moment();

  const handleIdleTime = (): void => {
    const currentMoment = moment();
    const idleTime = currentMoment.diff(accessedTime, "hour");

    if (idleTime >= EXPERT_PROJECT_APPLICATION_IDLE_TIMEOUT_HOURS) {
      notification.showError(t("selectTimeSlotsAgain"));
      onBack?.();
    } else {
      trackClick(MixPanelEventNames.ExpertUserProjectApplicationEngagement);
      onNext?.();
    }
  };

  const handleProjectAgreementDownload = () => {
    MetaService.getEngagementAgreement(
      AgreementType.EXPERT,
      undefined,
      projectId
    ).subscribe(
      (response) => {
        window.open(response.agreementUrl, "_blank");
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
      }
    );
  };

  return (
    <PageTracker
      page={MixpanelPages.ExpertUserProjectApplicationPageEngagement}
    >
      <ProjectApplicationWrapper
        onNext={handleIdleTime}
        stepDetails={stepDetails}
        isValid
        dirty
        onSaveProgress={onSaveProgress}
        onBack={onBack}
        isLoading={isLoading}
        downloadProjectAgreement={handleProjectAgreementDownload}
      >
        <div className={styles.projectAgreementContainer}>
          <h1>{t("engagementAgreement")}</h1>
          <AgreementPane engagementAgreement={engagementAgreement} />
        </div>
      </ProjectApplicationWrapper>
    </PageTracker>
  );
};

export default ProjectAgreement;
