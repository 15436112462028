import { Flex, Typography } from "antd";
import styles from "./NoMonitoringListPage.module.scss";

interface ContentTextItemProps {
  id: number;
  title: string;
}

export function ContentTextItem({ id, title }: ContentTextItemProps) {
  return (
    <Flex gap={8}>
      <div className={styles.itemNumber}>{id}</div>
      <Typography.Text>{title}</Typography.Text>
    </Flex>
  );
}
