import React from "react";
import { useTranslation } from "react-i18next";

import { ExpertSurvey } from "@arbolus-technologies/api";

import { Col, Flex, Row, Spin, Typography } from "antd";
import { ExpertSurveyCard } from "../../Components/ExpertSurveyCard/ExpertSurveyCard";

interface ExpertSurveyListProps {
  expertSurveys: ExpertSurvey[];
  isLoading: boolean;
}

const { Text } = Typography;

export const ExpertSurveyList: React.FC<ExpertSurveyListProps> = ({
  expertSurveys,
  isLoading
}) => {
  const { t } = useTranslation("expertSurveyList");

  return (
    <Row gutter={[24, 24]}>
      {expertSurveys.length === 0 ? (
        <Text style={{ paddingLeft: 12 }}>{t("noSurveys")}</Text>
      ) : (
        <>
          {expertSurveys.map((expertSurvey) => (
            <Col key={expertSurvey.surveyExpertId} xs={24} sm={12} lg={8}>
              <ExpertSurveyCard expertSurvey={expertSurvey} />
            </Col>
          ))}
          {isLoading && (
            <Col xs={24} sm={12} lg={8}>
              <Flex
                justify="center"
                align="center"
                style={{
                  height: "100%"
                }}
              >
                <Spin size="large" />
              </Flex>
            </Col>
          )}
        </>
      )}
    </Row>
  );
};
