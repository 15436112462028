import { Observable } from "rxjs";

import {
  SURVEY_EXPERT_PAYMENT_METHOD,
  SURVEY_EXPERT_STATUS,
  SURVEY_ORDER_BY,
  SURVEY_STATUS
} from "@arbolus-technologies/models/survey";

import { SURVEY_API } from "../constants/api";
import { SORT_DIRECTION } from "../enums/apiEnums";
import { MultiSortRequest } from "../helpers";
import {
  ApiPaginatedResponse,
  CreatedResponse,
  SuccessResponse
} from "../models/api";
import { FileResponse } from "../models/common";
import {
  BaseSurvey,
  ExpertSurvey,
  ExpertSurveyListRequest,
  ExportSurveyExpertListRequest,
  LandingSurvey,
  Survey,
  SurveyExpert,
  SurveyExpertListRequest,
  UpdateSurvey
} from "../models/survey";
import { restService } from "../restService";

export const SurveyService = {
  getAllSurveysForAProject: ({
    projectId,
    surveyStatus,
    searchTerm,
    limit = 100,
    offset = 0
  }: {
    projectId: string;
    surveyStatus?: SURVEY_STATUS;
    searchTerm?: string;
    limit?: number;
    offset?: number;
  }): Observable<ApiPaginatedResponse<BaseSurvey>> =>
    restService.get(SURVEY_API.GET_PROJECT_SURVEYS(projectId), {
      surveyStatus,
      searchTerm,
      limit,
      offset,
      orderBy: SURVEY_ORDER_BY.CREATED,
      orderDirection: SORT_DIRECTION.DESCENDING
    }),
  getSurvey: (surveyId: string): Observable<Survey> =>
    restService.get(SURVEY_API.GET_SURVEY(surveyId)),
  createSurvey: (surveyDetails: UpdateSurvey): Observable<CreatedResponse> =>
    restService.post(SURVEY_API.CREATE_SURVEY(), surveyDetails),
  updateSurvey: (surveyDetails: UpdateSurvey): Observable<CreatedResponse> =>
    restService.put(SURVEY_API.UPDATE_SURVEY(surveyDetails.id!), surveyDetails),
  closeSurvey: (surveyId: string): Observable<CreatedResponse> =>
    restService.patch(SURVEY_API.CLOSE_SURVEY(surveyId), {
      status: SURVEY_STATUS.CLOSED
    }),
  getSurveyExperts: (
    surveyId: string,
    queryParams: MultiSortRequest<SurveyExpertListRequest>
  ): Observable<ApiPaginatedResponse<SurveyExpert>> =>
    restService.get<ApiPaginatedResponse<SurveyExpert>>(
      SURVEY_API.GET_SURVEY_EXPERTS(surveyId),
      queryParams
    ),
  getSurveyPublic: (surveyId: string): Observable<LandingSurvey> =>
    restService.get(SURVEY_API.GET_SURVEY_PUBLIC(surveyId)),
  getSurveyExpertStatus: (
    surveyId: string
  ): Observable<{ surveyExpertStatus: SURVEY_EXPERT_STATUS }> =>
    restService.get(SURVEY_API.GET_SURVEY_EXPERT_STATUS(surveyId)),
  startExpertSurvey: (surveyId: string): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      SURVEY_API.START_EXPERT_SURVEY(surveyId),
      {}
    ),
  selectSurveyExpertPaymentMethod: (
    surveyExpertId: string,
    paymentMethod: SURVEY_EXPERT_PAYMENT_METHOD
  ): Observable<SuccessResponse> =>
    restService.patch<SuccessResponse>(
      SURVEY_API.SURVEY_EXPERT_PAYMENT_METHOD(surveyExpertId),
      { paymentMethod }
    ),
  completeSurveyExpert: (surveyExpertId: string): Observable<SuccessResponse> =>
    restService.patch<SuccessResponse>(
      SURVEY_API.COMPLETE_SURVEY_EXPERT(surveyExpertId),
      {}
    ),
  getSurveyExpert: (surveyExpertId: string): Observable<SurveyExpert> =>
    restService.get(SURVEY_API.GET_SURVEY_EXPERT(surveyExpertId)),
  getExpertSurveys: (
    queryParams: ExpertSurveyListRequest
  ): Observable<ApiPaginatedResponse<ExpertSurvey>> =>
    restService.get<ApiPaginatedResponse<ExpertSurvey>>(
      SURVEY_API.GET_EXPERT_SURVEYS(queryParams.expertId),
      queryParams
    ),
  exportSurveyExperts: (
    surveyId: string,
    exportSurveyExpertListRequest: ExportSurveyExpertListRequest
  ): Observable<FileResponse> =>
    restService.get<FileResponse>(
      SURVEY_API.EXPORT_SURVEY_EXPERTS(surveyId),
      exportSurveyExpertListRequest
    )
};
