import { ClassProperties } from "@arbolus-technologies/utils";

import { RULE_TYPE_ENUM } from "../Clients";
import { AngleModel, ExpertTaglineModel } from "../Projects";
import { ConfirmedEmailUserModel } from "../User";
import { DateString } from "../types";
import { Slot } from "./Slot";
import {
  APPLICATION_STATUS,
  COMPLIANCE_STATUS,
  REFERRAL_STAGE,
  REFERRAL_SUB_STATUS
} from "./enums";
import { RejectDescription } from "./interfaces";

export type PENDING_GATEKEEPING_ENUM = Omit<
  RULE_TYPE_ENUM,
  RULE_TYPE_ENUM.Email_Notification
>;

export type IReferral = Omit<
  ClassProperties<Referral>,
  | "expertName"
  | "isBookable"
  | "onlyMissingReview"
  | "updateDate"
  | "modified"
  | "compliance"
  | "hasMissingWorkHistoryLocation"
> & {
  updateDate: DateString | Date;
  modified: DateString | Date;
  compliance: REFERRAL_SUB_STATUS | COMPLIANCE_STATUS;
  hasMissingWorkHistoryLocation: boolean;
};

export class Referral {
  private _expertName?: string;

  id!: string;
  expert!: ExpertTaglineModel;
  owner?: ConfirmedEmailUserModel;
  angle!: AngleModel;
  stage!: REFERRAL_STAGE;
  review!: REFERRAL_SUB_STATUS;
  application!: APPLICATION_STATUS;
  hasMissingWorkHistoryLocation!: boolean;
  compliance!: COMPLIANCE_STATUS;
  updateDate!: Date;
  modified!: Date;
  rejectDescription?: RejectDescription;
  pendingGateKeepingTypes?: PENDING_GATEKEEPING_ENUM[];

  slots?: Slot[];

  get expertName(): string {
    if (!this._expertName) {
      this._expertName = `${this.expert.firstName} ${this.expert.lastName}`;
    }

    return this._expertName;
  }

  get isBookable(): boolean {
    return (
      this.application === APPLICATION_STATUS.ACCEPT &&
      this.compliance === COMPLIANCE_STATUS.ACCEPT &&
      this.stage === REFERRAL_STAGE.APPROVED &&
      this.review === REFERRAL_SUB_STATUS.ACCEPT
    );
  }

  get onlyMissingReview(): boolean {
    return (
      this.application === APPLICATION_STATUS.ACCEPT &&
      this.compliance === COMPLIANCE_STATUS.ACCEPT &&
      this.stage === REFERRAL_STAGE.CANDIDATE
    );
  }

  public static fromObject(json: IReferral): Referral {
    const referral = {
      ...json,
      updateDate: new Date(json.updateDate),
      modified: new Date(json.modified),
      compliance:
        json.application === APPLICATION_STATUS.ACCEPT
          ? (json.compliance as COMPLIANCE_STATUS)
          : COMPLIANCE_STATUS.UNAVAILABLE
    };
    return Object.assign(new Referral(), referral);
  }

  // should be overwritten by child classes
  public createUpdated(
    updatedValues: Partial<ClassProperties<Referral>>
  ): Referral {
    return Object.assign(new Referral(), { ...this, ...updatedValues });
  }

  public setSlots(slots: Slot[]): void {
    this.slots = slots;
  }
}
