export { BASE_URL } from "@arbolus-technologies/utils";

// Endpoints
const USERS_ENDPOINT = `/users`;
const PROJECT_ENDPOINT = `/projects`;
const PROJECTDRAFT_ENDPOINT = `/projectdrafts`;
const CLIENTS_ENDPOINT = `/clients`;
const METADATA_ENDPOINT = `/metadata`;
const PROJECT_CLIENTS_ENDPOINT = `/projectclients`;
const PROJECT_EXPERT_ENDPOINT = `/projectexperts`;
const ENDORSEMENTS_ENDPOINT = "/endorsements";

// METADATA
export const METADATA_API = {
  GET_COUNTRIES: (): string => `${METADATA_ENDPOINT}/countries`,
  GET_INDUSTRIES: (): string => `${METADATA_ENDPOINT}/industries`,
  GET_TIMEZONES: (): string => `${METADATA_ENDPOINT}/timezones`,
  GET_REGIONS: (): string => `${METADATA_ENDPOINT}/regions`,
  GET_CURRENCIES: (): string => `${METADATA_ENDPOINT}/currencies`,
  GET_ENGAGEMENT_AGREEMENT: (): string =>
    `${METADATA_ENDPOINT}/engagement-agreement`,
  POST_APP_CRASH_DATA: (): string => `${METADATA_ENDPOINT}/client-crash`,
  GET_FEATURE_FLAGS: (): string => `${METADATA_ENDPOINT}/feature-flags`,
  GET_PROVIDER_INDUSTRIES: (): string =>
    `${METADATA_ENDPOINT}/provider-industries`
};

// USERS
export const USERS_API = {
  ACCEPT_TERM: (termId: string): string =>
    `${USERS_ENDPOINT}/terms-and-conditions/${termId}/accept`,
  ADD_USER_PROFILE_IMAGE: (userId: string): string =>
    `${USERS_ENDPOINT}/${userId}/profile-image`,
  FEDERATED_REGISTER: (): string => `${USERS_ENDPOINT}/open-id/register`,
  GET_LATEST_TERM: (): string =>
    `${USERS_ENDPOINT}/terms-and-conditions/latest`,
  GET_PROFILE: (): string => `${USERS_ENDPOINT}/profile`,
  LOGIN: (): string => `${USERS_ENDPOINT}/login`,
  LOGOUT: (): string => `${USERS_ENDPOINT}/logout`,
  OPEN_ID: (): string => `${USERS_ENDPOINT}/open-id`,
  PUSHER_AUTHENTICATE: (): string => `${USERS_ENDPOINT}/pusher-authenticate`,
  REFRESH_TOKEN: (): string => `${USERS_ENDPOINT}/token`,
  REGISTER: (): string => `${USERS_ENDPOINT}/register`,
  REQUEST_RESET_PASSWORD: (): string => `${USERS_ENDPOINT}/password`,
  UPDATE_USER_PROFILE: (userId: string): string =>
    `${USERS_ENDPOINT}/${userId}`,
  USER_PASSWORD_CHANGE: (): string => `${USERS_ENDPOINT}/password/reset`,
  REINVITE_EXPIRED_INVITATION: (): string =>
    `${USERS_ENDPOINT}/resend-invitation`,
  GET_NOTIFICATION_CATEGORIES: (): string =>
    `${USERS_ENDPOINT}/notifications/categories`,
  GET_NOTIFICATIONS: (): string => `${USERS_ENDPOINT}/notifications`,
  UPDATE_NOTIFICATIONS_SETTINGS: (): string =>
    `${USERS_ENDPOINT}/notifications/settings`,
  SEND_FCM_TOKEN: (): string => `${USERS_ENDPOINT}/notifications/fcm-token`,
  UPDATE_STARRED_EXPERT: (expertId: string): string =>
    `${USERS_ENDPOINT}/starred-expert/${expertId}`,
  DELETE_STARRED_EXPERT: (expertId: string): string =>
    `${USERS_ENDPOINT}/starred-expert/${expertId}`
};

// EXPERT ENDORSEMENT
export const ENDORSEMENTS_API = {
  ADD_EXPERT_ENDORSEMENT: (): string => `${ENDORSEMENTS_ENDPOINT}`
};

// PROJECT DRAFTS
export const PROJECTDRAFT_API = {
  GET_PROJECTDRAFTS: (): string => `${PROJECTDRAFT_ENDPOINT}`,
  CREATE_PROJECTDRAFT: (): string => `${PROJECTDRAFT_ENDPOINT}`,
  GET_PROJECTDRAFT: (draftId: string): string =>
    `${PROJECTDRAFT_ENDPOINT}/${draftId}`,
  UPDATE_PROJECTDRAFT: (draftId: string): string =>
    `${PROJECTDRAFT_ENDPOINT}/${draftId}`,
  DELETE_PROJECTDRAFT: (draftId: string): string =>
    `${PROJECTDRAFT_ENDPOINT}/${draftId}`
};

// ProjectClients
export const PROJECT_CLIENTS_API = {
  GET_PROJECTS: (): string => `${PROJECT_CLIENTS_ENDPOINT}`,
  GET_PROJECTS_LIST: (): string => `${PROJECT_CLIENTS_ENDPOINT}/all`,
  GET_PROJECT_SUPPORT: (projectId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/support`,
  GET_DOCUMENTS: (projectId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/documents`,
  GET_NOTES_AND_DOCS: (projectId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/notes-and-docs`,
  CREATE_DOCUMENT: (projectId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/documents`,
  GET_DOCUMENT_STATUS: (projectId: string, documentId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/documents/${documentId}/status`
};

// ProjectExperts
export const PROJECT_EXPERTS_API = {
  GET_PROJECTS: (): string => `${PROJECT_EXPERT_ENDPOINT}`,
  GET_PROJECTS_LIST: (): string => `${PROJECT_EXPERT_ENDPOINT}/all`,
  GET_PROJECT_SUPPORT: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/support`,
  GET_DOCUMENTS: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/documents`,
  GET_NOTES_AND_DOCS: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/notes-and-docs`,
  CREATE_DOCUMENT: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/documents`,
  GET_DOCUMENT_STATUS: (projectId: string, documentId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/documents/${documentId}/status`,
  GET_PROJECT_APPLICATION_BRIEF: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}`,
  ACCEPT_PROJECT_AGREEMENT: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/referrals/status`,
  REFER_EXPERT: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/refer-expert`,
  GET_RATE: (): string => `${PROJECT_EXPERT_ENDPOINT}/rate`
};

// Projects
export const PROJECTS_API = {
  CREATE_PROJECT: (): string => `${PROJECT_ENDPOINT}`,
  GET_PROJECT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}`,
  EDIT_PROJECT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}`,
  GET_NOTES: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/notes`,
  GET_PROPOSALS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/proposals`,
  GET_MEMBERS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members`,
  ADD_MEMBER: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members`,
  REMOVE_MEMBER: (projectId: string, memberId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members/${memberId}`,
  INVITE_TO_PROJECT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members/invitation`,
  GET_REFERRALS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/details`,
  UPDATE_REFERRALS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/status`,
  GET_CANDIDATES: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members/candidates`,
  GET_PROJECT_SETTINGS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/settings`,
  UPDATE_PROJECT_STATE: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/state`,
  GET_QUESTIONS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/questions`,
  GET_COMPLIANCE_QUESTIONS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/compliance-questions`,
  REFERRAL_SUMMARY: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referral/summary`,
  GET_REFERRAL: (projectId: string, referralId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/${referralId}`,
  DELETE_FROM_PROJECT: (projectId: string, referralId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/${referralId}`,
  ADD_EXPERT_REFERRAL: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals`,
  UPDATE_COMPLIANCE_STATUS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/compliance`
};

// documents
export const DOCUMENTS_API = {
  GET_DOCUMENT: (projectId: string, documentId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/documents/${documentId}`,
  GET_DOCUMENT_DOWNLOAD_URL: (projectId: string, documentId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/documents/${documentId}`,
  RENAME_DOCUMENT_URL: (projectId: string, documentId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/documents/${documentId}`,
  DELETE_DOCUMENT: (projectId: string, documentId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/documents/${documentId}`,
  UPDATE_EXPERTS_DOCUMENT_STATUS: (projectId: string, chatId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/workspaces/${chatId}/documents`,
  GET_CANOPY_DOCUMENT_DOWNLOAD_URL: (downloadUrl: string): string =>
    `${downloadUrl}`
};

// Events
export const EVENTS_API = {
  GET_EVENTS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events`,
  GET_EVENT_CANDIDATES: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/candidates`,
  GET_EVENTS_DURATION: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/duration`,
  CREATE_EVENT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events`,
  GET_EVENT: (projectId: string, eventId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/${eventId}`,
  SAVE_EVENTS: (projectId: string, eventId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/${eventId}`,
  DELETE_EVENT: (projectId: string, eventId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/${eventId}`
};

// Clients
export const CLIENTS_API = {
  GET_TEAM_MEMBERS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members`,
  GET_TEAM_MEMBERS_PROJECTS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/projects`,
  GET_TEAM_MEMBERS_CANDIDATE_PROJECTS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/candidate-projects`,
  GET_FREQUENT_USED_COUNTRIES: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/countries`,
  CREATE_USER: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/invitation`,
  UPDATE_USER: (clientId: string, userId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/${userId}`,
  GET_USER: (clientId: string, userId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/${userId}`,
  GET_CLIENT: (clientId: string): string => `${CLIENTS_ENDPOINT}/${clientId}`,
  CLIENT_LOGO_UPDATE: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/logo`,
  CLIENT_UPDATE: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}`,
  UPDATE_ENQUIRIES: (): string => `${CLIENTS_ENDPOINT}/enquiries`,
  GET_SAVED_EXPERTS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/saved-experts`,
  GET_SAVED_EXPERT_STATUS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/saved-experts/status`,
  DELETE_SAVED_EXPERT: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/saved-experts`
};

// Limits
export const MAX_PAGE_SIZE = 10;

// Error Codes
export const REST_ERROR = {
  REQUEST_CANCELED: "REQUEST_CANCELED",
  NETWORK_ERROR: "NETWORK_ERROR",
  INTERNAL_ERROR: "INTERNAL_ERROR",

  UNSUPPORTED_FILE_TYPE: "1044",
  INVALID_REFERRAL_STATE: "1042"
};
