import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { Menu } from "antd";
import { IconName } from "libs/antDComponents/src/lib/Icon/IconDictionary";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ReportIncidentButtonForAnt } from "../../../ReportIncident/ReportIncidentButton/ReportIncidentButtonForAnt";
import { ISideBarItems } from "../../MenuItemsConfig";
import styles from "../AntDMenu.module.scss";

interface FooterMenuProps {
  menuItems: ISideBarItems[];
  handleOnClick: (menuItem: ISideBarItems) => void;
  isActive: (route: string) => string | undefined;
  handleLogout: () => void;
}

export const FooterMenu: React.FC<FooterMenuProps> = ({
  menuItems,
  handleOnClick,
  isActive,
  handleLogout
}) => {
  const location = useLocation();

  return (
    <Menu
      theme="light"
      inlineCollapsed
      mode="inline"
      selectedKeys={[location.pathname]}
    >
      {menuItems.map((item) => {
        if (!item.route && item.name === "Support") {
          return <ReportIncidentButtonForAnt key={item.name} />;
        }

        if (item.route && !item.subItems) {
          return (
            <Menu.Item
              key={item.route}
              onClick={() => handleOnClick(item)}
              className={styles.menuItem}
              accessKey={item.route}
              title={item.name}
              icon={
                <AntDIcon
                  name={item.icon as IconName}
                  fontSize="16px"
                  color={isActive(item.route!)}
                />
              }
            />
          );
        }

        if (item.subItems) {
          return (
            <Menu.SubMenu
              key={item.name}
              className={styles.subMenuItem}
              icon={<AntDIcon name={item.icon as IconName} fontSize="16px" />}
            >
              {item.subItems?.map((subItem) => (
                <Menu.Item
                  key={subItem.route}
                  className={styles.subMenuItemComp}
                  onClick={() => {
                    subItem.icon === "logout" && handleLogout();
                  }}
                  accessKey={subItem.route}
                  title={null}
                  icon={
                    <AntDIcon
                      name={subItem.icon as IconName}
                      fontSize="16px"
                      color={isActive(subItem.route!)}
                    />
                  }
                >
                  {subItem.route ? (
                    <Link to={subItem.route as string}>{subItem.name}</Link>
                  ) : (
                    subItem.name
                  )}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          );
        }

        return null;
      })}
    </Menu>
  );
};
