import i18next from "i18next";
import { stringify } from "query-string";
import { Observable } from "rxjs";

import { API_URL, USERS_API } from "./constants/api";
import { REST_ERROR } from "./enums/apiEnums";
import { CIQError, ErrorResponse } from "./models/api";

const loginRequestUrl = `${API_URL}${USERS_API.LOGIN()}`;
const openIdRequestUrl = `${API_URL}${USERS_API.OPEN_ID()}`;

interface FailedRequestHandler<T> {
  resolve: (value?: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
}

interface CustomRequestInit extends RequestInit {
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

export class BaseRestService {
  private isRefreshing = false;
  private failedRequestHandlers: FailedRequestHandler<any>[] = [];
  private defaultHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  private baseURL: string;
  private kickOutUser?: () => void;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  configure(kickOutUser: () => void): void {
    this.kickOutUser = kickOutUser;
  }

  get<T>(
    endPoint: string,
    queryParams: any | undefined = undefined
  ): Observable<T> {
    return this.apiCall<T>("GET", endPoint, undefined, queryParams);
  }

  post<T>(
    endPoint: string,
    data: any,
    queryParams: any | undefined = undefined,
    customConfig: CustomRequestInit = {}
  ): Observable<T> {
    if (
      customConfig.headers &&
      (customConfig.headers as Record<string, string>)["Content-Type"] ===
        "multipart/form-data"
    ) {
      return this.upload<T>(
        endPoint,
        data,
        customConfig.onUploadProgress ?? (() => {}),
        customConfig.headers
      );
    }
    return this.apiCall<T>("POST", endPoint, data, queryParams, customConfig);
  }

  put<T>(
    endPoint: string,
    data: any,
    queryParams: any | undefined = undefined
  ): Observable<T> {
    return this.apiCall<T>("PUT", endPoint, data, queryParams);
  }

  patch<T>(
    endPoint: string,
    data: any,
    queryParams: any | undefined = undefined
  ): Observable<T> {
    return this.apiCall<T>("PATCH", endPoint, data, queryParams);
  }

  delete<T>(
    endPoint: string,
    queryParams: any | undefined = undefined,
    data?: any
  ): Observable<T> {
    return this.apiCall<T>("DELETE", endPoint, data, queryParams);
  }

  private upload<T>(
    endPoint: string,
    data: FormData,
    onUploadProgress: (progressEvent: ProgressEvent) => void,
    headers: HeadersInit = {}
  ): Observable<T> {
    return new Observable<T>((subscriber) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${this.baseURL}${endPoint}`);
      xhr.withCredentials = true;
      xhr.upload.onprogress = onUploadProgress;

      let normalizedHeaders: Record<string, string> = {};
      if (Array.isArray(headers)) {
        normalizedHeaders = Object.fromEntries(
          headers.map(([key, value]) => [key, String(value)])
        );
      } else {
        normalizedHeaders = headers as Record<string, string>;
      }
      normalizedHeaders = { ...this.defaultHeaders, ...normalizedHeaders };

      // Set headers excluding Content-Type so it can be automatically handled by XMLHttpRequest
      for (const key in normalizedHeaders) {
        if (key !== "Content-Type") {
          xhr.setRequestHeader(key, normalizedHeaders[key]);
        }
      }

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          subscriber.next(JSON.parse(xhr.responseText));
          subscriber.complete();
        } else {
          const errorResponse: ErrorResponse<CIQError> = JSON.parse(
            xhr.responseText
          );
          subscriber.error(errorResponse);
        }
      };

      xhr.onerror = () => {
        subscriber.error(new Error(i18next.t("restService:somethingWrong")));
      };

      // Directly send the FormData (which automatically sets the correct content type)
      xhr.send(data);
    });
  }

  private async handleUnauthorized(
    error: ErrorResponse<CIQError>,
    requestOptions: RequestInit,
    url: string
  ): Promise<any> {
    const retryRequest = () =>
      this.retryRequestWithRefresh(url, requestOptions);

    if (this.isRefreshing) {
      try {
        await new Promise<void>((resolve, reject) =>
          this.failedRequestHandlers.push({ resolve, reject })
        );
        const response = await retryRequest();
        if (!response.ok) {
          // Use the original error message for auth failures
          throw error;
        }
        return response.json();
      } catch (err) {
        throw error;
      }
    }

    this.isRefreshing = true;

    try {
      const refreshResponse = await fetch(
        `${this.baseURL}${USERS_API.REFRESH_TOKEN()}`,
        {
          method: "POST",
          credentials: "include",
          headers: this.defaultHeaders
        }
      );

      if (!refreshResponse.ok) {
        throw new Error("Refresh failed");
      }

      this.isRefreshing = false;
      this.failedRequestHandlers.forEach((promise) => promise.resolve());
      this.failedRequestHandlers = [];

      const response = await retryRequest();
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (err) {
      this.isRefreshing = false;

      this.failedRequestHandlers.forEach((promise) => promise.reject(error));
      this.failedRequestHandlers = [];

      // Call kickOutUser before throwing the error
      if (this.kickOutUser) this.kickOutUser();

      throw error;
    }
  }

  private async retryRequestWithRefresh(
    url: string,
    options: RequestInit
  ): Promise<Response> {
    const response = await fetch(`${this.baseURL}${url}`, options);

    if (response.ok) return response;

    const errorData = await response.json();
    const isAuthRequest = ![loginRequestUrl, openIdRequestUrl].includes(url);

    // If we get another 401, try refresh again
    if (isAuthRequest && response.status === 401) {
      return this.handleUnauthorized(errorData, options, url);
    }
    throw errorData;
  }

  private apiCall<T>(
    method: string,
    endPoint: string,
    data?: any,
    queryParams: any | undefined = undefined,
    customConfig?: CustomRequestInit
  ): Observable<T> {
    const url = this.createUrl(endPoint, queryParams);
    const options = this.createRequestOptions(method, data, customConfig);

    return new Observable<T>((subscriber) => {
      const controller = new AbortController();
      const signal = controller.signal;
      const requestOptions = { ...options, signal };

      fetch(`${this.baseURL}${url}`, requestOptions)
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }

          const isAuthRequest = ![loginRequestUrl, openIdRequestUrl].includes(
            url
          );

          const { status } = response;
          let errorResponse = await response.json();

          if (status === 500) {
            errorResponse.message = i18next.t(
              "restService:somethingWrongTryLater"
            );
            errorResponse.status = REST_ERROR.INTERNAL_ERROR;
            throw errorResponse;
          }
          if (status === 400) {
            errorResponse.message =
              errorResponse.message ?? i18next.t("restService:badRequest");
            throw errorResponse;
          } else if (isAuthRequest && status === 401) {
            return this.handleUnauthorized(errorResponse, requestOptions, url);
          } else if (status === 403) {
            errorResponse.message = i18next.t("restService:accessDenied");
            throw errorResponse;
          } else if (status === 404) {
            errorResponse.message =
              errorResponse.message ?? i18next.t("restService:notFound");
            throw errorResponse;
          } else if (status === 409) {
            errorResponse.message =
              errorResponse.message ?? i18next.t("restService:conflict");
            throw errorResponse;
          } else if (status === 413) {
            errorResponse.message =
              errorResponse.message ?? i18next.t("restService:fileTooLarge");
            throw errorResponse;
          } else if (errorResponse.data) {
            errorResponse = errorResponse.data as ErrorResponse<CIQError>;
            throw errorResponse;
          } else {
            errorResponse.message = i18next.t("restService:somethingWrong");
            throw errorResponse;
          }
        })
        .then((data) => {
          subscriber.next(data);
          subscriber.complete();
        })
        .catch((error) => {
          const userFriendlyError = { ...error };
          if (userFriendlyError.name === "AbortError") {
            userFriendlyError.message = i18next.t(
              "restService:userCanceledRequest"
            );
            userFriendlyError.status = REST_ERROR.REQUEST_CANCELED;
          }
          subscriber.error(userFriendlyError);
        });

      return () => {
        controller.abort();
      };
    });
  }

  private createUrl(endPoint: string, queryParams?: any): string {
    return endPoint + (queryParams ? `?${stringify(queryParams)}` : "");
  }

  private createRequestOptions(
    method: string,
    data?: any,
    customConfig?: CustomRequestInit
  ): RequestInit {
    const { onUploadProgress, ...restConfig } = customConfig || {};

    return {
      method,
      headers: { ...this.defaultHeaders },
      body: data ? JSON.stringify(data) : undefined,
      credentials: "include",
      ...restConfig
    };
  }
}
