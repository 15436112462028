import { MultiSortPaginatedRequest } from "./api";

export interface MonitoringListRequest
  extends MultiSortPaginatedRequest<MONITORING_LIST_ORDER_BY> {
  clientId?: string;
  clientUserId?: string;
  companyName?: string;
  companyWebsite?: string;
  clientUserName?: string;
  sources?: MONITORING_LIST_SOURCE[];
}

export interface MonitoringListAddCompaniesRequest {
  companyIds: string[];
  userIds: string[];
}

export enum MONITORING_LIST_ORDER_BY {
  COMPANY_NAME = "companyName",
  COMPANY_WEBSITE = "companyWebsite",
  CLIENT_USER = "clientUserName",
  MODIFIED = "modified",
  SOURCE = "source"
}

export enum MONITORING_LIST_SOURCE {
  PROJECT = "Project",
  ADMIN = "Admin",
  INSIGHTS_EXTENSION = "Insights Extension"
}

export interface MonitoringListModel {
  id: string;
  companyId: string;
  companyName: string;
  companyWebsite: string;
  companyLogoUrl: string;
  clientUserFirstName: string;
  clientUserLastName: string;
  clientUserProfileImage: string | null;
  modified: string;
  source: MONITORING_LIST_SOURCE;
  createdByUserFirstName: string;
  createdByUserLastName: string;
}
