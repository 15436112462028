import { Flex } from "antd";
import { useTranslation } from "react-i18next";

import { APP_USER_ROLES } from "@arbolus-technologies/models/common";

import { AuthOption } from "../AuthOption/AuthOption";
import { AuthPageHeader } from "../AuthPageHeader/AuthPageHeader";

import styles from "./ClientOrExpert.module.scss";

export function ClientOrExpert({
  setUserType
}: {
  setUserType: (userType: string) => void;
}) {
  const { t } = useTranslation("login");

  return (
    <div className={styles.clientOrExpert}>
      <AuthPageHeader title={t("choicePageTitle")} />
      <Flex vertical>
        <AuthOption
          title={t("expertChoiceTitle")}
          description={t("expertChoiceText")}
          icon={"person"}
          onClick={() => setUserType(APP_USER_ROLES.expert)}
        />
        <AuthOption
          title={t("clientChoiceTitle")}
          description={t("clientChoiceText")}
          icon={"work"}
          onClick={() => setUserType(APP_USER_ROLES.client)}
        />
      </Flex>
    </div>
  );
}
