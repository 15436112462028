import clsx from "clsx";
import { Field, Formik, FormikProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";

import { REFERRAL_COMPLIANCE_STATE } from "@arbolus-technologies/models/common";

import {
  COMPLIANCE_STATUS,
  FIRST_CALL_STATUS
} from "../../../../../../../constants/project";
import { ComplianceStatusRequest } from "../../../../../../../models/project";
import {
  CIQFormInput,
  CIQRadioButtonGroup
} from "../../../../../../app/components";
import { ComplianceApprovalSchema } from "./ComplianceApprovalSchema";

interface ComplianceApprovalFormProps extends WithTranslation {
  expertName: string;
  isComplianceLoading: boolean;
  onComplianceSubmit: (complianceStatus: ComplianceStatusRequest) => void;
}

export interface ComplianceApprovalFormValues {
  status: string;
  firstCall?: string;
  comments?: string;
}

class ComplianceApprovalForm extends React.PureComponent<ComplianceApprovalFormProps> {
  handleApprovalSubmit = ({
    firstCall,
    status,
    comments
  }: ComplianceApprovalFormValues): void => {
    const { onComplianceSubmit } = this.props;
    const isCompliancePassed = status === COMPLIANCE_STATUS.PASSED;
    const complianceState = isCompliancePassed
      ? REFERRAL_COMPLIANCE_STATE.ACCEPT
      : REFERRAL_COMPLIANCE_STATE.REJECT;
    const chaperoneCall = firstCall === FIRST_CALL_STATUS.YES;

    const complianceStatusUpdate: ComplianceStatusRequest = {
      compliance: complianceState,
      chaperoneCall: isCompliancePassed ? chaperoneCall : undefined,
      complianceNote: comments
    };
    onComplianceSubmit(complianceStatusUpdate);
  };

  renderForm = (
    formProps: FormikProps<ComplianceApprovalFormValues>
  ): JSX.Element => {
    const { t, expertName, isComplianceLoading } = this.props;
    const { isValid, errors, touched, values, handleSubmit, setFieldValue } =
      formProps;

    const isPassed = values.status === COMPLIANCE_STATUS.PASSED;

    const statusButtonValues = [
      { buttonText: t("passed"), value: COMPLIANCE_STATUS.PASSED },
      { buttonText: t("failed"), value: COMPLIANCE_STATUS.FAILED }
    ];

    const firstCallButtonValues = [
      { buttonText: t("yes"), value: FIRST_CALL_STATUS.YES },
      { buttonText: t("no"), value: FIRST_CALL_STATUS.NO }
    ];

    const updateFirstCallValueOnStatusChange = (value: string): void => {
      if (value === COMPLIANCE_STATUS.FAILED) {
        setFieldValue("firstCall", undefined);
      }
    };

    return (
      <div className="compliance-approval-questions">
        <Label className="compliance-label">
          {t("complianceStatus", { expertName })}
        </Label>
        <CIQRadioButtonGroup
          buttonValues={statusButtonValues}
          fieldKey="status"
          customClass="high-width"
          onValueChange={updateFirstCallValueOnStatusChange}
        />
        {isPassed && (
          <>
            <Label>{t("callWithExpert", { expertName })}</Label>
            <CIQRadioButtonGroup
              buttonValues={firstCallButtonValues}
              fieldKey="firstCall"
              customClass="low-width"
            />
          </>
        )}
        <FormGroup
          className={clsx({
            "is-invalid": errors.comments && touched.comments
          })}
        >
          <Label className="space-between">
            {t("addComments")}
            <span>{t("optional")}</span>
          </Label>
          <Field
            name="comments"
            type="textarea"
            component={CIQFormInput}
            placeholder={t("commentsPlaceholder")}
          />
        </FormGroup>
        <div className="btn-container">
          <Button
            type="submit"
            color="primary"
            size="lg"
            disabled={!isValid || isComplianceLoading}
            // @ts-ignore
            onClick={handleSubmit}
          >
            {t("submit")}
          </Button>
        </div>
      </div>
    );
  };

  render(): JSX.Element {
    const { t } = this.props;
    return (
      <div className="compliance-approval">
        <h2>{t("finalApproval")}</h2>
        <Formik<ComplianceApprovalFormValues>
          initialValues={{ firstCall: undefined, comments: "", status: "" }}
          validateOnBlur
          validateOnMount
          validationSchema={ComplianceApprovalSchema}
          onSubmit={this.handleApprovalSubmit}
        >
          {this.renderForm}
        </Formik>
      </div>
    );
  }
}

export default withTranslation("expertComplianceTab")(ComplianceApprovalForm);
