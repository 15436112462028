import { useMemo, useState } from "react";

import { UserCompanyItem } from "@arbolus-technologies/api";

export function useSelectCompanies() {
  const [selectedCompanies, setSelectedCompanies] = useState<UserCompanyItem[]>(
    []
  );

  function addAndSelectCompany(company: UserCompanyItem) {
    setSelectedCompanies((prevState: UserCompanyItem[]) => [
      ...prevState,
      company
    ]);
  }

  function toggleCompany(newCompany: UserCompanyItem, checked: boolean) {
    setSelectedCompanies((prevState: UserCompanyItem[]) =>
      checked
        ? [...prevState, newCompany]
        : prevState.filter((company) => company.id !== newCompany.id)
    );
  }

  function resetSelection() {
    setSelectedCompanies([]);
  }

  return useMemo(
    () => ({
      selectedCompanies,
      addAndSelectCompany,
      toggleCompany,
      resetSelection
    }),
    [selectedCompanies]
  );
}
