import { Icon } from "arbolus-ui-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  Event,
  EventService,
  LIST_EVENTS_ORDER_BY,
  ListEventsParams,
  MIN_EVENTS_LENGTH,
  PaginationData,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  EVENT_PANEL_ROUTE,
  PROJECT_CALENDAR_ROUTE
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  CircleWithIcon,
  ExpertProjectEmptyCalendar,
  LoaderOrComponent
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";
import {
  monthsFromToday,
  transformedDateTime,
  utilService
} from "@arbolus-technologies/utils";

import { Pagination } from "../../Components/Pagination/Pagination";

import styles from "./ExpertProjectCalendar.module.scss";

interface ExpertProjectCalendarProps {
  projectId: string;
  eventService?: typeof EventService;
  notificationService?: ToasterService;
}

const queryParams: ListEventsParams = {
  limit: 3,
  offset: 0,
  excludePastEvents: true,
  orderBy: LIST_EVENTS_ORDER_BY.StartTime,
  orderDirection: SORT_DIRECTION.ASCENDING
};

export const ExpertProjectCalendar: React.FC<ExpertProjectCalendarProps> = ({
  projectId,
  eventService = EventService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertProjectCalendar");
  const history = useHistory();

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [events, setEvents] = useState<Event[]>([]);
  const [paginationDetails, setPaginationDetails] = useState<
    Omit<PaginationData, "offset">
  >({ limit: 0, count: 0 });
  const [isLoading, setIsLoading] = useState(true);

  const fetchEvents = useCallback(
    (offset = 0) => {
      setIsLoading(true);

      eventService
        .getEvents(projectId, {
          startTime: utilService.convertActiveZoneToUTC(),
          endTime: utilService.convertActiveZoneToUTC(monthsFromToday(1)),
          offset,
          OrderDirection: SORT_DIRECTION.ASCENDING,
          limit: MIN_EVENTS_LENGTH
        })
        .subscribe(
          (response) => {
            setEvents(response.items);
            setPaginationDetails(response.pagination);
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsLoading(false);
          }
        );
    },
    [eventService, notificationService, projectId]
  );

  useEffect(() => {
    fetchEvents(pageNumber * queryParams.limit);
  }, [fetchEvents, pageNumber]);

  return (
    <GrayBox
      title={{
        text: t("title"),
        to: PROJECT_CALENDAR_ROUTE(projectId)
      }}
      flexDirection="column"
      gap={[2, 0]}
      actionComponent={
        <Pagination
          limit={paginationDetails.limit}
          count={paginationDetails.count}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      }
    >
      <LoaderOrComponent isLoading={isLoading}>
        {!events?.length && <ExpertProjectEmptyCalendar />}
        {events?.map((event) => (
          <div key={event.id} className={styles.container}>
            <div
              className={styles.eventItem}
              onClick={() => history.push(EVENT_PANEL_ROUTE(event.id))}
            >
              <div className={styles.leftContainer}>
                <CircleWithIcon
                  icon="calendar_today"
                  color={ARBOLUS_COLORS.bColorBasePurple}
                  background={ARBOLUS_COLORS.bColorBaseWhite}
                  border="1px solid #6157FC"
                />
                <div className={styles.details}>
                  <h1>
                    {transformedDateTime({
                      startTime: new Date(event.startTime).toUTCString(),
                      endTime: new Date(event.endTime).toUTCString(),
                      timezone: event.timezone.displayText
                    })}
                  </h1>
                  <div className={styles.callInfo}>
                    {t("callWith", {
                      name: utilService.displayUserName(event.organizer, true)
                    })}
                  </div>
                </div>
              </div>
              <div>
                <Icon
                  name="chevron_right"
                  fontSize="24px"
                  color={ARBOLUS_COLORS.bColorBasePurple}
                />
              </div>
            </div>
          </div>
        ))}
      </LoaderOrComponent>
    </GrayBox>
  );
};
