import {
  AdoptionStatus,
  ChurnRisk,
  Persona,
  RenewalStatus
} from "@arbolus-technologies/models/common";
import { MultiSortPaginatedRequest } from "./api";

export interface MonitoringListRequest
  extends MultiSortPaginatedRequest<MONITORING_LIST_ORDER_BY> {
  clientId?: string;
  clientUserId?: string;
  companyName?: string;
  companyWebsite?: string;
  clientUserName?: string;
  sources?: MONITORING_LIST_SOURCE[];
}

export interface UserMonitoringListRequest extends MonitoringListRequest {
  clientId?: string;
  userId?: string;
}

export interface MonitoringListAddCompaniesRequest {
  companyIds: string[];
  userIds: string[];
}

export enum MONITORING_LIST_ORDER_BY {
  COMPANY_NAME = "companyName",
  COMPANY_WEBSITE = "companyWebsite",
  CLIENT_USER = "clientUserName",
  MODIFIED = "modified",
  SOURCE = "source"
}

export enum MONITORING_LIST_SOURCE {
  PROJECT = "Project",
  ADMIN = "Admin",
  INSIGHTS_EXTENSION = "Insights Extension"
}

export enum MONITORING_LIST_COMPANY_STATUS {
  MONITORING = "Monitoring",
  PASSIVE = "Passive",
  PRIORITY = "Priority"
}

export interface UpdateCompanySignalRequest {
  companyId: string;
  clientUserId: string;
  status: MONITORING_LIST_COMPANY_STATUS;
}

export interface MonitoringListModel {
  id: string;
  companyId: string;
  companyName: string;
  companyWebsite: string;
  companyLogoUrl: string;
  clientUserFirstName: string;
  clientUserLastName: string;
  clientUserProfileImage: string | null;
  modified: string;
  source: MONITORING_LIST_SOURCE;
  status: MONITORING_LIST_COMPANY_STATUS;
  createdByUserFirstName: string;
  createdByUserLastName: string;
}

export interface UserMonitoringListModel {
  id: string;
  vendorCompanyName: string;
  vendorCompanyId: string;
  vendorCompanyWebsite: string;
  vendorCompanyLogoUrl: string;
  customersCount: number;
  churnRisk: ChurnRisk;
  nps: number;
  spend: number;
  renewalIntent: RenewalStatus;
  adoption: AdoptionStatus;
  alternativeCompanies: string[];
  lastUpdate: Date;
  status: MONITORING_LIST_COMPANY_STATUS;
}

export type CompanyDataModel = Omit<
  UserMonitoringListModel,
  "vendorCompanyWebsite" | "vendorCompanyLogoUrl"
> & {
  customerCompanyId: string;
  persona: Persona;
};

export interface CompanyDataRequest extends MultiSortPaginatedRequest<{}> {
  vendorCompanyId?: string;
}

export interface CheckUserMonitoringListResponse {
  userIsMonitoringCompanies: boolean;
  isMonitoringListFeatureEnabled: boolean; //TODO Remove with the FF. This is a temporary solution to enabled a FF for some users
}

export interface DeleteCompanyMonitoringList {
  id: string;
  deleted: boolean;
}

export interface MonitoringListReportResponse {
  reportDate: string;
}
