import { Card, Flex, Tooltip, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ClipboardService } from "@arbolus-technologies/api";
import { EXPERT_EXPERIENCE } from "@arbolus-technologies/models/expert";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

const { Text } = Typography;

interface CallPriceProps {
  email: string;
  phoneNumber?: string;
  experienceLevel?: string;
  timezone?: string;
}

export const CallPrice: React.FC<CallPriceProps> = ({
  email,
  phoneNumber,
  experienceLevel,
  timezone
}) => {
  const { t } = useTranslation("customerSurveyExpertProfileSidePanel");

  const timezones = useSelector(CacheSelector.timezones());

  return (
    <Card>
      <Flex vertical gap={4}>
        <Text strong>{t("details")}</Text>
        <Flex wrap style={{ rowGap: 8, columnGap: 24 }}>
          <Tooltip title={email}>
            <Text
              ellipsis
              style={{ maxWidth: "300px" }}
              onClick={() => ClipboardService.copyToClipboard(email)}
            >
              {email}
            </Text>
          </Tooltip>
          {phoneNumber && (
            <Text
              onClick={() => ClipboardService.copyToClipboard(phoneNumber!)}
            >
              {phoneNumber}
            </Text>
          )}
          {experienceLevel && (
            <Text>{EXPERT_EXPERIENCE.get(experienceLevel)?.label}</Text>
          )}
          <Text>{timezones.find((tz) => tz.value === timezone)?.label}</Text>
        </Flex>
      </Flex>
    </Card>
  );
};
