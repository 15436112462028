import { Editor } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import clsx from "clsx";
import React, { memo, useCallback } from "react";

import { TiptapEditorToolbar } from "./TiptapEditorToolbar";

import { debounce } from "lodash";
import styles from "./TiptapEditor.module.scss";

interface TiptapEditorProps {
  onChange?: (content: string) => void;
  onSubmit?: (content: string) => void;
  initialEditorState: string;
  placeholder?: string;
  onBlur?: (e: React.SyntheticEvent) => void; // TODO: ARB-8872
  disabled?: boolean;
  flexibleHeight?: boolean;
  SubmitComponent?: React.FunctionComponent<{
    onSubmit: () => void;
    editorIsEmpty: boolean;
  }>;
  hasError: boolean;
  enterSendsMessage?: boolean;
  isChat?: boolean;
}

const debounceTimer = 500;

export const TiptapEditorMemoized: React.FC<TiptapEditorProps> = memo(
  function TiptapEditor({
    onChange,
    onSubmit,
    initialEditorState,
    placeholder,
    disabled = false,
    hasError,
    SubmitComponent,
    flexibleHeight = false,
    enterSendsMessage = true,
    isChat = false
  }) {
    const debouncedOnChange = useCallback(
      debounce((editorContent) => {
        onChange?.(editorContent);
      }, debounceTimer),
      [onChange]
    );
    const editor = useEditor(
      {
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          Underline,
          ListItem,
          OrderedList,
          BulletList,
          Placeholder.configure({
            placeholder,
            emptyEditorClass: styles.emptyEditor
          }),
          HardBreak.extend({
            addKeyboardShortcuts() {
              return {
                Enter: () => {
                  if (!isChat) {
                    return false;
                  }
                  if (!enterSendsMessage) {
                    return false;
                  }
                  if (this.editor.getText().trim() !== "" && !disabled) {
                    handleSubmit(this.editor);
                  }
                  return true;
                },
                "Shift-Enter": () =>
                  this.editor.commands.first(({ commands }) => [
                    () => commands.newlineInCode(),
                    () => commands.splitListItem("listItem"),
                    () => commands.createParagraphNear(),
                    () => commands.liftEmptyBlock(),
                    () => commands.splitBlock()
                  ]),
                "Ctrl-Enter": () =>
                  this.editor.commands.first(({ commands }) => [
                    () => commands.newlineInCode(),
                    () => commands.splitListItem("listItem"),
                    () => commands.createParagraphNear(),
                    () => commands.liftEmptyBlock(),
                    () => commands.splitBlock()
                  ])
              };
            }
          }),
          Link.configure({
            openOnClick: false,
            HTMLAttributes: {
              class: styles.link
            }
          })
        ],
        onUpdate: ({ editor }) => debouncedOnChange(editor.getHTML()),
        content: initialEditorState,
        editable: !disabled
      },
      // Very important for chat - otherwise we'll use old chat ID (send message to wrong chat)
      [onSubmit]
    );

    function handleSubmit(editorInstance: Editor) {
      if (!editorInstance || !onSubmit) return;

      onSubmit(editorInstance.getHTML());
      editorInstance.chain().clearContent().focus().run();
    }

    const editorIsEmpty = editor?.isEmpty || editor?.getText().trim() === "";

    if (!editor) return null;

    return (
      <div className={clsx(styles.editor, { [styles.error]: hasError })}>
        <TiptapEditorToolbar editor={editor} disabled={disabled} />
        <div
          className={clsx(styles.innerArea, {
            [styles.withSubmit]: SubmitComponent,
            [styles.disabled]: disabled
          })}
        >
          <div
            className={clsx(
              styles.textArea,
              flexibleHeight && styles.flexibleHeight
            )}
          >
            <EditorContent editor={editor} />
          </div>
          {typeof SubmitComponent === "function" && (
            <div className={styles.submit}>
              <SubmitComponent
                onSubmit={() => handleSubmit(editor)}
                editorIsEmpty={editorIsEmpty}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
