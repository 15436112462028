import { Col, DatePicker, Form, Row, TimePicker } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { EVENT_FORM } from "@arbolus-technologies/models/project";
import {
  EVENT_DAY_READABLE_MONTH_YEAR_DATE_FORMAT,
  EVENT_TWELVE_HOUR_TIME_FORMAT,
  MINUTE_STEP
} from "@arbolus-technologies/utils";
import { Dayjs } from "dayjs";

const { STARTING_DATE, ENDING_DATE, ENDING_TIME, STARTING_TIME } = EVENT_FORM;

interface EventDatePickersProps {
  handleSetStartingDate: (date: Dayjs | null) => void;
  handleSetEndingDate: (date: Dayjs | null) => void;
  handleSetStartingTime: (date: any) => void;
  handleSetEndingTime: (date: any) => void;
  disabledStartDate: (current: Dayjs) => boolean;
  disabledEndDate: (current: Dayjs) => boolean;
}

export const EventDatePickers: React.FC<EventDatePickersProps> = ({
  disabledEndDate,
  disabledStartDate,
  handleSetEndingDate,
  handleSetEndingTime,
  handleSetStartingDate,
  handleSetStartingTime
}) => {
  const { t } = useTranslation("eventForm");

  return (
    <Row gutter={24}>
      {/* STARTING DATE */}
      <Col span={6}>
        <Form.Item
          label={t("eventStartingDate")}
          name={STARTING_DATE}
          rules={[{ required: true, message: t("eventStartingDateRequired") }]}
        >
          <DatePicker
            format={EVENT_DAY_READABLE_MONTH_YEAR_DATE_FORMAT}
            placeholder={t("eventStartingDatePlaceholder")}
            style={{ minWidth: "100%" }}
            disabledDate={disabledStartDate}
            onChange={handleSetStartingDate}
          />
        </Form.Item>
      </Col>

      {/* STARTING TIME */}
      <Col span={6}>
        <Form.Item
          label={t("eventStartingTime")}
          name={STARTING_TIME}
          rules={[{ required: true, message: t("eventStartingTimeRequired") }]}
        >
          <TimePicker
            placeholder={t("eventStartingTimePlaceholder")}
            format={EVENT_TWELVE_HOUR_TIME_FORMAT}
            minuteStep={MINUTE_STEP}
            style={{ minWidth: "100%" }}
            showNow={false}
            onCalendarChange={handleSetStartingTime}
            use12Hours
          />
        </Form.Item>
      </Col>

      {/* ENDING DATE */}
      <Col span={6}>
        <Form.Item
          label={t("eventEndingDate")}
          name={ENDING_DATE}
          rules={[{ required: true, message: t("eventEndingDateRequired") }]}
        >
          <DatePicker
            format={EVENT_DAY_READABLE_MONTH_YEAR_DATE_FORMAT}
            placeholder={t("eventEndingDatePlaceholder")}
            style={{ minWidth: "100%" }}
            disabledDate={disabledEndDate}
            onChange={handleSetEndingDate}
          />
        </Form.Item>
      </Col>

      {/* ENDING TIME */}
      <Col span={6}>
        <Form.Item
          label={t("eventEndingTime")}
          name={ENDING_TIME}
          rules={[{ required: true, message: t("eventEndingTimeRequired") }]}
        >
          <TimePicker
            placeholder={t("eventEndingTimePlaceholder")}
            format={EVENT_TWELVE_HOUR_TIME_FORMAT}
            minuteStep={MINUTE_STEP}
            style={{ minWidth: "100%" }}
            showNow={false}
            onCalendarChange={handleSetEndingTime}
            use12Hours
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
