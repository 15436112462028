// SEARCH
export const SEARCH_DEBOUNCE_TIMEOUT = 750;
export const SEARCH_DEBOUNCE_TIMEOUT_COMMON = 600;

// APP
export const CONTENT_PANEL_ANIMATION_TIME = 250;

// EXPERT
export const CANDIDATE_ENDORSEMENT_CAROUSEL_SLIDE_INTERVAL = 5000;

// Documents
export const DOCUMENT_UPLOAD_COMPLETE_TIMEOUT = 2000;

// Project
export const PROJECT_REFERRAL_SUMMARY_AUDIT_TIME = 2000;

// READ MORE
export const READ_MORE_OVERFLOW_DEBOUNCE = 50;

// TRANSCRIPT
export const TRANSCRIPT_HEADER_RESIZE_DEBOUNCE = 300;

// TRANSACTIONS
export const TRANSACTION_TYPE_AHEAD_DEBOUNCE = 300;
export const OWNER_TYPE_AHEAD_DEBOUNCE = 300;

// PROJECT APPLICATION
export const EXPERT_PROJECT_APPLICATION_IDLE_TIMEOUT_HOURS = 1;

// EVENT
export const EVENT_CANDIDATES_SEARCH_DEBOUNCE = 500;
export const EVENT_ATTACHMENTS_SEARCH_DEBOUNCE = 500;
