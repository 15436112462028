import { Button, Card, Flex, Statistic, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { LandingSurvey } from "@arbolus-technologies/api";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { SURVEY_LANDING_DATE_FORMAT } from "@arbolus-technologies/utils";

import styles from "./SurveyData.module.scss";

interface SurveyDataProps {
  onStartSurvey: () => void;
  survey: LandingSurvey;
}

const { Text } = Typography;

export const SurveyData: React.FC<SurveyDataProps> = ({
  onStartSurvey,
  survey
}) => {
  const { t } = useTranslation("surveyData");
  const {
    description,
    minDuration,
    maxDuration,
    compensationAmount,
    deadlineDate
  } = survey;

  return (
    <Flex gap={48} className={styles.container}>
      <Flex
        vertical
        gap={24}
        justify="space-between"
        className={styles.surveyDescriptionContainer}
      >
        <Text className={styles.dangerousDescription}>
          <DangerouslySetInnerHTML text={description} />
        </Text>

        <Flex justify="center">
          <Button
            style={{ minWidth: "300px" }}
            type="primary"
            size="large"
            onClick={onStartSurvey}
          >
            {t("startSurvey")}
          </Button>
        </Flex>
      </Flex>
      <Flex vertical gap={16} className={styles.cardsContainer}>
        <Flex gap={16} className={styles.topCardsContainer}>
          <Card className={styles.card}>
            <Statistic
              title={t("duration")}
              value={t("durationAmount", {
                minDuration,
                maxDuration
              })}
              prefix={<AntDIcon name="schedule" fontSize="24px" />}
              valueStyle={{
                display: "flex",
                alignItems: "center",
                fontSize: 24,
                fontWeight: 600
              }}
            />
          </Card>
          <Card className={styles.card}>
            <Statistic
              title={t("compensation")}
              value={`${compensationAmount.toFixed(2)} USD`}
              prefix={<AntDIcon name="attach_money" fontSize="24px" />}
              valueStyle={{
                display: "flex",
                alignItems: "center",
                fontSize: 24,
                fontWeight: 600
              }}
            />
          </Card>
        </Flex>
        <Card className={styles.card}>
          <Statistic
            title={t("deadline")}
            value={moment(deadlineDate).format(SURVEY_LANDING_DATE_FORMAT)}
            prefix={<AntDIcon name="calendar_today" fontSize="24px" />}
            valueStyle={{
              display: "flex",
              alignItems: "center",
              fontSize: 24,
              fontWeight: 600
            }}
          />
        </Card>
      </Flex>
    </Flex>
  );
};
