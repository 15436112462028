import { Select, Spin, Tag } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  ApiErrorResponse,
  BasicUser,
  CIQError,
  DefaultToasterService,
  EventService,
  EventUserModel,
  GetEventCandidatesParams,
  SORT_DIRECTION,
  ToasterService,
  User
} from "@arbolus-technologies/api";
import { GuestOption } from "@arbolus-technologies/models/project";
import {
  EVENT_CANDIDATES_SEARCH_DEBOUNCE,
  getFullNameOrEmailOrId
} from "@arbolus-technologies/utils";

interface EventCandidatesSelectorProps {
  projectId: string;
  selectedCandidates?: GuestOption[];
  organizer?: EventUserModel;
  handleGuestsChange: (selectedGuests: GuestOption[]) => void;
  placeholder?: string;
  currentBasicUser: BasicUser;
  eventService?: typeof EventService;
  notificationService?: ToasterService;
}

const limit = 10;
const initialOffset = 0;
const initialSearchTerm = "";
const orderBy = "firstName";

export const EventCandidatesSelector: React.FC<
  EventCandidatesSelectorProps
> = ({
  projectId,
  selectedCandidates = [],
  organizer,
  handleGuestsChange,
  placeholder,
  currentBasicUser,
  eventService = EventService,
  notificationService = DefaultToasterService
}) => {
  const getDefaultOrganizer = useMemo((): GuestOption => {
    // If Event is defined, there is an organizer
    if (organizer) {
      const { id, email, firstName, lastName } = organizer ?? {};
      return {
        key: id,
        label: getFullNameOrEmailOrId(id, email, firstName, lastName),
        value: email ?? "",
        disabled: true
      };
      // If is being created, find current user on default candidates, if not force it
    } else {
      const { id, email, firstName, lastName } = currentBasicUser;
      const defaultOrganizer = selectedCandidates.find(
        (selectedCandidate) => selectedCandidate.key === id
      ) ?? {
        key: id,
        label: getFullNameOrEmailOrId(id, email, firstName, lastName),
        value: email,
        disabled: true
      };
      defaultOrganizer.disabled = true;
      return defaultOrganizer;
    }
  }, [currentBasicUser, organizer, selectedCandidates]);

  const [eventCandidates, setEventCandidates] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm);
  const [prevSearchTerm, setPrevSearchTerm] =
    useState<string>(initialSearchTerm);
  const [offset, setOffset] = useState<number>(initialOffset);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchCandidates = useCallback(
    async (search: string, newOffset = 0) => {
      if (!hasMore) {
        setLoading(false);
        return;
      }

      const params: GetEventCandidatesParams = {
        projectId,
        offset: newOffset,
        limit,
        searchTerm: search,
        orderBy,
        orderDirection: SORT_DIRECTION.DESCENDING
      };

      eventService.getEventCandidates(params).subscribe(
        ({ pagination, items }) => {
          setEventCandidates((prev) => {
            const newCandidates = [...prev, ...items];
            const uniqueCandidates = Array.from(
              new Map(
                newCandidates.map((candidate) => [candidate.id, candidate])
              ).values()
            );
            return uniqueCandidates;
          });

          setHasMore(newOffset + limit < pagination.count);
          setLoading(false);
        },
        (error: ApiErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setLoading(false);
        }
      );
    },
    [projectId, eventService, notificationService, hasMore]
  );

  const debouncedFetchCandidates = useCallback(
    debounce(fetchCandidates, EVENT_CANDIDATES_SEARCH_DEBOUNCE),
    []
  );

  useEffect(() => {
    setOffset(0);

    if (searchTerm !== prevSearchTerm) {
      setLoading(true);
      setEventCandidates([]);
    }

    debouncedFetchCandidates(searchTerm, 0);
    setPrevSearchTerm(searchTerm);

    return () => debouncedFetchCandidates.cancel();
  }, [searchTerm, debouncedFetchCandidates, prevSearchTerm]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { target } = event;
    const scrollHeight = (target as HTMLDivElement).scrollHeight;
    const scrollTop = (target as HTMLDivElement).scrollTop;
    const clientHeight = (target as HTMLDivElement).clientHeight;

    if (scrollHeight - scrollTop === clientHeight && hasMore && !loading) {
      const newOffset = offset + limit;
      setOffset(newOffset);
      fetchCandidates(searchTerm, newOffset);
    }
  };

  const handleSelectChange = (selectedGuestsOptions: GuestOption[]) => {
    handleGuestsChange(selectedGuestsOptions);
  };

  return (
    <Select
      mode="multiple"
      showSearch
      loading={loading}
      optionFilterProp="label"
      options={[
        getDefaultOrganizer,
        // Add other event candidates, filtering out the organizer
        ...eventCandidates
          .filter((candidate) => candidate.id !== getDefaultOrganizer.key)
          .map((candidate) => ({
            key: candidate.id,
            label: getFullNameOrEmailOrId(
              candidate.id,
              candidate.email,
              candidate.firstName,
              candidate.lastName
            ),
            value: candidate.email
          })),
        ...(loading
          ? [
              {
                label: <Spin size="small" />,
                value: "loading",
                key: "loading",
                disabled: true
              }
            ]
          : [])
      ]}
      onSearch={setSearchTerm}
      onPopupScroll={handleScroll}
      onChange={handleSelectChange}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          // Reset the search term when the dropdown is closed
          setSearchTerm(initialSearchTerm);
        }
      }}
      placeholder={placeholder}
      value={[
        // Add the organizer as the first option
        getDefaultOrganizer,
        // Add other event candidates, filtering out the organizer
        ...selectedCandidates.filter(
          (guest) => guest.key !== getDefaultOrganizer.key
        )
      ]}
      filterOption={false} // Show already selected options in dropdown
      labelInValue={true} // This is needed to return key, label, value
      tagRender={(props) => {
        const { label, value, onClose } = props;
        const isDisabled = value === getDefaultOrganizer.value; // Prevent deletion of organizer
        return (
          <Tag
            color={isDisabled ? "gold" : "default"}
            closable={!isDisabled}
            onClose={isDisabled ? undefined : onClose}
          >
            {label}
          </Tag>
        );
      }}
    />
  );
};
