import { isMobile } from "react-device-detect";

import { CIQMediaQueryResults } from "../DeviceMonitor/DeviceMonitor";

export const UI_WINDOW_HEIGHT = "var(--vh100)";

export const UI_CLIENT_ANSWERS_CANOPY_PAGE = {
  CLIENT_ROW_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 244 : 103,
  CONTAINER_HEIGHT_MOBILE: ({ large }: CIQMediaQueryResults): string =>
    `calc(${UI_WINDOW_HEIGHT} - 64px)`,
  CONTAINER_HEIGHT: ({ large }: CIQMediaQueryResults): string => `100%`
};

export const APP_DEVICE_MEDIA_QUERIES = {
  small: "(max-width: 768px)",
  medium: "(min-width: 768px) and (max-width: 1023px)",
  large: "(min-width: 1024px)"
};

export const UI_COMMON = {
  HAS_SIDE_MENU: (): boolean => window.innerWidth > 1023
};

export const UI_ANGLES_LIST = {
  CONTAINER_EXCESS_HEIGHT: isMobile ? 200 : 50
};

export const UI_CANOPIES_PAGE = {
  CANOPY_ROW_ELEMENT_HEIGHT: ({ large }: CIQMediaQueryResults): number =>
    large ? 96 : 120,
  CONTAINER_HEIGHT: (): string => `calc(${UI_WINDOW_HEIGHT} - 234px)`
};

export const UI_ENGAGE_EXPERT = {
  PROJECT_ROW_ELEMENT_HEIGHT: 88,
  CONTAINER_HEIGHT: `calc(${UI_WINDOW_HEIGHT} - 350px)`
};

export const UI_CARDS = {
  CARD_ITEM_HEIGHT: (): number => 112
};
