import {
  FilterModel,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

import {
  ExpertProfileSurveyListRequest,
  ExpertService,
  SORT_DIRECTION,
  getMultiSortParams
} from "@arbolus-technologies/api";

export class ExpertSurveysTableDataSource implements IServerSideDatasource {
  private readonly _expertId: string;

  constructor(expertId: string) {
    this._expertId = expertId;
  }

  getRows(params: IServerSideGetRowsParams): void {
    const { success, fail, request } = params;

    ExpertService.getProfileSurveys(
      this._expertId,
      getMultiSortParams(this.getQueryParams(request))
    ).subscribe({
      next: (response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      },
      error: () => fail()
    });
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): ExpertProfileSurveyListRequest {
    const { filterModel, sortModel } = request;

    const { offset, limit } = this.getPagination(request);

    // Keys equal to column IDs in column definitions
    const {
      clientName = {},
      surveyTitle = {},
      projectName = {},
      completionDate = {}
    } = filterModel as FilterModel;

    const queryParams: ExpertProfileSurveyListRequest = {
      clientName: clientName.filter,
      surveyTitle: surveyTitle.filter,
      projectName: projectName.filter,
      completionDate: completionDate.filter,
      offset,
      limit,
      sort:
        sortModel?.length > 0
          ? sortModel.map(({ colId, sort }) => ({
              orderBy: colId,
              orderDirection: sort as SORT_DIRECTION
            }))
          : []
    };

    return queryParams;
  }

  private getPagination(request: IServerSideGetRowsRequest) {
    const { startRow, endRow } = request;
    const queryParams = {
      limit: endRow! - startRow!,
      offset: startRow!
    };
    return queryParams;
  }
}
