import { Flex, Result, Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import { APP_TRACKING_ROUTES } from "@arbolus-technologies/models/common";
import { PublicRoutes } from "@arbolus-technologies/routes";

import styles from "./SurveyClosedFeedback.module.scss";

const { Text } = Typography;

export const SurveyClosedFeedback = () => {
  const { t } = useTranslation("surveyClosedFeedback");

  return (
    <Flex justify="center" align="center" className={styles.container}>
      <Result
        title={t("surveyClosed")}
        status="warning"
        className={styles.result}
        subTitle={
          <Flex vertical>
            <Text type="secondary">{t("thankYouForYourInterest")}</Text>
            <Text type="secondary">
              <Trans i18nKey="surveySupport">
                For any issue, please feel free to reach out at{" "}
                <a href="mailto:survey_support@arbolus.com">
                  survey_support@arbolus.com
                </a>
              </Trans>
            </Text>
          </Flex>
        }
        extra={
          <AntDButtonLink
            type="primary"
            pathname={PublicRoutes.REGISTER}
            state={{ from: APP_TRACKING_ROUTES.SURVEY_FEEDBACK }}
            text={t("createAnAccount")}
          />
        }
      />
    </Flex>
  );
};
