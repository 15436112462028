export const MAXIMUM_VIEWABLE_UPLOADING_FILES = 3;

export const MAXIMUM_FILE_UPLOADING_SIZE = 50 * 1024 ** 2;

export const MAXIMUM_FILES_UPLOAD_AT_ONCE = 5;

export const MAXIMUM_LINKS_AT_ONCE = 5;

export const MAXIMUM_FILE_NAME_LENGTH = 100;

export const MAXIMUM_IMAGE_UPLOAD_SIZE = 5 * 1024;

export const ALLOWED_FILE_TYPES = [
  "DOC",
  "DOCX",
  "XLS",
  "XLSX",
  "XLT",
  "PPT",
  "PPTX",
  "TXT",
  "JPG",
  "PNG",
  "ZIP",
  "RAR",
  "7ZIP",
  "GIF",
  "PDF",
  "CSV",
  "JPEG",
  "BMP",
  "MNG",
  "TIFF",
  "ICO",
  "M4V"
] as const;

export type FileExtension = (typeof ALLOWED_FILE_TYPES)[number];

export const acceptFileTypes = ALLOWED_FILE_TYPES.map(
  (extension) => `.${extension}`
).join(",");
