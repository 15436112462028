import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { zip } from "rxjs";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ProjectNote,
  ScreeningQuestion,
  ToasterService
} from "@arbolus-technologies/api";
import { EXPERT_PROFILE_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { Divider, Flex, Spin } from "antd";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

import { DiscoverMatchingFiltersPanelData } from "../../interfaces";
import { CustomerOf } from "./CustomerOf/CustomerOf";
import { FilterMatch } from "./FilterMatch/FilterMatch";
import { ProjectNotes } from "./ProjectNotes/ProjectNotes";
import { ScreeningAnswers } from "./ScreeningAnswers/ScreeningAnswers";

import styles from "./Content.module.scss";

interface ContentProps {
  panelData: DiscoverMatchingFiltersPanelData;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const Content: React.FC<ContentProps> = ({
  panelData,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectNotes, setProjectNotes] = useState<ProjectNote[]>([]);
  const [screeningQuestions, setScreeningQuestions] = useState<
    ScreeningQuestion[]
  >([]);
  const keywordsList = useSelector(ProjectNxSelector.keywordsList());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const {
    countryTwoLetterCode,
    fullName,
    lastPublicCompanyDate,
    profileImageUrl,
    title,
    expertId,
    doNotContactStatus,
    workHistories,
    searchMatchColumns,
    customers
  } = panelData || {};

  const getProjectNotesAndScreeningAnswers = () => {
    if (keywordsList) {
      setIsLoading(true);
      zip(
        expertService.getProjectNotes(expertId, keywordsList),
        expertService.getScreeningQuestions(expertId, keywordsList)
      ).subscribe(
        ([projectNotes, screeningQuestions]) => {
          setProjectNotes(projectNotes.items.filter((item) => !!item.note));
          setScreeningQuestions(screeningQuestions.items);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      );
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    getProjectNotesAndScreeningAnswers();
  }, [expertId]);

  if (isLoading) {
    return (
      <Flex align="center" justify="center" className={styles.loaderContainer}>
        <Spin />
      </Flex>
    );
  }

  return (
    <Flex vertical style={{ padding: 24 }}>
      <Link
        to={EXPERT_PROFILE_ROUTE(expertId)}
        target="_blank"
        className={styles.link}
      >
        <AntDAvatar
          firstName={fullName}
          profileImageUrl={profileImageUrl ?? undefined}
          title={title ?? undefined}
          countryTwoLetterCode={countryTwoLetterCode}
          lastPublicCompanyExpDate={lastPublicCompanyDate}
          size={isMobile ? "small" : "large"}
          doNotContactStatus={doNotContactStatus}
          isAdmin={isAdmin}
          showTitleTooltip={false}
        />
      </Link>
      <Divider style={{ margin: "24px 0" }} />
      <Flex vertical gap={12}>
        {keywordsList && (
          <>
            <FilterMatch
              searchMatchColumns={searchMatchColumns}
              workHistories={workHistories}
            />
            <CustomerOf customers={customers} />
            <ProjectNotes
              expertId={expertId}
              keywordsList={keywordsList}
              projectNotes={projectNotes}
            />
            <ScreeningAnswers
              expertId={expertId}
              keywordsList={keywordsList}
              screeningQuestions={screeningQuestions}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
