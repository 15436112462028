import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { useFeature } from "flagged";
import React from "react";
import styles from "./AuthPageBase.module.scss";
import { DefaultBanner } from "./DefaultBanner/DefaultBanner";
import { WebinarBanner } from "./WebinarBanner/WebinarBanner";

export function AuthPageBase({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const webinarFF = useFeature(FEATURE_FLAGS.WebinarBanner);

  return (
    <div className={styles.wrapper}>
      <div>{children}</div>
      <div className={styles.banner}>
        {webinarFF ? <WebinarBanner /> : <DefaultBanner />}
      </div>
    </div>
  );
}
