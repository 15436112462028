import { Button, Flex } from "antd";
import { useTranslation } from "react-i18next";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import { DefaultClipboardManager } from "@arbolus-technologies/features/common";
import { CustomerSurveyCompletionStatus } from "@arbolus-technologies/models/common";
import { CUSTOMERS_SURVEYS_ANSWERS_ROUTE } from "@arbolus-technologies/routes";
import { BASE_URL } from "@arbolus-technologies/utils";

interface ActionsCellRendererProps {
  data: {
    id: string;
    title: string;
    completionStatus: CustomerSurveyCompletionStatus;
  };
}

export function ActionsCellRenderer({ data }: ActionsCellRendererProps) {
  const { t } = useTranslation("customerSurveyTable");
  const allAnswersButtonDisabled =
    data?.completionStatus === CustomerSurveyCompletionStatus.NO_ANSWERS;

  function copyToClipboard() {
    DefaultClipboardManager.copy(`${BASE_URL}/customers/surveys/${data.id}`);
  }

  return (
    <Flex gap={16} justify="center" align="center" style={{ height: "100%" }}>
      <Button size="small" onClick={copyToClipboard}>
        {t("copyLinkButton")}
      </Button>
      <AntDButtonLink
        pathname={CUSTOMERS_SURVEYS_ANSWERS_ROUTE(data.id).pathname!}
        text={t("allAnswersButton")}
        state={{ surveyTitle: data.title, surveyId: data.id }}
        disabled={allAnswersButtonDisabled}
      />
    </Flex>
  );
}
