export const FEDERATED_AUTH_PROVIDER = {
  GOOGLE: "Google",
  MICROSOFT: "AzureActiveDirectory",
  APPLE: "Apple"
};

export enum RegisterStages {
  SELECTION = "SELECTION",
  TERMS = "TERMS",
  CREATE_WITH_EMAIL = "CREATE_WITH_EMAIL",
  INQUIRY = "INQUIRY",
  CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT"
}

export enum CreateAccountStages {
  CREATE_ACCOUNT = "CREATE_ACCOUNT",
  EXPERT_RATE = "EXPERT_RATE"
}

export enum REFERRER_TYPE {
  EXPERT = "Expert"
}
