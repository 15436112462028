import { Input, Select } from "antd";
import React, { useCallback, useState } from "react";

import { PREFIXES } from "./prefixes";

import styles from "./SimplePhoneInput.module.scss";

export interface PhoneValue {
  prefix: string;
  number: string;
}

export function SimplePhoneInput({
  value,
  onChange
}: {
  value: PhoneValue;
  onChange: (value: PhoneValue) => void;
}) {
  const [phoneNumber, setPhoneNumber] = useState(value.number);
  const [prefix, setPrefix] = useState(value.prefix);

  const handleNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Allow only digits (0-9)
      const newNumber = e.target.value;
      if (!/^\d*$/.test(newNumber)) {
        e.preventDefault();
        return;
      }
      setPhoneNumber(newNumber);
      onChange({ prefix, number: newNumber });
    },
    [prefix, onChange]
  );

  const handlePrefixChange = useCallback(
    (newPrefix: string) => {
      setPrefix(newPrefix);
      onChange({ prefix: newPrefix, number: phoneNumber });
    },
    [phoneNumber, onChange]
  );

  return (
    <Input
      addonBefore={
        <PrefixSelector value={prefix} onChange={handlePrefixChange} />
      }
      placeholder="123 456 789"
      onChange={handleNumberChange}
      value={phoneNumber}
    />
  );
}

function PrefixSelector({
  value,
  onChange
}: { value: string; onChange: (prefix: string) => void }) {
  return (
    <Select
      className={styles.prefixSelector}
      popupClassName={styles.prefixDropdown}
      value={value}
      labelInValue
      optionLabelProp="label"
      onChange={onChange}
      showSearch
      filterOption={(inputValue, option) => {
        if (option?.key) {
          return option.key.toLowerCase().includes(inputValue);
        }
        return false;
      }}
    >
      {Object.entries(PREFIXES).map(([label, prefix]) => (
        <Select.Option key={label} value={prefix}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
}
