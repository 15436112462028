import i18next from "i18next";
import * as Yup from "yup";

import { UserModel } from "@arbolus-technologies/api";
import { ScreeningQuestionConstraints } from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

import {
  ANGLE_COLOR,
  ANGLE_TITLE,
  EXPERT_COUNT,
  EXPERT_DESCRIPTION,
  INCLUDE_SCREENING_QUESTION,
  OWNER,
  QUESTIONS
} from "./types";

const MAX_BRIEF_DESCRIPTION_LENGTH = 8000;

const questionSchema: Yup.ObjectSchema<{ question: string }> = Yup.object({
  question: Yup.string()
    .transform((value) => value ?? "")
    .test(
      "max-length-check",
      i18next.t("viewEditAngle:maxLength", {
        length: ScreeningQuestionConstraints.MAX_QUESTION_LENGTH
      }),
      (value) => {
        const htmlStrippedText = utilService.getStrippedTextFromHtml(
          value ?? ""
        );

        if (
          htmlStrippedText.length >
          ScreeningQuestionConstraints.MAX_QUESTION_LENGTH
        ) {
          return false;
        }
        return true;
      }
    )
    .test("required", "Required", (value) => {
      const htmlStrippedText = utilService.getStrippedTextFromHtml(value ?? "");

      if (htmlStrippedText.length === 0) {
        return false;
      }
      return true;
    })
});

export const AngleDetailsSchema = Yup.object().shape({
  [ANGLE_TITLE]: Yup.string()
    .required(i18next.t("projectNx:invalidProjectNameError"))
    .test(
      "check space",
      i18next.t("projectNx:checkWhiteSpaces"),
      (str: string) => {
        const hasWordSpace = str.indexOf(" ");
        const reverseWord = str.split("").reverse().join("");
        const hasSpaceAtFinal = reverseWord.indexOf(" ");

        const checked = hasWordSpace === 0 || hasSpaceAtFinal === 0;
        return !checked;
      }
    )
    .max(
      60,
      i18next.t("viewEditAngle:maxLength", {
        length: 60
      })
    ),

  [ANGLE_COLOR]: Yup.string().required(
    i18next.t("projectNx:internalOverviewLengthRequired")
  ),
  [EXPERT_DESCRIPTION]: Yup.string()
    .required(i18next.t("projectNx:overViewLengthRequired"))
    .max(
      MAX_BRIEF_DESCRIPTION_LENGTH,
      i18next.t("projectNx:overViewLimitError", {
        length: MAX_BRIEF_DESCRIPTION_LENGTH
      })
    ),

  [EXPERT_COUNT]: Yup.number()
    .typeError("Must be a number")
    .required("Required")
    .min(1, "Must be greater than or equal to 1")
    .max(1000, "Must be less than or equal to 1000"),

  [OWNER]: Yup.object<UserModel>().required(
    i18next.t("projectNx:angleOwnerRequired")
  )
});

export const CreateAngleDetailsSchema = AngleDetailsSchema.concat(
  Yup.object().shape({
    [INCLUDE_SCREENING_QUESTION]: Yup.boolean().required("Required"),
    [QUESTIONS]: Yup.mixed().when("hasScreeningQuestions", {
      is: true,
      then: Yup.array().of(questionSchema),
      otherwise: Yup.array().nullable()
    })
  })
);
