/* spell-checker: disable */

export enum ExpertProjectApplicationFlow {
  ExpertUserDashboardStartApplication = "expertuser_dashboard_startapplication",
  ExpertUserProjectApplicationTotalDuration = "expertuser_projectapplication_totalduration",
  ExpertUserProjectApplicationAcceptProject = "expertuser_projectapplication_click_acceptproject",
  ExpertUserProjectApplicationDeclineProjectConfirm = "expertuser_projectapplication_click_declineproject_confirm",
  ExpertUserProjectApplicationBriefTime = "expertuser_projectapplication_brief",
  ExpertUserProjectApplicationScreenings = "expertuser_projectapplication_click_screenings",
  ExpertUserProjectApplicationScreeningTime = "expertuser_projectapplication_screening",
  ExpertUserProjectApplicationCompliances = "expertuser_projectapplication_click_compliances",
  ExpertUserProjectApplicationCompliancesTime = "expertuser_projectapplication_compliances",
  ExpertUserProjectApplicationAvailability = "expertuser_projectapplication_click_availability",
  ExpertUserProjectApplicationAvailabilityTime = "expertuser_projectapplication_availability",
  ExpertUserProjectApplicationWorkHistory = "expertuser_projectapplication_click_workhistory",
  ExpertUserProjectApplicationWorkHistoryTime = "expertuser_projectapplication_workhistory",
  ExpertUserProjectApplicationEngagement = "expertuser_projectapplication_click_engagement",
  ExpertUserProjectApplicationEngagementTime = "expertuser_projectapplication_engagement"
}
