import { Observable, zip } from "rxjs";

import { ExpertRate, ProjectModel } from "@arbolus-technologies/models/common";
import {
  CONTACT_TYPE_ENUM,
  ExternalExpertStats,
  PipelineExpert,
  PipelineExpertHistories,
  PipelineExpertUpdateModel,
  PipelineExpertUser
} from "@arbolus-technologies/models/expert";
import {
  DiscoverExpert,
  GPTResponse,
  ProjectResponse,
  SearchStatsResponse,
  SimplifiedCreateProjectPayload,
  SimplifiedEditProjectPayload,
  UnreadMessagesCountItem
} from "@arbolus-technologies/models/project";

import {
  CHAT_API,
  CLIENTS_API,
  DRAFT_PROJECT_API,
  MAX_PAGE_SIZE,
  METADATA_API,
  PROJECTS_API,
  PROJECT_EXPERTS_API
} from "../constants/api";
import { ADMIN_API } from "../endpoints/admin";
import { SORT_DIRECTION } from "../enums/apiEnums";
import {
  EARLIEST_AVAILABILITIES_ORDER_BY,
  PROJECT_REFERRAL_STATE
} from "../enums/projectEnums";
import { getMultiSortParams } from "../helpers";
import {
  AddMultipleReferralsResponse,
  ApiPaginatedResponse,
  CreatedResponse,
  DeletedResponse,
  ListResponse,
  PagedListResponse,
  PaginatedRequest,
  PaginatedResponseWithStatus,
  ProjectStats,
  SuccessResponse
} from "../models/api";
import { ChatListResponse } from "../models/chat";
import { FileResponse } from "../models/common";
import { ProjectCompliance } from "../models/compliance";
import { Expert, ProjectQuestionsResponse } from "../models/expert";
import { BusinessEntities, EngagementAgreement } from "../models/meta";
import {
  AngleDraftResponseList,
  AngleSingleDraft,
  AngleToSend,
  CreateNewProjectBody,
  DeleteProjectResponse,
  DraftProjectCreateRequest,
  DraftProjectCreateResponse,
  DraftProjectResponse,
  DraftProjectUpdateRequest,
  DraftProjectUpdateResponse,
  EarliestAvailability,
  EditProjectRequest,
  ExpertBaseProject,
  ExpertDiscoverRequest,
  ExportProjectPipelinesRequest,
  ListProjectDraftsRequest,
  ListProjectsRequest,
  Member,
  MemberUser,
  Project,
  ProjectApplication,
  ProjectCollectionRequest,
  ProjectCreateRequest,
  ProjectNx,
  ProjectPipelineExpertRequest,
  ProjectSettings,
  ProjectSettingsUpdateRequest,
  Referral,
  ReferralDetail,
  ReferralSummary,
  TaglineRequestInterface,
  UpdateReferralRequest,
  UpdateReferralResponse
} from "../models/projects";
import { restService } from "../restService";
import { restServiceV3 } from "../restServiceV3";

export const ProjectService = {
  getProjectMembers: (
    projectId: string,
    includeDeleted = true
  ): Observable<ListResponse<Member>> =>
    restService.get(PROJECTS_API.GET_MEMBERS(projectId), {
      includeDeleted
    }),

  addProjectMember: (
    projectId: string,
    userId: string
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(PROJECTS_API.ADD_MEMBER(projectId), {
      userId
    }),

  getProject: (projectId: string): Observable<Project> =>
    restService.get<Project>(PROJECTS_API.GET_PROJECT(projectId)),

  getProjectExperts: (
    projectId: string,
    limit: number = MAX_PAGE_SIZE,
    offset = 0,
    orderBy = "firstName",
    orderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<PagedListResponse<Expert>> =>
    restService.get<PagedListResponse<Expert>>(
      PROJECTS_API.GET_EXPERTS(projectId),
      {
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),

  getReferrals: (
    projectId: string,
    includeExpertDetails: boolean,
    status = "",
    limit = MAX_PAGE_SIZE,
    offset = 0,
    orderBy = "modified",
    orderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<PagedListResponse<Referral>> =>
    restService.get<PagedListResponse<Referral>>(
      PROJECTS_API.GET_REFERRALS(projectId),
      {
        includeExpertDetails,
        status,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),

  getReferral: (
    projectId: string,
    referralId: string
  ): Observable<ReferralDetail> =>
    restService.get<ReferralDetail>(
      PROJECTS_API.GET_REFERRAL(projectId, referralId)
    ),

  getReferralSummary: (
    projectId: string,
    angleId?: string
  ): Observable<ReferralSummary> =>
    restService.get<ReferralSummary>(PROJECTS_API.REFERRAL_SUMMARY(projectId), {
      angleId
    }),

  getExpertCandidates: (
    projectId: string,
    searchTerm = "",
    offset = 0,
    limit: number = MAX_PAGE_SIZE,
    orderBy = "firstName",
    orderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<PagedListResponse<Expert>> =>
    restService.get<PagedListResponse<Expert>>(
      PROJECTS_API.GET_EXPERT_CANDIDATES(projectId),
      {
        searchTerm,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),

  getProjectWithExperts: (
    projectId: string,
    expertLimit: number = MAX_PAGE_SIZE,
    expertOffset = 0,
    expertOrderBy = "",
    expertOrderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<[Project, PagedListResponse<Expert>]> =>
    zip(
      ProjectService.getProject(projectId),
      ProjectService.getProjectExperts(
        projectId,
        expertLimit,
        expertOffset,
        expertOrderBy,
        expertOrderDirection
      )
    ),

  removeFromProject: (
    projectId: string,
    referralId: string
  ): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      PROJECTS_API.DELETE_FROM_PROJECT(projectId, referralId)
    ),

  addExpertReferral: (
    projectId: string,
    expertId: string,
    state?: PROJECT_REFERRAL_STATE
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      PROJECTS_API.ADD_EXPERT_REFERRAL(projectId),
      { expertId, state }
    ),

  addMultipleReferrals: (payload: {
    projectId: string;
    angleId: string;
    expertIds: string[];
    state: PROJECT_REFERRAL_STATE;
  }): Observable<AddMultipleReferralsResponse> =>
    restService.post<AddMultipleReferralsResponse>(
      PROJECTS_API.ADD_MULTIPLE_REFERRALS(payload.projectId),
      payload
    ),

  getProjectCandidates: (
    projectId: string,
    limit: number = MAX_PAGE_SIZE,
    offset = 0,
    searchTerm = "",
    orderBy = ""
  ): Observable<ApiPaginatedResponse<MemberUser>> =>
    restService.get(PROJECTS_API.GET_PROJECT_CANDIDATES(projectId), {
      offset,
      limit,
      orderBy,
      searchTerm
    }),

  removeProjectMember: (
    projectId: string,
    memberId: string
  ): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      PROJECTS_API.REMOVE_MEMBER(projectId, memberId)
    ),

  inviteToProject: (
    projectId: string,
    email: string
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      PROJECTS_API.INVITE_TO_PROJECT(projectId),
      { email }
    ),

  updateReferralState: (
    projectId: string,
    referralId: string,
    status: UpdateReferralRequest
  ): Observable<UpdateReferralResponse> =>
    restService.patch(PROJECTS_API.UPDATE_REFERRAL_STATE(projectId), status, {
      referralId
    }),

  addReferralNote: (
    projectId: string,
    referralId: string,
    note?: string
  ): Observable<SuccessResponse> =>
    restService.put(
      PROJECTS_API.ADD_REFERRAL_NOTE(projectId),
      { note },
      { referralId }
    ),

  createDraftProject: (
    projectDraft: DraftProjectCreateRequest
  ): Observable<DraftProjectCreateResponse> =>
    restService.post<DraftProjectCreateResponse>(
      ADMIN_API.CREATE_DRAFT_PROJECT(),
      projectDraft
    ),

  updateDraftProject: (
    draftId: string,
    projectDraft: DraftProjectUpdateRequest
  ): Observable<DraftProjectUpdateResponse> =>
    restService.put<DraftProjectUpdateResponse>(
      ADMIN_API.UPDATE_DRAFT_PROJECT(draftId),
      projectDraft
    ),

  getDraftProject: (projectDraftId: string): Observable<DraftProjectResponse> =>
    restService.get<DraftProjectResponse>(
      DRAFT_PROJECT_API.GET_DRAFT_PROJECT(projectDraftId)
    ),

  getDraftProjectNxData: (projectDraftId: string): Observable<ProjectNx> =>
    restService.get<ProjectNx>(
      DRAFT_PROJECT_API.GET_DRAFT_PROJECT(projectDraftId)
    ),

  deleteDraftProject: (projectDraftId: string): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      DRAFT_PROJECT_API.DELETE_DRAFT_PROJECT(projectDraftId)
    ),

  listProjectDrafts: (
    params: ListProjectDraftsRequest
  ): Observable<ApiPaginatedResponse<ProjectModel>> =>
    restService.get(
      DRAFT_PROJECT_API.LIST_PROJECT_DRAFTS_V3(),
      getMultiSortParams(params)
    ),

  updateProjectState: (
    projectId: string,
    projectState: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      PROJECTS_API.UPDATE_PROJECT_STATE(projectId),
      {
        projectState
      }
    ),

  createProject: (project: ProjectCreateRequest): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(PROJECTS_API.CREATE_PROJECT(), project),

  sendDraftForApproval: (draftId: string): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(ADMIN_API.DRAFT_PROJECT_APPROVAL(), {
      draftId
    }),

  getProjectQuestions: (
    projectId: string
  ): Observable<ProjectQuestionsResponse> =>
    restService.get(PROJECTS_API.GET_QUESTIONS(projectId)),

  editProject: (project: EditProjectRequest): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      PROJECTS_API.EDIT_PROJECT(project.projectId),
      project
    ),

  getProjectSettings: (projectId: string): Observable<ProjectSettings> =>
    restService.get<ProjectSettings>(
      PROJECTS_API.GET_PROJECT_SETTINGS(projectId)
    ),

  updateProjectSettings: (
    projectId: string,
    projectSettings: ProjectSettingsUpdateRequest
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      PROJECTS_API.GET_PROJECT_SETTINGS(projectId),
      projectSettings
    ),

  deleteProject: (
    projectId: string,
    action: "check" | "delete" = "check"
  ): Observable<DeleteProjectResponse> =>
    restService.delete<DeleteProjectResponse>(
      PROJECTS_API.DELETE_PROJECT(projectId),
      { action }
    ),

  startDraftProjectApi: (
    name: string,
    clientId: string
  ): Observable<{ id: string }> =>
    restService.post<{ id: string }>(
      DRAFT_PROJECT_API.START_PROJECT_DRAFT_V2(),
      {
        name,
        clientId
      }
    ),

  putDraftProjectApi: (
    projectDraft: Partial<ProjectNx>
  ): Observable<ProjectNx> =>
    restService.put<ProjectNx>(
      DRAFT_PROJECT_API.PUT_PROJECT_DRAFT_V2(projectDraft.id!),
      projectDraft
    ),

  getBusinessEntities: (clientId: string): Observable<BusinessEntities> =>
    restService.get<BusinessEntities>(
      CLIENTS_API.GET_BUSINESS_ENTITIES(clientId)
    ),

  getEngagementAgreements: (
    agreementType: string,
    clientId: string
  ): Observable<EngagementAgreement> =>
    restService.get<EngagementAgreement>(
      METADATA_API.GET_ENGAGEMENT_AGREEMENT(),
      {
        agreementType,
        clientId
      }
    ),

  createAngleApi: (
    angleDraft: AngleToSend,
    projectDraftId: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(DRAFT_PROJECT_API.CREATE_ANGLE_DRAFT(), {
      ...angleDraft,
      projectDraftId
    }),

  getAnglesListFromApi: (
    projectDraftId: string
  ): Observable<AngleDraftResponseList> =>
    restService.get<AngleDraftResponseList>(
      DRAFT_PROJECT_API.GET_ANGLE_DRAFT_LIST(),
      { projectDraftId }
    ),

  deleteAngleFromApi: (angleDraftId: string): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      DRAFT_PROJECT_API.DELETE_ANGLE(angleDraftId)
    ),

  getAngleFromApi: (angleDraftId: string): Observable<AngleSingleDraft> =>
    restService.get<AngleSingleDraft>(
      DRAFT_PROJECT_API.GET_ANGLE(angleDraftId)
    ),

  updateAngleFromApi: (
    angleDraft: AngleToSend,
    angleDraftId: string
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      DRAFT_PROJECT_API.PUT_ANGLE(angleDraftId),
      {
        ...angleDraft
      }
    ),

  createProjectV2: (
    project: CreateNewProjectBody
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(PROJECTS_API.CREATE_PROJECT(), project),

  createProjectV3: (
    project: SimplifiedCreateProjectPayload
  ): Observable<CreatedResponse> =>
    restServiceV3.post<CreatedResponse>(PROJECTS_API.CREATE_PROJECT(), {
      ...project
    }),

  editProjectV3: (
    project: SimplifiedEditProjectPayload
  ): Observable<CreatedResponse> =>
    restServiceV3.put<CreatedResponse>(PROJECTS_API.CREATE_PROJECT(), {
      ...project
    }),

  deleteProjectDraftV2: (projectDraftId: string): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      DRAFT_PROJECT_API.DELETE_PROJECT_DRAFT_V2(projectDraftId)
    ),

  editTagline: (
    taglineRequestObject: TaglineRequestInterface
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      PROJECTS_API.EDIT_TAGLINE(
        taglineRequestObject.projectId,
        taglineRequestObject.referralId
      ),
      { tagline: taglineRequestObject.tagline }
    ),

  getRate: (projectId: string, expertId: string): Observable<ExpertRate> =>
    restService.get<ExpertRate>(PROJECT_EXPERTS_API.GET_RATE(), {
      projectId,
      expertId
    }),

  exportExperts: (
    projectId: string,
    stages?: string[]
  ): Observable<FileResponse> =>
    restService.get(PROJECTS_API.EXPORT_EXPERTS(projectId), {
      stages
    }),

  getExpertApplicationBrief: (
    projectId: string
  ): Observable<ProjectApplication> =>
    restService.get(
      PROJECT_EXPERTS_API.GET_PROJECT_APPLICATION_BRIEF(projectId)
    ),

  getEarliestAvailabilities: (
    queryParams: PaginatedRequest<EARLIEST_AVAILABILITIES_ORDER_BY>
  ): Observable<ApiPaginatedResponse<EarliestAvailability>> =>
    restService.get(PROJECTS_API.GET_EARLIEST_AVAILABILITIES(), queryParams),

  sendApplicationReminder: (
    projectId: string,
    expertId: string
  ): Observable<SuccessResponse> =>
    restService.post(
      PROJECTS_API.SEND_APPLICATION_REMINDER(projectId, expertId),
      {}
    ),

  getProjectStats: (
    projectId: string,
    angleId?: string | null
  ): Observable<ProjectStats> =>
    restService.get(PROJECTS_API.GET_PROJECT_STATS(projectId), { angleId }),

  getChatList: (projectId: string): Observable<ChatListResponse> =>
    restService.get(CHAT_API.GET_CHAT_LIST(projectId)),

  getUnreadMessagesCount: (
    projectId: string
  ): Observable<ListResponse<UnreadMessagesCountItem>> =>
    restService.get(CHAT_API.GET_CHAT_UNREAD_MESSAGES_COUNT(projectId)),

  getCompliance: (projectId: string): Observable<ProjectCompliance> =>
    restService.get(PROJECTS_API.GET_PROJECT_COMPLIANCE(projectId)),

  listProjects: (
    params: ListProjectsRequest
  ): Observable<ApiPaginatedResponse<ProjectModel>> =>
    restService.get(PROJECTS_API.LIST_PROJECTS(), getMultiSortParams(params)),

  askGpt: (projectId: string, question: string): Observable<GPTResponse> =>
    restService.post(PROJECTS_API.ASK_GPT(), {
      question,
      projectId
    }),

  expertSearch: (
    projectId: string,
    data: ExpertDiscoverRequest
  ): Observable<PaginatedResponseWithStatus<DiscoverExpert>> =>
    restService.post(PROJECTS_API.EXPERT_SEARCH(projectId), data),

  projectCollection: (
    queryParams: ProjectCollectionRequest
  ): Observable<ApiPaginatedResponse<ProjectResponse>> =>
    restService.get<ApiPaginatedResponse<ProjectResponse>>(
      PROJECTS_API.PROJECT_COLLECTION(),
      queryParams
    ),

  hasProjectVectors: (
    projectId: string
  ): Observable<{ hasProjectSearchVectors: boolean }> =>
    restService.get(PROJECTS_API.HAS_PROJECT_VECTORS(projectId)),

  getExpertProjects: (
    searchTerm: string,
    orderBy: string,
    orderDirection: string,
    offset = 0,
    limit = MAX_PAGE_SIZE
  ): Observable<ApiPaginatedResponse<ExpertBaseProject>> =>
    restService.get<ApiPaginatedResponse<ExpertBaseProject>>(
      PROJECT_EXPERTS_API.GET_PROJECTS(),
      {
        offset,
        limit,
        searchTerm,
        orderBy,
        orderDirection
      }
    ),

  getSearchStats: (projectId: string) =>
    restService.get<SearchStatsResponse>(
      PROJECTS_API.GET_SEARCH_STATS(projectId)
    ),

  getProjectPipelineExperts: (
    projectId: string,
    queryParams: ProjectPipelineExpertRequest
  ): Observable<ApiPaginatedResponse<PipelineExpertUser>> =>
    restService.get<ApiPaginatedResponse<PipelineExpertUser>>(
      PROJECTS_API.GET_PROJECT_PIPELINE_EXPERTS(projectId),
      queryParams
    ),
  getProjectPipelineExpert: (
    projectId: string,
    projectPipelineId: string
  ): Observable<PipelineExpert> =>
    restService.get<PipelineExpert>(
      PROJECTS_API.GET_PROJECT_PIPELINE_EXPERT(projectId, projectPipelineId)
    ),
  updateProjectPipelineExpert: (
    payload: PipelineExpertUpdateModel
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      PROJECTS_API.UPDATE_PROJECT_PIPELINE_EXPERT(payload.projectPipelineId),
      { ...payload }
    ),
  createProjectPipelineReferral: (
    projectPipelineId: string
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      PROJECTS_API.CREATE_PROJECT_PIPELINE_REFERRAL(projectPipelineId),
      {}
    ),
  getProjectPipelineStats: (
    projectId: string,
    searchTerm: string,
    angleId: string | null,
    excludeAlreadyAdded: boolean
  ): Observable<ExternalExpertStats> =>
    restService.get<ExternalExpertStats>(
      PROJECTS_API.GET_PROJECT_PIPELINE_STATS(projectId),
      { projectId, searchTerm, angleId, excludeAlreadyAdded }
    ),
  exportProjectPipelines: (
    data: ExportProjectPipelinesRequest
  ): Observable<FileResponse> =>
    restService.post<FileResponse>(
      PROJECTS_API.EXPORT_PROJECT_PIPELINES(data.projectId),
      data
    ),
  getProjectPipelineExpertHistory: (
    projectPipelineId: string
  ): Observable<PipelineExpertHistories> =>
    restService.get<PipelineExpertHistories>(
      PROJECTS_API.GET_PROJECT_PIPELINE_EXPERT_HISTORY(projectPipelineId)
    ),
  updateBulkStatus: (
    projectPipelineIds: string[],
    targetStatus: CONTACT_TYPE_ENUM
  ): Observable<SuccessResponse> =>
    restService.patch<SuccessResponse>(
      PROJECTS_API.UPDATE_PROJECT_PIPELINE_STATUS_BULK(),
      {
        projectPipelineIds,
        targetStatus
      }
    ),
  changeExpertToInternal: (
    projectPipelineId: string,
    internalExpertId: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      PROJECTS_API.CHANGE_EXPERT_TO_INTERNAL(projectPipelineId),
      {
        projectPipelineId,
        internalExpertId
      }
    )
};
