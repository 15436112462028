/* spell-checker: disable */

import { CanopyMixpanelEvent, CanopyMixpanelEventNames } from "./Events/Canopy";
import { ChatMixpanelEventNames } from "./Events/Chat";
import { ExpertAccountCreationFlow } from "./Events/ExpertAccountCreationFlow";
import { ExpertProfileMixpanelEventNames } from "./Events/ExpertProfile";
import { ExpertProjectApplicationFlow } from "./Events/ExpertProjectApplicationFlow";
import { ExternalSourcingMixpanelEventNames } from "./Events/ExternalSourcing";
import {
  DiscoverMixpanelEvent,
  DiscoverMixpanelEventNames
} from "./Events/FindExperts";
import { ProjectDashboardMixpanelEventNames } from "./Events/ProjectDashboard";
import { SurveyMixpanelEventNames } from "./Events/Survey";
import { TranscriptsMixpanelEventNames } from "./Events/Transcripts";
import { UserDashboardMixpanelEventNames } from "./Events/UserDashboard";

export enum MixpanelPages {
  CallTranscripts = "Call Transcripts",
  CanopyTranscripts = "Canopy Transcripts",
  Chat = "project_chat_page",
  Discover = "discover_page", // Legacy page
  Event = "Event page",
  ExpertProfile = "expert_profile_page", // FindExpertsProfile.tsx
  FindExperts = "findexperts_search_page", // New page that replaced Discover
  Inbox = "Inbox",
  Login = "Login",
  MainDashboard = "Main Dashboard",
  Network = "Network",
  Profile = "Profile", // ExpertProfilePage.tsx
  ProjectDashboard = "Project Dashboard",
  Projects = "Projects",
  TeamMembers = "Team Members",

  CanopyBuilder = "Canopy_builder",
  CanopyExperts = "canopy_experts",
  CanopyExpertsDeepDive = "canopy_experts_deepdive",
  CanopyQuestions = "canopy_questions",
  CanopyQuestionsDeepDive = "canopy_questions_deepdive",
  CanopySummary = "canopy_summary",
  CanopySummaryFromEmail = "canopy_summary_from_email",

  Canopies = "canopies",

  ExternalSourcing = "externalsourcing_page",

  ExpertUserRegistrationPageTerms = "expertuser_registration_page_terms",
  ExpertUserRegistrationPageUserForm = "expertuser_registration_page_userform",
  ExpertUserProjectApplicationPageBrief = "expertuser_projectapplication_page_brief",
  ExpertUserProjectApplicationPageScreenings = "expertuser_projectapplication_page_screenings",
  ExpertUserProjectApplicationPageCompliances = "expertuser_projectapplication_page_compliances",
  ExpertUserProjectApplicationPageAvailability = "expertuser_projectapplication_page_availability",
  ExpertUserProjectApplicationPageWorkHistory = "expertuser_projectapplication_page_workhistory",
  ExpertUserProjectApplicationPageEngagement = "expertuser_projectapplication_page_engagement"
}

// Keep the event names generic
export enum AllMixPanelEventNames {
  //Old (deprecated)
  LoggedIn = "Logged In",
  Logout = "Logout",
  DownloadDocuments = "Download Documents",
  CreateEvent = "Create Event",
  BookExpert = "Book Expert",
  DashboardNotification = "Dashboard Notification",
  Surveys = "Surveys",
  Projects = "Projects",
  // New
  InitialRender = "Initial Render",
  AllReferrals = "Visit all referrals table",
  ApprovedReferrals = "Visit approved referrals table",
  ReviewExpertReferral = "Visit expert referral table with expert panel open",
  Book = "Visit book",
  ProjectSection = "Project section menu",
  CreateSimplifiedProject = "Create simplified project loaded",
  // Canopy
  CanopySection = "Canopy section menu",
  CanopySummaryPDF = "Canopy summary PDF",
  NewProjectCreation = "new_project_creation",
  ProjectEditNew = "project_edit_new"
}

export const MixPanelEventNames = {
  ...AllMixPanelEventNames,
  ...UserDashboardMixpanelEventNames,
  ...DiscoverMixpanelEventNames,
  ...ProjectDashboardMixpanelEventNames,
  ...CanopyMixpanelEventNames,
  ...ChatMixpanelEventNames,
  ...ExpertProfileMixpanelEventNames,
  ...TranscriptsMixpanelEventNames,
  ...ExternalSourcingMixpanelEventNames,
  ...SurveyMixpanelEventNames,
  ...ExpertAccountCreationFlow,
  ...ExpertProjectApplicationFlow
};

export type MixPanelEvent =
  | AllMixPanelEventNames
  | UserDashboardMixpanelEventNames
  | DiscoverMixpanelEvent
  | ProjectDashboardMixpanelEventNames
  | CanopyMixpanelEvent
  | ChatMixpanelEventNames
  | ExpertProfileMixpanelEventNames
  | TranscriptsMixpanelEventNames
  | ExternalSourcingMixpanelEventNames
  | SurveyMixpanelEventNames
  | ExpertAccountCreationFlow
  | ExpertProjectApplicationFlow;

// keep the actions more specific
export enum MixPanelActions {
  VisitedPage = "Visited Page",
  Clicked = "Clicked",
  Redirect = "Redirect",
  RequestFailed = "Request Failed",
  Rendered = "Rendered",
  UnMounted = "Visit finished",
  SlidePanelLoaded = "Slide Panel Loaded",

  Accepted = "Accepted",

  Initialized = "Initialized",
  Completed = "Completed",

  FormSubmitted = "Form Submitted",
  FormSubmissionFailed = "Form Submission Failed",

  AccountCreated = "Account Created",
  AccountCreationFailed = "Account Creation Failed",

  LoggedOut = "Logged Out",
  LogoutFailed = "Logout Failed",

  Downloaded = "Downloaded",
  DownloadFailed = "Download Failed",

  DataLoaded = "Data Loaded",
  DataLoadFailed = "Data Load Failed",

  CurrenciesLoaded = "Currencies Loaded",
  CurrencyLoadFailed = "Currency Load Failed",

  MessageSent = "Message Sent",

  TimeTracking = "Time Tracking",

  Updated = "Updated",

  SubmitReportExpert = "Submit Report Expert",

  Filtered = "Filtered"
}
