import React from "react";
import { useTranslation } from "react-i18next";

import {
  AdminService,
  TransactionSurveyExpert
} from "@arbolus-technologies/api";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

interface SurveyExpertSelectorProps
  extends TypeaheadSelectorControllerProps<TransactionSurveyExpert> {
  surveyId: string;
}

export const SurveyExpertSelector: React.FC<SurveyExpertSelectorProps> = ({
  surveyId,
  placeholderText,
  ...props
}) => {
  const { t } = useTranslation();
  const placeholder = placeholderText ?? t("common:userSelection:findExpert");

  const renderer = ({ fullName }: TransactionSurveyExpert): JSX.Element => (
    <span>{fullName}</span>
  );

  const getItems = () => AdminService.getSurveyTransactionExperts(surveyId);

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={t("common:userSelection:findingExperts")}
      searchText={t("common:userSelection:findingExperts")}
      paginationText={t("common:userSelection:loadMore")}
      orderBy="fullName"
      renderer={renderer}
      getLabelKey={(survey) => survey.fullName}
      getItems={getItems}
    />
  );
};
