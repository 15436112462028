import { Button, Card, Flex, Progress, Space, Tag, Typography } from "antd";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Vendor } from "@arbolus-technologies/models/common";
import { EXPERT_EXPERIENCE_EDIT_TAB } from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { FLEX_GAP } from "../Insights";

import styles from "./CurrentPositionItem.module.scss";

const { Text } = Typography;

interface CurrentPositionItemProps {
  expertCompanyName: string;
  jobTitle: string;
  workHistoryId: string;
  vendors: Vendor[];
  isCurrentJob: boolean;
  onRemoveVendor: (workHistoryId: string, vendor: Vendor) => void;
}

export const CurrentPositionItem: React.FC<CurrentPositionItemProps> = ({
  expertCompanyName,
  jobTitle,
  workHistoryId,
  vendors,
  isCurrentJob,
  onRemoveVendor
}) => {
  const { t } = useTranslation("expertInsightsSection");
  const dispatch = useDispatch();
  const history = useHistory();

  const openSlidePanel = () =>
    dispatch(
      PanelStoreActions.openPanel(PanelId.AddVendor, {
        companyId: "",
        vendors,
        workHistoryId
      })
    );

  const TitleCard = () => (
    <>
      <Flex
        justify="space-between"
        align="center"
        className={styles.headerWrapper}
      >
        <Flex vertical>
          <Text strong>{expertCompanyName}</Text>
          <span className={styles.jobTitle}>{jobTitle}</span>
        </Flex>

        {isCurrentJob && (
          <Flex gap={FLEX_GAP}>
            <Button onClick={() => history.push(EXPERT_EXPERIENCE_EDIT_TAB)}>
              {t("updatePosition")}
            </Button>
            <Button type="primary" onClick={openSlidePanel}>
              {t("addVendors")}
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex gap={8} wrap="wrap" className={styles.headerBottomWrapper}>
        {vendors?.map((vendor) => (
          <Tag
            key={vendor.vendorCompanyId}
            closeIcon={isCurrentJob}
            onClose={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              return onRemoveVendor(workHistoryId, vendor);
            }}
            className={styles.tag}
          >
            {vendor.vendorCompanyName}
          </Tag>
        ))}
      </Flex>
    </>
  );

  return (
    <Space direction="vertical" size={FLEX_GAP} className={styles.cardWrapper}>
      <Card
        title={<TitleCard />}
        className={clsx(!isCurrentJob && styles.olderPositionCard)}
      >
        {isCurrentJob && (
          <Flex gap={FLEX_GAP} align="center">
            <Progress
              type="circle"
              size={30}
              strokeColor={ARBOLUS_COLORS.bColorBasePurple}
              trailColor={ARBOLUS_COLORS.colorBgLayout}
              strokeWidth={12}
              steps={{ count: 100, gap: 0 }}
              percent={vendors.length * 20}
              showInfo={false}
            />
            <Text>{t("iconFeedbackText")}</Text>
          </Flex>
        )}
      </Card>
    </Space>
  );
};
