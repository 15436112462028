import { Form } from "antd";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  Document,
  DocumentService,
  ErrorResponse,
  EventAttachmentModel,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MAXIMUM_FILES_UPLOAD_AT_ONCE,
  MAXIMUM_FILE_NAME_LENGTH,
  MAXIMUM_FILE_UPLOADING_SIZE
} from "@arbolus-technologies/models/documents";
import {
  EVENT_FORM,
  EventFormInterface
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { useSelector } from "react-redux";
import { zip } from "rxjs";

const { ATTACHMENTS } = EVENT_FORM;

interface UseEventAttachmentsProps {
  projectId: string;
  documentService?: typeof DocumentService;
  notificationService?: ToasterService;
}

interface UseEventAttachments {
  attachments: EventAttachmentModel[];
  handleAddAttachments: () => void;
  handleSelectPreselectedAttachment: (selectedAttachment: Document) => void;
  handleCloseAttachmentsPanel: () => void;
  isAttachmentsPanelOpen: boolean;
  preselectedAttachments: EventAttachmentModel[];
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  isUploadingFiles: boolean;
  handleRefetchDocuments: (shouldRefetch: boolean) => void;
  refetchDocuments: boolean;
  handleRemoveAttachment: (selectedAttachmentId: string) => void;
  handleIsAttachmentsPanelOpen: (isOpen: boolean) => void;
}

export const useEventAttachments = ({
  projectId,
  documentService = DocumentService,
  notificationService = DefaultToasterService
}: UseEventAttachmentsProps): UseEventAttachments => {
  const { t } = useTranslation("eventForm");

  const form = Form.useFormInstance<EventFormInterface>();
  const { attachments = [] } = Form.useWatch([], form) ?? {};

  const [isAttachmentsPanelOpen, setIsAttachmentsPanelOpen] = useState(false);
  const [preselectedAttachments, setPreselectedAttachments] = useState<
    EventAttachmentModel[]
  >([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [refetchDocuments, setRefetchDocuments] = useState(false);
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const documentServiceEndpoint = isAdmin
    ? documentService.uploadAdminProjectDocument
    : documentService.uploadClientProjectDocument;

  const handleIsAttachmentsPanelOpen = (isOpen: boolean) => {
    setIsAttachmentsPanelOpen(isOpen);
  };

  // Set preselected attachments from panel
  const handleSelectPreselectedAttachment = (selectedAttachment: Document) => {
    setPreselectedAttachments((prevAttachments) => {
      const isAlreadySelected = prevAttachments.some(
        (attachment) => attachment.id === selectedAttachment.id
      );

      return isAlreadySelected
        ? prevAttachments.filter(
            (attachment) => attachment.id !== selectedAttachment.id
          )
        : [...prevAttachments, selectedAttachment];
    });
  };

  // Confirm and add selected attachments to event
  const handleAddAttachments = () => {
    form.setFieldsValue({
      [ATTACHMENTS]: [
        ...attachments,
        ...preselectedAttachments
          .filter(
            (newAttachment) =>
              !attachments.some(
                (existingAttachment) =>
                  existingAttachment.id === newAttachment.id
              )
          )
          .map((attachment) => {
            const formattedAttachment: EventAttachmentModel = {
              id: attachment.id,
              fileName: attachment.fileName,
              fileSize: attachment.fileSize
            };
            return formattedAttachment;
          })
      ]
    });

    setIsAttachmentsPanelOpen(false);
    setPreselectedAttachments([]);
  };

  // Remove attachment once it has been added to the form
  const handleRemoveAttachment = (selectedAttachmentId: string) => {
    form.setFieldsValue({
      [ATTACHMENTS]: attachments.filter(
        (attachment) => attachment.id !== selectedAttachmentId
      )
    });

    setPreselectedAttachments((prevAttachments) =>
      prevAttachments.filter(
        (attachment) => attachment.id !== selectedAttachmentId
      )
    );
  };

  // Close panel and reset pre-selections
  const handleCloseAttachmentsPanel = () => {
    setIsAttachmentsPanelOpen(false);
    setPreselectedAttachments([]);
  };

  const handleCheckFiles = (
    fileList: FileList | null,
    fileInputRef: React.MutableRefObject<HTMLInputElement | null>
  ): File[] => {
    const files: File[] = Array.from(fileList ?? []);
    const validFiles: File[] = [];

    if (files.length >= MAXIMUM_FILES_UPLOAD_AT_ONCE) {
      DefaultToasterService.showError(
        t("maxFiles", { length: MAXIMUM_FILES_UPLOAD_AT_ONCE })
      );
      setIsUploadingFiles(false);
      return [];
    }

    for (const element of files) {
      const file: File = element;

      if (file.size >= MAXIMUM_FILE_UPLOADING_SIZE) {
        DefaultToasterService.showError(
          t("fileSizeLimitError", {
            limit: MAXIMUM_FILE_UPLOADING_SIZE / 1024 ** 2
          })
        );
        setIsUploadingFiles(false);
        return [];
      }

      if (file.name.length >= MAXIMUM_FILE_NAME_LENGTH) {
        DefaultToasterService.showError(
          t("fileNameMaxLengthError", {
            length: MAXIMUM_FILE_NAME_LENGTH
          })
        );
        setIsUploadingFiles(false);
        return [];
      }

      validFiles.push(file);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    return validFiles;
  };

  // Upload selected files to project and preselect them
  const handleUploadFiles = (event: Event) => {
    const changeEvent = event as unknown as ChangeEvent<HTMLInputElement>;
    setIsUploadingFiles(true);

    const validFiles: File[] = handleCheckFiles(
      changeEvent.target.files,
      fileInputRef
    );

    if (validFiles.length === 0) {
      setIsUploadingFiles(false);
      return;
    }

    const fileServicesBatch = validFiles.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      return documentServiceEndpoint(projectId, formData);
    });

    zip(...fileServicesBatch).subscribe(
      (uploadedFiles) => {
        setIsUploadingFiles(false);
        handleRefetchDocuments(true);

        const formattedAttachments: EventAttachmentModel[] = uploadedFiles.map(
          (file) => ({
            id: file.id,
            fileName: file.fileName,
            fileSize: file.fileSize,
            type: "Document",
            transcriptId: null,
            uploadedUserId: file.uploadedUserId,
            created: file.created,
            modified: file.modified,
            uploadedUser: {
              id: file.uploadedUser.id,
              firstName: file.uploadedUser.firstName,
              lastName: file.uploadedUser.lastName,
              title: file.uploadedUser.title,
              email: file.uploadedUser.email,
              phoneNumber: file.uploadedUser.phoneNumber,
              profileImageUrl: file.uploadedUser.profileImageUrl,
              isoCountryCode: file.uploadedUser.isoCountryCode || null
            }
          })
        );

        setPreselectedAttachments((prevAttachments) => [
          ...prevAttachments,
          ...formattedAttachments.filter(
            (newFile) =>
              !prevAttachments.some(
                (existingFile) => existingFile.id === newFile.id
              )
          )
        ]);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsUploadingFiles(false);
        notificationService.showError(error.message);
      }
    );
  };

  useEffect(() => {
    const inputRef = fileInputRef.current;
    inputRef?.addEventListener("change", handleUploadFiles);
    return () => {
      inputRef?.removeEventListener("change", handleUploadFiles);
    };
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  }, [handleUploadFiles]);

  // Refetch documents after uploading files
  const handleRefetchDocuments = (shouldRefetch: boolean) => {
    setRefetchDocuments(shouldRefetch);
  };

  return {
    attachments,
    fileInputRef,
    handleAddAttachments,
    handleCloseAttachmentsPanel,
    handleIsAttachmentsPanelOpen,
    handleRefetchDocuments,
    handleRemoveAttachment,
    handleSelectPreselectedAttachment,
    isAttachmentsPanelOpen,
    isUploadingFiles,
    preselectedAttachments,
    refetchDocuments
  };
};
