import React from "react";

import { ScreeningQuestion } from "@arbolus-technologies/api";

import { AntDAngleTag, AntDIcon } from "@arbolus-technologies/antDComponents";
import { DATE_DAY_SHORT_MONTH_YEAR_FORMAT } from "@arbolus-technologies/utils";
import { Avatar, Flex, Tag, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ScreeningAnswer } from "./ScreeningAnswer";

import styles from "./ScreeningAnswersCard.module.scss";

interface ScreeningAnswersCardProps {
  screeningQuestion: ScreeningQuestion;
  keywordsList: string[];
}

const { Text } = Typography;

export const ScreeningAnswersCard: React.FC<ScreeningAnswersCardProps> = ({
  screeningQuestion,
  keywordsList
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");

  const {
    angle,
    client,
    project,
    projectQuestionsAnswers,
    referralApplicationUpdateDate
  } = screeningQuestion;

  return (
    <Flex vertical gap={8}>
      <Flex gap={8} align="center">
        <Tag
          title={project.name}
          icon={<AntDIcon name="folder" />}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4
          }}
        >
          {project.name}
        </Tag>
        <AntDAngleTag angle={angle} showTooltip={false} />
      </Flex>
      {projectQuestionsAnswers.map((projectQuestionsAnswer, index) => (
        <ScreeningAnswer
          key={projectQuestionsAnswer.projectQuestion.id}
          questionAnswer={projectQuestionsAnswer}
          index={index}
          keywordsList={keywordsList}
        />
      ))}
      <Flex justify="space-between" align="center">
        <Text code className={styles.date}>
          {referralApplicationUpdateDate
            ? moment(referralApplicationUpdateDate).format(
                DATE_DAY_SHORT_MONTH_YEAR_FORMAT
              )
            : t("noDate")}
        </Text>
        <Flex gap={4} align="center">
          {client.logoUrl && (
            <Avatar size="small" src={client.logoUrl} alt="Client Avatar" />
          )}
          <Text>{client.name}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
