import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../../../translations/client/en.json';
import { KEY_CULTURE } from './app/constants/localStorage';

i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    lng: `${
      navigator.cookieEnabled ? localStorage.getItem(KEY_CULTURE) : 'en'
    }`,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: { escapeValue: true },
    resources: { en },
    ns: [
      'expertsDocumentItem',
      'projectsPage',
      'alert',
      'customDatePicker',
      'expertProfile',
      'countryPickerSidePanel',
      'industryPickerSidePanel',
      'dayHeader',
      'deleteModal',
      'restService',
      'customAvatarSelect',
      'expertContactCard',
      'notFoundPage',
      'redirectPage',
      'expertOptionsCard',
      'expertReferralState',
      'idleChat',
      'reinviteModal',
      'inbox'
    ]
  });

export default i18n;
