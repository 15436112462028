import { Button, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PROJECT_CREATE_SURVEY_ROUTE } from "@arbolus-technologies/routes";

const { Title, Text } = Typography;

interface EmptySurveyCardProps {
  projectId: string;
}

export const EmptySurveyCard: React.FC<EmptySurveyCardProps> = ({
  projectId
}) => {
  const { t } = useTranslation("emptySurveyCard");

  return (
    <Flex vertical gap={16} align="flex-start">
      <Flex gap={16}>
        📋<Title level={5}>{t("noSurveys")}</Title>
      </Flex>
      <Text type="secondary">{t("noSurveysDescription")}</Text>
      <Link to={PROJECT_CREATE_SURVEY_ROUTE(projectId)}>
        <Button type="primary">{t("newSurvey")}</Button>
      </Link>
    </Flex>
  );
};
