import { ClassProperties } from "@arbolus-technologies/utils";

import { IReferral, Referral } from "./Referral";
import { ISlot, Slot } from "./Slot";

export type ISlotReferral = Omit<IReferral, "expertName"> & {
  slots: ISlot[];
  expertTimezone?: string;
};

export class SlotsReferral extends Referral {
  expertTimezone?: string;
  slots: Slot[] = [];

  get hasSlots(): boolean {
    return this.slots?.length > 0;
  }

  getEarliestSlot(): Slot | undefined {
    let earliestSlot: Slot | undefined;
    this.slots.forEach((slot) => {
      if (!earliestSlot || slot.startTime < earliestSlot.startTime) {
        earliestSlot = slot;
      }
    });

    return earliestSlot;
  }

  public static fromObject(json: ISlotReferral): SlotsReferral {
    const referral = {
      ...Referral.fromObject(json),
      slots: json.slots.map(Slot.fromObject)
    };

    return Object.assign(new SlotsReferral(), referral);
  }

  public createUpdated(
    updatedValues: Partial<ClassProperties<SlotsReferral>>
  ): SlotsReferral {
    return Object.assign(new SlotsReferral(), { ...this, ...updatedValues });
  }
}
