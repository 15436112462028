import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { OpenEventParams } from "@arbolus-technologies/models/project";
import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { EventDetails } from "../../Components/EventDetails/EventDetails";
import { SlidePanel } from "../SlidePanel/SlidePanel";

export const EventSidePanel: React.FC = () => {
  const { t } = useTranslation("eventSidePanel");
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();

  const panelData = useSelector(
    PanelSelector.panelData<OpenEventParams>(PanelId.EventPanel)
  );

  const handleClosePanel = (): void => {
    dispatch(PanelStoreActions.closePanel(PanelId.EventPanel));
    if (window.location.hash) {
      history.push(window.location.pathname + window.location.search);
    }
  };

  return (
    <SlidePanel
      panelId={PanelId.EventPanel}
      width={SIDE_PANEL_SIZE._400}
      title={t("eventInformation")}
      closeButtonDirection="right"
      visibleScroll
      customCloseRequest={handleClosePanel}
    >
      <EventDetails
        eventId={panelData?.eventId ?? ""}
        projectId={projectId}
        eventData={panelData?.eventData}
        onClosePanel={handleClosePanel}
      />
    </SlidePanel>
  );
};
