import React from "react";
import { Switch } from "react-router";

import { ExpertContainer } from "@arbolus-technologies/features/expert-profile";

import {
  EXPERT_NETWORK,
  FIND_EXPERTS_PROFILE
} from "../../constants/navigation/authRoutes";
import { SentryRoute } from "../../sentry";
import { withFeatureProtection } from "../app/components/featureManagement/FeatureProtectionHOC";
import ExpertNetworkPage from "./pages/network/ExpertNetworkPage";

const ExpertNetworkContainer: React.FC = () => (
  <Switch>
    <SentryRoute
      exact
      path={FIND_EXPERTS_PROFILE}
      component={ExpertContainer}
    />
    <SentryRoute
      exact
      path={EXPERT_NETWORK}
      // @ts-ignore
      component={ExpertNetworkPage}
    />
  </Switch>
);

export default withFeatureProtection(ExpertNetworkContainer, "networkEnabled");
