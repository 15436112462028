import { ICellRendererParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import { AntDButtonLink, AntDIcon } from "@arbolus-technologies/antDComponents";
import { UserMonitoringListModel } from "@arbolus-technologies/api";
import { MONITORING_LIST_COMPANY_DATA } from "@arbolus-technologies/features/common";

interface CompanyDataRendererProps
  extends ICellRendererParams<UserMonitoringListModel> {}
export function CompanyDataRenderer({ data }: CompanyDataRendererProps) {
  const { t } = useTranslation("userMonitoringListPage");

  if (!data) {
    return null;
  }

  const { alternativeCompanies, lastUpdate, id, ...restOfData } = data;

  return (
    <AntDButtonLink
      type="link"
      text={t("companyData")}
      pathname={MONITORING_LIST_COMPANY_DATA}
      icon={<AntDIcon name="chevronRight" />}
      iconPosition="end"
      state={{ companyData: restOfData }}
    />
  );
}
