import { Alert, Button, Flex } from "antd";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  DefaultToasterService,
  ToasterService,
  UserService
} from "@arbolus-technologies/api";
import {
  CacheSelector,
  CacheStoreActions
} from "@arbolus-technologies/stores/cache";
import { useCookie } from "@arbolus-technologies/utils";

import { PLATFORM_AGREEMENT, PRIVACY_POLICY } from "../../externalLink";

interface PolicyBannersProps {
  userService?: typeof UserService;
  notificationService?: ToasterService;
}

export const PolicyBanners: React.FC<PolicyBannersProps> = ({
  userService = UserService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("policyBanners");
  const dispatch = useDispatch();
  const { setCookie, cookieStored } = useCookie("privacyPolicyUpdate");

  const loggedInUser = useSelector(CacheSelector.loggedInUser());
  const { termUpdateRequired, termId } = loggedInUser;

  const acceptPlatformAgreement = () => {
    userService.acceptTermsAndConditions(termId).subscribe(
      () => {
        dispatch(CacheStoreActions.acceptPlatformAgreement());
        window.open(PLATFORM_AGREEMENT, "_blank");
      },
      () => {
        notificationService.showError(i18next.t("restService:somethingWrong"));
      }
    );
  };

  return (
    <Flex vertical gap={8}>
      {!cookieStored && (
        <Alert
          message={t("privacyPolicyUpdate")}
          type="info"
          action={
            <Button
              size="small"
              type="link"
              href={PRIVACY_POLICY}
              target="_blank"
              onClick={setCookie}
            >
              {t("readMore")}
            </Button>
          }
          closable
          showIcon
          onClose={setCookie}
        />
      )}
      {termUpdateRequired && (
        <Alert
          message={t("termsUpdate")}
          type="info"
          action={
            <Button size="small" type="link" onClick={acceptPlatformAgreement}>
              {t("readMore")}
            </Button>
          }
          closable
          showIcon
          onClose={acceptPlatformAgreement}
        />
      )}
    </Flex>
  );
};
