import { Flex } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { AngleBadge } from "@arbolus-technologies/ui/angles";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";

import { ProjectAnswersResponse } from "../../../../../models/expert";

interface ExpertAnswersTabProps {
  questions: ProjectAnswersResponse;
  angle?: {
    title: string;
    color: string;
  };
}

const ExpertAnswersTab: React.FC<ExpertAnswersTabProps> = ({
  questions: { items, version },
  angle
}: ExpertAnswersTabProps) => {
  const { t } = useTranslation("expertAnswersTab");
  return (
    <div className="expert-question-container">
      {angle && (
        <div className="badge-container">
          <AngleBadge title={angle.title} color={angle.color} />
        </div>
      )}
      <div className="header-container">
        <h2>{t("screeningQuestion")}</h2>
        <h4>{t("version", { version })}</h4>
      </div>
      {items.map((question) => (
        <Flex
          vertical
          gap={8}
          className="question-item"
          key={`question_${question.questionId}`}
        >
          <DangerouslySetInnerHTML text={question.question} />
          <DangerouslySetInnerHTML text={question.answer ?? ""} />
        </Flex>
      ))}
    </div>
  );
};

export default ExpertAnswersTab;
