import { Card, Divider } from "antd";
import clsx from "clsx";
import { useSelector } from "react-redux";

import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { DiscoverExpert } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { WorkHistoryList } from "../WorkHistoryList/WorkHistoryList";
import { ExpertAvatar } from "./ExpertAvatar";
import { ExpertStats } from "./ExpertStats";
import { ExpertTags } from "./ExpertTags";

import styles from "./ExpertCard.module.scss";

interface ExpertCardProps {
  expert: DiscoverExpert;
  projectId: string;
  projectName: string;
  selectedExpertsIds: string[];
  shortlistedExpertsIds: string[];
  handleSelectExpert: (expertId: string) => void;
}

export const ExpertCard: React.FC<ExpertCardProps> = ({
  expert,
  projectId,
  projectName,
  selectedExpertsIds,
  shortlistedExpertsIds,
  handleSelectExpert
}) => {
  const { isoCountryCode, doNotContactStatus } = expert;

  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const expertCountry = metadataCountries.find(
    (metadataCountry) => metadataCountry.threeLetterCode === isoCountryCode
  );

  const isDNC = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isProceedWithCaution =
    doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION;
  const showCaution = isAdmin && isProceedWithCaution;

  const isShortlisted = shortlistedExpertsIds.includes(expert.id);
  const disableActions = isShortlisted || isDNC;

  return (
    <Card
      size="small"
      styles={{ header: { padding: "8px 12px" }, title: { marginRight: 24 } }}
      className={clsx({
        [styles.dnc]: isDNC,
        [styles.caution]: showCaution,
        [styles.disabled]: isShortlisted
      })}
      title={
        <ExpertAvatar
          expert={expert}
          projectId={projectId}
          projectName={projectName}
          expertCountry={expertCountry}
          disabled={disableActions}
          selectedExpertsIds={selectedExpertsIds}
          handleSelectExpert={handleSelectExpert}
        />
      }
      extra={
        <ExpertStats
          expert={expert}
          projectId={projectId}
          countryTwoLetterCode={expertCountry?.twoLetterCode ?? ""}
          disabled={disableActions}
        />
      }
    >
      <WorkHistoryList
        workHistories={expert.workHistories ?? []}
        workHistoryLastUpdateDate={expert.workHistoryLastUpdateDate}
      />
      <Divider style={{ margin: "8px 0" }} />
      <ExpertTags
        isRecommended={expert.isRecommendedExpert}
        recommendedAngle={expert.recommendedAngle}
        doNotContactStatus={expert.doNotContactStatus}
        rateCard={expert.rateCard}
      />
    </Card>
  );
};
