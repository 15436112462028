import React from "react";

import {
  AdminService,
  SORT_DIRECTION,
  TransactionProjectExpert
} from "@arbolus-technologies/api";

import { UserSelector, UserSelectorProps } from "./UserSelector";

interface TransactionProjectExpertSelectorProps
  extends Omit<UserSelectorProps<TransactionProjectExpert>, "getItems"> {
  projectId: string;
  allowInactive?: boolean;
}

export const TransactionProjectExpertSelector: React.FC<
  TransactionProjectExpertSelectorProps
> = ({ projectId, allowInactive = false, ...props }) => (
  <UserSelector
    {...props}
    getItems={(pagination) =>
      AdminService.getProjectExperts(
        projectId,
        pagination.searchTerm,
        "firstName",
        pagination.offset,
        pagination.limit,
        SORT_DIRECTION.DESCENDING,
        allowInactive
      )
    }
  />
);
