import { useSortable } from "@dnd-kit/sortable";
import { Tooltip } from "antd";
import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./DragAndDropWrapper.module.scss";

interface DragAndDropItemProps {
  children: React.ReactNode;
  id: number | string;
  isDisabled: boolean;
}

export const DragAndDropItem: React.FC<DragAndDropItemProps> = ({
  children,
  id,
  isDisabled
}) => {
  const { t } = useTranslation("dragAndDropItem");

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
    ZIndex: isDragging ? 100 : "auto",
    opacity: isDragging ? 0.8 : 1
  };

  return (
    <div ref={setNodeRef} style={style} className={styles.itemContainer}>
      <div
        className={clsx(styles.swapIcon, {
          [styles.disabled]: isDisabled
        })}
        {...listeners}
        {...attributes}
      >
        <Tooltip title={t("reorderDragAndDrop")}>
          <Icon
            name="swap_vert"
            fontSize="16px"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            disabled={isDisabled}
          />
        </Tooltip>
      </div>
      {children}
    </div>
  );
};
