import { Flex, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ExpertWorkHistory } from "@arbolus-technologies/models/common";
import { KEYWORDS_SEARCH_OPTIONS } from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ARBOLUS_COLORS, YELLOW } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { highlightWords } from "@arbolus-technologies/utils";

import styles from "./ExpertCard.module.scss";

const { Text } = Typography;

interface ExpertTitleProps {
  expertWorkHistory?: ExpertWorkHistory;
}

export const ExpertTitle: React.FC<ExpertTitleProps> = ({
  expertWorkHistory
}) => {
  const { t } = useTranslation("discoverPage");

  const keywordsList = useSelector(ProjectNxSelector.keywordsList());
  const industriesFilter = useSelector(
    ProjectNxSelector.discoverFilters()
  ).industries;
  const isHeadlineOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.USER_TITLE
    )
  );

  const { title, industryId, companyName, industryName } =
    expertWorkHistory ?? {};

  const isMatchingIndustry = industriesFilter.some(
    (filter) => filter.value === industryId
  );

  const getHighlightedTitle = (): string => {
    if (expertWorkHistory) {
      return highlightWords({
        text: expertWorkHistory.title ?? t("positionNotAvailable"),
        tags: isHeadlineOptionSelected ? keywordsList : [],
        highlightColor: YELLOW,
        boldedWords: false
      });
    }

    return t("positionNotAvailable");
  };

  const highlightedTitle = getHighlightedTitle();

  return (
    <Flex align="center" gap={4} className={styles.expertTitle}>
      {highlightedTitle && (
        <Tooltip title={title ?? ""}>
          <span>
            <DangerouslySetInnerHTML text={highlightedTitle} />
          </span>
        </Tooltip>
      )}
      {(companyName || industryName) && (
        <>
          {highlightedTitle.length > 0 && <Text>·</Text>}
          <Tooltip title={companyName ?? ""}>
            <Text ellipsis>{companyName ?? t("companyNotAvailable")}</Text>
          </Tooltip>
          {industryName && (
            <AntDIcon
              name="factory"
              color={ARBOLUS_COLORS.bColorSecondary}
              tooltip={industryName}
              style={
                isMatchingIndustry ? { backgroundColor: YELLOW } : undefined
              }
            />
          )}
        </>
      )}
    </Flex>
  );
};
