// cspell:disable
export const PREFIXES = {
  "Afghanistan (+93)": "+93",
  "Åland Islands (+358)": "+358",
  "Finland (+358)": "+358",
  "Albania (+355)": "+355",
  "Algeria (+213)": "+213",
  "American Samoa (+1684)": "+1684",
  "Andorra (+376)": "+376",
  "Angola (+244)": "+244",
  "Anguilla (+1264)": "+1264",
  "Antarctica (+672)": "+672",
  "Norfolk Island (+672)": "+672",
  "Antigua and Barbuda (+1268)": "+1268",
  "Argentina (+54)": "+54",
  "Armenia (+374)": "+374",
  "Aruba (+297)": "+297",
  "Australia (+61)": "+61",
  "Christmas Island (+61)": "+61",
  "Cocos (Keeling) Islands (+61)": "+61",
  "Austria (+43)": "+43",
  "Azerbaijan (+994)": "+994",
  "Bahamas (+1242)": "+1242",
  "Bahrain (+973)": "+973",
  "Bangladesh (+880)": "+880",
  "Barbados (+1246)": "+1246",
  "Belarus (+375)": "+375",
  "Belgium (+32)": "+32",
  "Belize (+501)": "+501",
  "Benin (+229)": "+229",
  "Bermuda (+1441)": "+1441",
  "Bhutan (+975)": "+975",
  "Bolivia (+591)": "+591",
  "Bosnia and Herzegovina (+387)": "+387",
  "Botswana (+267)": "+267",
  "Brazil (+55)": "+55",
  "British Indian Ocean Territory (+246)": "+246",
  "Brunei Darussalam (+673)": "+673",
  "Bulgaria (+359)": "+359",
  "Burkina Faso (+226)": "+226",
  "Burundi (+257)": "+257",
  "Cambodia (+855)": "+855",
  "Cameroon (+237)": "+237",
  "Canada (+1)": "+1",
  "United States (+1)": "+1",
  "Virgin Islands, U.S. (+1)": "+1",
  "Cape Verde (+238)": "+238",
  "Cayman Islands (+345)": "+345",
  "Central African Republic (+236)": "+236",
  "Chad (+235)": "+235",
  "Chile (+56)": "+56",
  "China (+86)": "+86",
  "Colombia (+57)": "+57",
  "Comoros (+269)": "+269",
  "Congo (+242)": "+242",
  "Congo, The Democratic Republic of the (+243)": "+243",
  "Cook Islands (+682)": "+682",
  "Costa Rica (+506)": "+506",
  "Côte d'Ivoire (+225)": "+225",
  "Croatia (+385)": "+385",
  "Cuba (+53)": "+53",
  "Curaçao (+599)": "+599",
  "Netherlands Antilles (+599)": "+599",
  "Cyprus (+537)": "+537",
  "Czech Republic (+420)": "+420",
  "Denmark (+45)": "+45",
  "Djibouti (+253)": "+253",
  "Dominica (+1767)": "+1767",
  "Dominican Republic (+1849)": "+1849",
  "Ecuador (+593)": "+593",
  "Egypt (+20)": "+20",
  "El Salvador (+503)": "+503",
  "Equatorial Guinea (+240)": "+240",
  "Eritrea (+291)": "+291",
  "Estonia (+372)": "+372",
  "Ethiopia (+251)": "+251",
  "Falkland Islands (Malvinas) (+500)": "+500",
  "Faroe Islands (+298)": "+298",
  "Fiji (+679)": "+679",
  "France (+33)": "+33",
  "French Guiana (+594)": "+594",
  "French Polynesia (+689)": "+689",
  "Gabon (+241)": "+241",
  "Gambia (+220)": "+220",
  "Georgia (+995)": "+995",
  "Germany (+49)": "+49",
  "Ghana (+233)": "+233",
  "Gibraltar (+350)": "+350",
  "Greece (+30)": "+30",
  "Greenland (+299)": "+299",
  "Grenada (+1473)": "+1473",
  "Guadeloupe (+590)": "+590",
  "Saint Barthélemy (+590)": "+590",
  "Saint Martin (French part) (+590)": "+590",
  "Guam (+1671)": "+1671",
  "Guatemala (+502)": "+502",
  "Guernsey (+44)": "+44",
  "Isle of Man (+44)": "+44",
  "Jersey (+44)": "+44",
  "United Kingdom (+44)": "+44",
  "Guinea (+224)": "+224",
  "Guinea-Bissau (+245)": "+245",
  "Guyana (+595)": "+595",
  "Paraguay (+595)": "+595",
  "Haiti (+509)": "+509",
  "Honduras (+504)": "+504",
  "Hong Kong (+852)": "+852",
  "Hungary (+36)": "+36",
  "Iceland (+354)": "+354",
  "India (+91)": "+91",
  "Indonesia (+62)": "+62",
  "Iran (+98)": "+98",
  "Iraq (+964)": "+964",
  "Ireland (+353)": "+353",
  "Israel (+972)": "+972",
  "Italy (+39)": "+39",
  "Jamaica (+1876)": "+1876",
  "Japan (+81)": "+81",
  "Jordan (+962)": "+962",
  "Kazakhstan (+7)": "+7",
  "Russia (+7)": "+7",
  "Kenya (+254)": "+254",
  "Kiribati (+686)": "+686",
  "North Korea (+850)": "+850",
  "South Korea (+82)": "+82",
  "Kuwait (+965)": "+965",
  "Kyrgyzstan (+996)": "+996",
  "Laos (+856)": "+856",
  "Latvia (+371)": "+371",
  "Lebanon (+961)": "+961",
  "Lesotho (+266)": "+266",
  "Liberia (+231)": "+231",
  "Libya (+218)": "+218",
  "Liechtenstein (+423)": "+423",
  "Lithuania (+370)": "+370",
  "Luxembourg (+352)": "+352",
  "Macao (+853)": "+853",
  "Macedonia (+389)": "+389",
  "Madagascar (+261)": "+261",
  "Malawi (+265)": "+265",
  "Malaysia (+60)": "+60",
  "Maldives (+960)": "+960",
  "Mali (+223)": "+223",
  "Malta (+356)": "+356",
  "Marshall Islands (+692)": "+692",
  "Martinique (+596)": "+596",
  "Mauritania (+222)": "+222",
  "Mauritius (+230)": "+230",
  "Réunion (+262)": "+262",
  "Mexico (+52)": "+52",
  "Micronesia (+691)": "+691",
  "Moldova (+373)": "+373",
  "Monaco (+377)": "+377",
  "Mongolia (+976)": "+976",
  "Montenegro (+382)": "+382",
  "Montserrat (+1664)": "+1664",
  "Morocco (+212)": "+212",
  "Mozambique (+258)": "+258",
  "Myanmar (+95)": "+95",
  "Namibia (+264)": "+264",
  "Nauru (+674)": "+674",
  "Nepal (+977)": "+977",
  "Netherlands (+31)": "+31",
  "New Caledonia (+687)": "+687",
  "New Zealand (+64)": "+64",
  "Nicaragua (+505)": "+505",
  "Niger (+227)": "+227",
  "Nigeria (+234)": "+234",
  "Niue (+683)": "+683",
  "Northern Mariana Islands (+1670)": "+1670",
  "Norway (+47)": "+47",
  "Oman (+968)": "+968",
  "Pakistan (+92)": "+92",
  "Palau (+680)": "+680",
  "Palestinian Territory, Occupied (+970)": "+970",
  "Panama (+507)": "+507",
  "Papua New Guinea (+675)": "+675",
  "Peru (+51)": "+51",
  "Philippines (+63)": "+63",
  "Pitcairn (+872)": "+872",
  "Poland (+48)": "+48",
  "Portugal (+351)": "+351",
  "Puerto Rico (+1939)": "+1939",
  "Qatar (+974)": "+974",
  "Romania (+40)": "+40",
  "Rwanda (+250)": "+250",
  "Saint Lucia (+1758)": "+1758",
  "Saint Pierre and Miquelon (+508)": "+508",
  "Saint Vincent and the Grenadines (+1784)": "+1784",
  "Samoa (+685)": "+685",
  "San Marino (+378)": "+378",
  "Sao Tome and Principe (+239)": "+239",
  "Saudi Arabia (+966)": "+966",
  "Senegal (+221)": "+221",
  "Serbia (+381)": "+381",
  "Seychelles (+248)": "+248",
  "Sierra Leone (+232)": "+232",
  "Singapore (+65)": "+65",
  "Sint Maarten (Dutch part) (+1721)": "+1721",
  "Slovakia (+421)": "+421",
  "Slovenia (+386)": "+386",
  "Solomon Islands (+677)": "+677",
  "Somalia (+252)": "+252",
  "South Africa (+27)": "+27",
  "South Sudan (+211)": "+211",
  "Spain (+34)": "+34",
  "Sri Lanka (+94)": "+94",
  "Sudan (+249)": "+249",
  "Suriname (+597)": "+597",
  "Swaziland (+268)": "+268",
  "Sweden (+46)": "+46",
  "Switzerland (+41)": "+41",
  "Syria (+963)": "+963",
  "Taiwan (+886)": "+886",
  "Tajikistan (+992)": "+992",
  "Tanzania (+255)": "+255",
  "Thailand (+66)": "+66",
  "Timor-Leste (+670)": "+670",
  "Togo (+228)": "+228",
  "Tokelau (+690)": "+690",
  "Tonga (+676)": "+676",
  "Trinidad and Tobago (+1868)": "+1868",
  "Tunisia (+216)": "+216",
  "Turkey (+90)": "+90",
  "Turkmenistan (+993)": "+993",
  "Turks and Caicos Islands (+1649)": "+1649",
  "Tuvalu (+688)": "+688",
  "Uganda (+256)": "+256",
  "Ukraine (+380)": "+380",
  "United Arab Emirates (+971)": "+971",
  "Uruguay (+598)": "+598",
  "Uzbekistan (+998)": "+998",
  "Vanuatu (+678)": "+678",
  "Venezuela (+58)": "+58",
  "Vietnam (+84)": "+84",
  "Wallis and Futuna (+681)": "+681",
  "Yemen (+967)": "+967",
  "Zambia (+260)": "+260",
  "Zimbabwe (+263)": "+263"
};
