import { useTranslation } from "react-i18next";

import { MONITORING_LIST_COMPANY_STATUS } from "@arbolus-technologies/api";

import styles from "./SelectLabelRenderer.module.scss";

const STYLES_MAP = {
  [MONITORING_LIST_COMPANY_STATUS.PRIORITY]: styles.priorityLabel,
  [MONITORING_LIST_COMPANY_STATUS.MONITORING]: styles.monitoringLabel
};

export function SelectLabelRenderer({
  value
}: { value: MONITORING_LIST_COMPANY_STATUS }) {
  const { t } = useTranslation("userMonitoringListPage");
  const translated = t(`status${value}`);

  // @ts-ignore no need to handle all enum values in styles map
  const className = STYLES_MAP[value];
  return <span className={className}>{translated}</span>;
}
