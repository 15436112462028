import clsx from "clsx";
import { useCallback, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";

import styles from "./ArbolusDropdown.module.scss";

export type DropdownOption<T> = { label: string; value: T };

interface ArbolusDropdownProps<T> {
  options: DropdownOption<T>[];
  onSelect: (selectedOption: DropdownOption<T>) => void;
  selectedLabel: string;
  disabled?: boolean;
}

export const ArbolusDropdown = <T,>({
  options,
  selectedLabel,
  onSelect,
  disabled = false
}: ArbolusDropdownProps<T>): JSX.Element => {
  const [isDropdownOpen, setIsDropDownOpen] = useState<boolean>(false);

  const handleOnClick = useCallback(
    (selectedOption: DropdownOption<T>) => {
      onSelect(selectedOption);

      // doesn't work outside of setTimeout, I don't know why
      setTimeout(() => {
        setIsDropDownOpen(false);
      }, 0);
    },
    [onSelect, setIsDropDownOpen]
  );

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={() => setIsDropDownOpen((prev) => !prev)}
      className={clsx(styles.dropdown, disabled && styles.disabled)}
      disabled={disabled}
    >
      <DropdownToggle caret className={styles.dropdownToggle}>
        <span>{selectedLabel}</span>
      </DropdownToggle>
      <DropdownMenu right className={styles.dropdownMenu}>
        {options.map((option) => (
          <DropdownItem
            key={option.label}
            onClick={() => handleOnClick(option)}
            className={styles.dropdownItem}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
