import { Card, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { LandingSurvey } from "@arbolus-technologies/api";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";

import styles from "./SurveyDescription.module.scss";

interface SurveyDescriptionProps {
  survey: LandingSurvey;
}

const { Title } = Typography;

export const SurveyDescription: React.FC<SurveyDescriptionProps> = ({
  survey
}) => {
  const { t } = useTranslation("surveyDescription");

  return (
    <Card className={styles.surveyDescriptionCard} bordered={false}>
      <Flex vertical gap={16}>
        <Title level={5}>{t("surveyDescription")}</Title>
        <DangerouslySetInnerHTML text={survey.description} />
      </Flex>
    </Card>
  );
};
