import { Company } from "@arbolus-technologies/api";
import React from "react";
import { Item } from "./Item/Item";

interface CompaniesListProps {
  companies: Company[];
  companiesSelected: string[];
  handleItemSelect: (
    id: string,
    isUserSelected: boolean,
    isUser: boolean
  ) => void;
}
export const CompaniesList: React.FC<CompaniesListProps> = ({
  companies,
  companiesSelected,
  handleItemSelect
}) => {
  return (
    <>
      {companies.map(({ id, name, website }) => {
        const isSelected = companiesSelected.some(
          (idSelected) => idSelected === id
        );

        return (
          <Item
            key={id}
            id={id}
            firstName={name}
            description={website ? `https://www.${website}` : ""}
            isSelected={isSelected}
            onClick={handleItemSelect}
          />
        );
      })}
    </>
  );
};
