import { RenewalStatus } from "@arbolus-technologies/models/common";
import { ICellRendererParams } from "ag-grid-community";
import { Tag, Typography } from "antd";
import styles from "./RenewalRenderer.module.scss";

const getRenewalColor = (renewal: RenewalStatus) => {
  switch (renewal) {
    case RenewalStatus.Yes:
      return "green";
    case RenewalStatus.No:
      return "red";
    case RenewalStatus.NotSure:
      return "red";
  }
};

export interface RenewalRendererProps
  extends Partial<ICellRendererParams<{ renewalIntent: RenewalStatus }>> {}

export function RenewalRenderer({ data }: RenewalRendererProps) {
  if (!data?.renewalIntent)
    return <Typography.Text type="secondary">-</Typography.Text>;

  return (
    <Tag className={styles.tag} color={getRenewalColor(data.renewalIntent)}>
      {data.renewalIntent}
    </Tag>
  );
}
