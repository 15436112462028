export enum ChurnRisk {
  Low = "Low",
  Mid = "Mid",
  High = "High"
}

export enum RenewalStatus {
  Yes = "Yes",
  No = "No",
  NotSure = "NotSure"
}

export enum AdoptionStatus {
  Pilot = "Pilot",
  User = "User",
  OffBoarding = "OffBoarding"
}

export enum Persona {
  Other = "Other",
  User = "User",
  Influencer = "Influencer"
}
