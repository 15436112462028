import { Button, Flex, Form, Input, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  CompanyService,
  DefaultToasterService,
  UserCompanyItem
} from "@arbolus-technologies/api";
import { getUrlWithProtocol } from "@arbolus-technologies/utils";

import styles from "./AddNewCompanyButton.module.scss";

interface AddNewCompanyButtonProps {
  searchTerm: string;
  onCompanyCreated: (company: UserCompanyItem) => void;
}

export const AddNewCompanyButton: React.FC<AddNewCompanyButtonProps> = ({
  searchTerm,
  onCompanyCreated
}) => {
  const { t } = useTranslation("addCompaniesModal");
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        icon={<AntDIcon name="add" />}
        onClick={() => setIsModalOpen(true)}
      >
        {t("createCompanyText", { searchTerm })}
      </Button>
      {/* Conditional rendering: easiest way to clear the form after closing modal */}
      {isModalOpen && (
        <CompanyCreationModal
          closeModal={() => setIsModalOpen(false)}
          defaultCompanyName={searchTerm}
          onCompanyCreated={onCompanyCreated}
        />
      )}
    </>
  );
};

function CompanyCreationModal({
  closeModal,
  defaultCompanyName,
  onCompanyCreated
}: {
  closeModal: VoidFunction;
  defaultCompanyName: string;
  onCompanyCreated: (company: UserCompanyItem) => void;
}) {
  const { t } = useTranslation("monitoringCompanyCreationModal");
  const [form] = Form.useForm();

  function onFinish({ companyName, url }: any) {
    createCompany(companyName, url)
      .then(onCompanyCreated)
      .catch((error) => DefaultToasterService.showApiErrors(error))
      .finally(() => closeModal());
  }

  return (
    <Modal
      title={t("modalTitle")}
      open={true}
      footer={null}
      onClose={closeModal}
    >
      <Form
        form={form}
        initialValues={{ companyName: defaultCompanyName }}
        onFinish={onFinish}
        className={styles.modalContent}
      >
        <Form.Item
          name="companyName"
          rules={[{ required: true, message: t("companyNameRequired") }]}
        >
          <Input placeholder={t("modalNameInputPlaceholder")} />
        </Form.Item>
        <Form.Item
          name="url"
          rules={[
            { required: true, message: t("invalidUrl") },
            { type: "url", message: t("invalidUrl") }
          ]}
        >
          <Input placeholder="URL" />
        </Form.Item>

        <Form.Item>
          <Flex justify="flex-end" gap={16}>
            <Button onClick={closeModal}>{t("modalCancelButton")}</Button>
            <Button type="primary" htmlType="submit">
              {t("modalSubmitButton")}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
}

async function createCompany(
  name: string,
  website: string
): Promise<UserCompanyItem> {
  const { id } = await CompanyService.createCompany({
    name,
    website: getUrlWithProtocol(website)
  }).toPromise();
  return {
    id,
    name,
    logoUrl: "",
    insightsCount: 0
  };
}
