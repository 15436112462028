import { RadioChoice, RadioOption } from "../RadioChoice/RadioChoice";

import styles from "./RadioChoices.module.scss";

export enum RADIO_VARIANT {
  MAX_CONTENT = "maxContent",
  MINIMAL = "minimal"
}

interface RadioChoicesProps<T extends string | boolean> {
  onChange: (value: T) => void;
  defaultValue?: T;
  options: RadioOption<T>[];
  variant?: RADIO_VARIANT;
}

export const RadioChoices = <T extends string | boolean>({
  options,
  defaultValue,
  onChange,
  variant = RADIO_VARIANT.MINIMAL
}: RadioChoicesProps<T>): JSX.Element => (
  <div className={styles[variant]}>
    {options.map((option, index) => (
      <RadioChoice
        key={index}
        radioOption={option}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    ))}
  </div>
);
