import { Button, Card, Flex, Modal, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";

const { Text } = Typography;

interface CloseSurveyCardProps {
  surveyId?: string;
  isSurveyClosed?: boolean;
  onSurveyClose?: () => void;
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const CloseSurveyCard: React.FC<CloseSurveyCardProps> = ({
  surveyId,
  isSurveyClosed,
  onSurveyClose,
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("closeSurveyCard");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleCloseSurvey = () => {
    if (surveyId) {
      setIsUpdating(true);
      surveyService.closeSurvey(surveyId).subscribe(
        () => {
          notificationService.showSuccess(t("surveyClosed"));
          setIsModalOpen(false);
          setIsUpdating(false);
          onSurveyClose?.();
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsUpdating(false);
        }
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card>
        <Flex vertical gap={24}>
          <Flex vertical gap={16}>
            <Text strong>{t("closeSurvey")}</Text>
            <Text>{t("closeSurveyDesc")}</Text>
          </Flex>
          <Flex justify="flex-end">
            <Button
              type="primary"
              disabled={!surveyId || isSurveyClosed}
              danger
              onClick={() => setIsModalOpen(true)}
            >
              {t("closeSurvey")}
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Modal
        open={isModalOpen}
        title={t("areYouSure")}
        onOk={handleCloseSurvey}
        onCancel={handleCancel}
        footer={
          <Flex justify="flex-end" gap={8}>
            <Button onClick={handleCancel} disabled={isUpdating}>
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              danger
              onClick={handleCloseSurvey}
              loading={isUpdating}
            >
              {t("closeSurvey")}
            </Button>
          </Flex>
        }
      >
        <Text>{t("NoLongerAccessible")}</Text>
      </Modal>
    </>
  );
};
