import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { GridApi } from "ag-grid-community";
import { Button, Card, Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { UserMonitoringListTable } from "../../Modules/UserMonitoringListTable/UserMonitoringListTable";

import styles from "./UserMonitoringListPage.module.scss";

interface UserMonitoringListTableProps {
  userId: string;
  showModal: () => void;
  onGridApiReady?: (api: GridApi) => void;
}

export function UserMonitoringListTablePage({
  userId,
  showModal,
  onGridApiReady
}: UserMonitoringListTableProps) {
  const { t } = useTranslation("userMonitoringListPage");
  const { trackClick } = useArbolusTracking();

  return (
    <>
      <Card className={styles.card}>
        <Flex justify="space-between" align="center">
          <Typography.Title level={5}>
            {t("addCompaniesTitle")}
          </Typography.Title>
          <Button
            type="primary"
            onClick={() => {
              showModal();
              trackClick(MixPanelEventNames.MonitoringListAddCompany);
            }}
          >
            {t("addCompanies")}
          </Button>
        </Flex>
      </Card>
      <UserMonitoringListTable
        onGridApiReady={onGridApiReady}
        userId={userId}
      />
    </>
  );
}
