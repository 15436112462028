import { Editor } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import CharacterCount from "@tiptap/extension-character-count";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";

import { Typography } from "antd";
import clsx from "clsx";
import { debounce } from "lodash";
import React, { memo, useCallback } from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { TiptapEditorToolbar } from "./TiptapEditorToolbar";

import styles from "./TiptapEditor.module.scss";

interface TiptapEditorProps {
  onChange?: (content: string) => void;
  onSubmit?: (content: string) => void;
  initialEditorState: string;
  placeholder?: string;
  onBlur?: (e: React.SyntheticEvent) => void; // TODO: ARB-8872
  disabled?: boolean;
  flexibleHeight?: boolean;
  SubmitComponent?: React.FunctionComponent<{
    onSubmit: () => void;
    editorIsEmpty: boolean;
  }>;
  hasError: boolean;
  enterSendsMessage?: boolean;
  isChat?: boolean;
  maxLength?: number;
  textAreaClassName?: string;
}

const debounceTimer = 500;

export const TiptapEditorMemoized: React.FC<TiptapEditorProps> = memo(
  function TiptapEditor({
    onChange,
    onSubmit,
    initialEditorState,
    placeholder,
    disabled = false,
    hasError,
    SubmitComponent,
    flexibleHeight = false,
    enterSendsMessage = true,
    isChat = false,
    maxLength,
    textAreaClassName
  }) {
    const debouncedOnChange = useCallback(
      debounce((editorContent) => {
        onChange?.(editorContent);
      }, debounceTimer),
      [onChange]
    );

    const editor = useEditor(
      {
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          Underline,
          ListItem,
          OrderedList,
          BulletList,
          History.configure({
            depth: 10,
            newGroupDelay: 1000
          }),
          CharacterCount.configure({}),
          Placeholder.configure({
            placeholder,
            emptyEditorClass: styles.emptyEditor
          }),
          HardBreak.extend({
            addKeyboardShortcuts() {
              return {
                Enter: () => {
                  if (!isChat) {
                    return false;
                  }
                  if (!enterSendsMessage) {
                    return false;
                  }
                  if (this.editor.getText().trim() !== "" && !disabled) {
                    handleSubmit(this.editor);
                  }
                  return true;
                },
                "Shift-Enter": () =>
                  this.editor.commands.first(({ commands }) => [
                    () => commands.newlineInCode(),
                    () => commands.splitListItem("listItem"),
                    () => commands.createParagraphNear(),
                    () => commands.liftEmptyBlock(),
                    () => commands.splitBlock()
                  ]),
                "Ctrl-Enter": () =>
                  this.editor.commands.first(({ commands }) => [
                    () => commands.newlineInCode(),
                    () => commands.splitListItem("listItem"),
                    () => commands.createParagraphNear(),
                    () => commands.liftEmptyBlock(),
                    () => commands.splitBlock()
                  ])
              };
            }
          }),
          Link.configure({
            openOnClick: false,
            HTMLAttributes: {
              class: styles.link
            }
          })
        ],
        onUpdate: ({ editor }) => debouncedOnChange(editor.getHTML()),
        content: initialEditorState,
        editable: !disabled
      },
      // Very important for chat - otherwise we'll use old chat ID (send message to wrong chat)
      [onSubmit, disabled]
    );

    function handleSubmit(editorInstance: Editor) {
      if (!editorInstance || !onSubmit) return;

      onSubmit(editorInstance.getHTML());
      editorInstance.chain().clearContent().focus().run();
    }

    const editorIsEmpty = editor?.isEmpty || editor?.getText().trim() === "";

    if (!editor) return null;

    const percentage =
      editor && maxLength
        ? Math.round(
            (100 / maxLength) * editor.storage.characterCount.characters()
          )
        : 0;

    const getColor = (percentage: number) => {
      const greenColor = ARBOLUS_COLORS.bColorSecondaryGreen;
      const yellowColor = ARBOLUS_COLORS.bColorSecondaryAmber;
      const redColor = ARBOLUS_COLORS.bColorError;
      if (percentage < 50) {
        return greenColor;
      } else if (percentage < 80) {
        return yellowColor;
      } else {
        return redColor;
      }
    };

    const strokeColor = getColor(percentage);
    return (
      <div className={styles.editor}>
        <TiptapEditorToolbar editor={editor} disabled={disabled} />
        <div
          className={clsx(styles.innerArea, {
            [styles.withSubmit]: SubmitComponent,
            [styles.disabled]: disabled,
            [styles.error]: hasError
          })}
        >
          <div
            className={clsx(
              styles.textArea,
              textAreaClassName,
              flexibleHeight && styles.flexibleHeight
            )}
          >
            <EditorContent editor={editor} />
          </div>

          {typeof SubmitComponent === "function" && (
            <div className={styles.submit}>
              <SubmitComponent
                onSubmit={() => handleSubmit(editor)}
                editorIsEmpty={editorIsEmpty}
              />
            </div>
          )}
        </div>
        {maxLength && (
          <div
            className={clsx(
              styles.characterCount,
              `character-count ${editor.storage.characterCount.characters() === maxLength ? "character-count--warning" : ""}`
            )}
          >
            <Typography.Text style={{ fontSize: "12px" }}>
              {editor.storage.characterCount.characters()} / {maxLength}
            </Typography.Text>
            {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              style={{ marginLeft: "6px" }}
            >
              <circle r="10" cx="10" cy="10" fill="#e9ecef" />
              <circle
                r="5"
                cx="10"
                cy="10"
                fill="transparent"
                stroke={strokeColor}
                strokeWidth="10"
                strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
                transform="rotate(-90) translate(-20)"
              />
              <circle r="6" cx="10" cy="10" fill="white" />
            </svg>
          </div>
        )}
      </div>
    );
  }
);
