import {
  CIQError,
  DocumentService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";

const notification = new ToasterService();

export const convertDates = (startTime: string, endTime: string) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const optionsDates: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric"
  };
  const date = start.toLocaleDateString("en-US", optionsDates);

  const optionsHours: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };
  const hours = `${start.toLocaleTimeString("en-US", optionsHours)} - ${end.toLocaleTimeString("en-US", optionsHours)}`;

  return { date, hours };
};

export const handleOnDocumentClicked = (
  id: string,
  projectId: string
): void => {
  DocumentService.downloadDocument(projectId, id).subscribe({
    error: (error: ErrorResponse<CIQError>) => {
      notification.showError(error.message);
    }
  });
};
