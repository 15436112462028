import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  DocumentIcon,
  InfiniteScrollV2
} from "@arbolus-technologies/ui/components";
import { Checkbox, Flex, Input, Typography } from "antd";

import {
  ApiPaginatedRequest,
  CIQError,
  DefaultToasterService,
  Document,
  DocumentService,
  ErrorResponse,
  EventAttachmentModel,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  EVENT_ATTACHMENTS_SEARCH_DEBOUNCE,
  getFileExtension
} from "@arbolus-technologies/utils";
import styles from "./AttachmentsPanelBody.module.scss";

const { Text } = Typography;
const { Search } = Input;

const LIMIT = 20;

interface AttachmentsPanelBodyProps {
  projectId: string;
  attachments: EventAttachmentModel[];
  preselectedAttachments: EventAttachmentModel[];
  handleSelectPreselectedAttachment: (attachment: Document) => void;
  handleRefetchDocuments: (shouldRefetch: boolean) => void;
  refetchDocuments: boolean;
  documentService?: typeof DocumentService;
  notificationService?: ToasterService;
}

export const AttachmentsPanelBody: React.FC<AttachmentsPanelBodyProps> = ({
  projectId,
  attachments,
  preselectedAttachments,
  handleSelectPreselectedAttachment,
  handleRefetchDocuments,
  refetchDocuments,
  documentService = DocumentService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("attachmentsPanel");

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const documentServiceEndpoint = isAdmin
    ? documentService.getAdminDocuments
    : documentService.getClientDocuments;

  const [documents, setDocuments] = useState<Document[]>([]);
  const [areDocumentsLoading, setAreDocumentsLoading] =
    useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");

  // Debounce search
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, EVENT_ATTACHMENTS_SEARCH_DEBOUNCE);

    return () => clearTimeout(handler); // Cleanup timeout if user types again before delay
  }, [searchQuery]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchDocuments(true);
  }, [debouncedSearch, refetchDocuments]);

  const fetchDocuments = (reset = false) => {
    if (areDocumentsLoading) return;

    setAreDocumentsLoading(true);

    const params: ApiPaginatedRequest = {
      searchTerm: debouncedSearch,
      offset: reset ? 0 : offset,
      orderBy: "Created",
      orderDirection: SORT_DIRECTION.DESCENDING,
      limit: LIMIT
    };

    documentServiceEndpoint(projectId, params).subscribe(
      ({ items, pagination }) => {
        setDocuments((prevDocs) => (reset ? items : [...prevDocs, ...items]));
        setHasMore(
          items.length > 0 && params.offset + LIMIT < pagination.count
        );
        setOffset((prevOffset) => (reset ? LIMIT : prevOffset + LIMIT));
        setAreDocumentsLoading(false);
        handleRefetchDocuments(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setAreDocumentsLoading(false);
        handleRefetchDocuments(false);
        notificationService.showApiErrors(error);
      }
    );
  };

  const handleBottomReached = () => {
    if (hasMore && !areDocumentsLoading) {
      fetchDocuments();
    }
  };

  const renderDocumentItem = (document: Document) => {
    const extension = getFileExtension(document?.fileName ?? "");
    const isAddedToForm = attachments
      .map((attachment) => attachment.id)
      .includes(document.id);

    return (
      <Checkbox
        key={document.id}
        value={document.id}
        onChange={() => handleSelectPreselectedAttachment(document)}
        disabled={isAddedToForm}
      >
        <Flex gap={4} align="center">
          <DocumentIcon
            fileExtension={extension}
            customHeight="22px"
            customWidth="22px"
            fontSize="14px"
          />
          <Text>{document.fileName}</Text>
        </Flex>
      </Checkbox>
    );
  };

  return (
    <Flex vertical gap={24}>
      <Text>{t("attachmentsDescription")}</Text>
      <Search
        placeholder={t("searchAttachmentPlaceholder")}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Checkbox.Group
        value={[
          ...new Set([
            ...attachments.map((attachment) => attachment.id),
            ...preselectedAttachments.map((attachment) => attachment.id)
          ])
        ]}
        className={styles.infiniteContainer}
      >
        <InfiniteScrollV2
          onBottomReached={handleBottomReached}
          items={documents}
          renderer={renderDocumentItem}
          isLoading={areDocumentsLoading}
        />
      </Checkbox.Group>
    </Flex>
  );
};
