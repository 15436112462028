import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  ErrorResponse,
  EventService,
  ListEventsParams,
  SharedRoutes,
  ToasterService
} from "@arbolus-technologies/api";
import {
  FEATURE_FLAGS,
  SelectOption
} from "@arbolus-technologies/models/common";
import {
  PROJECT_CALENDAR_ROUTE_OLD,
  PROJECT_NEW_EVENT_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { utilService } from "@arbolus-technologies/utils";
import { Button, Calendar, Card, Flex, Switch, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useFeature } from "flagged";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import CalendarHeader from "./CalendarAntDHeader";
import styles from "./CalendarAntDPage.module.scss";
import EventCellRenderer from "./EventCellRenderer";
import SelectedEventCard from "./SelectedEventCard";
import { EventCalendarItem } from "./types";

// Constants
const START_EVER_DATE = utilService.convertActiveZoneToUTC(
  moment.utc("1970-01-01T00:00:00Z").toDate()
);
const EVENTS_LIMIT = 1000;

const notification = new ToasterService();

interface CalendarAntDPageProps {
  projectName: string;
  projectTimezone: string;
  timezones: Map<string, SelectOption>;
  currentTimeZone: SelectOption;
  navigateToCreateEvent: (tabRouteName: string) => void;
}

const { Text } = Typography;

export const CalendarAntDPage: React.FC<CalendarAntDPageProps> = ({
  projectName,
  projectTimezone,
  timezones,
  currentTimeZone,
  navigateToCreateEvent
}) => {
  const { t } = useTranslation("calendarAntDPage");

  const projectTimezoneLabel = `${timezones.get(projectTimezone)?.label} - ${t("projectTimezone")}`;
  const projectTimezoneValue = timezones.get(projectTimezone)?.value;

  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    projectTimezoneValue || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [calendarReady, setCalendarReady] = useState(false);
  const [events, setEvents] = useState<EventCalendarItem[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<
    EventCalendarItem | undefined
  >(undefined);
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();

  const calendarAntD = useFeature(FEATURE_FLAGS.NewCalendarAntD);

  const fetchEvents = () => {
    setIsLoading(true);
    const eventsPaginatedRequest: ListEventsParams = {
      limit: EVENTS_LIMIT,
      offset: 0,
      count: EVENTS_LIMIT,
      excludePastEvents: false,
      startDate: START_EVER_DATE
    };

    EventService.listEvents(projectId, eventsPaginatedRequest).subscribe(
      (response) => {
        setEvents(response.items as any);
        setIsLoading(false);
        setCalendarReady(true);
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        setIsLoading(false);
      }
    );
  };

  const getSingleEvent = (eventId: string) => {
    EventService.getEvent(projectId, eventId).subscribe(
      (eventResponse) => {
        setSelectedEvent(eventResponse as any);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        setIsLoading(false);
      }
    );
  };

  const handleJoinCall = (): void => {
    if (selectedEvent?.meetingJoinUrl) {
      window.open(selectedEvent.meetingJoinUrl, "_blank");
    }
  };

  const handleTimezoneChange = (newTimezone: string) => {
    setSelectedTimezone(newTimezone);
  };

  const handleEditEventClick = (): void => {
    if (selectedEvent) {
      history.push(
        SharedRoutes.PROJECT_EVENT_ROUTE_WITH_EVENT(projectId, selectedEvent.id)
      );
    }
  };

  const deleteEvent = (): void => {
    if (selectedEvent) {
      EventService.deleteEvent(projectId, selectedEvent.id).subscribe(
        ({ deleted }) => {
          if (deleted) {
            fetchEvents();
            setSelectedEvent(undefined);
          }
        },
        (error: ErrorResponse<CIQError>) =>
          notification.showError(error.message)
      );
    }
  };

  const isEventPast = selectedEvent
    ? dayjs().isAfter(dayjs(selectedEvent.endTime))
    : false;

  const timezoneOptions = [
    {
      value: projectTimezoneValue || "",
      label: projectTimezoneLabel
    },
    {
      value: currentTimeZone.value || "",
      label: `${currentTimeZone.label} - ${t("yourTimezone")}`
    }
  ];

  const cellRenderer = (date: Dayjs) => (
    <EventCellRenderer
      date={date}
      events={events}
      selectedTimezone={selectedTimezone}
      onEventClick={getSingleEvent}
    />
  );

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleCreateEventPressed = () => {
    navigateToCreateEvent(PROJECT_NEW_EVENT_ROUTE(projectId));
  };

  const onChange = () => {
    history.push(PROJECT_CALENDAR_ROUTE_OLD(projectId));
  };

  return (
    <Flex
      vertical
      style={{
        background: ARBOLUS_COLORS.bColorSecondaryGreyLight,
        minHeight: "100vh",
        overflowY: "auto"
      }}
    >
      {!isLoading && calendarReady && (
        <>
          <AntDHeader
            stickyHeader
            title={`${t("calendar")}: ${projectName}`}
            backLink={PROJECT_ROUTE(projectId).pathname}
            rightContainer={
              <Flex gap={16} align="center">
                {calendarAntD && (
                  <>
                    <Switch defaultChecked onChange={onChange} size="small" />
                    <Text>{t("newCalendar")}</Text>
                  </>
                )}

                <Button
                  type="primary"
                  onClick={handleCreateEventPressed}
                  icon={<AntDIcon name="add" />}
                >
                  {t("event")}
                </Button>
              </Flex>
            }
          />

          <Flex className={styles.mainContainer} gap={16}>
            <Card className={styles.mainCard}>
              <Calendar
                cellRender={cellRenderer}
                onPanelChange={setSelectedDate}
                value={selectedDate}
                className={styles.calendarAntD}
                headerRender={({ value, onChange }) => (
                  <CalendarHeader
                    value={value}
                    onChange={onChange}
                    timezoneOptions={timezoneOptions}
                    selectedTimezone={selectedTimezone}
                    onTimezoneChange={handleTimezoneChange}
                  />
                )}
              />
            </Card>

            {selectedEvent && (
              <SelectedEventCard
                selectedEvent={selectedEvent}
                isEventPast={isEventPast}
                onEditEvent={handleEditEventClick}
                onJoinCall={handleJoinCall}
                onDeleteEvent={deleteEvent}
                projectId={projectId}
              />
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
