import { useEffect, useState } from "react";

import { APP_USER_ROLES } from "@arbolus-technologies/models/common";
import { Loader } from "@arbolus-technologies/ui/components";
import { getQueryParams } from "@arbolus-technologies/utils";

import { useRegister } from "../../hooks/useRegister";
import { registerWithLi } from "../../utils/linkedInAuth";
import { EmailOrLI } from "../EmailOrLI/EmailOrLI";
import { TermsConditions } from "../TermsConditions/TermsConditions";

export function ConfirmAccount({ onEmail }: { onEmail: VoidFunction }) {
  const queryParams = getQueryParams();
  const { email, token, signUpType, canopyId } = queryParams;
  const isCanopySignUp = canopyId && signUpType === "canopy";
  const registrationData = useRegister({ email, token });
  const [isLIFlow, setIsLIFlow] = useState(false);

  const isExpert =
    registrationData?.accountType === APP_USER_ROLES.expert || isCanopySignUp;

  useEffect(
    function redirectClient() {
      if (!registrationData) return;
      // Changes stage in RegisterPage to TERMS - will unmount current component
      if (!isExpert) onEmail();
    },
    [registrationData, isExpert, isCanopySignUp]
  );

  if (!registrationData || !isExpert) return <Loader />;

  if (isLIFlow) {
    return (
      <TermsConditions
        terms={registrationData.terms.text}
        acceptTerms={registerWithLi}
      />
    );
  }

  return <EmailOrLI onLI={() => setIsLIFlow(true)} onEmail={onEmail} />;
}
