import clsx from "clsx";
import { goBack, push } from "connected-react-router";
import i18next from "i18next";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Observable, zip } from "rxjs";
import SimpleBar from "simplebar-react";

import {
  CIQError,
  ErrorResponse,
  ExpertService,
  ProjectApplication,
  ProjectExpertAvailability,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import {
  EDIT_AVAILABILITY_UPDATE_ROUTE,
  PROJECT_APPLICATION_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";

import { ExpertAvailabilityScheduler } from "../ExpertAvailabilityScheduler/ExpertAvailabilityScheduler";

import styles from "./AvailabilitySchedulerPage.module.scss";

interface LocationState {
  expertName?: string;
  availabilityLinkExpired?: string;
}

export const AvailabilitySchedulerPage: React.FC = () => {
  const { t } = useTranslation("availabilityScheduler");
  const history = useHistory<LocationState>();

  const [availabilityDetails, setAvailabilityDetails] =
    useState<ProjectExpertAvailability>({
      expertAvailabilitySlots: [],
      expertTimezone: "",
      projectTimezone: "",
      projectName: "",
      hasBeenRequiredMoreAvailabilitySlots: false
    });
  const dispatch = useDispatch();
  const notificationService = new ToasterService();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isExpertApproved, setIsExpertApproved] = useState<boolean>(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const timezones = useSelector(
    CacheSelector.appTimezoneSelectOptionMapSelector()
  );
  const loggedInExpertId = useSelector(CacheSelector.loggedInUserExpertId());

  const pathName = window.location.pathname.split("/");
  const expertId = isAdmin ? pathName[4] : loggedInExpertId;
  const projectId = pathName[2];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { linkExpired }: any = queryString.parse(history.location.search);

  useEffect(() => {
    if (linkExpired === "true") {
      notificationService.showError(t("availabilityLinkExpiredMessage"));
      history.push("/", { availabilityLinkExpired: linkExpired });
    } else {
      const promises: Observable<
        ProjectExpertAvailability | ProjectApplication
      >[] = [ExpertService.getProjectExpertAvailability(projectId, expertId)];

      if (!isAdmin) {
        promises.push(ProjectService.getExpertApplicationBrief(projectId));
      }

      zip(...promises).subscribe(
        (response) => {
          if (response.length > 1) {
            const {
              referral: { activeReferral, applicationStatus }
            } = response[1] as ProjectApplication;

            const isApplicationAccepted =
              applicationStatus === REFERRAL_SUB_STATE.ACCEPT;
            if (!isApplicationAccepted) {
              dispatch(push(PROJECT_APPLICATION_ROUTE(projectId)));
            }
            setIsExpertApproved(activeReferral);
          }
          setAvailabilityDetails(response[0] as ProjectExpertAvailability);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          if (error.message === i18next.t("restService:accessDenied")) {
            history.push("/");
          } else {
            notificationService.showError(error.message);
            setIsLoading(false);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveExpertAvailability = (
    values: ProjectExpertAvailability
  ): void => {
    setIsLoading(true);

    ExpertService.addExpertAvailability(
      projectId,
      expertId,
      values.expertAvailabilitySlots,
      values.expertTimezone
    ).subscribe(
      () => {
        if (window.history.state) {
          dispatch(goBack());
        } else if (isExpertApproved) {
          dispatch(push(EDIT_AVAILABILITY_UPDATE_ROUTE(isAdmin, projectId)));
        } else {
          dispatch(push(PROJECT_APPLICATION_ROUTE(projectId)));
        }
        notificationService.showSuccess(t("saveSuccess"));
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
        notificationService.showError(error.message);
      }
    );
  };

  return (
    <div className={clsx("page-content", styles.availabilitySchedulerPage)}>
      <SimpleBar className={clsx("simplebar-light", styles.simplebarStyle)}>
        {isLoading ? (
          <Loader />
        ) : (
          <ExpertAvailabilityScheduler
            projectTimezone={availabilityDetails.projectTimezone!}
            currentTimezone={availabilityDetails.expertTimezone}
            timezones={timezones}
            expertName={history.location.state?.expertName || ""}
            expertAvailabilitySlotsSelected={
              availabilityDetails.expertAvailabilitySlots
            }
            onSaveProgress={handleSaveExpertAvailability}
            projectName={availabilityDetails.projectName}
            isAdmin={isAdmin}
            hasBeenRequiredMoreAvailabilitySlots={
              availabilityDetails.hasBeenRequiredMoreAvailabilitySlots
            }
          />
        )}
      </SimpleBar>
    </div>
  );
};
