import clsx from "clsx";
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService, USERS_API } from "@arbolus-technologies/api";
import { CustomAvatarSelect } from "@arbolus-technologies/features/common";
import { AntDHeader } from "@arbolus-technologies/ui/layout";

import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_USER_PROFILE_PAGE
} from "../../../../constants/ui";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { User } from "../../../../models/user";
import { DocumentService, UtilsService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { AppStoreActions } from "../../../app/store";
import { AuthSelector } from "../../../auth/store";
import EditUserPassword from "../../components/userProfile/editPassword/EditUserPassword";
import UserNotificationSettings from "../../components/userProfile/notificationSettings/UserNotificationSettings";
import UserProfileDetails from "../../components/userProfile/userProfileDetails/UserProfileDetails";

const notification = new ToasterService();

interface UserProfilePageStoreProps {
  userProfile: User;
}
interface UserProfilePageProps extends UserProfilePageStoreProps {
  getUserProfile: () => void;
}

interface UserProfilePageState {
  activeTab: number;
  isProfileImageLoading: boolean;
}

type UserProfilePageIntersectProps = UserProfilePageProps & WithTranslation;

class UserProfilePage extends Component<
  UserProfilePageIntersectProps,
  UserProfilePageState
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    getUserProfile: (): void => {}
  };

  constructor(props: UserProfilePageIntersectProps) {
    super(props);
    this.state = {
      activeTab: 0,
      isProfileImageLoading: false
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("myProfile");
  }

  componentWillUnmount(): void {
    this.userImageUploadSubscription?.unsubscribe();
  }

  private userImageUploadSubscription?: Subscription;

  handleTabChangeEvent = (tabIndex: number): void => {
    this.setState({
      activeTab: tabIndex
    });
  };

  handleSelectedImage = (userImage: File): void => {
    const { userProfile, getUserProfile, t } = this.props;
    const { id } = userProfile;

    this.setState({
      isProfileImageLoading: true
    });

    const formData = new FormData();
    formData.append("file", userImage);

    this.userImageUploadSubscription = DocumentService.uploadFile(
      USERS_API.ADD_USER_PROFILE_IMAGE(id),
      formData
    ).subscribe(
      () => {
        notification.showSuccess(t("userProfileImageUpdate"));
        this.setState({
          isProfileImageLoading: false
        });
        getUserProfile();
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        this.setState({
          isProfileImageLoading: false
        });
      }
    );
  };

  renderUserProfileImage = (): JSX.Element => {
    const { userProfile } = this.props;
    const { isProfileImageLoading } = this.state;
    return (
      <CustomAvatarSelect
        profileImageUrl={userProfile.profileImageUrl}
        onImageSelected={this.handleSelectedImage}
        isLoading={isProfileImageLoading}
      />
    );
  };

  renderNameAndProfession = (): JSX.Element => {
    const { userProfile } = this.props;
    return (
      <div className="name-container">
        <h3>{UtilsService.displayUserName(userProfile)}</h3>
        <p>{userProfile.title}</p>
      </div>
    );
  };

  renderUserDetailsAndPassword = (): JSX.Element => {
    const { activeTab } = this.state;
    const { t } = this.props;

    return (
      <>
        <Nav tabs style={{ justifyContent: "center" }}>
          <NavItem>
            <NavLink
              onClick={(): void => this.handleTabChangeEvent(0)}
              className={clsx({ active: activeTab === 0 })}
            >
              {t("details").toUpperCase()}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={(): void => this.handleTabChangeEvent(1)}
              className={clsx({ active: activeTab === 1 })}
            >
              {t("password").toUpperCase()}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={(): void => this.handleTabChangeEvent(2)}
              className={clsx({ active: activeTab === 2 })}
            >
              {t("notifications").toUpperCase()}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
            {/* @ts-ignore */}
            <UserProfileDetails />
          </TabPane>
          <TabPane tabId={1}>
            <EditUserPassword />
          </TabPane>
          <TabPane tabId={2}>
            <UserNotificationSettings />
          </TabPane>
        </TabContent>
      </>
    );
  };

  render(): JSX.Element {
    const { t } = this.props;
    return (
      <Media queries={APP_DEVICE_MEDIA_QUERIES}>
        {(matches): JSX.Element => (
          <div className="page-wrapper">
            <div className="user-container page-content">
              <div className="tab-page-header page-content-header header-active">
                <AntDHeader title={t("myProfile")} />
                <div className="bottom-container" />
              </div>
              <SimpleBar
                className="simplebar-light"
                style={{
                  maxHeight: UI_USER_PROFILE_PAGE.USER_PROFILE_HEIGHT(matches),
                  height: UI_USER_PROFILE_PAGE.USER_PROFILE_HEIGHT(matches),
                  overflowX: "hidden"
                }}
              >
                <div className="user-page-body">
                  <div className="top-container">
                    {this.renderUserProfileImage()}
                    {this.renderNameAndProfession()}
                  </div>
                  {this.renderUserDetailsAndPassword()}
                </div>
              </SimpleBar>
            </div>
          </div>
        )}
      </Media>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  UserProfilePageProps,
  UserProfilePageStoreProps
>({
  userProfile: AuthSelector.authUserSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  getUserProfile: (): AppAction => dispatch(AppStoreActions.getUserProfile())
});

export default withTranslation("userProfilePage")(
  connect(mapStateToProps, mapDispatchToProps)(UserProfilePage)
);
