import { MONITORING_LIST_ROUTES } from "@arbolus-technologies/features/common";
import {
  CUSTOMERS_CLIENT_ROUTES,
  EXPERT_PROFILE_EDIT_ROUTES,
  EXPERT_PROJECT_APPLICATION,
  EXPERT_SELF_PROFILE_EDIT,
  INBOX,
  MONITORING_LIST,
  PAYMENTS_ROUTES,
  SURVEY_ROUTES
} from "@arbolus-technologies/routes";

import {
  CANOPY_COMMON_ROUTES,
  CLIENT_CANOPY_ROUTES,
  CLIENT_CANOPY_V2_ROUTES,
  EXPERT_CANOPY_ROUTES
} from "./canopyRoutes";
import {
  PROJECT_CLIENT_ROUTES,
  PROJECT_COMMON_ROUTES,
  PROJECT_EXPERTS,
  PROJECT_EXPERT_AVAILABILITY,
  PROJECT_EXPERT_ROUTES
} from "./projectRoutes";

// Home
export const DASHBOARD = "/";

// Welcome
export const WELCOME = "/welcome";

export const FEATURE_OVERRIDE = "/feature-override";

// Endorsement
export const ENDORSEMENT = "/request-endorsement";

// Projects
export const PROJECTS = "/projects";

// New Project
const PROJECT_DRAFT_BASE_ROUTE = "/draft";
export const NEW_PROJECT = PROJECT_DRAFT_BASE_ROUTE;
export const DRAFT_PROJECT = `${PROJECT_DRAFT_BASE_ROUTE}/:draftId`;
export const DRAFT_PROJECT_ROUTE = (draftId: string): string =>
  `${PROJECT_DRAFT_BASE_ROUTE}/${draftId}`;

// User Management
const USERS_BASE_ROUTE = "/users";
export const USERS = USERS_BASE_ROUTE;
export const NEW_USER = `${USERS_BASE_ROUTE}/new-user`;
export const EDIT_USER = `${USERS_BASE_ROUTE}/:userId`;
export const EDIT_USER_ROUTE = (userId: string): string =>
  `${USERS_BASE_ROUTE}/${userId}`;

// Client User Profile
export const USER_PROFILE = `/account`;
// Client Profile
export const CLIENT_ACCOUNT = "/client-account";

// Find Experts
export const FIND_EXPERTS_PROFILE = "/expert/:expertId";
export const EXPERT_PROFILE_ROUTE = (expertId: string): string =>
  `expert/${expertId}`;

// Expert Network
export const EXPERT_NETWORK = "/expert-network";
export const EXPERT_NETWORK_ROUTES = [EXPERT_NETWORK, FIND_EXPERTS_PROFILE];

// Project
export const PROJECT_MAIN_PAGE_ROUTE = (projectId: string): string =>
  `/project/${projectId}`;

// Client Network
export const CLIENT_NETWORK = "/client-network";
export const CLIENT_NETWORK_ROUTES = [CLIENT_NETWORK];

export const EXPERT_ROUTES = [
  EXPERT_PROJECT_APPLICATION,
  EXPERT_SELF_PROFILE_EDIT,
  ENDORSEMENT
].concat(
  CLIENT_NETWORK_ROUTES,
  PROJECT_EXPERT_ROUTES,
  EXPERT_PROFILE_EDIT_ROUTES,
  EXPERT_CANOPY_ROUTES,
  SURVEY_ROUTES
);

export const CLIENT_ROUTES = [
  USER_PROFILE,
  NEW_PROJECT,
  DRAFT_PROJECT,
  PROJECT_EXPERTS
].concat(
  EXPERT_NETWORK_ROUTES,
  PROJECT_CLIENT_ROUTES,
  CLIENT_CANOPY_ROUTES,
  CLIENT_CANOPY_V2_ROUTES
);

export const CLIENT_ADMIN_ROUTES = [EDIT_USER, NEW_USER, USERS, CLIENT_ACCOUNT];

export const AUTH_ROUTES = [
  DASHBOARD,
  WELCOME,
  INBOX,
  MONITORING_LIST,
  PROJECTS,
  FEATURE_OVERRIDE
].concat(
  CLIENT_ROUTES,
  CLIENT_ADMIN_ROUTES,
  CUSTOMERS_CLIENT_ROUTES,
  EXPERT_ROUTES,
  PROJECT_COMMON_ROUTES,
  CANOPY_COMMON_ROUTES,
  PROJECT_EXPERT_AVAILABILITY,
  PAYMENTS_ROUTES,
  MONITORING_LIST_ROUTES
);
