import { Col, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface ExpertSurveyListSearchProps {
  handleSetSearchTerm: (searchTerm: string) => void;
}

const { Search } = Input;

export const ExpertSurveyListSearch: React.FC<ExpertSurveyListSearchProps> = ({
  handleSetSearchTerm
}) => {
  const { t } = useTranslation("expertSurveyListSearch");

  return (
    <Row gutter={24}>
      <Col xs={24} sm={12} lg={8}>
        <Search
          placeholder={t("searchPlaceholder")}
          allowClear
          onChange={(event) => handleSetSearchTerm(event.currentTarget.value)}
        />
      </Col>
    </Row>
  );
};
