import { cloneDeep, sample } from "lodash";
import { Handlers, createReducer } from "reduxsauce";

import {
  Angle,
  Document,
  IChatMessage,
  MAX_PAGE_SIZE_20,
  UserModel
} from "@arbolus-technologies/api";
import {
  ClassificationType,
  PaginatedStore
} from "@arbolus-technologies/models/common";
import {
  FilterTimeSelectorOptions,
  KEYWORDS_SEARCH_OPTIONS,
  PROJECT_CREATION_STEPS
} from "@arbolus-technologies/models/project";

import {
  CLEAR_PROJECT_MANAGERS,
  CREATE_ANGLE,
  CREATE_ANGLE_FAILURE,
  CREATE_ANGLE_SUCCESS,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_OLD,
  CREATE_PROJECT_SUCCESS,
  CreateAngleFailureAction,
  DELETE_ANGLE,
  DELETE_ANGLE_FAILURE,
  DELETE_ANGLE_SUCCESS,
  DELETE_CHAT_MESSAGE_SUCCESS,
  DeleteAngleSuccessAction,
  FILTER_DASHBOARD_BY_ANGLE,
  FORCE_RELOAD_PROJECTS,
  FilterDashboardByAngleAction,
  GET_ANGLE,
  GET_ANGLES_LIST,
  GET_ANGLES_LIST_FAILURE,
  GET_ANGLES_LIST_SUCCESS,
  GET_ANGLE_FAILURE,
  GET_ANGLE_SUCCESS,
  GET_BUSINESS_ENTITIES,
  GET_BUSINESS_ENTITIES_FAILURE,
  GET_BUSINESS_ENTITIES_SUCCESS,
  GET_CHAT_FILES,
  GET_CHAT_FILES_FAILURE,
  GET_CHAT_FILES_SUCCESS,
  GET_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_FAILURE,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_CLIENT_ID_SUCCESS,
  GET_PROJECT_ENGAGEMENT_AGREEMENTS,
  GET_PROJECT_ENGAGEMENT_AGREEMENTS_FAILURE,
  GET_PROJECT_ENGAGEMENT_AGREEMENTS_SUCCESS,
  GetAngleSuccessAction,
  GetAnglesListSuccessAction,
  GetBusinessEntitiesProjectSuccessAction,
  GetChatFilesAction,
  GetChatFilesSuccessAction,
  GetChatMessagesAction,
  GetChatMessagesSuccessAction,
  GetProjectClientIDSuccessAction,
  GetProjectEngagementAgreementsSuccessAction,
  LOAD_PROJECT_SUCCESS,
  LoadProjectSuccessAction,
  RECEIVE_NEW_CHAT_MESSAGE,
  RESET_CHAT_FILES,
  RESET_CHAT_MESSAGES,
  ReceiveNewChatMessageAction,
  SAVE_ARBOLUS_TEAM,
  SAVE_PROJECT_MANAGERS,
  SELECT_FILE_TO_SHARE,
  SEND_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE_FAILURE,
  SEND_CHAT_MESSAGE_SUCCESS,
  SET_DISPLAY_TIMEZONE,
  SHARE_CHAT_FILES,
  SHARE_CHAT_FILES_FAILURE,
  SHARE_CHAT_FILES_SUCCESS,
  SIMPLIFIED_CREATE_PROJECT,
  SIMPLIFIED_CREATE_PROJECT_FAILURE,
  SIMPLIFIED_CREATE_PROJECT_SUCCESS,
  STORE_ONLINE_CHAT_MEMBERS,
  SaveArbolusTeamAction,
  SaveProjectManagersAction,
  SelectFileToShareAction,
  SendChatMessageFailureAction,
  SetDisplayTimezoneAction,
  ShareChatFilesSuccessAction,
  StoreOnlineMembersByChatAction,
  UPDATE_ONLINE_CHAT_MEMBERS,
  UpdateOnlineMembersByChatAction
} from "./actions/ProjectActionTypes";
import { ExpertDiscoverHandlers } from "./handlers/ExpertDiscoverHandlers";
import { ReferralHandlers } from "./handlers/ReferralHandlers";
import { ProjectNxReducerState } from "./models/definitions";

const angleColors = [
  "#722ED1",
  "#1677FF",
  "#13C2C2",
  "#389E0D",
  "#EB2F96",
  "#FADB14",
  "#597EF7",
  "#BAE637",
  "#FA541C",
  "#FA8C16"
] as const;

export const initialState: ProjectNxReducerState = {
  angleColors: [],
  projectStepState: PROJECT_CREATION_STEPS.PROJECT_DETAILS,
  projectData: null,
  angleIdToUpdate: null,
  selectedAngle: {
    color: sample(angleColors) as string,
    expertCount: 0,
    expertDescription: "",
    hasScreeningQuestions: false,
    owner: {} as UserModel,
    questions: [{ question: "" }],
    title: "New Angle",
    isEdit: false,
    id: "",
    questionVersion: 0
  },
  projectDraft: {
    id: "",
    name: "New Project",
    industry: {
      id: "",
      name: ""
    },
    industryId: "",
    internalDescription: "",
    description: "",
    mainContactUser: undefined,
    mainContactUserId: "",
    adminContactNo: "",
    timeZone: "",
    currentStep: 0,
    businessEntityId: "",
    clientId: "",
    startDate: new Date(),
    endDate: null,
    caseCode: "",
    agreementId: "",
    classification: ClassificationType.Other,
    enableWorkspaces: true,
    excludedCompaniesIds: [],
    companiesOfInterestIds: [],
    targetCompany: null
  },
  anglesList: null,
  engagementAgreement: null,
  businessEntities: null,
  isLoadingProjectData: false,
  isProjectDraftLoading: false,
  isEngagementAgreementsLoading: false,
  isBusinessEntitiesLoading: false,
  isLoadingAnglesList: true,
  isCreatingProjectLoading: false,
  createAngleError: {
    errors: [],
    message: "",
    status: ""
  },
  forceReloadProjects: false,
  projectManagers: [],
  primaryProjectLead: undefined,
  secondaryProjectLead: undefined,
  isCreatingSimplifiedProject: false,
  chatMessages: { isLoading: false, store: undefined },
  isSendingMessage: false,
  isGetMessagesInitialFetch: true,
  chatFiles: { isLoading: false, store: undefined },
  selectedFilesToShare: [],
  isSharingFiles: false,
  onlineMembersByChat: null,
  expertDiscover: {
    filters: {
      expertLocations: [],
      workHistoryLocations: [],
      company: [],
      keywords: [],
      projects: [],
      customers: [],
      industries: []
    },
    filtersPreSelection: {
      expertLocations: [],
      workHistoryLocations: [],
      company: [],
      keywords: [],
      projects: [],
      customers: [],
      industries: []
    },
    workHistoryLocationFilterPeriod: FilterTimeSelectorOptions.All,
    industryFilterPeriod: FilterTimeSelectorOptions.All,
    companyFilterPeriod: FilterTimeSelectorOptions.All,
    keywordsFields: Object.values(KEYWORDS_SEARCH_OPTIONS),
    filtersQuery: {
      expertLocations: "",
      workHistoryLocations: "",
      projects: "",
      company: "",
      keyword: "",
      customers: ""
    },
    displayRecommendations: false,
    hideDncExperts: false,
    currentPage: 1,
    pageSize: MAX_PAGE_SIZE_20,
    expertsList: null,
    searchTerm: "",
    isLoading: false,
    customerSubFilters: {
      persona: [],
      spend: [],
      adoptionStage: [],
      nps: [],
      renewalIntent: []
    }
  },
  referrals: {
    tabCount: {
      shortList: 0,
      candidate: 0,
      scheduling: 0,
      booked: 0,
      rejected: 0
    }
  },
  displayTimezoneId: "Etc/UTC"
};

//GET ENGAGEMENT AGREEMENTS
const GetProjectEngagementAgreements = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isEngagementAgreementsLoading: true
});

const GetProjectEngagementAgreementsSuccess = (
  state = initialState,
  { payload }: GetProjectEngagementAgreementsSuccessAction
): ProjectNxReducerState => ({
  ...state,
  isEngagementAgreementsLoading: false,
  engagementAgreement: payload.responseEngagementAgreement
});

const GetProjectEngagementAgreementsFailure = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isEngagementAgreementsLoading: false
});

// GET BUSINESS ENTITIES
const GetBusinessEntitiesProject = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isBusinessEntitiesLoading: true
});

const GetBusinessEntitiesProjectSuccess = (
  state = initialState,
  { payload }: GetBusinessEntitiesProjectSuccessAction
): ProjectNxReducerState => {
  const businessEntities = payload.responseBusinessEntities.map((entity) => ({
    value: entity.id,
    label: entity.identifier
  }));
  return {
    ...state,
    isBusinessEntitiesLoading: false,
    businessEntities
  };
};

const GetBusinessEntitiesProjectFailure = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isBusinessEntitiesLoading: false
});

// CREATE ANGLE

const CreateAngle = (state = initialState): ProjectNxReducerState => ({
  ...state
});

const CreateAngleSuccess = (state = initialState): ProjectNxReducerState => ({
  ...state
});

const CreateAngleFailure = (
  state = initialState,
  { payload }: CreateAngleFailureAction
): ProjectNxReducerState => ({
  ...state,
  createAngleError: payload.error
});

// GET ANGLES LIST

const GetAnglesList = (state = initialState): ProjectNxReducerState => ({
  ...state,
  isLoadingAnglesList: true,
  createAngleError: initialState.createAngleError
});

const GetAnglesListSuccess = (
  state = initialState,
  { payload }: GetAnglesListSuccessAction
): ProjectNxReducerState => ({
  ...state,
  anglesList: payload.angleDraftsList,
  selectedAngle: {
    ...initialState.selectedAngle,
    color: sample(angleColors) as string
  },
  isLoadingAnglesList: false
});

const GetAnglesListFailure = (state = initialState): ProjectNxReducerState => ({
  ...state,
  isLoadingAnglesList: false
});

// DELETE ANGLE

const deleteAngle = (state = initialState): ProjectNxReducerState => ({
  ...state
});

const deleteAngleSuccess = (
  state = initialState,
  { payload }: DeleteAngleSuccessAction
): ProjectNxReducerState => {
  const handleFilterWhenDelete = () => {
    const angleDraftsListFiltered = state.anglesList?.filter(
      (angle) => angle.id !== payload.angleDraftDeletedId
    );
    if (angleDraftsListFiltered) {
      return angleDraftsListFiltered;
    }
    return null;
  };

  return {
    ...state,
    anglesList: handleFilterWhenDelete()
  };
};

const deleteAngleFailure = (state = initialState): ProjectNxReducerState => ({
  ...state
});

// GET ANGLE

const getAngle = (state = initialState): ProjectNxReducerState => ({
  ...state
});

const getAngleSuccess = (
  state = initialState,
  { payload }: GetAngleSuccessAction
): ProjectNxReducerState => {
  const respOwner = payload.angleResponse.owner;
  const questions = payload.angleResponse.questions.map((question) => ({
    question
  }));

  const reformAngle: Angle = {
    ...payload.angleResponse,
    isEdit: true,
    questions,
    owner: respOwner
  };

  return {
    ...state,
    selectedAngle: reformAngle
  };
};

const getAngleFailure = (state = initialState): ProjectNxReducerState => ({
  ...state
});

// CREATE PROJECT

const createProject = (state = initialState): ProjectNxReducerState => ({
  ...state,
  isCreatingProjectLoading: true
});

const createProjectSuccess = (state = initialState): ProjectNxReducerState => ({
  ...state,
  isCreatingProjectLoading: false
});

const createProjectFailure = (state = initialState): ProjectNxReducerState => ({
  ...state,
  isCreatingProjectLoading: false
});

const forceReloadProjects = (state = initialState): ProjectNxReducerState => ({
  ...state,
  forceReloadProjects: !state.forceReloadProjects
});

const saveProjectManagers = (
  state = initialState,
  { payload }: SaveProjectManagersAction
): ProjectNxReducerState => ({
  ...state,
  isLoadingProjectData: false,
  projectManagers: payload.projectManagers,
  primaryProjectLead: payload.primaryProjectLead,
  secondaryProjectLead: payload.secondaryProjectLead
});

const saveArbolusTeam = (
  state = initialState,
  { payload }: SaveArbolusTeamAction
): ProjectNxReducerState => ({
  ...state,
  isLoadingProjectData: false,
  projectData: {
    ...state.projectData!,
    arbolusTeam: {
      projectPrimaryLead: payload.primaryProjectLead,
      projectSecondaryLead: payload.secondaryProjectLead,
      standardManagers: payload.projectManagers
    }
  }
});

const clearProjectManagers = (state = initialState): ProjectNxReducerState => ({
  ...state,
  projectManagers: [],
  primaryProjectLead: undefined,
  secondaryProjectLead: undefined
});

const GetProjectClientIdSuccess = (
  state = initialState,
  { payload }: GetProjectClientIDSuccessAction
): ProjectNxReducerState => ({
  ...state,
  clientId: payload.clientId
});

const LoadProjectSuccess = (
  state = initialState,
  { payload }: LoadProjectSuccessAction
): ProjectNxReducerState => ({
  ...state,
  projectData: payload.projectData,
  displayTimezoneId: payload.projectData.timezone
});

const handleCreateSimplifiedProject = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isCreatingSimplifiedProject: true
});

const handleCreateSimplifiedProjectSuccess = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isCreatingSimplifiedProject: false
});

const handleCreateSimplifiedProjectError = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isCreatingSimplifiedProject: false
});

const handleFilterDashboardByAngleAction = (
  state = initialState,
  { payload }: FilterDashboardByAngleAction
): ProjectNxReducerState => ({
  ...state,
  dashboardAngleFilter: { ...payload }
});

// HANDLE GET CHAT MESSAGES

export const handleGetChatMessages = (
  state = initialState,
  { payload: { queryParams, isInitialFetch } }: GetChatMessagesAction
): ProjectNxReducerState => {
  const store = queryParams.offset === 0 ? undefined : state.chatMessages.store;

  return {
    ...state,
    chatMessages: {
      store,
      isLoading: true
    },
    isGetMessagesInitialFetch: isInitialFetch
  };
};

export const handleGetChatMessagesSuccess = (
  state = initialState,
  { payload: { response } }: GetChatMessagesSuccessAction
): ProjectNxReducerState => ({
  ...state,
  chatMessages: {
    ...state.chatMessages,
    isLoading: false,
    store: {
      items:
        response.pagination.offset === 0
          ? response.items
          : response.items.concat(state.chatMessages.store?.items ?? []),
      total: response.pagination.count
    }
  },
  isGetMessagesInitialFetch: false
});

export const handleGetChatMessagesFailure = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  chatMessages: {
    ...state.chatMessages,
    isLoading: false
  }
});

// HANDLE RESET CHAT MESSAGES

export const handleResetChatMessages = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  chatMessages: {
    ...state.chatMessages,
    isLoading: false,
    store: undefined
  }
});

// HANDLE SEND CHAT MESSAGE

export const handleSendChatMessage = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isSendingMessage: true
});

export const handleSendChatMessageSuccess = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isSendingMessage: false
});

export const handleSendChatMessageFailure = (
  state = initialState,
  { payload: { error } }: SendChatMessageFailureAction
): ProjectNxReducerState => ({
  ...state,
  isSendingMessage: false
});

export const handleReceiveNewChatMessage = (
  state = initialState,
  { payload: { message } }: ReceiveNewChatMessageAction
): ProjectNxReducerState => {
  const {
    id,
    userId,
    created,
    file,
    event,
    userFullName,
    userProfileImageUrl,
    userType
  } = message;
  const messageObject: IChatMessage = {
    seen: true,
    id,
    userId,
    created,
    file,
    event,
    message: message.message,
    userFullName,
    userProfileImageUrl,
    userType
  };
  const storeUpdated: PaginatedStore<IChatMessage> = {
    ...state.chatMessages.store,
    items: state.chatMessages.store
      ? state.chatMessages.store?.items.concat(messageObject)
      : [messageObject],
    total: 0
  };
  return {
    ...state,
    isSendingMessage: false,
    chatMessages: { store: storeUpdated, isLoading: false }
  };
};

// HANDLE GET CHAT FILES

export const handleGetChatFiles = (
  state = initialState,
  { payload: { queryParams } }: GetChatFilesAction
): ProjectNxReducerState => {
  const store = queryParams.offset === 0 ? undefined : state.chatFiles.store;

  return {
    ...state,
    chatFiles: {
      store,
      isLoading: true
    }
  };
};

export const handleGetChatFilesSuccess = (
  state = initialState,
  { payload: { response } }: GetChatFilesSuccessAction
): ProjectNxReducerState => {
  let responseItems: Document[] = [];
  if (response.pagination.offset === 0) {
    responseItems = response.items;
  } else if (state.chatFiles.store) {
    responseItems = state.chatFiles.store.items.concat(response.items);
  }
  return {
    ...state,
    chatFiles: {
      ...state.chatFiles,
      isLoading: false,
      store: {
        items: responseItems,
        total: response.pagination.count
      }
    }
  };
};

export const handleGetChatFilesFailure = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  chatFiles: {
    ...state.chatFiles,
    isLoading: false
  }
});

// HANDLE RESET CHAT FILES

export const handleResetChatFiles = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  chatFiles: {
    isLoading: false,
    store: undefined
  },
  selectedFilesToShare: []
});

// HANDLE SELECT FILE

export const handleSelectFileToShare = (
  state = initialState,
  { payload: { fileId } }: SelectFileToShareAction
): ProjectNxReducerState => {
  if (state.selectedFilesToShare.includes(fileId)) {
    return {
      ...state,
      selectedFilesToShare: state.selectedFilesToShare.filter(
        (selectedFileId) => selectedFileId !== fileId
      )
    };
  } else {
    return {
      ...state,
      selectedFilesToShare: [...state.selectedFilesToShare, fileId]
    };
  }
};

// HANDLE SHARE CHAT FILES

export const handleShareChatFiles = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isSharingFiles: true
});

export const handleShareChatFilesSuccess = (
  state = initialState,
  { payload: { response } }: ShareChatFilesSuccessAction
): ProjectNxReducerState => ({
  ...state,
  selectedFilesToShare: [],
  isSharingFiles: false
});

export const handleShareChatFilesFailure = (
  state = initialState
): ProjectNxReducerState => ({
  ...state,
  isSharingFiles: false
});

// HANDLE DELETE CHAT MESSAGE

export const handleDeleteChatMessageSuccess = (
  state = initialState,
  { payload: { response } }: ShareChatFilesSuccessAction
): ProjectNxReducerState => {
  const deletedMessageId = response.id;
  const messagesUpdated: IChatMessage[] =
    state.chatMessages.store?.items.filter(
      (message) => message.id !== deletedMessageId
    ) ?? [];
  const storeUpdated: PaginatedStore<IChatMessage> = {
    ...state.chatMessages.store,
    items: messagesUpdated,
    total: messagesUpdated.length
  };
  return {
    ...state,
    chatMessages: { store: storeUpdated, isLoading: false }
  };
};

// HANDLE STORE ONLINE CHAT MEMBERS
export const handleStoreOnlineChatMembers = (
  state = initialState,
  {
    payload: { channelName, onlineMembersByChat }
  }: StoreOnlineMembersByChatAction
): ProjectNxReducerState => {
  // Update onlineMembersByChat with the new data for the specific presence channel name
  const updatedOnlineMembersByChat = {
    ...state.onlineMembersByChat,
    [channelName]: onlineMembersByChat
  };

  // We have to deep copy the objects so Pusher do not update them automatically
  return {
    ...state,
    onlineMembersByChat: cloneDeep(updatedOnlineMembersByChat)
  };
};

export const handleUpdateOnlineChatMembers = (
  state: ProjectNxReducerState = initialState,
  { payload: { channelName, updatedMember } }: UpdateOnlineMembersByChatAction
): ProjectNxReducerState => {
  if (!state.onlineMembersByChat) {
    return state;
  }

  const currentChannel = state.onlineMembersByChat[channelName];
  const { members = {}, count, myID, me } = currentChannel;

  // Check if the member with the given id exists in the presence
  if (members[updatedMember.id]) {
    // Member exists, remove it
    const updatedMembers = { ...members };
    delete updatedMembers[updatedMember.id];

    const updatedChannel = {
      members: updatedMembers,
      count: count - 1,
      myID,
      me
    };

    const updatedOnlineMembersByChat = {
      ...state.onlineMembersByChat,
      [channelName]: updatedChannel
    };

    return {
      ...state,
      onlineMembersByChat: updatedOnlineMembersByChat
    };
  } else {
    // Member does not exist, include it
    const updatedChannel = {
      members: {
        ...members,
        [updatedMember.id]: updatedMember.info
      },
      count: count + 1,
      myID,
      me
    };

    const updatedOnlineMembersByChat = {
      ...state.onlineMembersByChat,
      [channelName]: updatedChannel
    };

    return {
      ...state,
      onlineMembersByChat: updatedOnlineMembersByChat
    };
  }
};

// HANDLE DISPLAY TIMEZONE
export const handleSetDisplayTimezone = (
  state = initialState,
  { payload: { timezoneId } }: SetDisplayTimezoneAction
): ProjectNxReducerState => ({ ...state, displayTimezoneId: timezoneId });

// Current `Handlers` definition not good enough for proper typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<ProjectNxReducerState, any> = {
  [GET_PROJECT_ENGAGEMENT_AGREEMENTS]: GetProjectEngagementAgreements,
  [GET_PROJECT_ENGAGEMENT_AGREEMENTS_SUCCESS]:
    GetProjectEngagementAgreementsSuccess,
  [GET_PROJECT_ENGAGEMENT_AGREEMENTS_FAILURE]:
    GetProjectEngagementAgreementsFailure,
  [GET_BUSINESS_ENTITIES]: GetBusinessEntitiesProject,
  [GET_BUSINESS_ENTITIES_SUCCESS]: GetBusinessEntitiesProjectSuccess,
  [GET_BUSINESS_ENTITIES_FAILURE]: GetBusinessEntitiesProjectFailure,
  [CREATE_ANGLE]: CreateAngle,
  [CREATE_ANGLE_SUCCESS]: CreateAngleSuccess,
  [CREATE_ANGLE_FAILURE]: CreateAngleFailure,
  [GET_ANGLES_LIST]: GetAnglesList,
  [GET_ANGLES_LIST_SUCCESS]: GetAnglesListSuccess,
  [GET_ANGLES_LIST_FAILURE]: GetAnglesListFailure,
  [DELETE_ANGLE]: deleteAngle,
  [DELETE_ANGLE_SUCCESS]: deleteAngleSuccess,
  [DELETE_ANGLE_FAILURE]: deleteAngleFailure,
  [GET_ANGLE]: getAngle,
  [GET_ANGLE_SUCCESS]: getAngleSuccess,
  [GET_ANGLE_FAILURE]: getAngleFailure,
  [CREATE_PROJECT_OLD]: createProject,
  [CREATE_PROJECT_SUCCESS]: createProjectSuccess,
  [CREATE_PROJECT_FAILURE]: createProjectFailure,
  [FORCE_RELOAD_PROJECTS]: forceReloadProjects,
  [SAVE_PROJECT_MANAGERS]: saveProjectManagers,
  [SAVE_ARBOLUS_TEAM]: saveArbolusTeam,
  [CLEAR_PROJECT_MANAGERS]: clearProjectManagers,
  [GET_CLIENT_ID_SUCCESS]: GetProjectClientIdSuccess,
  [LOAD_PROJECT_SUCCESS]: LoadProjectSuccess,
  [SIMPLIFIED_CREATE_PROJECT]: handleCreateSimplifiedProject,
  [SIMPLIFIED_CREATE_PROJECT_SUCCESS]: handleCreateSimplifiedProjectSuccess,
  [SIMPLIFIED_CREATE_PROJECT_FAILURE]: handleCreateSimplifiedProjectError,
  [FILTER_DASHBOARD_BY_ANGLE]: handleFilterDashboardByAngleAction,
  [GET_CHAT_MESSAGES]: handleGetChatMessages,
  [GET_CHAT_MESSAGES_SUCCESS]: handleGetChatMessagesSuccess,
  [GET_CHAT_MESSAGES_FAILURE]: handleGetChatMessagesFailure,
  [RESET_CHAT_MESSAGES]: handleResetChatMessages,
  [SEND_CHAT_MESSAGE]: handleSendChatMessage,
  [SEND_CHAT_MESSAGE_SUCCESS]: handleSendChatMessageSuccess,
  [SEND_CHAT_MESSAGE_FAILURE]: handleSendChatMessageFailure,
  [RECEIVE_NEW_CHAT_MESSAGE]: handleReceiveNewChatMessage,
  [GET_CHAT_FILES]: handleGetChatFiles,
  [GET_CHAT_FILES_SUCCESS]: handleGetChatFilesSuccess,
  [GET_CHAT_FILES_FAILURE]: handleGetChatFilesFailure,
  [RESET_CHAT_FILES]: handleResetChatFiles,
  [SELECT_FILE_TO_SHARE]: handleSelectFileToShare,
  [SHARE_CHAT_FILES]: handleShareChatFiles,
  [SHARE_CHAT_FILES_SUCCESS]: handleShareChatFilesSuccess,
  [SHARE_CHAT_FILES_FAILURE]: handleShareChatFilesFailure,
  [DELETE_CHAT_MESSAGE_SUCCESS]: handleDeleteChatMessageSuccess,
  [SET_DISPLAY_TIMEZONE]: handleSetDisplayTimezone,
  [STORE_ONLINE_CHAT_MEMBERS]: handleStoreOnlineChatMembers,
  [UPDATE_ONLINE_CHAT_MEMBERS]: handleUpdateOnlineChatMembers,
  ...ExpertDiscoverHandlers,
  ...ReferralHandlers
};

export const ProjectNxReducer = createReducer(initialState, handlers);
