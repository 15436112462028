import { Button } from "antd";
import { useTranslation } from "react-i18next";

import linkedinWhiteLogo from "../../assets/linkedin-white-logo.png";

import styles from "./LinkedInRegisterButton.module.scss";

export function LinkedInRegisterButton({ onClick }: { onClick: VoidFunction }) {
  const { t } = useTranslation("register");

  return (
    <Button
      type="primary"
      onClick={onClick}
      block
      size="large"
      className={styles.linkedinButton}
      icon={
        <img
          src={linkedinWhiteLogo}
          alt="LinkedIn"
          className={styles.linkedinLogo}
        />
      }
    >
      {t("linkedInRegisterButton")}
    </Button>
  );
}
