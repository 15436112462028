import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  AuthOption,
  AuthPageHeader,
  ExpertRegisterMethodSelector
} from "@arbolus-technologies/auth";
import { APP_TRACKING_ROUTES } from "@arbolus-technologies/models/common";
import { AppSelector as AppSelectorNx } from "@arbolus-technologies/stores/app";

import { PublicRouteConstants } from "../../../../../constants";
import { RegisterStages } from "../../../../../constants/auth";

export function OnboardSelectionBody({
  onOptionSelect,
  navigateToLogin,
  from
}: {
  onOptionSelect: (newStage: RegisterStages) => void;
  navigateToLogin: VoidFunction;
  from?: string;
}) {
  const { t } = useTranslation("register");
  const currentQueryString = window.location.search;
  const { LinkedInAuth } = useSelector(AppSelectorNx.getFeatureFlags());

  if (LinkedInAuth) {
    return (
      <ExpertRegisterMethodSelector
        onEmailRegister={() => onOptionSelect(RegisterStages.TERMS)}
        footer={
          <Trans
            ns="register"
            i18nKey="alreadyHaveAccount"
            components={[
              <Link
                key="0"
                to={{
                  pathname: PublicRouteConstants.LOGIN,
                  search: currentQueryString,
                  state: { from: from ?? APP_TRACKING_ROUTES.REGISTER }
                }}
              />
            ]}
          />
        }
      />
    );
  }

  return (
    <div className="sign-up-options-page">
      <AuthPageHeader title={t("createAccountHeader")} />
      <AuthOption
        title={t("expertAccountTitle")}
        description={t("expertMessage")}
        icon="person"
        onClick={(): void => onOptionSelect(RegisterStages.TERMS)}
      />
      <AuthOption
        title={t("companyAccountTitle")}
        description={t("companyMessage")}
        icon="work"
        onClick={(): void => onOptionSelect(RegisterStages.INQUIRY)}
      />
      <AuthOption
        title={t("teamAccount")}
        description={t("teamMessage")}
        icon="groups"
        onClick={navigateToLogin}
      />

      <div className="sign-up-options-footer">
        <Trans
          ns="register"
          i18nKey="alreadyHaveAccount"
          components={[
            <Link
              key="0"
              to={{
                pathname: PublicRouteConstants.LOGIN,
                search: currentQueryString,
                state: { from: from ?? APP_TRACKING_ROUTES.REGISTER }
              }}
            />
          ]}
        />
      </div>
    </div>
  );
}
