import { GridApi } from "ag-grid-community";
import React from "react";
import { useDispatch } from "react-redux";

import { SurveyExpert } from "@arbolus-technologies/api";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

export interface SurveyExpertGridContext {
  openBulkModifyOwner: (referrals: SurveyExpert[]) => void;
}

export const useSurveyExpertGridContext = (
  api?: GridApi,
  reloadData?: SurveyExpert[] // If you have to update the groups
): SurveyExpertGridContext => {
  const dispatch = useDispatch();

  const context = React.useMemo<SurveyExpertGridContext>(
    () => ({
      openBulkModifyOwner: (surveyExperts) =>
        dispatch(
          PanelStoreActions.openPanel(
            PanelId.ModifySurveyExpertOwner,
            surveyExperts
          )
        )
    }),
    [dispatch]
  );

  React.useEffect(() => {
    if (!api) {
      return;
    }

    api.setGridOption("context", context);
    api.refreshHeader();
    if (reloadData) {
      api.setGridOption("rowData", []);
      api.setGridOption("rowData", reloadData);
      api.expandAll();
    } else {
      api.redrawRows();
    }
  }, [api, reloadData, context]);

  return context;
};
