import { Moment } from "moment";

import {
  AngleModel,
  ClassificationType,
  DO_NOT_CONTACT_STATUS,
  DateString,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import {
  CONTACT_METHOD_FILTERS_ENUM,
  CONTACT_TYPE_FILTERS_ENUM
} from "@arbolus-technologies/models/expert";
import {
  ADOPTION_STAGE,
  ExpertUser,
  NPS,
  PERSONA,
  REFERRAL_REJECT_REASON,
  RENEWAL_INTENT,
  RejectDescription,
  SPEND
} from "@arbolus-technologies/models/project";

import { TAGLINE } from "../constants/api";
import { PROJECT_REFERRAL_STATE, PROJECT_STATES } from "../enums/projectEnums";
import { CALL_STATUSES } from "../enums/projectExpertEnums";
import { ApiPaginatedRequest, MultiSortPaginatedRequest } from "./api";
import { BusinessEntityProject, ProjectBusinessEntity } from "./businessEntity";
import { ProjectClient, ProjectClientInfo } from "./client";
import { IEntity, UserModel } from "./common";
import { Company } from "./company";
import { Country } from "./meta";
import { User } from "./user";

export interface MemberUser extends User {
  emailConfirmed: boolean;
}

export interface ProjectOwner {
  id: string;
  firstName?: string;
  lastName?: string;
}

export interface ListProjectBase {
  id: string;
  name: string;
  projectState?: string;
  caseCode?: string;
}

export interface AvailabilityExpert {
  tagline: string;
  expertId: string;
  id: string;
  firstName: string;
  lastName: string;
  profileImageUrl?: string;
  lastPublicCompanyExpDate: DateString | null;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
}

export interface EarliestAvailability {
  expert: AvailabilityExpert;
  expertAvailabilitySlots: ExpertAvailabilitySlot[];
  projectId: string;
  projectName: string;
  referralId: string;
  timezone: string;
  status: REFERRAL_STATUS;
  reviewStatus: REFERRAL_SUB_STATE;
}

export interface ExpertAvailabilitySlot {
  startTime: string;
  endTime: string;
}

export interface ProjectHistory {
  projectId: string;
  projectName: string;
  projectStatus: string;
  projectCreationDate: string | null;
  projectPrimaryLeadUserId: string | null;
  projectPrimaryLeadName: string | null;
  projectCallsSold: number;
  projectPrimaryLeadUserProfileImageUrl: string | null;
}

export interface BaseProject extends IEntity {
  name: string;
  created: string;
  projectState: PROJECT_STATES;
  description: string;
}

export interface ExpertApplicationProject extends BaseProject {
  applicationStatus: StatusExpertTable;
}

export interface ListProject extends BaseProject {
  hasNewCandidates: boolean;
  hasUnreadMessages: boolean;
  client: ProjectClient;
  businessEntity: ProjectBusinessEntity;
}

export interface ArbolusTeam {
  projectPrimaryLead: User | null;
  projectSecondaryLead: User | null;
  standardManagers: User[];
}

export interface Project extends BaseProject {
  idealExpertDescription?: string;
  expertISOCountryCodes?: string[];
  expertCount?: string;
  startDate: string;
  endDate?: string;
  caseCode?: string;
  adminContactNo?: string;
  hasScreeningQuestions: boolean;
  mainContactUser: User;
  clientId: string;
  expertDetailsUpdateBy: User;
  projectDetailsUpdateBy: User;
  projectDetailsUpdate: Date;
  expertDetailsUpdate: Date;
  findExpertOption?: string;
  hasComplianceCheck: boolean;
  invitationUrl?: string;
  internalDescription: string;
  timezone: string;
  client: ProjectClientInfo;
  businessEntity: BusinessEntityProject;
  arbolusTeam: ArbolusTeam;
  defaultEventGuests: User[];
  classification: string;
  classificationDescription?: string;
  industry: { id: string; name: string };
  targetCompany: Company | null;
  managerName: string;
  managerEmail: string;
  partnerName: string;
  partnerEmail: string;
  enableWorkspaces: boolean;
  clientNotificationsEnabled: boolean;
  companiesOfInterest?: Company[];
  excludedCompanies?: Company[];
  isScreenSharingEnabled?: boolean;
}

export interface EditProjectRequest {
  adminContactNo?: string;
  businessEntityId: string;
  caseCode?: string;
  classification: ClassificationType;
  classificationDescription?: string;
  description?: string;
  endDate?: string | null;
  internalDescription?: string;
  mainContactUserId: string;
  managerEmail?: string;
  managerName?: string;
  name: string;
  partnerEmail?: string;
  partnerName?: string;
  primaryLeadUserId?: string;
  projectId: string;
  projectManagerUserIds?: string[];
  secondaryLeadUserId?: string;
  startDate: string;
  targetCompanyId?: string;
  timezone: string;
  industryId?: string;
}

export interface Member {
  id: string;
  projectId: string;
  userId: string;
  user: MemberUser;
  deleted: boolean;
}

export interface BaseReferral {
  id: string;
  referredUserId: string;
  expertId: string;
  projectId: string;
  status: PROJECT_REFERRAL_STATE;
  quote: string;
  fastTrack: FastTrack;
  activeReferral: boolean;
  complianceRequired: boolean;
  complianceNote?: string;
  chaperoneCall?: boolean;
  hasNote: boolean;
  allowResetApplication: boolean;
}

export interface Referral extends BaseReferral {
  quote: string;
  modified: Date;
  expert: User;
  review: BaseReviewStatus;
  application: BaseReviewStatus;
  compliance: ComplianceStatus;
}

export interface Slot {
  startTime: string;
  endTime: string;
  eventId?: string;
  isExpired?: boolean;
  isScheduled?: boolean;
}

export interface ProjectExpertAvailability {
  expertAvailabilitySlots: Slot[];
  expertTimezone: string;
  projectName?: string;
  projectTimezone?: string;
  hasBeenRequiredMoreAvailabilitySlots: boolean;
}

export interface ReferralDetail extends BaseReferral {
  referredUser: User;
  note?: ReferralNote;
  review: ReviewReviewStatus;
  application: ReviewStatus;
  compliance: ComplianceStatus;
  expertAvailabilitySlots?: Slot[];
  tagline: string;
  angle: AngleModel;
  expert: ExpertUser;
  referralAvailability: ReferralAvailability;
}

export interface ReferralAvailability {
  availabilityRequesterUser?: UserBasicInfoInterface;
  availabilityRequesterUserId?: string;
  availabilityRequiredDate?: Date;
  isRequiredMoreAvailabilitySlotsAllowed: boolean;
  waitingHours: number;
}

export interface BaseReviewStatus {
  subStatus: REFERRAL_SUB_STATE;
  updateDate: Date;
}

export interface ReviewStatus extends BaseReviewStatus {
  updateUserId: string;
  updateUser: User;
}

export interface ComplianceStatus {
  subStatus: REFERRAL_COMPLIANCE_STATE;
  updateDate: Date;
  updateUserId: string;
  updateUser: User;
}

export interface ReviewReviewStatus extends ReviewStatus {
  rejectDescription?: RejectDescription;
}

export interface FastTrack {
  fastTrack: boolean;
  fastTrackDate: Date;
}

export interface ReferralNote {
  note: string;
  noteUserId: string;
  noteUser: User;
  noteDate: string;
}

export interface ReferralSummary {
  recommendations: number;
  shortList: number;
  candidate: number;
  accept: number;
  reject: number;
  allExperts: number;
  review: number;
  scheduled: number;
  callsScheduled: number;
}

export interface ProjectAnswer {
  answerId?: string;
  questionId: string;
  question: string;
  answer?: string;
}

export interface ProjectAnswersResponse {
  version: number;
  items: ProjectAnswer[];
}

export interface ProjectCreateRequest {
  name: string;
  description: string;
  internalDescription: string;
  idealExpertDescription: string;
  expertISOCountryCodes: string[];
  expertCount: string;
  startDate: string;
  endDate?: string;
  timezone: string;
  adminContactNo: string;
  findExpertOption: string;
  caseCode?: string;
  mainContactUserId: string;
  hasScreeningQuestions: boolean;
  questions: string[];
  createdByAdmin?: boolean;
  businessEntity: string;
}

export interface DraftProjectBase {
  projectName: string;
  formData: string;
  mainContactUserId?: string;
}

export interface DraftProjectCreateRequest extends DraftProjectBase {
  clientId: string;
}

export interface DraftProjectCreateResponse {
  projectName: string;
  id: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DraftProjectUpdateRequest extends DraftProjectBase {}

export interface DraftProjectUpdateResponse extends DraftProjectBase {
  id: string;
}

export interface DraftProjectResponse extends DraftProjectBase {
  id: string;
  created: Date;
  modified: Date;
}

export interface UpdateReferralRequest {
  fastTrack?: boolean;
  status?: string;
  review?: string;
  compliance?: string;
  reset?: boolean;
  rejectReason?: REFERRAL_REJECT_REASON;
  rejectText?: string;
}

export interface UpdateReferralResponse {
  status: PROJECT_REFERRAL_STATE;
  review: ReviewStatus;
  compliance: ComplianceStatus;
  application: ReviewStatus;
}

export interface ProjectNotificationSubscription {
  Messages: boolean;
  NewJoins: boolean;
  Approvals: boolean;
  Compliance: boolean;
  SharedDocs: boolean;
  ThingsTodo: boolean;
  Applications: boolean;
  EventInvites: boolean;
  NewReferrals: boolean;
  MobileMessage: boolean;
}

export interface ProjectSettings {
  pushNotifications: boolean;
  notificationSubscription: ProjectNotificationSubscription;
  clientNotificationsEnabled: boolean;
}

export interface ProjectSettingsUpdateRequest {
  pushNotifications: boolean;
  notificationSubscription: ProjectNotificationSubscription;
  clientNotificationsEnabled?: boolean;
}

export interface BusinessEntitiesProject {
  items: {
    id: string;
    identifier: string;
    isActive: boolean;
  }[];
}

export enum PROJECT_EVENT_PLATFORM {
  CONNECT = "ArbolusConnect",
  ZOOM = "Zoom"
}

export enum ProjectDependencyType {
  CANOPY = "Canopy",
  STATE = "State"
}

export type ProjectDependencyErrors = {
  [key in ProjectDependencyType]: string[];
};

export interface DeleteProjectResponse {
  id: string;
  isDeleted: boolean;
  dependencyErrors: ProjectDependencyErrors;
}

export interface ProjectDraft {
  id?: string;

  findExpertOption: string;
  hasScreeningQuestions: boolean;

  projectName: string;
  internalOverview: string;
  overview: string;
  caseCode?: string;
  projectEndDate?: Date;
  projectStartDate: Date;
  mainContactUser: User;
  projectPhone: string;
  timezone: string;

  idealExpertDescription: string;
  countries: Country[];
  expertCount: string;

  questions: string[];

  currentStep: number;

  isSentForApproval: boolean;

  businessEntity: string;
}

// AFTER REFACTOR

interface UserBasicInfoInterface {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Angle extends AngleModel {
  owner: UserModel;
  expertDescription: string;
  expertCount: number;
  hasScreeningQuestions: boolean;
  questions: { question: string }[];
  isEdit: boolean;
  expertDetailsModifiedBy?: UserBasicInfoInterface;
  expertDetailsModifiedOn?: string;
  questionsModifiedBy?: UserBasicInfoInterface;
  questionsModifiedOn?: string;
  questionVersion: number;
}

export interface ProjectAngle extends AngleModel {
  ownerId: string;
  expertDescription: string;
  expertCount: number;
  questions: { question: string; id: string }[];
  isCompleted?: boolean;
}

export interface AngleResponse extends Omit<Angle, "owner"> {
  owner: {
    id: string;
    firstName: string;
    lastName: string;
    profileImageUrl: string;
  };
}

export interface AngleToSend
  extends Omit<
    Angle,
    | "questions"
    | "owner"
    | "isEdit"
    | "questionVersion"
    | "id"
    | "hasScreeningQuestions"
  > {
  id?: string;
  questions: string[];
  ownerId: string;
  isEdit?: boolean;
  projectId?: string;
  questionVersion?: number;
  hasScreeningQuestions?: boolean;
}

export interface QuestionsResponse {
  version: number;
  modifiedBy: UserBasicInfoInterface;
  modifiedOn: string;
  questions: {
    id: string;
    question: string;
    sortOrder: number;
  }[];
}

export interface UserPr
  extends Omit<
    User,
    | "profileImageUrl"
    | "isoCountryCode"
    | "title"
    | "phoneNumber"
    | "firstName"
    | "lastName"
  > {
  profileImageUrl: string | null;
  isoCountryCode: string | null;
  title: string | null;
  phoneNumber: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface BaseNxProject {
  id: string;
  name: string;
  clientId: string;
  mainContactUserId: string;
  startDate: Date;
  timeZone: string;
  businessEntityId: string;
  enableWorkspaces: boolean;
  classification: ClassificationType;
  classificationDescription?: string;
  currentStep?: number;
  endDate?: Date | null;
  description?: string;
  internalDescription?: string;
  adminContactNo?: string;
  caseCode?: string;
  managerName?: string;
  managerEmail?: string;
  partnerName?: string;
  partnerEmail?: string;
  targetCompanyId?: string;
  primaryLeadUserId?: string;
  secondaryLeadUserId?: string;
  projectManagerUserIds?: string[];
  companiesOfInterestIds?: string[];
  excludedCompaniesIds?: string[];
  isScreenSharingEnabled?: boolean;
}

export interface ProjectNx extends BaseNxProject {
  industry?: { id: string; name: string };
  industryId?: string;
  mainContactUser?: UserPr;
  agreementId?: string;
  companiesOfInterest?: Company[];
  excludedCompanies?: Company[];
  targetCompany: Company | null;
  arbolusTeam?: {
    projectPrimaryLead?: User | null;
    projectSecondaryLead?: User | null;
    standardManagers?: User[] | null;
  };
}

export interface CreateNewProjectBody
  extends Omit<ProjectNx, "id" | "enableWorkspaces"> {
  workspaceEnabled: boolean;
  projectDraftId: string;
}

export enum UpdateMode {
  SAVE = "save",
  NEXT = "next"
}

export interface AngleDraftResponse extends Omit<ProjectAngle, "questions"> {
  questions: string[];
}

export interface AngleDraftResponseList {
  items: AngleDraftResponse[];
}

export interface AngleSingleDraft extends Omit<Angle, "questions" | "owner"> {
  questions: string[];
  owner: {
    id: string;
    firstName: string;
    lastName: string;
    profileImageUrl: string;
  };
}

export interface ResponseAllExpertsTable {
  id: string;
  projectId: string;
  expert: {
    id: string;
    firstName: string;
    lastName: string;
    title: string;
    profileImageUrl: string | null;
    email: string;
    isAnyValidAvailability: boolean;
    lastPublicCompanyExpDate: DateString | null;
    doNotContactStatus: DO_NOT_CONTACT_STATUS;
  };
  reviewStatus: StatusExpertTable; // left border
  applicationStatus: StatusExpertTable;
  complianceStatus: StatusExpertTable | null; //optional - if it's null -> No Required - String/null
  isExpertInNetwork: boolean;
  tagline: string | null; // or Null-> if is null take expert.title
  angle: AngleModel;
  fastTrack?: boolean;
  status: string;
  currentStatusDate: Date;
  callStatus: CALL_STATUSES | null;
}

export interface RecommendationCompany {
  name: string;
  isCurrentWork: boolean;
}

export interface RecommendationResponse {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  profileImageUrl: string | null;
  recommendationLevel: number;
  companies: RecommendationCompany[];
}

export interface TaglineInterface {
  [TAGLINE]: string;
}

export interface TaglineRequestInterface {
  referralId: string;
  projectId: string;
  tagline: string;
}

export enum StatusExpertTable {
  Accept = "Accept",
  Reject = "Reject",
  Pending = "Pending"
}

export enum ExpertsListPageTab {
  Book = "Book",
  Recommendations = "Recommendations", // only in admin
  AllExperts = "AllExperts", // only in client
  ShortList = "ShortList", // only in admin
  Candidate = "Candidate", // only in admin
  Accept = "Accept",
  Reject = "Reject",
  Review = "Review"
}

export enum LIST_PROJECTS_ORDER_BY {
  CREATED = "Created",
  NAME = "Name",
  STATE = "State",
  CLIENT_NAME = "ClientName",
  CLASSIFICATION = "Classification",
  IN_PROJECT = "InProject",
  CONTACT = "Contact"
}

export type InfoBoxType = "notification" | "warning" | "error";
export type ActionBoxType = "approve" | "confirmApprove" | "confirmReject";

export interface ActionBoxInterface {
  type: ActionBoxType;
  title: string;
  description: string;
  icon: string;
  icon2: string;
  text: string;
  text2: string;
}

export interface BoxInterface {
  approve: ActionBoxInterface;
  confirmApprove: ActionBoxInterface;
  confirmReject: ActionBoxInterface;
}
export interface ActionBoxInterface {
  actionBoxData: BoxInterface;
  handleApprovedAnswers: () => void;
  handleRejectedAnswers: () => void;
}

export interface ProjectApplication {
  id: string;
  name: string;
  description: string;
  findExpertOption: string;
  referral: ProjectReferral;
  timezone: string;
}

export interface ProjectReferral {
  id: string;
  activeReferral: boolean;
  referralState: string;
  applicationStatus: string;
  fastTrack: boolean;
  questionsAnswered: boolean;
  complianceAnswered: boolean;
  expertAvailabilitySlotsSelected: boolean;
  hasCompliance: boolean;
  hasQuestions: boolean;
}

export interface ExpertSlot {
  startTime: DateString | Moment;
  endTime: DateString | Moment;
  expertId: string;
}

export interface ProjectReferralAvailability {
  slots: ExpertSlot[];
  experts: ExpertSlotDetails[];
}

export interface ExpertAvailability {
  expert: ExpertSlotDetails;
  slots: ExpertSlot[];
}

export interface ExpertSlotsByDate {
  date: string;
  experts: ExpertAvailability[];
  count: number;
}

export interface ExpertSlotDetails {
  id: string;
  email: string;
  expertId: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  angle: AngleModel;
  status: REFERRAL_STATUS;
  reviewStatus: "Accept" | "Pending";
  complianceStatus: "Accept" | "Pending" | null;
  applicationStatus: REFERRAL_SUB_STATE;
  tagline: string | null;
  referralId: string;
  hasMinimumCallTimeRate: boolean;
  timeSlots: ExpertSlot[];
  hasBeenCalled?: boolean;
  lastPublicCompanyExpDate: DateString | null;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
}

export interface ListProjectsRequest
  extends MultiSortPaginatedRequest<LIST_PROJECTS_ORDER_BY> {
  projectStates?: PROJECT_STATES[];
  inProject?: boolean;
  classifications?: ClassificationType[];
  projectName?: string;
  clientName?: string;
}

export interface ListProjectDraftsRequest
  extends MultiSortPaginatedRequest<LIST_PROJECTS_ORDER_BY> {
  inProject?: boolean;
  projectName?: string;
  clientName?: string;
}

export interface CompanyFilter {
  companyId: string;
  isExcluded: boolean;
}

export interface LocationFilter {
  locationId: string;
  isExcluded: boolean;
}

export interface ProjectFilter {
  projectId: string;
}

export interface ExpertDiscoverRequest {
  projectId: string;
  searchTerm?: string;
  offset: number;
  limit: number;
  count?: number;
  expertCurrentLocations: {
    filters: LocationFilter[];
  };
  workHistoryLocations: {
    filters: LocationFilter[];
    isCurrent: boolean;
    isPastNotCurrent: boolean;
  };
  companies: {
    filters: CompanyFilter[];
    isCurrent: boolean;
    isPastNotCurrent: boolean;
    companyInPastMonths: number;
  };
  projects: {
    filters: ProjectFilter[];
  };
  customers: {
    companyIds: string[];
    persona: PERSONA[];
    spend: SPEND[];
    adoptionStage: ADOPTION_STAGE[];
    nps: NPS[];
    renewalIntent: RENEWAL_INTENT[];
  };
  industries: {
    filters: string[];
    isCurrent: boolean;
    isPastNotCurrent: boolean;
  };
  keywords: string;
  keywordsFields: string[];
  isRecommendationFilterEnabled: boolean;
  isDoNotContactHideFilterEnabled: boolean;
  clientId?: string;
}

export interface ProjectCollectionRequest extends ApiPaginatedRequest {
  clientId?: string;
}

export interface ExpertBaseProject {
  id: string;
  name: string;
  activeReferral: boolean;
  hasUnreadMessages: boolean;
  referralApplicationStatus: REFERRAL_SUB_STATE;
}

export interface ProjectPipelineExpertRequest {
  angleId: string | null;
  searchTerm: string;
  excludeAlreadyAdded: boolean;
  projectPipelineStatus: CONTACT_TYPE_FILTERS_ENUM;
  projectPipelineContactMethod: CONTACT_METHOD_FILTERS_ENUM;
  offset: number;
  limit: number;
}

export interface ExportProjectPipelinesRequest {
  projectId: string;
  angleId: string | null;
  searchTerm: string;
  projectPipelineStatus: CONTACT_TYPE_FILTERS_ENUM;
  projectPipelineContactMethod: CONTACT_METHOD_FILTERS_ENUM;
  projectPipelineIds: string[];
  exportAllContacts: boolean;
}
