import { Button, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ARBOLUS_ABOUT_US,
  ARBOLUS_TRUSTPILOT
} from "@arbolus-technologies/features/common";
import { trustpilotArbolus } from "@arbolus-technologies/theme";
import { LinkWrapper } from "@arbolus-technologies/ui/components";

import styles from "./SurveyInformation.module.scss";

const { Text, Title } = Typography;

export const SurveyInformation: React.FC = () => {
  const { t } = useTranslation("surveyInformation");

  return (
    <Flex gap={48} className={styles.informationSectionContainer}>
      <Flex vertical gap={16} className={styles.informationSection}>
        <Title level={4}>{t("whoIsArbolusTitle")}</Title>
        <Text>{t("whoIsArbolusDescription")}</Text>
        <LinkWrapper to={ARBOLUS_ABOUT_US} isExternal target="_blank">
          <Button type="default" size="large">
            {t("learnMore")}
          </Button>
        </LinkWrapper>
      </Flex>
      <Flex vertical gap={16} className={styles.informationSection}>
        <Title level={4}>{t("howItWorksTitle")}</Title>
        <Flex gap={8} align="center">
          <div className={styles.listNumber}>1</div>
          <Text>{t("howItWorksText1")}</Text>
        </Flex>
        <Flex gap={8} align="center">
          <div className={styles.listNumber}>2</div>
          <Text>{t("howItWorksText2")}</Text>
        </Flex>
        <Flex gap={8} align="center">
          <div className={styles.listNumber}>3</div>
          <Text>{t("howItWorksText3")}</Text>
        </Flex>
      </Flex>
      <Flex vertical gap={16} className={styles.informationSection}>
        <Title level={4}>{t("ratedExcellentTitle")}</Title>
        <LinkWrapper to={ARBOLUS_TRUSTPILOT} isExternal target="_blank">
          <img src={trustpilotArbolus} alt="Arbolus trustpilot" />
        </LinkWrapper>
      </Flex>
    </Flex>
  );
};
