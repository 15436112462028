import { Grid } from "antd";

const { useBreakpoint } = Grid;

export function useIsMobile() {
  const { xs, sm, md } = useBreakpoint();
  // xs - width smaller then 576px
  // sm - width smaller than 768px
  // md checked to be sure it's not bigger than 768px
  const isMobile = (xs || sm) && !md;
  return isMobile;
}
