import React from "react";
import { useTranslation } from "react-i18next";

import { ExpertSurvey } from "@arbolus-technologies/api";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  SURVEY_EXPERT_STATUS,
  SURVEY_EXPERT_STATUS_COLORS
} from "@arbolus-technologies/models/survey";
import {
  SURVEY_PAYMENT_METHOD,
  SURVEY_ROUTE
} from "@arbolus-technologies/routes";
import { Card, Flex, Tag, Typography } from "antd";
import dayjs from "dayjs";
import styles from "./ExpertSurveyCard.module.scss";

interface ExpertSurveyCardProps {
  expertSurvey: ExpertSurvey;
}

const { Text, Title } = Typography;

export const ExpertSurveyCard: React.FC<ExpertSurveyCardProps> = ({
  expertSurvey
}) => {
  const { t } = useTranslation("expertSurveyCard");
  const { trackClick } = useArbolusTracking();

  const surveyButton = {
    [SURVEY_EXPERT_STATUS.IN_PROGRESS]: (
      <AntDButtonLink
        isExternal
        to={SURVEY_ROUTE(expertSurvey.surveyId)}
        text={t("answerNow")}
        type="primary"
      />
    ),
    [SURVEY_EXPERT_STATUS.COMPLETED]: (
      <AntDButtonLink
        to={`${SURVEY_PAYMENT_METHOD}?surveyExpertId=${expertSurvey.surveyExpertId}`}
        text={t("details")}
        onClick={() => trackClick(MixPanelEventNames.SurveyViewAllDetails)}
      />
    )
  };

  return (
    <Card
      styles={{
        body: {
          display: "flex",
          minHeight: "182px"
        }
      }}
    >
      <Flex vertical justify="space-between" gap={16} style={{ width: "100%" }}>
        <Tag
          color={SURVEY_EXPERT_STATUS_COLORS[expertSurvey.surveyExpertStatus]}
          className={styles.tag}
        >
          {t(`surveyExpertStatus_${expertSurvey.surveyExpertStatus}`)}
        </Tag>
        <Title
          level={5}
          ellipsis={{
            rows: 2
          }}
          title={expertSurvey.title}
        >
          {expertSurvey.title}
        </Title>
        <Flex gap={16} justify="space-between" align="center">
          <Text type="secondary">
            {dayjs(expertSurvey.completionDate).format(APP_DATE_FORMAT)}
          </Text>
          {surveyButton[expertSurvey.surveyExpertStatus]}
        </Flex>
      </Flex>
    </Card>
  );
};
