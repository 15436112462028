import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { CHAT_EVENT_STATUS, ChatEvent } from "@arbolus-technologies/api";
import { EVENT_PANEL_ROUTE } from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithBackground } from "@arbolus-technologies/ui/components";
import {
  CHAT_EVENT_FORMAT,
  CHAT_EVENT_FORMAT_SHORT,
  formatDate
} from "@arbolus-technologies/utils";

import { isMobileChat } from "../../../utils";

import styles from "./EventMessageContent.module.scss";

interface EventMessageContentProps {
  event: ChatEvent;
  isDncExpertChat: boolean;
}

export const EventMessageContent: React.FC<EventMessageContentProps> = ({
  event,
  isDncExpertChat
}) => {
  const { t } = useTranslation("chat");
  const history = useHistory();

  const startFormatted = formatDate(event.startTime, CHAT_EVENT_FORMAT);
  const endFormatted = formatDate(event.endTime, CHAT_EVENT_FORMAT_SHORT);
  const isRescheduled = event.status === CHAT_EVENT_STATUS.MODIFIED;
  const isCancelled = event.status === CHAT_EVENT_STATUS.DELETED;
  const isEventUpdated = isRescheduled || isCancelled;

  const iconSize = isMobileChat() ? "small" : "big";
  const iconSizePx = isMobileChat() ? "16px" : "24px";

  const handleClickEvent = () => {
    if (isDncExpertChat) {
      return;
    }

    history.replace(EVENT_PANEL_ROUTE(event.id));
  };

  const eventUpdatedDescription: Record<CHAT_EVENT_STATUS, string> = {
    [CHAT_EVENT_STATUS.DELETED]: "eventCancelled",
    [CHAT_EVENT_STATUS.MODIFIED]: "eventRescheduled",
    [CHAT_EVENT_STATUS.NEW]: ""
  };

  return (
    <>
      <div
        className={clsx(styles.event, {
          [styles.eventBorder]: isEventUpdated,
          [styles.isEventCancelled]: isCancelled || isDncExpertChat
        })}
      >
        <div className={styles.eventIconAndDetails}>
          <IconWithBackground
            color={ARBOLUS_COLORS.bColorBaseWhite}
            backgroundColor={ARBOLUS_COLORS.bColorBaseBlue}
            name="calendar_today"
            size={iconSize}
            fontSize={iconSizePx}
          />
          <div className={styles.eventDetails}>
            <span
              className={styles.title}
              onClick={handleClickEvent}
              onKeyDown={handleClickEvent}
            >
              {event.title}
            </span>
            <span
              className={clsx(styles.duration, {
                [styles.durationCrossed]: isEventUpdated
              })}
            >
              {startFormatted} - {endFormatted}
            </span>
          </div>
        </div>
        <Icon
          name="phone_forwarded"
          fontSize="24px"
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          onClick={handleClickEvent}
        />
      </div>
      {isEventUpdated && (
        <div
          className={clsx(styles.eventUpdated, {
            [styles.cancelled]: isCancelled,
            [styles.rescheduled]: isRescheduled
          })}
        >
          {t(eventUpdatedDescription[event.status])}
        </div>
      )}
    </>
  );
};
