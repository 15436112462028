import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { getRandomAngleColor } from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectAnglesSelector } from "@arbolus-technologies/stores/project-angles-store";

import { AngleDetailsForm } from "./AngleDetailsForm/AngleDetailsForm";
import {
  AngleDetailsSchema,
  CreateAngleDetailsSchema
} from "./AngleDetailsForm/AngleDetailsSchema";
import {
  ANGLE_COLOR,
  ANGLE_TITLE,
  AnglesDetailsBase,
  EXPERT_COUNT,
  EXPERT_DESCRIPTION,
  INCLUDE_SCREENING_QUESTION,
  OWNER,
  QUESTIONS
} from "./AngleDetailsForm/types";

const createModeDefaultValue = {
  [INCLUDE_SCREENING_QUESTION]: false,
  [QUESTIONS]: []
};

interface AngleDetailsProps {
  isAdmin?: boolean;
  isEdit?: boolean;
  projectId: string;
}

export const AngleDetails: React.FC<AngleDetailsProps> = ({
  isAdmin,
  isEdit = false,
  projectId
}) => {
  const angleDetails = useSelector(ProjectAnglesSelector.selectedAngle());
  const clientIdByAdmin = useSelector(ProjectAnglesSelector.clientIdByAdmin());
  const loggedInUserClientId = useSelector(
    CacheSelector.loggedInUserClientId()
  );

  const defaultValues = {
    [ANGLE_TITLE]: isEdit ? angleDetails?.title : "",
    [ANGLE_COLOR]: isEdit ? angleDetails?.color! : getRandomAngleColor(),
    [OWNER]: isEdit ? angleDetails.owner : undefined,
    [EXPERT_DESCRIPTION]: isEdit ? angleDetails?.expertDescription : "",
    [EXPERT_COUNT]: isEdit ? angleDetails?.expertCount : 0,
    ...(!isEdit && createModeDefaultValue)
  };

  const methods = useForm<AnglesDetailsBase>({
    resolver: yupResolver(
      isEdit ? AngleDetailsSchema : CreateAngleDetailsSchema
    ),
    defaultValues
  });

  return (
    <FormProvider {...methods}>
      <AngleDetailsForm
        clientId={isAdmin ? clientIdByAdmin : loggedInUserClientId}
        isEdit={isEdit}
        isAdmin={isAdmin}
        projectId={projectId}
      />
    </FormProvider>
  );
};
