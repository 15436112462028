import { Col, Flex, Form, Input, Row, Typography } from "antd";
import { LabeledValue } from "antd/es/select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AntDIcon,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  Company,
  CompanyService,
  DefaultToasterService,
  ErrorResponse,
  LIST_COMPANIES_ORDER_BY,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import { INPUT_DEBOUNCE } from "@arbolus-technologies/ui/components";
import { SEARCH_DEBOUNCE_TIMEOUT_COMMON } from "@arbolus-technologies/utils";

import { CompanySelector } from "../../Selectors/CompanySelector/CompanySelector";

const { Text } = Typography;

interface AddTargetCompany {
  disabled?: boolean;
  companyService?: typeof CompanyService;
  notificationService?: ToasterService;
}

export const AddTargetCompany: React.FC<AddTargetCompany> = ({
  disabled = false,
  companyService = CompanyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("addTargetCompany");
  const form = Form.useFormInstance();
  const { targetCompany, companyWebsite = "" } = Form.useWatch([], form) ?? {};

  const [companyFound, setCompanyFound] = useState<boolean | null>(null);

  const companyInputDisabled =
    companyWebsite.length === 0 || companyFound === true;

  const fetchTargetCompany = () => {
    if (companyWebsite.length === 0) {
      return;
    }

    companyService
      .listCompanies({
        website: companyWebsite,
        offset: 0,
        limit: 10,
        sort: [
          {
            orderBy: LIST_COMPANIES_ORDER_BY.Name,
            orderDirection: SORT_DIRECTION.ASCENDING
          }
        ]
      })
      .subscribe(
        ({ items }) => {
          if (items.length > 0) {
            setCompanyFound(true);

            const company = items[0];
            form.setFieldsValue({ ["targetCompany"]: company });
            form.setFieldsValue({
              ["targetCompany"]: company
            });
          } else {
            form.setFieldsValue({ ["targetCompany"]: null });
            setCompanyFound(false);
          }
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchTargetCompany();
    }, SEARCH_DEBOUNCE_TIMEOUT_COMMON);

    return () => clearTimeout(timeout);
  }, [companyWebsite]);

  const handleCompanySelected = (
    value: AntDSelectValueType,
    companies: Company[]
  ) => {
    if (value) {
      const company = value as LabeledValue;

      const selectedCompany = companies.find(
        (item) => item.id === company.value
      )!;
      form.setFieldsValue({
        ["targetCompany"]: {
          id: selectedCompany.id,
          website: selectedCompany.website,
          name: selectedCompany.name
        }
      });
      setCompanyFound(true);
    } else {
      clearCompany();
    }
  };

  const clearCompany = () => {
    form.setFieldsValue({ ["targetCompany"]: null });
    form.setFieldsValue({ ["companyWebsite"]: "" });
    setCompanyFound(null);
  };

  const handleAddCompany = (name: string) => {
    companyService.createCompany({ name, website: companyWebsite }).subscribe(
      (response) => {
        form.setFieldsValue({
          ["targetCompany"]: {
            id: response.id,
            website: companyWebsite,
            name: name
          }
        });
        setCompanyFound(true);
        notificationService.showSuccess(t("companyAddedSuccessfully"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          label={t("targetCompanyUrl")}
          name="companyWebsite"
          validateDebounce={INPUT_DEBOUNCE}
        >
          <Input
            placeholder={t("pasteCompanyUrl")}
            disabled={!!companyFound || disabled}
            prefix={<AntDIcon name="link" />}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Flex vertical>
          <Form.Item
            label={t("targetCompany")}
            name="targetCompany"
            validateDebounce={INPUT_DEBOUNCE}
          >
            <CompanySelector
              disabled={companyWebsite.length === 0 || disabled}
              onChange={handleCompanySelected}
              initialValue={
                targetCompany
                  ? {
                      label: targetCompany.name,
                      value: targetCompany.id
                    }
                  : null
              }
              allowClear
              open={companyInputDisabled ? false : undefined}
              addNewCompany={{
                placeholder: t("companyName"),
                buttonName: t("addCompany"),
                onAddItem: handleAddCompany
              }}
              allowTyping={!targetCompany}
            />
          </Form.Item>
          {companyWebsite && companyFound === false && (
            <Text type="secondary">{t("companyNotFoundMessage")}</Text>
          )}
        </Flex>
      </Col>
    </Row>
  );
};
