import { Button, Col, Form, Input, Row, Select } from "antd";
import { RcFile } from "antd/es/upload/interface";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";

import { UploadProfileImage } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  DocumentService,
  ErrorResponse,
  ToasterService,
  USERS_API
} from "@arbolus-technologies/api";
import { EXPERT_EXPERIENCE } from "@arbolus-technologies/models/expert";
import {
  INPUT_DEBOUNCE,
  LINKEDIN_URL_REGEX,
  TiptapEditorMemoized
} from "@arbolus-technologies/ui/components";
import { convertValueToCurrencyFormat } from "@arbolus-technologies/utils";

import { ExpertProfileFormDetails } from "../../helpers/types";

import styles from "./Details.module.scss";

const OVERVIEW_MAX_LENGTH = 8000;
const ROW_GUTTER = 24;
const requiredRule = { required: true };

interface DetailsFormProps {
  expert: ExpertProfileFormDetails;
  userId: string;
  updateExpertDetails: (expert: ExpertProfileFormDetails) => void;
  notificationService?: ToasterService;
  documentService?: typeof DocumentService;
}
export const DetailsForm: React.FC<DetailsFormProps> = ({
  expert,
  userId,
  updateExpertDetails,
  notificationService = DefaultToasterService,
  documentService = DocumentService
}) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const [form] = Form.useForm();
  const { t } = useTranslation("expertDetailSection");
  const { hourlyRate, isoCurrencyCode } = expert;

  const uploadSelectedImage = (file: RcFile) => {
    const formData = new FormData();
    formData.append("file", file);

    documentService
      .uploadFile(USERS_API.ADD_USER_PROFILE_IMAGE(userId), formData)
      .subscribe(
        () => {
          setImageUrl(URL.createObjectURL(file));
          notificationService.showSuccess(t("updateExpertProfileImageSuccess"));
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={expert}
      onFinish={updateExpertDetails}
    >
      <UploadProfileImage
        expert={{
          firstName: expert.firstName,
          lastName: expert.lastName,
          profileImageUrl: expert.profileImageUrl
        }}
        handleUploadSelectedImage={uploadSelectedImage}
        defaultImageUrl={imageUrl}
      />

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label={t("name")}
            name="firstName"
            rules={[requiredRule]}
            validateDebounce={INPUT_DEBOUNCE}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            validateDebounce={INPUT_DEBOUNCE}
            label={t("surName")}
            name="lastName"
            rules={[requiredRule]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label={t("email")}
            name="email"
            rules={[requiredRule]}
            validateDebounce={INPUT_DEBOUNCE}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label={t("phoneNumber")}
            name="phoneNumber"
            validateDebounce={INPUT_DEBOUNCE}
            className={styles.phoneInput}
          >
            <PhoneInput
              inputProps={{
                name: "phoneNumber"
              }}
              onChange={(value: string) =>
                form.setFieldValue("phoneNumber", value ?? "")
              }
              enableSearch
              country="us"
              disableSearchIcon
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        validateDebounce={INPUT_DEBOUNCE}
        label={t("linkedinUrl")}
        name="linkedinProfile"
        rules={[
          {
            type: "url",
            pattern: LINKEDIN_URL_REGEX,
            message: t("invalidLinkedinUrl")
          }
        ]}
      >
        <Input placeholder={t("linkedinPlaceholder")} />
      </Form.Item>
      <Form.Item
        label={t("headline")}
        name="title"
        validateDebounce={INPUT_DEBOUNCE}
      >
        <Input />
      </Form.Item>
      <Form.Item
        validateDebounce={INPUT_DEBOUNCE}
        label={t("experience")}
        name="overview"
        rules={[
          {
            max: OVERVIEW_MAX_LENGTH,
            message: t("experienceLimitError", {
              length: OVERVIEW_MAX_LENGTH
            })
          }
        ]}
      >
        <TiptapEditorMemoized
          placeholder={t("experiencePlaceholder")}
          onChange={(content: string): void => {
            form.setFieldsValue({ overview: content });
          }}
          hasError={false}
          initialEditorState={expert.overview ?? ""}
        />
      </Form.Item>

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            validateDebounce={INPUT_DEBOUNCE}
            label={t("seniority")}
            name="experienceLevel"
            rules={[requiredRule]}
          >
            <Select
              options={Array.from(EXPERT_EXPERIENCE.values())}
              placement="bottomLeft"
              className={styles.seniority}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          {isoCurrencyCode && hourlyRate && (
            <Form.Item
              label={t("hourlyRate")}
              name="hourlyRate"
              validateDebounce={INPUT_DEBOUNCE}
            >
              <Input
                type="number"
                value={convertValueToCurrencyFormat(
                  hourlyRate,
                  isoCurrencyCode
                )}
                disabled
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Button type="primary" htmlType="submit">
        {t("saveChanges")}
      </Button>
    </Form>
  );
};
