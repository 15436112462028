import { Card, Flex, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import { ReferralNote } from "@arbolus-technologies/api";
import { CustomerCompanyInfo } from "@arbolus-technologies/models/project";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

const { Text } = Typography;

interface ClientProjectNotesProps {
  note?: ReferralNote;
  customers: CustomerCompanyInfo[];
}

export const ClientProjectNotes: React.FC<ClientProjectNotesProps> = ({
  note,
  customers
}) => {
  const { t } = useTranslation("referralProjectNotes");

  return (
    <Card>
      <Flex vertical gap={16}>
        <Text strong>{t("projectNote")}</Text>
        {note?.note && <DangerouslySetInnerHTML text={note?.note} />}
        {customers.length > 0 && (
          <Flex align="center" wrap style={{ rowGap: 8 }}>
            <Text strong style={{ marginRight: "8px" }}>
              {t("customerOf")}
            </Text>
            {customers.map((company) => (
              <Tag key={company.companyId}>{company.companyName}</Tag>
            ))}
          </Flex>
        )}
        {note?.noteUser && (
          <Flex gap={16} justify="space-between">
            <Text code>{moment(note.noteDate).format(APP_DATE_FORMAT)}</Text>
            <AntDAvatar
              profileImageUrl={note.noteUser.profileImageUrl}
              firstName={note.noteUser.firstName}
              lastName={note.noteUser.lastName}
              size="small"
            />
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
