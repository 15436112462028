import { Card, Col, Divider, Modal, Row } from "antd";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDAvatar, IconName } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  PROJECT_REFERRAL_STATE,
  ProjectService,
  ReferralDetail,
  ToasterService,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectExpertsSelector } from "@arbolus-technologies/stores/project-experts-store";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { BainConfirmation } from "../../../../../Components/BainConfirmation/BainConfirmation";
import ProjectExpertEvents from "../../constants/ProjectExpertEvents";
import { HeaderActions } from "./HeaderActions";
import { Statuses } from "./Statuses";

const BAIN_ID = process.env.NX_PUBLIC_BAIN_ID;

const getExpertStatusIconInfo = (referralStatus: PROJECT_REFERRAL_STATE) => {
  const isShortlist = referralStatus === PROJECT_REFERRAL_STATE.SHORTLIST;
  const isCandidate = referralStatus === PROJECT_REFERRAL_STATE.CANDIDATE;
  const isAccepted = referralStatus === PROJECT_REFERRAL_STATE.ACCEPT;

  if (isShortlist) {
    return {
      name: "circle" as IconName,
      fontSize: "20px",
      backgroundColor: ARBOLUS_COLORS.bColorWarning,
      tooltip: i18next.t("referralsTableExpertInfo:shortlist")
    };
  }
  if (isCandidate) {
    return {
      name: "equal" as IconName,
      fontSize: "20px",
      backgroundColor: ARBOLUS_COLORS.candidateIconBackground,
      tooltip: i18next.t("referralsTableExpertInfo:candidate")
    };
  }
  if (isAccepted) {
    return {
      name: "check" as IconName,
      fontSize: "20px",
      backgroundColor: ARBOLUS_COLORS.bColorSuccess,
      tooltip: i18next.t("referralsTableExpertInfo:accepted")
    };
  }
  return {
    name: "close" as IconName,
    fontSize: "20px",
    backgroundColor: ARBOLUS_COLORS.bColorError,
    tooltip: i18next.t("referralsTableExpertInfo:rejected")
  };
};

interface ExpertInfoProps {
  expert: ExpertDetail;
  referral: ReferralDetail;
  isVerified: boolean;
  projectId: string;
  onEditNote: () => void;
  onReferralRemoval?: (referralId: string) => void;
  onRejectExpert: () => void;
  onMoveToAvailabilityTab: () => void;
  onMoveToNextExpert?: () => void;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ExpertInfo: React.FC<ExpertInfoProps> = ({
  expert,
  referral,
  isVerified,
  projectId,
  onEditNote,
  onReferralRemoval,
  onRejectExpert,
  onMoveToAvailabilityTab,
  onMoveToNextExpert,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralsTableExpertInfo");

  const [isCandidate, setIsCandidate] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showBainModalOpen, setShowBainModalOpen] = useState(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const loggedInUserClientId = useSelector(
    CacheSelector.loggedInUserClientId()
  );
  const expertCurrentCompanies = useSelector(
    ProjectExpertsSelector.expertCurrentCompanies()
  );
  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const expertCountry = metadataCountries.find(
    (country) => country.threeLetterCode === expert.user.isoCountryCode
  );

  const isBain = loggedInUserClientId === BAIN_ID;

  useEffect(() => {
    setIsCandidate(referral.status === PROJECT_REFERRAL_STATE.CANDIDATE);
    setIsApproved(referral.review.subStatus === REFERRAL_SUB_STATE.ACCEPT);
  }, [referral]);

  const handleUpdateReferralState = (
    state: UpdateReferralRequest,
    isFastTrack?: boolean
  ): void => {
    setIsApproving(true);
    projectService.updateReferralState(projectId, referral.id, state).subscribe(
      (response) => {
        if (isAdmin) {
          if (isFastTrack) {
            ProjectExpertEvents.fastTrackReferral(referral.id, response);
          } else {
            ProjectExpertEvents.updateReferral(referral.id, response);
          }
        } else {
          onMoveToNextExpert?.();
        }

        if (state.review === REFERRAL_SUB_STATE.ACCEPT) {
          setIsCandidate(false);
        }

        setIsApproving(false);
        setIsApproved(true);
        setShowApproveModal(false);
        notificationService.showSuccess(t("updateSuccess"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsApproving(false);
      }
    );
  };

  const handleApproveExpert = () => {
    if (isBain) {
      setShowBainModalOpen(true);
    } else {
      setShowApproveModal(true);
    }
  };

  return (
    <>
      <Card>
        <Row gutter={24}>
          <Col span={18}>
            <AntDAvatar
              firstName={expert.user.firstName}
              lastName={expert.user.lastName}
              profileImageUrl={expert.user.profileImageUrl}
              size={64}
              title={referral.tagline ?? expert.user.title}
              isVerified={isVerified}
              lastPublicCompanyExpDate={expert.lastPublicCompanyExpDate}
              linkedInProfile={expert.linkedinProfile}
              countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
              countryName={expertCountry?.name ?? ""}
              angle={referral.angle}
              icon={getExpertStatusIconInfo(referral.status)}
              mainGap={16}
            />
          </Col>
          <Col span={6}>
            <HeaderActions
              expert={expert}
              referral={referral}
              isCandidate={isCandidate}
              onEditNote={onEditNote}
              onRejectExpert={onRejectExpert}
              onApproveExpert={handleApproveExpert}
              onReferralRemoval={onReferralRemoval}
              onUpdateReferralState={handleUpdateReferralState}
              onMoveToAvailabilityTab={onMoveToAvailabilityTab}
            />
          </Col>
        </Row>
        <Divider />
        <Statuses
          referral={referral}
          isDnc={expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC}
          isCandidate={isCandidate}
          isApproved={isApproved}
          onRejectExpert={onRejectExpert}
          onApproveExpert={handleApproveExpert}
        />
      </Card>
      <Modal
        open={showApproveModal}
        closable={false}
        title={t("approveExpertTitle")}
        okText={t("approveExpertConfirmText")}
        okButtonProps={{
          onClick: () =>
            handleUpdateReferralState({ review: REFERRAL_SUB_STATE.ACCEPT }),
          disabled: isApproving
        }}
        cancelText={t("cancel")}
        cancelButtonProps={{
          onClick: () => setShowApproveModal((prev) => !prev),
          disabled: isApproving
        }}
      >
        {t("approveExpertSubtitle")}
      </Modal>
      <Modal
        title={t("companiesCID")}
        open={showBainModalOpen}
        cancelText={t("reviewLater")}
        onCancel={() => setShowBainModalOpen(false)}
        okText={t("passCID")}
        okType="primary"
        okButtonProps={{
          onClick: () =>
            handleUpdateReferralState({ review: REFERRAL_SUB_STATE.ACCEPT }),
          disabled: isApproving
        }}
        cancelButtonProps={{
          onClick: () => setShowBainModalOpen((prev) => !prev),
          disabled: isApproving
        }}
      >
        <BainConfirmation
          expertId={expert.id}
          expertCurrentCompanies={expertCurrentCompanies}
        />
      </Modal>
    </>
  );
};
