import {
  CANOPY_DETAILS,
  CANOPY_EXPERTS,
  CANOPY_INDIVIDUAL_RESPONSE,
  CANOPY_MOBILE_MENU,
  CANOPY_QUESTIONS,
  CANOPY_QUESTION_PAGE_MULTI,
  CANOPY_QUESTION_PAGE_NPS,
  CANOPY_QUESTION_PAGE_SHORT_TEXT,
  CANOPY_QUESTION_PAGE_VALUE,
  CANOPY_QUESTION_PAGE_VIDEO,
  CANOPY_SINGLE_EXPERT,
  CANOPY_SINGLE_QUESTION,
  CANOPY_SUMMARY,
  CLIENT_ACTIVE_CANOPY_EXPERTS_V2_ROUTES,
  CLIENT_ACTIVE_CANOPY_QUESTIONS_V2_ROUTES,
  EXPERT_CANOPY
} from "@arbolus-technologies/routes";

// Canopy
export const CANOPY_MAIN_PAGE = "/canopy/:canopyId";
export const CANOPY_PAUSED = "/canopy/paused";
export const CANOPY_APPLICATION_PAGE_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/application`;
export const CANOPY_APPLICATION_PAGE = "/canopy/:canopyId/application";
export const CANOPY_DETAILS_PAGE_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/details`;
export const CANOPY_MAIN_PAGE_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}`;

export const CANOPY_QUESTION_PAGE = "/canopy/:canopyId/question/:questionId";

export const CANOPY_QUESTION_ROUTE = (
  canopyId: string,
  questionId: string
): string => `/canopy/${canopyId}/question/${questionId}`;

export const CANOPY_EXPERT_PAGE = "/canopy/:canopyId/expert/:expertId";
export const CANOPY_TRANSCRIPT_PAGE =
  "/canopy/:canopyId/question/:questionId/transcript/:transcriptId";
export const CANOPY_SEND_ANSWERS = "/canopy/:canopyId/send-answers";
export const CANOPY_LIST = "/canopies";

export const EXPERT_CANOPY_ROUTES = [
  CANOPY_SEND_ANSWERS,
  EXPERT_CANOPY,
  CANOPY_QUESTION_PAGE_VIDEO,
  CANOPY_QUESTION_PAGE_MULTI,
  CANOPY_QUESTION_PAGE_VALUE,
  CANOPY_QUESTION_PAGE_SHORT_TEXT,
  CANOPY_QUESTION_PAGE_NPS,
  CANOPY_PAUSED,
  CANOPY_APPLICATION_PAGE
];

export const CLIENT_CANOPY_ROUTES = [
  CANOPY_MAIN_PAGE,
  CANOPY_EXPERT_PAGE,
  CANOPY_TRANSCRIPT_PAGE,
  CANOPY_SUMMARY,
  CANOPY_LIST
];

export const CLIENT_CANOPY_V2_ROUTES = [
  CANOPY_QUESTIONS,
  CANOPY_EXPERTS,
  CANOPY_MOBILE_MENU,
  CANOPY_SINGLE_QUESTION,
  CANOPY_SINGLE_EXPERT,
  CANOPY_INDIVIDUAL_RESPONSE,
  CANOPY_LIST
];

export const CLIENT_ACTIVE_CANOPY_V2_ROUTES = [
  CANOPY_DETAILS,
  CANOPY_SUMMARY,
  ...CLIENT_ACTIVE_CANOPY_QUESTIONS_V2_ROUTES,
  ...CLIENT_ACTIVE_CANOPY_EXPERTS_V2_ROUTES,
  CANOPY_INDIVIDUAL_RESPONSE
];

export const CANOPY_COMMON_ROUTES = [CANOPY_DETAILS, CANOPY_QUESTION_PAGE];
