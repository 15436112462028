import { AdoptionStatus } from "@arbolus-technologies/models/common";
import { ICellRendererParams } from "ag-grid-community";
import { Tag, Typography } from "antd";
import styles from "./AdoptionRenderer.module.scss";

export interface AdoptionProps
  extends Partial<ICellRendererParams<{ adoption: AdoptionStatus }>> {}

export function AdoptionRenderer({ data }: AdoptionProps) {
  if (!data?.adoption) {
    return <Typography.Text type="secondary">-</Typography.Text>;
  }

  return (
    <Tag className={styles.tag} color={getAdoptionColor(data.adoption)}>
      {data.adoption}
    </Tag>
  );
}

function getAdoptionColor(adoption: AdoptionStatus) {
  switch (adoption) {
    case AdoptionStatus.Pilot:
      return "yellow";
    case AdoptionStatus.User:
      return "green";
    case AdoptionStatus.OffBoarding:
      return "red";
  }
}
