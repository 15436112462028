import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { WorkHistory } from "@arbolus-technologies/models/expert";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";

interface BainConfirmationProps {
  expertId: string;
  expertCurrentCompanies?: WorkHistory[];
}

export const BainConfirmation: React.FC<BainConfirmationProps> = ({
  expertId,
  expertCurrentCompanies
}) => {
  const { t } = useTranslation("expertsList");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProjectExpertsStoreActions.getAngleExpert(expertId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertId]);

  const renderCompanyNames = () => {
    if (expertCurrentCompanies && expertCurrentCompanies?.length > 0) {
      return expertCurrentCompanies
        .map((workHistoryItem) => workHistoryItem.company?.name)
        .filter(Boolean)
        .join(", ");
    }
    return t("expertNotEmployedCurrently");
  };

  return <div>{renderCompanyNames()}</div>;
};
