import { useFeature } from "flagged";
import React from "react";
import { useLocation } from "react-router";

import { CustomerSurveyExpertProfileSidePanel } from "@arbolus-technologies/features/common";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { CUSTOMERS_BASE } from "@arbolus-technologies/routes";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { CustomerSurveyAnswerTable } from "../../modules/CustomerSurveyAnswerTable/CustomerSurveyAnswerTable";

interface LocationState {
  surveyId: string;
  surveyTitle: string;
}

export const CustomerSurveysAnswerPage: React.FC = () => {
  const location = useLocation<LocationState>();
  const { surveyId, surveyTitle } = location.state || {};

  useDocumentTitle("customerSurveyAnswerTable", "pageTitle");

  const newReferralTableSidePanel = useFeature(
    FEATURE_FLAGS.NewReferralTableSidePanel
  );

  return (
    <MainPageLayout title={surveyTitle} stickyHeader backLink={CUSTOMERS_BASE}>
      <CustomerSurveyAnswerTable surveyId={surveyId} />
      {newReferralTableSidePanel && <CustomerSurveyExpertProfileSidePanel />}
    </MainPageLayout>
  );
};
