import { Alert, Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultToasterService } from "@arbolus-technologies/api";
import { INPUT_DEBOUNCE } from "@arbolus-technologies/ui/components";

import {
  NewPasswordField,
  PASSWORD_REQUIREMENTS
} from "./NewPasswordField/NewPasswordField";
import { useChangePassword } from "./useChangePassword";

import styles from "./SecuritySettings.module.scss";

const { Title } = Typography;

type SecuritySettingsForm = {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
};

export function SecuritySettings({
  isEditPasswordDisabled
}: { isEditPasswordDisabled: boolean }) {
  const { t } = useTranslation("newExpertProfileEditPage");
  const [formValid, setFormValid] = useState(false);
  const [form] = Form.useForm();
  const { changePassword, inProgress } = useChangePassword();

  const handleFinish = async (values: SecuritySettingsForm) => {
    try {
      await changePassword(values.oldPassword!, values.newPassword!);
      DefaultToasterService.showSuccess(
        t("newExpertProfileEditPage:passwordApiSuccess")
      );
    } catch (apiError) {
      // Error code managed by BE
      if (apiError.status === "1133") {
        form.setFields([
          {
            name: "oldPassword",
            errors: [apiError.message]
          }
        ]);
      } else {
        DefaultToasterService.showError(
          apiError?.message ?? t("newExpertProfileEditPage:passwordApiError")
        );
      }
    }
  };

  return (
    <Card>
      {isEditPasswordDisabled && (
        <Alert
          message={t("passwordDisabledAlert")}
          type="warning"
          showIcon
          className={styles.passwordDisabledAlert}
        />
      )}

      <Title level={5}>{t("securityPageHeader")}</Title>
      <p>{t("securityPageDescription")}</p>

      <Form
        form={form}
        layout="vertical"
        onFieldsChange={(_, allFields) => {
          setFormValid(
            allFields.every(
              (field) => field.errors?.length === 0 && field.touched
            )
          );
        }}
        onFinish={handleFinish}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item<SecuritySettingsForm>
              validateDebounce={INPUT_DEBOUNCE}
              label={t("oldPassword")}
              name="oldPassword"
              htmlFor="oldPassword"
              rules={[
                { required: true, message: t("missingPasswordMessage") },
                { min: 6, message: t("missingPasswordMessage") }
              ]}
            >
              <Input.Password
                name="oldPassword"
                placeholder={t("oldPasswordPlaceholder")}
                disabled={isEditPasswordDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item<SecuritySettingsForm>
          validateDebounce={INPUT_DEBOUNCE}
          label={t("newPassword")}
          name="newPassword"
          htmlFor="newPassword"
          rules={[
            { required: true, message: PASSWORD_REQUIREMENTS.MIN_8_CHARS },
            { min: 8, message: PASSWORD_REQUIREMENTS.MIN_8_CHARS },
            {
              validator: (_, value: string) => {
                if (value.match(/\d/)) {
                  return Promise.resolve();
                }
                return Promise.reject(PASSWORD_REQUIREMENTS.INCLUDE_DIGIT);
              }
            },
            {
              validator: (_, value: string) => {
                if (value.match(/[!@#$%^&*(),.?":{}|<>]/)) {
                  return Promise.resolve();
                }
                return Promise.reject(PASSWORD_REQUIREMENTS.SPECIAL_CHAR);
              }
            },
            {
              validator: (_, value: string) => {
                if (value.match(/[a-z]/) && value.match(/[A-Z]/)) {
                  return Promise.resolve();
                }
                return Promise.reject(PASSWORD_REQUIREMENTS.MIX_LOWER_UPPER);
              }
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue("oldPassword") === value) {
                  return Promise.reject(t("passwordReused"));
                }
                return Promise.resolve();
              }
            })
          ]}
          className={styles.newPassword}
        >
          {/* value and onChange props automatically supplied by antd */}
          {/* @ts-ignore */}
          <NewPasswordField disabled={isEditPasswordDisabled} />
        </Form.Item>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item<SecuritySettingsForm>
              validateDebounce={INPUT_DEBOUNCE}
              label={t("confirmPassword")}
              name="confirmNewPassword"
              htmlFor="confirmNewPassword"
              rules={[
                { required: true, message: t("missingPasswordMessage") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("newPassword") !== value) {
                      return Promise.reject(t("passwordsNotMatch"));
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input.Password
                name="confirmNewPassword"
                placeholder={t("confirmPasswordPlaceholder")}
                disabled={isEditPasswordDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={inProgress || !formValid || isEditPasswordDisabled}
          >
            {t("securityPageSaveButton")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
