import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AngleModel } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Checkbox } from "@arbolus-technologies/ui/components";

import { MoveToShortlistButton } from "./MoveToShortlistButton";

import styles from "./ResultsAndActions.module.scss";

interface ResultsAndActionsProps {
  totalExpertsAvailable: number;
  totalExpertsFound: number;
  selectedExpertsIds: string[];
  selectedCautionExperts: { id: string; name: string }[];
  onShortlistConfirmed: (selectedExpertsIds: string[]) => void;
  handleToggleSelectAllExperts: () => void;
  areAllExpertsSelected: boolean;
  handleSetIsConfirmReferralsLoading: (isLoading: boolean) => void;
  isConfirmReferralsLoading: boolean;
  angles: AngleModel[];
}

export const ResultsAndActions: React.FC<ResultsAndActionsProps> = ({
  totalExpertsAvailable,
  totalExpertsFound,
  selectedExpertsIds,
  selectedCautionExperts,
  onShortlistConfirmed,
  handleToggleSelectAllExperts,
  areAllExpertsSelected,
  handleSetIsConfirmReferralsLoading,
  isConfirmReferralsLoading,
  angles
}) => {
  const { t } = useTranslation("discoverPage");
  const isClient = useSelector(CacheSelector.isCurrentUserClientSelector());

  const areActionsDisabled = selectedExpertsIds.length === 0;
  const searchEngineLimit = 10000;

  const displayedExpertsNumber =
    totalExpertsFound >= searchEngineLimit
      ? `+${searchEngineLimit}`
      : totalExpertsFound;

  const getExpertsCountText = (): string => {
    if (selectedExpertsIds.length > 0) {
      return t("selectedExperts", { count: selectedExpertsIds.length });
    }
    if (totalExpertsFound > 0) {
      return t("totalExpertsFound", { count: displayedExpertsNumber });
    }
    return t("totalExpertsAvailable", { count: totalExpertsAvailable });
  };

  return (
    <div className={styles.container}>
      <Checkbox
        isChecked={areAllExpertsSelected}
        onChange={handleToggleSelectAllExperts}
        isDisabled={totalExpertsFound === 0 || isClient}
        text={getExpertsCountText()}
      />
      <div className={styles.buttonsContainer}>
        {!isClient && (
          <MoveToShortlistButton
            disabled={areActionsDisabled}
            selectedExpertsIds={selectedExpertsIds}
            selectedCautionExperts={selectedCautionExperts}
            onShortlistConfirmed={onShortlistConfirmed}
            handleSetIsConfirmReferralsLoading={
              handleSetIsConfirmReferralsLoading
            }
            isConfirmReferralsLoading={isConfirmReferralsLoading}
            angles={angles}
          />
        )}
      </div>
    </div>
  );
};
