import {
  Alert,
  Button,
  Flex,
  Popconfirm,
  Skeleton,
  Tag,
  Typography
} from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  AntDDownloadButton,
  AntDIcon
} from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  DocumentService,
  ErrorResponse,
  EventService,
  GetEventResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DateString,
  SelectOption,
  UserRole
} from "@arbolus-technologies/models/common";
import { PROJECT_EDIT_EVENT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  CALENDAR_DATE_FORMAT,
  CALENDAR_TIME_FORMAT,
  utilService
} from "@arbolus-technologies/utils";

import { useUserProfile } from "../../Hooks/useUserProfile";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Title, Text } = Typography;

const convertDates = (
  startTime: DateString,
  endTime: DateString,
  timezone?: string | null
) => {
  const start = timezone ? dayjs(startTime).tz(timezone) : dayjs(startTime);
  const end = timezone ? dayjs(endTime).tz(timezone) : dayjs(endTime);

  const date = start.format(CALENDAR_DATE_FORMAT);
  const hours = `${start.format(CALENDAR_TIME_FORMAT)} - ${end.format(CALENDAR_TIME_FORMAT)}`;

  return { date, hours };
};

interface EventDetailsProps {
  eventId: string;
  projectId: string;
  eventData?: GetEventResponse;
  timezones?: Map<string, SelectOption>;
  preselectedTimezoneValue?: string;
  onClosePanel?: () => void;
  eventService?: typeof EventService;
  documentService?: typeof DocumentService;
  notificationService?: ToasterService;
}

export const EventDetails: React.FC<EventDetailsProps> = ({
  eventId,
  projectId,
  eventData,
  timezones,
  preselectedTimezoneValue,
  onClosePanel,
  eventService = EventService,
  documentService = DocumentService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("eventDetails");
  const history = useHistory();
  const location = useLocation<{ isSchedulerScreen?: boolean }>();

  const projectTimezone =
    preselectedTimezoneValue && timezones
      ? timezones.get(preselectedTimezoneValue)
      : null;

  const [event, setEvent] = useState<GetEventResponse | undefined>(eventData);
  const [isLoading, setIsLoading] = useState(!eventData);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const userProfile = useUserProfile();
  const userRole = useSelector(CacheSelector.generateActiveUserRole());

  useEffect(() => {
    if (!eventData && eventId) {
      getEvent();
    }
  }, [eventData, eventId]);

  const getEvent = () => {
    setIsLoading(true);
    eventService.getEvent(projectId, eventId).subscribe(
      (eventResponse) => {
        setEvent(eventResponse as any);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsLoading(false);
        onClosePanel?.();
      }
    );
  };

  if (isLoading) {
    return <Skeleton paragraph />;
  }

  if (!event) {
    onClosePanel?.();
    return null;
  }

  const handleDeleteEvent = (): void => {
    eventService.deleteEvent(projectId, event.id).subscribe(
      ({ deleted }) => {
        if (deleted) {
          notificationService.showSuccess(t("deleteSuccess"));
          onClosePanel?.();
        }
      },
      (error: ErrorResponse<CIQError>) =>
        notificationService.showError(error.message)
    );
  };

  const handleEditEvent = (): void => {
    history.push(PROJECT_EDIT_EVENT_ROUTE(projectId, event.id));
    onClosePanel?.();
  };

  const handleJoinCall = (): void => {
    if (event.meetingJoinUrl) {
      window.open(event.meetingJoinUrl, "_blank");
    }
  };

  const handleDownloadDocument = (id: string, projectId: string): void => {
    documentService.downloadDocument(projectId, id).subscribe({
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    });
  };

  const dateConverted = convertDates(
    event.startTime,
    event.endTime,
    projectTimezone?.value ?? event.timezone?.id
  );
  const isEventPast = dayjs().isAfter(dayjs(event.endTime));
  const sortedGuestsList = (event.eventGuests ?? [])
    .slice()
    .sort((a, b) => (a?.userId === event.organizer.id ? -1 : 1));

  const { organizer, endTime, meetingJoinUrl } = event;
  const iamOrganizer = userProfile.id === organizer.id;
  const isEventPassed = utilService.isTimeBeforeLocalTime(new Date(endTime));
  const isEventControl = isAdmin || (iamOrganizer && !isEventPassed);
  const { isSchedulerScreen } = location.state || {};
  const isExpert = userRole === UserRole.expert;

  return (
    <Flex gap="16px" vertical>
      <Title level={4}>{event.title}</Title>
      <Flex gap={8} vertical style={{ marginBottom: "16px" }}>
        <Flex gap={8} align="center">
          <AntDIcon name="calendar_today" color={ARBOLUS_COLORS.colorIcon} />
          {dateConverted.date}
        </Flex>
        <Flex gap={8} align="center">
          <AntDIcon name="schedule" color={ARBOLUS_COLORS.colorIcon} />
          {dateConverted.hours}
        </Flex>
        <Flex gap={8} align="center">
          <AntDIcon name="schedule" color={ARBOLUS_COLORS.colorIcon} />
          <Text>{projectTimezone?.label ?? event.timezone?.displayText}</Text>
        </Flex>
      </Flex>
      {event.clientGateKeepingPending && (
        <Alert
          message={t("gatekeepingWarning")}
          showIcon
          type="warning"
          style={{ alignItems: "flex-start" }}
        />
      )}
      <Flex wrap gap={4} justify="space-between">
        {isEventControl && (
          <Popconfirm
            title={t("deleteEventMsg")}
            okText={t("delete")}
            cancelText={t("cancel")}
            onConfirm={handleDeleteEvent}
            placement="left"
          >
            <Button
              type="default"
              disabled={isEventPast}
              icon={<AntDIcon name="delete" />}
            />
          </Popconfirm>
        )}

        <Flex gap={8}>
          {!isSchedulerScreen && isEventControl && (
            <Button
              type="default"
              onClick={handleEditEvent}
              disabled={isEventPast}
            >
              {t("editEvent")}
            </Button>
          )}
          {meetingJoinUrl && (
            <Button
              type="primary"
              onClick={handleJoinCall}
              disabled={isEventPast}
            >
              {t("joinCall")}
            </Button>
          )}
        </Flex>
      </Flex>
      {!isExpert && (
        <>
          <Flex wrap gap={8} vertical>
            <Text strong>{t("guests")}</Text>
            <Flex gap={4} wrap>
              {sortedGuestsList.map((guest) => (
                <Tag
                  key={guest.email}
                  color={
                    guest?.userId === event.organizer.id ? "gold" : "default"
                  }
                >
                  {guest.user !== null
                    ? `${guest.user?.firstName} ${guest.user?.lastName}`
                    : t("deletedUser")}
                </Tag>
              ))}
            </Flex>
          </Flex>
          <Flex wrap gap={8} vertical>
            <Text strong>{t("transcript")}</Text>
            <Flex gap={2} vertical>
              {event.transcribe ? (
                <Flex align="center" gap={4}>
                  <AntDIcon
                    name="check"
                    color={ARBOLUS_COLORS.bColorAccentGreenDark}
                  />
                  {t("transcriptEnabled")}
                </Flex>
              ) : (
                <Flex align="center" gap={4}>
                  <AntDIcon name="close" />
                  {t("transcriptDisabled")}
                </Flex>
              )}
              {event.humanTranscribe ? (
                <Flex align="center" gap={4}>
                  <AntDIcon
                    name="check"
                    color={ARBOLUS_COLORS.bColorAccentGreenDark}
                  />
                  {t("smartTranscriptEnabled")}
                </Flex>
              ) : (
                <Flex align="center" gap={4}>
                  <AntDIcon name="close" />
                  {t("smartTranscriptDisabled")}
                </Flex>
              )}
            </Flex>
          </Flex>
        </>
      )}
      {event.notes && (
        <Flex wrap gap={8} vertical>
          <Text strong>{t("notes")}</Text>
          <Flex gap={2} wrap>
            {event.notes}
          </Flex>
        </Flex>
      )}
      {event.eventAttachments && event.eventAttachments.length > 0 && (
        <Flex wrap gap={8} vertical>
          <Text strong>{t("attachments")}</Text>
          <Flex gap={2} wrap>
            {event.eventAttachments.map((attachment) => (
              <AntDDownloadButton
                key={attachment.id}
                fileName={attachment.fileName}
                handleOnClick={() =>
                  handleDownloadDocument(attachment.id, projectId)
                }
              />
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
