export enum REFERRAL_EXPERT_TABS {
  PROFILE = "profile",
  SCREENING = "screening",
  COMPLIANCE = "compliance",
  SCHEDULING = "scheduling"
}

export type REFERRAL_EXPERT_TABS_TYPE = `${REFERRAL_EXPERT_TABS}`;

export const getReferralProfileTabs = (
  isApplicationDeclined: boolean,
  hasScreeningQuestions: boolean,
  hasCompliance: boolean
) => {
  const TABS = [REFERRAL_EXPERT_TABS.PROFILE];

  if (!isApplicationDeclined) {
    if (hasScreeningQuestions) {
      TABS.push(REFERRAL_EXPERT_TABS.SCREENING);
    }

    if (hasCompliance) {
      TABS.push(REFERRAL_EXPERT_TABS.COMPLIANCE);
    }

    TABS.push(REFERRAL_EXPERT_TABS.SCHEDULING);
  }

  return TABS;
};
