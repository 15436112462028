import { Collapse, CollapseProps } from "antd";

import { AntDIcon } from "../Icon/AntDIcon";

export const AntDCollapse: React.FC<CollapseProps> = ({
  defaultActiveKey,
  items
}) => {
  return (
    <Collapse
      defaultActiveKey={defaultActiveKey}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <AntDIcon name={`${isActive ? "arrowUp" : "arrowDown"}`} />
      )}
      items={items}
    />
  );
};
