import { ChurnRisk } from "@arbolus-technologies/models/common";
import { ICellRendererParams } from "ag-grid-community";
import { Tag, Typography } from "antd";

import styles from "./ChurnRiskRenderer.module.scss";

export interface ChurnRiskRendererProps
  extends Partial<ICellRendererParams<{ churnRisk: ChurnRisk }>> {}

export function ChurnRiskRenderer({ data }: ChurnRiskRendererProps) {
  if (!data?.churnRisk) {
    return <Typography.Text type="secondary">-</Typography.Text>;
  }

  return (
    <Tag className={styles.tag} color={getChurnRiskColor(data.churnRisk)}>
      {data.churnRisk}
    </Tag>
  );
}

function getChurnRiskColor(churnRisk: ChurnRisk) {
  switch (churnRisk) {
    case ChurnRisk.High:
      return "red";
    case ChurnRisk.Mid:
      return "orange";
    case ChurnRisk.Low:
      return "green";
    default:
      return "gray";
  }
}
