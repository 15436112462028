import { Flex, Result, Typography } from "antd";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import { DASHBOARD } from "@arbolus-technologies/routes";
import styles from "./SurveyAlreadyCompletedFeedback.module.scss";

const { Text } = Typography;

export const SurveyAlreadyCompletedFeedback = () => {
  const { t } = useTranslation("surveyAlreadyCompletedFeedback");

  return (
    <Flex justify="center" align="center" className={styles.container}>
      <Result
        title={t("surveyAlreadyCompleted")}
        status="warning"
        className={styles.result}
        subTitle={
          <Flex vertical>
            <Text type="secondary">{t("looksLikeAlreadyResponse")}</Text>
            <Text type="secondary">
              <Trans i18nKey="surveySupport">
                If you encountered any issue, please feel free to reach out at{" "}
                <a href="mailto:survey_support@arbolus.com">
                  survey_support@arbolus.com
                </a>
              </Trans>
            </Text>
          </Flex>
        }
        extra={
          <AntDButtonLink
            type="primary"
            pathname={DASHBOARD}
            text={t("reviewPaymentPreference")}
          />
        }
      />
    </Flex>
  );
};
