import { Subject } from "rxjs";

import { UpdateReferralResponse } from "@arbolus-technologies/api";

export enum ExpertReferralEventType {
  UPDATE,
  DELETE,
  FAST_TRACK,
  RESET_APPLICATION,
  ADD_NOTE,
  REMOVE_NOTE
}

export interface ExpertReferralEvent {
  eventType: ExpertReferralEventType;
  referralId: string;
  newStatus?: UpdateReferralResponse;
}

class ProjectExpertEvents {
  eventSubject: Subject<ExpertReferralEvent>;

  constructor() {
    this.eventSubject = new Subject();
  }

  deleteReferral(referralId: string): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.DELETE,
      referralId
    });
  }

  updateReferral(referralId: string, newStatus: UpdateReferralResponse): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.UPDATE,
      referralId,
      newStatus
    });
  }

  fastTrackReferral(
    referralId: string,
    newStatus: UpdateReferralResponse
  ): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.FAST_TRACK,
      referralId,
      newStatus
    });
  }

  resetApplication(
    referralId: string,
    newStatus: UpdateReferralResponse
  ): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.RESET_APPLICATION,
      referralId,
      newStatus
    });
  }

  addNote(referralId: string): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.ADD_NOTE,
      referralId
    });
  }

  removeNote(referralId: string): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.REMOVE_NOTE,
      referralId
    });
  }
}

export default new ProjectExpertEvents();
