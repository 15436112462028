import { Button } from "arbolus-ui-components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ReferralDetail } from "@arbolus-technologies/api";
import { AvailableSlot } from "@arbolus-technologies/models/project";
import {
  PROJECT_CALENDAR_ROUTE,
  PROJECT_CREATE_EVENT_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { SIDE_PANEL_SIZE, formatDateToUTC } from "@arbolus-technologies/utils";

import { ExpertAvailability } from "../../Components/ExpertAvailability/ExpertAvailability";
import { MixPanelEventNames } from "../../Mixpanel/enums";
import { useArbolusTracking } from "../../Mixpanel/useArbolusTracking";
import { InternalSlidePanel } from "../InternalSlidePanel/InternalSlidePanel";

import styles from "./SelectAvailabilitySlidePanel.module.scss";

interface SelectAvailabilitySlidePanelProps {
  chatId: string;
  projectId: string;
  projectName: string;
  projectTimezone: string;
  expertId: string;
  referral?: ReferralDetail;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  showHeader?: boolean;
}

export const SelectAvailabilitySlidePanel: React.FC<
  SelectAvailabilitySlidePanelProps
> = ({
  chatId,
  projectId,
  projectName,
  projectTimezone,
  expertId,
  referral,
  isOpen,
  setIsOpen,
  showHeader
}) => {
  const { t } = useTranslation("selectAvailabilitySlidePanel");
  const history = useHistory();
  const timezones = useSelector(
    CacheSelector.appTimezoneSelectOptionMapSelector()
  );
  const { trackSlidePanel, trackClick } = useArbolusTracking();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!isOpen) return;
    trackSlidePanel(MixPanelEventNames.ChatAvailabilitySidepanel);
  }, [isOpen]);

  const handleCreateEvent = (
    selectedSlot: AvailableSlot,
    timezone?: string
  ) => {
    if (!referral) return;

    history.push(
      PROJECT_CREATE_EVENT_ROUTE(
        projectId,
        `referralId=${referral.id}&workspaceId=${chatId}&startingDate=${formatDateToUTC(selectedSlot.slotStartValue)}&endingDate=${formatDateToUTC(selectedSlot.slotEndValue)}`
      )
    );
  };

  // change the way we create events from other points of the app
  const handleCreateCustomEvent = () => {
    if (!referral) return;
    trackClick(MixPanelEventNames.ChatBookCustomTime);

    history.push(
      PROJECT_CREATE_EVENT_ROUTE(
        projectId,
        `referralId=${referral.id}&workspaceId=${chatId}`
      )
    );
  };

  const handleAfterCallScheduled = () => {
    trackClick(MixPanelEventNames.ChatArrangeCall);
    setIsOpen(false);
    history.push(PROJECT_CALENDAR_ROUTE(projectId));
  };

  const expertAvailabilitySlots = referral?.expertAvailabilitySlots
    ? referral.expertAvailabilitySlots.filter((slot) => !slot.isExpired)
    : [];

  return (
    <InternalSlidePanel
      width={SIDE_PANEL_SIZE._720}
      title={t("slidePanelTitle")}
      closeButtonDirection="right"
      customCloseRequest={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      {referral && (
        <ExpertAvailability
          expertAvailabilitySlots={expertAvailabilitySlots}
          createEvent={handleCreateEvent}
          complianceStatus={referral.compliance.subStatus}
          expertStatus={referral.status}
          timezoneDetails={{
            timezones: Array.from(timezones.values()),
            projectTimezone,
            expertTimezone: referral.expert.timezone
          }}
          projectId={projectId}
          expertId={expertId}
          referralId={referral.id}
          referralAvailability={referral.referralAvailability}
          applicationStatus={referral.application.subStatus}
          onCallScheduled={handleAfterCallScheduled}
          showHeader={showHeader}
        />
      )}
      <div className={styles.footer}>
        <Button
          text={t("bookCustomEventButton")}
          type="tertiary"
          onClick={handleCreateCustomEvent}
        />
      </div>
    </InternalSlidePanel>
  );
};
