import { MONTH_FORMAT } from "@arbolus-technologies/utils";
import { Flex, Select } from "antd";
import { Dayjs } from "dayjs";
import moment from "moment";
import React from "react";

interface CalendarHeaderProps {
  value: Dayjs;
  onChange: (value: Dayjs) => void;
  timezoneOptions: { value: string; label: string }[];
  selectedTimezone: string;
  onTimezoneChange: (timezone: string) => void;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  value,
  onChange,
  timezoneOptions,
  selectedTimezone,
  onTimezoneChange
}) => {
  const currentMonth = value.month();
  const currentYear = value.year();
  const numberOfMonths = 12;
  const minYears = 5;
  const numberOfYearsMax = 11;

  const monthOptions = Array.from({ length: numberOfMonths }, (_, i) => (
    <Select.Option key={i} value={i}>
      {moment().month(i).format(MONTH_FORMAT)}
    </Select.Option>
  ));

  const yearOptions = Array.from({ length: numberOfYearsMax }, (_, i) => (
    <Select.Option key={i} value={currentYear - minYears + i}>
      {currentYear - minYears + i}
    </Select.Option>
  ));

  return (
    <Flex gap={8}>
      <Select
        value={currentMonth}
        onChange={(newMonth) => onChange(value.clone().month(newMonth))}
      >
        {monthOptions}
      </Select>

      <Select
        value={currentYear}
        onChange={(newYear) => onChange(value.clone().year(newYear))}
      >
        {yearOptions}
      </Select>

      <Select
        value={selectedTimezone}
        style={{ width: 400, marginLeft: "auto" }}
        onChange={onTimezoneChange}
        options={timezoneOptions}
      />
    </Flex>
  );
};

export default CalendarHeader;
