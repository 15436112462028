import { ClientUser } from "@arbolus-technologies/api";
import React from "react";
import { Item } from "./Item/Item";

interface UsersListProps {
  users: ClientUser[];
  usersSelected: string[];
  handleItemSelect: (
    id: string,
    isUserSelected: boolean,
    isUser: boolean
  ) => void;
}
export const UsersList: React.FC<UsersListProps> = ({
  users,
  usersSelected,
  handleItemSelect
}) => {
  return (
    <>
      {users.map(({ id, firstName, lastName, profileImageUrl }) => {
        const isSelected = usersSelected.some(
          (idSelected) => idSelected === id
        );
        const props = {
          id,
          firstName,
          lastName,
          profileImageUrl,
          isSelected,
          isUser: true
        };

        return <Item key={id} {...props} onClick={handleItemSelect} />;
      })}
    </>
  );
};
