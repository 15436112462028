import { Button, Flex, Result, Typography } from "antd";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { PublicRoutes } from "@arbolus-technologies/routes";
import { LinkWrapper } from "@arbolus-technologies/ui/components";

const { Text } = Typography;

export const SurveyClosedFeedback = () => {
  const { t } = useTranslation("surveyClosedFeedback");

  return (
    <Result
      title={t("surveyClosed")}
      subTitle={
        <Flex vertical>
          <Text type="secondary">{t("thankYouForYourInterest")}</Text>
          <Text type="secondary">
            <Trans i18nKey="surveySupport">
              For any issue, please feel free to reach out at{" "}
              <a href="mailto:survey_support@arbolus.com">
                survey_support@arbolus.com
              </a>
            </Trans>
          </Text>
        </Flex>
      }
      extra={
        <Flex justify="center">
          <LinkWrapper to={PublicRoutes.REGISTER}>
            <Button type="primary">{t("createAnAccount")}</Button>
          </LinkWrapper>
        </Flex>
      }
      style={{ maxWidth: 720 }}
    />
  );
};
