import { Card, Checkbox, Flex } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AngleModel } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { MoveToShortlistButton } from "./MoveToShortlist/MoveToShortlistButton";

const SEARCH_ENGINE_LIMIT = 10000;

interface ExpertListHeaderProps {
  angles: AngleModel[];
  totalExpertsAvailable: number;
  totalExpertsFound: number;
  selectedExpertsIds: string[];
  selectedCautionExperts: { id: string; name: string }[];
  areAllExpertsSelected: boolean;
  areSomeExpertsSelected: boolean;
  isConfirmReferralsLoading: boolean;
  onShortlistConfirmed: (selectedExpertsIds: string[]) => void;
  handleToggleSelectAllExperts: () => void;
  handleSetIsConfirmReferralsLoading: (isLoading: boolean) => void;
}

export const ExpertListHeader: React.FC<ExpertListHeaderProps> = ({
  angles,
  totalExpertsAvailable,
  totalExpertsFound,
  selectedExpertsIds,
  selectedCautionExperts,
  areAllExpertsSelected,
  areSomeExpertsSelected,
  isConfirmReferralsLoading,
  onShortlistConfirmed,
  handleToggleSelectAllExperts,
  handleSetIsConfirmReferralsLoading
}) => {
  const { t } = useTranslation("discoverPage");

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const displayedExpertsNumber =
    totalExpertsFound >= SEARCH_ENGINE_LIMIT
      ? `+${SEARCH_ENGINE_LIMIT}`
      : totalExpertsFound;

  const titleMessage = [
    t("totalExperts", {
      count:
        totalExpertsFound > 0 ? displayedExpertsNumber : totalExpertsAvailable
    })
  ];
  if (selectedExpertsIds.length > 0) {
    titleMessage.push(
      t("selectedExperts", { count: selectedExpertsIds.length })
    );
  }

  return (
    <Card size="small">
      {isAdmin ? (
        <Flex justify="space-between" align="center">
          <Checkbox
            checked={areAllExpertsSelected}
            indeterminate={!areAllExpertsSelected && areSomeExpertsSelected}
            onChange={handleToggleSelectAllExperts}
            disabled={totalExpertsFound === 0}
          >
            {titleMessage.join(" - ")}
          </Checkbox>
          <MoveToShortlistButton
            disabled={selectedExpertsIds.length === 0}
            selectedExpertsIds={selectedExpertsIds}
            selectedCautionExperts={selectedCautionExperts}
            onShortlistConfirmed={onShortlistConfirmed}
            handleSetIsConfirmReferralsLoading={
              handleSetIsConfirmReferralsLoading
            }
            isConfirmReferralsLoading={isConfirmReferralsLoading}
            angles={angles}
          />
        </Flex>
      ) : (
        titleMessage.join(" - ")
      )}
    </Card>
  );
};
