import { Endorsement } from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./EndorsementCardTitle.module.scss";

export function EndorsementCardTitle({
  endorsement
}: { endorsement: Endorsement }) {
  const name = displayUserName(endorsement.endorser);

  return (
    <div className={styles.cardHeader}>
      <Avatar
        avatar={{
          name,
          imageUrl: endorsement.endorser.profileImageUrl
        }}
        type="mediumXSCircle"
      />
      <div className={styles.nameAndJobTitle}>
        <span>{name}</span>
        <span className={styles.jobTitle}>{endorsement.endorser.title}</span>
      </div>
    </div>
  );
}
