import { SURVEY_EXPERT_PAYMENT_METHOD } from "@arbolus-technologies/models/survey";

import {
  ClientInvoiceStatus,
  ExpertPaymentStatus,
  TransactionStatus,
  TransactionType
} from "../enums/transactionEnums";
import { ApiPaginatedRequest } from "./api";
import { ListTransactionCanopy } from "./canopy";
import { BaseClient } from "./client";
import { TransactionEventDetails } from "./event";
import { TransactionProjectExpert } from "./expert";
import { ExchangeRate } from "./meta";
import { ListProject, ListProjectBase } from "./projects";
import { BaseSurvey, TransactionSurveyExpert } from "./survey";
import { User } from "./user";

export enum PAYMENT_METHOD_TYPE {
  CREDIT = "Credit",
  MONEY = "Money"
}

export interface ListTransaction {
  id: string;
  transactionDate: Date;
  status: TransactionStatus;
  type: TransactionType;
  isAuto: boolean;
  expert?: User;
  client?: BaseClient;
  project?: ListProjectBase;
  expertPayment?: ExpertPayment;
  clientInvoice?: ClientInvoice;
  owner?: User;
  secondaryOwner?: User;
  isModified: boolean;
  isInstantPay?: boolean;
  transactionDetails?: string;
}

interface ClientInvoice {
  status: ClientInvoiceStatus;
  isoCurrencyCode: string;
  amount: number;
  invoiceId?: string;
  isPaid: boolean;
  paymentMethodType: PAYMENT_METHOD_TYPE;
}

interface ExpertPayment {
  status: ExpertPaymentStatus;
  isoCurrencyCode: string;
  amount: number;
}

export type CreateTransactionData =
  | CreateIntroFeeTransactionData
  | CreateCallTransactionData
  | CreateOfflineWorkTransactionData
  | CreateSurveyTransactionData
  | CreateExpertSurveyTransactionData
  | CreateRefundClientTransactionData
  | CreateRefundExpertTransactionData
  | CreateClientExpenseTransactionData
  | CreateReferralTransactionData
  | CreateInternalCanopyTransactionData
  | CreateCanopyTransactionData;

export interface TransactionStats {
  NotYetApproved: number;
  NotYetInvoiced: number;
  UnpaidInvoices: number;
  UnpaidExperts: number;
  [key: string]: number;
}

// CREATE TRANSACTION
export interface BaseCreateTransaction {
  transactionDate: string;
  transactionType: TransactionType;
  projectId: string;
  ownerId: string;
  secondaryOwnerId: string | null;
  note?: string;
}

export interface CreateCallTransaction extends BaseCreateTransaction {
  call: CreateCallTransactionData;
}

export interface CreateIntroFeeTransaction extends BaseCreateTransaction {
  introFee: CreateIntroFeeTransactionData;
}

export interface CreateRefundClientTransaction extends BaseCreateTransaction {
  refundClient: CreateRefundClientTransactionData;
}

export interface CreateClientExpenseTransaction extends BaseCreateTransaction {
  clientExpense: CreateClientExpenseTransactionData;
}

export interface CreateRefundExpertTransaction extends BaseCreateTransaction {
  refundExpert: CreateRefundExpertTransactionData;
}

export interface CreateOfflineWorkTransaction extends BaseCreateTransaction {
  offlineWork: CreateOfflineWorkTransactionData;
}

export interface CreateSurveyTransaction extends BaseCreateTransaction {
  survey: CreateSurveyTransactionData;
}

export interface CreateExpertSurveyTransaction extends BaseCreateTransaction {
  expertSurvey: CreateExpertSurveyTransactionData;
}

export interface CreateCanopyTransaction extends BaseCreateTransaction {
  canopy: CreateCanopyTransactionData;
}

export interface CreateReferralTransaction extends BaseCreateTransaction {
  referral: CreateReferralTransactionData;
}

export interface CreateInternalCanopyTransaction extends BaseCreateTransaction {
  internalCanopy: CreateInternalCanopyTransactionData;
}

// UPDATE TRANSACTION
export interface BaseUpdateTransaction {
  transactionDate: string;
  note: string;
  ownerId: string;
  secondaryOwnerId: string | null;
}

export interface UpdateCallTransaction extends BaseUpdateTransaction {
  call: CallTransactionData;
}

export interface UpdateIntroFeeTransaction extends BaseUpdateTransaction {
  introFee: IntroFeeTransactionData;
}

export interface UpdateRefundClientTransaction extends BaseUpdateTransaction {
  refundClient: RefundClientTransactionData;
}

export interface UpdateClientExpenseTransaction extends BaseUpdateTransaction {
  clientExpense: ClientExpenseTransactionData;
}

export interface UpdateRefundExpertTransaction extends BaseUpdateTransaction {
  refundExpert: RefundExpertTransactionData;
}

export interface UpdateOfflineWorkTransaction extends BaseUpdateTransaction {
  offlineWork: OfflineWorkTransactionData;
}

export interface UpdateSurveyTransaction extends BaseUpdateTransaction {
  survey: SurveyTransactionData;
}

export interface UpdateExpertSurveyTransaction extends BaseUpdateTransaction {
  expertSurvey: ExpertSurveyTransactionData;
}

export interface UpdateCanopyTransaction extends BaseUpdateTransaction {
  canopy: CanopyTransactionData;
}

export interface UpdateReferralTransaction extends BaseUpdateTransaction {
  referral: ReferralTransactionData;
}

export interface UpdateInternalCanopyTransaction extends BaseUpdateTransaction {
  internalCanopy: InternalCanopyTransactionData;
}

// Call
export interface CallTransactionData {
  clientInvoicedAmount: number;
  invoicedDurationMinutes: number;
  expertAmount: number;
  eventStartTime: string;
  isInstantPay: boolean;
  isFollowUp: boolean;
}

export interface CreateCallTransactionData extends CallTransactionData {
  clientId: string;
  expertId: string;
  eventId: string;
}

// Offline Work
export interface OfflineWorkTransactionData {
  clientInvoicedAmount: number;
  expertAmount: number;
  invoicedDurationMinutes: number;
  isInstantPay: boolean;
}

export interface CreateOfflineWorkTransactionData
  extends OfflineWorkTransactionData {
  clientId: string;
  expertId: string;
}

// Survey
export interface SurveyTransactionData {
  clientInvoicedAmount: number;
  expertAmount: number;
  invoicedDurationMinutes: number;
  isInstantPay: boolean;
}

export interface ExpertSurveyTransactionData {
  clientInvoicedAmount: number;
  expertAmount: number;
  expertISOCurrency: string;
  isInstantPay: boolean;
  expertPaymentMethodType: string;
}

export interface CreateSurveyTransactionData extends SurveyTransactionData {
  clientId: string;
  expertId: string;
}

export interface CreateExpertSurveyTransactionData
  extends ExpertSurveyTransactionData {
  clientId: string;
  expertId: string;
  surveyId: string;
  expertISOCurrency: string;
  isInstantPay: boolean;
}

// Canopy
export interface CanopyTransactionData {
  clientInvoicedAmount: number;
  expertAmount: number;
  isInstantPay: boolean;
  expertISOCurrency: string;
}

export interface CreateCanopyTransactionData extends CanopyTransactionData {
  clientId: string;
  expertId: string;
  canopyId: string;
}

// Internal Canopy
export interface InternalCanopyTransactionData {
  expertAmount: number;
  isInstantPay: boolean;
  expertISOCurrency: string;
}

export interface CreateInternalCanopyTransactionData
  extends InternalCanopyTransactionData {
  expertId: string;
  canopyId: string;
}

// Intro Fee
export interface IntroFeeTransactionData {
  clientInvoicedAmount: number;
}

export interface CreateIntroFeeTransactionData extends IntroFeeTransactionData {
  clientId: string;
  expertId: string;
}

// Referral
export interface ReferralTransactionData {
  expertAmount: number;
  isInstantPay: boolean;
}

export interface CreateReferralTransactionData extends ReferralTransactionData {
  expertId: string;
  referredExpertId: string;
}

// Refund Client
export interface RefundClientTransactionData {
  clientInvoicedAmount: number;
}

export interface CreateRefundClientTransactionData
  extends RefundClientTransactionData {
  clientId: string;
}

// Expense Client
export interface ClientExpenseTransactionData {
  clientInvoicedAmount: number;
}

export interface CreateClientExpenseTransactionData
  extends ClientExpenseTransactionData {
  clientId: string;
}

// Refund Expert
export interface RefundExpertTransactionData {
  expertAmount: number;
  isInstantPay: boolean;
}

export interface CreateRefundExpertTransactionData
  extends RefundExpertTransactionData {
  expertId: string;
}

export interface Notes {
  created: string;
  createdBy: User;
  note: string;
}

export interface TransactionDetails {
  transactionId: string;
  transactionDate: Date;
  transactionType: TransactionType;
  project: ListProject;
  created: string;

  status: TransactionStatus;

  lastUpdatedDate: string;
  lastUpdatedUser: User;

  clientInvoicedAmount?: number;
  expertAmount?: number;
  createdBy: User;
  owner: User;
  secondaryOwner: User | null;
  isInstantPay: boolean;

  notes: Notes[];

  expert?: TransactionProjectExpert | TransactionSurveyExpert;
  referredExpert?: TransactionProjectExpert;
  event?: TransactionEventDetails;
  invoicedDurationMinutes?: number;

  exchangeRates: ExchangeRate[];
  canopy: ListTransactionCanopy;
  expertISOCurrency: string;
  paymentMethodType: PAYMENT_METHOD_TYPE;
  isFollowUp?: boolean;

  survey: BaseSurvey;
  expertPaymentMethodType: SURVEY_EXPERT_PAYMENT_METHOD;
}

export interface GetTransactionProjectRequest extends ApiPaginatedRequest {
  includeCurrentMonth: boolean;
}

export interface GetTransactionBusinessEntityRequest
  extends ApiPaginatedRequest {
  includeCurrentMonth: boolean;
}

export interface TransactionProject {
  id: string;
  name: string;
  description: string;
  projectState: string;
  created: string;
  clientId: string;
  clientName: string;
  ownerId: string;
  ownerName: string;
  lastEngagement: string;
  totalClientAmount: number;
  clientISOCurrencyCode: string | null;
  paymentMethodType: PAYMENT_METHOD_TYPE;
}

export interface TransactionBusinessEntity {
  id: string;
  companyLegalName: string;
  identifier: string;
  clientId: string;
  clientName: string;
  ownerId: string;
  ownerName: string;
  lastEngagement: string;
  totalClientAmount: number;
  isoCurrencyCode: string | null;
  paymentMethodType: PAYMENT_METHOD_TYPE;
}

export interface ExpertAmountCalculation {
  transactionType?: TransactionTypes;
  transactionMode: TransactionFormMode;
  invoicedDurationMinutes: number;
  invoicedDurationHours: number;
  invoicedDurationMinutesSaved?: number;
  invoicedDurationHoursSaved?: number;
  hourlyRate: number;
  expertHasMinimumCallTimeRate: boolean;
}

export interface ClientInvoicedAmountCalculation {
  transactionMode: TransactionFormMode;
  expertAmount: number;
  formValues: TransactionTypeInterface;
  exchangeRates: ExchangeRate[];
  isCreditModel: boolean;
}

export type TransactionTypes =
  | "IntroFee"
  | "Call"
  | "RefundClient"
  | "Survey"
  | "ExpertSurvey"
  | "OfflineWork"
  | "RefundExpert"
  | "Canopy"
  | "ClientExpense"
  | "Referral"
  | "InternalCanopy";

export type TransactionFormMode = "create" | "edit";

export type TransactionFormStep =
  | "project"
  | "expert"
  | "event"
  | "canopy"
  | "survey"
  | "";

export const TRANSACTION_TYPE = "transactionType";
export const TRANSACTION_DATE = "transactionDate";
export const PROJECT = "project";
export const EVENT = "event";
export const EVENT_START = "eventStart";
export const INVOICED_DURATION_HOURS = "invoicedDurationHours";
export const INVOICED_DURATION_MINUTES = "invoicedDurationMinutes";
export const EVENT_REAL_DURATION = "eventRealDuration";
export const EXPERT = "expert";
export const REFERRED_EXPERT = "referredExpert";
export const CLIENT = "client";
export const CLIENT_INVOICED_AMOUNT = "clientInvoicedAmount";
export const OWNER = "owner";
export const SECONDARY_OWNER = "secondaryOwner";
export const NOTE = "note";
export const EXPERT_AMOUNT = "expertAmount";
export const IS_INSTANT_PAY = "isInstantPay";
export const HOURLY_RATE = "hourlyRate";
export const CANOPY = "canopy";
export const EXPERT_ISO_CURRENCY = "expertISOCurrency";
export const IS_FOLLOW_UP = "isFollowUp";
export const SURVEY = "survey";
export const SURVEY_PAYMENT_METHOD = "paymentMethod";

export interface TransactionTypeInterface {
  [TRANSACTION_TYPE]: TransactionType;
  [TRANSACTION_DATE]: Date;
  [PROJECT]: ListProject | null;
  [EVENT]: TransactionEventDetails[];
  [EVENT_START]: Date;
  [INVOICED_DURATION_HOURS]: number;
  [INVOICED_DURATION_MINUTES]: number;
  [EVENT_REAL_DURATION]: number;
  [EXPERT]: TransactionProjectExpert | TransactionSurveyExpert | null;
  [REFERRED_EXPERT]: TransactionProjectExpert | null;
  [CLIENT_INVOICED_AMOUNT]: number;
  [OWNER]: User | null;
  [SECONDARY_OWNER]: User | null;
  [NOTE]: string;
  [EXPERT_AMOUNT]: number;
  [IS_INSTANT_PAY]: boolean;
  [CANOPY]: ListTransactionCanopy[];
  [EXPERT_ISO_CURRENCY]: string;
  [IS_FOLLOW_UP]: boolean;
  [SURVEY]: BaseSurvey | null;
  [SURVEY_PAYMENT_METHOD]: string | null;
}
