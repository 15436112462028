import React from "react";

import { ClipboardService } from "@arbolus-technologies/api";

interface InviteTagProps {
  url: string;
}

const InviteTag: React.FC<InviteTagProps> = ({ url }: InviteTagProps) => {
  const urlObj = new URL(url);
  const urlText = `${urlObj.hostname}${urlObj.pathname}`;

  const handleCopyToClipboard = () => {
    ClipboardService.copyToClipboard(url);
  };

  return (
    <div className="invite-tag">
      <p className="invite-url" title={urlText}>
        {urlText}
      </p>
      <span
        className="ciq-icon ciq-assignments-outline"
        onClick={handleCopyToClipboard}
      />
    </div>
  );
};

export default InviteTag;
