import React from "react";

import { PaginatedResponseWithStatus } from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { DiscoverExpert } from "@arbolus-technologies/models/project";

import { ExpertCard } from "./ExpertCard/ExpertCard";
import { ExpertListFooter } from "./ExpertListFooter";

interface ExpertListProps {
  angles: AngleModel[];
  projectId: string;
  projectName: string;
  selectedExpertsIds: string[];
  shortlistedExpertsIds: string[];
  expertListPaginated: PaginatedResponseWithStatus<DiscoverExpert>;
  totalExpertsFound: number;
  selectedCautionExperts: { id: string; name: string }[];
  isConfirmReferralsLoading: boolean;
  handleSelectExpert: (expertId: string) => void;
  handleShortlistConfirmed: (selectedExpertsIds: string[]) => void;
  handleSetIsConfirmReferralsLoading: (isLoading: boolean) => void;
}

export const ExpertList: React.FC<ExpertListProps> = ({
  angles,
  projectId,
  projectName,
  selectedExpertsIds,
  shortlistedExpertsIds,
  expertListPaginated,
  totalExpertsFound,
  selectedCautionExperts,
  isConfirmReferralsLoading,
  handleSelectExpert,
  handleShortlistConfirmed,
  handleSetIsConfirmReferralsLoading
}) => {
  return (
    <>
      {expertListPaginated.items.map((expert) => (
        <ExpertCard
          key={expert.id}
          expert={expert}
          projectId={projectId}
          projectName={projectName}
          selectedExpertsIds={selectedExpertsIds}
          shortlistedExpertsIds={shortlistedExpertsIds}
          handleSelectExpert={handleSelectExpert}
        />
      ))}
      <ExpertListFooter
        angles={angles}
        totalExpertsFound={totalExpertsFound}
        selectedExpertsIds={selectedExpertsIds}
        selectedCautionExperts={selectedCautionExperts}
        isConfirmReferralsLoading={isConfirmReferralsLoading}
        onShortlistConfirmed={handleShortlistConfirmed}
        handleSetIsConfirmReferralsLoading={handleSetIsConfirmReferralsLoading}
      />
    </>
  );
};
