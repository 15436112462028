import { Tag, Tooltip } from "antd";
import React from "react";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { AngleModel } from "@arbolus-technologies/models/common";

import styles from "./AntDAngleTag.module.scss";

export const getColorName = (color: string) => {
  return colorNameMap[color] || color;
};

const colorNameMap: Record<string, string> = {
  "#BAE637": "lime",
  "#722ED1": "purple",
  "#1677FF": "blue",
  "#EB2F96": "magenta",
  "#13C2C2": "cyan",
  "#389E0D": "green",
  "#FADB14": "gold",
  "#597EF7": "geekblue",
  "#FA8C16": "orange",
  "#FA541C": "volcano"
};

interface AntDAngleTagProps {
  angle: AngleModel;
  showTooltip?: boolean;
}

export const AntDAngleTag: React.FC<AntDAngleTagProps> = ({
  angle,
  showTooltip = true
}) => {
  return (
    <Tag
      icon={<AntDIcon name={"zoom_in_map"} />}
      color={getColorName(angle.color)}
      className={styles.angleTag}
    >
      {showTooltip ? (
        <Tooltip title={angle.title} className={styles.angleText}>
          {angle.title}
        </Tooltip>
      ) : (
        angle.title
      )}
    </Tag>
  );
};
