import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  DO_NOT_CONTACT_STATUS,
  DateString
} from "@arbolus-technologies/models/common";
import { AvailabilityStatus } from "@arbolus-technologies/models/project";
import {
  PROJECT_BOOK,
  PROJECT_CANDIDATES_ROUTE,
  PROJECT_CREATE_EVENT_WITH_REFERRAL_ROUTE,
  PROJECT_ROUTE,
  PROJECT_SCHEDULE_REFERRALS_PANEL,
  PROJECT_SCHEDULING_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  AssignedAngle,
  BadgeWithIcon,
  ChevronButton,
  DNCTag,
  PublicCompanyIcon,
  UserPortrait
} from "@arbolus-technologies/ui/components";
import { transformedDateTime } from "@arbolus-technologies/utils";

import { MixPanelEventNames } from "../../Mixpanel/enums";
import { useArbolusTracking } from "../../Mixpanel/useArbolusTracking";

import "react-placeholder/lib/reactPlaceholder.css";
import styles from "./EarlyAvailabilityCard.module.scss";

interface EarlyAvailabilityCardProps {
  project: {
    title: string;
    id: string;
  };
  angle?: {
    id: string;
    title: string;
    color: string;
  };
  earliestAvailability: {
    startTime: string;
    endTime: string;
  };
  remainingSlots: number;
  expert: {
    title: string;
    fullName: string;
    imageUrl: string;
    id: string;
    lastPublicCompanyExpDate: DateString | null;
    doNotContactStatus?: DO_NOT_CONTACT_STATUS;
  };
  referralId: string;
  timezone: string;
  status?: AvailabilityStatus;
}

export const EarlyAvailabilityCard: React.FC<EarlyAvailabilityCardProps> = ({
  expert,
  project,
  angle,
  earliestAvailability,
  remainingSlots,
  referralId,
  timezone,
  status = AvailabilityStatus.BOOK
}) => {
  const { t } = useTranslation("earliestAvailabilities");
  const { trackRedirect } = useArbolusTracking();

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const createEventUrl = PROJECT_CREATE_EVENT_WITH_REFERRAL_ROUTE(
    project.id,
    referralId
  );

  const getReferralRoute = () => {
    if (isAdmin) {
      if (status === AvailabilityStatus.BOOK) {
        return PROJECT_SCHEDULING_ROUTE(project.id, expert.id, referralId);
      } else {
        return PROJECT_CANDIDATES_ROUTE(project.id, expert.id, referralId);
      }
    } else {
      return PROJECT_SCHEDULE_REFERRALS_PANEL(
        project.id,
        expert.id,
        referralId
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {angle ? (
          <AssignedAngle angle={angle} />
        ) : (
          <Link
            to={PROJECT_ROUTE(project.id)}
            className={styles.projectNameContainer}
          >
            <BadgeWithIcon
              text={project.title}
              icon="folder"
              enableCursorPointer
            />
          </Link>
        )}
        <div className={styles.remainingSlotsContainer}>
          <p className={styles.availabilityDate}>
            {transformedDateTime({
              startTime: earliestAvailability.startTime,
              endTime: earliestAvailability.endTime,
              timezone
            })}
          </p>
          {remainingSlots > 0 && (
            <AntDButtonLink
              text={`+${remainingSlots}`}
              type="default"
              to={
                isAdmin
                  ? PROJECT_SCHEDULING_ROUTE(project.id).pathname!
                  : PROJECT_BOOK(project.id).pathname!
              }
              onClick={() => {
                trackRedirect(
                  MixPanelEventNames.Book,
                  PROJECT_BOOK(project.id).pathname!
                );
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.userBookContainer}>
        <Link to={getReferralRoute()}>
          <UserPortrait
            user={{
              title: expert.title,
              fullName: expert.fullName,
              id: expert.id,
              profileImageUrl: expert.imageUrl
            }}
            icon={
              (expert.lastPublicCompanyExpDate !== null && (
                <PublicCompanyIcon date={expert.lastPublicCompanyExpDate} />
              )) ||
              undefined
            }
          />
        </Link>

        {expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC ? (
          <DNCTag dncStatus={expert.doNotContactStatus} />
        ) : (
          <ChevronButton
            to={createEventUrl}
            text={t(status)}
            icon={{ direction: "right", position: "end" }}
            disabled={status !== AvailabilityStatus.BOOK}
            onClick={() =>
              trackRedirect(MixPanelEventNames.BookExpert, createEventUrl)
            }
          />
        )}
      </div>
    </div>
  );
};
