interface IRestClientErrorRequest {
  method: string;
  url: string;
  params?: any;
  payload?: any;
}

interface IRestClientErrorResponse {
  status?: number;
}

export class SimpleRestServiceError extends Error {
  request: IRestClientErrorRequest;
  response: IRestClientErrorResponse;

  constructor(
    request: IRestClientErrorRequest,
    response: IRestClientErrorResponse
  ) {
    super("API error");
    this.name = "SimpleRestServiceError";
    this.request = request;
    this.response = response;
  }

  toJSON() {
    return {
      response: JSON.stringify(this.response),
      request: JSON.stringify(this.request)
    };
  }
}
