import React from "react";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { CreateNewExpert } from "./Sections/CreateNewExpert/CreateNewExpert";
import { ExpertInvitePanelHeader } from "./Sections/ExpertInvitePanelHeader/ExpertInvitePanelHeader";
import { SearchNetwork } from "./Sections/SearchNetwork/SearchNetwork";

import styles from "./ExpertInvitePanel.module.scss";

interface ExpertInvitePanelProps {
  angleId?: string;
  projectId: string;
  createExpertRoute: string;
}

export const ExpertInvitePanel: React.FC<ExpertInvitePanelProps> = ({
  angleId,
  projectId,
  createExpertRoute
}) => {
  const isAdmin = useSelector(CacheSelector.isAdmin());

  return (
    <div className={styles.container}>
      <ExpertInvitePanelHeader />
      {!isAdmin && <SearchNetwork projectId={projectId} />}
      {isAdmin && <CreateNewExpert createExpertRoute={createExpertRoute} />}
    </div>
  );
};
