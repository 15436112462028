import { Flex, Typography } from "antd";
import moment from "moment";
import React from "react";

import { QuestionAnswer } from "@arbolus-technologies/api";
import { AssignedAngle } from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import {
  Avatar,
  DangerouslySetInnerHTML
} from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import styles from "./ScreeningCardItem.module.scss";

const { Text } = Typography;

export interface ScreeningCardItemProps {
  question: {
    angle: AngleModel;
    client: {
      id: string;
      name: string;
      logoUrl: string;
    };
    project: {
      id: string;
      name: string;
    };
    projectQuestionsAnswers: QuestionAnswer[];
    referralApplicationUpdateDate: string;
  };
}

export const ScreeningCardItem: React.FC<ScreeningCardItemProps> = ({
  question
}) => {
  const {
    angle,
    client,
    project,
    projectQuestionsAnswers,
    referralApplicationUpdateDate
  } = question;

  const dateFormatted = moment(referralApplicationUpdateDate).format(
    APP_DATE_FORMAT
  );

  return (
    <div className={styles.cardContainer}>
      <div className={styles.header}>
        <div className={styles.logoNameWrapper}>
          <Avatar
            avatar={{
              name: client.name,
              imageUrl: client.logoUrl
            }}
          />
          <span className={styles.companyName}>{client.name}</span>
        </div>
        <span className={styles.date}>{dateFormatted}</span>
      </div>
      <hr />
      <span className={styles.title}>{project.name}</span>
      <AssignedAngle angle={angle} angleClassName={styles.angle} />
      <ol>
        <Flex vertical gap={16}>
          {projectQuestionsAnswers.map((ques) => (
            <li key={ques.projectQuestion.id}>
              <Flex vertical gap={16}>
                <DangerouslySetInnerHTML text={ques.projectQuestion.question} />
                <div className={styles.answer}>
                  <DangerouslySetInnerHTML text={ques.projectAnswer.answer} />
                </div>
              </Flex>
            </li>
          ))}
        </Flex>
      </ol>
    </div>
  );
};
