import {
  Alert,
  Button,
  Card,
  Flex,
  Modal,
  Select,
  Tag,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  PROJECT_REFERRAL_STATE,
  ReferralDetail,
  Slot,
  ToasterService
} from "@arbolus-technologies/api";
import {
  APP_TRACKING_ROUTES,
  DO_NOT_CONTACT_STATUS,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { EXPERT_STATUS } from "@arbolus-technologies/models/expert";
import {
  AvailableSlot,
  DEFAULT_SLOTS_NUMBER,
  MAX_SLOTS_NUMBER
} from "@arbolus-technologies/models/project";
import { PROJECT_CREATE_EVENT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import {
  ProjectExpertsSelector,
  ProjectExpertsStoreActions
} from "@arbolus-technologies/stores/project-experts-store";
import {
  formatDateToUTC,
  useFormatSlotsValues
} from "@arbolus-technologies/utils";

import { BainConfirmation } from "../../../../../../Components/BainConfirmation/BainConfirmation";
import {
  MixPanelActions,
  MixPanelEventNames
} from "../../../../../../Mixpanel/enums";
import { trackEvent } from "../../../../../../Mixpanel/utils";
import { ButtonsWrapper } from "./ButtonsWrapper";

import styles from "./Scheduling.module.scss";

const { Text } = Typography;
const BAIN_ID = process.env.NX_PUBLIC_BAIN_ID;

interface SchedulingProps {
  referral: ReferralDetail;
  expert: ExpertDetail;
  validAvailabilitySlots: Slot[];
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const Scheduling: React.FC<SchedulingProps> = ({
  referral,
  expert,
  validAvailabilitySlots,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralScheduling");
  const dispatch = useDispatch();
  const history = useHistory();
  const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [availableSlots, setAvailableSlots] = useState<AvailableSlot[]>([]);
  const [selectedTimezone, setSelectedTimezone] =
    useState<string>(systemTimezone);
  const [sliceSlots, setSliceSlots] = useState<number>(DEFAULT_SLOTS_NUMBER);
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
  const [isBainModalOpen, setIsBainModalOpen] = useState(false);
  const [isRequestTimeSlot, setIsRequestTimeSlot] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<AvailableSlot | undefined>();

  const {
    status: referralStatus,
    application,
    compliance,
    complianceRequired
  } = referral;

  const { getFormattedSlotsValues, sortFormattedSlotsValues } =
    useFormatSlotsValues(validAvailabilitySlots, selectedTimezone);

  const clientId = useSelector(ProjectNxSelector.projectClientId());
  const timezones = useSelector(CacheSelector.timezones());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const expertCurrentCompanies = useSelector(
    ProjectExpertsSelector.expertCurrentCompanies()
  );

  const isBainClient = clientId === BAIN_ID;
  const isNotExpertApproved =
    referral.status === PROJECT_REFERRAL_STATE.CANDIDATE;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const slotsFormatted = getFormattedSlotsValues();
    const sortedSlots = sortFormattedSlotsValues(slotsFormatted);
    setAvailableSlots(sortedSlots);
  }, [
    getFormattedSlotsValues,
    sortFormattedSlotsValues,
    validAvailabilitySlots
  ]);

  const handleBookEvent = (slot?: AvailableSlot) => {
    if (referralStatus === PROJECT_REFERRAL_STATE.CANDIDATE) {
      setIsCandidateModalOpen(true);
    } else {
      handleCreateEvent(slot);
    }
  };

  const handleCreateEvent = (slot?: AvailableSlot) => {
    if (application.subStatus !== REFERRAL_SUB_STATE.ACCEPT) {
      dispatch(
        ProjectExpertsStoreActions.updateReferralStatus(
          referral.projectId,
          referral.id,
          { review: REFERRAL_SUB_STATE.ACCEPT }
        )
      );
    }

    if (!isAdmin && isBainClient && isNotExpertApproved) {
      setIsBainModalOpen(true);
      setSelectedSlot(slot);
    } else {
      createEvent(slot);
    }

    trackEvent?.(MixPanelEventNames.CreateEvent, {
      action: MixPanelActions.Initialized,
      from: APP_TRACKING_ROUTES.EXPERT_AVAILABILITY
    });
  };

  const createEvent = (selectedSlot?: AvailableSlot) => {
    if (referralStatus === PROJECT_REFERRAL_STATE.CANDIDATE) {
      dispatch(
        ProjectExpertsStoreActions.updateReferralStatus(
          referral.projectId,
          referral.id,
          { review: EXPERT_STATUS.ACCEPT }
        )
      );
    }

    dispatch(PanelStoreActions.closePanel(PanelId.ReferralExpertPanel));

    history.push(
      PROJECT_CREATE_EVENT_ROUTE(
        referral.projectId,
        `referralId=${referral.id}&startingDate=${formatDateToUTC(selectedSlot?.slotStartValue)}&endingDate=${formatDateToUTC(selectedSlot?.slotEndValue)}`
      )
    );
  };

  const isApplicationComplete =
    application.subStatus === REFERRAL_SUB_STATE.ACCEPT;

  const isComplianceNotApproved =
    complianceRequired &&
    compliance.subStatus !== REFERRAL_COMPLIANCE_STATE.ACCEPT;
  const isExpertRejectedOrShortlisted =
    referralStatus === PROJECT_REFERRAL_STATE.REJECT ||
    referralStatus === PROJECT_REFERRAL_STATE.SHORTLIST;

  const isExpertUnapproved = referralStatus !== PROJECT_REFERRAL_STATE.ACCEPT;
  const isBainAndUnapprovedExpert =
    isAdmin && isBainClient && isExpertUnapproved;
  const isDnc = expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  const disableEventCreation =
    isDnc ||
    isComplianceNotApproved ||
    isBainAndUnapprovedExpert ||
    isExpertRejectedOrShortlisted ||
    application.subStatus === REFERRAL_SUB_STATE.PENDING;

  if (!isApplicationComplete) {
    return (
      <Alert
        message={t("visibleAfterApplicationCompletion")}
        type="warning"
        showIcon
      />
    );
  }

  const handleRequestTimeSlots = () => {
    // This service approves the expert directly
    expertService
      .requestExpertAvailability(referral.projectId, expert.id)
      .subscribe(
        () => {
          dispatch(PanelStoreActions.closePanel(PanelId.ReferralExpertPanel));
          notificationService.showSuccess(t("requestTimeSlotSuccessMessage"));
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
        }
      );
  };

  const requestTimeSlots = () => {
    if (isBainClient && isExpertUnapproved && !isAdmin) {
      setIsBainModalOpen(true);
      setIsRequestTimeSlot(true);
    } else {
      handleRequestTimeSlots();
    }
  };

  return (
    <Flex vertical gap={24}>
      {isComplianceNotApproved && (
        <Alert message={t("compliancePending")} type="warning" showIcon />
      )}
      {availableSlots.length === 0 ? (
        <Flex gap={24} vertical>
          <Alert message={t("slotsExpired")} type="warning" showIcon />
          <ButtonsWrapper
            referral={referral}
            disableEventCreation={disableEventCreation}
            onBookEvent={handleBookEvent}
            onRequestTimeSlots={requestTimeSlots}
          />
        </Flex>
      ) : (
        <Card>
          <Flex gap={16} vertical>
            <Text strong>{t("expertAvailability")}</Text>
            <Text>{t("readyToConsult")}</Text>
            <Flex gap={8} align="center">
              <Text>{t("timeDisplayed")}</Text>
              <Select
                showSearch
                defaultValue={
                  timezones.find(
                    (timezone) => timezone.value === systemTimezone
                  )?.label
                }
                onChange={(timezone: string) => setSelectedTimezone(timezone)}
                options={timezones}
                style={{ flex: 1 }}
              />
            </Flex>
            <Flex gap={24} vertical>
              <div className={styles.slotsContainer}>
                {availableSlots.slice(0, sliceSlots).map((availableSlot) => (
                  <Card key={availableSlot.slotId}>
                    <Flex gap={4} vertical>
                      <Text strong>
                        {availableSlot.slotTimeStart} -{" "}
                        {availableSlot.slotTimeEnd}
                      </Text>
                      <Flex gap={16} align="center" justify="space-between">
                        <Text type="secondary">{availableSlot.slotDate}</Text>
                        {availableSlot.slotScheduled ? (
                          <Tag
                            icon={<AntDIcon name="check" />}
                            color="success"
                            className={styles.tag}
                          >
                            {t("booked")}
                          </Tag>
                        ) : (
                          <Button
                            type="primary"
                            disabled={disableEventCreation}
                            onClick={() => handleBookEvent(availableSlot)}
                          >
                            {t("book")}
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  </Card>
                ))}
              </div>
              <ButtonsWrapper
                referral={referral}
                disableEventCreation={disableEventCreation}
                onBookEvent={handleBookEvent}
                onRequestTimeSlots={requestTimeSlots}
              >
                {sliceSlots === DEFAULT_SLOTS_NUMBER &&
                  availableSlots.length > DEFAULT_SLOTS_NUMBER && (
                    <Button
                      type="link"
                      icon={<AntDIcon name="keyboard_arrow_down" />}
                      iconPosition="end"
                      onClick={() => setSliceSlots(MAX_SLOTS_NUMBER)}
                    >
                      {t("showMore")}
                    </Button>
                  )}
              </ButtonsWrapper>
            </Flex>
          </Flex>
        </Card>
      )}
      <Modal
        title={t("expertCandidateTitle")}
        open={isCandidateModalOpen}
        cancelText={t("cancel")}
        onCancel={() => setIsCandidateModalOpen(false)}
        onOk={() => {
          setIsCandidateModalOpen(false);
          handleCreateEvent();
        }}
        okText={t("approve")}
        okType="primary"
      >
        {t("expertCandidateSubtitleArrangeCall")}
      </Modal>
      <Modal
        title={t("companiesCID")}
        open={isBainModalOpen}
        cancelText={t("reviewLater")}
        onCancel={() => setIsBainModalOpen(false)}
        onOk={() => {
          setIsBainModalOpen(false);
          if (isRequestTimeSlot) {
            handleRequestTimeSlots();
          } else {
            createEvent(selectedSlot);
          }
        }}
        okText={t("passCID")}
        okType="primary"
      >
        <BainConfirmation
          expertId={expert.id}
          expertCurrentCompanies={expertCurrentCompanies}
        />
      </Modal>
    </Flex>
  );
};
