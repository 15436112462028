import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import { push } from "connected-react-router";
import { Field, Form, Formik, FormikProps } from "formik";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, FormGroup, Label } from "reactstrap";
import { Dispatch } from "redux";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import {
  AuthPageHeader,
  MixPanelActions,
  MixPanelEventNames,
  noop,
  trackEvent
} from "@arbolus-technologies/features/common";

import { PublicRouteConstants } from "../../../../../constants";
import { RECAPTCHA_ACTIONS } from "../../../../../constants/auth";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { ClientService } from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { CIQFormInput } from "../../../../app/components";
import { CompanyEnquirySchema } from "./CompanyEnquirySchema";
import { HelloMessage } from "./HelloMessage";

const notification = new ToasterService();

interface CompanyEnquiryProps extends WithTranslation {
  navigateToLogin: () => void;
  executeRecaptcha: (action: string) => Promise<string>;
}

interface CompanyEnquiryState {
  isSubmitting: boolean;
  enquirer?: string;
}

interface EnquiryFormValues {
  name: string;
  company: string;
  email: string;
  phone: string;
  message: string;
}

class CompanyEnquiry extends React.PureComponent<
  CompanyEnquiryProps,
  CompanyEnquiryState
> {
  static defaultProps = {
    navigateToLogin: noop
  };

  constructor(props: CompanyEnquiryProps) {
    super(props);
    this.state = {
      isSubmitting: false
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("companyInquiry");

    trackEvent(MixPanelEventNames.UserRegistrationCompanyEnquiry, {
      action: MixPanelActions.VisitedPage
    });
  }

  componentWillUnmount(): void {
    this.enquirySendSubscription?.unsubscribe();
  }

  private enquirySendSubscription?: Subscription;

  handleFormSubmit = async ({
    company,
    email,
    message,
    name,
    phone
  }: EnquiryFormValues): Promise<void> => {
    const { t, executeRecaptcha } = this.props;

    const phoneNumber = phone ? `+${phone}` : "";
    const mixpanelProperties = {
      name,
      email,
      company,
      phoneNumber
    };

    try {
      const recaptchaToken = await executeRecaptcha(
        RECAPTCHA_ACTIONS.COMPANY_ENQUIRY
      );

      this.setState({ isSubmitting: true });

      this.enquirySendSubscription = ClientService.createEnquiries({
        companyName: company,
        name,
        email: email.toLowerCase(),
        phoneNumber,
        enquiryText: message,
        recaptchaToken
      }).subscribe(
        () => {
          this.setState({ isSubmitting: false, enquirer: name });
          notification.showSuccess(t("enquirySent"));

          trackEvent(MixPanelEventNames.UserRegistrationCompanyEnquiry, {
            action: MixPanelActions.FormSubmitted,
            ...mixpanelProperties
          });
        },
        (error: ErrorResponse<CIQError>) => {
          this.setState({ isSubmitting: false });
          notification.showError(error.message);

          trackEvent(MixPanelEventNames.UserRegistrationCompanyEnquiry, {
            action: MixPanelActions.FormSubmissionFailed,
            ...mixpanelProperties
          });
        }
      );
    } catch (err) {
      this.setState({ isSubmitting: false });
      notification.showError(t("errorInCompanyEnquiry"));

      trackEvent(MixPanelEventNames.UserRegistrationCompanyEnquiry, {
        action: MixPanelActions.FormSubmissionFailed,
        ...mixpanelProperties
      });
    }
  };

  renderEnquiryForm = ({
    errors,
    touched,
    handleBlur,
    setFieldValue,
    values,
    setFieldTouched,
    isValid
  }: FormikProps<EnquiryFormValues>): JSX.Element => {
    const { isSubmitting } = this.state;
    const { t } = this.props;
    const { phone } = values;

    const handlePhoneChange = (value: string): void => {
      setFieldTouched("phone", true);
      setFieldValue("phone", value);
    };

    return (
      <Form>
        <FormGroup
          className={clsx({
            "is-invalid": errors.company && touched.company
          })}
        >
          <Field
            name="company"
            placeholder={t("company")}
            type="text"
            component={CIQFormInput}
          />
        </FormGroup>
        <FormGroup
          className={clsx({
            "is-invalid": errors.name && touched.name
          })}
        >
          <Field
            name="name"
            placeholder={t("name")}
            type="text"
            component={CIQFormInput}
          />
        </FormGroup>
        <FormGroup
          className={clsx({
            "is-invalid": errors.email && touched.email
          })}
        >
          <Field
            className="email-input"
            name="email"
            placeholder={t("email")}
            component={CIQFormInput}
          />
        </FormGroup>
        <FormGroup
          className={clsx({
            "is-invalid": errors.phone && touched.phone
          })}
        >
          <PhoneInput
            inputProps={{
              name: "phone",
              autoComplete: "off"
            }}
            value={phone || ""}
            placeholder={t("phoneNumber")}
            onChange={handlePhoneChange}
            onBlur={handleBlur("phone")}
            enableSearch
            country="us"
            disableSearchIcon
            prefix=""
          />
          {errors.phone && touched.phone && (
            <Label className="invalid-feedback">{errors.phone}</Label>
          )}
        </FormGroup>
        <FormGroup
          className={clsx({
            "is-invalid": errors.message && touched.message
          })}
        >
          <Field
            rows={9}
            name="message"
            placeholder={t("enquiryMessage")}
            type="textarea"
            component={CIQFormInput}
          />
        </FormGroup>
        <div className="company-inquiry-footer">
          <Button
            nativeType="submit"
            disabled={!isValid || isSubmitting}
            text={isSubmitting ? t("submitButtonWorking") : t("submitButton")}
          />
        </div>
      </Form>
    );
  };

  renderForm = (): JSX.Element => {
    const { t } = this.props;
    const currentQueryString = window.location.search;

    return (
      <>
        <h3>
          {t("toSetUp")}
          {t("companyAccount")}
          {t("pleaseGetIn")}
        </h3>

        <Formik<EnquiryFormValues>
          initialValues={{
            company: "",
            email: "",
            message: "",
            name: "",
            phone: ""
          }}
          validationSchema={CompanyEnquirySchema}
          onSubmit={this.handleFormSubmit}
        >
          {this.renderEnquiryForm}
        </Formik>
        <div className="company-inquiry-already-has-account">
          <Trans
            ns="register"
            i18nKey="alreadyHaveAccount"
            components={[
              <Link
                key="0"
                to={{
                  pathname: PublicRouteConstants.LOGIN,
                  search: currentQueryString
                }}
              />
            ]}
          />
        </div>
      </>
    );
  };

  render(): JSX.Element {
    const { enquirer } = this.state;
    const { t, navigateToLogin } = this.props;

    return (
      <Container className="company-inquiry-container">
        <AuthPageHeader title={t("registerCompanyPageTitle")} />
        {enquirer ? (
          <HelloMessage navigateToLogin={navigateToLogin} enquirer={enquirer} />
        ) : (
          this.renderForm()
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToLogin: (): void => {
    dispatch(push(PublicRouteConstants.LOGIN));
  }
});

export default withTranslation("register")(
  // @ts-ignore
  connect(null, mapDispatchToProps)(CompanyEnquiry)
);
