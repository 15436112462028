import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ProjectAnglesSelector,
  ProjectAnglesStoreActions
} from "@arbolus-technologies/stores/project-angles-store";
import { MultiTextInputController } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import { ViewEditAngleFooter } from "../../ViewEditAngleFooter";
import { AngleScreeningQuestionSchema } from "./AngleScreeningQuestionsSchema";

import styles from "./AngleScreeningQuestionsEnabled.module.scss";

const QUESTIONS = "questions";

interface AngleScreeningQuestionsEnabledProps {
  isAdmin?: boolean;
  isEdit: boolean;
}

interface QuestionsForm {
  questions: { question: string }[];
}

export const AngleScreeningQuestionsEnabled: React.FC<
  AngleScreeningQuestionsEnabledProps
> = ({ isAdmin = false, isEdit }) => {
  const { t } = useTranslation("viewEditAngle");
  const dispatch = useDispatch();
  const disabled = useSelector(
    ProjectAnglesSelector.isFormCreateEditAngleDisabled()
  );
  const selectedAngle = useSelector(ProjectAnglesSelector.selectedAngle());
  const {
    questions,
    questionVersion,
    questionsModifiedBy,
    questionsModifiedOn
  } = selectedAngle;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<QuestionsForm>({
    // @ts-ignore
    resolver: yupResolver(AngleScreeningQuestionSchema),
    defaultValues: {
      [QUESTIONS]: questions.length > 0 ? questions : [{ question: "" }]
    }
  });

  const questionsMethod = useFieldArray({
    control,
    name: QUESTIONS
  });

  const handleUpdateQuestions = (data: QuestionsForm) => {
    const questionsToSend = data.questions.map(
      (q: { question: string }) => q.question
    );

    dispatch(
      ProjectAnglesStoreActions.editAngleQuestionsAdmin(selectedAngle.id, {
        questions: questionsToSend
      })
    );
  };

  return (
    <form className={styles.formContainer}>
      <MultiTextInputController
        arrMethods={questionsMethod}
        watch={watch}
        control={control}
        errors={errors}
        disabled={disabled}
      />
      {questionVersion > 0 && (
        <div className={styles.informLastUpdated}>
          {t("anglesQuestionLastUpdated", {
            version: questionVersion,
            date: moment(questionsModifiedOn).format("DD MMM YYYY"),
            userName: displayUserName({
              firstName: questionsModifiedBy?.firstName!,
              lastName: questionsModifiedBy?.lastName!,
              email: ""
            })
          })}
        </div>
      )}
      <ViewEditAngleFooter
        canEdit={isAdmin}
        isEdit={isEdit}
        isDisabled={disabled && !isAdmin}
        isScreeningQuestionView
        handleSubmit={handleSubmit(handleUpdateQuestions)}
        editAction={() =>
          dispatch(ProjectAnglesStoreActions.setFormCreateEditAngleEnabled())
        }
      />
    </form>
  );
};
