import { Divider, Flex, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDAvatar, AntDIcon } from "@arbolus-technologies/antDComponents";
import { Compliance, User } from "@arbolus-technologies/api";
import { REFERRAL_COMPLIANCE_STATE } from "@arbolus-technologies/models/common";
import { COMPLIANCE_UPDATE_FORMAT } from "@arbolus-technologies/utils";

import styles from "./Compliance.module.scss";

const { Text } = Typography;
const complianceAutoApproval = "Compliance automatically approved";

interface ComplianceAnswersProps {
  complianceInfo: Compliance[];
  complianceStatus: REFERRAL_COMPLIANCE_STATE;
  chaperoneCall: boolean;
  complianceNote?: string;
  complianceUpdatedBy: User;
  complianceUpdateDate: Date;
}

export const ComplianceAnswers: React.FC<ComplianceAnswersProps> = ({
  complianceInfo,
  complianceStatus,
  chaperoneCall,
  complianceNote,
  complianceUpdatedBy,
  complianceUpdateDate
}) => {
  const { t } = useTranslation("referralCompliance");

  const isCompliancePending =
    complianceStatus === REFERRAL_COMPLIANCE_STATE.PENDING;
  const isCompliancePassed =
    complianceStatus === REFERRAL_COMPLIANCE_STATE.ACCEPT;

  const isAutomaticallyApproved = complianceNote === complianceAutoApproval;

  const firstCallStatus = chaperoneCall
    ? t("joiningFirstCall")
    : t("notJoiningFirstCall");

  return (
    <Flex vertical>
      {complianceInfo.map((item, index) => {
        const expectedAnswer = item.question.displayModel.expectedAnswer;
        const answer = item.answer?.answer;
        const textAnswer = item.answer?.textAnswer;
        const isAnswerMatch = !expectedAnswer || expectedAnswer === answer;

        return (
          <Flex key={item.questionId} gap={8} vertical>
            <Text strong>{`${index + 1}. ${item.question.question}`}</Text>
            <Flex gap={16} align="center">
              {answer && (
                <Tag
                  icon={
                    <AntDIcon
                      name={isAnswerMatch ? "check_circle" : "cancel"}
                    />
                  }
                  color={isAnswerMatch ? "success" : "error"}
                  className={styles.tag}
                >
                  {answer}
                </Tag>
              )}
              <Text>{t("preferredAnswer", { expectedAnswer })}</Text>
              {textAnswer && <Text>{t("detailsRequired")}</Text>}
            </Flex>
            {textAnswer && <Text>{textAnswer}</Text>}
          </Flex>
        );
      })}
      {!isCompliancePending && (
        <>
          <Divider />
          <Flex gap={16} vertical>
            <Flex gap={16} align="center" justify="space-between">
              <Text strong>{t("complianceApproval")}</Text>
              <Flex gap={8}>
                <Tag
                  icon={
                    <AntDIcon
                      name={isCompliancePassed ? "check_circle" : "cancel"}
                    />
                  }
                  color={isCompliancePassed ? "success" : "error"}
                  className={styles.tag}
                >
                  {isCompliancePassed ? t("passed") : t("failed")}
                </Tag>
                {complianceUpdatedBy && isCompliancePassed && (
                  <Tag
                    icon={
                      <AntDIcon
                        name={chaperoneCall ? "call" : "phone_disabled"}
                      />
                    }
                    color={chaperoneCall ? "processing" : "default"}
                    className={styles.tag}
                  >
                    {firstCallStatus}
                  </Tag>
                )}
              </Flex>
            </Flex>
            {!isAutomaticallyApproved && (
              <Flex gap={8} vertical>
                <Text strong>{t("additionalComments")}</Text>
                <Text>{complianceNote || "-"}</Text>
              </Flex>
            )}
            {complianceUpdatedBy && (
              <Flex gap={8} align="center">
                {isAutomaticallyApproved ? (
                  <p>{t("automaticallyApproved")}</p>
                ) : (
                  <AntDAvatar
                    profileImageUrl={complianceUpdatedBy.profileImageUrl}
                    firstName={complianceUpdatedBy.firstName}
                    lastName={complianceUpdatedBy.lastName}
                    size="small"
                  />
                )}
                <Text code>
                  {moment(complianceUpdateDate).format(
                    COMPLIANCE_UPDATE_FORMAT
                  )}
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
