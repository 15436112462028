import { GridApi } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { SurveyExpert } from "@arbolus-technologies/api";

export const BULK_ACTIONS = "##BULK_ACTIONS##";

interface CustomStatusPanelProps {
  api: GridApi<SurveyExpert>;
}

export const BulkActionsStatusPanel: React.FC<CustomStatusPanelProps> = ({
  api
}) => {
  const { t } = useTranslation("surveyExpertTable");

  const openContextMenu = React.useCallback(
    (event: React.MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- hidden API
      (api as any).menuService.contextMenuFactory.showMenu(
        undefined,
        undefined,
        BULK_ACTIONS,
        event
      );
    },
    [api]
  );

  return (
    <div className="flex-row-8 h-100 align-items-center">
      <Icon
        name="more_horiz"
        fontSize="18px"
        tooltip={t("bulkActions")}
        onClick={openContextMenu}
      />
    </div>
  );
};
