import { API_URL_V1 } from "@arbolus-technologies/api";

export const MONITORING_LIST = "/monitoring-list";

export const MONITORING_LIST_API = {
  MONITORING_LIST_COMPANIES: (userId: string): string =>
    `${API_URL_V1}${MONITORING_LIST}/${userId}/eligible-companies`,
  USER_MONITORING_LIST: (userId: string) =>
    `${API_URL_V1}${MONITORING_LIST}/${userId}`,
  CHECK_USER_MONITORING_LIST: (userId: string) =>
    `${API_URL_V1}${MONITORING_LIST}/${userId}/is-monitoring-companies`,
  COMPANY_DATA: (vendorCompanyId: string): string =>
    `${API_URL_V1}${MONITORING_LIST}/company-data/${vendorCompanyId}`,
  COMPANY_DATA_REPORTS: (vendorCompanyId: string): string =>
    `${API_URL_V1}${MONITORING_LIST}/company-data/${vendorCompanyId}/reports`,
  COMPANY_SIGNALS: () => `${API_URL_V1}${MONITORING_LIST}/company-signals`
};
