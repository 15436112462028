import { useState } from "react";

import { ErrorResponse, UserService } from "@arbolus-technologies/api";

export function useChangePassword() {
  const [inProgress, setInProgress] = useState(false);

  function changePassword(currentPassword: string, newPassword: string) {
    setInProgress(true);

    return new Promise((resolve, reject) => {
      UserService.changeUserPassword({
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: newPassword
      }).subscribe(
        () => {
          setInProgress(false);
          resolve(true);
        },
        (error: ErrorResponse) => {
          setInProgress(false);
          reject(error);
        }
      );
    });
  }

  return {
    changePassword,
    inProgress
  };
}
