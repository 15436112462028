import { Observable } from "rxjs";

import {
  USERS_API,
  UpdateUserPayload,
  UserService,
  restService
} from "@arbolus-technologies/api";

import { APP_USER_ROLES } from "../constants/app";
import { ApiPaginatedResponse, ApiSuccessResponse } from "../models/api";
import { FederatedLoginRequest } from "../models/auth";
import { InboxNotification } from "../models/notifications";
import {
  FederateLoginResponse,
  LoggedInUser,
  NotificationsGetRequest,
  RegisterUserRequest,
  ReinviteExpiredInviteRequest,
  ResetPasswordRequestEmail,
  User
} from "../models/user";

export const userService = {
  ...UserService,

  federatedLogin: (
    loginRq: FederatedLoginRequest
  ): Observable<FederateLoginResponse> =>
    restService.post<FederateLoginResponse>(USERS_API.OPEN_ID(), loginRq),

  generateActiveUserRole: (userRoles: string[]): string => {
    const isClient = userRoles.includes(APP_USER_ROLES.client);
    const isAdminClient = userRoles.includes(APP_USER_ROLES.admin);
    const isExpert = userRoles.includes(APP_USER_ROLES.expert);

    if (isExpert) return APP_USER_ROLES.expert;
    if (isClient) {
      if (isAdminClient) return APP_USER_ROLES.adminClient;
      return APP_USER_ROLES.client;
    }

    return APP_USER_ROLES.guest;
  },

  getNotifications: (
    notificationsRequest: NotificationsGetRequest
  ): Observable<ApiPaginatedResponse<InboxNotification>> =>
    restService.get<ApiPaginatedResponse<InboxNotification>>(
      USERS_API.GET_NOTIFICATIONS(),
      {
        ...notificationsRequest
      }
    ),

  getUserProfile: (): Observable<LoggedInUser> =>
    restService.get<LoggedInUser>(USERS_API.GET_PROFILE()),

  isUserMatchesSearchCriteria: (
    { firstName, lastName, email }: User,
    query: string
  ) =>
    UserService.isUserMatchesSearchCriteria(firstName, lastName, email, query),

  reinviteExpiredInvitation: (
    expiredInvite: ReinviteExpiredInviteRequest
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(
      USERS_API.REINVITE_EXPIRED_INVITATION,
      expiredInvite
    ),

  registerUser: (
    registerUser: RegisterUserRequest
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(USERS_API.REGISTER(), registerUser),

  resetPasswordRequestEmail: ({
    email,
    recaptchaToken
  }: ResetPasswordRequestEmail) =>
    UserService.resetPasswordRequestEmail(email, recaptchaToken),

  updateUserProfile: (id: string, payload: UpdateUserPayload) =>
    UserService.updateProfile({ id, ...payload })
};
