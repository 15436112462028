import queryString from "query-string";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext, Switch } from "react-router";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";

import { ExpertsListPageTab, ToasterService } from "@arbolus-technologies/api";
import { ProjectData } from "@arbolus-technologies/features/common";
import { ExpertListPage } from "@arbolus-technologies/features/project-experts-list";
import { ChatPage } from "@arbolus-technologies/features/projects";
import { PROJECT_CHAT, PROJECT_CHAT_BASE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { Loader } from "@arbolus-technologies/ui/components";

import {
  PROJECT_BRIEF,
  PROJECT_CALENDAR,
  PROJECT_EVENT,
  PROJECT_EXPERTS,
  PROJECT_FILES,
  PROJECT_NEW_EVENT
} from "../../constants/navigation/projectRoutes";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../contexts/contentPanel/ContentPanelContext";
import { UserClient } from "../../models/client";
import { SentryRoute } from "../../sentry";
import { AppAction } from "../../store/actions";
import { AppState } from "../../store/reducers";
import { AuthSelector } from "../auth/store";
import { ClientProjectRouter, ExpertProjectRouter } from "./ProjectRouter";
import { CIQCandidatePicker, CIQExpertProfile } from "./components/panels";
import { CalendarPage, EventPage, FilesPage } from "./pages";
import LegacyProjectBriefPage from "./pages/legacyProjectBrief/LegacyProjectBriefPage";
import { ProjectSelector, ProjectStoreActions } from "./store";

const notification = new ToasterService();

interface ProjectContainerStoreProps {
  projectId: string;
  isProjectLoading: boolean;
  isProjectNxLoading: boolean;
  projectName: string;
  projectData: ProjectData | null;
  client?: UserClient;
  hasCompliance: boolean;
  loggedInUserClientId?: string;
  projectTimezone: string;
}

interface ProjectContainerProps
  extends RouteComponentProps<
      { projectId: string; eventId: string },
      StaticContext,
      { eventPageUuid?: string }
    >,
    ProjectContainerStoreProps,
    WithTranslation {
  getProject: (projectId: string) => void;
  getProjectNx: (projectId: string) => void;
  exitFromProject: () => void;
}

interface ProjectContainerState {
  projectId: string;
  projectName: string;
}

interface LocationState {
  referralState?: ExpertsListPageTab;
}

const bainId = process.env.NX_PUBLIC_BAIN_ID;

class ProjectContainer extends React.Component<
  ProjectContainerProps,
  ProjectContainerState
> {
  constructor(props: ProjectContainerProps) {
    super(props);

    const {
      match,
      getProject,
      getProjectNx,
      isProjectLoading,
      isProjectNxLoading
    } = props;
    this.state = {
      projectId: "",
      projectName: ""
    };

    if (!isProjectLoading && !isProjectNxLoading) {
      const { projectId } = match.params;
      getProject(projectId);
      getProjectNx(projectId);
    }
  }

  static getDerivedStateFromProps(
    nextProps: ProjectContainerProps
  ): ProjectContainerState {
    const nextState = {} as ProjectContainerState;
    nextState.projectId = nextProps.projectId;
    nextState.projectName =
      nextProps.projectData?.name ?? nextProps.projectName;
    return nextState;
  }

  componentDidMount = (): void => {
    const { t } = this.props;
    if (window.history.state && "pusherRefresh" in window.history.state) {
      notification.showSuccess(t("pusherRefresh"));

      // Clear state to avoid showing toast on refresh window
      window.history.replaceState({}, document.title);
    }
  };

  componentDidUpdate = (): void => {
    const { projectName } = this.state;

    if (projectName) {
      document.title = projectName;
    }
  };

  componentWillUnmount(): void {
    const { exitFromProject } = this.props;
    exitFromProject();
  }

  handleClickRow = (
    expertId: string,
    referralId: string,
    { setContent }: ContentPanelContextProps
  ): void => {
    const { projectId } = this.props;
    setContent(
      <CIQExpertProfile
        projectId={projectId}
        referralId={referralId}
        expertId={expertId}
      />,
      true,
      "panel-large"
    );
  };

  handleReviewNow = ({
    setContent,
    closePanel
  }: ContentPanelContextProps): void => {
    const { projectId, history, location } = this.props;
    const { referralState }: LocationState = queryString.parse(
      window.location.search
    );
    setContent(
      // @ts-ignore
      <CIQCandidatePicker projectId={projectId} onPanelClose={closePanel} />,
      true,
      "panel-large",
      () => {
        // Reset the url when closing the side panel and remove the showReview parameter
        const newUrl = `${location.pathname}?referralState=${referralState}`;
        history.replace(newUrl);
        return closePanel;
      }
    );
  };

  handleSlidePanelAvailability = (
    { setContent }: ContentPanelContextProps,
    projectId: string,
    expertId: string,
    referralId: string
  ): void => {
    setContent(
      <CIQExpertProfile
        projectId={projectId}
        expertId={expertId}
        referralId={referralId}
        showAvailabilityTab
      />,
      true,
      "panel-large"
    );
  };

  render(): JSX.Element {
    const { projectId, projectName } = this.state;
    const {
      isProjectLoading,
      isProjectNxLoading,
      hasCompliance,
      loggedInUserClientId,
      projectTimezone,
      projectData,
      client,
      match,
      location
    } = this.props;
    const { eventId } = match.params;

    if (isProjectLoading || isProjectNxLoading || !projectId) {
      return <Loader isFull />;
    }

    return (
      <div>
        <Switch>
          <SentryRoute path={PROJECT_CALENDAR} exact component={CalendarPage} />
          <SentryRoute
            key={PROJECT_BRIEF}
            path={PROJECT_BRIEF}
            component={LegacyProjectBriefPage}
          />
          <SentryRoute path={PROJECT_FILES} exact component={FilesPage} />
          <SentryRoute
            path={[PROJECT_CHAT_BASE, PROJECT_CHAT]}
            exact
            render={() => (
              <ContentPanelContextConsumer>
                {(
                  contentPanelContext: ContentPanelContextProps
                ): JSX.Element => (
                  <ChatPage
                    projectId={projectId}
                    client={client}
                    handleOpenExpertSidePanel={(
                      expertId: string,
                      referralId: string
                    ) =>
                      this.handleClickRow(
                        expertId,
                        referralId,
                        contentPanelContext
                      )
                    }
                  />
                )}
              </ContentPanelContextConsumer>
            )}
          />
          {loggedInUserClientId ? (
            <>
              {ClientProjectRouter}
              <SentryRoute
                path={[PROJECT_EVENT, PROJECT_NEW_EVENT]}
                exact
                component={() => (
                  // @ts-ignore
                  <EventPage
                    eventId={eventId}
                    eventPageUuid={location.state?.eventPageUuid}
                  />
                )}
              />
              <SentryRoute
                path={PROJECT_EXPERTS}
                exact
                render={() => (
                  <ContentPanelContextConsumer>
                    {(
                      contentPanelContext: ContentPanelContextProps
                    ): JSX.Element => (
                      <ExpertListPage
                        projectData={{
                          id: projectId,
                          name: projectName,
                          hasCompliance,
                          timezone: projectData?.timezone ?? projectTimezone,
                          isBain: loggedInUserClientId === bainId
                        }}
                        handleReviewNow={() =>
                          this.handleReviewNow(contentPanelContext)
                        }
                        handleClickRow={(
                          expertId: string,
                          referralId: string
                        ) =>
                          this.handleClickRow(
                            expertId,
                            referralId,
                            contentPanelContext
                          )
                        }
                        handleSlidePanelAvailability={(
                          projectId,
                          expertId,
                          referralId
                        ) =>
                          this.handleSlidePanelAvailability(
                            contentPanelContext,
                            projectId,
                            expertId,
                            referralId
                          )
                        }
                      />
                    )}
                  </ContentPanelContextConsumer>
                )}
              />
            </>
          ) : (
            ExpertProjectRouter
          )}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  ProjectContainerProps,
  ProjectContainerStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  projectName: ProjectSelector.projectNameSelector(),
  projectData: ProjectNxSelector.projectData(),
  client: AuthSelector.authClientSelector(),
  isProjectLoading: ProjectSelector.projectLoadingSelector(),
  isProjectNxLoading: ProjectNxSelector.isLoadingProjectData(),
  hasCompliance: ProjectSelector.projectHasComplianceSelector(),
  loggedInUserClientId: CacheSelector.loggedInUserClientId(),
  projectTimezone: ProjectSelector.projectTimezoneSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  getProject: (projectId: string): AppAction =>
    dispatch(ProjectStoreActions.getProject(projectId)),
  getProjectNx: (projectId: string): AppAction =>
    dispatch(ProjectNxStoreActions.loadProject(projectId)),
  exitFromProject: (): AppAction =>
    dispatch(ProjectStoreActions.exitFromProject())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTranslation("projectBasePage")(ProjectContainer));
