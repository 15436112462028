import { Button, Calendar, Card, Flex, Switch, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useFeature } from "flagged";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  EventService,
  GetEventResponse,
  ListEventsParams,
  ToasterService
} from "@arbolus-technologies/api";
import {
  FEATURE_FLAGS,
  SelectOption
} from "@arbolus-technologies/models/common";
import {
  PROJECT_CALENDAR_ROUTE_OLD,
  PROJECT_CREATE_EVENT_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { utilService } from "@arbolus-technologies/utils";

import { EventDetails } from "../../Components/EventDetails/EventDetails";
import { CalendarHeader } from "./CalendarAntDHeader";
import { EventCellRenderer } from "./EventCellRenderer";

import styles from "./CalendarAntDPage.module.scss";

// Constants
const START_EVER_DATE = utilService.convertActiveZoneToUTC(
  moment.utc("1970-01-01T00:00:00Z").toDate()
);
const EVENTS_LIMIT = 1000;

interface CalendarAntDPageProps {
  projectName: string;
  projectTimezone: string;
  timezones: Map<string, SelectOption>;
  currentTimeZone: SelectOption;
  eventService?: typeof EventService;
  notificationService?: ToasterService;
}

const { Text } = Typography;

export const CalendarAntDPage: React.FC<CalendarAntDPageProps> = ({
  projectName,
  projectTimezone,
  timezones,
  currentTimeZone,
  eventService = EventService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("calendarAntDPage");
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const calendarAntD = useFeature(FEATURE_FLAGS.NewCalendarAntD);

  const projectTimezoneLabel = `${timezones.get(projectTimezone)?.label} - ${t("projectTimezone")}`;
  const projectTimezoneValue = timezones.get(projectTimezone)?.value;

  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    projectTimezoneValue ?? ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [calendarReady, setCalendarReady] = useState(false);
  const [events, setEvents] = useState<GetEventResponse[]>([]);
  const [selectedEventId, setSelectedEventId] = useState("");

  const fetchEvents = () => {
    setIsLoading(true);
    const eventsPaginatedRequest: ListEventsParams = {
      limit: EVENTS_LIMIT,
      offset: 0,
      count: EVENTS_LIMIT,
      excludePastEvents: false,
      startDate: START_EVER_DATE
    };

    eventService.listEvents(projectId, eventsPaginatedRequest).subscribe(
      (response) => {
        setEvents(response.items as any);
        setIsLoading(false);
        setCalendarReady(true);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsLoading(false);
      }
    );
  };

  const handleTimezoneChange = (newTimezone: string) => {
    setSelectedTimezone(newTimezone);
  };

  const timezoneOptions = [
    {
      value: projectTimezoneValue ?? "",
      label: projectTimezoneLabel
    },
    {
      value: currentTimeZone.value ?? "",
      label: `${currentTimeZone.label} - ${t("yourTimezone")}`
    }
  ];

  const cellRenderer = (date: Dayjs) => (
    <EventCellRenderer
      date={date}
      events={events}
      selectedTimezone={selectedTimezone}
      onEventClick={setSelectedEventId}
    />
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchEvents();
  }, []);

  const handleCreateEventPressed = () => {
    history.push(PROJECT_CREATE_EVENT_ROUTE(projectId));
  };

  const onChange = () => {
    history.push(PROJECT_CALENDAR_ROUTE_OLD(projectId));
  };

  return (
    <Flex
      vertical
      style={{
        background: ARBOLUS_COLORS.bColorSecondaryGreyLight,
        minHeight: "100vh",
        overflowY: "auto"
      }}
    >
      {!isLoading && calendarReady && (
        <>
          <AntDHeader
            stickyHeader
            title={`${t("calendar")}: ${projectName}`}
            backLink={PROJECT_ROUTE(projectId).pathname}
            rightContainer={
              <Flex gap={16} align="center">
                {calendarAntD && (
                  <>
                    <Switch defaultChecked onChange={onChange} size="small" />
                    <Text>{t("newCalendar")}</Text>
                  </>
                )}

                <Button
                  type="primary"
                  onClick={handleCreateEventPressed}
                  icon={<AntDIcon name="add" />}
                >
                  {t("event")}
                </Button>
              </Flex>
            }
          />

          <Flex className={styles.mainContainer} gap={16}>
            <Card className={styles.mainCard}>
              <Calendar
                cellRender={cellRenderer}
                onPanelChange={setSelectedDate}
                value={selectedDate}
                className={styles.calendarAntD}
                headerRender={({ value, onChange }) => (
                  <CalendarHeader
                    value={value}
                    onChange={onChange}
                    timezoneOptions={timezoneOptions}
                    selectedTimezone={selectedTimezone}
                    onTimezoneChange={handleTimezoneChange}
                  />
                )}
              />
            </Card>

            {selectedEventId && (
              <Card style={{ flex: "1 1 25%" }}>
                <EventDetails
                  eventId={selectedEventId}
                  projectId={projectId}
                  timezones={timezones}
                  preselectedTimezoneValue={selectedTimezone}
                />
              </Card>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
