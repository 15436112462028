export enum ClientInvoiceStatus {
  NotInvoiced = "NotInvoiced",
  Invoiced = "Invoiced",
  Paid = "Paid"
}

export enum ExpertPaymentStatus {
  All = "All", // Only For FE
  Pending = "Pending",
  Paid = "Paid"
}

export enum TransactionStatus {
  InReview = "InReview",
  Approved = "Approved",
  Invoiced = "Invoiced"
}

export enum TransactionStatFilter {
  NotYetApproved = "NotYetApproved",
  NotYetInvoiced = "NotYetInvoiced",
  UnpaidInvoices = "UnpaidInvoices",
  UnpaidExperts = "UnpaidExperts"
}

export enum TransactionMode {
  Create = "create",
  Edit = "edit"
}

export enum TransactionType {
  IntroFee = "IntroFee",
  Call = "Call",
  RefundClient = "RefundClient",
  Survey = "Survey",
  ExpertSurvey = "ExpertSurvey",
  OfflineWork = "OfflineWork",
  RefundExpert = "RefundExpert",
  Canopy = "Canopy",
  ClientExpense = "ClientExpense",
  Referral = "Referral",
  InternalCanopy = "InternalCanopy"
}

export enum EventDurationUnit {
  MINUTE,
  HOURS
}
