import { LocationDescriptorObject } from "history";

import { Button } from "../Buttons/Button/Button";
import { LinkButton } from "../Buttons/Button/LinkButton";

interface TabLinkOption {
  text: string;
  route: LocationDescriptorObject | string;
  isActive: boolean;
  count?: number;
}

interface TabLinksProps {
  options: TabLinkOption[];
  size?: "small" | "large";
}

export const TabLinks = ({ options, size = "large" }: TabLinksProps) => (
  <div className="flex-row-8 flex-wrap">
    {options.map(({ isActive, text, route, count }) =>
      isActive ? (
        <div key={text} className="pe-none">
          <Button
            text={getLinkText(text, count)}
            type="tab"
            activeTab
            size={size}
          />
        </div>
      ) : (
        <LinkButton
          key={text}
          text={getLinkText(text, count)}
          to={route}
          type="tab"
          size={size}
        />
      )
    )}
  </div>
);

function getLinkText(text: string, count?: number) {
  return text + (Number.isInteger(count) ? ` · ${count}` : "");
}
