import { Checkbox, Flex } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import { Country } from "@arbolus-technologies/api";
import { DiscoverExpert } from "@arbolus-technologies/models/project";
import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { ExpertTitle } from "./ExpertTitle";

import styles from "./ExpertCard.module.scss";

interface ExpertAvatarProps {
  expert: DiscoverExpert;
  projectId: string;
  projectName: string;
  expertCountry?: Country;
  disabled: boolean;
  selectedExpertsIds: string[];
  handleSelectExpert: (expertId: string) => void;
}

export const ExpertAvatar: React.FC<ExpertAvatarProps> = ({
  expert,
  projectId,
  projectName,
  expertCountry,
  disabled,
  selectedExpertsIds,
  handleSelectExpert
}) => {
  const { t } = useTranslation("discoverPage");
  const searchParams = new URLSearchParams({ projectId, projectName });

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const {
    id,
    fullName,
    lastPublicCompanyDate,
    profileImageUrl,
    workHistories,
    isOnboarded,
    linkedInProfile,
    isVerified
  } = expert;

  const AVATAR = (
    <Link
      to={{
        pathname: EXPERT_PROFILE(id).pathname,
        search: `?${searchParams.toString()}`
      }}
      target="_blank"
      className={styles.avatar}
    >
      <AntDAvatar
        firstName={fullName}
        lastName=""
        profileImageUrl={profileImageUrl ?? undefined}
        lastPublicCompanyExpDate={lastPublicCompanyDate}
        countryName={expertCountry?.name ?? ""}
        countryTwoLetterCode={expertCountry?.twoLetterCode ?? ""}
        composedTitle={
          <ExpertTitle expertWorkHistory={(workHistories ?? [])[0]} />
        }
        linkedInProfile={linkedInProfile}
        isVerified={isVerified}
        icon={
          isOnboarded
            ? {
                name: "check",
                fontSize: "12px",
                backgroundColor: ARBOLUS_COLORS.bColorSecondaryGreen,
                tooltip: t("onboardedExpert")
              }
            : undefined
        }
        size="large"
      />
    </Link>
  );

  return isAdmin ? (
    <Flex gap={16}>
      <Checkbox
        checked={selectedExpertsIds.includes(expert.id)}
        onChange={() => handleSelectExpert(expert.id)}
        disabled={disabled}
      />
      {AVATAR}
    </Flex>
  ) : (
    AVATAR
  );
};
