import { Button, Dropdown } from "antd";
import { useFeature } from "flagged";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AntDButtonLink, AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  ArbolusGPTButton,
  ExpertInvitePanel,
  MixPanelEventNames,
  SlidePanel,
  useArbolusTracking,
  useIsChatAvailable
} from "@arbolus-technologies/features/common";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import {
  NEW_EXPERT,
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_CREATE_SURVEY_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { NotificationDot } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import styles from "./HeaderButtons.module.scss";

interface HeaderButtonProps {
  projectId: string;
  unreadMessagesCount?: number;
}

export const HeaderButtons: React.FC<HeaderButtonProps> = ({
  projectId,
  unreadMessagesCount
}) => {
  const { t } = useTranslation("projectDashboard");
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackRedirect, trackClick } = useArbolusTracking();
  const SurveysFF = useFeature(FEATURE_FLAGS.Surveys);

  const projectName = useSelector(ProjectNxSelector.projectName());
  const loggedUserMail = useSelector(CacheSelector.userProfile()).email;
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const chatUrl = PROJECT_CHAT_BASE_ROUTE(projectId);
  const displayChatButton = useIsChatAvailable();
  const hasUnreadMessages =
    typeof unreadMessagesCount !== "undefined" && unreadMessagesCount > 0;
  const showNotificationDot = displayChatButton && hasUnreadMessages;

  const openExpertsPanel = () => {
    dispatch(PanelStoreActions.openPanel(PanelId.AssignExperts));
  };

  const dropDownItems = [
    {
      key: "canopy",
      label: t("canopy"),
      onClick: () =>
        dispatch(PanelStoreActions.openPanel(PanelId.CreateSimpleCanopy))
    },
    {
      key: "survey",
      label: t("survey"),
      onClick: () => {
        history.push(PROJECT_CREATE_SURVEY_ROUTE(projectId));
      }
    }
  ];

  return (
    <>
      <div className={styles.btnContainer}>
        <ArbolusGPTButton
          projectPath={`${PROJECT_ROUTE(projectId).pathname} | ${projectName}>`}
          onTrack={() =>
            trackClick(MixPanelEventNames.ProjectDashboardArbolusGPTTest, {
              loggedUserMail
            })
          }
          projectId={projectId}
        />
        {displayChatButton && (
          <div className={styles.chatContainer}>
            {showNotificationDot && (
              <NotificationDot
                offsetTop="-4px"
                offsetRight="-4px"
                size="10px"
              />
            )}
            <AntDButtonLink
              to={chatUrl}
              onClick={() =>
                trackRedirect(
                  MixPanelEventNames.ProjectDashboardTopChat,
                  chatUrl,
                  {
                    component: "Chat icon"
                  }
                )
              }
              icon={<AntDIcon name="forum" />}
            />
          </div>
        )}
        {SurveysFF && isAdmin && (
          <Dropdown menu={{ items: dropDownItems }}>
            <Button
              iconPosition="end"
              icon={<AntDIcon name="keyboard_arrow_down" />}
            >
              {t("new")}
            </Button>
          </Dropdown>
        )}
        <Button
          type="primary"
          onClick={openExpertsPanel}
          icon={<AntDIcon name="add" />}
        >
          {t("addExperts")}
        </Button>
      </div>
      <SlidePanel
        panelId={PanelId.AssignExperts}
        width={SIDE_PANEL_SIZE._400}
        closeButtonDirection="right"
        title={t("addExperts")}
        hideHeader
        noPadding
      >
        <ExpertInvitePanel
          projectId={projectId}
          createExpertRoute={NEW_EXPERT}
        />
      </SlidePanel>
    </>
  );
};
