import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { PanelId, PanelSelector } from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SlidePanel } from "../../SlidePanel/SlidePanel";
import { CustomerSurveyExpertProfile } from "./components/CustomerSurveyExpertProfile";

export const CustomerSurveyExpertProfileSidePanel: React.FC = () => {
  const { t } = useTranslation("referralsTableExpertProfileSidePanel");

  const panelData = useSelector(
    PanelSelector.panelData<{ expertId: string }>(
      PanelId.CustomerSurveyExpertPanel
    )
  );

  return (
    <SlidePanel
      panelId={PanelId.CustomerSurveyExpertPanel}
      width={SIDE_PANEL_SIZE._720}
      title={t("profile")}
      noPadding
    >
      <CustomerSurveyExpertProfile expertId={panelData?.expertId} />
    </SlidePanel>
  );
};
