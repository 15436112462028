import { useFormContext } from "react-hook-form";

import { CheckboxController } from "@arbolus-technologies/ui/components";

import styles from "./VendorsCheckboxes.module.scss";

export type Options = { label: string; name: string }[];

export function VendorsCheckboxes({ options }: { options: Options }) {
  const { control } = useFormContext();

  return (
    <div className={styles.options}>
      {options.map((option) => (
        <CheckboxController
          key={option.label}
          control={control}
          name={option.name}
          errors={{}}
          text={option.label}
        />
      ))}
    </div>
  );
}
