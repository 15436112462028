import React from "react";
import { useSelector } from "react-redux";

import { ExpertDetail, ReferralNote } from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { WorkHistories } from "../../../../shared/WorkHistories/WorkHistories";
import { AdminProjectNotes } from "./AdminProjectNotes";
import { CallPrice } from "./CallPrice";
import { ClientProjectNotes } from "./ClientProjectNotes";
import { Endorsements } from "./Endorsements";

interface ProfileProps {
  expert: ExpertDetail;
  note?: ReferralNote;
  referralId: string;
  editNote?: boolean;
  setEditNote?: (edit: boolean) => void;
  newNote?: string;
  setNewNote?: (note: string) => void;
  onSaveNote?: (note?: string) => void;
}

export const Profile: React.FC<ProfileProps> = ({
  expert,
  note,
  referralId,
  editNote = false,
  setEditNote = () => {},
  newNote = "",
  setNewNote = () => {},
  onSaveNote
}) => {
  const isAdmin = useSelector(CacheSelector.isAdmin());

  return (
    <>
      {isAdmin && (note?.note || editNote) && (
        <AdminProjectNotes
          note={note}
          customers={expert.customers}
          expertId={expert.id}
          referralId={referralId}
          currentWorkHistoryId={
            expert.workHistories.find((wh) => wh.isCurrentWork)?.id
          }
          editNote={editNote}
          setEditNote={setEditNote}
          newNote={newNote}
          setNewNote={setNewNote}
          onSaveNote={onSaveNote}
        />
      )}
      {!isAdmin && note?.note && (
        <ClientProjectNotes note={note} customers={expert.customers} />
      )}
      <CallPrice expert={expert} />
      {expert.workHistories.length > 0 && (
        <WorkHistories
          workHistories={expert.workHistories}
          lastPublicCompanyExpDate={expert.lastPublicCompanyExpDate}
        />
      )}
      {expert.endorsements.length > 0 && (
        <Endorsements endorsements={expert.endorsements} />
      )}
    </>
  );
};
