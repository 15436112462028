import { Collapse, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { COMPANY_TYPE, DateString } from "@arbolus-technologies/models/common";
import { WorkHistory } from "@arbolus-technologies/models/expert";
import {
  DangerouslySetInnerHTML,
  PublicCompanyBadge
} from "@arbolus-technologies/ui/components";
import {
  EXPERT_WORK_TIME_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";
import dayjs from "dayjs";

const { Text } = Typography;

interface WorkHistoryCardProps {
  workHistoryItem: WorkHistory;
  lastPublicCompanyExpDate: DateString | null;
  index?: number;
}

export const WorkHistoryCard: React.FC<WorkHistoryCardProps> = ({
  workHistoryItem,
  lastPublicCompanyExpDate,
  index
}) => {
  const { t } = useTranslation("referralWorkHistory");

  const companyName =
    workHistoryItem.company?.name ?? t(workHistoryItem.employedStatus);
  const country = workHistoryItem.country?.name;
  const displayPublicCompanyBadge =
    lastPublicCompanyExpDate !== null &&
    workHistoryItem.company?.type === COMPANY_TYPE.Public;

  const startDate = (date: Date) => formatDate(date, EXPERT_WORK_TIME_FORMAT);
  const endDate = (date: Date | null) =>
    date ? formatDate(date, EXPERT_WORK_TIME_FORMAT) : t("current");

  const workHistoryDescription = [
    workHistoryItem.title ?? "",
    `${startDate(workHistoryItem.from)} - ${endDate(workHistoryItem.to)}`,
    country ?? ""
  ];

  return (
    <Collapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <AntDIcon name={`${isActive ? "arrowUp" : "arrowDown"}`} />
      )}
      defaultActiveKey={`${dayjs(workHistoryItem.from).format("llll")}_0_0`}
      items={[
        {
          key: `${dayjs(workHistoryItem.from).format("llll")}_${index ?? 0}_0`,
          label: (
            <Flex vertical>
              <Flex gap={8}>
                <Text strong>{companyName}</Text>
                {displayPublicCompanyBadge && (
                  <PublicCompanyBadge date={workHistoryItem.to} />
                )}
              </Flex>
              <Flex gap={4} wrap>
                <Text ellipsis>
                  {workHistoryDescription.filter(Boolean).join(" | ")}
                </Text>
              </Flex>
            </Flex>
          ),
          children: (
            <DangerouslySetInnerHTML
              text={
                workHistoryItem.jobDescription ?? t("descriptionNotAvailable")
              }
            />
          )
        }
      ]}
    />
  );
};
