import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "reactstrap";

import {
  CreateAccountWithLinkedIn,
  RECAPTCHA_SITE_KEY
} from "@arbolus-technologies/auth";
import { SSOPage } from "@arbolus-technologies/features/common";
import {
  CanopyPaused,
  CanopyWelcome
} from "@arbolus-technologies/features/expert-canopy";
import { AppSelector as AppSelectorNx } from "@arbolus-technologies/stores/app";

import { useSelector } from "react-redux";
import { PublicRouteConstants } from "../../constants";
import { SentryRoute } from "../../sentry";
import { withPublicAccess } from "../app/components";
import ExpertEndorsementPage from "./pages/endorsement/ExpertEndorsementPage";
import FederatedRedirectPage from "./pages/federate/FederatedRedirectPage";
import ForgotPasswordPage from "./pages/forgot/ForgotPasswordPage";
import LoginPage from "./pages/login/LoginPage";
import RegisterPage from "./pages/register/RegisterPage";
import ResetPasswordPage from "./pages/reset/ResetPasswordPage";

function AuthContainer() {
  const { LinkedInAuth } = useSelector(AppSelectorNx.getFeatureFlags());

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY!}>
      <Container className="login-container">
        <SentryRoute
          path={PublicRouteConstants.SSO}
          exact
          component={SSOPage}
        />
        <SentryRoute
          exact
          path={PublicRouteConstants.LOGIN}
          component={LoginPage}
        />
        <SentryRoute
          path={PublicRouteConstants.REGISTER}
          exact
          render={(props: RouteComponentProps): JSX.Element => (
            <RegisterPage {...props} />
          )}
        />
        {/* User wouldn't visit this directly - only redirected from LI */}
        {LinkedInAuth && (
          <SentryRoute
            path={PublicRouteConstants.REGISTER_WITH_LI}
            exact
            component={CreateAccountWithLinkedIn}
          />
        )}
        <SentryRoute
          path={PublicRouteConstants.FORGOT_PASSWORD}
          exact
          // @ts-ignore
          component={ForgotPasswordPage}
        />
        <SentryRoute
          path={PublicRouteConstants.RESET_PASSWORD}
          exact
          component={ResetPasswordPage}
        />
        <SentryRoute
          path={PublicRouteConstants.FEDERATED_REDIRECT}
          exact
          component={FederatedRedirectPage}
        />
        <SentryRoute
          path={PublicRouteConstants.ENDORSEMENT}
          exact
          component={ExpertEndorsementPage}
        />
        <SentryRoute
          exact
          path={PublicRouteConstants.WELCOME_CANOPY}
          component={CanopyWelcome}
        />
        <SentryRoute
          exact
          path={PublicRouteConstants.CANOPY_PAUSED_PUBLIC}
          component={CanopyPaused}
        />
      </Container>
    </GoogleReCaptchaProvider>
  );
}

export default withPublicAccess(AuthContainer);
