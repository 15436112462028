import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import { MonitoringListModel } from "@arbolus-technologies/api";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";

export const ClientUserRenderer: React.FC<
  ICellRendererParams<MonitoringListModel, never, never>
> = ({ data }) => {
  if (!data?.clientUserFirstName || !data?.clientUserLastName) {
    return null;
  }

  const props = {
    profileImageUrl: data?.clientUserProfileImage ?? undefined,
    firstName: data?.clientUserFirstName,
    lastName: data?.clientUserLastName
  };

  return <AntDAvatar {...props} size="small" />;
};
