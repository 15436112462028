import i18next from "i18next";
import * as Yup from "yup";

import { ScreeningQuestionConstraints } from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

const questionSchema: Yup.ObjectSchema<{ question: string }> = Yup.object({
  question: Yup.string()
    .transform((value) => value ?? "")
    .test(
      "max-length-check",
      i18next.t("viewEditAngle:maxLength", {
        length: ScreeningQuestionConstraints.MAX_QUESTION_LENGTH
      }),
      (value) => {
        const htmlStrippedText = utilService.getStrippedTextFromHtml(
          value ?? ""
        );

        if (
          htmlStrippedText.length >
          ScreeningQuestionConstraints.MAX_QUESTION_LENGTH
        ) {
          return false;
        }
        return true;
      }
    )
    .test("required", "Required", (value) => {
      const htmlStrippedText = utilService.getStrippedTextFromHtml(value ?? "");

      if (htmlStrippedText.length === 0) {
        return false;
      }
      return true;
    })
});

export const AngleScreeningQuestionSchema = Yup.object().shape({
  questions: Yup.array().of(questionSchema)
});
