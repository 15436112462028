import { Button } from "arbolus-ui-components";
import React, { ReactNode } from "react";
import ReactHtmlParser from "react-html-parser";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import {
  AuthPageHeader,
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import { UserRole } from "@arbolus-technologies/models/common";
import { Loader } from "@arbolus-technologies/ui/components";

import { AppConstants, PublicRouteConstants } from "../../../../../constants";
import {
  RECAPTCHA_ACTIONS,
  REFERRER_TYPE
} from "../../../../../constants/auth";
import { USER_INVITATION_TYPE } from "../../../../../constants/user";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import {
  Invitation,
  RegisterRequest,
  Terms,
  User,
  UserRegisterResponse
} from "../../../../../models/user";
import { UserService } from "../../../../../services";

const notification = new ToasterService();

interface AcceptTermsProps {
  token?: string;
  email?: string;
  inviteToken?: string;
  signUpType?: string;
  onError: (email?: string, oldToken?: string) => void;
  onTermsAccept: (
    isClient: boolean,
    termsId: string,
    isEmailInvite: boolean,
    user?: User,
    invitationType?: Invitation,
    clientName?: string,
    isCanopySignUp?: boolean
  ) => void;
  executeRecaptcha: (action: string) => Promise<string>;
}

interface AcceptTermsState {
  isLoading: boolean;
  terms: Terms;
}

type AcceptTermsIntersectProps = AcceptTermsProps & WithTranslation;

class AcceptTerms extends React.Component<
  AcceptTermsIntersectProps,
  AcceptTermsState
> {
  constructor(props: AcceptTermsIntersectProps) {
    super(props);
    this.state = {
      isLoading: true,
      terms: {} as Terms
    };
  }

  componentDidMount(): void {
    const { t } = this.props;

    this.fetchTerms();
    document.title = t("acceptTerms");
  }

  componentWillUnmount(): void {
    this.userRegisterFetchSubscription?.unsubscribe();
  }

  private inviteUser?: User;

  private accountType?: string;

  private clientName?: string;

  private invitation?: Invitation;

  fetchTerms = async (): Promise<void> => {
    const { email, token, onError, executeRecaptcha, t, inviteToken } =
      this.props;

    try {
      const recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTIONS.REGISTER);

      const registrationRequest: RegisterRequest = {
        email,
        token,
        recaptchaToken,
        invitationCode: inviteToken
      };

      this.userRegisterFetchSubscription = UserService.userRegistration(
        registrationRequest
      ).subscribe(
        (response: UserRegisterResponse) => {
          const { terms, accountType, client, user, invitation } = response;

          this.inviteUser = {
            ...user!,
            isCiqManager: client?.subdomain.includes("ciq") || false
          };
          this.accountType = accountType;
          this.clientName = client?.name || invitation?.clientName;
          this.invitation = invitation;

          trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
            action: MixPanelActions.DataLoaded,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email,
            invitationCode: inviteToken,
            accountType: invitation ? accountType : UserRole.expert,
            phoneNumber: user?.phoneNumber
          });

          this.setState({
            isLoading: false,
            terms
          });
        },
        (error: ErrorResponse<CIQError>) => {
          this.setState(
            {
              isLoading: false
            },
            () => {
              onError(email, token);
              notification.showError(error.message);

              trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
                action: MixPanelActions.DataLoadFailed,
                email,
                invitationCode: inviteToken
              });
            }
          );
        }
      );
    } catch (err) {
      this.setState(
        {
          isLoading: false
        },
        () => {
          onError(email, token);
          notification.showError(t("errorInTerms"));

          trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
            action: MixPanelActions.DataLoadFailed,
            email,
            invitationCode: inviteToken
          });
        }
      );
    }
  };

  private userRegisterFetchSubscription?: Subscription;

  handleAcceptTerms = (): void => {
    const { onTermsAccept, email, signUpType, inviteToken } = this.props;
    const { terms } = this.state;

    let isClientAccount =
      this.accountType === AppConstants.APP_USER_ROLES.client;

    if (this.invitation) {
      const { invitationType } = this.invitation;
      if (invitationType === USER_INVITATION_TYPE.CLIENT_TEAM) {
        isClientAccount = true;
      }
    }

    let isCanopySignUp = false;
    if (signUpType === "canopy") {
      isCanopySignUp = true;
    }

    trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
      action: MixPanelActions.Accepted,
      firstName: this.inviteUser?.firstName,
      lastName: this.inviteUser?.lastName,
      email,
      invitationCode: inviteToken,
      accountType: this.invitation ? this.accountType : UserRole.expert,
      phoneNumber: this.inviteUser?.phoneNumber
    });

    onTermsAccept(
      isClientAccount,
      terms.id,
      Boolean(email),
      this.inviteUser,
      this.invitation,
      this.clientName,
      isCanopySignUp
    );
  };

  renderMessagePanel = (): JSX.Element => {
    const { NETWORK, CLIENT_TEAM } = USER_INVITATION_TYPE;
    const { EXPERT } = REFERRER_TYPE;
    const { client, expert } = AppConstants.APP_USER_ROLES;
    const isClientAccount = this.accountType === client;
    const { email, inviteToken } = this.props;
    const { firstName, lastName, phoneNumber } = this.inviteUser || {};

    const mixpanelProperties = {
      firstName,
      lastName,
      email,
      invitationCode: inviteToken,
      accountType: this.accountType,
      phoneNumber
    };

    // Client User Invite
    if (isClientAccount) {
      trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
        referralType: "Client User Invite",
        ...mixpanelProperties,
        accountType: client
      });

      return (
        <Trans
          ns="register"
          i18nKey="clientInviteClient"
          values={{ clientName: this.clientName }}
          components={[<span key="1" />]}
        />
      );
    }

    if (this.invitation) {
      const { invitationType, referrerName, referrerType } = this.invitation;
      const isExpertInvite = referrerType === EXPERT;

      // Expert Refer Expert
      if (isExpertInvite) {
        trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
          referralType: "Expert Refer Expert",
          ...mixpanelProperties
        });

        return (
          <Trans
            ns="register"
            i18nKey="expertReferInviteExpert"
            values={{ referrerName }}
            components={[<br key="0" />, <span key="1" />]}
          />
        );
      }

      // Client Invite Client To Team
      if (invitationType === CLIENT_TEAM) {
        trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
          referralType: "Client Invite Client To Team",
          ...mixpanelProperties
        });

        return (
          <Trans
            ns="register"
            i18nKey="clientInviteClient"
            values={{ clientName: this.clientName }}
            components={[<span key="1" />]}
          />
        );
      }

      // Client Invite Expert To Network
      if (invitationType === NETWORK) {
        trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
          referralType: "Client Invite Expert To Network",
          ...mixpanelProperties
        });

        return (
          <Trans
            ns="register"
            i18nKey="clientNetworkInviteExpert"
            values={{ clientName: this.clientName }}
            components={[<br key="1" />, <span key="2" />]}
          />
        );
      }

      // Client Invite Expert to project
      trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
        referralType: "Client Invite Expert to project",
        ...mixpanelProperties
      });

      return (
        <Trans
          ns="register"
          i18nKey="clientProjectInviteExpert"
          components={[<br key="1" />, <span key="2" />]}
        />
      );
    }

    // Expert Self On-boarding
    trackEvent(MixPanelEventNames.UserRegistrationAcceptTerms, {
      referralType: "Expert Self On-boarding",
      ...mixpanelProperties,
      accountType: expert
    });

    return (
      <Trans
        ns="register"
        i18nKey="expertSelfOnBoarding"
        components={[<span key="1" />]}
      />
    );
  };

  render(): JSX.Element {
    const { isLoading, terms } = this.state;
    const { t } = this.props;
    const currentQueryString = window.location.search;

    if (isLoading) {
      return <Loader isFull />;
    }

    return (
      <div className="terms-container">
        <AuthPageHeader title={t("ourTerms")} />

        <h3>{this.renderMessagePanel()}</h3>

        <div>{ReactHtmlParser(terms.text) as ReactNode}</div>

        <div className="accept-terms-submit">
          <Button onClick={this.handleAcceptTerms} text={t("accept&Proceed")} />
        </div>

        <div className="accept-terms-footer">
          <Trans
            ns="register"
            i18nKey="alreadyHaveAccount"
            components={[
              <Link
                key="0"
                to={{
                  pathname: PublicRouteConstants.LOGIN,
                  search: currentQueryString
                }}
              />
            ]}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("register")(AcceptTerms);
