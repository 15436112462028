import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { IconWithText } from "../IconWithText/IconWithText";

export function TextWithGreenTick({
  content,
  checked
}: { content: string; checked: boolean }) {
  return (
    <IconWithText
      icon={{
        name: checked ? "check_circle" : "circle",
        color: checked
          ? ARBOLUS_COLORS.bColorSecondaryGreen
          : ARBOLUS_COLORS.bColorSecondaryGreyDark
      }}
      text={{ content }}
    />
  );
}
