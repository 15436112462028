import { Flex, Result, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { EXPERT_DETAILS_EDIT_TAB } from "@arbolus-technologies/routes";

import styles from "./GiftCardThanksScreen.module.scss";

const { Text } = Typography;

export const GiftCardThanksScreen: React.FC = () => {
  const { t } = useTranslation("giftCardThanksScreen");
  const { trackRender, trackClick } = useArbolusTracking();

  useEffect(() => {
    trackRender(MixPanelEventNames.SurveyPaymentGiftCardThankYou);
  }, []);

  return (
    <Flex justify="center" align="center" className={styles.container}>
      <Result
        status="success"
        title={t("thankYou")}
        className={styles.result}
        subTitle={
          <Flex vertical gap={24}>
            <Flex vertical>
              <Text>{t("thankYouText_1")}</Text>
              <Text strong>{t("thankYouText_2")}</Text>
            </Flex>
            <Text>{t("thankYouText_3")}</Text>
          </Flex>
        }
        extra={
          <AntDButtonLink
            type="primary"
            pathname={EXPERT_DETAILS_EDIT_TAB}
            text={t("improveYourProfile")}
            onClick={() =>
              trackClick(MixPanelEventNames.SurveyPaymentGiftCardProfile)
            }
          />
        }
      />
    </Flex>
  );
};
