import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { FULL_DATETIME_FORMAT } from "@arbolus-technologies/utils";
import { GridApi } from "ag-grid-community";
import { Button, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styles from "./ExportCSVButton.module.scss";

interface ExportCSVButtonProps {
  api: GridApi;
  tableName: string;
}

export function ExportCSVButton({ api, tableName }: ExportCSVButtonProps) {
  const { t } = useTranslation("companyData");
  const { trackClick } = useArbolusTracking();
  function exportData() {
    api.exportDataAsCsv({
      fileName: `${tableName}_${moment().format(FULL_DATETIME_FORMAT)}`
    });
    trackClick(MixPanelEventNames.MonitoringListCompanyDataExport, {
      tableName
    });
  }

  return (
    <>
      {tableName === t("companyData") && (
        <Typography.Text type="secondary" className={styles.headerName}>
          {t("lastUpdate")}
        </Typography.Text>
      )}
      <div className={styles.exportButton}>
        <Button onClick={exportData} size="small">
          {t("export")}
        </Button>
      </div>
    </>
  );
}
