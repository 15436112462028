export enum TRANSCRIPT_STATUS {
  NO_TRANSCRIPT = "NoTranscript",
  AUTOMATIC_TRANSCRIPT = "AutomaticTranscript",
  SMART_TRANSCRIPT = "SmartTranscript"
}

export enum TRANSCRIPT_EDIT_MODE {
  VIEW = "view",
  EDIT = "edit"
}

export enum TRANSCRIPTS_MODE {
  QUESTIONS_MODE = "questionsMode",
  EXPERTS_MODE = "expertsMode"
}

export enum CURRENCIES_SYMBOLS {
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP"
}

export enum EXPERT_PRICING_TYPE {
  CREDIT_PACKAGE = "CreditPackage",
  INTRODUCTORY_FEE_MARKUP = "IntroductoryFeeMarkup"
}

export enum APP_TRACKING_ROUTES {
  ACCOUNT = "account",
  BOOK = "book",
  FOLLOW_UP = "follow_up",
  CHAT = "chat",
  DASHBOARD = "dashboard",
  DASHBOARD_PANEL = "dashboard_panel",
  DISCOVER = "discover",
  EXPERT_AVAILABILITY = "expert_availability",
  FIRST_PAGE = "first_page",
  INBOX = "inbox",
  NETWORK = "network",
  PROJECT_CALENDAR = "project_calendar",
  PROJECTS = "projects",
  SHARE_BOOKING_LINK = "SharedBookingLink",
  TEAM_MEMBERS = "team_members",
  REGISTER = "register",
  SURVEY_QUALIFIED = "survey-qualified",
  SURVEY_NOT_QUALIFIED = "survey-not-qualified",
  SURVEY_FEEDBACK = "survey-feedback"
}

export enum REFERRAL_SUB_STATE {
  PENDING = "Pending",
  ACCEPT = "Accept",
  REJECT = "Reject",
  UI_NOT_REQUIRED = "NotRequired" // Only used for UI rendering
}

export enum REFERRAL_COMPLIANCE_STATE {
  PENDING = "Pending",
  ACCEPT = "Accept",
  REJECT = "Reject"
}

export enum REFERRAL_STATUS {
  SHORTLIST = "Shortlist",
  CANDIDATE = "Candidate",
  ACCEPT = "Accept",
  REJECT = "Reject"
}
