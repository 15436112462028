import { Button, Flex } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { AttachmentsPanel } from "@arbolus-technologies/features/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { useEventAttachments } from "../../../../../Hooks/Event/useEventAttachments";
import { AttachmentsList } from "./AttachmentsList";

interface AttachmentsProps {
  projectId: string;
}

export const Attachments: React.FC<AttachmentsProps> = ({ projectId }) => {
  const { t } = useTranslation("eventForm");

  const {
    attachments,
    fileInputRef,
    handleAddAttachments,
    handleCloseAttachmentsPanel,
    handleIsAttachmentsPanelOpen,
    handleRefetchDocuments,
    handleRemoveAttachment,
    handleSelectPreselectedAttachment,
    isAttachmentsPanelOpen,
    isUploadingFiles,
    preselectedAttachments,
    refetchDocuments
  } = useEventAttachments({ projectId });

  return (
    <>
      <AttachmentsPanel
        attachments={attachments}
        handleAddAttachments={handleAddAttachments}
        handleSelectPreselectedAttachment={handleSelectPreselectedAttachment}
        handleCloseAttachmentsPanel={handleCloseAttachmentsPanel}
        isOpen={isAttachmentsPanelOpen}
        projectId={projectId}
        preselectedAttachments={preselectedAttachments}
        fileInputRef={fileInputRef}
        isUploading={isUploadingFiles}
        handleRefetchDocuments={handleRefetchDocuments}
        refetchDocuments={refetchDocuments}
      />
      <Flex vertical gap={16}>
        <AttachmentsList
          attachments={attachments}
          handleRemoveAttachment={handleRemoveAttachment}
        />
        <Button
          onClick={() => handleIsAttachmentsPanelOpen(true)}
          style={{ maxWidth: "fit-content" }}
          iconPosition="start"
          icon={
            <AntDIcon
              name="add"
              fontSize="16px"
              color={ARBOLUS_COLORS.colorIcon}
            />
          }
        >
          {t("add")}
        </Button>
      </Flex>
    </>
  );
};
