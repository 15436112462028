import { Tag } from "antd";

export function NPSCellRenderer({
  data
}: {
  data: { nps: number };
}) {
  const { nps } = data;

  if (!nps) return "N/A";

  let color;
  if (nps >= 9) color = "green";
  if (nps <= 6) color = "red";

  return <Tag color={color}>{nps}</Tag>;
}
