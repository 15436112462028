import {
  Button,
  Card,
  Col,
  Flex,
  Progress,
  Row,
  Space,
  Tag,
  Typography
} from "antd";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Vendor } from "@arbolus-technologies/models/common";
import { EXPERT_EXPERIENCE_EDIT_TAB } from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { useIsMobile } from "@arbolus-technologies/utils";

import styles from "./CurrentPositionItem.module.scss";

const { Text } = Typography;

interface CurrentPositionItemProps {
  expertCompanyName: string;
  jobTitle: string;
  workHistoryId: string;
  vendors: Vendor[];
  isCurrentJob: boolean;
  onRemoveVendor: (workHistoryId: string, vendor: Vendor) => void;
}

export const CurrentPositionItem: React.FC<CurrentPositionItemProps> = ({
  expertCompanyName,
  jobTitle,
  workHistoryId,
  vendors,
  isCurrentJob,
  onRemoveVendor
}) => {
  const { t } = useTranslation("expertInsightsSection");
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();

  const openSlidePanel = () =>
    dispatch(
      PanelStoreActions.openPanel(PanelId.AddVendor, {
        companyId: "",
        vendors,
        workHistoryId
      })
    );

  const TitleCard = () => (
    <>
      {isCurrentJob && (
        <Row gutter={[16, 8]} className={styles.headerWrapper}>
          <Col xs={24} sm={24} md={12}>
            <Flex vertical>
              <Text strong>{expertCompanyName}</Text>
              <span className={styles.jobTitle}>{jobTitle}</span>
            </Flex>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Flex gap={8} justify={isMobile ? "flex-start" : "flex-end"}>
              <Button onClick={() => history.push(EXPERT_EXPERIENCE_EDIT_TAB)}>
                {t("updatePosition")}
              </Button>
              <Button type="primary" onClick={openSlidePanel}>
                {t("addVendors")}
              </Button>
            </Flex>
          </Col>
        </Row>
      )}

      <Flex gap={8} wrap="wrap" className={styles.headerBottomWrapper}>
        {vendors?.map((vendor) => (
          <Tag
            key={vendor.vendorCompanyId}
            closeIcon={isCurrentJob}
            onClose={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              return onRemoveVendor(workHistoryId, vendor);
            }}
            className={styles.tag}
          >
            {vendor.vendorCompanyName}
          </Tag>
        ))}
      </Flex>
    </>
  );

  return (
    <Card
      title={<TitleCard />}
      className={clsx(!isCurrentJob && styles.olderPositionCard)}
    >
      {isCurrentJob && (
        <Flex gap={16} align="center">
          <Progress
            type="circle"
            size={30}
            strokeColor={ARBOLUS_COLORS.bColorBasePurple}
            trailColor={ARBOLUS_COLORS.colorBgLayout}
            strokeWidth={12}
            steps={{ count: 100, gap: 0 }}
            percent={vendors.length * 20}
            showInfo={false}
          />
          <Text>{t("iconFeedbackText")}</Text>
        </Flex>
      )}
    </Card>
  );
};
