import { Avatar, Badge, Flex, Image, Tooltip, Typography } from "antd";
import React, { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  AngleModel,
  DO_NOT_CONTACT_STATUS,
  DateString
} from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS, PALETTE, linkedin } from "@arbolus-technologies/theme";
import { DNCTag, PublicCompanyIcon } from "@arbolus-technologies/ui/components";
import { twoLetterName } from "@arbolus-technologies/utils";

import { AntDAngleTag } from "../AngleTag/AntDAngleTag";
import { AntDIcon, IconName } from "../Icon/AntDIcon";

import styles from "./AntDAvatar.module.scss";

const { Text } = Typography;

export interface AntDAvatarProps {
  profileImageUrl?: string;
  firstName?: string;
  lastName?: string;
  onlyAvatar?: boolean;
  size?: number | "large" | "small" | "default";
  title?: string;
  composedTitle?: ReactNode;
  color?: PALETTE;
  isVerified?: boolean;
  lastPublicCompanyExpDate?: DateString | null;
  linkedInProfile?: string;
  countryTwoLetterCode?: string | null;
  countryName?: string;
  angle?: AngleModel;
  icon?: {
    name: IconName;
    color?: string;
    fontSize?: string;
    backgroundColor?: string;
    tooltip?: string;
  };
  mainGap?: number;
  doNotContactStatus?: DO_NOT_CONTACT_STATUS;
  isAdmin?: boolean;
  showTitleTooltip?: boolean;
}

export const AntDAvatar: React.FC<AntDAvatarProps> = ({
  profileImageUrl,
  firstName = "",
  lastName = "",
  title,
  composedTitle,
  color,
  isVerified = false,
  lastPublicCompanyExpDate,
  linkedInProfile,
  countryTwoLetterCode,
  countryName,
  size = "default",
  angle,
  onlyAvatar,
  icon,
  mainGap = 8,
  doNotContactStatus,
  isAdmin = false,
  showTitleTooltip = true
}) => {
  const { t } = useTranslation("avatar");

  const renderAvatar = useMemo(() => {
    const avatarContent = profileImageUrl ? (
      <Avatar
        src={profileImageUrl}
        size={size}
        style={{ backgroundColor: color }}
      />
    ) : (
      <Avatar size={size}>{twoLetterName(firstName, lastName)}</Avatar>
    );

    return (
      <Badge
        count={
          icon ? (
            <AntDIcon
              name={icon.name}
              tooltip={icon.tooltip}
              fontSize={icon.fontSize ?? "16px"}
              color={icon.color ?? ARBOLUS_COLORS.bColorBaseWhite}
              className={styles.avatarBadge}
              style={{ backgroundColor: icon?.backgroundColor }}
            />
          ) : undefined
        }
      >
        {avatarContent}
      </Badge>
    );
  }, [profileImageUrl, size, color, firstName, lastName, icon]);

  return (
    <Flex gap={mainGap} align="center">
      <div className={styles.img}>{renderAvatar}</div>

      {!onlyAvatar && (
        <Flex gap={8} vertical className={styles.details}>
          <Flex vertical>
            <Flex gap={8} align="center">
              <Text
                ellipsis
                strong={!!title}
                style={{ fontSize: title ? "16px" : "14px", color }}
              >
                {`${firstName} ${lastName}`}
              </Text>

              {countryTwoLetterCode && (
                <span
                  id="countryFlag"
                  title={countryName}
                  style={{ width: 14 }}
                  className={`flag-icon flag-icon-${countryTwoLetterCode.toLowerCase()}`}
                />
              )}

              {linkedInProfile && (
                <Image
                  src={linkedin}
                  alt="LinkedIn"
                  className={styles.img}
                  width={14}
                  onClick={() => window.open(linkedInProfile, "_blank")}
                  preview={false}
                />
              )}

              {isVerified && (
                <Tooltip title={t("linkedinVerified")}>
                  <AntDIcon
                    name="verified_user"
                    fontSize="14px"
                    color={ARBOLUS_COLORS.bColorAccentGreenDark}
                  />
                </Tooltip>
              )}

              {lastPublicCompanyExpDate && (
                <PublicCompanyIcon
                  date={lastPublicCompanyExpDate}
                  customFontSize="14px"
                />
              )}

              {doNotContactStatus && (
                <DNCTag
                  dncStatus={doNotContactStatus}
                  showTooltip
                  isAdmin={isAdmin}
                />
              )}
            </Flex>

            <Tooltip title={showTitleTooltip ? title : ""} placement="topLeft">
              {composedTitle ? composedTitle : <Text ellipsis>{title}</Text>}
            </Tooltip>
          </Flex>

          {angle && <AntDAngleTag angle={angle} />}
        </Flex>
      )}
    </Flex>
  );
};
