import { SimpleRestServiceError } from "./SimpleRestServiceError";

export async function call_get<T = Record<string, unknown>>(
  url: string,
  params?: any
) {
  const parsedUrl = new URL(url);
  const parsedParams = new URLSearchParams(params);
  parsedUrl.search = parsedParams.toString();

  const response = await fetch(parsedUrl, {
    mode: "cors",
    credentials: "include"
  });

  if (!response.ok) {
    throw new SimpleRestServiceError(
      { method: "GET", url: parsedUrl.toString(), params },
      { status: response.status }
    );
  }

  return (await response.json()) as T;
}

export async function call_post<
  ReqT = Record<string, unknown>,
  RespT = Record<string, unknown>
>(url: string, payload?: ReqT) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    credentials: "include"
  } as RequestInit;
  if (payload) {
    requestOptions["headers"] = {
      "Content-Type": "application/json"
    };
    requestOptions["body"] = JSON.stringify(payload);
  }

  // new URL will test url format - we'll see the error on FE
  const response = await fetch(new URL(url), requestOptions);

  if (!response.ok) {
    throw new SimpleRestServiceError(
      { method: "POST", url, payload },
      { status: response.status }
    );
  }

  return (await response.json()) as RespT;
}

export async function call_delete(
  url: string,
  { params = "", payload = null } = {}
) {
  const parsedUrl = new URL(url);
  const parsedParams = new URLSearchParams(params);
  parsedUrl.search = parsedParams.toString();

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  } as RequestInit;
  if (payload) {
    requestOptions["headers"] = {
      "Content-Type": "application/json"
    };
    requestOptions["body"] = JSON.stringify(payload);
  }

  const response = await fetch(parsedUrl, requestOptions);

  if (!response.ok) {
    throw new SimpleRestServiceError(
      { method: "DELETE", url, params, payload },
      { status: response.status }
    );
  }

  return true;
}
