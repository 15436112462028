import { ICellRendererParams } from "ag-grid-community";
import { Tag, Typography } from "antd";

export interface NPSRendererProps
  extends Partial<ICellRendererParams<{ nps: number }>> {}

export function NPSRenderer({ data }: NPSRendererProps) {
  if (!data?.nps) return <Typography.Text type="secondary">-</Typography.Text>;

  return <Tag>{data.nps}</Tag>;
}
