import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import React, { useCallback, useMemo } from "react";

import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { MonitoringListDataSource } from "../MonitoringListDataSource";
import { getDefaultColumns } from "./MonitoringListTableColumnDefs";

interface MonitoringListTableProps {
  clientId: string;
  onClickAction: (id: string) => void;
  onGridApiReady?: (api: GridApi) => void;
}

export const MonitoringListTable: React.FC<MonitoringListTableProps> = ({
  clientId,
  onClickAction,
  onGridApiReady
}) => {
  const dataSource = useMemo(
    () => new MonitoringListDataSource(clientId),
    [clientId]
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      const { api } = event;
      api.setGridOption("serverSideDatasource", dataSource);
      onGridApiReady?.(api);
      api.sizeColumnsToFit();
    },
    [dataSource, onGridApiReady]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: true,
      resizable: true,
      sortable: true,
      menuTabs: ["filterMenuTab"]
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo<ColDef[]>(
    () => getDefaultColumns(onClickAction),
    [onClickAction]
  );

  return (
    <ArbolusGrid
      rowModelType="serverSide"
      getRowId={({ data }) => data.id}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      readOnlyEdit
      onCellEditRequest={(evt) => dataSource.editWebsite(evt)}
      suppressRowClickSelection
      skipHeaderOnAutoSize
      suppressContextMenu
      suppressMenuHide
      pagination
      paginationAutoPageSize
    />
  );
};
