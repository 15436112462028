import { replace } from "connected-react-router";
import i18next from "i18next";
import queryString from "query-string";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { Subscription } from "rxjs";

import { Invitation, ToasterService } from "@arbolus-technologies/api";
import { AuthPageHeader, RECAPTCHA_ACTIONS } from "@arbolus-technologies/auth";
import {
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import {
  APP_TRACKING_ROUTES,
  SelectOption
} from "@arbolus-technologies/models/common";
import { SURVEY_PAYMENT_METHOD } from "@arbolus-technologies/routes";
import { Loader } from "@arbolus-technologies/ui/components";

import { AppConstants, PublicRouteConstants } from "../../../../../constants";
import { APP_USER_ROLES } from "../../../../../constants/app";
import { CreateAccountStages } from "../../../../../constants/auth";
import { WELCOME } from "../../../../../constants/navigation/authRoutes";
import { CANOPY_DETAILS_PAGE_ROUTE } from "../../../../../constants/navigation/canopyRoutes";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { ExpertRateCardType, RateCard } from "../../../../../models/expert";
import { RegisterUserRequest, User } from "../../../../../models/user";
import { MetaService, UserService } from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { LoginStoreActions } from "../../../store";
import ExpertRate, { ExpertRateFormValues } from "../expertRate/ExpertRate";
import RegisterForm from "../registerForm/RegisterForm";

const ACCOUNT_ALREADY_EXISTS_ERROR_STATUS = "1090";

const notification = new ToasterService();

interface CreateAccountState {
  isLoading: boolean;
  isCurrencyLoading: boolean;
  signUpError?: ErrorResponse<CIQError>;
  stage: CreateAccountStages;
}

interface CreateAccountProps {
  termId: string;
  user?: User;
  token?: string;
  isExpert: boolean;
  isEmailInvite: boolean;
  invitationCode?: string;
  invitation?: Invitation;
  clientName?: string;
  isCanopySignUp?: boolean;
  canopyId?: string;
  from?: string;
  executeRecaptcha: (action: string) => Promise<string>;
  onError: () => void;
}

interface CreateAccountDispatchProps {
  onRegisterUserSuccess: (
    userId: string,
    roles: string[],
    nextPath: string,
    invitation?: Invitation
  ) => void;
}

type CreateAccountIntersectProps = CreateAccountProps &
  CreateAccountDispatchProps &
  WithTranslation;

export interface CreateAccountFormValues {
  email: string;
  password: string;
  password2: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  linkedinUrl?: string;
  title?: string;
  isRealNameEnabled?: boolean;
  displayName?: string;
}
class CreateAccount extends React.Component<
  CreateAccountIntersectProps,
  CreateAccountState
> {
  constructor(props: CreateAccountIntersectProps) {
    super(props);
    this.state = {
      isLoading: false,
      isCurrencyLoading: true,
      stage: CreateAccountStages.CREATE_ACCOUNT
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("createAccountTitle");
    this.fetchCurrencies();
  }

  componentWillUnmount(): void {
    this.registerUserSubscription?.unsubscribe();
    this.authenticateUserSubscription?.unsubscribe();
    this.currencyFetchSubscription?.unsubscribe();
  }

  private registerUserSubscription?: Subscription;

  private authenticateUserSubscription?: Subscription;

  private currencyFetchSubscription?: Subscription;

  private currencies?: SelectOption[];

  private preservedUserData?: CreateAccountFormValues;

  private preservedRateCard?: RateCard;

  fetchCurrencies = async (): Promise<void> => {
    const { executeRecaptcha, onError } = this.props;
    try {
      const recaptchaToken = await executeRecaptcha(
        RECAPTCHA_ACTIONS.LIST_CURRENCIES
      );

      this.currencyFetchSubscription = MetaService.getCurrencies(
        recaptchaToken
      ).subscribe(
        (currencies) => {
          this.currencies = currencies.items.map((t) => ({
            value: t.isoCurrencyCode,
            label: t.isoCurrencyCode,
            customLabel: ""
          }));
          this.setState({ isCurrencyLoading: false });
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          onError();
        }
      );
    } catch (error) {
      notification.showError(i18next.t("restService:somethingWrong"));
      onError();
    }
  };

  handleOnCreateClicked = async (): Promise<void> => {
    const {
      executeRecaptcha,
      t,
      invitationCode,
      termId,
      token,
      isExpert,
      from
    } = this.props;
    const { expert, client } = APP_USER_ROLES;

    const accountType = isExpert ? expert : client;
    const {
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      title,
      isRealNameEnabled,
      displayName,
      linkedinUrl
    } = this.preservedUserData!;

    this.setState({ isLoading: true, signUpError: undefined });

    try {
      const recaptchaToken = await executeRecaptcha(
        RECAPTCHA_ACTIONS.CREATE_ACCOUNT
      );
      const linkedinProfileUrlFormatted = linkedinUrl?.includes("?")
        ? linkedinUrl.split("?")[0]
        : linkedinUrl;
      const user: RegisterUserRequest = {
        termId,
        accountType,
        token,
        email,
        password,
        firstName,
        lastName,
        phoneNumber: `+${phoneNumber}`,
        recaptchaToken,
        rateCard: this.preservedRateCard,
        invitationCode,
        title,
        isRealNameEnabled,
        displayName: isRealNameEnabled ? "" : displayName,
        linkedinUrl: linkedinProfileUrlFormatted
      };

      this.registerUserSubscription = UserService.registerUser(user).subscribe(
        () => {
          this.handleOnRegisterUserSuccess(email, password);
          trackEvent(MixPanelEventNames.ExpertUserRegistrationAccountCreated, {
            action: MixPanelActions.AccountCreated,
            from
          });
        },
        (error: ErrorResponse<CIQError>) => {
          const registrationFailureError =
            error?.status === ACCOUNT_ALREADY_EXISTS_ERROR_STATUS;
          if (registrationFailureError) {
            notification.showError(t("registrationFailure"));
          }
          this.setState({
            signUpError: registrationFailureError ? undefined : error,
            stage: CreateAccountStages.CREATE_ACCOUNT,
            isLoading: false
          });

          trackEvent(
            MixPanelEventNames.ExpertUserRegistrationAccountCreationFailed,
            { action: MixPanelActions.AccountCreationFailed, from }
          );
        }
      );
    } catch (error) {
      this.setState({
        isLoading: false,
        stage: CreateAccountStages.CREATE_ACCOUNT
      });
      notification.showError(error?.message ?? t("errorInCreateAccount"));

      trackEvent(
        MixPanelEventNames.ExpertUserRegistrationAccountCreationFailed,
        { action: MixPanelActions.AccountCreationFailed, from }
      );
    }
  };

  handleOnRegisterUserSuccess = (email: string, password: string): void => {
    const {
      onRegisterUserSuccess,
      invitation,
      isExpert,
      isCanopySignUp,
      canopyId
    } = this.props;

    const queryParams = queryString.parse(window.location.search);

    const surveyExpertId = queryString.parse(
      queryParams.search as string
    ).surveyExpertId;

    const redirect = queryParams.redirect;

    let nextPath = redirect ? redirect.toString() : WELCOME;

    if (isExpert && isCanopySignUp && canopyId) {
      nextPath = CANOPY_DETAILS_PAGE_ROUTE(canopyId);
    }

    if (surveyExpertId && redirect?.includes("payment-method")) {
      const surveyPaymentMethodURL = `${SURVEY_PAYMENT_METHOD}?surveyExpertId=${surveyExpertId}`;
      trackEvent(MixPanelEventNames.SurveySignUp, {
        action: MixPanelActions.Redirect
      });
      nextPath = surveyPaymentMethodURL;
    }

    this.authenticateUserSubscription = UserService.authenticateUser({
      email,
      password,
      remember: true
    }).subscribe(
      ({ userId, userRoles }) => {
        this.setState(
          {
            isLoading: false
          },
          () => {
            localStorage.setItem(
              AppConstants.LOCALSTORAGE.LAST_LOGIN,
              new Date().getTime().toString()
            );
            onRegisterUserSuccess(userId, userRoles, nextPath, invitation);
          }
        );
      },
      (error: ErrorResponse<CIQError>) => {
        this.setState({
          signUpError: error,
          isLoading: false
        });
      }
    );
  };

  handleCreateAccountClicked = (values: CreateAccountFormValues): void => {
    const { invitation, isEmailInvite, isExpert, from } = this.props;

    this.preservedUserData = {
      ...values,
      // Force new registrations to lowercase email addresses
      email: isEmailInvite ? values.email : values.email.toLowerCase()
    };

    trackEvent(MixPanelEventNames.ExpertUserRegistrationCompleteUserForm, {
      action: MixPanelActions.Clicked,
      from
    });

    if (isExpert && invitation) {
      this.setState({
        stage: CreateAccountStages.EXPERT_RATE,
        signUpError: undefined
      });
    } else {
      this.handleOnCreateClicked();
    }
  };

  handleExpertRateSaveClicked = ({
    currency,
    hourlyRate
  }: ExpertRateFormValues): void => {
    this.preservedRateCard = {
      hours: 1,
      isoCurrencyCode: currency,
      rateCardType: ExpertRateCardType.AdHoc,
      packageName: "Base",
      price: hourlyRate!
    };
    this.handleOnCreateClicked();
  };

  renderMessagePanel = (): JSX.Element => {
    const { isExpert, clientName } = this.props;

    return isExpert ? (
      <Trans
        ns="register"
        i18nKey="expertSelfOnBoarding"
        components={[<span key="1" />]}
      />
    ) : (
      <Trans
        ns="register"
        i18nKey="clientInviteClient"
        values={{ clientName }}
        components={[<span key="1" />]}
      />
    );
  };

  renderStages = (): JSX.Element => {
    const { user, isEmailInvite, isCanopySignUp } = this.props;
    const { stage, isLoading, signUpError } = this.state;

    const { EXPERT_RATE } = CreateAccountStages;

    let userData = user;

    if (this.preservedUserData) {
      const { email, firstName, lastName, phoneNumber, title } =
        this.preservedUserData;

      userData = { email, firstName, lastName, phoneNumber, title } as User;
    }

    if (stage === EXPERT_RATE) {
      return (
        <ExpertRate
          hourlyRate={this.preservedRateCard?.price}
          currencies={this.currencies!}
          isLoading={isLoading}
          currencyCode={this.preservedRateCard?.isoCurrencyCode}
          onSaveButtonClicked={this.handleExpertRateSaveClicked}
        />
      );
    }

    return (
      <RegisterForm
        user={userData}
        isEmailInvite={isEmailInvite}
        signUpError={signUpError}
        isLoading={isLoading}
        isCanopySignUp={isCanopySignUp}
        onCreateAccountClicked={this.handleCreateAccountClicked}
        isExpert={this.props.isExpert}
      />
    );
  };

  render(): JSX.Element {
    const { from } = this.props;
    const { stage, isCurrencyLoading } = this.state;
    const currentQueryString = window.location.search;
    const isExpertRateState = stage === CreateAccountStages.EXPERT_RATE;

    if (isCurrencyLoading) {
      return <Loader isFull />;
    }

    return (
      <div className="create-account-container">
        <AuthPageHeader title="New account" />

        {!isExpertRateState && <h3>{this.renderMessagePanel()}</h3>}

        {this.renderStages()}

        {!isExpertRateState && (
          <div className="create-account-footer">
            <Trans
              ns="register"
              i18nKey="alreadyHaveAccount"
              components={[
                <Link
                  key="0"
                  to={{
                    pathname: PublicRouteConstants.LOGIN,
                    search: currentQueryString,
                    state: { from: from ?? APP_TRACKING_ROUTES.REGISTER }
                  }}
                />
              ]}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch
): CreateAccountDispatchProps => ({
  onRegisterUserSuccess: (
    userId: string,
    roles: string[],
    nextPath: string,
    invitation?: Invitation
  ): AppAction => {
    dispatch(LoginStoreActions.authenticateUserSuccess(userId, roles));
    dispatch(replace(nextPath, invitation));
  }
});

export default withTranslation("register")(
  // @ts-ignore
  connect(null, mapDispatchToProps)(CreateAccount)
);
