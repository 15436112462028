import { Tag, Typography } from "antd";

import { MONITORING_LIST_COMPANY_STATUS } from "@arbolus-technologies/api";

export function CompanyStatusRenderer({
  data
}: { data: { status: MONITORING_LIST_COMPANY_STATUS } }) {
  if (!data?.status) {
    return <Typography.Text type="secondary">-</Typography.Text>;
  }

  return <Tag color={getColor(data.status)}>{data.status}</Tag>;
}

function getColor(status: MONITORING_LIST_COMPANY_STATUS) {
  switch (status) {
    case MONITORING_LIST_COMPANY_STATUS.PRIORITY:
      return "red";
    case MONITORING_LIST_COMPANY_STATUS.MONITORING:
      return "blue";
    default:
      return "grey";
  }
}
