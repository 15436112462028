import { FlagsProvider } from "flagged";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router";

import { AvailabilitySchedulerPage } from "@arbolus-technologies/features/availability";
import {
  AntDMenu,
  AntDMenuMobile,
  DesktopMenu,
  FeatureOverridePage,
  MixPanelEventNames,
  RejectFeedbackPanel,
  TabletMobileMenu,
  identifyUser,
  setUserProfileDetails,
  useArbolusTracking,
  useCachedFlags
} from "@arbolus-technologies/features/common";
import { DashboardPage } from "@arbolus-technologies/features/dashboard";
import { ProjectApplication } from "@arbolus-technologies/features/expert-applications";
import {
  EditProfile,
  ExpertInactive,
  ExpertTraining
} from "@arbolus-technologies/features/expert-profile";
import { ExpertProjectList } from "@arbolus-technologies/features/expert-project";
import {
  AssignExpertToProjectPanel,
  ProjectsListPage
} from "@arbolus-technologies/features/projects";
import {
  ExpertSurveyListPage,
  SurveyPaymentMethodPage
} from "@arbolus-technologies/features/surveys";
import {
  ArbolusFeatureFlag,
  UserRole
} from "@arbolus-technologies/models/common";
import {
  EXPERT_PROFILE_EDIT_ROUTES,
  EXPERT_PROJECT_APPLICATION,
  EXPERT_SELF_PROFILE_EDIT,
  INBOX,
  SURVEYS,
  SURVEY_PAYMENT_METHOD
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";
import { DISABLE_MIXPANEL, displayUserName } from "@arbolus-technologies/utils";

import {
  CLIENT_ACCOUNT,
  CLIENT_NETWORK_ROUTES,
  DASHBOARD,
  DRAFT_PROJECT,
  EDIT_USER,
  ENDORSEMENT,
  EXPERT_NETWORK_ROUTES,
  FEATURE_OVERRIDE,
  NEW_PROJECT,
  NEW_USER,
  PROJECTS,
  USERS,
  USER_PROFILE,
  WELCOME
} from "../../constants/navigation/authRoutes";
import {
  PROJECT_EXPERT_AVAILABILITY,
  PROJECT_ROUTES
} from "../../constants/navigation/projectRoutes";
import ContentPanelContextProvider from "../../contexts/contentPanel/ContentPanelProvider";
import { SentryRoute } from "../../sentry";
import { FirebaseService, RBServiceManager } from "../../services";
import { withAuthAccess } from "../app/components";
import { AppSelector, AppStoreActions } from "../app/store";
import { AuthSelector, LoginStoreActions } from "../auth/store";
import { BookmarksRouter } from "../bookmarks/BookmarksRouter";
import { CanopyRouterContainer } from "../canopy/CanopyRouterContainer";
import ClientNetworkContainer from "../clientNetwork";
import ExpertNetworkContainer from "../expertNetwork/ExpertNetworkContainer";
import { ExpertPaymentsRouter } from "../expertPayments/ExpertPaymentsRouter";
import ProjectContainer from "../project/ProjectContainer";
import { SimplifiedCreateProjectPage } from "../project/pages/SimplifiedCreateProjectPage/SimplifiedCreateProjectPage";
import ContentPanelRouter from "./components/contentPanelRouter/ContentPanelRouter";
import {
  ClientSettingsPage,
  NewUserPage,
  ProjectApplicationPage,
  ProjectsPage,
  UserProfilePage,
  UsersPage,
  WelcomePage
} from "./pages";
import { ExpertProfileEditPage } from "./pages/expertProfileEdit/ExpertProfileEditPage";
import InboxPage from "./pages/inbox/InboxPage";
import NewEndorsement from "./pages/welcome/newEndorsement/NewEndorsement";

const DashboardWrapper = (): JSX.Element => (
  <DashboardPage
    routes={{
      project: PROJECTS,
      inbox: INBOX
    }}
  />
);

export const DashboardContainer = withAuthAccess(() => {
  const dispatch = useDispatch();
  const { trackRender } = useArbolusTracking();

  const isMetaExist = useSelector(AppSelector.isAppMetaReadySelector());

  const authUser = useSelector(AuthSelector.authUserSelector());
  const userId = authUser?.id;
  const clientId = useSelector(AuthSelector.authClientIdSelector());
  const user = useSelector(AuthSelector.authUserSelector());
  const userRole = useSelector(CacheSelector.generateActiveUserRole());
  const currentUser = useSelector(CacheSelector.currentUserSelector());
  const currentUserIsInactiveExpert = useSelector(
    AuthSelector.currentUserIsInactiveExpert()
  );

  const logout = () => dispatch(LoginStoreActions.logoutUser());

  const isExpert = userRole === UserRole.expert;

  const profileRoute = isExpert ? EXPERT_SELF_PROFILE_EDIT : USER_PROFILE;

  // Feature flags
  const isFeatureFlagsReady = useSelector(
    AppSelector.isFeatureFlagsReadySelector()
  );
  const userPermissions = useSelector(AppSelector.appUserPermissionsSelector());
  const { featureFlags } = userPermissions;
  const showNewDashboard = featureFlags.NewDashboard;
  const { cachedOverrides } = useCachedFlags();
  const isFeatureOverrideEnabled =
    userPermissions.featureFlags.FeatureFlagOverride;
  const handleChangeFlag = (flag: ArbolusFeatureFlag, value: boolean) => {
    dispatch(AppStoreActions.overrideFeatureFlag(flag, value));
  };
  const handleResetFlagsToDefault = () => {
    dispatch(AppStoreActions.resetOverrideFeatureFlag());
  };

  const isAppReady = userId && isMetaExist && isFeatureFlagsReady;

  const expertDetails = {
    expertId: user.expertId!,
    expertName: displayUserName(user, true),
    isActive: user.isActive
  };

  useEffect(
    function initializeFlagsFromCache() {
      const hasLocalOverrides = Object.keys(cachedOverrides || {}).length > 0;
      if (isAppReady && hasLocalOverrides && cachedOverrides) {
        dispatch(AppStoreActions.initializeFlagsOverride(cachedOverrides));
      }
    },
    [dispatch, isAppReady]
  );

  useEffect(() => {
    if (Object.keys(currentUser).length < 1 || DISABLE_MIXPANEL) return;

    identifyUser(currentUser.loggedInId);
    setUserProfileDetails(currentUser);
    trackRender(MixPanelEventNames.InitialRender);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (userId) {
      RBServiceManager.subscribeToStore();
      FirebaseService.getNewToken(userId);
      dispatch(AppStoreActions.getAppData());
      dispatch(AppStoreActions.getFeatureFlags());
    }

    return (): void => {
      RBServiceManager.unsubscribeFromStore();
    };
  }, [dispatch, userId]);

  if (currentUserIsInactiveExpert) {
    return <ExpertInactive expertId={authUser.expertId!} logout={logout} />;
  }

  if (!isAppReady) return <Loader isFull />;

  return (
    <FlagsProvider features={featureFlags}>
      <ContentPanelContextProvider>
        <RejectFeedbackPanel />
        {featureFlags.AntDUI ? (
          <AntDMenu handleLogout={logout} profileRoute={profileRoute} />
        ) : (
          <DesktopMenu handleLogout={logout} profileRoute={profileRoute} />
        )}
        {featureFlags.AntDUI ? (
          <AntDMenuMobile handleLogout={logout} profileRoute={profileRoute} />
        ) : (
          <TabletMobileMenu handleLogout={logout} profileRoute={profileRoute} />
        )}
        <ContentPanelRouter />
        <ExpertTraining />
        <Switch>
          {isFeatureOverrideEnabled && (
            <SentryRoute
              exact
              path={FEATURE_OVERRIDE}
              render={() => (
                <FeatureOverridePage
                  featureFlags={featureFlags}
                  featureFlagsDefaults={userPermissions.featureFlagsDefaults}
                  onChangeFlag={handleChangeFlag}
                  onResetFlagsToDefault={handleResetFlagsToDefault}
                />
              )}
            />
          )}
          <SentryRoute exact path={WELCOME} component={WelcomePage} />
          <SentryRoute exact path={ENDORSEMENT} component={NewEndorsement} />
          <SentryRoute path={INBOX} exact component={InboxPage} />
          {isExpert ? (
            <SentryRoute path={PROJECTS} exact component={ExpertProjectList} />
          ) : (
            <SentryRoute
              path={PROJECTS}
              exact
              component={
                featureFlags.ProjectsTable ? ProjectsListPage : ProjectsPage
              }
            />
          )}
          <SentryRoute
            path={[NEW_PROJECT, DRAFT_PROJECT]}
            exact
            render={() => <SimplifiedCreateProjectPage clientId={clientId!} />}
          />
          {featureFlags.Bookmarks && BookmarksRouter}
          {isExpert && ExpertPaymentsRouter}
          {isExpert && (
            <SentryRoute
              key={PROJECT_EXPERT_AVAILABILITY}
              path={PROJECT_EXPERT_AVAILABILITY}
              component={AvailabilitySchedulerPage}
              exact
            />
          )}
          <SentryRoute
            path={PROJECT_ROUTES}
            component={ProjectContainer}
            exact
          />
          <SentryRoute path={USERS} exact component={UsersPage} />
          <SentryRoute
            path={SURVEY_PAYMENT_METHOD}
            exact
            component={SurveyPaymentMethodPage}
          />
          <SentryRoute path={SURVEYS} exact component={ExpertSurveyListPage} />
          {/* @ts-ignore */}
          <SentryRoute
            path={[NEW_USER, EDIT_USER]}
            exact
            component={NewUserPage}
          />
          {/* @ts-ignore */}
          <SentryRoute path={USER_PROFILE} exact component={UserProfilePage} />
          <SentryRoute
            path={CLIENT_ACCOUNT}
            exact
            component={ClientSettingsPage}
          />
          {showNewDashboard && userRole !== UserRole.expert && (
            <SentryRoute path={DASHBOARD} exact component={DashboardWrapper} />
          )}
          {showNewDashboard && userRole === UserRole.expert && (
            <SentryRoute path={DASHBOARD} exact component={ExpertProjectList} />
          )}
          {showNewDashboard && (
            <SentryRoute
              path={RBServiceManager.valueSelector(
                EXPERT_NETWORK_ROUTES,
                CLIENT_NETWORK_ROUTES
              )}
              exact
              // @ts-ignore
              component={RBServiceManager.valueSelector(
                ExpertNetworkContainer,
                ClientNetworkContainer
              )}
            />
          )}
          {!showNewDashboard && (
            <SentryRoute
              path={RBServiceManager.valueSelector(
                [...EXPERT_NETWORK_ROUTES, DASHBOARD],
                [...CLIENT_NETWORK_ROUTES, DASHBOARD]
              )}
              exact
              // @ts-ignore
              component={RBServiceManager.valueSelector(
                ExpertNetworkContainer,
                ClientNetworkContainer
              )}
            />
          )}
          {featureFlags.NewEditExpertProfile ? (
            //TODO remove "expertProfileEditPage" from en.json client and change the default root to details tab
            <SentryRoute
              path={EXPERT_PROFILE_EDIT_ROUTES}
              exact
              component={() => (
                <EditProfile
                  expertDetails={expertDetails}
                  userId={user.id}
                  logOut={logout}
                />
              )}
            />
          ) : (
            <SentryRoute
              path={EXPERT_PROFILE_EDIT_ROUTES}
              exact
              component={ExpertProfileEditPage}
            />
          )}
          {featureFlags.NewExpertApplication ? (
            <SentryRoute
              path={EXPERT_PROJECT_APPLICATION}
              exact
              component={ProjectApplication}
            />
          ) : (
            <SentryRoute
              path={EXPERT_PROJECT_APPLICATION}
              exact
              component={ProjectApplicationPage}
            />
          )}
          <CanopyRouterContainer />
        </Switch>
        <AssignExpertToProjectPanel />
      </ContentPanelContextProvider>
    </FlagsProvider>
  );
});
