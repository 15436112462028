import { MONITORING_LIST_ORDER_BY } from "@arbolus-technologies/api";
import { floatingFilter } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";
import { ColDef } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";
import { ActionRenderer } from "../../Components/CellComponents/ActionRenderer";
import { ClientUserRenderer } from "../../Components/CellComponents/ClientUserRenderer/ClientUserRenderer";
import styles from "./MonitoringListTable.module.scss";

const MONITORING_LIST_WEBSITE_MAX_LENGTH = 256;

const filterParams = {
  suppressAndOrCondition: true,
  filterOptions: ["contains"]
};

export const getDefaultColumns = (
  onClickAction: (id: string) => void
): ColDef[] => {
  const columnDefs: ColDef[] = [
    {
      field: "companyName",
      colId: MONITORING_LIST_ORDER_BY.COMPANY_NAME,
      headerName: i18next.t("monitoringList:companyName"),
      cellClass: "cursor-pointer",
      filter: "agTextColumnFilter",
      cellEditor: "agTextCellEditor",
      flex: 1,
      sortable: true,
      ...filterParams,
      ...floatingFilter
    },
    {
      field: "companyWebsite",
      colId: MONITORING_LIST_ORDER_BY.COMPANY_WEBSITE,
      sortable: false,
      headerName: i18next.t("monitoringList:website"),
      cellClass: "cursor-pointer",
      filter: "agTextColumnFilter",
      editable: true,
      cellEditor: "agTextCellEditor",
      minWidth: 200,
      cellEditorParams: {
        maxLength: MONITORING_LIST_WEBSITE_MAX_LENGTH
      },
      valueGetter: (params) =>
        params.data.companyWebsite
          ? `https://www.${params.data.companyWebsite}`
          : "",
      ...filterParams,
      ...floatingFilter
    },
    {
      field: "clientUser",
      colId: MONITORING_LIST_ORDER_BY.CLIENT_USER,
      headerName: i18next.t("monitoringList:clientUser"),
      cellClass: styles.clientUserCell,
      cellRenderer: ClientUserRenderer,
      filter: "agTextColumnFilter",
      ...filterParams,
      ...floatingFilter
    },
    {
      field: "modified",
      colId: MONITORING_LIST_ORDER_BY.MODIFIED,
      headerName: i18next.t("monitoringList:modified"),
      valueGetter: (params) =>
        moment(params.data.modified).format(APP_DATE_FORMAT),
      suppressHeaderMenuButton: true
    },
    {
      field: "source",
      colId: MONITORING_LIST_ORDER_BY.SOURCE,
      headerName: i18next.t("monitoringList:source"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Project", "Admin", "insightsExtension"]
      },
      valueGetter: (params) =>
        i18next.t(`monitoringList:${params.data.source}`),
      suppressFloatingFilterButton: true,
      sortable: false
    },
    {
      field: "action",
      headerName: i18next.t("monitoringList:action"),
      sortable: false,
      maxWidth: 100,
      cellClass: styles.actionCell,
      cellRenderer: ActionRenderer,
      cellRendererParams: {
        onClickAction
      },
      suppressHeaderMenuButton: true
    }
  ];

  return columnDefs;
};
