import { GridApi } from "ag-grid-community";
import { Input, Layout, Menu, Modal, Typography } from "antd";
import i18next from "i18next";
import { differenceBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { UserCompanyItem } from "@arbolus-technologies/api";
import { useDebouncedSearch } from "@arbolus-technologies/utils";

import { CompanyList } from "./CompanyList/CompanyList";
import { useCompaniesRequests } from "./useCompaniesRequests";
import { useSelectCompanies } from "./useSelectedCompanies";

import {
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import styles from "./AddCompaniesModal.module.scss";

const { Sider, Content } = Layout;

const items = [
  {
    key: "1",
    label: i18next.t("addCompaniesModal:searchAndAdd")
  }
];

interface AddCompaniesModalProps {
  userId: string;
  isModalOpen: boolean;
  hideModal: () => void;
  gridApi?: GridApi;
  actionAfterClose?: () => void;
}

export const AddCompaniesModal: React.FC<AddCompaniesModalProps> = ({
  userId,
  isModalOpen,
  hideModal,
  gridApi,
  actionAfterClose
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { t } = useTranslation("addCompaniesModal");
  const {
    companiesList,
    isLoading,
    totalCount,
    getCompanies,
    resetCompanies,
    addCompanies
  } = useCompaniesRequests({
    userId,
    searchTerm,
    gridApi,
    actionAfterClose
  });
  const {
    selectedCompanies,
    addAndSelectCompany,
    toggleCompany,
    resetSelection
  } = useSelectCompanies();

  // biome-ignore lint/correctness/useExhaustiveDependencies: getCompanies function has to been called when searchTerm changes
  useEffect(() => {
    if (isModalOpen) {
      getCompanies();
    }
  }, [searchTerm, isModalOpen]);

  const onQueryChange = (searchValue: string) => {
    resetCompanies();
    setSearchTerm(searchValue);
  };

  const { handleInputChangeEvent } = useDebouncedSearch({
    onQueryChange,
    isDebounced: true
  });

  const handleAfterClose = () => {
    resetCompanies();
    setSearchTerm("");
    resetSelection();
  };

  function handleCompanyCreated(company: UserCompanyItem) {
    addAndSelectCompany(company);
    resetCompanies();
    setSearchTerm("");
  }

  const companiesListWithSelected: UserCompanyItem[] = [
    ...selectedCompanies,
    ...differenceBy(companiesList, selectedCompanies, "id")
  ];

  const confirmButtonDisabled = selectedCompanies.length === 0;

  return (
    <Modal
      title={
        <Typography.Text strong className={styles.title}>
          {t("addCompanies")}
        </Typography.Text>
      }
      width={600}
      open={isModalOpen}
      onClose={hideModal}
      afterClose={handleAfterClose}
      destroyOnClose
      onCancel={hideModal}
      onOk={() => {
        addCompanies(selectedCompanies).then(() => {
          hideModal();
          trackEvent(MixPanelEventNames.MonitoringListAddCompanyConfirmation, {
            action: MixPanelActions.Clicked
          });
        });
      }}
      okButtonProps={{ disabled: confirmButtonDisabled }}
      okText={t("addCompanies")}
    >
      <Layout className={styles.layout}>
        <Sider className={styles.sider}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["1"]}
            items={items}
            className={styles.antMenu}
          >
            {items.map((item) => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content className={styles.content}>
          <Input
            addonBefore={<AntDIcon name="search" />}
            allowClear
            onChange={handleInputChangeEvent}
            className={styles.searchInput}
          />
          <CompanyList
            companies={companiesListWithSelected}
            isLoading={isLoading}
            getCompanies={getCompanies}
            totalCount={totalCount}
            searchTerm={searchTerm}
            onCompanyCreated={handleCompanyCreated}
            selectedCompanies={selectedCompanies}
            toggleCompany={toggleCompany}
          />
        </Content>
      </Layout>
    </Modal>
  );
};
