import React, { RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiPaginatedResponse,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertSurvey,
  ExpertSurveyListRequest,
  SORT_DIRECTION,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import {
  SEARCH_DEBOUNCE_TIMEOUT,
  useDebounce,
  useDocumentTitle
} from "@arbolus-technologies/utils";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Flex, Spin } from "antd";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useSelector } from "react-redux";
import { ExpertSurveyList } from "../../Components/ExpertSurveyList/ExpertSurveyList";
import { ExpertSurveyListSearch } from "../../Components/ExpertSurveyListSearch/ExpertSurveyListSearch";
import styles from "./ExpertSurveyListPage.module.scss";

interface ExpertSurveyListPageProps {
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const ExpertSurveyListPage: React.FC<ExpertSurveyListPageProps> = ({
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertSurveyListPage");
  useDocumentTitle("expertSurveyListPage", "title");
  const { trackRender } = useArbolusTracking();
  trackRender(MixPanelEventNames.SurveyViewAll);

  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const expertId = loggedInExpert.expertId;
  const orderBy = "CompletionDate";

  const [isLoading, setIsLoading] = useState(false);
  const [isNewSearchTerm, setIsNewSearchTerm] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedSearchTerm = useDebounce<string>(
    searchTerm,
    SEARCH_DEBOUNCE_TIMEOUT
  );

  const initialQueryParams: ExpertSurveyListRequest = {
    limit: 10,
    offset: 0,
    orderBy,
    orderDirection: SORT_DIRECTION.DESCENDING,
    searchTerm: "",
    expertId: expertId ?? ""
  };

  const [queryParams, setQueryParams] =
    useState<ExpertSurveyListRequest>(initialQueryParams);
  const [expertSurveys, setExpertSurveys] = useState<ExpertSurvey[]>([]);

  const getExpertSurveys = (isInitialSearch?: boolean) => {
    setIsLoading(true);
    const params = isInitialSearch ? initialQueryParams : queryParams;
    const queryParamsWithSearch = {
      ...params,
      searchTerm: debouncedSearchTerm
    };
    surveyService.getExpertSurveys(queryParamsWithSearch).subscribe(
      (surveyExpertResponse: ApiPaginatedResponse<ExpertSurvey>) => {
        const updatedExpertSurveys = isInitialSearch
          ? surveyExpertResponse.items
          : [...expertSurveys, ...surveyExpertResponse.items];
        setExpertSurveys(updatedExpertSurveys);
        setQueryParams({
          ...queryParams,
          limit: initialQueryParams.limit,
          offset:
            surveyExpertResponse.pagination.offset + initialQueryParams.limit,
          count: surveyExpertResponse.pagination.count
        });
        setIsNewSearchTerm(false);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsNewSearchTerm(false);
        setIsLoading(false);
        notificationService.showApiErrors(error);
      }
    );
  };

  const handleSetSearchTerm = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleBottomReached = () => {
    if (queryParams.count && queryParams.count > queryParams.offset) {
      return getExpertSurveys();
    }

    return null;
  };

  const scrollRef: RefObject<HTMLElement> = useBottomScrollListener(() => {
    handleBottomReached();
  });

  useEffect(() => {
    if (expertId) {
      setIsNewSearchTerm(true);
      getExpertSurveys(true);
    }
  }, [debouncedSearchTerm, expertId]);

  return (
    <Flex className={styles.container}>
      <AntDHeader title={t("surveys")} />
      <Flex
        vertical
        gap={16}
        className={styles.searchContainer}
        ref={scrollRef as RefObject<HTMLDivElement>}
      >
        <ExpertSurveyListSearch handleSetSearchTerm={handleSetSearchTerm} />
        {isNewSearchTerm && <Spin size="large" />}
        {!isNewSearchTerm && (
          <ExpertSurveyList
            expertSurveys={expertSurveys}
            isLoading={isLoading}
          />
        )}
      </Flex>
    </Flex>
  );
};
