import { ICellRendererParams } from "ag-grid-community";
import { Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";

export interface SpendRendererProps
  extends Partial<ICellRendererParams<{ spend: number }>> {}

export function SpendRenderer({ data }: SpendRendererProps) {
  const { t } = useTranslation("userMonitoringListPage");

  if (!data?.spend)
    return <Typography.Text type="secondary">-</Typography.Text>;

  return <Tag>{t(`${getSpendValue(data.spend)}`)}</Tag>;
}

const getSpendValue = (spend: number) => {
  if (spend < 20000) {
    return "small";
  }
  if (spend > 100000) {
    return "large";
  }

  return "mid";
};
