import { Icon } from "arbolus-ui-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  AdminService,
  CIQError,
  CIQUser,
  ErrorResponse,
  SORT_DIRECTION,
  SpecialPermissionsPaginatedRequest,
  ToasterService,
  USER_PAGE_SIZE
} from "@arbolus-technologies/api";
import { InternalSlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { HR } from "@arbolus-technologies/ui/components";
import {
  SEARCH_DEBOUNCE_TIMEOUT,
  SIDE_PANEL_SIZE,
  useDebounce
} from "@arbolus-technologies/utils";

import { ClientsListSlidePanel } from "../ClientsList/ClientsListSlidePanel";
import { TeamMemberContent } from "../TeamMemberContent/TeamMemberContent";
import { UserTable } from "../UserTable/UserTable";

import styles from "./UsersList.module.scss";

export interface UsersListProps {
  onSendOnboardingEmail: (userId: string) => void;
  adminService?: typeof AdminService;
}

const notification = new ToasterService();
export const UsersList: React.FC<UsersListProps> = ({
  onSendOnboardingEmail,
  adminService = AdminService
}) => {
  const { t } = useTranslation("usersList");
  const dispatch = useDispatch();

  const [usersList, setUsersList] = useState<CIQUser[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTeamMemberSlidePanelOpen, setIsTeamMemberSlidePanelOpen] =
    useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<CIQUser | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedValue = useDebounce<string>(
    searchTerm,
    SEARCH_DEBOUNCE_TIMEOUT
  );

  const fetchUsers = useCallback(
    (nextPage = 0) => {
      const nextOffset = nextPage * USER_PAGE_SIZE;
      setCurrentPage(nextPage);

      const apiParams: SpecialPermissionsPaginatedRequest = {
        searchTerm: debouncedValue,
        claimValues: selectedPermissionIds,
        offset: nextOffset,
        limit: USER_PAGE_SIZE,
        orderBy: "firstName",
        orderDirection: SORT_DIRECTION.ASCENDING
      };

      setIsLoading(true);
      adminService.getAdminUsers(apiParams).subscribe(
        ({ items, pagination }) => {
          setUsersList(items);
          setTotalCount(pagination.count);
          setIsLoading(false);
        },
        (err: ErrorResponse<CIQError>) => {
          notification.showError(err.message);
          setIsLoading(false);
        }
      );
    },
    [debouncedValue, selectedPermissionIds, adminService]
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const showAssignClientSlidePanel = (userId: string): void => {
    setSelectedUserId(userId);
    handleCloseTeamMemberSlidePanel();
    dispatch(PanelStoreActions.openPanel(PanelId.ClientList));
  };

  const handleCloseTeamMemberSlidePanel = () => {
    setIsTeamMemberSlidePanelOpen(false);
  };

  const handleUpdateListOnAssignClient = () => {
    fetchUsers(currentPage);
  };

  const handleUserNameClick = (user: CIQUser) => {
    setIsTeamMemberSlidePanelOpen(true);
    setUserSelected(user);
  };

  return (
    <>
      <UserTable
        onSendOnboardingEmail={onSendOnboardingEmail}
        onAssignSlidePanel={showAssignClientSlidePanel}
        onSearch={setSearchTerm}
        onPageChange={fetchUsers}
        onUserNameClick={handleUserNameClick}
        isLoading={isLoading}
        totalCount={totalCount}
        users={usersList}
        onFilter={setSelectedPermissionIds}
        selectedPermissionIds={selectedPermissionIds}
      />
      <InternalSlidePanel
        customCloseRequest={handleCloseTeamMemberSlidePanel}
        isOpen={isTeamMemberSlidePanelOpen}
        width={SIDE_PANEL_SIZE._720}
        hideHeader
      >
        <div className={styles.slidePanelContainerTitle}>
          <h2>{t("details")}</h2>
          <Icon
            name="close"
            fontSize="18px"
            onClick={handleCloseTeamMemberSlidePanel}
          />
        </div>
        <HR margin={{ top: 3, bottom: 3 }} />
        {userSelected && (
          <TeamMemberContent
            user={userSelected}
            handleAssignClient={showAssignClientSlidePanel}
            handleCloseTeamMemberSlidePanel={handleCloseTeamMemberSlidePanel}
          />
        )}
      </InternalSlidePanel>
      <ClientsListSlidePanel
        userId={selectedUserId}
        onUpdateListOnAssignClient={handleUpdateListOnAssignClient}
      />
    </>
  );
};
