import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

const baseIconHtml = (iconName: string) =>
  `<span class="material-symbols-sharp icon" style="font-size: 14px; display: block">${iconName}</span>`;

const baseFilledColorIconHtml = (iconName: string, color: ARBOLUS_COLORS) =>
  `<span class="material-symbols-sharp icon" style="font-size: 14px; display: block; color: ${color}; font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48 !important;">${iconName}</span>`;

export const agGridArbolusIcons = {
  menu: baseIconHtml("filter_list"),
  filter: baseIconHtml("filter_alt")
};

export const AgGridCustomIcons = {
  ...agGridArbolusIcons,
  newFolder: baseIconHtml("create_new_folder"),
  send: baseIconHtml("send"),
  equal: baseIconHtml("equal"),
  canopy: baseIconHtml("workspaces"),
  angle: baseIconHtml("zoom_in_map"),
  copy: baseIconHtml("content_copy"),
  reset: baseIconHtml("replay"),
  delete: baseIconHtml("delete"),
  download: baseIconHtml("download"),
  phone: baseIconHtml("phone"),
  email: baseIconHtml("email"),
  approve: baseFilledColorIconHtml(
    "check_circle",
    ARBOLUS_COLORS.bColorSecondaryGreen
  ),
  reject: baseFilledColorIconHtml("cancel", ARBOLUS_COLORS.bColorBaseOrange),
  sync: baseIconHtml("sync")
};
