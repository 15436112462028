import React from "react";

import { arbolusLogo } from "@arbolus-technologies/theme";

import banner from "./banner.png";

import styles from "./PageTemplate.module.scss";

export function PageTemplate({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img
          src={arbolusLogo}
          className={styles.logoImage}
          alt="Arbolus Logo"
        />
      </div>

      <div className={styles.content}>{children}</div>

      <div className={styles.footer}>
        <div className={styles.footerLogo}>
          <img
            src={arbolusLogo}
            className={styles.footerLogoImage}
            alt="Arbolus Logo"
          />
          <span>Fewer calls. Better Insights. Faster.</span>
        </div>
        <img
          className={styles.banner}
          src={banner}
          alt="1000 Europe's fastest growing companies 2023"
        />
      </div>
    </div>
  );
}
