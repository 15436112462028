import { Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ARBOLUS_INSTAGRAM,
  ARBOLUS_LINKEDIN
} from "@arbolus-technologies/features/common";
import { instagramLogo, linkedInLogo } from "@arbolus-technologies/theme";
import { LinkWrapper } from "@arbolus-technologies/ui/components";
import { GrowingCompanies } from "./GrowingCompanies/GrowingCompanies";
import { LinksAndSocials } from "./LinksAndSocials/LinksAndSocials";

const { Text } = Typography;

export const SurveyFooter: React.FC = () => {
  const { t } = useTranslation("surveyFooter");

  return (
    <Flex vertical gap={24}>
      <GrowingCompanies />
      <LinksAndSocials />
      <Flex justify="space-between">
        <Flex vertical>
          <Text type="secondary">{t("copyrightText1")}</Text>
          <Text type="secondary">{t("copyrightText2")}</Text>
        </Flex>
        <Flex gap={10} align="start">
          <LinkWrapper to={ARBOLUS_LINKEDIN} isExternal target="_blank">
            <img src={linkedInLogo} alt="LinkedIn logo" height={18} />
          </LinkWrapper>
          <LinkWrapper to={ARBOLUS_INSTAGRAM} isExternal target="_blank">
            <img src={instagramLogo} alt="Instagram logo" height={18} />
          </LinkWrapper>
        </Flex>
      </Flex>
    </Flex>
  );
};
