import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DiscoverExpert } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { useDiscoverExperts } from "../../../../../Hooks/Discover/useDiscoverExperts";
import { NAVIGATION_DIRECTION_ENUM } from "../../enums";
import { DiscoverMatchingFiltersPanelData } from "../../interfaces";
import { NavigationDirection } from "../../types";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { Button, Divider, Flex, Typography } from "antd";

interface HeaderProps {
  panelData: DiscoverMatchingFiltersPanelData;
}

const { Title } = Typography;

export const Header: React.FC<HeaderProps> = ({ panelData }) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");
  const dispatch = useDispatch();
  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const { expertId, workHistories, projectId } = panelData || {};
  const { expertListPaginated } = useDiscoverExperts({ projectId });

  const findExpertPosition = (
    expertList: DiscoverExpert[],
    searchExpertId: string
  ): number => expertList.findIndex((item) => item.id === searchExpertId);

  const currentExpertPosition = findExpertPosition(
    expertListPaginated?.items ?? [],
    expertId
  );

  const closePanel = () =>
    dispatch(PanelStoreActions.closePanel(PanelId.DiscoverMatchingFilters));

  const navigateToExpert = (direction: NavigationDirection) => {
    if (expertListPaginated) {
      const newIndex =
        direction === NAVIGATION_DIRECTION_ENUM.PREVIOUS
          ? Math.max(currentExpertPosition - 1, 0)
          : Math.min(
              currentExpertPosition + 1,
              expertListPaginated.items.length - 1
            );
      const expert = expertListPaginated.items[newIndex];
      const {
        id,
        fullName,
        title,
        isoCountryCode,
        lastPublicCompanyDate,
        profileImageUrl,
        searchMatchColumns,
        doNotContactStatus,
        customers
      } = expert;
      const panelData: DiscoverMatchingFiltersPanelData = {
        expertId: id,
        fullName,
        title,
        countryTwoLetterCode:
          metadataCountries.find(
            (country) => country.threeLetterCode === isoCountryCode
          )?.twoLetterCode ?? null,
        lastPublicCompanyDate,
        profileImageUrl,
        projectId,
        searchMatchColumns,
        doNotContactStatus,
        customers,
        workHistories
      };
      dispatch(
        PanelStoreActions.openPanel(PanelId.DiscoverMatchingFilters, panelData)
      );
    }
  };

  return (
    <Flex vertical>
      <Flex
        align="center"
        justify="space-between"
        style={{ padding: "16px 24px" }}
      >
        <Flex gap={12}>
          <AntDIcon
            fontSize="24px"
            name="close"
            onClick={closePanel}
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          />
          <Title level={5}>{t("filterMatch")}</Title>
        </Flex>
        <Flex gap={24} align="center" justify="center">
          <Button
            onClick={() => navigateToExpert(NAVIGATION_DIRECTION_ENUM.PREVIOUS)}
            icon={<AntDIcon fontSize="16px" name="chevronLeft" />}
            iconPosition="start"
            type="link"
            disabled={currentExpertPosition === 0}
            style={{ display: "flex", alignItems: "center" }}
          >
            {t("prev")}
          </Button>
          <Button
            onClick={() => navigateToExpert(NAVIGATION_DIRECTION_ENUM.NEXT)}
            icon={<AntDIcon fontSize="16px" name="chevronRight" />}
            iconPosition="end"
            type="link"
            disabled={
              currentExpertPosition + 1 === expertListPaginated?.items.length
            }
            style={{ display: "flex", alignItems: "center" }}
          >
            {t("next")}
          </Button>
        </Flex>
      </Flex>

      <Divider style={{ margin: "0" }} />
    </Flex>
  );
};
