export const RECAPTCHA_SITE_KEY =
  process.env.NX_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY;

export const RECAPTCHA_ACTIONS = {
  REINVITE: "REINVITE",
  COMPANY_ENQUIRY: "COMPANY_ENQUIRY",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  REGISTER: "REGISTER",
  LIST_CURRENCIES: "LIST_CURRENCIES"
};
