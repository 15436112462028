import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface ComplianceWarningModalProps {
  onOk: () => void;
  onCancel: () => void;
  isComplianceWarningModalOpen: boolean;
}

export const ComplianceWarningModal: React.FC<ComplianceWarningModalProps> = ({
  onCancel,
  onOk,
  isComplianceWarningModalOpen
}) => {
  const { t } = useTranslation("complianceWarningModal");

  return (
    <Modal
      title={t("title")}
      open={isComplianceWarningModalOpen}
      onOk={onOk}
      onCancel={onCancel}
      cancelText={t("cancelText")}
      okText={t("okText")}
    >
      {t("description")}
    </Modal>
  );
};
