import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ToasterService,
  UserService
} from "@arbolus-technologies/api";
import { Card, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { zip } from "rxjs";
import styles from "./Details.module.scss";
import { DetailsForm } from "./DetailsForm";

const { Text, Title } = Typography;

export interface ExpertProfileFormDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | undefined;
  linkedinProfile: string | undefined;
  profileImageUrl: string | undefined;
  title: string;
  overview: string | undefined;
  experienceLevel: string | undefined;
  hourlyRate?: number;
  isoCurrencyCode?: string;
  background?: string;
}

export interface DetailsProps {
  userId: string;
  expertProfile: ExpertProfileFormDetails | null;
  userService?: typeof UserService;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

const linkedinProfileFormatter = (linkedinProfile: string | undefined) => {
  if (linkedinProfile === "" || linkedinProfile === undefined) {
    return linkedinProfile;
  }

  return linkedinProfile?.includes("https://")
    ? linkedinProfile
    : `https://${linkedinProfile}`;
};

export const Details: React.FC<DetailsProps> = ({
  userId,
  expertProfile,
  userService = UserService,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertDetailSection");

  const updateExpertDetails = (values: ExpertProfileFormDetails) => {
    const {
      firstName,
      lastName,
      email,
      title,
      profileImageUrl,
      phoneNumber,
      linkedinProfile,
      overview,
      experienceLevel
    } = values;

    const userToUpdate = {
      id: userId,
      firstName,
      lastName,
      email,
      title,
      profileImageUrl,
      phoneNumber: phoneNumber?.includes("+") ? phoneNumber : `+${phoneNumber}`,
      linkedinProfile
    };

    const linkedinProfileUrl = linkedinProfileFormatter(linkedinProfile);

    zip(
      userService.updateProfile(userToUpdate),
      expertService.updateExpertProfile(
        expertProfile?.id!,
        overview ?? "",
        experienceLevel ?? "",
        ["", ""],
        linkedinProfileUrl,
        ""
      )
    ).subscribe(
      () => {
        notificationService.showSuccess(t("expertDetailsUpdated"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(
          error?.message || t("expertDetailsUpdateError")
        );
      }
    );
  };

  const TitleCard = () => (
    <div className={styles.titleWrapper}>
      <Title level={5}>{t("accountDetails")}</Title>
      <Text className={styles.subtitle}>{t("subtitleCard")}</Text>
    </div>
  );

  return (
    <Space direction="vertical" size={16} className={styles.space}>
      <Card title={<TitleCard />}>
        {expertProfile && (
          <DetailsForm
            expert={expertProfile}
            updateExpertDetails={updateExpertDetails}
            userId={userId}
          />
        )}
      </Card>
    </Space>
  );
};
