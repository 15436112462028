/* eslint-disable import/extensions */
import { replace } from "connected-react-router";
import i18next from "i18next";
import queryString from "query-string";
import { Epic } from "redux-observable";
import { EMPTY, forkJoin, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  Currency,
  Industry,
  Region,
  TimeZone,
  ToasterService,
  UserNotificationCountsResponse
} from "@arbolus-technologies/api";
import {
  Country,
  FeatureFlagsAppType
} from "@arbolus-technologies/models/common";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";
import { CacheStoreActions } from "@arbolus-technologies/stores/cache";

import { AppStoreActions, NavBarStoreActions } from "..";
import i18n from "../../../../../i18n";
import { AppConstants } from "../../../../constants";
import { APP_USER_ROLES, REDIRECT_STATES } from "../../../../constants/app";
import { PANEL_HASHES } from "../../../../constants/navigation/panelRoutes";
import { INBOX_NOTIFICATION_CATEGORY } from "../../../../constants/notifications";
import { ApiNonPaginatedResponse } from "../../../../models/api";
import {
  ExpertProjectListResponse,
  ListProject
} from "../../../../models/project";
import { LoggedInUser } from "../../../../models/user";
import {
  MetaService,
  ProjectService,
  RBServiceManager,
  UserService,
  UtilsService
} from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { ProjectStoreActions } from "../../../project/store";
import {
  GET_APP_DATA,
  GET_FEATURE_FLAGS,
  GET_USER_PROFILE
} from "../actions/AppActionTypes";
import { CLOSE_CONTENT_PANEL } from "../actions/ContentPanelActionTypes";
import { GET_PROJECT_LIST_FOR_NAVBAR } from "../actions/NavbarActionTypes";

const notification = new ToasterService();

const getUserProfileEpic: Epic<AppAction, AppAction, AppState> = (action$) =>
  action$.pipe(
    filter(isOfType(GET_USER_PROFILE)),
    switchMap(() =>
      UserService.getUserProfile().pipe(
        mergeMap((response: LoggedInUser) => {
          const { culture, userRoles, client, email } = response;
          const { host, protocol } = window.location;

          const baseDomain = process.env.NX_PUBLIC_BASE_URL;
          const currentDomain = `${protocol}//${host}`;
          const isDeployed = !host.includes("localhost");

          const isClient = userRoles.includes(APP_USER_ROLES.client);

          localStorage.setItem(AppConstants.LOCALSTORAGE.CULTURE, culture);
          i18n.changeLanguage(culture);

          if (isDeployed) {
            if (isClient) {
              // Client login in to account
              const subDomain = client?.subdomain!;
              if (currentDomain !== subDomain) {
                window.location.href = UtilsService.generateURL(subDomain);

                return of(
                  AppStoreActions.switchSubdomain(
                    REDIRECT_STATES.AUTH_SUBDOMAIN_SWITCH
                  )
                );
              }
              return of(
                AppStoreActions.getUserProfileSuccess(response),
                CacheStoreActions.getLoggedInUser(response),
                CacheStoreActions.getAPPType(false) // isAdmin?
              );
            }

            // Expert accessing from invalid subdomain
            if (baseDomain !== currentDomain) {
              window.location.href = UtilsService.generateURL(baseDomain!);
              return of(
                AppStoreActions.switchSubdomain(
                  REDIRECT_STATES.AUTH_SUBDOMAIN_SWITCH
                )
              );
            }
          }
          return of(
            AppStoreActions.getUserProfileSuccess(response),
            CacheStoreActions.getLoggedInUser(response),
            CacheStoreActions.getAPPType(false)
          );
        }),
        catchError((error) => {
          const { host, protocol } = window.location;
          const baseDomain = process.env.NX_PUBLIC_BASE_URL;
          const isDeployed = !host.includes("localhost");
          const currentDomain = `${protocol}//${host}`;

          if (isDeployed) {
            if (baseDomain !== currentDomain) {
              window.location.href = UtilsService.generateURL(baseDomain!);
              return of(
                AppStoreActions.switchSubdomain(
                  REDIRECT_STATES.GUEST_SUBDOMAIN_SWITCH
                )
              );
            }
          }

          return of(AppStoreActions.getUserProfileFailure(error));
        })
      )
    )
  );

const getProjectListForNavBarEpic: Epic<AppAction, AppAction, AppState> = (
  action$
) =>
  action$.pipe(
    filter(isOfType(GET_PROJECT_LIST_FOR_NAVBAR)),
    switchMap(() =>
      RBServiceManager.serviceCaller<
        ApiNonPaginatedResponse<ListProject>,
        ExpertProjectListResponse
      >(
        ProjectService.getClientProjectsList(),
        ProjectService.getExpertProjectsList()
      ).pipe(
        map((response) => {
          if ("pendingReferralCount" in response) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const expertResponse = response as ExpertProjectListResponse;
            return NavBarStoreActions.getProjectListForNavbarSuccess(
              expertResponse.items,
              expertResponse.pendingReferralCount
            );
          }
          return NavBarStoreActions.getProjectListForNavbarSuccess(
            response.items
          );
        }),
        catchError((error) =>
          of(NavBarStoreActions.getProjectListForNavbarFailure(error))
        )
      )
    )
  );

const getAppData: Epic<AppAction, AppAction, AppState> = (action$) =>
  action$.pipe(
    filter(isOfType(GET_APP_DATA)),
    switchMap(() =>
      forkJoin([
        MetaService.getCountries(),
        MetaService.getIndustries({ level: 0 }),
        MetaService.getIndustries({ level: 2 }),
        MetaService.getTimezones(),
        MetaService.getRegions(),
        MetaService.getCurrencies(),
        UserService.getNotificationCounts()
      ]).pipe(
        mergeMap(
          ([
            countries,
            industriesLevel0,
            industriesLevel2,
            timezones,
            regions,
            currencies,
            notifications
          ]) => {
            const countriesResponse =
              countries as ApiNonPaginatedResponse<Country>;
            const industriesLevel0Response =
              industriesLevel0 as ApiNonPaginatedResponse<Industry>;
            const industriesLevel2Response =
              industriesLevel2 as ApiNonPaginatedResponse<Industry>;
            const currenciesResponse =
              currencies as ApiNonPaginatedResponse<Currency>;
            const timezonesResponse =
              timezones as ApiNonPaginatedResponse<TimeZone>;
            const regionsResponse = regions as ApiNonPaginatedResponse<Region>;
            const notificationsResponse =
              notifications as UserNotificationCountsResponse;

            const { notificationCounts } = notificationsResponse;
            const notificationMap = new Map<
              INBOX_NOTIFICATION_CATEGORY,
              number
            >();
            Object.keys(notificationCounts).forEach((key) => {
              notificationMap.set(
                key as INBOX_NOTIFICATION_CATEGORY,
                notificationCounts[key]
              );
            });

            return of(
              AppStoreActions.getAppDataSuccess(
                countriesResponse.items,
                industriesLevel0Response.items,
                industriesLevel2Response.items,
                timezonesResponse.items,
                regionsResponse.items,
                currenciesResponse.items,
                notificationMap
              ),
              CacheStoreActions.getAppDataSuccess(
                countriesResponse.items,
                industriesLevel0Response.items,
                industriesLevel2Response.items,
                timezonesResponse.items,
                regionsResponse.items,
                currenciesResponse.items,
                notificationCounts
              ),
              CacheStoreActions.getAPPType(false)
            );
          }
        ),
        catchError((error) => {
          notification.showError(i18next.t("restService:somethingWrong"));
          return of(AppStoreActions.getAppDataFailure(error));
        })
      )
    )
  );

const completedReviewalCompleteEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isOfType(CLOSE_CONTENT_PANEL)),
    filter(
      (action) =>
        action.payload.panelKey === AppConstants.CONTENT_PANEL.CANDIDATE_PICKER
    ),
    filter(
      () =>
        store.value.project.referrals.filter(
          (r) => r.status !== REFERRAL_STATES.CANDIDATE
        ).length > 0
    ),
    map(() => ProjectStoreActions.completeCandidateReviewal())
  );

const onContentPanelCloseEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isOfType(CLOSE_CONTENT_PANEL)),
    switchMap(() => {
      const { hash, pathname, search } = store.value.router.location;
      const parsedHash = queryString.parse(hash);

      if (Object.keys(parsedHash).some((m) => PANEL_HASHES.includes(m))) {
        return of(replace(`${pathname}${search}`));
      }
      return EMPTY;
    })
  );

const getFeatureFlagsEpic: Epic<AppAction, AppAction, AppState> = (action$) =>
  action$.pipe(
    filter(isOfType(GET_FEATURE_FLAGS)),
    switchMap(() =>
      MetaService.getFeatureFlags(FeatureFlagsAppType.CLIENT).pipe(
        map(
          (res) => AppStoreActions.getFeatureFlagsSuccess(res),
          catchError((error) =>
            of(AppStoreActions.getFeatureFlagsFailure(error))
          )
        )
      )
    )
  );

const appEpics = [
  onContentPanelCloseEpic,
  getUserProfileEpic,
  getProjectListForNavBarEpic,
  getAppData,
  completedReviewalCompleteEpic,
  getFeatureFlagsEpic
];

export default appEpics;
