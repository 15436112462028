import {
  ClientService,
  CompanyService,
  DefaultToasterService,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

const queryParams = {
  offset: 0,
  limit: 100,
  orderBy: "firstName",
  orderDirection: SORT_DIRECTION.ASCENDING
} as const;

export async function getUsers(clientId: string, searchTerm: string) {
  try {
    const users = await ClientService.getTeamMembers(clientId, {
      ...queryParams,
      searchTerm
    }).toPromise();
    return users;
  } catch (error) {
    DefaultToasterService.showError(error.message);
  }

  return { items: [] };
}

export async function getCompanies(searchTerm: string) {
  try {
    const companies = await CompanyService.listCompanies({
      ...queryParams,
      searchTerm
    }).toPromise();
    return companies;
  } catch (error) {
    DefaultToasterService.showError(error.message);
  }

  return { items: [] };
}
