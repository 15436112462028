import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

import {
  ArbolusGPTButton,
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";

import { TranscriptsTable } from "../../Modules/TranscriptsTable/TranscriptsTable";

type TranscriptListPageProps = RouteComponentProps<{
  projectId: string;
}>;

export const TranscriptsListPage: React.FC<TranscriptListPageProps> = ({
  match: {
    params: { projectId }
  }
}) => {
  const { t } = useTranslation("transcriptListPage");
  const { trackClick } = useArbolusTracking();

  const loggedUserMail = useSelector(CacheSelector.userProfile()).email;
  const projectName = useSelector(ProjectNxSelector.projectName());

  return (
    <MainPageLayout
      title={t("transcripts")}
      stickyHeader
      backLink={PROJECT_ROUTE(projectId).pathname}
      rightButtonContainer={
        <ArbolusGPTButton
          projectPath={`${PROJECT_ROUTE(projectId).pathname} | ${projectName}>`}
          projectId={projectId}
          onTrack={() =>
            trackClick(MixPanelEventNames.TranscriptsArbolusGPT, {
              loggedUserMail
            })
          }
        />
      }
    >
      <TranscriptsTable projectId={projectId} />
    </MainPageLayout>
  );
};
