import { Trans, useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

import { EXPERT_NOTIFICATION_REJECTION_TYPE } from "../../../../../constants/notifications";

interface ExpertApplicationRejectedPanelProps {
  onClose: () => void;
  rejectionType: EXPERT_NOTIFICATION_REJECTION_TYPE;
}

const SUPPORT_EMAIL = "support@arbolus.com";

const ExpertApplicationRejectedPanel = ({
  onClose,
  rejectionType = EXPERT_NOTIFICATION_REJECTION_TYPE.Review
}: ExpertApplicationRejectedPanelProps): JSX.Element => {
  const { t } = useTranslation("expertApplicationRejectedPanel");

  const isReviewFailed =
    rejectionType === EXPERT_NOTIFICATION_REJECTION_TYPE.Review;
  const titleKey = isReviewFailed ? "applicationDeclined" : "complianceIssue";
  const headerKey = isReviewFailed
    ? "whyWasItDeclined"
    : "whyDidNotIPassCompliance";
  const descKey = isReviewFailed
    ? "reviewalFailedMessage"
    : "complianceFailedMessage";

  return (
    <SimpleBar className="content-panel-body application-declined-container simplebar-light">
      <div className="panel-header">
        <h2>{t(titleKey)}</h2>
        <div className="btn-close" onClick={onClose}>
          <i className="ciq-icon ciq-close" />
        </div>
      </div>
      <hr />
      <div className="panel-body">
        <div className="info-container">
          <h3>{t(headerKey)}</h3>
          <p>
            <Trans
              ns="expertApplicationRejectedPanel"
              i18nKey={descKey}
              values={{ email: SUPPORT_EMAIL }}
              components={[
                <br key="1" />,
                <a href={`mailto:${SUPPORT_EMAIL}`} key="2" />
              ]}
            />
          </p>
        </div>
      </div>
    </SimpleBar>
  );
};

export default ExpertApplicationRejectedPanel;
