import { GridApi } from "ag-grid-community";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CIQError,
  CompanyService,
  DefaultToasterService,
  ErrorResponse,
  GetUserCompaniesResponse,
  LIST_COMPANIES_ORDER_BY,
  MonitoringListService,
  SORT_DIRECTION,
  UserCompanyItem
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

const queryParams = {
  sort: [
    {
      orderBy: LIST_COMPANIES_ORDER_BY.Name,
      orderDirection: SORT_DIRECTION.ASCENDING
    }
  ]
};

interface useCompaniesRequestsParams {
  userId: string;
  searchTerm: string;
  gridApi?: GridApi;
  actionAfterClose?: () => void;
}

export const useCompaniesRequests = ({
  userId,
  searchTerm,
  gridApi,
  actionAfterClose
}: useCompaniesRequestsParams) => {
  const { loggedInId } = useSelector(CacheSelector.currentUserSelector());
  const [companiesList, setCompaniesList] = useState<UserCompanyItem[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation("addCompaniesModal");

  const getCompanies = useCallback(() => {
    setIsLoading(true);
    CompanyService.listCompaniesByUser(userId, {
      ...queryParams,
      limit: searchTerm ? 100 : 10,
      searchTerm,
      offset: searchTerm ? 0 : companiesList.length + offset,
      sorting: ["CompanySignalsCount,desc", "InsightsCount,desc"]
    })
      .then((res: GetUserCompaniesResponse) => {
        setCompaniesList((prevCompanies) => [...prevCompanies, ...res.items]);
        setTotalCount(res.pagination.count);
        if (searchTerm) {
          setOffset(res.pagination.offset);
        }
        setIsLoading(false);
      })
      .catch((error: ErrorResponse<CIQError>) => {
        DefaultToasterService.showApiErrors(error);
        setIsLoading(false);
      });
  }, [userId, searchTerm, companiesList, offset]);

  const addCompanies = async (selectedCompanies: UserCompanyItem[]) => {
    setIsLoading(true);
    try {
      await MonitoringListService.addCompanies({
        companyIds: selectedCompanies.map((company) => company.id),
        userIds: [loggedInId]
      });
      DefaultToasterService.showSuccess(t("companiesAddedSuccess"));
      gridApi?.refreshServerSide({ purge: true });
      setIsLoading(false);
      actionAfterClose?.();
    } catch (error) {
      DefaultToasterService.showError(error.message);
      console.error(error);
      setIsLoading(false);
    }
  };

  return {
    companiesList,
    isLoading,
    totalCount,
    getCompanies,
    resetCompanies: () => {
      setCompaniesList([]);
      setOffset(0);
    },
    addCompanies
  };
};
