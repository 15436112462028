import { Icon } from "arbolus-ui-components";
import { Formik, FormikHelpers } from "formik";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { LinkedInLoginButton } from "@arbolus-technologies/auth";
import { AuthPageHeader } from "@arbolus-technologies/features/common";
import { AppSelector as AppSelectorNx } from "@arbolus-technologies/stores/app";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { PublicRouteConstants } from "../../../../constants";
import { FEDERATED_AUTH_PROVIDER } from "../../../../constants/auth";
import { LoginFormValidationSchema } from "./LoginFormSchema";

export interface LoginFormValues {
  email: string;
  password: string;
  remember: boolean;
}

export function LoginFormSection({
  isFederateReady,
  handleLoginClicked,
  renderLoginForm,
  handleFederatedLoginClicked
}: {
  isFederateReady: boolean;
  handleLoginClicked: (
    { email, password, remember }: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) => void;
  renderLoginForm: () => React.ReactNode;
  handleFederatedLoginClicked: (provider: string) => void;
}) {
  const { t } = useTranslation("login");

  const { LinkedInAuth, MicrosoftAppleSSO } = useSelector(
    AppSelectorNx.getFeatureFlags()
  );

  const queryParams = queryString.parse(window.location.search);
  const surveyExpertId = queryString.parse(
    queryParams.search as string
  ).surveyExpertId;

  return (
    <div className="login-page">
      <AuthPageHeader title="Sign In" />

      <Formik<LoginFormValues>
        initialValues={{
          email: "",
          password: "",
          remember: false
        }}
        validationSchema={LoginFormValidationSchema}
        onSubmit={handleLoginClicked}
      >
        {renderLoginForm}
      </Formik>

      {isFederateReady && !surveyExpertId && (
        <div className="another-login-section">
          <span>{t("socialAuth")}</span>

          <div className="social-login-container">
            <Link to={PublicRouteConstants.SSO} className="external-provider">
              <Icon
                name="key"
                color={ARBOLUS_COLORS.bColorGrayIcon}
                fontSize="24px"
              />
              SSO Okta
            </Link>

            {MicrosoftAppleSSO && (
              <div
                className="external-provider"
                onClick={() =>
                  handleFederatedLoginClicked(FEDERATED_AUTH_PROVIDER.APPLE)
                }
              >
                <div className="apple-icon" />
                Apple
              </div>
            )}

            <div
              className="external-provider"
              onClick={() =>
                handleFederatedLoginClicked(FEDERATED_AUTH_PROVIDER.GOOGLE)
              }
            >
              <div className="google-icon" />
              Google
            </div>

            {MicrosoftAppleSSO && (
              <div
                className="external-provider"
                onClick={() =>
                  handleFederatedLoginClicked(FEDERATED_AUTH_PROVIDER.MICROSOFT)
                }
              >
                <div className="microsoft-icon" />
                Microsoft
              </div>
            )}

            {LinkedInAuth && <LinkedInLoginButton />}
          </div>
        </div>
      )}
    </div>
  );
}
