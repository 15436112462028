import { Button, Flex } from "antd";
import { Formik } from "formik";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  INBOX_NOTIFICATION_CATEGORY,
  clientCategories,
  expertCategories
} from "../../../../../../constants/notifications";
import {
  NotificationSubscription,
  ProjectSettingsUpdateRequest
} from "../../../../../../models/project";
import { RBServiceManager } from "../../../../../../services";
import { AuthSelector } from "../../../../../auth/store";
import NotificationSettingItem from "./NotificationSettingItem";

const NOTIFICATION_SETTING_ITEMS_MAP = new Map([
  [
    INBOX_NOTIFICATION_CATEGORY.Messages,
    {
      title: i18next.t("notificationSettings:messages"),
      subTitle: i18next.t("notificationSettings:messagesDescription")
    }
  ],
  [
    INBOX_NOTIFICATION_CATEGORY.SharedDocs,
    {
      title: i18next.t("notificationSettings:sharedDocs"),
      subTitle: i18next.t("notificationSettings:sharedDocsDescription")
    }
  ],
  [
    INBOX_NOTIFICATION_CATEGORY.NewReferrals,
    {
      title: i18next.t("notificationSettings:candidates"),
      subTitle: i18next.t("notificationSettings:candidatesDescription")
    }
  ],
  [
    INBOX_NOTIFICATION_CATEGORY.Compliance,
    {
      title: i18next.t("notificationSettings:compliance"),
      subTitle: i18next.t("notificationSettings:complianceDescription")
    }
  ],
  [
    INBOX_NOTIFICATION_CATEGORY.Approvals,
    {
      title: i18next.t("notificationSettings:updates"),
      subTitle: i18next.t("notificationSettings:updatesDescription")
    }
  ],
  [
    INBOX_NOTIFICATION_CATEGORY.NewJoins,
    {
      title: i18next.t("notificationSettings:newJoins"),
      subTitle: i18next.t("notificationSettings:newJoinsDescription")
    }
  ],
  [
    INBOX_NOTIFICATION_CATEGORY.Applications,
    {
      title: i18next.t("notificationSettings:updates"),
      subTitle: i18next.t("notificationSettings:applicationsDescription")
    }
  ],
  [
    INBOX_NOTIFICATION_CATEGORY.Canopies,
    {
      title: i18next.t("notificationSettings:arbolusCanopy"),
      subTitle: i18next.t("notificationSettings:arbolusCanopyDescription")
    }
  ]
]);

interface NotificationSettingsListProps {
  isPanel?: boolean;
  notificationSubscription: NotificationSubscription;
  pushNotifications?: boolean;
  isLoading: boolean;
  onUpdateSettings: (projectSettings: ProjectSettingsUpdateRequest) => void;
}

interface NotificationSettingsFormValues {
  notificationSubscription: NotificationSubscription;
  pushNotifications?: boolean;
}

const NotificationSettingsList: React.FC<NotificationSettingsListProps> = ({
  isPanel,
  notificationSubscription,
  pushNotifications,
  isLoading,
  onUpdateSettings
}: NotificationSettingsListProps) => {
  const { t } = useTranslation("notificationSettings");

  const isClientUser = useSelector(AuthSelector.authIsClientUserSelector());

  if (!isClientUser) {
    NOTIFICATION_SETTING_ITEMS_MAP.set(
      INBOX_NOTIFICATION_CATEGORY.NewReferrals,
      {
        title: i18next.t("notificationSettings:newReferrals"),
        subTitle: i18next.t("notificationSettings:newReferralsDescription")
      }
    );
  }

  const categories = RBServiceManager.valueSelector(
    clientCategories,
    expertCategories
  ).filter(
    (category) =>
      ![
        INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
        INBOX_NOTIFICATION_CATEGORY.EventInvites
      ].includes(category)
  );

  return (
    <Formik<NotificationSettingsFormValues>
      initialValues={{
        notificationSubscription,
        pushNotifications
      }}
      enableReinitialize
      onSubmit={(data): void => {
        onUpdateSettings({
          notificationSubscription: data.notificationSubscription,
          pushNotifications: data.pushNotifications!
        });
      }}
    >
      {({ handleSubmit, dirty }): JSX.Element => {
        const saveButtonDisabled = !dirty || isLoading;

        return (
          <div className="notification-setting-list">
            {isPanel && (
              <>
                <div className="header-container">
                  <h3>{t("instantAlerts")}</h3>
                  <p>{t("alertsMessage")}</p>
                  <NotificationSettingItem
                    title={t("browserNotification")}
                    fieldKey="pushNotifications"
                    isSaving={isLoading}
                  />
                </div>
                <hr />
              </>
            )}
            <div className="middle-container">
              <div className="email-description">
                <h3>{t("emails")}</h3>
                <p>{t("emailMessage")}</p>
              </div>
              <div className="notification-settings-items">
                {categories.map((category) => (
                  <NotificationSettingItem
                    key={category}
                    fieldKey={`notificationSubscription.${category}`}
                    title={NOTIFICATION_SETTING_ITEMS_MAP.get(category)?.title!}
                    subTitle={
                      NOTIFICATION_SETTING_ITEMS_MAP.get(category)?.subTitle
                    }
                    isSaving={isLoading}
                  />
                ))}
              </div>
              {!isClientUser && (
                <>
                  <hr />
                  <div className="sms-description">
                    <h3>{t("sms")}</h3>
                    <p>{t("smsMessage")}</p>
                    <NotificationSettingItem
                      title={t("smsNotification")}
                      fieldKey={"notificationSubscription.MobileMessage"}
                      isSaving={isLoading}
                    />
                  </div>
                </>
              )}
            </div>
            <Flex justify="flex-end">
              <Button
                // @ts-ignore
                onClick={handleSubmit}
                type="primary"
                disabled={saveButtonDisabled}
                size="large"
              >
                {isPanel ? t("save") : t("saveNotifications")}
              </Button>
            </Flex>
          </div>
        );
      }}
    </Formik>
  );
};

export default NotificationSettingsList;
