import React, { useEffect } from "react";
import { Control, UseFormWatch } from "react-hook-form";

import { SelectOption } from "@arbolus-technologies/models/common";
import { CustomSelectLabelController } from "@arbolus-technologies/ui/components";

import { INCIDENT_CATEGORY, INCIDENT_FORM } from "../../../../enums";

interface IncidentCategorySelectorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
  error?: string;
  placeholder: string;
  noOptionsMessage: string;
  onChange: (category: INCIDENT_CATEGORY) => void;
}

export const IncidentCategorySelector: React.FC<
  IncidentCategorySelectorProps
> = ({ control, watch, error, placeholder, noOptionsMessage, onChange }) => {
  const categorySelected: INCIDENT_CATEGORY = watch(INCIDENT_FORM.CATEGORY);

  const categories: SelectOption[] = Object.values(INCIDENT_CATEGORY).map(
    (category): SelectOption => ({
      value: category,
      label: category
    })
  );

  const customLabel = categories.find(
    (category) => category.value === categorySelected
  );

  useEffect(() => onChange(categorySelected), [categorySelected, onChange]);

  return (
    <CustomSelectLabelController
      name={INCIDENT_FORM.CATEGORY}
      control={control}
      options={categories}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      error={error}
      customLabel={customLabel?.label || INCIDENT_CATEGORY.CALL}
    />
  );
};
