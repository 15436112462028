import {
  Angle,
  AngleDraftResponse,
  AngleSingleDraft,
  AngleToSend,
  BusinessEntitiesItem,
  CIQError,
  CreatedResponse,
  DeletedResponse,
  Document,
  EngagementAgreement,
  ErrorResponse,
  IChatMessage,
  PagedListResponse,
  PaginatedRequest,
  Project,
  ProjectNx,
  User
} from "@arbolus-technologies/api";
import { ISearchTerm } from "@arbolus-technologies/models/common";
import {
  ChatUserRole,
  OnlineMembers,
  SimplifiedCreateProjectPayload,
  SimplifiedEditProjectPayload,
  UpdatedMember
} from "@arbolus-technologies/models/project";

import {
  CLEAR_PROJECT_MANAGERS,
  CREATE_ANGLE,
  CREATE_ANGLE_FAILURE,
  CREATE_ANGLE_SUCCESS,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_OLD,
  CREATE_PROJECT_SUCCESS,
  ClearProjectManagersAction,
  CreateAngleAction,
  CreateAngleFailureAction,
  CreateAngleSuccessAction,
  CreateProjectAction,
  CreateProjectFailureAction,
  CreateProjectSuccessAction,
  DELETE_ANGLE,
  DELETE_ANGLE_FAILURE,
  DELETE_ANGLE_SUCCESS,
  DELETE_CHAT_MESSAGE_SUCCESS,
  DELETE_PROJECT_DRAFT,
  DELETE_PROJECT_DRAFT_FAILURE,
  DELETE_PROJECT_DRAFT_SUCCESS,
  DeleteAngleAction,
  DeleteAngleFailureAction,
  DeleteAngleSuccessAction,
  DeleteChatMessageSuccessAction,
  DeleteProjectDraftAction,
  DeleteProjectDraftFailureAction,
  DeleteProjectDraftSuccessAction,
  FILTER_DASHBOARD_BY_ANGLE,
  FORCE_RELOAD_PROJECTS,
  FilterDashboardByAngleAction,
  ForceReloadProjectsAction,
  GET_ANGLE,
  GET_ANGLES_LIST,
  GET_ANGLES_LIST_FAILURE,
  GET_ANGLES_LIST_SUCCESS,
  GET_ANGLE_FAILURE,
  GET_ANGLE_SUCCESS,
  GET_BUSINESS_ENTITIES,
  GET_BUSINESS_ENTITIES_FAILURE,
  GET_BUSINESS_ENTITIES_SUCCESS,
  GET_CHAT_FILES,
  GET_CHAT_FILES_FAILURE,
  GET_CHAT_FILES_SUCCESS,
  GET_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_FAILURE,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_CLIENT_ID_SUCCESS,
  GET_PROJECT_ENGAGEMENT_AGREEMENTS,
  GET_PROJECT_ENGAGEMENT_AGREEMENTS_FAILURE,
  GET_PROJECT_ENGAGEMENT_AGREEMENTS_SUCCESS,
  GetAngleAction,
  GetAngleFailureAction,
  GetAngleSuccessAction,
  GetAnglesListAction,
  GetAnglesListFailureAction,
  GetAnglesListSuccessAction,
  GetBusinessEntitiesProjectAction,
  GetBusinessEntitiesProjectFailureAction,
  GetBusinessEntitiesProjectSuccessAction,
  GetChatFilesAction,
  GetChatFilesFailureAction,
  GetChatFilesSuccessAction,
  GetChatMessagesAction,
  GetChatMessagesFailureAction,
  GetChatMessagesSuccessAction,
  GetProjectClientIDSuccessAction,
  GetProjectEngagementAgreementsAction,
  GetProjectEngagementAgreementsFailureAction,
  GetProjectEngagementAgreementsSuccessAction,
  LOAD_PROJECT,
  LOAD_PROJECT_FAILURE,
  LOAD_PROJECT_SUCCESS,
  LoadProjectAction,
  LoadProjectFailureAction,
  LoadProjectSuccessAction,
  RECEIVE_NEW_CHAT_MESSAGE,
  RESET_CHAT_FILES,
  RESET_CHAT_MESSAGES,
  ReceiveNewChatMessageAction,
  ResetChatFilesAction,
  ResetChatMessagesAction,
  SAVE_ANGLE,
  SAVE_ANGLE_FAILURE,
  SAVE_ANGLE_SUCCESS,
  SAVE_ARBOLUS_TEAM,
  SAVE_PROJECT_MANAGERS,
  SELECT_FILE_TO_SHARE,
  SEND_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE_FAILURE,
  SEND_CHAT_MESSAGE_SUCCESS,
  SET_DISPLAY_TIMEZONE,
  SHARE_CHAT_FILES,
  SHARE_CHAT_FILES_FAILURE,
  SHARE_CHAT_FILES_SUCCESS,
  SIMPLIFIED_CREATE_PROJECT,
  SIMPLIFIED_CREATE_PROJECT_FAILURE,
  SIMPLIFIED_CREATE_PROJECT_SUCCESS,
  SIMPLIFIED_EDIT_PROJECT,
  SIMPLIFIED_EDIT_PROJECT_FAILURE,
  SIMPLIFIED_EDIT_PROJECT_SUCCESS,
  STORE_ONLINE_CHAT_MEMBERS,
  SaveAngleAction,
  SaveAngleFailureAction,
  SaveAngleSuccessAction,
  SaveArbolusTeamAction,
  SaveProjectManagersAction,
  SelectFileToShareAction,
  SendChatMessageAction,
  SendChatMessageFailureAction,
  SendChatMessageSuccessAction,
  SetDisplayTimezoneAction,
  ShareChatFilesAction,
  ShareChatFilesFailureAction,
  ShareChatFilesSuccessAction,
  SimplifiedCreateProjectAction,
  SimplifiedCreateProjectFailureAction,
  SimplifiedCreateProjectSuccessAction,
  SimplifiedEditProjectAction,
  SimplifiedEditProjectFailureAction,
  SimplifiedEditProjectSuccessAction,
  StoreOnlineMembersByChatAction,
  UPDATE_ANGLE,
  UPDATE_ANGLE_FAILURE,
  UPDATE_ANGLE_SUCCESS,
  UPDATE_ONLINE_CHAT_MEMBERS,
  UpdateAngleAction,
  UpdateAngleFailureAction,
  UpdateAngleSuccessAction,
  UpdateOnlineMembersByChatAction
} from "./ProjectActionTypes";

export const loadProject = (projectId: string): LoadProjectAction => ({
  type: LOAD_PROJECT,
  payload: {
    projectId
  }
});

export const loadProjectSuccess = (
  projectData: Project
): LoadProjectSuccessAction => ({
  type: LOAD_PROJECT_SUCCESS,
  payload: {
    projectData
  }
});

export const loadProjectFailure = (): LoadProjectFailureAction => ({
  type: LOAD_PROJECT_FAILURE
});

// Save Arbolus Team Project Managers
export const saveProjectManagers = (
  projectManagers: User[],
  primaryProjectLead: User | undefined,
  secondaryProjectLead: User | undefined
): SaveProjectManagersAction => ({
  type: SAVE_PROJECT_MANAGERS,
  payload: {
    projectManagers,
    primaryProjectLead,
    secondaryProjectLead
  }
});

// Save Arbolus Team Project Managers
export const saveArbolusTeam = (
  projectManagers: User[],
  primaryProjectLead: User | null,
  secondaryProjectLead: User | null
): SaveArbolusTeamAction => ({
  type: SAVE_ARBOLUS_TEAM,
  payload: {
    projectManagers,
    primaryProjectLead,
    secondaryProjectLead
  }
});

// SAVE ANGLE
export const saveAngle = (angleData: Angle): SaveAngleAction => ({
  type: SAVE_ANGLE,
  payload: {
    angleData
  }
});

export const saveAngleSuccess = (): SaveAngleSuccessAction => ({
  type: SAVE_ANGLE_SUCCESS
});

export const saveAngleFailure = (
  error: ErrorResponse<CIQError>
): SaveAngleFailureAction => ({
  type: SAVE_ANGLE_FAILURE,
  error
});

//GET ENGAGEMENT AGREEMENTS

export const getEngagementAgreements = (
  clientId: string
): GetProjectEngagementAgreementsAction => ({
  type: GET_PROJECT_ENGAGEMENT_AGREEMENTS,
  payload: {
    clientId
  }
});

export const getEngagementAgreementsSuccess = (
  responseEngagementAgreement: EngagementAgreement
): GetProjectEngagementAgreementsSuccessAction => ({
  type: GET_PROJECT_ENGAGEMENT_AGREEMENTS_SUCCESS,
  payload: {
    responseEngagementAgreement
  }
});

export const getEngagementAgreementsFailure = (
  error: ErrorResponse<CIQError>
): GetProjectEngagementAgreementsFailureAction => ({
  type: GET_PROJECT_ENGAGEMENT_AGREEMENTS_FAILURE,
  error
});

export const getBusinessEntitiesProject = (
  clientId: string
): GetBusinessEntitiesProjectAction => ({
  type: GET_BUSINESS_ENTITIES,
  payload: {
    clientId
  }
});

export const getBusinessEntitiesProjectSuccess = (
  responseBusinessEntities: BusinessEntitiesItem[]
): GetBusinessEntitiesProjectSuccessAction => ({
  type: GET_BUSINESS_ENTITIES_SUCCESS,
  payload: {
    responseBusinessEntities
  }
});

export const getBusinessEntitiesProjectFailure = (
  error: ErrorResponse<CIQError>
): GetBusinessEntitiesProjectFailureAction => ({
  type: GET_BUSINESS_ENTITIES_FAILURE,
  payload: {
    error
  }
});

// CREATE ANGLE

export const createAngle = (
  angleData: AngleToSend,
  projectDraftId: string
): CreateAngleAction => ({
  type: CREATE_ANGLE,
  payload: {
    angleData,
    projectDraftId
  }
});

export const createAngleSuccess = (): CreateAngleSuccessAction => ({
  type: CREATE_ANGLE_SUCCESS
});

export const createAngleFailure = (
  error: ErrorResponse<CIQError>
): CreateAngleFailureAction => ({
  type: CREATE_ANGLE_FAILURE,
  payload: {
    error
  }
});

// CREATE PROJECT V2

export const createProjectOld = (
  projectDraft: ProjectNx
): CreateProjectAction => ({
  type: CREATE_PROJECT_OLD,
  payload: { projectDraft }
});

export const createProjectSuccess = (): CreateProjectSuccessAction => ({
  type: CREATE_PROJECT_SUCCESS
});

export const createProjectFailure = (): CreateProjectFailureAction => ({
  type: CREATE_PROJECT_FAILURE
});

// CREATE PROJECT V3 - SIMPLIFIED

export const simplifiedCreateProject = (
  apiPayload: SimplifiedCreateProjectPayload
): SimplifiedCreateProjectAction => ({
  type: SIMPLIFIED_CREATE_PROJECT,
  payload: { apiPayload }
});

export const simplifiedCreateProjectSuccess =
  (): SimplifiedCreateProjectSuccessAction => ({
    type: SIMPLIFIED_CREATE_PROJECT_SUCCESS
  });

export const simplifiedCreateProjectFailure =
  (): SimplifiedCreateProjectFailureAction => ({
    type: SIMPLIFIED_CREATE_PROJECT_FAILURE
  });

export const simplifiedEditProject = (
  apiPayload: SimplifiedEditProjectPayload
): SimplifiedEditProjectAction => ({
  type: SIMPLIFIED_EDIT_PROJECT,
  payload: { apiPayload }
});

export const simplifiedEditProjectSuccess = (
  updatedProject: SimplifiedEditProjectPayload
): SimplifiedEditProjectSuccessAction => ({
  type: SIMPLIFIED_EDIT_PROJECT_SUCCESS,
  payload: { updatedProject }
});

export const simplifiedEditProjectFailure =
  (): SimplifiedEditProjectFailureAction => ({
    type: SIMPLIFIED_EDIT_PROJECT_FAILURE
  });

// DELETE PROJECT DRAFT

export const deleteProjectDraft = (
  projectDraftId: string
): DeleteProjectDraftAction => ({
  type: DELETE_PROJECT_DRAFT,
  payload: { projectDraftId }
});

export const deleteProjectDraftSuccess =
  (): DeleteProjectDraftSuccessAction => ({
    type: DELETE_PROJECT_DRAFT_SUCCESS
  });

export const deleteProjectDraftFailure =
  (): DeleteProjectDraftFailureAction => ({
    type: DELETE_PROJECT_DRAFT_FAILURE
  });

// GET ANGLES LIST

export const getAnglesList = (projectDraftId: string): GetAnglesListAction => ({
  type: GET_ANGLES_LIST,
  payload: {
    projectDraftId
  }
});

export const getAnglesListSuccess = (
  angleDraftsList: AngleDraftResponse[]
): GetAnglesListSuccessAction => ({
  type: GET_ANGLES_LIST_SUCCESS,
  payload: {
    angleDraftsList
  }
});

export const getAnglesListFailure = (
  error: ErrorResponse<CIQError>
): GetAnglesListFailureAction => ({
  type: GET_ANGLES_LIST_FAILURE,
  payload: {
    error
  }
});

// DELETE ANGLE

export const deleteAngle = (angleDraftId: string): DeleteAngleAction => ({
  type: DELETE_ANGLE,
  payload: {
    angleDraftId
  }
});

export const deleteAngleSuccess = (
  angleDraftDeletedId: string
): DeleteAngleSuccessAction => ({
  type: DELETE_ANGLE_SUCCESS,
  payload: {
    angleDraftDeletedId
  }
});

export const deleteAngleFailure = (
  error: ErrorResponse<CIQError>
): DeleteAngleFailureAction => ({
  type: DELETE_ANGLE_FAILURE,
  payload: {
    error
  }
});

// GET ANGLE

export const getAngle = (angleDraftId: string): GetAngleAction => ({
  type: GET_ANGLE,
  payload: {
    angleDraftId
  }
});

export const getAngleSuccess = (
  angleResponse: AngleSingleDraft
): GetAngleSuccessAction => ({
  type: GET_ANGLE_SUCCESS,
  payload: {
    angleResponse
  }
});

export const getAngleFailure = (
  error: ErrorResponse<CIQError>
): GetAngleFailureAction => ({
  type: GET_ANGLE_FAILURE,
  payload: {
    error
  }
});

// UPDATE ANGLE

export const updateAngle = (
  angleDraft: AngleToSend,
  projectDraftId: string
): UpdateAngleAction => ({
  type: UPDATE_ANGLE,
  payload: {
    angleDraft,
    projectDraftId
  }
});

export const updateAngleSuccess = (): UpdateAngleSuccessAction => ({
  type: UPDATE_ANGLE_SUCCESS
});

export const updateAngleFailure = (
  error: ErrorResponse<CIQError>
): UpdateAngleFailureAction => ({
  type: UPDATE_ANGLE_FAILURE,
  payload: {
    error
  }
});

export const forceReloadProjects = (): ForceReloadProjectsAction => ({
  type: FORCE_RELOAD_PROJECTS
});

export const clearProjectManagers = (): ClearProjectManagersAction => ({
  type: CLEAR_PROJECT_MANAGERS
});

export const getProjectClientIdSuccess = (
  clientId: string
): GetProjectClientIDSuccessAction => ({
  type: GET_CLIENT_ID_SUCCESS,
  payload: {
    clientId
  }
});

export const filterDashboardByAngle = (
  projectId: string,
  angleId?: string
): FilterDashboardByAngleAction => ({
  type: FILTER_DASHBOARD_BY_ANGLE,
  payload: {
    projectId,
    angleId
  }
});

// GET CHAT MESSAGES

export const getChatMessages = (
  projectId: string,
  chatId: string,
  isInitialFetch: boolean,
  queryParams: PaginatedRequest<"Created">
): GetChatMessagesAction => ({
  type: GET_CHAT_MESSAGES,
  payload: {
    projectId,
    chatId,
    isInitialFetch,
    queryParams
  }
});
export const getChatMessagesSuccess = (
  response: PagedListResponse<IChatMessage>
): GetChatMessagesSuccessAction => ({
  type: GET_CHAT_MESSAGES_SUCCESS,
  payload: {
    response
  }
});
export const getChatMessagesFailure = (
  error: ErrorResponse<CIQError>
): GetChatMessagesFailureAction => ({
  type: GET_CHAT_MESSAGES_FAILURE,
  payload: {
    error
  }
});

export const resetChatMessages = (): ResetChatMessagesAction => ({
  type: RESET_CHAT_MESSAGES
});

// GET CHAT MESSAGES

export const sendChatMessage = (
  projectId: string,
  chatId: string,
  message: string
): SendChatMessageAction => ({
  type: SEND_CHAT_MESSAGE,
  payload: {
    projectId,
    chatId,
    message
  }
});
export const sendChatMessageSuccess = (): SendChatMessageSuccessAction => ({
  type: SEND_CHAT_MESSAGE_SUCCESS
});
export const sendChatMessageFailure = (
  error: ErrorResponse<CIQError>
): SendChatMessageFailureAction => ({
  type: SEND_CHAT_MESSAGE_FAILURE,
  payload: {
    error
  }
});

export const receiveNewChatMessage = (
  message: IChatMessage
): ReceiveNewChatMessageAction => ({
  type: RECEIVE_NEW_CHAT_MESSAGE,
  payload: {
    message
  }
});

// GET CHAT FILES

export const getChatFiles = (
  projectId: string,
  queryParams: PaginatedRequest<"Created"> & ISearchTerm,
  userRole: ChatUserRole
): GetChatFilesAction => ({
  type: GET_CHAT_FILES,
  payload: {
    projectId,
    queryParams,
    userRole
  }
});
export const getChatFilesSuccess = (
  response: PagedListResponse<Document>
): GetChatFilesSuccessAction => ({
  type: GET_CHAT_FILES_SUCCESS,
  payload: {
    response
  }
});
export const getChatFilesFailure = (
  error: ErrorResponse<CIQError>
): GetChatFilesFailureAction => ({
  type: GET_CHAT_FILES_FAILURE,
  payload: {
    error
  }
});

export const resetChatFiles = (): ResetChatFilesAction => ({
  type: RESET_CHAT_FILES
});

// SELECT FILE TO SHARE

export const selectFileToShare = (fileId: string): SelectFileToShareAction => ({
  type: SELECT_FILE_TO_SHARE,
  payload: {
    fileId
  }
});

// SHARE CHAT FILES
export const shareChatFiles = (
  projectId: string,
  chatId: string,
  documentIds: string[]
): ShareChatFilesAction => ({
  type: SHARE_CHAT_FILES,
  payload: {
    projectId,
    chatId,
    documentIds
  }
});
export const shareChatFilesSuccess = (
  response: CreatedResponse
): ShareChatFilesSuccessAction => ({
  type: SHARE_CHAT_FILES_SUCCESS,
  payload: {
    response
  }
});
export const shareChatFilesFailure = (
  error: ErrorResponse<CIQError>
): ShareChatFilesFailureAction => ({
  type: SHARE_CHAT_FILES_FAILURE,
  payload: {
    error
  }
});

// DELETE CHAT MESSAGE

export const deleteChatMessageSuccess = (
  response: DeletedResponse
): DeleteChatMessageSuccessAction => ({
  type: DELETE_CHAT_MESSAGE_SUCCESS,
  payload: {
    response
  }
});

// DISPLAY TIMEZONE
export const setDisplayTimezone = (
  timezoneId: string
): SetDisplayTimezoneAction => ({
  type: SET_DISPLAY_TIMEZONE,
  payload: {
    timezoneId
  }
});

// ONLINE CHAT MEMBERS
export const storeOnlineMembersByChat = (
  channelName: string,
  onlineMembersByChat: OnlineMembers
): StoreOnlineMembersByChatAction => ({
  type: STORE_ONLINE_CHAT_MEMBERS,
  payload: {
    channelName,
    onlineMembersByChat
  }
});

export const updateOnlineMembersByChat = (
  channelName: string,
  updatedMember: UpdatedMember
): UpdateOnlineMembersByChatAction => ({
  type: UPDATE_ONLINE_CHAT_MEMBERS,
  payload: {
    channelName,
    updatedMember
  }
});

export * from "./ExpertDiscover/ExpertDiscoverActions";
export * from "./Referral/ReferralActions";
