import {
  CellClickedEvent,
  CheckboxSelectionCallbackParams,
  ColDef
} from "ag-grid-community";
import i18next from "i18next";

import {
  APPLICATION_STATUS,
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATUS,
  Referral
} from "@arbolus-technologies/models/common";

import { ExpertRenderer } from "../../../Components/CellRenderers/ExpertRenderer";

export function isRowMaster(data: Referral) {
  return (
    (data.application === APPLICATION_STATUS.ACCEPT ||
      (data.review === REFERRAL_SUB_STATUS.ACCEPT &&
        data.application !== APPLICATION_STATUS.REJECT)) &&
    data.expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC
  );
}

const baseExpertCol: ColDef<Referral> = {
  field: "expertName",
  headerName: i18next.t("referrals:headers:expert"),
  headerTooltip: i18next.t("referrals:headers:expert"),
  cellRenderer: ExpertRenderer,
  checkboxSelection: (params: CheckboxSelectionCallbackParams<Referral>) =>
    !!params.data &&
    params.data.expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC,
  headerCheckboxSelection: true,
  filter: "agMultiColumnFilter",
  filterParams: {
    filters: [
      {
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 1
        }
      },
      {
        filter: "agSetColumnFilter"
      }
    ]
  },
  floatingFilter: true,
  suppressHeaderMenuButton: true,
  minWidth: 250,
  onCellClicked: ({ context, data }: CellClickedEvent<Referral>) => {
    const params = {
      projectId: context.project.id,
      expertId: data!.expert.expertId,
      referralId: data!.id
    };
    context.openExpertProfile(params);
  }
};

export const expertNameCol: ColDef<Referral> = {
  ...baseExpertCol,
  cellClass: "d-flex align-items-center cursor-pointer",
  cellRenderer: ExpertRenderer
};

export const expertMasterNameCol: ColDef<Referral> = {
  ...baseExpertCol,
  cellRenderer: "agGroupCellRenderer",
  cellRendererParams: {
    innerRenderer: ExpertRenderer
  },
  cellClass: ({ data }) => {
    if (!data) {
      return undefined;
    }

    return isRowMaster(data)
      ? "cursor-pointer"
      : "cursor-pointer hide-row-grouping";
  }
};
