import { Observable } from "rxjs";

import { Permission } from "@arbolus-technologies/models/common";
import { SURVEY_ORDER_BY } from "@arbolus-technologies/models/survey";

import { MAX_PAGE_SIZE } from "../constants/api";
import { ADMIN_API } from "../endpoints/admin";
import {
  ORDER_BY,
  OWNERSHIP_ORDER_BY,
  SORT_DIRECTION
} from "../enums/apiEnums";
import { MultiSortRequest } from "../helpers";
import {
  ApiPaginatedRequest,
  ApiPaginatedResponse,
  CreatedResponse,
  ListResponse,
  ObjectResponse,
  OwnerShipResponse,
  PagedListResponse,
  SpecialPermissionsPaginatedRequest,
  SuccessResponse,
  UserSpecialPermissionsResponse
} from "../models/api";
import { ListTransactionCanopy } from "../models/canopy";
import {
  Client,
  ClientAccountDetails,
  ClientAccountUpdateRequest,
  ClientCreateRequest,
  ClientProjectCounts,
  ListBaseClient,
  ListClient
} from "../models/client";
import {
  GetEventDurationResponse,
  TransactionEventDetails
} from "../models/event";
import {
  AdminExpertListRequest,
  AdminExpertListResponseItem,
  DNCHistory,
  ListProjectExpert
} from "../models/expert";
import {
  ListProject,
  ListProjectBase,
  QuestionsResponse
} from "../models/projects";
import {
  TransactionSurvey,
  TransactionSurveyAmounts,
  TransactionSurveyExpert
} from "../models/survey";
import { CIQUser, User } from "../models/user";
import { restService } from "../restService";

export const AdminService = {
  getAdminUser: (
    Managers?: boolean,
    Offset = 0,
    Limit: number = MAX_PAGE_SIZE,
    searchTerm = "",
    OrderBy = ORDER_BY.FIRST_NAME,
    OrderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<PagedListResponse<User>> =>
    restService.get<PagedListResponse<User>>(ADMIN_API.GET_ADMIN_USERS(), {
      searchTerm,
      Offset,
      Limit,
      OrderBy,
      OrderDirection,
      Managers
    }),

  getClients: (
    Offset = 0,
    SearchTerm = "",
    OrderBy = "",
    Limit: number = MAX_PAGE_SIZE,
    OrderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<PagedListResponse<Client>> =>
    restService.get<PagedListResponse<Client>>(ADMIN_API.GET_CLIENTS(), {
      Offset,
      SearchTerm,
      Limit,
      OrderBy,
      OrderDirection
    }),

  getAllClients: (
    searchTerm = "",
    offset = 0,
    limit: number = MAX_PAGE_SIZE,
    orderBy: "name" | "userCount" = "userCount",
    orderDirection: SORT_DIRECTION = SORT_DIRECTION.DESCENDING,
    emailConfirmed = false
  ): Observable<PagedListResponse<ListClient>> =>
    restService.get<PagedListResponse<ListClient>>(
      ADMIN_API.GET_ALL_CLIENTS(),
      {
        searchTerm,
        offset,
        limit,
        orderBy,
        orderDirection,
        emailConfirmed
      }
    ),

  getClientAdminUsers: (
    clientId: string,
    Managers?: boolean,
    Offset = 0,
    Limit: number = MAX_PAGE_SIZE,
    searchTerm = "",
    OrderBy = ORDER_BY.FIRST_NAME,
    OrderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<PagedListResponse<User>> =>
    restService.get<PagedListResponse<User>>(
      ADMIN_API.GET_CLIENT_ADMIN_USERS(clientId),
      {
        searchTerm,
        Offset,
        Limit,
        OrderBy,
        OrderDirection,
        Managers
      }
    ),

  getClientProjects: (
    clientId: string,
    offset = 0,
    searchTerm = "",
    orderBy = "",
    limit: number = MAX_PAGE_SIZE,
    orderDirection: string,
    projectState?: string[],
    clientBillingInfo = false
  ): Observable<PagedListResponse<ListProject>> =>
    restService.get<PagedListResponse<ListProject>>(ADMIN_API.GET_PROJECTS(), {
      clientId,
      offset,
      searchTerm,
      limit,
      projectState,
      orderBy,
      orderDirection,
      clientBillingInfo
    }),

  createClient: (client: ClientCreateRequest): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(ADMIN_API.CREATE_CLIENT(), client),

  updateClientAccount: (
    clientAccountUpdateRequest: ClientAccountUpdateRequest
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      ADMIN_API.UPDATE_CLIENT(clientAccountUpdateRequest.id),
      clientAccountUpdateRequest
    ),

  getClient: (clientId: string): Observable<ClientAccountDetails> =>
    restService.get<ClientAccountDetails>(ADMIN_API.GET_CLIENT(clientId)),

  getClientMembers: (
    clientId: string,
    Offset = 0,
    Limit: number = MAX_PAGE_SIZE,
    OrderBy = "",
    OrderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<PagedListResponse<User>> =>
    restService.get<PagedListResponse<User>>(ADMIN_API.GET_MEMBERS(clientId), {
      Offset,
      Limit,
      OrderBy,
      OrderDirection
    }),

  getClientManagers: (
    clientId: string,
    apiParams: ApiPaginatedRequest
  ): Observable<ApiPaginatedResponse<User>> =>
    restService.get<ApiPaginatedResponse<User>>(
      ADMIN_API.GET_MANAGERS(clientId),
      apiParams
    ),

  getClientProjectCounts: (clientId: string): Observable<ClientProjectCounts> =>
    restService.get<ClientProjectCounts>(
      ADMIN_API.GET_CLIENT_PROJECTS_COUNT(clientId)
    ),

  getAdminUsers: (
    apiParams: SpecialPermissionsPaginatedRequest
  ): Observable<ApiPaginatedResponse<CIQUser>> =>
    restService.get<ApiPaginatedResponse<CIQUser>>(
      ADMIN_API.GET_ADMIN_USERS(),
      apiParams
    ),

  getAllClientProject: (): Observable<ListResponse<ListProjectBase>> =>
    restService.get<ListResponse<ListProjectBase>>(
      ADMIN_API.GET_ALL_CLIENT_PROJECTS()
    ),

  getUserCandidateClients: (
    userId: string,
    searchTerm = "",
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = ORDER_BY.NAME,
    orderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<ApiPaginatedResponse<ListBaseClient>> =>
    restService.get<ApiPaginatedResponse<ListBaseClient>>(
      ADMIN_API.GET_USER_CANDIDATE_CLIENTS(userId),
      {
        searchTerm,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),

  deactivateUserAccount: (
    userId: string,
    isActivate: boolean
  ): Observable<SuccessResponse> =>
    restService.put(ADMIN_API.DEACTIVATE_USER(userId), {}, { isActivate }),

  addClientManager: (
    clientId: string,
    userId: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(ADMIN_API.ADD_CLIENT_MANAGER(clientId), {
      userId
    }),

  getUserClients: (
    userId: string,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    searchTerm = "",
    orderBy = ORDER_BY.NAME,
    orderDirection: string = SORT_DIRECTION.ASCENDING
  ): Observable<ApiPaginatedResponse<ListBaseClient>> =>
    restService.get<ApiPaginatedResponse<ListBaseClient>>(
      ADMIN_API.GET_USER_CLIENTS(userId),
      {
        searchTerm,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),

  getProjectExperts: (
    projectId: string,
    searchTerm = "",
    orderBy = "",
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderDirection = SORT_DIRECTION.ASCENDING,
    allowInactive = false
  ): Observable<ApiPaginatedResponse<ListProjectExpert>> =>
    restService.get<ApiPaginatedResponse<ListProjectExpert>>(
      ADMIN_API.GET_PROJECT_EXPERTS(projectId),
      {
        offset,
        limit,
        searchTerm,
        orderBy,
        orderDirection,
        allowInactive
      }
    ),

  getProjectEvents: (
    projectId: string,
    searchTerm = "",
    orderBy = "",
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderDirection = SORT_DIRECTION.ASCENDING
  ): Observable<ApiPaginatedResponse<TransactionEventDetails>> =>
    restService.get<ApiPaginatedResponse<TransactionEventDetails>>(
      ADMIN_API.GET_PROJECT_EVENTS(projectId),
      {
        offset,
        limit,
        searchTerm,
        orderBy,
        orderDirection
      }
    ),

  getEventDuration: (
    projectId: string,
    eventId: string
  ): Observable<GetEventDurationResponse> =>
    restService.get<GetEventDurationResponse>(
      ADMIN_API.GET_PROJECT_EVENT_DURATION(projectId, eventId)
    ),

  getOwnerUsers: (
    projectId: string,
    searchTerm = "",
    orderBy = "",
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderDirection = SORT_DIRECTION.ASCENDING
  ): Observable<ApiPaginatedResponse<User>> =>
    restService.get<ApiPaginatedResponse<User>>(
      ADMIN_API.GET_OWNER_USERS(projectId),
      {
        offset,
        limit,
        searchTerm,
        orderBy,
        orderDirection
      }
    ),

  getCanopies: (
    offset: number,
    searchTerm: string,
    orderBy: string,
    limit: number,
    orderDirection: string,
    canopyStates?: string[]
  ): Observable<PagedListResponse<ListTransactionCanopy>> =>
    restService.get<PagedListResponse<ListTransactionCanopy>>(
      ADMIN_API.GET_CANOPIES(),
      {
        offset,
        searchTerm,
        orderBy,
        limit,
        orderDirection,
        canopyStates
      }
    ),

  editAngleQuestionsAdmin: (
    angleId: string,
    questions: { questions: string[] }
  ): Observable<QuestionsResponse> =>
    restService.post<QuestionsResponse>(
      ADMIN_API.EDIT_ANGLE_QUESTIONS(angleId),
      questions
    ),

  reportIncident: (
    title: string,
    overview: string,
    priority: string,
    category: string,
    reportFromUrl: string,
    entityId?: string
  ): Observable<ObjectResponse<string>> =>
    restService.post(ADMIN_API.REPORT_INCIDENT(), {
      title,
      overview,
      priority,
      category,
      reportFromUrl,
      entityId
    }),

  addIncidentAttachment: (
    incidentKey: string,
    attachment: FormData
  ): Observable<SuccessResponse> =>
    restService.post(
      ADMIN_API.ADD_INCIDENT_ATTACHMENT(incidentKey),
      attachment,
      undefined,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    ),

  getUserSpecialPermissions: (
    userId: string
  ): Observable<UserSpecialPermissionsResponse> =>
    restService.get<UserSpecialPermissionsResponse>(
      ADMIN_API.GET_USER_SPECIAL_PERMISSIONS(userId)
    ),

  getOwnerships: (
    userId: string,
    searchTerm = "",
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = OWNERSHIP_ORDER_BY.CLIENT_NAME,
    orderDirection = SORT_DIRECTION.ASCENDING
  ): Observable<OwnerShipResponse> =>
    restService.get<OwnerShipResponse>(ADMIN_API.GET_OWNERSHIPS(userId), {
      offset,
      searchTerm,
      orderBy,
      limit,
      orderDirection
    }),

  getSpecialPermissions: (): Observable<ListResponse<Permission>> =>
    restService.get<ListResponse<Permission>>(
      ADMIN_API.GET_SPECIAL_PERMISSIONS()
    ),

  getCurrentAdminSpecialPermissions: (): Observable<ListResponse<Permission>> =>
    restService.get<ListResponse<Permission>>(
      ADMIN_API.GET_CURRENT_ADMIN_SPECIAL_PERMISSIONS()
    ),

  updateUserSpecialPermissions: (
    userId: string,
    permissions: Permission[]
  ): Observable<SuccessResponse> =>
    restService.put(ADMIN_API.UPDATE_USER_SPECIAL_PERMISSIONS(userId), {
      permissions
    }),

  getExperts: (
    queryParams: MultiSortRequest<AdminExpertListRequest>
  ): Observable<PagedListResponse<AdminExpertListResponseItem>> =>
    restService.get<PagedListResponse<AdminExpertListResponseItem>>(
      ADMIN_API.GET_EXPERTS(),
      queryParams
    ),

  getExpertDNCHistory: (expertId: string): Observable<DNCHistory> =>
    restService.get(ADMIN_API.GET_EXPERT_DNC_HISTORY(expertId)),

  getAllSurveys: ({
    searchTerm,
    limit = 100,
    offset = 0
  }: {
    searchTerm?: string;
    limit?: number;
    offset?: number;
  }): Observable<ApiPaginatedResponse<TransactionSurvey>> =>
    restService.get(ADMIN_API.GET_SURVEYS(), {
      searchTerm,
      limit,
      offset,
      orderBy: SURVEY_ORDER_BY.CREATED,
      orderDirection: SORT_DIRECTION.DESCENDING
    }),

  getSurveyTransactionExperts: (
    surveyId: string
  ): Observable<ApiPaginatedResponse<TransactionSurveyExpert>> =>
    restService.get(ADMIN_API.GET_SURVEY_TRANSACTION_EXPERTS(surveyId)),

  getSurveyTransactionAmounts: (
    surveyId: string,
    expertId: string
  ): Observable<TransactionSurveyAmounts> =>
    restService.get(ADMIN_API.GET_SURVEY_TRANSACTION_AMOUNTS(surveyId), {
      expertId
    })
};
