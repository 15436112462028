import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultExpertPaymentsService,
  DefaultToasterService,
  ExpertPaymentsService,
  TipaltiIframes,
  ToasterService
} from "@arbolus-technologies/api";
import { LoaderOrComponent, Tabs } from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { PublicInterface, useDocumentTitle } from "@arbolus-technologies/utils";

import { TipaltiIframe } from "../Components/TipaltiIframe";

import styles from "./ExpertPaymentsPage.module.scss";

export interface ExpertPaymentsPageProps {
  expertPaymentsService?: PublicInterface<ExpertPaymentsService>;
  notificationService?: PublicInterface<ToasterService>;
}

export const ExpertPaymentsPage: React.FC<ExpertPaymentsPageProps> = ({
  expertPaymentsService = DefaultExpertPaymentsService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertPayments");
  const [iframes, setIframes] = React.useState<TipaltiIframes>();

  useDocumentTitle("expertPayments", "title");

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    expertPaymentsService.getIframes().subscribe({
      next: setIframes,
      error: notificationService.showApiErrors
    });
  }, []);

  const tabMapping = useMemo(
    () => ({
      setup: {
        title: t("account"),
        component: () => (
          <TipaltiIframe src={iframes?.dashboardUrl || ""} title="account" />
        )
      },
      history: {
        title: t("paymentsHistory"),
        component: () => (
          <TipaltiIframe src={iframes?.paymentsUrl || ""} title="payments" />
        )
      }
    }),
    [iframes, t]
  );

  return (
    <MainPageLayout title={t("title")}>
      <LoaderOrComponent isLoading={!iframes}>
        <div className={styles.container}>
          <Tabs tabMapping={tabMapping} />
        </div>
      </LoaderOrComponent>
    </MainPageLayout>
  );
};
