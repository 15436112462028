import { Handlers, createReducer } from "reduxsauce";

import {
  CANOPY_CLIENT_ACTIVE_TAB,
  CANOPY_CLIENT_ANSWER_STATE,
  CanopyClientActiveTabAction,
  CanopyClientAnswerStateAction,
  EDIT_VIDEO_TRANSCRIPT,
  EDIT_VIDEO_TRANSCRIPT_FAILURE,
  EDIT_VIDEO_TRANSCRIPT_SUCCESS,
  EditVideoTranscriptSuccessAction,
  GET_ANSWERS_BY_QUESTION,
  GET_ANSWERS_BY_QUESTION_FAILURE,
  GET_ANSWERS_BY_QUESTION_SUCCESS,
  GET_CANOPY_ANSWERS_BY_EXPERT,
  GET_CANOPY_ANSWERS_BY_EXPERT_FAILURE,
  GET_CANOPY_ANSWERS_BY_EXPERT_SUCCESS,
  GET_CANOPY_CLIENT_EXPERTS,
  GET_CANOPY_CLIENT_EXPERTS_FAILURE,
  GET_CANOPY_CLIENT_EXPERTS_SUCCESS,
  GET_IS_EXPERT_IN_PROJECT_SUCCESS,
  GET_VIDEO_TRANSCRIPT,
  GET_VIDEO_TRANSCRIPT_FAILURE,
  GET_VIDEO_TRANSCRIPT_SUCCESS,
  GetApiAnswersByExpertsSuccessAction,
  GetCanopyAnswersByExpertAction,
  GetCanopyAnswersByExpertSuccessAction,
  GetCanopyClientExpertsSuccessAction,
  GetIsExpertInProjectSuccessAction,
  GetVideoTranscriptSuccessAction,
  RESET_ANSWER_EXPERT,
  RESET_CANOPY_ANSWERS_BY_EXPERT,
  RESET_SELECTED_QUESTION_AND_EXPERT,
  RESET_VIDEO_TRANSCRIPT,
  RESET_VIDEO_TRANSCRIPT_FAILURE,
  SET_CANOPY_ANSWER_SELECTED,
  SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_SUCCESS,
  SET_SELECTED_EXPERT_SUCCESS,
  SHOW_RIGHT_COMPONENT,
  SetCanopyAnswerSelectedAction,
  SetCanopyAnswerSelectedByExpertsSuccessAction,
  SetSelectedExpertSuccessAction,
  ShowRightComponentAction
} from "./actions/CanopyClientsActionTypes";
import { ClientCanopyReducerState } from "./models/definitions";

export const initialState: ClientCanopyReducerState = {
  canopyClientAnswersState: "details",
  canopyClientActiveTab: "questions",
  canopyAnswersByQuestion: null,
  getCanopyAnswersByQuestionLoading: false,
  canopyAnswerSelected: null,
  videoTranscriptLoading: false,
  editVideoTranscriptLoading: false,
  videoTranscript: null,
  canopyExperts: null,
  loadingCanopyExperts: false,
  loadingAllCanopyAnswersByExperts: false,
  allCanopyAnswersByExpert: null,
  expertSelected: null,
  showRightComponent: false,
  hasExpertClientVisibleReferral: false,
  isSmartTranscript: false
};

// CHANGE Canopy Answer State
const changeCanopyAnswerState = (
  state = initialState,
  { payload }: CanopyClientAnswerStateAction
): ClientCanopyReducerState => ({
  ...state,
  canopyClientAnswersState: payload.state
});

// GET Canopy Answers by Expert
const getCanopyAnswersByExpert = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  getCanopyAnswersByQuestionLoading: true
});

const getCanopyAnswersByExpertSuccess = (
  state = initialState,
  { payload }: GetApiAnswersByExpertsSuccessAction
): ClientCanopyReducerState => ({
  ...state,
  getCanopyAnswersByQuestionLoading: false,
  canopyAnswersByQuestion: {
    pagination: payload.answersByQuestion.pagination,
    questionText: payload.answersByQuestion.questionText,
    questionDetails: payload.answersByQuestion.questionDetails,
    items: state.canopyAnswersByQuestion?.items
      ? state.canopyAnswersByQuestion?.items.concat(
          payload.answersByQuestion.items
        )
      : payload.answersByQuestion.items,
    questionId: payload.questionId,
    order: payload.answersByQuestion.order + 1
  }
});

const resetAnswerExpert = (state = initialState): ClientCanopyReducerState => ({
  ...state,
  canopyAnswersByQuestion: null,
  canopyClientAnswersState: "details",
  allCanopyAnswersByExpert: null
});

const getCanopyAnswersByExpertFailure = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  getCanopyAnswersByQuestionLoading: false
});

// Set Canopy Answer Selected

const setCanopyAnswerSelected = (
  state = initialState,
  { payload }: SetCanopyAnswerSelectedAction
): ClientCanopyReducerState => ({
  ...state,
  canopyAnswerSelected: {
    ...payload.answer,
    questionText: state.canopyAnswersByQuestion?.questionText,
    questionDetails: state.canopyAnswersByQuestion?.questionDetails
  }
});

// Set Canopy Answer Selected

const setCanopyAnswerSelectedByExpertsSuccess = (
  state = initialState,
  { payload }: SetCanopyAnswerSelectedByExpertsSuccessAction
): ClientCanopyReducerState => {
  const questionSelected = payload.answersByExpert;
  const answers = payload.answersByExpert.items;
  const answerSelected =
    answers.length > 0
      ? answers.filter((answer) => answer.id === payload.answerId)[0]
      : null;
  return {
    ...state,
    canopyAnswerSelected: answerSelected
      ? {
          id: answerSelected.id,
          type: answerSelected.type,
          downloadUrl: answerSelected.downloadUrl,
          transcriptPreview: answerSelected.transcriptPreview,
          questionText: questionSelected.questionText,
          questionDetails: questionSelected.questionDetails,
          expert: answerSelected.expert,
          status: answerSelected.status
        }
      : null
  };
};

// Get Video Transcript
const getVideoTranscript = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  videoTranscriptLoading: true
});

const getVideoTranscriptSuccess = (
  state = initialState,
  { payload }: GetVideoTranscriptSuccessAction
): ClientCanopyReducerState => {
  const { isSmartTranscript } = payload;
  const newCanopyAnswersByExpert = () => {
    if (state.canopyAnswersByQuestion) {
      return state.canopyAnswersByQuestion.items.map((item) =>
        item.id === payload.answerId
          ? { ...item, transcriptPreview: payload.transcript.substring(0, 500) }
          : item
      );
    } else {
      return [];
    }
  };

  const newAllCanopyAnswersByExpert = () => {
    if (state.allCanopyAnswersByExpert) {
      return state.allCanopyAnswersByExpert.items.map((item) =>
        item.id === payload.answerId
          ? { ...item, transcriptPreview: payload.transcript.substring(0, 500) }
          : item
      );
    } else {
      return [];
    }
  };
  return {
    ...state,
    videoTranscriptLoading: false,
    videoTranscript: payload.transcript,
    isSmartTranscript,
    allCanopyAnswersByExpert: state.allCanopyAnswersByExpert
      ? {
          ...state.allCanopyAnswersByExpert,
          items: newAllCanopyAnswersByExpert()
        }
      : null,
    canopyAnswersByQuestion: state.canopyAnswersByQuestion
      ? {
          ...state.canopyAnswersByQuestion,
          items: newCanopyAnswersByExpert()
        }
      : null
  };
};

const getVideoTranscriptFailure = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  videoTranscriptLoading: false
});

const editVideoTranscript = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  editVideoTranscriptLoading: true
});

const editVideoTranscriptSuccess = (
  state = initialState,
  { payload }: EditVideoTranscriptSuccessAction
): ClientCanopyReducerState => {
  const newCanopyAnswersByExpert = () => {
    if (state.canopyAnswersByQuestion) {
      return state.canopyAnswersByQuestion.items.map((item) =>
        item.id === payload.answerId
          ? { ...item, transcriptPreview: payload.transcript.substring(0, 500) }
          : item
      );
    } else {
      return [];
    }
  };

  const newAllCanopyAnswersByExpert = () => {
    if (state.allCanopyAnswersByExpert) {
      return state.allCanopyAnswersByExpert.items.map((item) =>
        item.id === payload.answerId
          ? { ...item, transcriptPreview: payload.transcript.substring(0, 500) }
          : item
      );
    } else {
      return [];
    }
  };

  return {
    ...state,
    editVideoTranscriptLoading: false,
    videoTranscript: payload.transcript,
    isSmartTranscript: payload.isSmartTranscript,
    allCanopyAnswersByExpert: state.allCanopyAnswersByExpert
      ? {
          ...state.allCanopyAnswersByExpert,
          items: newAllCanopyAnswersByExpert()
        }
      : null,
    canopyAnswersByQuestion: state.canopyAnswersByQuestion
      ? {
          ...state.canopyAnswersByQuestion,
          items: newCanopyAnswersByExpert()
        }
      : null
  };
};

const editVideoTranscriptFailure = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  editVideoTranscriptLoading: false
});

const resetVideoTranscript = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  videoTranscriptLoading: true
});

const resetVideoTranscriptFailure = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  videoTranscriptLoading: false
});

// Get Answers by Experts
const getCanopyClientExperts = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  loadingCanopyExperts: true
});

const getCanopyClientExpertsSuccess = (
  state = initialState,
  { payload }: GetCanopyClientExpertsSuccessAction
): ClientCanopyReducerState => ({
  ...state,
  loadingCanopyExperts: false,
  canopyExperts: payload.canopyExperts
});

const getCanopyClientExpertsFailure = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  loadingCanopyExperts: false
});

const allCanopyAnswersByExperts = (
  state = initialState,
  { payload }: GetCanopyAnswersByExpertAction
): ClientCanopyReducerState => ({
  ...state,
  loadingAllCanopyAnswersByExperts: true
});

const allCanopyAnswersByExpertSuccess = (
  state = initialState,
  { payload }: GetCanopyAnswersByExpertSuccessAction
): ClientCanopyReducerState => ({
  ...state,
  loadingAllCanopyAnswersByExperts: false,
  allCanopyAnswersByExpert: {
    pagination: payload.answersByExpert.pagination,
    items: state.allCanopyAnswersByExpert?.items
      ? state.allCanopyAnswersByExpert?.items.concat(
          payload.answersByExpert.items
        )
      : payload.answersByExpert.items,
    expertId: payload.expertId,
    canopyId: payload.canopyId
  }
});

const allCanopyAnswersByExpertFailure = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  loadingAllCanopyAnswersByExperts: false
});

const resetCanopyAnswersByExpert = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  loadingAllCanopyAnswersByExperts: false,
  allCanopyAnswersByExpert: null
});

const resetSelectedQuestionAndExpert = (
  state = initialState
): ClientCanopyReducerState => ({
  ...state,
  canopyAnswersByQuestion: initialState.canopyAnswersByQuestion,
  allCanopyAnswersByExpert: initialState.allCanopyAnswersByExpert,
  expertSelected: initialState.expertSelected
});

const setSelectedExpertSuccess = (
  state = initialState,
  { payload }: SetSelectedExpertSuccessAction
): ClientCanopyReducerState => {
  const experts = payload.ExpertInACanopy;
  const expertInACanopy =
    experts.length > 0
      ? experts.find((expert) => expert.expert.expertId === payload.expertId)
          ?.expert
      : null;
  return {
    ...state,
    expertSelected: expertInACanopy!
  };
};

const changeClientActiveTab = (
  state = initialState,
  { payload }: CanopyClientActiveTabAction
): ClientCanopyReducerState => ({
  ...state,
  canopyClientActiveTab: payload.activeTab
});

const showRightComponent = (
  state = initialState,
  { payload }: ShowRightComponentAction
): ClientCanopyReducerState => ({
  ...state,
  showRightComponent: payload.showRightComponent
});

const getIsExpertInProjectSuccess = (
  state = initialState,
  { payload }: GetIsExpertInProjectSuccessAction
): ClientCanopyReducerState => ({
  ...state,
  hasExpertClientVisibleReferral: payload.hasExpertClientVisibleReferral
});

export const handlers: Handlers<ClientCanopyReducerState, any> = {
  [CANOPY_CLIENT_ANSWER_STATE]: changeCanopyAnswerState,
  [GET_ANSWERS_BY_QUESTION]: getCanopyAnswersByExpert,
  [GET_ANSWERS_BY_QUESTION_SUCCESS]: getCanopyAnswersByExpertSuccess,
  [GET_ANSWERS_BY_QUESTION_FAILURE]: getCanopyAnswersByExpertFailure,
  [RESET_ANSWER_EXPERT]: resetAnswerExpert,
  [GET_VIDEO_TRANSCRIPT]: getVideoTranscript,
  [GET_VIDEO_TRANSCRIPT_SUCCESS]: getVideoTranscriptSuccess,
  [GET_VIDEO_TRANSCRIPT_FAILURE]: getVideoTranscriptFailure,
  [EDIT_VIDEO_TRANSCRIPT]: editVideoTranscript,
  [EDIT_VIDEO_TRANSCRIPT_SUCCESS]: editVideoTranscriptSuccess,
  [EDIT_VIDEO_TRANSCRIPT_FAILURE]: editVideoTranscriptFailure,
  [RESET_VIDEO_TRANSCRIPT]: resetVideoTranscript,
  [RESET_VIDEO_TRANSCRIPT_FAILURE]: resetVideoTranscriptFailure,
  [SET_CANOPY_ANSWER_SELECTED]: setCanopyAnswerSelected,
  [GET_CANOPY_CLIENT_EXPERTS]: getCanopyClientExperts,
  [GET_CANOPY_CLIENT_EXPERTS_SUCCESS]: getCanopyClientExpertsSuccess,
  [GET_CANOPY_CLIENT_EXPERTS_FAILURE]: getCanopyClientExpertsFailure,
  [GET_CANOPY_ANSWERS_BY_EXPERT]: allCanopyAnswersByExperts,
  [GET_CANOPY_ANSWERS_BY_EXPERT_SUCCESS]: allCanopyAnswersByExpertSuccess,
  [GET_CANOPY_ANSWERS_BY_EXPERT_FAILURE]: allCanopyAnswersByExpertFailure,
  [RESET_CANOPY_ANSWERS_BY_EXPERT]: resetCanopyAnswersByExpert,
  [RESET_SELECTED_QUESTION_AND_EXPERT]: resetSelectedQuestionAndExpert,
  [SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_SUCCESS]:
    setCanopyAnswerSelectedByExpertsSuccess,
  [SET_SELECTED_EXPERT_SUCCESS]: setSelectedExpertSuccess,
  [CANOPY_CLIENT_ACTIVE_TAB]: changeClientActiveTab,
  [SHOW_RIGHT_COMPONENT]: showRightComponent,
  [GET_IS_EXPERT_IN_PROJECT_SUCCESS]: getIsExpertInProjectSuccess
};

export const ClientCanopyReducer = createReducer(initialState, handlers);
