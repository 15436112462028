import { Col, RadioChangeEvent, Row } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useLocation } from "react-router";

import {
  EXPERT_SELF_PROFILE_EDIT,
  EXPERT_DETAILS_EDIT_TABS_PATH as ROUTES,
  EXPERT_DETAILS_EDIT_TABS as TABS
} from "@arbolus-technologies/routes";
import { AntDHeader } from "@arbolus-technologies/ui/layout";

import { Details } from "../../Components/Details/Details";
import { Endorsements } from "../../Components/Endorsements/Endorsements";
import { Insights } from "../../Components/Insights/Insights";
import { NotificationSettings } from "../../Components/NotificationSettings/NotificationSettings";
import { SecuritySettings } from "../../Components/SecuritySettings/SecuritySettings";
import { SkeletonLoader } from "../../Components/SkeletonLoader/SkeletonLoader";
import { EditExpertExperience } from "../../Modules/EditExpertExperience/EditExpertExperience";
import { ExpertNotificationDetails } from "../../helpers/types";
import { EditProfileNavigation, getActiveTab } from "./EditProfileNavigation";
import { useProfileDetails } from "./useProfileDetails";

import styles from "./EditProfile.module.scss";

export interface EditProfileProps {
  expertDetails: ExpertNotificationDetails;
  userId: string;
  logOut: () => void;
}

export const EditProfile: React.FC<EditProfileProps> = ({
  expertDetails,
  userId,
  logOut
}) => {
  const { t } = useTranslation("expertEditProfile");
  const location = useLocation();
  const history = useHistory();

  const {
    getExpertDetails,
    updateExpertExperience,
    isLoading,
    endorsements,
    expertProfile
  } = useProfileDetails(expertDetails);
  useEffect(() => getExpertDetails(), []);

  const activeTab = getActiveTab(location);
  const handleTabChange = useCallback((value: typeof TABS) => {
    history.push(`${EXPERT_SELF_PROFILE_EDIT}/${value}`);
  }, []);

  const TABS_ROUTES = useMemo(
    () => [
      {
        label: t("experience"),
        value: TABS.EXPERIENCE,
        path: ROUTES.EXPERIENCE,
        component: () => (
          <EditExpertExperience
            expertId={expertDetails.expertId!}
            background={expertProfile?.background}
            updateExpertExperience={updateExpertExperience}
          />
        )
      },
      {
        label: t("insights"),
        value: TABS.INSIGHTS,
        path: ROUTES.INSIGHTS,
        component: () => <Insights expertId={expertDetails.expertId!} />
      },
      {
        label: t("endorsements"),
        value: TABS.ENDORSEMENTS,
        path: ROUTES.ENDORSEMENTS,
        component: () => (
          <Endorsements
            expertId={expertDetails.expertId!}
            endorsements={endorsements!}
          />
        )
      },
      {
        label: t("security"),
        value: TABS.PASSWORD,
        path: ROUTES.PASSWORD,
        component: SecuritySettings
      },
      {
        label: t("settings"),
        value: TABS.SETTINGS,
        path: ROUTES.SETTINGS,
        component: () => (
          <NotificationSettings expertDetails={expertDetails} logOut={logOut} />
        )
      }
    ],
    [expertProfile, expertDetails, userId]
  );

  if (isLoading) {
    return (
      <div className={styles.editProfile}>
        <AntDHeader title={t("editProfile")} />
        <div className={styles.pageContent}>
          <SkeletonLoader />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.editProfile}>
      <AntDHeader title={t("editProfile")} />

      <Row>
        <Col
          xs={24}
          sm={24}
          md={22}
          lg={22}
          xl={16}
          className={styles.pageContent}
        >
          <EditProfileNavigation
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            tabsRoutes={TABS_ROUTES}
          />

          <Switch>
            {TABS_ROUTES.map(({ path, component: Component }) => (
              <Route key={path} path={path}>
                <Component />
              </Route>
            ))}
            {/* Manually defined at the end because it's a default one */}
            <Route
              path={[ROUTES.DETAILS, "*"]}
              component={() => (
                <Details expertProfile={expertProfile} userId={userId} />
              )}
            />
          </Switch>
        </Col>
      </Row>
    </div>
  );
};
