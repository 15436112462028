import { Card, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  PROJECT_REFERRAL_STATE,
  ProjectAnswer
} from "@arbolus-technologies/api";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";

import { ScreeningForm } from "./ScreeningForm";

const { Text } = Typography;

interface ScreeningProps {
  screeningInfo: ProjectAnswer[];
  expertId: string;
  referralStatus: PROJECT_REFERRAL_STATE;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  onUpdateScreeningAnswers: (answers: ProjectAnswer[]) => void;
}

export const Screening: React.FC<ScreeningProps> = ({
  screeningInfo,
  expertId,
  referralStatus,
  doNotContactStatus,
  onUpdateScreeningAnswers
}) => {
  const { t } = useTranslation("referralScreening");

  const canEdit =
    referralStatus !== PROJECT_REFERRAL_STATE.REJECT &&
    doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC;

  return (
    <Card>
      <Flex vertical gap={16}>
        <Text strong>{t("screeningQuestions")}</Text>
        {canEdit ? (
          <ScreeningForm
            screeningInfo={screeningInfo}
            expertId={expertId}
            onUpdateScreeningAnswers={onUpdateScreeningAnswers}
          />
        ) : (
          screeningInfo.map((item, index) => (
            <Flex key={item.questionId} gap={8} vertical>
              <Text strong>{`${index + 1}. ${item.question}`}</Text>
              <DangerouslySetInnerHTML text={item.answer ?? "-"} />
            </Flex>
          ))
        )}
      </Flex>
    </Card>
  );
};
