import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectExpertAvailability
} from "@arbolus-technologies/api";
import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import {
  Answer,
  Compliance,
  ProjectApplicationDataTypes,
  ProjectApplicationStepDetails,
  ProjectComplianceFormValues
} from "@arbolus-technologies/models/project";

import { PROJECTS } from "../../../../constants/navigation/authRoutes";
import { EngagementAgreement } from "../../../../models/meta";
import { ProjectApplication } from "../../../../models/project";
import { ExpertService, ProjectService } from "../../../../services";
import { AppSelector } from "../../../app/store";
import { CompanyValidationStep } from "../../components/applyProject/CompanyValidationStep/CompanyValidationStep";
import ProjectAgreement from "../../components/applyProject/ProjectAgreement/ProjectAgreement";
import { ExpertSchedulerWrapper } from "../../components/applyProject/availability/ExpertSchedulerWrapper";
import { ProjectCompliance } from "../../components/applyProject/compliance/ProjectCompliance";
import {
  ProjectQuestions,
  ProjectQuestionsFormValues
} from "../../components/applyProject/projectQuestions/ProjectQuestions";
import { PROJECT_APPLICATION_STEPS } from "./projectApplicationTypes";
import { getStepAfter } from "./projectApplicationUtils";

const {
  PROJECT_QUESTIONS,
  COMPLIANCE_QUESTIONS,
  EXPERT_AVAILABILITY,
  COMPANY_VALIDATION,
  ENGAGEMENT_AGREEMENT
} = PROJECT_APPLICATION_STEPS;

const STEP_TO_TRANSLATION_KEY = {
  [PROJECT_QUESTIONS]: "screeningQuestions",
  [COMPLIANCE_QUESTIONS]: "compliance",
  [EXPERT_AVAILABILITY]: "availability",
  [COMPANY_VALIDATION]: "companyValidation",
  [ENGAGEMENT_AGREEMENT]: "agreement"
};

interface ProjectApplicationStepProps {
  projectId: string;
  expertId: string;
  expertAnswers: Answer[];
  setExpertAnswers: (answers: Answer[]) => void;
  expertComplianceAnswers: Compliance[];
  setExpertComplianceAnswers: (answers: Compliance[]) => void;
  projectApplicationBrief: ProjectApplication;
  setProjectApplicationBrief: (application: ProjectApplication) => void;
  setShowBriefScreen: (isShown: boolean) => void;
  projectExpertAvailability: ProjectExpertAvailability;
  setProjectExpertAvailability: (
    availability: ProjectExpertAvailability
  ) => void;
  updateExpertNewApplicationsCount: () => void;
  engagementAgreement: EngagementAgreement;
  hasScreeningQuestions: boolean;
  hasComplianceQuestions: boolean;
  isUpdateLoading: boolean;
  setIsUpdateLoading: (isLoading: boolean) => void;
  goToProjects: () => void;
  scrollRef: { current: any };
}

export const ProjectApplicationStep = ({
  projectId,
  expertId,
  expertAnswers,
  setExpertAnswers,
  expertComplianceAnswers,
  setExpertComplianceAnswers,
  projectApplicationBrief,
  setProjectApplicationBrief,
  setShowBriefScreen,
  projectExpertAvailability,
  setProjectExpertAvailability,
  updateExpertNewApplicationsCount,
  engagementAgreement,
  hasScreeningQuestions,
  hasComplianceQuestions,
  isUpdateLoading,
  setIsUpdateLoading,
  goToProjects,
  scrollRef
}: ProjectApplicationStepProps) => {
  const { t } = useTranslation("projectApplication");

  const history = useHistory();

  const currentTimezone = useSelector(
    AppSelector.appGuessCurrentTimeZoneSelector()
  );
  const timezones = useSelector(
    AppSelector.appTimezoneSelectOptionMapSelector()
  );
  const { expertTimezone, expertAvailabilitySlots } = projectExpertAvailability;
  const {
    questionsAnswered,
    complianceAnswered,
    expertAvailabilitySlotsSelected
  } = projectApplicationBrief.referral;

  const steps: Array<PROJECT_APPLICATION_STEPS> = [];
  if (hasScreeningQuestions) {
    steps.push(PROJECT_QUESTIONS);
  }
  if (hasComplianceQuestions) {
    steps.push(COMPLIANCE_QUESTIONS);
  }
  steps.push(EXPERT_AVAILABILITY, COMPANY_VALIDATION, ENGAGEMENT_AGREEMENT);

  const [currentStep, setCurrentStep] = useState<PROJECT_APPLICATION_STEPS>(
    () => {
      // The order of check matters - start from the last step
      if (expertAvailabilitySlotsSelected) {
        return getStepAfter(steps, EXPERT_AVAILABILITY);
      }
      if (hasComplianceQuestions && complianceAnswered) {
        return getStepAfter(steps, COMPLIANCE_QUESTIONS);
      }
      if (hasScreeningQuestions && questionsAnswered) {
        return getStepAfter(steps, PROJECT_QUESTIONS);
      }
      return steps[0];
    }
  );
  const currentStepIndex = steps.indexOf(currentStep);

  function scrollToTop() {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }

  const handleSaveSuccess = (
    isManualSave: boolean,
    key: string,
    onNextAction?: () => void
  ): void => {
    const { referral } = projectApplicationBrief;
    setIsUpdateLoading(false);

    if (isManualSave) {
      DefaultToasterService.showSuccess(t("saveProgressSuccess"));
      goToProjects();
    } else {
      setProjectApplicationBrief({
        ...projectApplicationBrief,
        referral: { ...referral, [key]: true }
      });
      handleNextClick();
      onNextAction?.();
    }
  };

  const handleNextClick = (): void => {
    setCurrentStep(steps[currentStepIndex + 1]);
    scrollToTop();
  };

  const handleBackPress = (): void => {
    if (currentStepIndex === 0) {
      setShowBriefScreen(true);
    } else {
      setCurrentStep(steps[currentStepIndex - 1]);
    }
    scrollToTop();
  };

  function saveScreeningAnswers(
    values: ProjectApplicationDataTypes,
    isManualSave: boolean
  ) {
    const projectValues = values as ProjectQuestionsFormValues;
    const projectAnswers = projectValues.questions.map(
      ({ questionId, answer }) => ({
        answer,
        questionId
      })
    );

    setIsUpdateLoading(true);

    ExpertService.addProjectAnswers(expertId, {
      answers: projectAnswers,
      projectId
    }).subscribe(
      () => {
        handleSaveSuccess(isManualSave, "questionsAnswered");
        setExpertAnswers(projectValues.questions);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsUpdateLoading(false);
        DefaultToasterService.showError(error.message);
      }
    );
  }

  const saveComplianceAnswers = (
    values: ProjectApplicationDataTypes,
    isManualSave: boolean
  ): void => {
    const complianceValues = values as ProjectComplianceFormValues;
    const complianceAnswers = complianceValues.compliances.map((c) => ({
      answer: c.answer?.answer ?? c.question.displayModel.defaultValue!,
      questionId: c.questionId,
      textAnswer: c.answer?.textAnswer
    }));

    setIsUpdateLoading(true);

    ExpertService.addComplianceAnswers(expertId, {
      projectId,
      answers: complianceAnswers
    }).subscribe(
      () => {
        handleSaveSuccess(isManualSave, "complianceAnswered");
        setExpertComplianceAnswers(complianceValues.compliances);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsUpdateLoading(false);
        DefaultToasterService.showError(error.message);
      }
    );
  };

  const saveExpertAvailability = (
    values: ProjectApplicationDataTypes,
    isManualSave: boolean
  ): void => {
    const projectExpertAvailability = values as ProjectExpertAvailability;
    const { expertAvailabilitySlots, expertTimezone } =
      projectExpertAvailability;

    setProjectExpertAvailability(projectExpertAvailability);
    setIsUpdateLoading(true);

    ExpertService.addExpertAvailability(
      projectId,
      expertId,
      expertAvailabilitySlots,
      expertTimezone || ""
    ).subscribe(
      () => {
        handleSaveSuccess(
          isManualSave,
          "expertAvailabilitySlotsSelected",
          updateExpertNewApplicationsCount
        );
      },
      (error: ErrorResponse<CIQError>) => {
        setIsUpdateLoading(false);
        DefaultToasterService.showError(error.message);
      }
    );
  };

  function acceptAgreement() {
    const { referral } = projectApplicationBrief;
    const { id: agreementId } = engagementAgreement;

    setIsUpdateLoading(true);

    ProjectService.acceptAgreement(
      projectId,
      referral.id,
      agreementId,
      REFERRAL_SUB_STATE.ACCEPT
    ).subscribe(
      () => {
        setProjectApplicationBrief({
          ...projectApplicationBrief,
          referral: {
            ...referral,
            applicationStatus: REFERRAL_SUB_STATE.ACCEPT
          }
        });
        setIsUpdateLoading(false);
        updateExpertNewApplicationsCount();
        history.push(PROJECTS);
        DefaultToasterService.showSuccess(t("applicationSubmitted"));
      },
      (error: ErrorResponse<CIQError>) => {
        setIsUpdateLoading(false);
        DefaultToasterService.showError(error.message);
      }
    );
  }

  const stepDetails: ProjectApplicationStepDetails = {
    currentStep: currentStepIndex,
    nextStepTitle: t(STEP_TO_TRANSLATION_KEY[steps[currentStepIndex + 1]]),
    totalSteps: steps.length,
    completedSteps: currentStepIndex
  };

  if (currentStep === PROJECT_QUESTIONS) {
    return (
      <ProjectQuestions
        key={PROJECT_QUESTIONS}
        isLoading={isUpdateLoading}
        questions={expertAnswers}
        onSaveProgress={saveScreeningAnswers}
        stepDetails={stepDetails}
        onBack={handleBackPress}
      />
    );
  }

  if (currentStep === COMPLIANCE_QUESTIONS) {
    return (
      <ProjectCompliance
        key={COMPLIANCE_QUESTIONS}
        isLoading={isUpdateLoading}
        compliances={expertComplianceAnswers}
        onSaveProgress={saveComplianceAnswers}
        stepDetails={stepDetails}
        onBack={handleBackPress}
      />
    );
  }

  if (currentStep === EXPERT_AVAILABILITY) {
    return (
      <ExpertSchedulerWrapper
        key={EXPERT_AVAILABILITY}
        projectTimezone={projectApplicationBrief.timezone}
        currentTimezone={expertTimezone || currentTimezone.value}
        timezones={timezones}
        expertAvailabilitySlotsSelected={expertAvailabilitySlots}
        isLoading={isUpdateLoading}
        stepDetails={stepDetails}
        onSaveProgress={saveExpertAvailability}
        onBack={handleBackPress}
      />
    );
  }

  if (currentStep === COMPANY_VALIDATION) {
    return (
      <CompanyValidationStep
        key={COMPANY_VALIDATION}
        isLoading={isUpdateLoading}
        stepDetails={stepDetails}
        onSaveProgress={handleNextClick}
        onBack={handleBackPress}
        expertId={expertId}
      />
    );
  }

  if (currentStep === ENGAGEMENT_AGREEMENT) {
    return (
      <ProjectAgreement
        key={ENGAGEMENT_AGREEMENT}
        isLoading={isUpdateLoading}
        engagementAgreement={engagementAgreement}
        onSaveProgress={goToProjects}
        stepDetails={{
          ...stepDetails,
          nextStepTitle: t("agreeSubmitButton")
        }}
        onNext={acceptAgreement}
        onBack={handleBackPress}
      />
    );
  }

  return null;
};
