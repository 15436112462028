import { Flex, Typography } from "antd";
import i18next from "i18next";

import { AdoptionRenderer } from "../../Components/CellComponents/AdoptionRenderer/AdoptionRenderer";
import { ChurnRiskRenderer } from "../../Components/CellComponents/ChurnRiskRenderer/ChurnRiskRenderer";
import { CustomersRenderer } from "../../Components/CellComponents/CustomersRenderer/CustomersRenderer";
import { NPSRenderer } from "../../Components/CellComponents/NPSRenderer/NPSRenderer";
import { RenewalRenderer } from "../../Components/CellComponents/RenewalRenderer/RenewalRenderer";
import { SpendRenderer } from "../../Components/CellComponents/SpendRenderer/SpendRenderer";
import { CompanyData } from "../../models";
import { CompanyStatusRenderer } from "./CompanyStatusRenderer";

const RENDERER_MAPPING: Record<
  keyof CompanyDataBoxFields,
  React.FC<{ data: any }>
> = {
  customersCount: CustomersRenderer,
  churnRisk: ChurnRiskRenderer,
  nps: NPSRenderer,
  spend: SpendRenderer,
  renewalIntent: RenewalRenderer,
  adoption: AdoptionRenderer,
  status: CompanyStatusRenderer
};

export function DataBoxFields({ companyData }: { companyData: CompanyData }) {
  const dataBoxValues = getCompanyDataBoxValues(companyData);

  return (
    <Flex gap={16}>
      {Object.entries(dataBoxValues).map(([key, value]) => {
        const typedKey = key as keyof CompanyDataBoxFields;
        const RendererComponent = RENDERER_MAPPING[typedKey];
        const data = { [typedKey]: value };

        return (
          <Flex vertical gap={4} key={key}>
            <Typography.Text>
              {i18next.t(`companyData:${key}`) as string}
            </Typography.Text>
            {RendererComponent && <RendererComponent data={data} />}
          </Flex>
        );
      })}
    </Flex>
  );
}

function getCompanyDataBoxValues(data: CompanyData) {
  const {
    id,
    vendorCompanyId,
    vendorCompanyLogoUrl,
    vendorCompanyName,
    vendorCompanyWebsite,
    ...companyDataValues
  } = data;

  return companyDataValues;
}

type CompanyDataBoxFields = ReturnType<typeof getCompanyDataBoxValues>;
