import { ArbolusGrid } from "@arbolus-technologies/ui/components";
import { ColDef } from "ag-grid-community";
import { useMemo } from "react";
import { CompanyDataSource } from "../CompanyDataSource";
import { COLUMN_DEFS } from "./ColumnDefs";

interface CompanyDataTableProps {
  vendorCompanyId: string;
}
export const CompanyDataTable: React.FC<CompanyDataTableProps> = ({
  vendorCompanyId
}) => {
  const dataSource = useMemo(
    () => new CompanyDataSource(vendorCompanyId),
    [vendorCompanyId]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: false,
      resizable: true,
      sortable: false,
      menuTabs: []
    }),
    []
  );

  return (
    <ArbolusGrid
      rowModelType="serverSide"
      serverSideDatasource={dataSource}
      getRowId={({ data }) => data.id}
      defaultColDef={defaultColDef}
      columnDefs={COLUMN_DEFS}
      readOnlyEdit
      suppressRowClickSelection
      skipHeaderOnAutoSize
      suppressContextMenu
      suppressMenuHide
      pagination
      paginationAutoPageSize
    />
  );
};
