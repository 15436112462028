import { Button, Spin, Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { useReport } from "../../Hooks/useReport";

export function RequestReport({
  companyId,
  companyName
}: { companyId: string; companyName: string }) {
  const { t } = useTranslation("userMonitoringListPage");
  const { report, fetchReport, openReportRequestModal } = useReport(
    companyId,
    companyName
  );

  useEffect(() => {
    fetchReport();
  }, []);

  if (!report) return <Spin />;

  if (report?.reportDate) {
    return <RequestedDate reportDate={report.reportDate} />;
  }

  return (
    <Button onClick={openReportRequestModal} type="primary">
      {t("requestReportButton")}
    </Button>
  );
}

function RequestedDate({ reportDate }: { reportDate: string }) {
  const { t } = useTranslation("userMonitoringListPage");

  return (
    <Typography.Text type="secondary">
      {t("requestedReportOn", {
        reportDate: formatDate(reportDate, APP_DATE_FORMAT)
      })}
    </Typography.Text>
  );
}
