import { Button, Flex, Popover, Tooltip, Typography } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AntDIcon, IconName } from "@arbolus-technologies/antDComponents";
import { DiscoverExpert } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { DiscoverMatchingFiltersPanelData } from "../../DiscoverMatchingFiltersPanel/interfaces";

const { Text } = Typography;

interface ExpertStatsProps {
  expert: DiscoverExpert;
  projectId: string;
  countryTwoLetterCode: string;
  disabled: boolean;
}

export const ExpertStats: React.FC<ExpertStatsProps> = ({
  expert,
  projectId,
  countryTwoLetterCode,
  disabled = false
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const discoverFilters = useSelector(ProjectNxSelector.discoverFilters());
  const searchTerm = useSelector(
    ProjectNxSelector.getDiscoverExpertSearchTerm()
  );

  const {
    id,
    fullName,
    title,
    email,
    phoneNumber,
    lastPublicCompanyDate,
    profileImageUrl,
    doNotContactStatus,
    customers,
    workHistories,
    stats,
    projects,
    searchMatchColumns = [],
    isRecommendedExpert,
    recommendationMatchPercentage
  } = expert;

  const discoverMatchingFiltersPanelData: DiscoverMatchingFiltersPanelData = {
    expertId: id,
    fullName,
    title,
    countryTwoLetterCode,
    lastPublicCompanyDate,
    profileImageUrl,
    doNotContactStatus,
    projectId,
    searchMatchColumns,
    customers,
    workHistories: workHistories ?? []
  };

  const matchedProjects = useMemo(() => {
    const projectsFromFilters = discoverFilters.projects.map(
      ({ label }) => label
    );

    return projects
      .filter((project) => projectsFromFilters.includes(project.name))
      .map((project) => project.name);
  }, [discoverFilters, projects]);

  const areFiltersApplied = useMemo(
    () =>
      Object.values(discoverFilters).some(
        (filter) => Array.isArray(filter) && filter.length > 0
      ),
    [discoverFilters]
  );

  const displayRecommendedMatchPercentage =
    !areFiltersApplied && !searchTerm && isRecommendedExpert;

  return (
    <Flex gap={16} align="center">
      <Flex gap={12}>
        <Stat
          title={matchedProjects.join(", ")}
          iconName="folder"
          stats={stats.projects}
        />
        <Stat title={t("calls")} iconName="call" stats={stats.calls} />
        <Stat
          title={t("surveys")}
          iconName="assignment"
          stats={stats.surveys}
        />
        <Stat
          title={t("canopies")}
          iconName="workspaces"
          stats={stats.canopies}
        />
      </Flex>

      {isAdmin && (
        <>
          {disabled || (!email && !phoneNumber) ? (
            <Button
              disabled={disabled}
              icon={<AntDIcon name="contact_emergency" />}
            />
          ) : (
            <Popover
              content={
                <Flex vertical gap={4}>
                  <Text
                    copyable={{ icon: <AntDIcon name="copy" /> }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {email}
                  </Text>
                  {phoneNumber && (
                    <Text
                      copyable={{ icon: <AntDIcon name="copy" /> }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {phoneNumber}
                    </Text>
                  )}
                </Flex>
              }
            >
              <Button icon={<AntDIcon name="contact_emergency" />} />
            </Popover>
          )}

          {displayRecommendedMatchPercentage ? (
            <Button style={{ pointerEvents: "none" }} disabled={disabled}>
              {t("matchPercentage", {
                percentage: recommendationMatchPercentage
              })}
            </Button>
          ) : (
            <Button
              iconPosition="end"
              icon={<AntDIcon name="chevronRight" />}
              disabled={searchMatchColumns.length === 0 || disabled}
              onClick={() =>
                dispatch(
                  PanelStoreActions.openPanel(
                    PanelId.DiscoverMatchingFilters,
                    discoverMatchingFiltersPanelData
                  )
                )
              }
            >
              {t("matches", { count: searchMatchColumns.length })}
            </Button>
          )}
        </>
      )}
    </Flex>
  );
};

interface StatProps {
  title: string;
  iconName: IconName;
  stats: number;
}

const Stat: React.FC<StatProps> = ({ title, iconName, stats = 0 }) => (
  <Tooltip title={title}>
    <Text type="secondary">
      <Flex gap={4} align="center">
        <AntDIcon name={iconName} />
        {stats}
      </Flex>
    </Text>
  </Tooltip>
);
