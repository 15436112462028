import { ICellRendererParams } from "ag-grid-community";
import { Button, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AddCreateVendorsPanelData } from "@arbolus-technologies/features/common";
import { CustomerSurvey } from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import { CompanyBadge } from "../../../../components/CompanyBadge";
import { requestUpdateCompanies } from "../utils";

import styles from "./CustomerSurveyCompanies.module.scss";

export const CustomerSurveyCompanies: React.FC<
  ICellRendererParams<CustomerSurvey>
> = ({ data, node }) => {
  const { t } = useTranslation("customerSurveyTable");
  const dispatch = useDispatch();

  function toggleVisibility(id: string) {
    if (!data) return;

    const companyIndex = data.companies.findIndex(
      (company) => company.id === id
    );
    if (companyIndex < 0) return;

    const companies = [...data.companies];
    const company = companies[companyIndex];
    companies[companyIndex] = {
      ...company,
      visible: !company.visible
    };
    const updatedData = { ...data, companies };
    node.setData(updatedData);
    requestUpdateCompanies(data.id, companies);
  }

  function openCompanySelectionPanel() {
    dispatch(
      PanelStoreActions.openPanel<AddCreateVendorsPanelData>(
        PanelId.AddVendor,
        {
          rowId: node.id,
          vendorIds: data?.companies
            ? data.companies.map((company) => company.id)
            : []
        }
      )
    );
  }

  return (
    <Row className={styles.surveyCompanies} align="middle">
      <Col flex="auto">
        {data?.companies.map((company) => (
          <CompanyBadge
            key={company.id}
            name={company.name}
            visible={company.visible}
            onClick={() => toggleVisibility(company.id)}
          />
        ))}
      </Col>
      <Col>
        <Button onClick={openCompanySelectionPanel} size="small" type="link">
          {t("addCompanyButton")}
        </Button>
      </Col>
    </Row>
  );
};
