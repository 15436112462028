import clsx from "clsx";
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { Button, Input } from "reactstrap";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";
import { isSupportedFileType } from "@arbolus-technologies/features/common";
import { Loader, SearchBar } from "@arbolus-technologies/ui/components";

import { APIConstants } from "../../../../../constants";
import { APP_USER_ROLES, LISTING_TYPES } from "../../../../../constants/app";
import {
  FILES_SORT_CRITERIA,
  MAXIMUM_FILES_UPLOAD_AT_ONCE,
  MAXIMUM_FILE_NAME_LENGTH,
  MAXIMUM_FILE_UPLOADING_SIZE,
  MAXIMUM_VIEWABLE_UPLOADING_FILES
} from "../../../../../constants/files";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_ATTACHMENT_PANEL,
  UI_WINDOW_HEIGHT
} from "../../../../../constants/ui";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../../contexts/contentPanel/ContentPanelContext";
import {
  ApiPaginatedRequest,
  CIQError,
  ErrorResponse
} from "../../../../../models/api";
import { Document } from "../../../../../models/documents";
import { UploadingAttachment } from "../../../../../models/view/documents";
import {
  DocumentService,
  RBServiceManager,
  UtilsService
} from "../../../../../services";
import {
  CIQEmptyPlaceholder,
  CIQInfiniteScroll
} from "../../../../app/components";
import AttachmentSidePanelItem from "./AttachmentSidePanelItem";

const notification = new ToasterService();

interface AttachmentSidePanelProps {
  headerText: string;
  actionText: string;
  projectId: string;
  excludedIds?: string[];
  excludeTranscripts?: boolean;
  onPanelClose?: () => void;
  onUploadStateChanged: (isUploading: boolean) => void;
  onActionClicked: (selectedFiles: Document[]) => void;
}

interface AttachmentSidePanelState {
  attachments: Document[];
  uploadingAttachments: UploadingAttachment[];
  currentPage: number;
  hasMoreFiles: boolean;
  isLoading: boolean;
  selectedAttachments: Document[];
  searchQuery: string;
}

type AttachmentSidePanelIntersectProps = AttachmentSidePanelProps &
  WithTranslation;

class AttachmentSidePanel extends Component<
  AttachmentSidePanelIntersectProps,
  AttachmentSidePanelState
> {
  constructor(props: AttachmentSidePanelIntersectProps) {
    super(props);
    this.state = {
      attachments: [],
      uploadingAttachments: [],
      currentPage: 0,
      hasMoreFiles: false,
      isLoading: false,
      selectedAttachments: [],
      searchQuery: ""
    };
  }

  componentDidUpdate(): void {
    const { onUploadStateChanged } = this.props;
    const { uploadingAttachments } = this.state;
    const isUploading = Boolean(uploadingAttachments.length);

    if (isUploading) {
      window.addEventListener("beforeunload", this.handleBrowserCloseListener);
    } else {
      window.removeEventListener(
        "beforeunload",
        this.handleBrowserCloseListener
      );
    }

    onUploadStateChanged(isUploading);
  }

  componentWillUnmount(): void {
    this.attachmentsFetchSubscription?.unsubscribe();
    this.attachmentShareSubscription?.unsubscribe();
    this.searchQueryDebounceTimeout &&
      clearTimeout(this.searchQueryDebounceTimeout);

    window.removeEventListener("beforeunload", this.handleBrowserCloseListener);
  }

  private attachmentsFetchSubscription?: Subscription;

  private attachmentShareSubscription?: Subscription;

  private searchQueryDebounceTimeout: number | undefined;

  private initialElementCount = APIConstants.MAX_PAGE_SIZE;

  fetchAttachments = (): void => {
    const { projectId, excludeTranscripts } = this.props;
    const { attachments, currentPage, searchQuery } = this.state;
    const nextOffset = attachments.length;

    const attachmentParams = {
      searchTerm: searchQuery,
      offset: nextOffset,
      orderBy: FILES_SORT_CRITERIA.Name.value,
      orderDirection: FILES_SORT_CRITERIA.Name.direction,
      eventAttachment: excludeTranscripts,
      limit:
        currentPage === 0
          ? this.initialElementCount
          : APIConstants.MAX_PAGE_SIZE
    } as ApiPaginatedRequest;

    this.setState({ isLoading: true });
    this.attachmentsFetchSubscription = RBServiceManager.serviceCaller(
      DocumentService.getClientDocuments(projectId, attachmentParams),
      DocumentService.getExpertDocuments(projectId, attachmentParams)
    ).subscribe(
      (response) => {
        this.setState({
          attachments: attachments.concat(response.items),
          hasMoreFiles: attachments.length < response.pagination.count,
          currentPage: currentPage + 1,
          isLoading: false
        });
      },
      (error: ErrorResponse<CIQError>) => notification.showError(error.message)
    );
  };

  handleBrowserCloseListener = (event: BeforeUnloadEvent): void => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = "";
  };

  handleClearQueryClicked = (): void => {
    this.setState(
      {
        searchQuery: "",
        currentPage: 0,
        attachments: []
      },
      () => this.fetchAttachments()
    );
  };

  handleInitialFetch = (elementCount?: number): void => {
    if (elementCount) this.initialElementCount = elementCount;
    this.fetchAttachments();
  };

  handleQueryChange = (query: string): void => {
    this.setState({ searchQuery: query, currentPage: 0, attachments: [] }, () =>
      this.fetchAttachments()
    );
  };

  handleAttachmentActionOnClick = (): void => {
    const { onPanelClose, onActionClicked } = this.props;
    const { selectedAttachments } = this.state;

    onActionClicked(selectedAttachments);
    this.setState({
      selectedAttachments: []
    });

    if (onPanelClose) {
      onPanelClose();
    }
  };

  handleOnShareChecked = (attachment: Document, state: boolean): void => {
    const { selectedAttachments } = this.state;
    if (state) {
      this.setState({
        selectedAttachments: selectedAttachments.concat(attachment)
      });
    } else {
      this.setState({
        selectedAttachments: selectedAttachments.filter(
          (a) => a.id !== attachment.id
        )
      });
    }
  };

  handleSelectedAttachmentsUpload = (selectedAttachments: FileList): void => {
    const { t } = this.props;
    const { uploadingAttachments } = this.state;

    if (uploadingAttachments.length < MAXIMUM_FILES_UPLOAD_AT_ONCE) {
      let newUploadAttachments = Array.from(selectedAttachments)
        .filter((file: File) => {
          // Validate file type
          const isSupported = isSupportedFileType(file);
          if (!isSupported) {
            notification.showError(t("unsupported"));
            return false;
          }

          // Validate file size
          const isExceedFileSize = file.size >= MAXIMUM_FILE_UPLOADING_SIZE;
          if (isExceedFileSize) {
            notification.showError(
              t("fileSizeLimitError", {
                limit: MAXIMUM_FILE_UPLOADING_SIZE / 1024 ** 2
              })
            );
            return false;
          }

          // Validate file name
          const isFileNameTooLong =
            file.name.length >= MAXIMUM_FILE_NAME_LENGTH;
          if (isFileNameTooLong) {
            notification.showError(
              t("fileNameMaxLengthError", {
                length: MAXIMUM_FILE_NAME_LENGTH
              })
            );

            return false;
          }

          return true;
        })
        .map((file: File) => ({
          attachment: file,
          attachmentId: UtilsService.generateUUID()
        }));

      const availableUploadSlots =
        MAXIMUM_FILES_UPLOAD_AT_ONCE - uploadingAttachments.length;
      if (availableUploadSlots < newUploadAttachments.length) {
        notification.showError(t("fileCountLimitError"));
      }

      newUploadAttachments.splice(
        MAXIMUM_FILES_UPLOAD_AT_ONCE - uploadingAttachments.length
      );
      newUploadAttachments = uploadingAttachments.concat(newUploadAttachments);

      this.setState({
        uploadingAttachments: newUploadAttachments
      });
    }
  };

  handleOnFinishedUploading = (
    uploadedAttachmentId: string,
    uploadedAttachment: Document
  ): void => {
    const { uploadingAttachments, attachments, selectedAttachments } =
      this.state;

    this.setState({
      uploadingAttachments: uploadingAttachments.filter(
        (attachment) => attachment.attachmentId !== uploadedAttachmentId
      ),
      attachments: [uploadedAttachment, ...attachments],
      selectedAttachments: selectedAttachments.concat(uploadedAttachment)
    });
  };

  handleUploadInterrupted = (failedFileId: string): void => {
    const { uploadingAttachments } = this.state;
    this.setState({
      uploadingAttachments: uploadingAttachments.filter(
        (f) => f.attachmentId !== failedFileId
      )
    });
  };

  handleAttachmentsBottomReached = (): void => {
    const { hasMoreFiles, isLoading } = this.state;

    if (hasMoreFiles && !isLoading) {
      this.fetchAttachments();
    }
  };

  renderEmptyStates = (): JSX.Element => {
    const { t } = this.props;
    const { searchQuery } = this.state;

    const isSearchResult = searchQuery !== "";

    const emptyResultTitle = isSearchResult
      ? t("noSearchResultTitle")
      : t("noDocs");

    const clientEmptyDescription = isSearchResult
      ? t("noSearchResultDescription")
      : t("noDocsDescription");

    const clientAdminEmptyDescription = isSearchResult
      ? t("noSearchResultDescription")
      : t("noDocsDescription");

    const expertEmptyDescription = isSearchResult
      ? t("noSearchResultDescription")
      : t("expertNoDocsDescription");

    const emptyDescription = new Map([
      [APP_USER_ROLES.client, clientEmptyDescription],
      [APP_USER_ROLES.expert, expertEmptyDescription],
      [APP_USER_ROLES.adminClient, clientAdminEmptyDescription]
    ]);

    return (
      <CIQEmptyPlaceholder
        title={emptyResultTitle}
        roleDescription={emptyDescription}
        itemType={LISTING_TYPES.DOCUMENTS}
      />
    );
  };

  renderUploadedAttachments = (): JSX.Element[] | JSX.Element => {
    let { attachments } = this.state;
    const { isLoading, uploadingAttachments } = this.state;
    const { selectedAttachments } = this.state;
    const { projectId, excludedIds } = this.props;

    attachments = attachments.filter((a) => !excludedIds?.includes(a.id));

    if (
      attachments.length === 0 &&
      uploadingAttachments.length === 0 &&
      !isLoading
    ) {
      return this.renderEmptyStates();
    }

    return attachments.map((attachment) => {
      const { id, fileName, fileSize, durationSeconds, type } = attachment;
      return (
        <AttachmentSidePanelItem
          key={id}
          isChecked={Boolean(selectedAttachments.find((a) => a.id === id))}
          attachmentName={fileName}
          attachmentSize={fileSize}
          attachmentDuration={durationSeconds}
          attachmentId={id}
          projectId={projectId}
          onShareChecked={(status): void =>
            this.handleOnShareChecked(attachment, status)
          }
          attachmentType={type}
        />
      );
    });
  };

  renderUploadingAttachments = (): JSX.Element[] => {
    const { uploadingAttachments } = this.state;
    const { projectId } = this.props;

    return uploadingAttachments.map(({ attachment, attachmentId }) => (
      <AttachmentSidePanelItem
        key={attachmentId}
        attachmentName={attachment.name}
        attachmentSize={attachment.size}
        attachmentId={attachmentId}
        projectId={projectId}
        attachment={attachment}
        onUploadStop={this.handleUploadInterrupted}
        onFinishedUploading={this.handleOnFinishedUploading}
      />
    ));
  };

  render(): JSX.Element {
    const { headerText, actionText, t } = this.props;
    const {
      isLoading,
      uploadingAttachments,
      selectedAttachments,
      searchQuery
    } = this.state;

    const isUploading = uploadingAttachments.length > 0;
    const isChecked = selectedAttachments.length === 0;

    const uploadingElementHeight = `${
      UI_ATTACHMENT_PANEL.SINGLE_UPLOADING_ELEMENT_HEIGHT *
      (uploadingAttachments.length < MAXIMUM_VIEWABLE_UPLOADING_FILES
        ? uploadingAttachments.length
        : MAXIMUM_VIEWABLE_UPLOADING_FILES)
    }px`;

    const isUploadDisabled =
      uploadingAttachments.length >= MAXIMUM_FILES_UPLOAD_AT_ONCE;

    return (
      <Media queries={APP_DEVICE_MEDIA_QUERIES}>
        {(matches): JSX.Element => (
          <div className="content-panel-body">
            <div className="panel-header">
              <h2>{headerText}</h2>
              <ContentPanelContextConsumer>
                {({ closePanel }: ContentPanelContextProps): JSX.Element => (
                  <div
                    className={clsx("btn-close", {
                      disabled: isUploading
                    })}
                    onClick={closePanel}
                  >
                    <i className="ciq-icon ciq-close" />
                  </div>
                )}
              </ContentPanelContextConsumer>
            </div>
            <div className="file-list-body panel-body">
              <div className="top-container">
                {isUploading ? (
                  <div className="uploading-text">{t("uploadingFiles")}</div>
                ) : (
                  <div className="search-filter-container">
                    <SearchBar
                      initialValue={searchQuery}
                      onQueryChange={this.handleQueryChange}
                      onClear={this.handleClearQueryClicked}
                      placeholder={t("searchNotesAndDocs")}
                      isDebounced
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="file-list-container">
              {isUploading && (
                <SimpleBar
                  className="uploading-files-container simplebar-light"
                  style={{
                    maxHeight: uploadingElementHeight,
                    overflowX: "hidden"
                  }}
                >
                  {this.renderUploadingAttachments()}
                </SimpleBar>
              )}

              <CIQInfiniteScroll
                className="uploaded-container simplebar-light"
                style={{
                  maxHeight: `calc(${UI_WINDOW_HEIGHT} - (${UI_ATTACHMENT_PANEL.PANEL_EXCESS_HEIGHT}px + ${uploadingElementHeight}))`,
                  height: `calc(${UI_WINDOW_HEIGHT} - (${UI_ATTACHMENT_PANEL.PANEL_EXCESS_HEIGHT}px + ${uploadingElementHeight}))`,
                  overflowX: "hidden"
                }}
                onBottomReached={this.handleAttachmentsBottomReached}
                onInitialFetch={this.handleInitialFetch}
                elementHeight={UI_ATTACHMENT_PANEL.ATTACHMENT_ELEMENT_HEIGHT(
                  matches
                )}
              >
                {this.renderUploadedAttachments()}
                {isLoading && <Loader />}
              </CIQInfiniteScroll>
            </div>
            <div className="panel-footer">
              <div className="left-container">
                <div className="form-file-upload">
                  <Button
                    size="lg"
                    color="secondary"
                    disabled={isUploadDisabled}
                    className="m-0"
                  >
                    <i className="ciq-icon ciq-plus" /> {t("upload")}
                  </Button>
                  <Input
                    disabled={isUploadDisabled}
                    type="file"
                    multiple
                    onChange={({ target }): void => {
                      if (target.files && target.files?.length > 0) {
                        this.handleSelectedAttachmentsUpload(target.files);
                      }

                      // eslint-disable-next-line no-param-reassign
                      target.value = "";
                    }}
                  />
                </div>
              </div>
              <div className="right-container">
                <Button
                  size="lg"
                  color="primary"
                  className="m-0"
                  disabled={isChecked || isUploading}
                  onClick={this.handleAttachmentActionOnClick}
                >
                  {actionText}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Media>
    );
  }
}

export default withTranslation("attachmentSidePanel")(AttachmentSidePanel);
