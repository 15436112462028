import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext } from "@dnd-kit/sortable";
import React from "react";

import { DragAndDropItem } from "./DragAndDropItem";

import styles from "./DragAndDropWrapper.module.scss";

type NamedType = { id: number | string };

interface DragAndDropWrapperProps<T extends NamedType> {
  items: T[];
  isDisabled?: boolean;
  onItemsChange?: (items: T[], oldIndex: number, newIndex: number) => void;
  renderItem: (item: T, itemNumber: number) => JSX.Element;
}

export const DragAndDropWrapper = <T extends NamedType>({
  items,
  isDisabled = false,
  onItemsChange,
  renderItem
}: DragAndDropWrapperProps<T>): JSX.Element => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over) {
      if (active.id !== over.id) {
        // Reorder the list
        if (typeof onItemsChange === "function") {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over.id);

          onItemsChange(items, oldIndex, newIndex);
        }
      }
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext items={items}>
        <div className={styles.container}>
          {items.map((item, index) => (
            <DragAndDropItem key={item.id} id={item.id} isDisabled={isDisabled}>
              {renderItem(item, index + 1)}
            </DragAndDropItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
