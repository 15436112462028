import { Flex, Input, Select } from "antd";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { SURVEY_STATUS } from "@arbolus-technologies/models/survey";

const { Search } = Input;

const STATUS_OPTIONS = [
  {
    value: "",
    label: i18next.t("surveyListFilters:all")
  },
  {
    value: SURVEY_STATUS.ACTIVE,
    label: SURVEY_STATUS.ACTIVE
  },
  {
    value: SURVEY_STATUS.CLOSED,
    label: SURVEY_STATUS.CLOSED
  }
];

interface SurveyListFiltersProps {
  onFilterChange: (searchTerm?: string, surveyStatus?: SURVEY_STATUS) => void;
}

export const SurveyListFilters: React.FC<SurveyListFiltersProps> = ({
  onFilterChange
}) => {
  const { t } = useTranslation("surveyListFilters");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const handleQueryChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    onFilterChange(searchTerm, status ? (status as SURVEY_STATUS) : undefined);
  };

  const handleChangeStatus = (status: string) => {
    setStatus(status);
    onFilterChange(searchTerm, status ? (status as SURVEY_STATUS) : undefined);
  };

  return (
    <Flex gap={24} justify="space-between">
      <Search
        placeholder={t("searchPlaceholder")}
        allowClear
        onSearch={handleQueryChange}
        style={{ width: 304 }}
      />
      <Select
        value={status}
        onChange={handleChangeStatus}
        options={STATUS_OPTIONS}
        style={{ width: 200 }}
      />
    </Flex>
  );
};
