import { Alert, Button, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExportSurveyExpertListRequest,
  FileResponse,
  Survey,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  SURVEY_STATUS,
  SURVEY_TABS,
  SURVEY_TABS_TYPE
} from "@arbolus-technologies/models/survey";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { Loader } from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import {
  APP_DATE_FORMAT,
  blobTypeExtension,
  string64toBlob
} from "@arbolus-technologies/utils";

import { FilterChangedEvent, FilterModel } from "ag-grid-community";
import saveAs from "file-saver";
import { CreateSurvey } from "../../Components/CreateSurvey";
import { EditSurvey } from "../../Components/EditSurvey";
import { SurveyExpertTable } from "../../Components/SurveyExpertTable/SurveyExpertTable";

interface SurveyPageProps {
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const SurveyPage: React.FC<SurveyPageProps> = ({
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("surveyPage");
  const { projectId, surveyId } = useParams<{
    projectId: string;
    surveyId?: string;
  }>();

  const initialExportSurveyExpertListRequest: ExportSurveyExpertListRequest = {
    amount: null,
    completionDate: null,
    email: null,
    fullName: null,
    paymentMethod: null,
    surveyExpertId: null
  };

  const [activeTab, setActiveTab] = useState<SURVEY_TABS_TYPE>(
    SURVEY_TABS.DETAILS
  );
  const [survey, setSurvey] = useState<Survey>();
  const [isSurveyClosed, setIsSurveyClosed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exportSurveyExpertListRequest, setExportSurveyExpertListRequest] =
    useState<ExportSurveyExpertListRequest>(
      initialExportSurveyExpertListRequest
    );

  useEffect(() => {
    if (surveyId && activeTab === SURVEY_TABS.DETAILS) {
      setIsLoading(true);
      surveyService.getSurvey(surveyId).subscribe(
        (res) => {
          setSurvey(res);
          setIsSurveyClosed(res.status === SURVEY_STATUS.CLOSED);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      );
    }
  }, [surveyId, activeTab]);

  const handleSurveyClose = () => {
    setIsSurveyClosed(true);
  };

  const handleOnFilterChange = (event: FilterChangedEvent) => {
    const { api } = event;
    const filterModel = api.getFilterModel();

    const {
      fullName = {},
      email = {},
      surveyExpertId = {},
      completionDate = {},
      paymentMethod = {},
      amount = {}
    } = filterModel as FilterModel;

    const exportSurveyExpertListRequest: ExportSurveyExpertListRequest = {
      fullName: fullName.filter ?? null,
      email: email.filter ?? null,
      surveyExpertId: surveyExpertId.filter ?? null,
      completionDate: completionDate.filter ?? null,
      paymentMethod: paymentMethod.values ?? null,
      amount: amount.values ?? null
    };

    setExportSurveyExpertListRequest(exportSurveyExpertListRequest);
  };

  const handleExportExperts = () => {
    if (surveyId) {
      surveyService
        .exportSurveyExperts(surveyId, exportSurveyExpertListRequest)
        .subscribe({
          next: (response: FileResponse) => {
            const blob = string64toBlob(response.file, blobTypeExtension);
            saveAs(blob, response.name);
            notificationService.showSuccess(t("downloadSuccess"));
          },
          error: (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
          }
        });
    }
  };

  const renderTabsPage = {
    [SURVEY_TABS.DETAILS]: survey ? (
      isLoading ? (
        <Loader isFull />
      ) : (
        <EditSurvey
          surveyDetails={survey}
          isSurveyClosed={isSurveyClosed}
          onSurveyClose={handleSurveyClose}
        />
      )
    ) : (
      <CreateSurvey />
    ),
    [SURVEY_TABS.EXPERTS]: (
      <SurveyExpertTable onFilterChange={handleOnFilterChange} />
    )
  };

  if (surveyId && !survey) {
    return <Loader isFull />;
  }

  return (
    <MainPageLayout
      title={surveyId ? survey?.title : t("createSurvey")}
      stickyHeader
      backLink={
        projectId ? (PROJECT_ROUTE(projectId).pathname as string) : undefined
      }
      rightButtonContainer={
        activeTab === SURVEY_TABS.EXPERTS ? (
          <Button onClick={handleExportExperts} type="primary">
            {t("export")}
          </Button>
        ) : undefined
      }
    >
      <Space.Compact block>
        {Object.values(SURVEY_TABS).map((tabKey) => (
          <Button
            key={tabKey}
            type={tabKey === activeTab ? "primary" : "default"}
            onClick={() => setActiveTab(tabKey)}
            disabled={tabKey === SURVEY_TABS.EXPERTS && !survey?.id}
          >
            {t(tabKey)}
          </Button>
        ))}
      </Space.Compact>
      {isSurveyClosed && (
        <Alert
          showIcon
          message={t("surveyClosed", {
            date: moment(survey?.surveyClosedDate).format(APP_DATE_FORMAT)
          })}
          type="warning"
        />
      )}
      {renderTabsPage[activeTab]}
    </MainPageLayout>
  );
};
