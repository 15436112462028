import React from "react";

import { QuestionAnswer } from "@arbolus-technologies/api";
import { YELLOW } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { highlightWords } from "@arbolus-technologies/utils";

import { Flex, Typography } from "antd";

interface ScreeningAnswerProps {
  questionAnswer: QuestionAnswer;
  index: number;
  keywordsList: string[];
}

const { Title } = Typography;

export const ScreeningAnswer: React.FC<ScreeningAnswerProps> = ({
  questionAnswer,
  index,
  keywordsList
}) => {
  const { projectAnswer, projectQuestion } = questionAnswer;

  const highlightedWords = highlightWords({
    text: projectAnswer.answer,
    tags: keywordsList,
    highlightColor: YELLOW,
    boldedWords: false
  });

  return (
    <Flex vertical style={{ textAlign: "left" }} gap={4}>
      <Title level={5}>{`Q${index + 1}. ${projectQuestion.question}`}</Title>
      <DangerouslySetInnerHTML text={highlightedWords} />
    </Flex>
  );
};
