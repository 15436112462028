import { App } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  DefaultToasterService,
  MonitoringListReportResponse,
  MonitoringListService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { RequestConfirmationContent } from "../Components/RequestConfirmationContent/RequestConfirmationContent";

export function useReport(companyId: string, companyName: string) {
  const [report, setReport] = useState<MonitoringListReportResponse>();

  const { modal } = App.useApp();
  const { t } = useTranslation("userMonitoringListPage");
  const currentUser = useSelector(CacheSelector.currentUserSelector());
  const { trackClick } = useArbolusTracking();

  const fetchReport = useCallback(function () {
    if (!companyId) return;

    MonitoringListService.getCompanyReport(companyId)
      .then(setReport)
      .catch((error) => {
        DefaultToasterService.showApiErrors(error);
        console.error(error);
      });
  }, []);

  const openReportRequestModal = () => {
    if (report) {
      DefaultToasterService.showInfo(
        `This report already exists: ${JSON.stringify(report)}`
      );
      return;
    }

    modal.confirm({
      title: t("requestModalTitle", { companyName }),
      content: <RequestConfirmationContent />,
      okText: t("requestModalOkButton"),
      onOk: handleRequestReport,
      icon: null,
      closable: true,
      width: 480
    });
  };

  function handleRequestReport() {
    requestReport()
      .then(() => {
        showThankYou();
        trackClick(MixPanelEventNames.MonitoringListCompanyDataReportRequest);
      })
      .catch((error) => {
        console.error(error);
        DefaultToasterService.showApiErrors(error);
      });
  }

  const requestReport = useCallback(async () => {
    await MonitoringListService.requestCompanyReport(companyId);
    setReport({
      reportDate: new Date().toISOString()
    });
  }, []);

  function showThankYou() {
    modal.info({
      title: t("requestThanksTitle"),
      content: t("requestThanksContent", { email: currentUser.email }),
      icon: null
    });
  }

  return { report, fetchReport, openReportRequestModal };
}
