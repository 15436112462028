import { EventFormInterface } from "@arbolus-technologies/models/project";
import { Button, Flex, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface FormActionsProps {
  isEditMode: boolean;
  isSaving: boolean;
}

export const FormActions: React.FC<FormActionsProps> = ({
  isEditMode,
  isSaving
}) => {
  const { t } = useTranslation("eventForm");
  const history = useHistory();
  const form = Form.useFormInstance<EventFormInterface>();
  const formValues = Form.useWatch([], form);

  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        const fieldsErrors = form.getFieldsError();
        const hasErrors = fieldsErrors.some(
          (fieldError) => fieldError.errors.length > 0
        );
        setIsSubmittable(!hasErrors);
      })
      .catch((errorInfo) => {
        const hasErrors = errorInfo.errorFields.length > 0;
        return setIsSubmittable(!hasErrors);
      });
  }, [form, formValues]);

  return (
    <Flex justify="flex-end" gap={16}>
      <Button onClick={() => history.goBack()}>{t("cancel")}</Button>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSaving}
          disabled={!isSubmittable}
        >
          {isEditMode ? t("updateEvent") : t("createEvent")}
        </Button>
      </Form.Item>
    </Flex>
  );
};
