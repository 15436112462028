import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import clsx from "clsx";
import React, { CSSProperties } from "react";

import { IconName, icons } from "./IconDictionary";

import styles from "./AntDIcon.module.scss";

export interface AntDIconProps {
  name: IconName;
  fontSize?: string;
  color?: string;
  filled?: boolean;
  cursor?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  tooltip?: React.ReactNode | string;
  id?: string;
  inline?: boolean;
  className?: string;
  style?: CSSProperties;
  tooltipPlacement?: TooltipPlacement;
}

export const AntDIcon: React.FC<AntDIconProps> = ({
  name,
  fontSize = "16px",
  color,
  filled = false,
  cursor = false,
  onClick,
  disabled = false,
  tooltip,
  id,
  inline = false,
  className = "",
  style,
  tooltipPlacement
}) => {
  const IconComponent = icons[name];

  const Component = (
    <div
      onClick={onClick}
      className={clsx(styles.icon, className, {
        [styles.cursor]: !!onClick || cursor,
        [styles.filled]: filled,
        [styles.disabled]: disabled,
        [styles.inline]: inline
      })}
      id={id}
      style={{
        color,
        height: fontSize,
        ...style
      }}
      data-testid={id}
    >
      <IconComponent fontSize={fontSize} style={{ fontSize: fontSize }} />
    </div>
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      {Component}
    </Tooltip>
  ) : (
    Component
  );
};

export type { IconName };
