import { Card, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { Compliance as ComplianceType, User } from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";

import { ComplianceAnswers } from "./ComplianceAnswers";
import { ComplianceForm } from "./ComplianceForm";

const { Text } = Typography;

interface ComplianceProps {
  complianceInfo: ComplianceType[];
  applicationStatus: REFERRAL_SUB_STATE;
  complianceStatus: REFERRAL_COMPLIANCE_STATE;
  chaperoneCall?: boolean;
  complianceNote?: string;
  complianceUpdatedBy: User;
  complianceUpdateDate: Date;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  expertId: string;
  onUpdateComplianceAnswers: (answers: ComplianceType[]) => void;
}

export const Compliance: React.FC<ComplianceProps> = ({
  complianceInfo,
  applicationStatus,
  complianceStatus,
  chaperoneCall = false,
  complianceNote,
  complianceUpdatedBy,
  complianceUpdateDate,
  doNotContactStatus,
  expertId,
  onUpdateComplianceAnswers
}) => {
  const { t } = useTranslation("referralCompliance");

  const isApplicationPending = applicationStatus === REFERRAL_SUB_STATE.PENDING;

  return (
    <Card>
      <Flex vertical gap={16}>
        <Text strong>{t("complianceQuestions")}</Text>
        {!isApplicationPending ||
        doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC ? (
          <ComplianceAnswers
            complianceInfo={complianceInfo}
            complianceStatus={complianceStatus}
            chaperoneCall={chaperoneCall}
            complianceNote={complianceNote}
            complianceUpdatedBy={complianceUpdatedBy}
            complianceUpdateDate={complianceUpdateDate}
          />
        ) : (
          <ComplianceForm
            complianceInfo={complianceInfo}
            expertId={expertId}
            onUpdateComplianceAnswers={onUpdateComplianceAnswers}
          />
        )}
      </Flex>
    </Card>
  );
};
