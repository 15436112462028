import { Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import React from "react";
import { Link } from "react-router-dom";

interface AntDButtonLinkProps extends BaseButtonProps {
  text?: string;
  style?: React.CSSProperties;
  pathname: string;
  search?: string;
  state?: any;
  onClick?: () => void;
}

export const AntDButtonLink: React.FC<AntDButtonLinkProps> = ({
  pathname,
  text,
  onClick,
  style,
  search,
  state,
  ...props
}) => {
  return (
    <Link to={{ pathname, search, state }} style={style}>
      <Button onClick={onClick} {...props}>
        {text}
      </Button>
    </Link>
  );
};
