import { Button } from "arbolus-ui-components";
import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  Company,
  CompanyService,
  DefaultToasterService,
  ErrorResponse,
  LIST_COMPANIES_ORDER_BY,
  RELATIONSHIP_TYPE,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import { SearchInput } from "@arbolus-technologies/ui/components";
import { SEARCH_DEBOUNCE_TIMEOUT_COMMON } from "@arbolus-technologies/utils";

import { SelectableCompany } from "./SelectableCompany/SelectableCompany";

import styles from "./CompanyPanelBody.module.scss";

interface CompanyPanelBodyProps {
  company: Company | null;
  onSelectCompany: (value: Company) => void;
  newCompanyUrl?: string;
  companyService?: typeof CompanyService;
  notificationService?: ToasterService;
}
export const CompanyPanelBody: React.FC<CompanyPanelBodyProps> = ({
  company,
  onSelectCompany,
  newCompanyUrl,
  companyService = CompanyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("companySelectionPanel");

  const [searchTerm, setSearchTerm] = useState<string>(company?.name ?? "");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(
    company
  );

  useEffect(() => {
    const queryParams = {
      searchTerm,
      offset: 0,
      limit: 100,
      sort: [
        {
          orderBy: LIST_COMPANIES_ORDER_BY.Name,
          orderDirection: SORT_DIRECTION.ASCENDING
        }
      ]
    };

    companyService.listCompanies(queryParams).subscribe(
      (res) => {
        setCompanies(res.items);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  }, [companyService, searchTerm, notificationService]);

  const displayCreateCompanyButton = useMemo(
    () =>
      searchTerm &&
      companies?.every(
        (c) => c.name.toLowerCase() !== searchTerm.toLowerCase()
      ),
    [companies, searchTerm]
  );

  const onQueryChange = (searchValue: string) => {
    setSearchTerm(searchValue);
    if (searchValue === "") {
      setCompanies([]);
    }
  };

  const handleSelectedCompany = (company: Company) => {
    setSelectedCompany(company);
    onSelectCompany(company);
  };

  const handleOnCreateCompany = () => {
    companyService
      .createCompany({ name: searchTerm, website: newCompanyUrl })
      .subscribe(
        (response) => {
          onSelectCompany({
            id: response.id,
            name: searchTerm,
            website: newCompanyUrl,
            relationship: {
              type: RELATIONSHIP_TYPE.None
            }
          });
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  return (
    <div>
      <SearchInput
        onQueryChange={onQueryChange}
        isDebounced
        initialValue={searchTerm}
        debouncingTime={SEARCH_DEBOUNCE_TIMEOUT_COMMON}
        placeholder={t("searchCompanyPlaceholder")}
        hasSearchIcon
        containerClassnames={styles.searchInputContainer}
        autoFocus={!isMobile}
      />
      {companies?.length > 0 && (
        <div className={styles.companyListContainer}>
          {companies.map((company) => {
            const isSelected = company.id === selectedCompany?.id;

            return (
              <SelectableCompany
                key={company.id}
                company={company}
                isSelected={isSelected}
                handleSelectedCompany={handleSelectedCompany}
              />
            );
          })}
        </div>
      )}
      {displayCreateCompanyButton && (
        <div className={styles.noResultsContainer}>
          {companies?.length === 0 && (
            <span className={styles.noResults}>{t("noResults")}</span>
          )}
          <div className={styles.createCompanyButtonContainer}>
            <Button
              text={t("createCompanyButton")}
              onClick={handleOnCreateCompany}
              endIcon="add"
            />
          </div>
        </div>
      )}
    </div>
  );
};
