import { Card, Divider, Flex, Statistic, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { ExpertRate } from "@arbolus-technologies/models/common";
import { EXPERT_EXPERIENCE } from "@arbolus-technologies/models/expert";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { getCurrenciesSymbols } from "@arbolus-technologies/utils";

const { Text } = Typography;

interface CallPriceProps {
  expert: ExpertDetail;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const CallPrice: React.FC<CallPriceProps> = ({
  expert,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralCallPrice");
  const { projectId } = useParams<{ projectId: string }>();

  const [uiExpertRate, setUiExpertRate] = useState<ExpertRate | null>(null);

  const timezones = useSelector(CacheSelector.timezones());

  useEffect(() => {
    getExpertRate(projectId, expert.id);
  }, []);

  const getExpertRate = (projectId: string, expertId: string) => {
    projectService.getRate(projectId, expertId).subscribe(
      (rate) => {
        setUiExpertRate(rate);
      },
      (err: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(err);
      }
    );
  };

  const pricingText = (): string => {
    const { pricing } = uiExpertRate!;

    if (pricing?.rate) {
      return `${pricing.rate.amount} ${getCurrenciesSymbols(
        pricing.rate.isoCurrencyCode
      )}`;
    }

    if (pricing?.creditRate) {
      return t("credit", { count: pricing.creditRate });
    }
    return t("notAssigned");
  };

  if (!uiExpertRate) {
    return null;
  }

  return (
    <Card>
      <Flex justify="space-between" align="flex-start">
        <Flex vertical gap={4}>
          <Text strong>{t("callPrice")}</Text>
          <Flex gap={8}>
            <Text>{t("followUpDiscount")}</Text>
            {uiExpertRate.pricing?.hasMinimumCallTimeRate && (
              <Text>{t("minimumCall")}</Text>
            )}
          </Flex>
        </Flex>
        <Flex gap={16} align="center">
          <Statistic
            value={pricingText()}
            valueStyle={{ color: ARBOLUS_COLORS.bColorSuccess }}
          />
        </Flex>
      </Flex>
      <Divider />
      <Flex vertical gap={4}>
        <Text strong>{t("details")}</Text>
        <Flex wrap style={{ rowGap: 8, columnGap: 24 }}>
          <Tooltip title={expert.user.email}>
            <Text ellipsis style={{ maxWidth: "300px" }}>
              {expert.user.email}
            </Text>
          </Tooltip>
          <Text>{expert.user.phoneNumber}</Text>
          {expert.experienceLevel && (
            <Text>{EXPERT_EXPERIENCE.get(expert.experienceLevel)?.label}</Text>
          )}
          {/* TODO: To be added once BE is fixed and returns rate card for all pricing type */}
          {/* {expert.rateCards && (
            <Text>
              {t("rate", {
                price: expert.rateCards[0].price,
                isoCurrencyCode: expert.rateCards[0].isoCurrencyCode
              })}
            </Text>
          )} */}
          <Text>
            {timezones.find((tz) => tz.value === expert.user.timezone)?.label}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
