import { Button } from "arbolus-ui-components";
import React from "react";

import { ClipboardService, Project } from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  Referral,
  SlotMoment
} from "@arbolus-technologies/models/common";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Booking } from "../BookingButton/Booking";
import { formatSlotMoment } from "./ReferralSlotsDetailHelper";
import styles from "./SingleSlot.module.scss";

interface SingleSlotProps {
  referral: Referral;
  slot: SlotMoment;
  project: Project;
  timezone: string;
}

export const SingleSlot: React.FC<SingleSlotProps> = ({
  referral,
  project,
  slot,
  timezone
}) => {
  if (!referral.isBookable && !referral.onlyMissingReview) {
    return renderButton(slot, true);
  }

  return (
    <Booking
      key={slot.identifier}
      slot={slot.toSlot()}
      referral={referral}
      timezone={timezone}
      project={project}
      render={(_, onClick) =>
        renderButton(
          slot,
          referral.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC,
          onClick
        )
      }
    />
  );
};

function renderButton(
  slot: SlotMoment,
  disabled = false,
  onClick?: () => void
) {
  return (
    <div className="d-flex align-items-start">
      <Button
        text={formatSlotMoment(slot)}
        type="tertiary"
        disabled={disabled}
        size="small"
        onClick={onClick}
      />
      <AntDIcon
        className={styles.copyIcon}
        tooltip="copy"
        color={ARBOLUS_COLORS.bColorBasePurple}
        name="copy"
        onClick={() => ClipboardService.copyToClipboard(formatSlotMoment(slot))}
      />
    </div>
  );
}
