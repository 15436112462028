import React from "react";
import { TypeaheadResult } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";

import {
  ApiPaginatedRequest,
  ApiPaginatedResponse
} from "@arbolus-technologies/api";
import { formatDate } from "@arbolus-technologies/utils";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

import styles from "./EventSelector.module.scss";

interface DisplayEvent {
  title: string;
  startTime: Date;
}

export interface EventSelectorProps<T extends DisplayEvent>
  extends TypeaheadSelectorControllerProps<DisplayEvent> {
  getItems: (
    pagination: ApiPaginatedRequest
  ) => Observable<ApiPaginatedResponse<T>>;
}

export const EventSelector = <T extends DisplayEvent>({
  placeholderText,
  ...props
}: EventSelectorProps<T>): React.ReactElement => {
  const { t } = useTranslation();
  const placeholder = placeholderText ?? t("common:eventSelection:findEvent");

  const renderer = ({
    title,
    startTime
  }: TypeaheadResult<DisplayEvent>): JSX.Element => (
    <div className={styles.itemContainer}>
      <span className={styles.itemText}>{title}</span>
      <span className={styles.itemText}>{formatDate(startTime)}</span>
    </div>
  );

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={t("common:eventSelection:findingEvents")}
      searchText={t("common:eventSelection:findingEvents")}
      paginationText={t("common:eventSelection:loadMore")}
      orderBy="name"
      renderer={renderer}
      getLabelKey={(event) => event.title ?? ""}
    />
  );
};
