import i18next from "i18next";
import * as Yup from "yup";

import {
  DIGIT_REGEX_REQUIRED,
  LOWER_UPPERCASE_REGEX,
  SPECIAL_CHARACTERS_REGEX,
  UserConstraints
} from "../../../../../constants/validation";
import { UtilsService } from "../../../../../services";

const getSharedFields = (isCanopySignUp?: boolean) => ({
  firstName: Yup.string()
    .max(
      UserConstraints.MAX_FIRST_NAME_LENGTH,
      i18next.t("newUserPage:firstNameMaxLengthError", {
        length: UserConstraints.MAX_FIRST_NAME_LENGTH
      })
    )
    .test(
      "firstName-valid",
      i18next.t("newUserPage:urlValuesAreInvalid"),
      (val: string) => !UtilsService.isStringUrl(val)
    )
    .required(i18next.t("newUserPage:firstNameRequireError")),
  lastName: Yup.string()
    .max(
      UserConstraints.MAX_LAST_NAME_LENGTH,
      i18next.t("newUserPage:lastNameMaxLengthError", {
        length: UserConstraints.MAX_LAST_NAME_LENGTH
      })
    )
    .test(
      "lastName-valid",
      i18next.t("newUserPage:urlValuesAreInvalid"),
      (val: string) => !UtilsService.isStringUrl(val)
    )
    .required(i18next.t("newUserPage:lastNameRequireError")),
  title: isCanopySignUp
    ? Yup.string()
        .max(
          UserConstraints.MAX_TITLE_LENGTH,
          i18next.t("newUserPage:titleMaxLengthError", {
            length: UserConstraints.MAX_TITLE_LENGTH
          })
        )
        .test(
          "lastName-valid",
          i18next.t("newUserPage:urlValuesAreInvalid"),
          (val: string) => !UtilsService.isStringUrl(val)
        )
        .required(i18next.t("newUserPage:titleRequireError"))
    : Yup.string(),
  password: Yup.string()
    .required(i18next.t("newUserPage:passwordRequiredError"))
    .matches(LOWER_UPPERCASE_REGEX, " ")
    .matches(SPECIAL_CHARACTERS_REGEX, " ")
    .matches(DIGIT_REGEX_REQUIRED, " ")
    .min(UserConstraints.MIN_PASSWORD_LENGTH, " "), // Note: Empty string will replace by yup, use a space for empty error message
  password2: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      i18next.t("newUserPage:passwordMatchError")
    )
    .required(i18next.t("newUserPage:password2RequiredError")),
  email: Yup.string()
    .required(i18next.t("newUserPage:emailRequiredError"))
    .email(i18next.t("newUserPage:emailInvalidError"))
    .max(
      UserConstraints.MAX_EMAIL_LENGTH,
      i18next.t("newUserPage:maxEmailLength", {
        length: UserConstraints.MAX_EMAIL_LENGTH
      })
    ),
  phoneNumber: Yup.string().test(
    "phone-valid",
    i18next.t("newUserPage:phoneNumberInvalid"),
    (val: string) => UtilsService.validateContactNumber(`+${val}`)
  )
});

export const ExpertRegisterSchema = (isCanopySignUp?: boolean) =>
  Yup.object({
    ...getSharedFields(isCanopySignUp),
    linkedinUrl: Yup.string()
      .url(i18next.t("newUserPage:linkedinUrlInvalid"))
      .required(i18next.t("newUserPage:linkedinUrlRequired"))
  });

export const ClientRegisterSchema = (isCanopySignUp?: boolean) =>
  Yup.object({
    ...getSharedFields(isCanopySignUp),
    displayName: Yup.string().when("isRealNameEnabled", {
      is: false,
      then: Yup.string().required(
        i18next.t("newUserPage:displayNameRequiredError")
      ),
      otherwise: Yup.string().nullable()
    })
  });
