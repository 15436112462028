export const ADMIN_ENDPOINT = `/admin`;

export const ADMIN_API = {
  GET_ADMIN_USERS: (): string => `${ADMIN_ENDPOINT}/users`,
  GET_CLIENTS: (): string => `${ADMIN_ENDPOINT}/clients`,
  GET_ALL_CLIENTS: (): string => `${ADMIN_ENDPOINT}/clients/all`,
  GET_PROJECTS: (): string => `${ADMIN_ENDPOINT}/projects`,
  CREATE_CLIENT: (): string => `${ADMIN_ENDPOINT}/clients`,
  GET_CLIENT: (clientId: string): string =>
    `${ADMIN_ENDPOINT}/clients/${clientId}`,
  UPDATE_CLIENT: (clientId: string): string =>
    `${ADMIN_ENDPOINT}/clients/${clientId}`,
  GET_MEMBERS: (clientId: string): string =>
    `${ADMIN_ENDPOINT}/clients/${clientId}/members`,
  GET_MANAGERS: (clientId: string): string =>
    `${ADMIN_ENDPOINT}/clients/${clientId}/managers`,
  GET_CLIENT_PROJECTS_COUNT: (clientId: string): string =>
    `${ADMIN_ENDPOINT}/clients/${clientId}/project-counts`,
  GET_USER_CANDIDATE_CLIENTS: (userId: string): string =>
    `${ADMIN_ENDPOINT}/users/${userId}/candidate-clients`,
  ADD_CLIENT_MANAGER: (clientId: string): string =>
    `${ADMIN_ENDPOINT}/clients/${clientId}/manager`,
  GET_USER_CLIENTS: (userId: string): string =>
    `${ADMIN_ENDPOINT}/users/${userId}/clients`,
  GET_ALL_CLIENT_PROJECTS: (): string => `${ADMIN_ENDPOINT}/projects/all`,
  GET_PROJECT_NOTES_N_DOCS: (projectId: string): string =>
    `${ADMIN_ENDPOINT}/${projectId}/notes-and-docs`,
  CREATE_DOCUMENT: (projectId: string): string =>
    `${ADMIN_ENDPOINT}/${projectId}/documents`,
  GET_DOCUMENTS: (projectId: string): string =>
    `${ADMIN_ENDPOINT}/projects/${projectId}/documents`,
  GET_PROJECT_EXPERTS: (projectId: string): string =>
    `${ADMIN_ENDPOINT}/projects/${projectId}/experts`,
  GET_PROJECT_EVENTS: (projectId: string): string =>
    `${ADMIN_ENDPOINT}/projects/${projectId}/events`,
  GET_PROJECT_EVENT_DURATION: (projectId: string, eventId: string): string =>
    `${ADMIN_ENDPOINT}/projects/${projectId}/event/${eventId}/duration`,
  GET_ADMIN_DOCUMENT_STATUS: (projectId: string, documentId: string): string =>
    `${ADMIN_ENDPOINT}/projects/${projectId}/documents/${documentId}/status`,
  CREATE_DRAFT_PROJECT: (): string => `${ADMIN_ENDPOINT}/project-drafts`,
  UPDATE_DRAFT_PROJECT: (draftId: string): string =>
    `${ADMIN_ENDPOINT}/project-drafts/${draftId}`,
  DRAFT_PROJECT_APPROVAL: (): string =>
    `${ADMIN_ENDPOINT}/project-drafts/notification`,
  DEACTIVATE_USER: (userId: string): string =>
    `${ADMIN_ENDPOINT}/users/${userId}/status`,
  PAYMENT_INVITATION: (userId: string): string =>
    `${ADMIN_ENDPOINT}/experts/${userId}/payment-invitation`,
  GET_OWNER_USERS: (projectId: string): string =>
    `${ADMIN_ENDPOINT}/projects/${projectId}/experts`,
  DELETE_EXPERT: (userId: string): string =>
    `${ADMIN_ENDPOINT}/users/${userId}`,
  GET_CANOPIES: (): string => `${ADMIN_ENDPOINT}/canopies`,
  GET_CLIENT_ADMIN_USERS: (clientId: string): string =>
    `${ADMIN_ENDPOINT}/clients/${clientId}/managers`,
  EDIT_ANGLE_QUESTIONS: (angleId: string): string =>
    `${ADMIN_ENDPOINT}/${angleId}/screening-questions`,
  REPORT_INCIDENT: (): string => `${ADMIN_ENDPOINT}/report`,
  ADD_INCIDENT_ATTACHMENT: (incidentKey: string): string =>
    `${ADMIN_ENDPOINT}/report/${incidentKey}/attachment`,
  GET_USER_SPECIAL_PERMISSIONS: (userId: string): string =>
    `${ADMIN_ENDPOINT}/users/${userId}/special-permissions`,
  GET_OWNERSHIPS: (userId: string): string =>
    `${ADMIN_ENDPOINT}/users/${userId}/ownerships`,
  GET_SPECIAL_PERMISSIONS: (): string =>
    `${ADMIN_ENDPOINT}/users/special-permissions`,
  UPDATE_USER_SPECIAL_PERMISSIONS: (userId: string): string =>
    `${ADMIN_ENDPOINT}/users/${userId}/special-permissions`,
  GET_CURRENT_ADMIN_SPECIAL_PERMISSIONS: (): string =>
    `${ADMIN_ENDPOINT}/users/special-permissions/user`,
  GET_EXPERTS: (): string => `${ADMIN_ENDPOINT}/experts`,
  GET_EXPERT_DNC_HISTORY: (expertId: string): string =>
    `${ADMIN_ENDPOINT}/experts/${expertId}/do-not-contact-histories`,
  GET_SURVEYS: (): string => `${ADMIN_ENDPOINT}/surveys/all`,
  GET_SURVEY_TRANSACTION_EXPERTS: (surveyId: string): string =>
    `${ADMIN_ENDPOINT}/survey/${surveyId}/transaction-experts`,
  GET_SURVEY_TRANSACTION_AMOUNTS: (surveyId: string): string =>
    `${ADMIN_ENDPOINT}/survey/${surveyId}/transaction-amounts`
};
