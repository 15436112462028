import { Flex, Tag } from "antd";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDAngleTag, AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  AngleModel,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";
import { DiscoverExpert } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import {
  ArbolusTooltipPlace,
  DNCTag
} from "@arbolus-technologies/ui/components";

import { WorkWithMeButton } from "../ResultsAndActions/WorkWithMeButton";
import { DiscoverExpertInfo } from "./DiscoverExpertInfo/DiscoverExpertInfo";
import { WorkHistoryList } from "./WorkHistoryList/WorkHistoryList";

import styles from "./ExpertDiscoverCard.module.scss";

interface ExpertDiscoverCardProps {
  expert: DiscoverExpert;
  projectId: string;
  projectName: string;
  angles: AngleModel[];
}

export const ExpertDiscoverCard: React.FC<ExpertDiscoverCardProps> = ({
  expert,
  projectId,
  projectName,
  angles
}) => {
  const { t } = useTranslation("discoverPage");

  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const industriesFilter = useSelector(
    ProjectNxSelector.discoverFilters()
  ).industries;

  const {
    isoCountryCode,
    fullName,
    profileImageUrl,
    stats,
    title,
    workHistories,
    lastPublicCompanyDate,
    id,
    projects,
    isRecommendedExpert,
    recommendedAngle,
    isOnboarded,
    doNotContactStatus,
    searchMatchColumns,
    recommendationMatchPercentage,
    customers,
    linkedInProfile,
    workHistoryLastUpdateDate
  } = expert;
  const previousProjects = projects.map(({ name }) => name);
  const expertCountry = metadataCountries.find(
    (metadataCountry) => metadataCountry.threeLetterCode === isoCountryCode
  );

  const isDNC = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isProceedWithCaution =
    doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION;
  const showCaution = isAdmin && isProceedWithCaution;

  return (
    <div
      className={clsx(styles.container, {
        [styles.isClient]: !isAdmin,
        [styles.dnc]: isDNC,
        [styles.caution]: showCaution
      })}
    >
      <DiscoverExpertInfo
        expertId={id}
        countryName={expertCountry?.name ?? null}
        countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
        fullName={fullName}
        stats={stats}
        title={title}
        profileImageUrl={profileImageUrl}
        lastPublicCompanyDate={lastPublicCompanyDate}
        projectId={projectId}
        projectName={projectName}
        previousProjects={previousProjects}
        isOnboarded={isOnboarded}
        searchMatchColumns={searchMatchColumns}
        doNotContactStatus={doNotContactStatus}
        recommendationMatchPercentage={recommendationMatchPercentage}
        isRecommendedExpert={isRecommendedExpert}
        customers={customers}
        linkedinUrl={linkedInProfile}
        workHistories={workHistories ?? []}
        industriesFilter={industriesFilter}
      />
      <div className={styles.workHistoriesContainer}>
        <WorkHistoryList
          workHistories={workHistories ?? []}
          workHistoryLastUpdateDate={workHistoryLastUpdateDate}
        />
        <div className={styles.statusAndButtonContainer}>
          <div className={styles.statusTagsContainer}>
            {isRecommendedExpert && isAdmin && recommendedAngle && (
              <Flex>
                <Tag
                  color="success"
                  icon={<AntDIcon name="award_star" />}
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  {t("recommendedFor")}
                </Tag>
                <AntDAngleTag angle={recommendedAngle} />
              </Flex>
            )}
            <DNCTag
              dncStatus={doNotContactStatus}
              placement={ArbolusTooltipPlace.LEFT}
              showTooltip
              isFullTag
              isAdmin={isAdmin}
            />
          </div>
          {!isAdmin && !isDNC && (
            <div className={styles.addToProjectContainer}>
              <WorkWithMeButton
                expertId={id}
                angles={angles}
                projectId={projectId}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
