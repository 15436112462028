export enum DatabaseChatType {
  Angle = "Angle",
  ClientSupport = "ClientSupport",
  ExpertSupport = "ExpertSupport",
  Workspace = "Workspace"
}

export enum ChatUserAPIRole {
  Expert = "Expert",
  Admin = "ArbolusAdmin",
  Client = "Client"
}

// Used as translation keys
export enum ChatUserRole {
  Expert = "expertChatRole",
  Admin = "adminChatRole",
  Client = "clientChatRole"
}

export enum MessageType {
  Incoming,
  Outgoing
}

export enum ExpertAvailabilityActions {
  ArrangeCall = "ArrangeCall",
  RequestTimeSlots = "RequestTimeSlots"
}

export enum DiscoverFilterOperator {
  Include = "include",
  Exclude = "exclude"
}

export enum DiscoverFilterType {
  Expert_Locations = "expertLocations",
  Work_History_Locations = "workHistoryLocations",
  Company = "company",
  Keywords = "keywords",
  Projects = "projects",
  Customers = "customers",
  Industries = "industries"
}

export enum FilterTimeSelectorOptions {
  All = "all",
  Current = "current",
  PastNotCurrent = "pastNotCurrent"
}

export enum FindExpertStatusEnum {
  OK = "Ok",
  RESULTS_NOT_FOUND = "ResultsNotFound",
  EXPERT_RECOMMENDATIONS_NOT_GENERATED = "ExpertRecommendationsNotGenerated"
}

export enum KEYWORDS_SEARCH_OPTIONS {
  USER_TITLE = "user.title",
  WORK_HISTORIES_TITLE = "workHistories.title",
  WORK_HISTORIES_COMPANY = "workHistories.companyName",
  PROJECTS_NOTES = "projects.notes",
  PROJECTS_ANSWERS = "projects.answers"
}

export enum FILTERS_SEARCH_MATCHES {
  FILTER_SEARCH_TERM = "filter.searchTerm",
  FILTER_PROJECTS = "filter.projects",
  FILTER_COMPANIES = "filter.companies",
  FILTER_WORK_HISTORY_LOCATIONS = "filter.workHistoryLocations",
  FILTER_EXPERT_CURRENT_LOCATIONS = "filter.expertCurrentLocations",
  FILTER_USER_TITLE = "filter.keywords.user.title",
  FILTER_WORK_HISTORIES_TITLE = "filter.keywords.workHistories.title",
  FILTER_WORK_HISTORIES_COMPANY = "filter.keywords.workHistories.companyName",
  FILTER_PROJECTS_NOTES = "filter.keywords.projects.notes",
  FILTER_PROJECTS_ANSWERS = "filter.keywords.projects.answers",
  FILTER_CUSTOMERS = "filter.customers",
  FILTER_INDUSTRIES = "filter.industries"
}

export enum EVENT_FORM {
  TITLE = "title",
  STARTING_DATE = "startingDate",
  ENDING_DATE = "endingDate",
  STARTING_TIME = "startingTime",
  ENDING_TIME = "endingTime",
  TIMEZONE = "timezone",
  GUESTS = "guests",
  NOTES = "notes",
  FREE_TRANSCRIPT = "freeTranscript",
  SMART_TRANSCRIPT = "smartTranscript",
  ATTACHMENTS = "attachments"
}

export enum PERSONA {
  Decision_Maker = "Influencer",
  Other = "Other",
  User = "User"
}

export enum SPEND {
  Small = "Small",
  Medium = "Medium",
  Large = "Large"
}

export enum ADOPTION_STAGE {
  Pilot = "Pilot",
  MoreThan1Year = "MoreThan1Year",
  LessThan1Year = "LessThan1Year",
  Offboarding = "Offboarding"
}

export enum NPS {
  Promoters = "Promoters",
  Passives = "Passives",
  Detractors = "Detractors"
}

export enum RENEWAL_INTENT {
  Yes = "Yes",
  No = "No",
  Unsure = "NotSure"
}
