import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ErrorResponse,
  ExpertService
} from "@arbolus-technologies/api";

export function useRequestEndorsement(userId: string) {
  const { t } = useTranslation("endorsements");
  const [inProgress, setInProgress] = useState(false);

  function requestEndorsement(name: string, email: string) {
    setInProgress(true);

    ExpertService.requestEndorsement([{ name, email }], userId).subscribe(
      () => {
        setInProgress(false);
        DefaultToasterService.showSuccess(t("requestSuccess", { name }));
      },
      (error: ErrorResponse) => {
        setInProgress(false);
        DefaultToasterService.showError(error?.message ?? t("requestError"));
      }
    );
  }

  return {
    requestEndorsement,
    inProgress
  };
}
