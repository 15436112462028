import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import {
  AuthPageBase,
  AuthPageHeader
} from "@arbolus-technologies/features/common";
import {
  PhoneValue,
  SimplePhoneInput
} from "@arbolus-technologies/ui/components";

import styles from "./CreateAccountWithLinkedIn.module.scss";

export const CreateAccountWithLinkedIn: React.FC<RouteComponentProps> = (
  props
) => {
  const { t } = useTranslation();
  const params = Object.fromEntries(new URLSearchParams(props.location.search));
  const [form] = Form.useForm();

  function handleSubmit() {
    form.validateFields().then((values) => {
      const { phoneNumber, ...rest } = values;
      // TODO: make PUT request
      // TODO: ignore linkedinUrl change that a user can do in query params
    });
  }

  return (
    <AuthPageBase>
      <div className={styles.createAccount}>
        <AuthPageHeader title={t("register:details")} />

        <Form
          form={form}
          initialValues={{
            ...params,
            phoneNumber: { prefix: "+1", number: "1234567890" },
            linkedinUrl: "www.linkedin.com/in/expert-full-name"
          }}
        >
          <Row gutter={16}>
            <Col>
              <Form.Item<{ firstName: string }>
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t("register:firstNameRequiredError")
                  }
                ]}
              >
                <Input placeholder={t("register:firstName")} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item<{ lastName: string }>
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t("register:lastNameRequiredError")
                  }
                ]}
              >
                <Input placeholder={t("register:lastName")} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item<{ email: string }>
            name="email"
            rules={[
              { type: "email", message: t("register:emailInvalidError") },
              { required: true, message: t("register:emailRequiredError") }
            ]}
          >
            <Input placeholder={t("register:email")} />
          </Form.Item>

          <Form.Item<{ linkedinUrl: string }>
            name="linkedinUrl"
            rules={[
              { required: true, message: t("register:linkedinUrlRequired") }
            ]}
          >
            <Input addonBefore="http://" disabled />
          </Form.Item>
          <Form.Item<{ phoneNumber: PhoneValue }>
            name="phoneNumber"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value?.number) {
                    return Promise.reject(
                      t("register:phoneNumberInvalidError")
                    );
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <SimplePhoneInput
              value={form.getFieldValue("phoneNumber")}
              onChange={(value) => form.setFieldValue("phoneNumber", value)}
            />
          </Form.Item>
        </Form>

        <Form.Item>
          <Button type="primary" onClick={handleSubmit}>
            {t("register:createAccount")}
          </Button>
        </Form.Item>
      </div>
    </AuthPageBase>
  );
};
