import { useTranslation } from "react-i18next";
import styles from "./DefaultBanner.module.scss";
import screenshots from "./screenshots.webp";
import trustpilotScore from "./trustpilot-score.webp";

export const DefaultBanner = () => {
  const { t } = useTranslation("authPageBase");

  return (
    <div className={styles.bannerContent}>
      <span className={styles.header}>{t("slogan")}</span>
      <img src={screenshots} className={styles.screenshots} alt="" />
      <div className={styles.trustpilotScore}>
        <img src={trustpilotScore} alt="TrustScore 4.6 - 868 reviews" />
      </div>
    </div>
  );
};
