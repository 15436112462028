import { Epic, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, takeUntil } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  CIQError,
  EXPERT_ENDPOINT,
  ErrorResponse
} from "@arbolus-technologies/api";

import { EXIT_FROM_EXPERT, GET_EXPERT } from "../actions/ExpertActionTypes";
import { ExpertStoreActions } from "../actions/ExpertActions";
import {
  ExpertNxAppState,
  ExpertStoreAction,
  ExpertStoreDependencies
} from "../models/definitions";

const getExpert: Epic<
  ExpertStoreAction,
  ExpertStoreAction,
  ExpertNxAppState,
  ExpertStoreDependencies
> = (action$, _, { expertService, history }) =>
  action$.pipe(
    filter(isOfType(GET_EXPERT)),
    switchMap(({ payload: { expertId } }) =>
      expertService.getExpertById({ expertId, includeRateCard: true }).pipe(
        map((expert) => ExpertStoreActions.getExpertSuccess(expert.id, expert)),
        takeUntil(action$.pipe(ofType(EXIT_FROM_EXPERT))),
        catchError((error: ErrorResponse<CIQError>) => {
          history.replace(EXPERT_ENDPOINT);
          return of(ExpertStoreActions.getExpertFailure(error));
        })
      )
    )
  );

export const ExpertEpics = { getExpert };
