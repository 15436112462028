import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { switchMap } from "rxjs/operators";

import {
  CIQError,
  CanopyQuestionForExpert,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  EXPERT_CANOPY_STATUS,
  QuestionParamUrlTypes
} from "@arbolus-technologies/models/canopy";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyExpertSelector,
  CanopyExpertStoreActions
} from "@arbolus-technologies/stores/canopy-expert";
import { HR, Loader } from "@arbolus-technologies/ui/components";

import {
  MultiFormData,
  NpsForExpertProvider
} from "./NpsForExpertProvider/NpsForExpertProvider";

import styles from "./NpsForExpert.module.scss";

interface NpsForExpertProps {
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}
export const NpsForExpert: React.FC<NpsForExpertProps> = ({
  notificationService = DefaultToasterService,
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyExpert");
  const dispatch = useDispatch();

  const [canopyQuestionData, setCanopyQuestionData] =
    useState<CanopyQuestionForExpert>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const expertId = useSelector(CacheSelector.loggedInUserExpertId());
  const expertCanopy = useSelector(CanopyExpertSelector.canopySelector());
  const expertQuestionList = useSelector(
    CanopyExpertSelector.questionsSelector()
  );
  const loadingQuestions = useSelector(
    CanopyExpertSelector.canopyListLoadingSelector()
  );

  const { questionId, canopyId } = useParams<QuestionParamUrlTypes>();

  const selectedQuestion = expertQuestionList.find(
    (question) => question.id === questionId
  );

  const { canopyExpertStatus } = expertCanopy;
  const isCanopyCompleted =
    canopyExpertStatus === EXPERT_CANOPY_STATUS.COMPLETE ||
    canopyExpertStatus === EXPERT_CANOPY_STATUS.REJECTED;

  const getCanopyQuestion = useCallback(() => {
    setIsLoading(true);
    canopyService.getCanopyQuestionForExpert(questionId).subscribe(
      (canopyQuestionData) => {
        setCanopyQuestionData(canopyQuestionData);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    );
  }, [canopyService, notificationService, questionId]);

  const getCanopyAnswer = useCallback(() => {
    setIsLoading(true);
    canopyService.getCanopyAnswerForExpert(questionId, expertId!).subscribe(
      (canopyQuestionData) => {
        setCanopyQuestionData(canopyQuestionData);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    );
  }, [canopyService, questionId, expertId, notificationService]);

  const postCanopyAnswer = (data: MultiFormData) => {
    setIsSaving(true);

    canopyService
      .postCanopyAnswerNpsForExpert(questionId, {
        npsValue: data.npsValue
      })
      .subscribe(
        () => {
          notificationService.showSuccess(t("success"));
          dispatch(
            CanopyExpertStoreActions.getCanopyExpert(canopyId, expertId!)
          );
          dispatch(CanopyExpertStoreActions.markQuestionAsAnswered(questionId));
          setIsSaving(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsSaving(false);
          notificationService.showApiErrors(error);
        }
      );
  };

  const putCanopyAnswer = (data: MultiFormData) => {
    setIsSaving(true);

    canopyService
      .deleteCanopyAnswer(canopyQuestionData?.id!)
      .pipe(
        switchMap(() =>
          canopyService.postCanopyAnswerNpsForExpert(questionId, {
            npsValue: data.npsValue
          })
        )
      )
      .subscribe(
        () => {
          notificationService.showSuccess(t("updateSuccess"));
          dispatch(
            CanopyExpertStoreActions.getCanopyExpert(canopyId, expertId!)
          );
          setIsSaving(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsSaving(false);
          notificationService.showApiErrors(error);
        }
      );
  };

  useEffect(() => {
    if (!loadingQuestions) {
      if (selectedQuestion?.isAnswered) {
        getCanopyAnswer();
      } else {
        getCanopyQuestion();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCanopyAnswer, getCanopyQuestion, loadingQuestions]);

  return (
    <div className={styles.container}>
      {isLoading && <Loader />}
      {canopyQuestionData && !isLoading && (
        <>
          <div className={styles.header}>
            <span className={styles.title}>
              {canopyQuestionData.text || canopyQuestionData.questionText}
            </span>
            <span className={styles.subtitle}>
              {canopyQuestionData.details}
            </span>
          </div>
          <HR margin={{ top: 2, bottom: 2 }} />
          <NpsForExpertProvider
            isSaving={isSaving}
            canopyIsCompleted={isCanopyCompleted}
            npsValue={canopyQuestionData.npsValue}
            isAnswered={selectedQuestion?.isAnswered}
            handleOnSubmit={
              selectedQuestion?.isAnswered ? putCanopyAnswer : postCanopyAnswer
            }
          />
        </>
      )}
    </div>
  );
};
