import { Steps, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { useIsMobile } from "@arbolus-technologies/utils";

import styles from "./TrainingTitle.module.scss";

export function TrainingTitle({
  title,
  current,
  total,
  pending
}: { title: string; current: number; total: number; pending: number }) {
  const { t } = useTranslation("expertTrainingModal");
  const isMobile = useIsMobile();

  return (
    <div className={styles.titleWrapper}>
      <DangerouslySetInnerHTML
        text={t("disclaimer", { pending })}
        fontSize="12px"
        color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
      />
      <Stepper current={current} total={total} />
      <Typography.Title level={2} className={styles.header}>
        {title}
      </Typography.Title>
    </div>
  );
}

function Stepper({ current, total }: { current: number; total: number }) {
  const { t } = useTranslation("expertTrainingModal");
  const isMobile = useIsMobile();

  return (
    <Steps
      current={current}
      className={styles.stepper}
      items={Array.from({ length: total }, (_, index) => ({
        title: isMobile
          ? null
          : index >= current
            ? t("inProgress")
            : t("finished")
      }))}
      size="small"
      responsive={false}
    />
  );
}
