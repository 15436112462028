import { Button } from "arbolus-ui-components";
import React from "react";

interface AsyncAwareButtonProps {
  isLoading: boolean;
  text: string;
  onClick?: () => void;
}

export const AsyncAwareSubmitButton: React.FC<AsyncAwareButtonProps> = ({
  isLoading,
  text
}) => <Button disabled={isLoading} nativeType="submit" text={text} />;
