import { call_delete, call_get, call_post } from "../SimpleRestService";
import { INSIGHTS_API } from "../endpoints/insights";
import { getMultiSortParams } from "../helpers";
import type { ApiPaginatedResponse } from "../models/api";
import type {
  MonitoringListAddCompaniesRequest,
  MonitoringListModel,
  MonitoringListRequest
} from "../models/monitoringList";

export function getCompanySignals(queryParams: MonitoringListRequest) {
  return call_get<ApiPaginatedResponse<MonitoringListModel>>(
    INSIGHTS_API.COMPANY_SIGNALS,
    getMultiSortParams(queryParams)
  );
}

export function addCompanies(payload: MonitoringListAddCompaniesRequest) {
  return call_post<MonitoringListAddCompaniesRequest>(
    INSIGHTS_API.COMPANY_SIGNALS,
    payload
  );
}

export function deleteCompanySignal(id: string) {
  return call_delete(INSIGHTS_API.COMPANY_SIGNAL(id));
}
