import { Card, Col, Divider, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import {
  ExpertDetail,
  ReferralDetail,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { AvatarExpertStatusIconInfo } from "./AvatarExpertStatusIconInfo";
import { HeaderActions } from "./HeaderActions";
import { Statuses } from "./Statuses";

interface ExpertInfoProps {
  expert: ExpertDetail;
  referral: ReferralDetail;
  onAddNote: () => void;
  onReferralRemoval: (referralId: string) => void;
  onRejectExpert: () => void;
  updateReferralState: (
    state: UpdateReferralRequest,
    isFastTrack?: boolean
  ) => void;
  onMoveToAvailabilityTab: () => void;
}

export const ExpertInfo: React.FC<ExpertInfoProps> = ({
  expert,
  referral,
  onAddNote,
  onReferralRemoval,
  onRejectExpert,
  updateReferralState,
  onMoveToAvailabilityTab
}) => {
  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const expertCountry = metadataCountries.find(
    (country) => country.threeLetterCode === expert.user.isoCountryCode
  );

  return (
    <Card>
      <Row gutter={24}>
        <Col span={18}>
          <AntDAvatar
            firstName={referral.expert.firstName}
            lastName={referral.expert.lastName}
            profileImageUrl={referral.expert.profileImageUrl}
            size={64}
            title={referral.tagline ?? referral.expert.title}
            isVerified={expert.isVerified}
            lastPublicCompanyExpDate={expert.lastPublicCompanyExpDate}
            linkedInProfile={expert.linkedinProfile}
            countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
            countryName={expertCountry?.name ?? ""}
            angle={referral.angle}
            icon={
              <AvatarExpertStatusIconInfo referralStatus={referral.status} />
            }
            mainGap={16}
          />
        </Col>
        <Col span={6}>
          <HeaderActions
            expert={expert}
            referral={referral}
            onAddNote={onAddNote}
            onReferralRemoval={onReferralRemoval}
            onRejectExpert={onRejectExpert}
            updateReferralState={updateReferralState}
            onMoveToAvailabilityTab={onMoveToAvailabilityTab}
          />
        </Col>
      </Row>
      <Divider />
      <Statuses
        applicationStatus={referral.application.subStatus}
        complianceStatus={referral.compliance.subStatus}
        referralStatus={referral.status}
        reviewStatus={referral.review.subStatus}
        fastTrack={referral.fastTrack.fastTrack}
        complianceRequired={referral.complianceRequired}
        doNotContactStatus={expert.doNotContactStatus}
        onRejectExpert={onRejectExpert}
        updateReferralState={updateReferralState}
      />
    </Card>
  );
};
