import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { OptionChoiceAnswer } from "@arbolus-technologies/models/canopy-panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { NumberCircle } from "@arbolus-technologies/ui/components";
import {
  CANOPY_DATE_TIME_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { OptionCheckbox } from "../../OptionCheckbox/OptionCheckbox";
import { AnswerQuestion } from "../types";

import styles from "./AnswerMultiChoice.module.scss";

interface AnswerMultiChoiceProps {
  answer: {
    id: string;
    created: string;
  };
  expertId: string;
  question: AnswerQuestion;
  handleShareLink: (answerId: string, expertId: string) => void;
  redirectToQuestion: (questionId: string) => void;
  optionChoices: OptionChoiceAnswer[];
}

export const AnswerMultiChoice: React.FC<AnswerMultiChoiceProps> = ({
  answer,
  expertId,
  question,
  handleShareLink,
  redirectToQuestion,
  optionChoices
}) => {
  const { t } = useTranslation("answer");

  return (
    <div className={styles.container}>
      <div className={styles.numberAndContent}>
        <div className={styles.numberColumn}>
          <NumberCircle
            number={question.sortOrder ? question.sortOrder + 1 : 1}
            classnames={styles.number}
          />
          <Icon
            name="sort"
            color={ARBOLUS_COLORS.bColorGrayIcon}
            fontSize="24px"
            tooltip={t("multiChoice")}
          />
        </div>
        <div className={styles.contentColumn}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <h1
                className={styles.title}
                onClick={() => redirectToQuestion(question.id)}
              >
                {question.text}
              </h1>
              <div className={styles.iconsContainer}>
                <Icon
                  name="share"
                  color={ARBOLUS_COLORS.bColorGrayIcon}
                  fontSize="24px"
                  onClick={() => handleShareLink(answer.id, expertId)}
                  tooltip={t("share")}
                />
              </div>
            </div>

            <h2 className={styles.subtitle}>{question.details}</h2>
          </div>
          <div className={styles.optionsList}>
            {optionChoices.map((option) => (
              <OptionCheckbox key={option.id} option={option} />
            ))}
          </div>
          <div className={styles.footer}>
            <p className={styles.responseDate}>
              {t("responseDate", {
                date: formatDate(answer.created, CANOPY_DATE_TIME_FORMAT)
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
