import {
  Eventcalendar,
  MbscCalendarEvent,
  MbscCellClickEvent,
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscEventDragEvent,
  MbscEventUpdateEvent,
  momentTimezone,
  setOptions,
  toast
} from "@mobiscroll/react";
import clsx from "clsx";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { Slot } from "@arbolus-technologies/api";
import { SelectOption } from "@arbolus-technologies/models/common";
import { EVENT_PANEL_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  CALENDAR_DATE_TIME_FORMAT,
  EVENT_DATE_TIME_FORMAT,
  TimezoneService
} from "@arbolus-technologies/utils";

import {
  AvailabilitySlidePanelForm,
  SelectedEventDetails
} from "./AvailabilitySlidePanelForm/AvailabilitySlidePanelForm";
import { ExpertAvailabilityPanelBase } from "./AvailabilitySlidePanelForm/types";
import { toastDuration } from "./helpers/constants";
import { useSchedulerSettings } from "./helpers/useSchedulerSettings";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import styles from "./Availability.module.scss";

// setup Mobiscroll Timezone plugin with Moment
momentTimezone.moment = moment;

setOptions({
  theme: "ios",
  themeVariant: "light"
});

interface AvailabilityProps {
  projectTimezone: string;
  expertTimezone: string;
  expertAvailabilitySlotsSelected: Slot[];
  timezones: Map<string, SelectOption>;
  hasAdditionalTimeSlotsRequested?: boolean;
  availabilityHeader?: () => JSX.Element;
  onUpdateAvailabilityDetails?: (
    expertAvailabilitySlots: MbscCalendarEvent[]
  ) => void;
}

export const Availability: React.FC<AvailabilityProps> = ({
  projectTimezone,
  expertAvailabilitySlotsSelected,
  timezones,
  hasAdditionalTimeSlotsRequested,
  expertTimezone,
  availabilityHeader,
  onUpdateAvailabilityDetails
}) => {
  const { t } = useTranslation("expertAvailabilityScheduler");
  const dispatch = useDispatch();
  const history = useHistory();

  const slidePanelId = PanelId.ExpertAvailability;
  const [selectedSlots, setSelectedSlots] = useState<MbscCalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] =
    useState<SelectedEventDetails | null>(null);
  const [newEventDetails, setNewEventDetails] = useState(
    {} as { start: string; end: string }
  );

  const systemTimezone = useSelector(
    CacheSelector.appGuessCurrentTimeZoneSelector()
  );

  const { minDate, maxDate, viewSettings, invalidDates, colorSettings } =
    useSchedulerSettings(expertTimezone, projectTimezone, timezones);

  useEffect(() => {
    const savedSlots = expertAvailabilitySlotsSelected
      .filter((slot) => !slot.isExpired)
      .map((slot, idx) => ({
        id: idx + 1,
        start: slot.startTime,
        end: slot.endTime,
        color: slot.isScheduled
          ? ARBOLUS_COLORS.bColorBaseWhite
          : ARBOLUS_COLORS.bColorBasePurple,
        isScheduled: slot.isScheduled,
        eventId: slot.eventId,
        cssClass: clsx(
          hasAdditionalTimeSlotsRequested ? styles.disabled : "",
          slot.isScheduled ? styles.scheduledSlot : ""
        )
      }));

    setSelectedSlots(savedSlots);
  }, [expertAvailabilitySlotsSelected, hasAdditionalTimeSlotsRequested]);

  useEffect(() => {
    onUpdateAvailabilityDetails?.(selectedSlots);
  }, [selectedSlots, expertTimezone, onUpdateAvailabilityDetails]);

  useEffect(() => {
    if (selectedEvent) {
      dispatch(PanelStoreActions.openPanel(slidePanelId));
    }
  }, [dispatch, selectedEvent, slidePanelId]);

  const hasOverlap = useCallback(
    (
      args: MbscEventUpdateEvent | MbscEventCreatedEvent | MbscEventDragEvent,
      inst: Eventcalendar
    ) => {
      const newEvent = args.event;
      const events = inst
        .getEvents(newEvent.start, newEvent.end)
        .filter((e: MbscCalendarEvent) => e.id !== newEvent.id);

      return events.length > 0;
    },
    []
  );

  const handleCreateEvent = (
    args: MbscEventCreatedEvent | MbscEventDragEvent,
    inst: Eventcalendar
  ) => {
    const { id, start, end } = args.event;
    const overlapEvents = inst.getEvents(start, end);
    const events = overlapEvents.filter((e: MbscCalendarEvent) => e.id !== id);

    //if there are overlap events, the return false is using, so we don't need to do the next checks
    if (overlapEvents.length > 1) {
      return false;
    }

    if (events.length > 0) {
      const diffMin = moment(overlapEvents[0].end).diff(
        overlapEvents[0].start,
        "minutes"
      );

      if (diffMin < 60) {
        const overlapEventStartMinute = moment(overlapEvents[0].start)
          .tz(expertTimezone)
          .format("mm");
        const startsAtFullHour = overlapEventStartMinute === "00";

        const startNewEventTime = startsAtFullHour
          ? moment(overlapEvents[0].end)
          : moment(start);

        const endNewEventTime = moment(
          startsAtFullHour ? overlapEvents[0].end : start
        ).add(30, "minutes");

        setNewEventDetails({
          start: startNewEventTime.toISOString(),
          end: endNewEventTime.toISOString()
        });
        return true;
      }
    }

    if (isMobile) {
      const differenceMin = moment(end).diff(start, "minutes");
      const moreThanOneHour = differenceMin > 60;

      if (moreThanOneHour) {
        toast({
          message: t("warningMaxTime"),
          duration: toastDuration
        });
        return false;
      }
    }

    if (hasOverlap(args, inst)) {
      toast({
        message: t("overlapWarning"),
        duration: toastDuration
      });
      return false;
    }

    setNewEventDetails({
      start: moment(start).toISOString(),
      end: moment(end).toISOString()
    });
  };

  const handleCreatedEvent = (
    args: MbscEventCreatedEvent | MbscEventDragEvent
  ) => {
    setSelectedSlots((prevSelectedSlots) => [
      ...prevSelectedSlots,
      {
        id: prevSelectedSlots.length + 1,
        start: newEventDetails.start,
        end: newEventDetails.end,
        color: ARBOLUS_COLORS.bColorBasePurple,
        mobiScrollId: args.event.id
      }
    ]);
  };

  const handleUpdateEvent = (
    args: MbscEventUpdateEvent,
    inst: Eventcalendar
  ) => {
    const { id, start, end, isScheduled } = args.event;
    // handle mobile long press slot creation scenarios
    if (isMobile) {
      if (selectedSlots.filter((s) => s.id === id).length === 0) {
        const events = inst
          .getEvents(start, end)
          .filter(
            (e: MbscCalendarEvent) => e.id !== id && e.mobiScrollId !== id
          );

        if (events.length > 0) {
          toast({
            message: t("overlapWarning"),
            duration: toastDuration
          });
          return false;
        } else {
          setSelectedSlots(
            selectedSlots.map((s) => {
              if (s.mobiScrollId === id) {
                return {
                  ...s,
                  start: moment(start).toISOString(),
                  end: moment(end).toISOString()
                };
              }
              return s;
            })
          );
        }
        return true;
      }
    }

    if (isScheduled) {
      toast({
        message: t("callScheduledWarning"),
        duration: toastDuration
      });
      return false;
    }

    const differenceMin = moment(end).diff(start, "minutes");
    const moreThanOneHour = differenceMin > 60;

    if (moreThanOneHour) {
      toast({
        message: t("warningMaxTime"),
        duration: toastDuration
      });
      return false;
    }

    if (hasOverlap(args, inst)) {
      toast({
        message: t("overlapWarning"),
        duration: toastDuration
      });
      return false;
    }

    return true;
  };

  const handleCellClick = (args: MbscCellClickEvent, inst: Eventcalendar) => {
    const startTime = moment(
      moment(args.date).format(EVENT_DATE_TIME_FORMAT)
    ).tz(expertTimezone, true);

    const endTime = moment(
      moment(args.date).add(1, "hour").format(EVENT_DATE_TIME_FORMAT)
    ).tz(expertTimezone, true);

    const isInInvalidDateRange = startTime.isBetween(
      moment(new Date(invalidDates[0].start as Date)),
      moment(new Date(invalidDates[0].end as Date))
    );

    if (isInInvalidDateRange) {
      return false;
    }

    const overlapEvents = inst.getEvents(startTime, endTime);

    const start = startTime;
    const end = endTime;
    if (overlapEvents.length > 0) {
      const startTimeDifference = moment(overlapEvents[0].start).diff(
        startTime,
        "minutes"
      );

      const endTimeDifference = moment(overlapEvents[0].end).diff(
        endTime,
        "minutes"
      );

      if (startTimeDifference === 0 && endTimeDifference === 0) {
        toast({
          message: t("overlapWarning"),
          duration: toastDuration
        });
        return false;
      }

      if (startTimeDifference > 0) {
        end.subtract(30, "minutes");
      } else if (endTimeDifference < 0) {
        start.add(30, "minutes");
      }
    }

    setSelectedSlots((prevSelectedSlots) => [
      ...prevSelectedSlots,
      {
        id: prevSelectedSlots.length + 1,
        start: start.toISOString(),
        end: end.toISOString(),
        color: ARBOLUS_COLORS.bColorBasePurple,
        mobiScrollId: prevSelectedSlots.length + 1
      }
    ]);
  };

  const onEventClick = (args: MbscEventClickEvent, inst: Eventcalendar) => {
    const { id, start, end, isScheduled, eventId } = args.event;
    if (isScheduled) {
      history.replace(EVENT_PANEL_ROUTE(eventId), {
        isSchedulerScreen: true
      });
    } else {
      setSelectedEvent({
        id: id as number,
        date: new Date(
          moment(start)
            .tz(expertTimezone)
            .format(CALENDAR_DATE_TIME_FORMAT)
            .replace(" ", "T")
        ),
        startTime: new Date(
          moment(start)
            .tz(expertTimezone)
            .format(CALENDAR_DATE_TIME_FORMAT)
            .replace(" ", "T")
        ),
        endTime: new Date(
          moment(end)
            .tz(expertTimezone)
            .format(CALENDAR_DATE_TIME_FORMAT)
            .replace(" ", "T")
        ),
        inst
      });
    }
  };

  const handleUpdateEventDetails = (
    data: ExpertAvailabilityPanelBase
  ): void => {
    const { startTime, endTime } = data;
    const filteredSlots = selectedSlots.filter((slot) => slot.id !== data.id);

    const { start, end } = TimezoneService.convertDatesTimezone(
      `${moment(startTime).format(CALENDAR_DATE_TIME_FORMAT)}`,
      `${moment(endTime).format(CALENDAR_DATE_TIME_FORMAT)}`,
      systemTimezone.value,
      expertTimezone
    );

    const updatedSlots = [
      ...filteredSlots,
      {
        id: data.id,
        start: start.toDate(),
        end: end.toDate(),
        color: ARBOLUS_COLORS.bColorBasePurple
      }
    ];
    setSelectedSlots(updatedSlots);
    setSelectedEvent(null);
    dispatch(PanelStoreActions.closePanel(slidePanelId));
  };

  const handleDeleteEvent = (eventId: number): void => {
    const filteredSlots = selectedSlots.filter((slot) => slot.id !== eventId);
    const updatedSlots = [...filteredSlots];
    setSelectedSlots(updatedSlots);
    setSelectedEvent(null);
    dispatch(PanelStoreActions.closePanel(slidePanelId));
  };

  const handleClosePanel = () => {
    setSelectedEvent(null);
    dispatch(PanelStoreActions.closePanel(slidePanelId));
  };

  return (
    <>
      <div className={styles.availabilityContainer}>
        <Eventcalendar
          data={selectedSlots}
          view={viewSettings}
          colors={colorSettings}
          min={minDate}
          max={maxDate}
          clickToCreate={isMobile ? "double" : "single"}
          dragToCreate={isMobile}
          dragToMove={true}
          dragToResize={true}
          eventDelete
          displayTimezone={expertTimezone}
          onEventCreate={handleCreateEvent}
          onEventCreated={handleCreatedEvent}
          onEventDragEnd={handleUpdateEvent}
          onEventUpdate={handleUpdateEvent}
          onCellClick={isMobile ? handleCellClick : undefined}
          onEventClick={onEventClick}
          timezonePlugin={momentTimezone}
          dragTimeStep={30}
          renderHeader={availabilityHeader}
          invalid={invalidDates}
        />
      </div>
      {selectedEvent && (
        <AvailabilitySlidePanelForm
          slidePanelId={slidePanelId}
          projectTimezone={projectTimezone}
          expertTimezone={expertTimezone}
          timezones={timezones}
          selectedEvent={selectedEvent}
          onUpdateEvent={handleUpdateEventDetails}
          onDeleteEvent={handleDeleteEvent}
          onClosePanel={handleClosePanel}
        />
      )}
    </>
  );
};
