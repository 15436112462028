import { Button, Flex } from "antd";
import { replace } from "connected-react-router";
import { useFeature } from "flagged";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import SimpleBar from "simplebar-react";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ExpertDetail, ExpertService } from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  ProjectNotes,
  ReportExpertButton,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  DO_NOT_CONTACT_STATUS,
  FEATURE_FLAGS
} from "@arbolus-technologies/models/common";
import {
  DASHBOARD,
  EXPERT_NETWORK,
  EXPERT_PROFILE_EDIT_ROUTE,
  EXPERT_TABLE,
  PROJECT_DISCOVER_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { DncInfoBox, Loader, Tabs } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { displayUserName } from "@arbolus-technologies/utils";

import { ExpertHeader } from "../../Components/ExpertHeader/ExpertHeader";
import { InternalComments } from "../../Modules/InternalComments/InternalComments";
import { PaymentsHistory } from "../../Modules/PaymentsHistory/PaymentsHistory";
import { Profile } from "../../Modules/Profile/Profile";
import { Projects } from "../../Modules/Projects/Projects";
import { Screening } from "../../Modules/Screening/Screening";
import { EXPERT_PROFILE_TABS } from "../../helpers/types";

import styles from "./ExpertContainer.module.scss";

const {
  PROFILE,
  PROJECT,
  INTERNAL_COMMENTS,
  PROJECT_NOTES,
  SCREENING,
  PAYMENTS
} = EXPERT_PROFILE_TABS;

interface ExpertContainerProps {
  expertService?: typeof ExpertService;
}

export const ExpertContainer: React.FC<ExpertContainerProps> = ({
  expertService = ExpertService
}) => {
  const { t } = useTranslation("expertContainer");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trackClick } = useArbolusTracking();
  const tipaltiFeatureFlag = useFeature(FEATURE_FLAGS.Tipalti);
  const { expertId } = useParams<{ expertId: string }>();

  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("projectId");
  const defaultTabParam = queryParams.get(
    "defaultTab"
  ) as EXPERT_PROFILE_TABS | null;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expertDetails, setExpertDetails] = useState<ExpertDetail>();
  const [defaultTab, setDefaultTab] = useState<EXPERT_PROFILE_TABS>(
    EXPERT_PROFILE_TABS.PROFILE
  );

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const clientId = useSelector(CacheSelector.loggedInUserClientId());

  const handleNavigateBack = useCallback(() => {
    // Redirection from project find experts page
    if (projectId) {
      history.push(PROJECT_DISCOVER_ROUTE(projectId));
    } else if (history.length > 2) {
      history.goBack();
    } else {
      isAdmin && history.push(EXPERT_TABLE);
    }
  }, [history, isAdmin, projectId]);

  const fetchExpertProfile = (): void => {
    setIsLoading(true);

    expertService
      .getExpertById({
        expertId,
        clientId,
        includeRateCard: isAdmin
      })
      .subscribe(
        (expert) => {
          setExpertDetails(expert);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
          history.replace(DASHBOARD);
        }
      );
  };

  const handleProjectEditClicked = (): void => {
    dispatch(replace(EXPERT_PROFILE_EDIT_ROUTE(expertId)));
  };

  const handleOpenSlidePanel = (): void => {
    trackClick(MixPanelEventNames.OpenAssignToProject);
    dispatch(
      PanelStoreActions.openPanel(PanelId.AssignExpertToProject, [{ expertId }])
    );
  };

  useEffect(() => {
    fetchExpertProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (expertDetails) {
      document.title = `${t("expert")} | ${displayUserName(
        expertDetails.user
      )}`;
    }
  }, [expertDetails, t]);

  useEffect(() => {
    if (defaultTabParam) {
      setDefaultTab(defaultTabParam);
    }
  }, [defaultTabParam]);

  const TabMapping = useMemo(() => {
    const AdminTabMapping = {
      [PROFILE]: {
        title: t("profile"),
        component: () => (
          <Profile
            expertDetails={expertDetails}
            onNavigateBack={handleNavigateBack}
            handleLoading={(loading: boolean) => setIsLoading(loading)}
          />
        )
      },
      [PROJECT]: {
        title: t("projects"),
        component: () => <Projects expertId={expertId} />
      },
      [INTERNAL_COMMENTS]: {
        title: t("internalComments"),
        component: () => <InternalComments expertId={expertId} />
      },
      [PROJECT_NOTES]: {
        title: t("projectNotes"),
        component: () => <ProjectNotes expertId={expertId} />
      },
      [SCREENING]: {
        title: t("screening"),
        component: () => <Screening expertId={expertId} />
      }
    };

    if (tipaltiFeatureFlag) {
      // @ts-ignore
      AdminTabMapping[PAYMENTS] = {
        title: t("payments"),
        component: () => <PaymentsHistory expertId={expertId} />
      };
    }

    const ClientTabMapping = {
      [PROFILE]: {
        title: t("profile"),
        component: () => (
          <Profile
            expertDetails={expertDetails}
            onNavigateBack={handleNavigateBack}
            handleLoading={(loading: boolean) => setIsLoading(loading)}
          />
        )
      }
    };

    return isAdmin ? AdminTabMapping : ClientTabMapping;
  }, [
    expertDetails,
    expertId,
    handleNavigateBack,
    isAdmin,
    t,
    tipaltiFeatureFlag
  ]);

  return (
    <PageTracker page={MixpanelPages.ExpertProfile}>
      {isLoading ? <Loader isFull /> : null}
      {expertDetails && (
        <div className={styles.expertContainer}>
          <AntDHeader
            title={displayUserName(expertDetails.user)}
            backLink={isAdmin ? EXPERT_TABLE : EXPERT_NETWORK}
            rightContainer={
              isAdmin ? (
                <Flex gap="8px">
                  <ReportExpertButton
                    expertName={displayUserName(expertDetails.user)}
                    expertId={expertId}
                  />
                  <Button
                    type="primary"
                    onClick={handleProjectEditClicked}
                    icon={<AntDIcon name="edit" />}
                  >
                    {t("edit")}
                  </Button>
                  {expertDetails.doNotContactStatus !==
                    DO_NOT_CONTACT_STATUS.DNC && (
                    <Button
                      type="primary"
                      onClick={handleOpenSlidePanel}
                      icon={<AntDIcon name="add" />}
                    >
                      {t("assign")}
                    </Button>
                  )}
                </Flex>
              ) : (
                expertDetails.doNotContactStatus !==
                  DO_NOT_CONTACT_STATUS.DNC && (
                  <Button
                    type="primary"
                    onClick={handleOpenSlidePanel}
                    icon={<AntDIcon name="add" />}
                  >
                    {t("assign")}
                  </Button>
                )
              )
            }
          />
          <SimpleBar
            className="simplebar-light"
            style={{
              height: "calc(100vh - 88px)",
              maxHeight: "calc(100vh - 88px)",
              overflowX: "hidden"
            }}
          >
            <div className={styles.bodyContainer}>
              <div className={styles.topContainer}>
                <ExpertHeader
                  user={expertDetails.user}
                  platformAgreementAccepted={
                    expertDetails.platformAgreementAccepted
                  }
                  lastPublicCompanyExperienceDate={
                    expertDetails.lastPublicCompanyExpDate as unknown as Date
                  }
                  isLinkedinVerified={expertDetails.isVerified}
                />
                <DncInfoBox
                  doNotContactStatus={expertDetails.doNotContactStatus}
                  doNotContactStatusDescription={
                    expertDetails.doNotContactStatusDescription
                  }
                  doNotContactStatusCategory={
                    expertDetails.doNotContactStatusCategory
                  }
                  isAdmin={isAdmin}
                />
              </div>
              <Tabs
                tabMapping={TabMapping}
                defaultActiveTab={defaultTab as any}
              />
            </div>
          </SimpleBar>
        </div>
      )}
    </PageTracker>
  );
};
