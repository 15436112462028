import { Slot, SlotMoment } from "@arbolus-technologies/models/common";
import { DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

export function groupSlotsByDay(
  slots: Slot[],
  timezone: string
): SlotMoment[][] {
  const grouped = slots.reduce(
    (acc, slot) => {
      const slotMoment = new SlotMoment(slot, timezone);
      const day = slotMoment.startTime.format(DATE_TIME_FORMAT);
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(slotMoment);
      return acc;
    },
    {} as Record<string, SlotMoment[]>
  );

  for (const key in grouped) {
    grouped[key].sort((a, b) => a.compare(b));
  }

  return Object.values(grouped).sort(
    (a, b) => a[0].startTime.unix() - b[0].startTime.unix()
  );
}

export function formatSlotMoment(slot: SlotMoment): string {
  return `${slot.startTime.format("LT")} - ${slot.endTime.format("LT")}`;
}
