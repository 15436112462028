import { Button } from "arbolus-ui-components";
import { Field, Form, Formik, FormikProps } from "formik";
import queryString from "query-string";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { InputGroup } from "reactstrap";
import { Subscription } from "rxjs";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  UserService
} from "@arbolus-technologies/api";
import { AuthPageBase, AuthPageHeader } from "@arbolus-technologies/auth";
import { PublicRoutes } from "@arbolus-technologies/routes";
import { utilService } from "@arbolus-technologies/utils";

import { CIQPasswordGuide } from "../../../app/components";
import CustomPasswordInput from "../../../app/components/CustomPasswordInput";
import { Alert } from "../../components";
import { ResetPasswordFormValidationSchema } from "./ResetPasswordFormSchema";

import styles from "./ResetPasswordPage.module.scss";

interface ResetPasswordProps
  extends RouteComponentProps<{ token: string }>,
    WithTranslation {}

interface ResetPasswordState {
  changeAttempted: boolean;
  isLoading: boolean;
  resetPasswordError?: ErrorResponse<CIQError>;
}

interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

class ResetPasswordPage extends React.Component<
  ResetPasswordProps,
  ResetPasswordState
> {
  constructor(props: ResetPasswordProps) {
    super(props);

    this.state = {
      changeAttempted: false,
      isLoading: false
    };
  }

  componentDidMount(): void {
    const { t, history } = this.props;
    document.title = t("resetPassword");
    const { email, token } = this.extractParams();
    const isValidEmail = utilService.validateEmail(email);

    if (!isValidEmail || !email || !token) {
      history.replace(PublicRoutes.LOGIN);
    }
  }

  componentWillUnmount(): void {
    this.resetPasswordSubscription?.unsubscribe();
  }

  private resetPasswordSubscription?: Subscription;

  extractParams = () => {
    const { location } = this.props;
    const { email, token } = queryString.parse(location.search);
    return { email, token } as { email: string; token: string };
  };

  handleResetPassword = ({ password }: ResetPasswordFormValues): void => {
    const { email, token } = this.extractParams();
    const { t, history } = this.props;

    this.setState({
      isLoading: true
    });

    this.resetPasswordSubscription = UserService.resetPasswordWithToken({
      email,
      password,
      token: decodeURIComponent(token)
    }).subscribe(
      () => {
        this.setState({
          isLoading: false,
          changeAttempted: true
        });

        DefaultToasterService.showSuccess(t("passwordChanged") as string);
        history.replace(PublicRoutes.LOGIN);
      },
      (error: ErrorResponse<CIQError>) => {
        this.setState({
          resetPasswordError: error,
          isLoading: false,
          changeAttempted: true
        });
      }
    );
  };

  renderForm = ({
    values,
    isValid
  }: FormikProps<ResetPasswordFormValues>): JSX.Element => {
    const { isLoading } = this.state;
    const { t } = this.props;
    return (
      <Form className={styles.form}>
        <InputGroup className="append">
          <Field
            name="password"
            placeholder={t("password")}
            type="password"
            component={CustomPasswordInput}
          />
        </InputGroup>

        <CIQPasswordGuide password={values.password} />

        <InputGroup className="append">
          <Field
            name="confirmPassword"
            placeholder={t("confirmPassword")}
            type="password"
            component={CustomPasswordInput}
          />
        </InputGroup>

        <div className={styles.footer}>
          <Link to={PublicRoutes.LOGIN}>{t("signIn")}</Link>
          <Button
            nativeType="submit"
            text={t(isLoading ? t("submitting") : t("reset"))}
            disabled={isLoading || !isValid}
          />
        </div>
      </Form>
    );
  };

  render(): JSX.Element {
    const { changeAttempted, resetPasswordError } = this.state;
    const { t } = this.props;

    return (
      <AuthPageBase>
        <AuthPageHeader title={t("resetPassword")} />
        <p>{t("instruction")}</p>

        {resetPasswordError && (
          <Alert feedback={resetPasswordError} isSuccess={false} />
        )}
        {!changeAttempted && (
          <Formik<ResetPasswordFormValues>
            initialValues={{
              password: "",
              confirmPassword: ""
            }}
            validationSchema={ResetPasswordFormValidationSchema}
            onSubmit={this.handleResetPassword}
            render={this.renderForm}
          />
        )}
      </AuthPageBase>
    );
  }
}

export default withTranslation("resetPassword")(ResetPasswordPage);
