import { Button, ButtonProps } from "antd";
import { useCallback, useState } from "react";

export function AsyncButton(props: ButtonProps) {
  const [disabled, setDisabled] = useState(false);
  const onClick = props.onClick as Function;

  const wrappedOnClick = useCallback(async () => {
    setDisabled(true);
    await onClick();
    setDisabled(false);
  }, [props.onClick]);

  return (
    <Button
      {...props}
      onClick={wrappedOnClick}
      disabled={disabled || props.disabled}
    />
  );
}
