import { twoLetterName } from "@arbolus-technologies/utils";
import { Avatar, Typography } from "antd";
import React from "react";
import styles from "./AntDAvatar.module.scss";

export interface AntDAvatarProps {
  profileImageUrl?: string;
  firstName?: string;
  lastName?: string;
  onlyAvatar?: boolean;
  size?: number | "large" | "small" | "default";
}

export const AntDAvatar: React.FC<AntDAvatarProps> = ({
  profileImageUrl,
  firstName,
  lastName,
  onlyAvatar,
  size = "default"
}) => {
  const { Text } = Typography;

  return profileImageUrl ? (
    <div className={styles.avatarContainer}>
      <Avatar
        src={profileImageUrl}
        size={size}
        className={styles.overrideAvatar}
      />
      {!onlyAvatar && <Text ellipsis>{`${firstName} ${lastName}`}</Text>}
    </div>
  ) : (
    <div className={styles.avatarContainer}>
      <Avatar size={size} className={styles.overrideAvatar}>
        {twoLetterName(firstName, lastName)}
      </Avatar>
      {!onlyAvatar && <Text ellipsis>{`${firstName} ${lastName}`}</Text>}
    </div>
  );
};
