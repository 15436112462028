import { Card, Divider, Flex } from "antd";
import React from "react";

import { PrivacyAndTerms } from "../PrivacyAndTerms";
import { SurveyFooter } from "../SurveyFooter";

import styles from "./LegalInformation.module.scss";

export const LegalInformation: React.FC = () => (
  <Card className={styles.legalInformationCard}>
    <Flex vertical gap={24}>
      <PrivacyAndTerms />
      <Flex gap={8}>
        <Divider style={{ margin: 0 }} />
      </Flex>
      <SurveyFooter />
    </Flex>
  </Card>
);
