import { Observable } from "rxjs";

import { MetaService, restService } from "@arbolus-technologies/api";

import { METADATA_API } from "../constants/api";
import { ApiNonPaginatedResponse } from "../models/api";
import { Currency } from "../models/meta";

// Easy way to de-duplicate code without changing lots of files
export const metaService = {
  ...MetaService,
  getCurrencies: (
    recaptchaToken?: string
  ): Observable<ApiNonPaginatedResponse<Currency>> =>
    restService.get<ApiNonPaginatedResponse<Currency>>(
      METADATA_API.GET_CURRENCIES(),
      { recaptchaToken }
    )
};
