import React from "react";

import { ProjectNote } from "@arbolus-technologies/api";
import { YELLOW } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import {
  DATE_DAY_SHORT_MONTH_YEAR_FORMAT,
  highlightWords
} from "@arbolus-technologies/utils";

import { AntDAngleTag, AntDIcon } from "@arbolus-technologies/antDComponents";
import { Avatar, Flex, Tag, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

import styles from "./ProjectNoteCard.module.scss";

interface ProjectNoteCardProps {
  projectNote: ProjectNote;
  keywordsList: string[];
}

const { Text } = Typography;

export const ProjectNoteCard: React.FC<ProjectNoteCardProps> = ({
  projectNote,
  keywordsList
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");
  const { angle, client, note, project, noteDate } = projectNote;

  const highlightedWords = highlightWords({
    text: note,
    tags: keywordsList,
    highlightColor: YELLOW,
    boldedWords: false
  });

  return (
    <Flex vertical gap={8}>
      <Flex gap={8} align="center">
        <Tag
          title={project.name}
          icon={<AntDIcon name="folder" />}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4
          }}
        >
          {project.name}
        </Tag>
        <AntDAngleTag angle={angle} showTooltip={false} />
      </Flex>
      <DangerouslySetInnerHTML text={highlightedWords} />
      <Flex justify="space-between" align="center">
        <Text code className={styles.date}>
          {noteDate
            ? moment(noteDate).format(DATE_DAY_SHORT_MONTH_YEAR_FORMAT)
            : t("noDate")}
        </Text>
        <Flex gap={4} align="center">
          {client.logoUrl && (
            <Avatar size="small" src={client.logoUrl} alt="Client Avatar" />
          )}
          <Text>{client.name}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
