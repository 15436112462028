import { Collapse, Flex, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { COMPANY_TYPE, DateString } from "@arbolus-technologies/models/common";
import { WorkHistory } from "@arbolus-technologies/models/expert";
import {
  DangerouslySetInnerHTML,
  PublicCompanyBadge,
  WorkHistoryItem
} from "@arbolus-technologies/ui/components";

const { Text } = Typography;

interface WorkHistoryCardProps {
  workHistoryItem: WorkHistory;
  lastPublicCompanyExpDate: DateString | null;
  index?: number;
}

export const WorkHistoryCard: React.FC<WorkHistoryCardProps> = ({
  workHistoryItem,
  lastPublicCompanyExpDate,
  index
}) => {
  const { t } = useTranslation("referralWorkHistory");

  const companyName =
    workHistoryItem.company?.name ?? t(workHistoryItem.employedStatus);
  const country = workHistoryItem.country?.name;
  const displayPublicCompanyBadge =
    lastPublicCompanyExpDate !== null &&
    workHistoryItem.company?.type === COMPANY_TYPE.Public;

  return (
    <Collapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <AntDIcon name={`${isActive ? "arrowUp" : "arrowDown"}`} />
      )}
      defaultActiveKey={`${dayjs(workHistoryItem.from).format("llll")}_0_0`}
      items={[
        {
          key: `${dayjs(workHistoryItem.from).format("llll")}_${index ?? 0}_0`,
          label: (
            <Flex vertical>
              <Flex gap={8}>
                <Text strong>{companyName}</Text>
                {displayPublicCompanyBadge && (
                  <PublicCompanyBadge date={workHistoryItem.to} />
                )}
              </Flex>
              <WorkHistoryItem
                key={workHistoryItem.id}
                title={workHistoryItem.title}
                from={workHistoryItem.from.toString()}
                to={workHistoryItem.to ? workHistoryItem.to.toString() : null}
                companyName={companyName}
                countryName={country}
              />
            </Flex>
          ),
          children: (
            <DangerouslySetInnerHTML
              text={
                workHistoryItem.jobDescription ?? t("descriptionNotAvailable")
              }
            />
          )
        }
      ]}
    />
  );
};
