import { LocationDescriptorObject } from "history";

export const EXPERT_BASE_ROUTE = "/expert";
export const NEW_EXPERT = "/new-expert";
export const EXPERT_TABLE = "/expert-table";

export const EXPERT_PROFILE = (expertId: string): LocationDescriptorObject => ({
  pathname: `${EXPERT_BASE_ROUTE}/${expertId}`
});

export const EXPERT_PROFILE_ROUTE = (expertId: string): string =>
  `${EXPERT_BASE_ROUTE}/${expertId}`;

// Expert Project Application
export const EXPERT_PROJECT_APPLICATION = "/application/:projectId";
export const PROJECT_APPLICATION_ROUTE = (projectId: string): string =>
  `application/${projectId}`;

export const EXPERT_SELF_PROFILE_EDIT = "/profile-edit";
export const EXPERT_DETAILS_EDIT_TAB = `${EXPERT_SELF_PROFILE_EDIT}/details`;
export const EXPERT_PROFILE_EDIT_ROUTE = (expertId: string): string =>
  `${EXPERT_BASE_ROUTE}/${expertId}${EXPERT_SELF_PROFILE_EDIT}`;
export const EXPERT_PROFILE_EDIT_INSIGHTS_ROUTE = `${EXPERT_SELF_PROFILE_EDIT}/insights`;

export const EXPERT_EXPERIENCE_EDIT_TAB = `${EXPERT_SELF_PROFILE_EDIT}/experience`;

export const EXPERT_NETWORK = "/expert-network";

export const EXPERT_DETAILS_EDIT_TABS = {
  DETAILS: "details",
  INSIGHTS: "insights",
  EXPERIENCE: "experience",
  CAREER: "career",
  PASSWORD: "password",
  NOTIFICATIONS: "notifications",
  ENDORSEMENTS: "endorsements",
  SETTINGS: "settings"
};

export const EXPERT_DETAILS_EDIT_TABS_PATH = Object.fromEntries(
  Object.entries(EXPERT_DETAILS_EDIT_TABS).map(([key, value]) => [
    key,
    `${EXPERT_SELF_PROFILE_EDIT}/${value}`
  ])
);

export const EXPERT_PROFILE_EDIT_ROUTES = [
  EXPERT_SELF_PROFILE_EDIT,
  ...Object.values(EXPERT_DETAILS_EDIT_TABS_PATH)
];
