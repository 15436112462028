import { Subject } from "rxjs";

enum ExpertReferralEventType {
  UPDATE,
  COMPLIANCE_UPDATE,
  SAVE_NETWORK
}

interface ExpertReferralEvent {
  eventType: ExpertReferralEventType;
  referralId: string;
  newStatus?: string;
  complianceStatus?: string;
  networkStatus?: boolean;
}

class ProjectExpertEvents {
  eventSubject: Subject<ExpertReferralEvent>;

  constructor() {
    this.eventSubject = new Subject();
  }

  updateReferral(referralId: string, newStatus: string): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.UPDATE,
      referralId,
      newStatus
    });
  }

  updateCompliancePassed(referralId: string): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.COMPLIANCE_UPDATE,
      referralId
    });
  }

  changeSaveNetworkStatus(referralId: string, networkStatus: boolean): void {
    this.eventSubject.next({
      eventType: ExpertReferralEventType.SAVE_NETWORK,
      referralId,
      networkStatus
    });
  }
}

export default new ProjectExpertEvents();
