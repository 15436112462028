import { Badge, Card, Typography } from "antd";
import { Dayjs } from "dayjs";
import moment from "moment-timezone";
import React from "react";

import { GetEventResponse } from "@arbolus-technologies/api";
import { DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

const { Text } = Typography;

interface EventCellRendererProps {
  date: Dayjs;
  events: GetEventResponse[];
  selectedTimezone: string;
  onEventClick: (eventId: string) => void;
}

export const EventCellRenderer: React.FC<EventCellRendererProps> = ({
  date,
  events,
  selectedTimezone,
  onEventClick
}) => {
  const formattedDate = date.format(DATE_TIME_FORMAT);

  const eventsForDate = events
    .filter(
      (event) =>
        moment(event.startTime).format(DATE_TIME_FORMAT) === formattedDate
    )
    .sort(
      (a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf()
    );

  return (
    <>
      {eventsForDate.length > 0
        ? eventsForDate.map((event) => {
            const eventStartTime = moment
              .tz(event.startTime, selectedTimezone)
              .format("h:mma");
            const eventEndTime = moment
              .tz(event.endTime, selectedTimezone)
              .format("h:mma");

            return (
              <Card
                hoverable
                key={event.id}
                style={{
                  cursor: "pointer",
                  marginBottom: "4px",
                  borderRadius: "4px"
                }}
                styles={{
                  body: {
                    padding: "4px"
                  }
                }}
                onClick={() => onEventClick(event.id)}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    marginBottom: "8px",
                    maxWidth: "100%",
                    width: "100%",
                    margin: "0 auto"
                  }}
                  title={event.title}
                  ellipsis
                >
                  <Badge status="success" style={{ marginRight: "4px" }} />
                  {eventStartTime} - {eventEndTime}: {event.title}
                </Text>
              </Card>
            );
          })
        : null}
    </>
  );
};
