import { ColDef, ValueFormatterParams } from "ag-grid-community";

import { Project } from "@arbolus-technologies/api";
import {
  ConfirmedEmailUserModel,
  Referral
} from "@arbolus-technologies/models/common";

function nameFormatter(value: ConfirmedEmailUserModel) {
  return value.firstName + " " + value.lastName;
}

function getManagers(arbolusTeam: Project["arbolusTeam"]) {
  const managers: ConfirmedEmailUserModel[] = [];
  if (arbolusTeam.projectPrimaryLead) {
    managers.push(arbolusTeam.projectPrimaryLead);
  }

  if (arbolusTeam.projectSecondaryLead) {
    managers.push(arbolusTeam.projectSecondaryLead);
  }

  if (arbolusTeam.standardManagers) {
    managers.push(...arbolusTeam.standardManagers);
  }

  return managers;
}

export const OWNER_COL_ID = "owner";

export function getOwnerCol(
  arbolusTeam: Project["arbolusTeam"]
): ColDef<Referral> {
  return {
    colId: OWNER_COL_ID,
    field: "owner",
    headerName: "Owner",
    minWidth: 100,
    valueFormatter: ({ data }) =>
      data?.owner ? nameFormatter(data.owner) : "",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains"],
            maxNumConditions: 1
          }
        },
        {
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: ({
              value
            }: ValueFormatterParams<unknown, string>) => value
          }
        }
      ]
    },
    floatingFilter: true,
    filterValueGetter: ({ data }) =>
      data?.owner ? nameFormatter(data.owner) : undefined,
    suppressHeaderMenuButton: true,
    editable: true,
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      values: getManagers(arbolusTeam),
      cellRenderer: ({
        value
      }: {
        value: ConfirmedEmailUserModel | undefined;
      }) => (value ? nameFormatter(value) : undefined)
    }
  };
}
