import i18next from "i18next";

import { DefaultToasterService } from "./ToastService";

const notification = DefaultToasterService;

export const ClipboardService = {
  copyToClipboard: (text: string): void => {
    if (navigator.clipboard === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isCopied = (window as { [key: string]: any }).clipboardData.setData(
        "Text",
        text
      );

      if (isCopied) {
        notification.showSuccess(i18next.t("clipboard:copiedToClipboard"));
      } else {
        notification.showError(i18next.t("clipboard:copiedToClipboardError"));
      }
    } else {
      navigator.clipboard
        .writeText(text)
        .then(() =>
          notification.showSuccess(i18next.t("clipboard:copiedToClipboard"))
        )
        .catch(() => {
          notification.showError(i18next.t("clipboard:copiedToClipboardError"));
        });
    }
  }
};
