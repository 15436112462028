import React from "react";

import { ClipboardService, Project } from "@arbolus-technologies/api";
import { Referral, Slot } from "@arbolus-technologies/models/common";
import { HR } from "@arbolus-technologies/ui/components";

import { DefaultFormatDateService } from "../../Services/Referrals/FormatDateService";
import { SingleSlot } from "./SingleSlot";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { useTranslation } from "react-i18next";
import { formatSlotMoment, groupSlotsByDay } from "./ReferralSlotsDetailHelper";
import styles from "./SlotsByDay.module.scss";

interface SlotsByDayProps {
  referral: Referral;
  slots: Slot[];
  project: Project;
  timezone: string;
}

export const SlotsByDay: React.FC<SlotsByDayProps> = ({
  referral,
  project,
  slots,
  timezone
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "availability" });
  const slotsByDay = React.useMemo(
    () => groupSlotsByDay(slots, timezone),
    [slots, timezone]
  );

  if (!slotsByDay.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      {slotsByDay.map((slots) => (
        <div
          className={styles.row}
          key={slots[0].startTime.format("YYYY-MM-DD")}
        >
          <div className={styles.day}>
            {DefaultFormatDateService.formatDayMoment(slots[0].startTime)}
          </div>
          <div className={styles.slots}>
            <div className={styles.allCopyIcon}>
              {t("fullDay")}
              <AntDIcon
                tooltip={t("copy")}
                name="copy"
                onClick={() =>
                  ClipboardService.copyToClipboard(
                    `${DefaultFormatDateService.formatDayMoment(slots[0].startTime)}: ${formatSlotMoment(slots[0])}`
                  )
                }
              />
            </div>
            {slots.map((slot) => (
              <SingleSlot
                key={slot.identifier}
                referral={referral}
                slot={slot}
                project={project}
                timezone={timezone}
              />
            ))}
          </div>
        </div>
      ))}
      <HR />
    </div>
  );
};
