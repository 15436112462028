import { Button } from "arbolus-ui-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { PROJECT_REFER_EXPERT_ROUTE } from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import styles from "./ReferExpertSlidePanel.module.scss";

interface ReferExpertSlidePanelProps {
  projectId: string;
}

export const ReferExpertSlidePanel = ({
  projectId
}: ReferExpertSlidePanelProps): JSX.Element => {
  const { t } = useTranslation("referExpert");
  const history = useHistory();
  const dispatch = useDispatch();

  const handleReferNowClicked = (): void => {
    history.push(PROJECT_REFER_EXPERT_ROUTE(projectId));
    dispatch(PanelStoreActions.closePanel(PanelId.ReferExpert));
  };

  return (
    <SlidePanel
      title={t("referExperts")}
      panelId={PanelId.ReferExpert}
      closeButtonDirection="right"
      width={SIDE_PANEL_SIZE._400}
    >
      <div className={styles.container}>
        <div className={styles.details}>
          <h3>{t("howWorks")}</h3>
          <p>{t("detail_1")}</p>
          <p>{t("detail_2")}</p>
        </div>
        <Button onClick={handleReferNowClicked} text={t("referNow")} />
      </div>
    </SlidePanel>
  );
};
