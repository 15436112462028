import { Button, Flex, Typography } from "antd";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  CreatedResponse,
  DefaultToasterService,
  ErrorResponse,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { SURVEY_ROUTE } from "@arbolus-technologies/routes";

import styles from "./ComplianceStatement.module.scss";

const { Text, Title } = Typography;

interface ComplianceStatementProps {
  surveyId: string;
  surveyLink: string;
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const ComplianceStatement: React.FC<ComplianceStatementProps> = ({
  surveyId,
  surveyLink,
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("surveyComplianceStatement");
  const { trackClick } = useArbolusTracking();
  const [isAcceptAndContinueDisabled, setIsAcceptAndContinueDisabled] =
    useState(false);

  const codeOfConduct = () => (
    <Text>
      <Trans i18nKey="complianceStatementText1">
        This survey is designed to gather your expert insights while ensuring
        that your responses remain anonymous and unbiased. We ask that you
        adhere to our{" "}
        <a
          href="https://www.arbolus.com/code-of-conduct"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Code of Conduct
        </a>{" "}
        throughout this process, which includes maintaining the confidentiality
        of any proprietary or sensitive information you may hold and not acting
        in violation of any pre-existing obligations imposed upon you. By
        continuing with this survey, you acknowledge and agree:
      </Trans>
    </Text>
  );

  const handleMixpanelTracking = () => {
    const survey = localStorage.getItem(`survey_${surveyId}`);
    const surveyObj = JSON.parse(survey ?? "{}");

    if (!surveyObj.accepted) {
      localStorage.setItem(
        `survey_${surveyId}`,
        JSON.stringify({ ...surveyObj, accepted: true })
      );

      trackClick(MixPanelEventNames.SurveyAcceptAndProceedButton);
    }
  };

  const buildLink = (surveyLink: string, surveyCreatedResponseId: string) => {
    if (surveyLink.includes("{{expertId}}")) {
      return surveyLink.replace(/{{expertId}}/g, surveyCreatedResponseId);
    }
    return surveyLink;
  };

  const handleAcceptAndContinue = () => {
    setIsAcceptAndContinueDisabled(true);
    surveyService.startExpertSurvey(surveyId).subscribe(
      (surveyCreatedResponse: CreatedResponse) => {
        const surveyLinkWithCustomId = buildLink(
          surveyLink,
          surveyCreatedResponse.id
        );
        handleMixpanelTracking();
        window.location.href = surveyLinkWithCustomId;
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  return (
    <Flex vertical gap={48} align="center" className={styles.container}>
      <Title>{t("welcomeSurveyTitle")}</Title>
      <Flex vertical gap={24} align="center">
        <Title level={5}>{t("complianceStatement")}</Title>
        <Flex vertical gap={16}>
          {codeOfConduct()}
          <Text>{t("complianceStatementText2")}</Text>
          <Text>{t("complianceStatementText3")}</Text>
          <Text>{t("complianceStatementText4")}</Text>
          <Text>{t("complianceStatementText5")}</Text>
        </Flex>
        <Flex justify="center" gap={16} className={styles.buttonsContainer}>
          <AntDButtonLink
            pathname={SURVEY_ROUTE(surveyId)}
            text={t("back")}
            size="large"
          />
          <Button
            type="primary"
            size="large"
            onClick={() => handleAcceptAndContinue()}
            disabled={isAcceptAndContinueDisabled}
          >
            {t("acceptAndStart")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
