import { ICellRendererParams } from "ag-grid-community";
import { Tag, Typography } from "antd";

import styles from "./CustomersRenderer.module.scss";

export interface CustomersProps
  extends Partial<ICellRendererParams<{ customersCount: number }>> {}

export function CustomersRenderer({ data }: CustomersProps) {
  if (!data?.customersCount) {
    return <Typography.Text type="secondary">-</Typography.Text>;
  }

  return <Tag className={styles.tag}>{data.customersCount}</Tag>;
}
