import { Button, Flex, Typography } from "antd";
import clsx from "clsx";
import React from "react";

import { AntDButtonLink, AntDIcon } from "@arbolus-technologies/antDComponents";

import styles from "./AntDHeader.module.scss";

const { Title } = Typography;

interface AntDHeaderProps {
  title: string;
  rightContainer?: React.ReactNode;
  backLink?: string;
  stickyHeader?: boolean;
  backButton?: () => void;
}

export const AntDHeader: React.FC<AntDHeaderProps> = ({
  rightContainer,
  title,
  backLink,
  stickyHeader,
  backButton
}) => {
  return (
    <Flex
      justify="space-between"
      align="center"
      className={clsx(styles.main, stickyHeader && styles.stickyHeader)}
    >
      <Flex className={styles.titleBackContainer} align="center">
        {backLink && (
          <AntDButtonLink
            type="link"
            icon={<AntDIcon name="chevronLeft" />}
            to={backLink}
          />
        )}
        {backButton && (
          <Button
            type="link"
            icon={<AntDIcon name="chevronLeft" onClick={backButton} />}
          />
        )}
        <Title className={styles.text} level={4}>
          {title}
        </Title>
      </Flex>
      <Flex className={styles.childrenContainer}>{rightContainer}</Flex>
    </Flex>
  );
};
