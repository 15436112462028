import clsx from "clsx";
import React, { Component } from "react";
import { FormGroup, Label, Progress } from "reactstrap";
import { Subject, Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";

import {
  PROJECT_CLIENTS_API,
  PROJECT_EXPERTS_API,
  REST_ERROR
} from "../../../../../constants/api";
import { FILE_UPLOADING_STATES } from "../../../../../constants/files";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { Document, FILE_TYPE } from "../../../../../models/documents";
import {
  DocumentService,
  RBServiceManager,
  UtilsService
} from "../../../../../services";
import { CIQFileIcon } from "../../documents";

const notification = new ToasterService();

interface AttachmentSidePanelItemProps {
  attachmentId: string;
  attachmentName: string;
  attachmentSize: number;
  attachmentType?: FILE_TYPE;
  attachmentDuration?: number;
  attachment?: File;
  projectId: string;
  isChecked?: boolean;
  onFinishedUploading?: (
    uploadedAttachmentId: string,
    attachment: Document
  ) => void;
  onUploadStop?: (failedFileId: string) => void;
  onShareChecked?: (status: boolean) => void;
}

interface AttachmentSidePanelItemState {
  uploadingProgress: number;
  uploadingState: FILE_UPLOADING_STATES;
}

class AttachmentSidePanelItem extends Component<
  AttachmentSidePanelItemProps,
  AttachmentSidePanelItemState
> {
  constructor(props: AttachmentSidePanelItemProps) {
    super(props);
    this.state = {
      uploadingProgress: 0,
      uploadingState: FILE_UPLOADING_STATES.IDLE
    };
  }

  componentDidMount(): void {
    const { attachment } = this.props;

    if (attachment) {
      this.uploadSelectedFile();
    }
  }

  componentWillUnmount(): void {
    this.documentUploadSubscription &&
      this.documentUploadSubscription.unsubscribe();
  }

  private documentUploadSubscription?: Subscription;

  uploadSelectedFile = (): void => {
    const {
      attachment,
      attachmentId,
      onFinishedUploading,
      onUploadStop,
      projectId
    } = this.props;
    const progressSubject = new Subject<number>();

    if (attachment) {
      const formData = new FormData();
      formData.append("file", attachment);
      progressSubject.subscribe((progress) =>
        this.setState({ uploadingProgress: progress })
      );
      this.setState({ uploadingState: FILE_UPLOADING_STATES.UPLOADING });

      this.documentUploadSubscription = RBServiceManager.serviceCaller(
        DocumentService.uploadFile(
          PROJECT_CLIENTS_API.CREATE_DOCUMENT(projectId),
          formData,
          progressSubject
        ),
        DocumentService.uploadFile(
          PROJECT_EXPERTS_API.CREATE_DOCUMENT(projectId),
          formData,
          progressSubject
        )
      ).subscribe(
        (response: Document) =>
          this.setState(
            {
              uploadingState: FILE_UPLOADING_STATES.UPLOADED
            },
            () => {
              onFinishedUploading!(attachmentId, response);
              progressSubject.unsubscribe();
            }
          ),
        ({ status, message }: ErrorResponse<CIQError>) => {
          this.setState({
            uploadingState: FILE_UPLOADING_STATES.UPLOAD_FAILED
          });
          switch (status) {
            case REST_ERROR.REQUEST_CANCELED:
              onUploadStop!(attachmentId);
              break;
            case REST_ERROR.UNSUPPORTED_FILE_TYPE:
              notification.showError(message);
              onUploadStop!(attachmentId);
              break;
            default:
              notification.showError(message);
          }
        }
      );
    }
  };

  getProgressBarColor = (): string => {
    const { uploadingState } = this.state;
    let color = "";
    if (uploadingState === FILE_UPLOADING_STATES.UPLOADING) {
      color = "";
    } else if (uploadingState === FILE_UPLOADING_STATES.UPLOADED) {
      color = "success";
    } else if (uploadingState === FILE_UPLOADING_STATES.UPLOAD_FAILED) {
      color = "danger";
    }
    return color;
  };

  handleStopUploading = (): void => {
    const { onUploadStop, attachment, attachmentId } = this.props;

    if (attachment) {
      onUploadStop!(attachmentId);
    }
  };

  handleRetryFailedUpload = (): void => {
    this.setState({
      uploadingState: FILE_UPLOADING_STATES.UPLOADING,
      uploadingProgress: 0
    });
    this.uploadSelectedFile();
  };

  handleShareCheckboxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { onShareChecked } = this.props;
    const { checked } = event.target;

    onShareChecked!(checked);
  };

  renderRightContainer = (): JSX.Element => {
    let content = null;
    const { attachment, isChecked } = this.props;
    const { uploadingState } = this.state;
    const isUploadFailed =
      uploadingState === FILE_UPLOADING_STATES.UPLOAD_FAILED;

    if (attachment) {
      content = (
        <span
          className={clsx("ciq-icon", {
            "ciq-refresh": isUploadFailed,
            "ciq-close": !isUploadFailed
          })}
          onClick={
            isUploadFailed
              ? this.handleRetryFailedUpload
              : this.handleStopUploading
          }
        />
      );
    } else {
      content = (
        <FormGroup className="custom-checkbox">
          <Label check>
            <input
              onChange={this.handleShareCheckboxOnChange}
              name="projectTypeCheck"
              type="checkbox"
              checked={isChecked}
              className="form-check-input"
            />
            <span className="checkmark" />
          </Label>
        </FormGroup>
      );
    }

    return content;
  };

  render(): JSX.Element {
    const {
      attachment,
      attachmentName,
      attachmentSize,
      attachmentType,
      attachmentDuration
    } = this.props;
    const { uploadingProgress, uploadingState } = this.state;

    const isUploading = uploadingState === FILE_UPLOADING_STATES.UPLOADING;
    const isUploadFailed =
      uploadingState === FILE_UPLOADING_STATES.UPLOAD_FAILED;

    const isTranscript = attachmentType === FILE_TYPE.TRANSCRIPT;

    const fileSizeOrDuration = isTranscript
      ? UtilsService.convertSecondsToHoursAndMinutes(attachmentDuration || 0)
      : DocumentService.prettifyFileSize(attachmentSize);

    const prettifiedFileName = isTranscript
      ? attachmentName
      : DocumentService.prettifyFileName(
          DocumentService.sanitizeFileName(attachmentName)
        );

    return (
      <div
        className={clsx("file-item", {
          uploading: isUploading || isUploadFailed
        })}
      >
        <div className="left-container">
          <div className="file-type-icon">
            {CIQFileIcon(attachmentName, attachmentType)}
          </div>
          <div className="detail-container">
            <div className="file-name">{prettifiedFileName}</div>
            <div className="file-size">{fileSizeOrDuration}</div>
            {attachment && (
              <div className="file-uploading-progressbar">
                <Progress
                  value={uploadingProgress}
                  color={this.getProgressBarColor()}
                />
              </div>
            )}
          </div>
        </div>

        <div className="right-container">{this.renderRightContainer()}</div>
      </div>
    );
  }
}

export default AttachmentSidePanelItem;
