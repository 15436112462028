import { Flex, Typography } from "antd";
import React from "react";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { EventAttachmentModel } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { DocumentIcon } from "@arbolus-technologies/ui/components";
import { getFileExtension } from "@arbolus-technologies/utils";

const { Text } = Typography;

interface AttachmentsListProps {
  attachments: EventAttachmentModel[];
  handleRemoveAttachment: (selectedAttachmentId: string) => void;
}

export const AttachmentsList: React.FC<AttachmentsListProps> = ({
  attachments,
  handleRemoveAttachment
}) => {
  return (
    <>
      {attachments.length > 0 && (
        <Flex gap={8} wrap>
          {attachments.map((attachment) => {
            const extension = getFileExtension(attachment?.fileName ?? "");

            return (
              <Flex
                key={attachment.id}
                style={{
                  backgroundColor: ARBOLUS_COLORS.bColorBaseWhite,
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: ARBOLUS_COLORS.colorBorder,
                  padding: "4px 8px",
                  borderRadius: 4
                }}
                align="center"
                gap={8}
              >
                <Flex gap={4} align="center">
                  <DocumentIcon
                    fileExtension={extension}
                    customHeight="22px"
                    customWidth="22px"
                    fontSize="14px"
                  />
                  <Text>{attachment.fileName}</Text>
                </Flex>
                <AntDIcon
                  name="close"
                  fontSize="16px"
                  color={ARBOLUS_COLORS.bColorBaseBlack}
                  onClick={() => handleRemoveAttachment(attachment.id)}
                />
              </Flex>
            );
          })}
        </Flex>
      )}
    </>
  );
};
