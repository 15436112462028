import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  APP_TRACKING_ROUTES,
  APP_USER_ROLES
} from "@arbolus-technologies/models/common";
import { AppSelector } from "@arbolus-technologies/stores/app";

import { PublicRoutes } from "@arbolus-technologies/routes";
import { useHistory } from "react-router";
import { ClientOrExpert } from "../ClientOrExpert/ClientOrExpert";

type ClientOrExpertRole =
  | typeof APP_USER_ROLES.expert
  | typeof APP_USER_ROLES.client;

export function LoginPageJunction({
  from,
  clientContent,
  expertContent,
  fallbackContent
}: {
  from?: string;
  clientContent: React.ReactNode;
  expertContent: React.ReactNode;
  fallbackContent: React.ReactNode;
}) {
  const { t } = useTranslation("login");
  const history = useHistory();
  const { LinkedInAuth } = useSelector(AppSelector.getFeatureFlags());
  const [userType, setUserType] = useState<ClientOrExpertRole>();

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const surveyExpertId = queryString.parse(
      queryParams.search as string
    ).surveyExpertId;

    const fromRegisterOrSurvey =
      from === APP_TRACKING_ROUTES.REGISTER ||
      from === APP_TRACKING_ROUTES.SURVEY_QUALIFIED ||
      from === APP_TRACKING_ROUTES.SURVEY_NOT_QUALIFIED ||
      from === APP_TRACKING_ROUTES.SURVEY_FEEDBACK;

    if (surveyExpertId) {
      // Redirect survey experts from Login to Register if they came from an unexpected route
      if (!fromRegisterOrSurvey) {
        // TODO: Revisit this after LinkedIn release - could be dead code
        const paymentMethodParams = queryString.stringify(queryParams);
        history.replace(`${PublicRoutes.REGISTER}?${paymentMethodParams}`);
      } else {
        setUserType(APP_USER_ROLES.expert);
      }
    }
  }, []);

  if (!LinkedInAuth) {
    return <>{fallbackContent}</>;
  } else if (userType === APP_USER_ROLES.expert) {
    return <>{expertContent}</>;
  } else if (userType === APP_USER_ROLES.client) {
    return <>{clientContent}</>;
  } else {
    return <ClientOrExpert setUserType={setUserType} />;
  }
}
