import { Badge, Button, Flex, Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDAvatar, AntDIcon } from "@arbolus-technologies/antDComponents";
import { ReferralDetail, User } from "@arbolus-technologies/api";
import {
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

const { Text } = Typography;

interface StatusesProps {
  referral: ReferralDetail;
  isDnc: boolean;
  isCandidate: boolean;
  isApproved: boolean;
  onRejectExpert: () => void;
  onApproveExpert: () => void;
}

export const Statuses: React.FC<StatusesProps> = ({
  referral,
  isDnc,
  isCandidate,
  isApproved,
  onRejectExpert,
  onApproveExpert
}) => {
  const { t } = useTranslation("referralStatuses");

  const { REJECT, ACCEPT, PENDING } = REFERRAL_SUB_STATE;
  const {
    ACCEPT: ACCEPT_STATE,
    REJECT: REJECT_STATE,
    PENDING: PENDING_STATE
  } = REFERRAL_COMPLIANCE_STATE;

  const {
    application: {
      subStatus: applicationStatus,
      updateUser: applicationUpdateUser,
      updateDate: applicationUpdateDate
    },
    compliance: {
      subStatus: complianceStatus,
      updateUser: complianceUpdateUser,
      updateDate: complianceUpdateDate
    },
    complianceRequired,
    fastTrack: { fastTrack },
    review: {
      subStatus: reviewStatus,
      updateUser: reviewUpdateUser,
      updateDate: reviewUpdateDate
    }
  } = referral;

  const isApplicationAccepted = applicationStatus === ACCEPT;
  const isApplicationRejected = applicationStatus === REJECT;
  const isApplicationSent = applicationStatus === PENDING && fastTrack;
  const isApplicationNotSent = applicationStatus === PENDING && !fastTrack;

  const isComplianceAccepted = complianceStatus === ACCEPT_STATE;
  const isComplianceRejected = complianceStatus === REJECT_STATE;
  const isCompliancePending = complianceStatus === PENDING_STATE;

  const isRejected = reviewStatus === REJECT;
  const isPending = reviewStatus === PENDING;

  const showReviewOptions = isCandidate && !isApplicationRejected && !isDnc;

  if (isApplicationRejected) {
    return (
      <Badge
        color={ARBOLUS_COLORS.bColorError}
        text={t("applicationDeclined")}
      />
    );
  }

  const getTooltipText = (user: User, date: Date) => {
    if (!date && !user) {
      return "";
    }

    return (
      <Flex vertical gap={8}>
        {date && (
          <Text style={{ color: ARBOLUS_COLORS.bColorBaseWhite }}>
            {moment(date).format(APP_DATE_FORMAT)}
          </Text>
        )}
        {user && (
          <AntDAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            color={ARBOLUS_COLORS.bColorBaseWhite}
            profileImageUrl={user.profileImageUrl}
          />
        )}
      </Flex>
    );
  };

  return (
    <Flex gap={8} justify="space-between">
      <Flex gap={8} align="center">
        <Tooltip
          placement="bottom"
          title={getTooltipText(applicationUpdateUser, applicationUpdateDate)}
        >
          {isApplicationNotSent && (
            <Badge
              color={ARBOLUS_COLORS.colorTextPlaceholder}
              text={t("applicationNotSent")}
            />
          )}
          {isApplicationSent && (
            <Badge
              color={ARBOLUS_COLORS.bColorWarning}
              text={t("applicationPending")}
            />
          )}
          {isApplicationAccepted && (
            <Badge
              color={ARBOLUS_COLORS.bColorSuccess}
              text={t("applicationCompleted")}
            />
          )}
        </Tooltip>
        <AntDIcon name="chevronRight" />
        {complianceRequired && (
          <>
            <Tooltip
              placement="bottom"
              title={getTooltipText(complianceUpdateUser, complianceUpdateDate)}
            >
              {isCompliancePending && (
                <Badge
                  color={ARBOLUS_COLORS.bColorWarning}
                  text={t("pendingCompliance")}
                />
              )}
              {isComplianceAccepted && (
                <Badge
                  color={ARBOLUS_COLORS.bColorSuccess}
                  text={t("compliancePassed")}
                />
              )}
              {isComplianceRejected && (
                <Badge
                  color={ARBOLUS_COLORS.bColorError}
                  text={t("complianceFailed")}
                />
              )}
            </Tooltip>
            <AntDIcon name="chevronRight" />
          </>
        )}

        <Tooltip
          placement="bottom"
          title={getTooltipText(reviewUpdateUser, reviewUpdateDate)}
        >
          {isApproved ? (
            <Badge color={ARBOLUS_COLORS.bColorSuccess} text={t("approved")} />
          ) : (
            <>
              {isPending && (
                <Badge
                  color={ARBOLUS_COLORS.bColorWarning}
                  text={t("pendingApproval")}
                />
              )}
              {isRejected && (
                <Badge
                  color={ARBOLUS_COLORS.bColorError}
                  text={t("rejected")}
                />
              )}
            </>
          )}
        </Tooltip>
      </Flex>

      {showReviewOptions && (
        <Flex gap={8}>
          <Button
            danger
            type="primary"
            icon={<AntDIcon name="close" />}
            onClick={onRejectExpert}
          />
          <Button
            type="primary"
            icon={<AntDIcon name="check" />}
            onClick={onApproveExpert}
            style={{ backgroundColor: ARBOLUS_COLORS.bColorSuccess }}
          />
        </Flex>
      )}
    </Flex>
  );
};
