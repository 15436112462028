import { Col, Form, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { BasicUser, EventUserModel } from "@arbolus-technologies/api";
import { EventCandidatesSelector } from "@arbolus-technologies/features/common";
import {
  EVENT_FORM,
  EventFormInterface,
  GuestOption
} from "@arbolus-technologies/models/project";

const { GUESTS } = EVENT_FORM;

interface EventGuestsProps {
  projectId: string;
  organizer?: EventUserModel;
  currentBasicUser: BasicUser;
}

export const EventGuests: React.FC<EventGuestsProps> = ({
  projectId,
  organizer,
  currentBasicUser
}) => {
  const { t } = useTranslation("eventForm");

  const form = Form.useFormInstance<EventFormInterface>();
  const { guests } = Form.useWatch([], form) ?? {};

  const handleGuestsChange = (selectedGuests: GuestOption[]) => {
    form.setFieldsValue({ [GUESTS]: selectedGuests });
  };

  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label={t("eventGuests")}
          name={GUESTS}
          rules={[
            {
              required: true,
              message: t("eventGuestsRequired")
            }
          ]}
        >
          <EventCandidatesSelector
            projectId={projectId}
            selectedCandidates={guests}
            handleGuestsChange={handleGuestsChange}
            placeholder={t("eventGuestsPlaceholder")}
            organizer={organizer}
            currentBasicUser={currentBasicUser}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
