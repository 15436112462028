import { ICellRendererParams } from "ag-grid-community";
import { Tag, Typography } from "antd";

export interface NPSCompanyDataRendererProps
  extends Partial<ICellRendererParams<{ nps: number }>> {
  isCompanyData?: boolean;
}

export function NPSCompanyDataRenderer({ data }: NPSCompanyDataRendererProps) {
  if (!data?.nps) return <Typography.Text type="secondary">-</Typography.Text>;

  return <Tag color={getColor(data.nps)}>{data.nps}</Tag>;
}

function getColor(nps: number) {
  if (nps >= 0 && nps <= 6) {
    return "red";
  } else if (nps >= 7 && nps <= 8) {
    return "yellow";
  } else if (nps >= 9 && nps <= 10) {
    return "green";
  }
  return "grey";
}
