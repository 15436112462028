import clsx from "clsx";
import { Control, useController } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";

import styles from "./InputRadio.module.scss";

interface InputRadioProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  direction?: "row" | "column";
  options: {
    value: T;
    label: string;
  }[];
  onChange?: (value: T) => void;
}

export const InputRadio = <T extends string | string[] | number>({
  control,
  name,
  options,
  onChange,
  direction = "column"
}: InputRadioProps<T>): JSX.Element => {
  const { field } = useController({ name, control });
  return (
    <div className={clsx(styles.container, styles[direction])}>
      {options.map(({ value, label }) => (
        <FormGroup key={label} check>
          <Label className={styles.label} check>
            <Input
              {...field}
              className={styles.circle}
              type="radio"
              value={value}
              id={label}
              checked={field.value === value}
              onChangeCapture={() =>
                onChange ? onChange(value) : field.onChange(value)
              }
            />

            <span>{" " + label}</span>
          </Label>
        </FormGroup>
      ))}
    </div>
  );
};
