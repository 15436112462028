enum UserMonitoringListMixpanelEventNames {
  MonitoringListAddCompany = "monitoring_list_click_addCompany",
  MonitoringListAddCompanyConfirmation = "monitoring_list_event_addCompanyConfirmation",
  MonitoringListRemoveCompany = "monitoring_list_click_removeCompany"
}

enum MonitoringListCompanyDataMixpanelEventNames {
  MonitoringListCompanyDataReportRequest = "monitoring_list_company_data_click_report_request",
  MonitoringListCompanyDataExport = "monitoring_list_company_data_click_export_data"
}

export const MonitoringListMixpanelEventNames = {
  ...UserMonitoringListMixpanelEventNames,
  ...MonitoringListCompanyDataMixpanelEventNames
};

export type MonitoringListMixpanelEvent =
  | UserMonitoringListMixpanelEventNames
  | MonitoringListCompanyDataMixpanelEventNames;
