import { Observable } from "rxjs";

import {
  EVENTS_API,
  MAX_PAGE_SIZE,
  PROJECT_EVENTS_API
} from "../constants/api";
import { SORT_DIRECTION } from "../enums/apiEnums";
import {
  ApiPaginatedResponse,
  CreatedResponse,
  DeletedResponse,
  SuccessResponse
} from "../models/api";
import {
  CreateEventRequest,
  Event,
  EventGetPaginatedRequest,
  EventsDuration,
  GetEventCandidatesParams,
  GetEventResponse,
  ListEventModel,
  ListEventsParams,
  NewCreateEventRequest,
  NewUpdateEventRequest,
  UpdateEventRequest
} from "../models/event";
import { User } from "../models/user";
import { restService } from "../restService";

export const EventService = {
  getEvents: (
    projectId: string,
    apiParams: EventGetPaginatedRequest
  ): Observable<ApiPaginatedResponse<Event>> =>
    restService.get<ApiPaginatedResponse<Event>>(
      PROJECT_EVENTS_API.GET_EVENTS(projectId),
      apiParams
    ),

  getEventCandidates: ({
    projectId,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    searchTerm = "",
    orderBy = "firstName",
    orderDirection = SORT_DIRECTION.ASCENDING
  }: GetEventCandidatesParams): Observable<ApiPaginatedResponse<User>> =>
    restService.get<ApiPaginatedResponse<User>>(
      PROJECT_EVENTS_API.GET_EVENT_CANDIDATES(projectId),
      {
        searchTerm,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),

  getEventsDuration: (projectId: string): Observable<EventsDuration> =>
    restService.get<EventsDuration>(
      PROJECT_EVENTS_API.GET_EVENTS_DURATION(projectId)
    ),
  getEvent: (projectId: string, eventId: string): Observable<Event> =>
    restService.get(PROJECT_EVENTS_API.GET_EVENT(projectId, eventId)),
  deleteEvent: (
    projectId: string,
    eventId: string
  ): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      PROJECT_EVENTS_API.DELETE_EVENT(projectId, eventId)
    ),
  createEvent: (
    projectId: string,
    createEventRequest: CreateEventRequest
  ): Observable<CreatedResponse> =>
    restService.post<Event>(
      PROJECT_EVENTS_API.CREATE_EVENT(projectId),
      createEventRequest
    ),
  updateEvent: (
    projectId: string,
    eventId: string,
    updateEventRequest: UpdateEventRequest
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      PROJECT_EVENTS_API.SAVE_EVENTS(projectId, eventId),
      updateEventRequest
    ),
  listEvents: (
    projectId: string,
    params: ListEventsParams
  ): Observable<ApiPaginatedResponse<ListEventModel>> =>
    restService.get<ApiPaginatedResponse<ListEventModel>>(
      EVENTS_API.LIST_EVENTS(projectId),
      params
    ),

  // Updated services splitted from old ones and old models
  newGetEvent: (
    projectId: string,
    eventId: string
  ): Observable<GetEventResponse> =>
    restService.get(PROJECT_EVENTS_API.GET_EVENT(projectId, eventId)),
  newCreateEvent: (
    projectId: string,
    createEventRequest: NewCreateEventRequest
  ): Observable<CreatedResponse> =>
    restService.post<Event>(
      PROJECT_EVENTS_API.CREATE_EVENT(projectId),
      createEventRequest
    ),
  newUpdateEvent: (
    projectId: string,
    eventId: string,
    updateEventRequest: NewUpdateEventRequest
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      PROJECT_EVENTS_API.SAVE_EVENTS(projectId, eventId),
      updateEventRequest
    )
};
