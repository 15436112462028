import { Select, Space } from "antd";
import { DefaultOptionType, SelectValue } from "antd/es/select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  SEARCH_DEBOUNCE_TIMEOUT_COMMON,
  useDebounce
} from "@arbolus-technologies/utils";

import { AddNewItem } from "./AddNewItem";
import { LoadMore } from "./LoadMore";

export type AntDSelectValueType = SelectValue;

export interface AntDSelectMenuProps {
  options: DefaultOptionType[];
  placeholder?: string;
  optionFilterProp?: string;
  value?: SelectValue | null;
  mode?: "multiple" | "tags";
  open?: boolean;
  labelInValue?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  showLoadMoreButton?: boolean;
  addNewItem?: {
    placeholder: string;
    buttonName: string;
    onAddItem: (name: string) => void;
  };
  allowTyping?: boolean;
  optionRenderer?: (item: any) => JSX.Element;
  onChange: (value: SelectValue) => void;
  onGetItems: (query: string, offset: number) => void;
  handleClearList: () => void;
}

export const AntDSelectMenu: React.FC<AntDSelectMenuProps> = ({
  options,
  placeholder,
  optionFilterProp,
  value,
  mode,
  open,
  labelInValue = false,
  allowClear = false,
  disabled = false,
  showLoadMoreButton = false,
  addNewItem,
  allowTyping = true,
  optionRenderer,
  onChange,
  onGetItems,
  handleClearList
}) => {
  const { t } = useTranslation("antDSelectMenu");

  const [query, setQuery] = useState<string>("");

  const debouncedSearch = useDebounce(query, SEARCH_DEBOUNCE_TIMEOUT_COMMON);

  useEffect(() => {
    if (debouncedSearch) {
      onGetItems(debouncedSearch, 0);
    } else {
      handleClearList();
    }
  }, [debouncedSearch]);

  const handleOnChange = (value: SelectValue) => {
    onChange(value);
  };

  return (
    <Select
      showSearch={allowTyping}
      value={value}
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      filterOption={false}
      onSearch={(query) => {
        handleClearList();
        return setQuery(query);
      }}
      onChange={handleOnChange}
      mode={mode}
      dropdownRender={(menu) => (
        <>
          {options.length > 0 ? (
            <>
              {menu}
              {showLoadMoreButton && (
                <LoadMore
                  query={query}
                  offset={options.length}
                  onGetItems={onGetItems}
                />
              )}
            </>
          ) : (
            <Space style={{ padding: "8px " }}>{t("noOptionsMessage")}</Space>
          )}
          {addNewItem && (
            <AddNewItem
              query={query}
              placeholder={addNewItem.placeholder}
              buttonName={addNewItem.buttonName}
              onAddItem={addNewItem.onAddItem}
            />
          )}
        </>
      )}
      options={options}
      optionRender={optionRenderer}
      labelInValue={labelInValue}
      allowClear={allowClear}
      disabled={disabled}
      open={open}
      autoClearSearchValue
    />
  );
};
