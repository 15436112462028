import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const NoWorkHistory: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  return <Text type="secondary">{t("noWorkHistory")}</Text>;
};
