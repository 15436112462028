import { Flex, Result, Typography } from "antd";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { AntDButtonLink, AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { APP_TRACKING_ROUTES } from "@arbolus-technologies/models/common";
import { PublicRoutes } from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { SurveyHeader } from "../../Components/SurveyHeader/SurveyHeader";

import styles from "./SurveyLandingNotQualifiedPage.module.scss";

const { Text } = Typography;

export const SurveyLandingNotQualifiedPage: React.FC = () => {
  const { t } = useTranslation("surveyLandingNotQualifiedPage");
  const { trackRender, trackClick } = useArbolusTracking();

  useEffect(() => {
    trackRender(MixPanelEventNames.SurveyTerminatedThankYouPage);
  }, []);

  return (
    <Flex vertical className={styles.container}>
      <SurveyHeader />
      <Flex justify="center" align="center" className={styles.resultContainer}>
        <Result
          status="info"
          title={t("notQualifiedTitle")}
          className={styles.result}
          icon={
            <AntDIcon
              name="info"
              fontSize="60px"
              color={ARBOLUS_COLORS.bColorBasePurple}
              style={{ justifyContent: "center" }}
            />
          }
          subTitle={
            <Text type="secondary">
              <Trans>
                Looks like this survey is not the best fit for your expertise.
                If you are interested in future opportunities, consider creating
                an account with us. Your expertise may be a perfect match for
                upcoming projects! For any issue, please feel free to reach out
                at{" "}
                <a
                  href="mailto:survey_support@arbolus.com"
                  className={styles.supportEmailLink}
                >
                  survey_support@arbolus.com
                </a>
              </Trans>
            </Text>
          }
          extra={
            <AntDButtonLink
              type="primary"
              pathname={PublicRoutes.REGISTER}
              state={{ from: APP_TRACKING_ROUTES.SURVEY_NOT_QUALIFIED }}
              text={t("createAccount")}
              onClick={() =>
                trackClick(MixPanelEventNames.SurveyTerminatedCreateAccount)
              }
            />
          }
        />
      </Flex>
    </Flex>
  );
};
