import { Radio, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { EXPERT_DETAILS_EDIT_TABS as TABS } from "@arbolus-technologies/routes";
import { useIsMobile } from "@arbolus-technologies/utils";

const { Group } = Radio;

export function EditProfileNavigation({
  activeTab,
  handleTabChange,
  tabsRoutes
}: {
  activeTab: typeof TABS;
  handleTabChange: (value: typeof TABS) => void;
  tabsRoutes: {
    label: string;
    value: string;
  }[];
}) {
  const { t } = useTranslation("expertEditProfile");
  const isMobile = useIsMobile();

  const options = [{ label: t("details"), value: TABS.DETAILS }, ...tabsRoutes];

  if (isMobile) {
    return (
      <Select
        options={options}
        defaultValue={options[0].value as unknown as typeof TABS}
        value={activeTab}
        onChange={handleTabChange}
      />
    );
  }

  return (
    <Group
      options={options}
      value={activeTab}
      onChange={(event) => handleTabChange(event.target.value)}
      optionType="button"
      buttonStyle="solid"
    />
  );
}

// any because of type mismatch between native Location and react-router
export function getActiveTab(location: any) {
  const tabFromLocation = location.pathname.split("/").pop();
  if (tabFromLocation === "" || tabFromLocation === "profile-edit") {
    return TABS.DETAILS;
  }
  return tabFromLocation;
}
