import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { DateString } from "@arbolus-technologies/models/common";
import { ExpertDiscoverFilters } from "@arbolus-technologies/models/project";
import { ARBOLUS_COLORS, YELLOW } from "@arbolus-technologies/theme";
import {
  EXPERT_DISCOVER_TIME_FORMAT,
  formatDate,
  highlightWords
} from "@arbolus-technologies/utils";

import { DangerouslySetInnerHTML } from "../DangerouslySetInnerHTML/DangerouslySetInnerHTML";

import styles from "./WorkHistory.module.scss";

interface WorkHistoryItemProps {
  title: string | null;
  from: DateString;
  to: DateString | null;
  companyName?: string | null;
  countryName?: string | null;
  industryId?: string | null;
  industryName?: string | null;
  discoverFilters?: ExpertDiscoverFilters;
  keywordsList?: string[] | null;
  highlightWorkHistoryTitle?: boolean;
  highlightWorkHistoryCompany?: boolean;
}

const { Text, Paragraph } = Typography;

export const WorkHistoryItem: React.FC<WorkHistoryItemProps> = ({
  title,
  from,
  to,
  companyName,
  countryName,
  industryId,
  industryName,
  discoverFilters,
  keywordsList,
  highlightWorkHistoryCompany = false,
  highlightWorkHistoryTitle = false
}) => {
  const { t } = useTranslation("workHistory");

  const fromDateFormatted = formatDate(from, EXPERT_DISCOVER_TIME_FORMAT);
  const toDateFormatted = to
    ? formatDate(to, EXPERT_DISCOVER_TIME_FORMAT)
    : t("present");

  const companies = discoverFilters?.company.map((company) => company.label);
  const locations = discoverFilters?.workHistoryLocations.map(
    (location) => location.label
  );
  const isMatchingIndustry = Boolean(
    discoverFilters?.industries.find((filter) => filter.value === industryId)
  );

  const companyTags = (): string[] => {
    let companyTagsArray: string[] = [];
    if (companies) {
      companyTagsArray = [...companies];
    }
    if (highlightWorkHistoryCompany && keywordsList) {
      companyTagsArray = [...companyTagsArray, ...keywordsList];
    }
    return companyTagsArray;
  };

  const highlightedTitle = highlightWords({
    text: title && title.length > 0 ? title : t("positionNotAvailable"),
    tags: highlightWorkHistoryTitle && keywordsList ? [...keywordsList] : [],
    highlightColor: YELLOW,
    boldedWords: true
  });

  const highlightedCompany = highlightWords({
    text:
      companyName && companyName.length > 0
        ? companyName
        : t("companyNotAvailable"),
    tags: companyTags(),
    highlightColor: YELLOW,
    boldedWords: true
  });

  const highlightedLocation = highlightWords({
    text: countryName ?? t("countryNotAvailable"),
    tags: locations ? [...locations] : [],
    highlightColor: YELLOW,
    boldedWords: true
  });

  return (
    <div className={styles.workHistoryDetails}>
      <Paragraph ellipsis={{ rows: 2 }} className={styles.text}>
        <DangerouslySetInnerHTML text={highlightedTitle} />
        <Text className={styles.spacing}>·</Text>
        <DangerouslySetInnerHTML text={highlightedCompany} />
        {industryName && (
          <AntDIcon
            name="factory"
            color={ARBOLUS_COLORS.bColorSecondary}
            tooltip={industryName}
            className={styles.industryIcon}
            style={isMatchingIndustry ? { backgroundColor: YELLOW } : undefined}
          />
        )}
        <Text className={styles.spacing}>·</Text>
        <Text type="secondary">
          {`${fromDateFormatted} - ${toDateFormatted}`}
        </Text>
        {highlightedLocation && (
          <>
            <Text className={styles.spacing}>·</Text>
            <DangerouslySetInnerHTML
              text={highlightedLocation}
              color={ARBOLUS_COLORS.bColorSecondary}
              title={countryName ?? undefined}
            />
          </>
        )}
      </Paragraph>
    </div>
  );
};
