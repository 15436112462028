import {
  DefaultToasterService,
  MonitoringListService
} from "@arbolus-technologies/api";
import { Alert, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  MixpanelPages,
  PageTracker,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { MonitoringListMixpanelEventNames } from "libs/features/common/src/lib/Mixpanel/Events/MonitoringList";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RemoveCompanyModal } from "../../Components/RemoveCompanyModal/RemoveCompanyModal";
import { CompanyDataTable } from "../../Modules/CompanyDataTable/CompanyDataTable";
import { CompanyData } from "../../models";
import { CompanyDataBox } from "./CompanyDataBox";

interface LocationState {
  state: {
    companyData: CompanyData;
  };
}

export function CompanyDataPage() {
  const [removeCompanyModal, setRemoveCompanyModal] = useState<boolean>(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState<string>("");

  const { t } = useTranslation("");
  const location = useLocation();
  const history = useHistory();
  useDocumentTitle("companyData", "companyData");
  const { loggedInId } = useSelector(CacheSelector.currentUserSelector());
  const { trackClick } = useArbolusTracking();

  const { state = {} } = location as LocationState;
  const { companyData } = state as LocationState["state"];
  const showBanner = companyData?.customersCount < 1;

  const handleOpenModal = (id: string) => {
    setDeleteCompanyId(id);
    setRemoveCompanyModal(true);
  };

  const handleRemoveCompany = async () => {
    try {
      const response = await MonitoringListService.deleteCompanyMonitoringList(
        loggedInId,
        {
          companyIds: [deleteCompanyId]
        }
      );
      if (response.deleted) {
        DefaultToasterService.showSuccess(
          t("userMonitoringListPage:removeCompanySuccess")
        );
        setRemoveCompanyModal(false);
        setDeleteCompanyId("");
        history.goBack();
        trackClick(
          MonitoringListMixpanelEventNames.MonitoringListRemoveCompany
        );
      } else {
        DefaultToasterService.showError(
          t("userMonitoringListPage:removeCompanyError")
        );
      }
    } catch (error) {
      DefaultToasterService.showError(error.message);
      setRemoveCompanyModal(false);
      setDeleteCompanyId("");
    }
  };

  return (
    <PageTracker page={MixpanelPages.CompanyData}>
      <MainPageLayout
        title={companyData?.vendorCompanyName}
        backLink="/monitoring-list"
        rightButtonContainer={
          <Button
            icon={<AntDIcon name="delete" />}
            onClick={() => handleOpenModal(companyData.vendorCompanyId)}
          />
        }
        stickyHeader
      >
        {showBanner && (
          <Alert
            message={t("companyData:companyDataAlertMessage")}
            type="info"
            showIcon
            closable
          />
        )}
        {companyData && <CompanyDataBox companyData={companyData} />}
        <CompanyDataTable vendorCompanyId={companyData.vendorCompanyId} />
        <RemoveCompanyModal
          isOpen={removeCompanyModal}
          setIsOpen={setRemoveCompanyModal}
          handleOk={handleRemoveCompany}
        />
      </MainPageLayout>
    </PageTracker>
  );
}
