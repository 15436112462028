import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams
} from "ag-grid-community";
import i18next from "i18next";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  PAYMENT_METHOD_TYPE,
  ProjectClientBillingItem,
  ProjectClientService,
  TransactionType
} from "@arbolus-technologies/api";
import { ProjectModel } from "@arbolus-technologies/models/common";
import {
  ArbolusGrid,
  PersonRenderer,
  fixedWidth,
  floatingFilter
} from "@arbolus-technologies/ui/components";
import {
  TRANSACTION_FORMAT,
  convertValueToCurrencyFormat,
  formatCredits,
  formatDate,
  formatDuration
} from "@arbolus-technologies/utils";

import { ProjectSpendDataSource } from "./ProjectSpendDataSource";

interface ProjectSpendTableProps {
  projectId: string;
  projectClientService?: typeof ProjectClientService;
}

const ExpertRenderer: React.FC<
  ICellRendererParams<ProjectClientBillingItem>
> = ({ data }) =>
  data?.expert ? (
    <PersonRenderer person={{ ...data.expert, fullName: data.expert.name }} />
  ) : null;

const formatAmount = ({
  data
}: ValueFormatterParams<ProjectClientBillingItem>) => {
  if (data!.paymentMethodType === PAYMENT_METHOD_TYPE.CREDIT) {
    return formatCredits(data!.amount);
  }

  return convertValueToCurrencyFormat(data!.amount, data!.currencyCode, 2);
};

const formatTransactionType = ({
  data
}: ValueFormatterParams<ProjectClientBillingItem>) => {
  const { type, isFollowUp } = data!;
  if (type !== TransactionType.Call) {
    return i18next.t(`projectSpend:transactionTypes:${type}`);
  }

  return isFollowUp
    ? i18next.t("projectSpend:transactionTypes:FollowUp")
    : i18next.t("projectSpend:transactionTypes:Call");
};

export const ProjectSpendTable: React.FC<ProjectSpendTableProps> = ({
  projectId,
  projectClientService = ProjectClientService
}) => {
  const { t } = useTranslation("projectSpend");
  const onGridReady = useCallback(
    (event: GridReadyEvent<ProjectModel>) => {
      const { api } = event;
      api.setGridOption(
        "serverSideDatasource",
        new ProjectSpendDataSource(projectClientService, projectId)
      );
      api.sizeColumnsToFit();
    },
    [projectClientService, projectId]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: false,
      sortable: false,
      suppressMovable: true,
      suppressHeaderMenuButton: true
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: "date",
        headerName: t("created"),
        valueFormatter: ({ value }) =>
          formatDate(
            typeof value === "string" ? new Date(value) : value,
            TRANSACTION_FORMAT
          ),
        ...fixedWidth(110)
      },
      {
        colId: "expertName",
        field: "expert.name",
        headerName: t("expert"),
        cellRenderer: ExpertRenderer,
        tooltipField: "expert.name",
        filter: "agTextColumnFilter",
        menuTabs: ["filterMenuTab"],
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ["contains"],
          buttons: ["reset"]
        },
        ...floatingFilter
      },
      {
        colId: "expertTitle",
        field: "expert.title",
        headerName: t("jobTitle"),
        tooltipField: "expert.title",
        flex: 1,
        filter: "agTextColumnFilter",
        menuTabs: ["filterMenuTab"],
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ["contains"],
          buttons: ["reset"]
        },
        ...floatingFilter
      },
      {
        field: "type",
        headerName: t("type"),
        valueFormatter: formatTransactionType,
        ...fixedWidth(120),
        suppressHeaderMenuButton: false,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        filterParams: {
          suppressFilterSearch: true,
          suppressMiniFilter: true,
          buttons: ["reset"],
          values: [
            TransactionType.Call,
            TransactionType.Canopy,
            TransactionType.ClientExpense,
            TransactionType.IntroFee,
            TransactionType.OfflineWork,
            TransactionType.RefundClient,
            TransactionType.Survey,
            "FollowUp"
          ],
          valueFormatter: ({ value }: { value: string }) =>
            i18next.t(`projectSpend:transactionTypes:${value}`)
        }
      },
      {
        field: "durationInMinutes",
        headerName: t("duration"),
        valueFormatter: ({ value }) => formatDuration(value),
        ...fixedWidth(115)
      },
      {
        field: "amount",
        headerName: t("amount"),
        valueFormatter: formatAmount,
        cellClass: "weight-semi-bold",
        ...fixedWidth(100)
      },
      {
        headerName: t("equivalency"),
        valueGetter: ({ data }) => {
          const { creditEquivalence } = data!;

          if (creditEquivalence) {
            return convertValueToCurrencyFormat(
              creditEquivalence.amount,
              creditEquivalence.currencyCode,
              2
            );
          } else {
            return "";
          }
        },
        cellClass: "weight-semi-bold",
        ...fixedWidth(115)
      }
    ],
    [t]
  );

  return (
    <ArbolusGrid
      rowModelType="serverSide"
      getRowId={({ data }) => data.id}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      rowSelection="multiple"
      suppressRowClickSelection
      skipHeaderOnAutoSize
      suppressContextMenu
      suppressMenuHide
      pagination
      paginationAutoPageSize
    />
  );
};
