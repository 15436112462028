import { Observable, Subject, of } from "rxjs";
import { switchMap } from "rxjs/operators";

import {
  CLIENTS_API,
  DOCUMENTS_API,
  MAX_PAGE_SIZE,
  USERS_API
} from "../constants/api";
import { ADMIN_API } from "../endpoints/admin";
import {
  ApiPaginatedRequest,
  CreatedResponse,
  DeletedResponse,
  ListResponse,
  PagedListResponse,
  SuccessResponse
} from "../models/api";
import {
  Document,
  DocumentDownloadResponse,
  DocumentStatusChangeRequest,
  ExpertDocument,
  NoteOrDoc
} from "../models/documents";
import { restService } from "../restService";

export const DocumentService = {
  downloadDocument: (
    projectId: string,
    documentId: string
  ): Observable<boolean> =>
    restService
      .get<DocumentDownloadResponse>(
        DOCUMENTS_API.GET_DOCUMENT_DOWNLOAD_URL(projectId, documentId)
      )
      .pipe(
        switchMap((response: DocumentDownloadResponse) => {
          window.open(response.downloadUrl, "_self");
          return of(true);
        })
      ),
  getDocuments: (
    projectId: string,
    params: ApiPaginatedRequest,
    searchTerm = "",
    excludeTranscripts = false
  ): Observable<PagedListResponse<Document>> =>
    restService.get<PagedListResponse<Document>>(
      DOCUMENTS_API.GET_CLIENT_DOCUMENTS(projectId),
      {
        ...params,
        searchTerm,
        eventAttachment: excludeTranscripts
      }
    ),
  getAdminDocuments: (
    projectId: string,
    params: ApiPaginatedRequest,
    excludeTranscripts = false
  ): Observable<PagedListResponse<Document>> =>
    restService.get<PagedListResponse<Document>>(
      DOCUMENTS_API.GET_ADMIN_DOCUMENTS(projectId),
      {
        ...params,
        eventAttachment: excludeTranscripts
      }
    ),
  getClientDocuments: (
    projectId: string,
    params: ApiPaginatedRequest,
    excludeTranscripts = false
  ): Observable<PagedListResponse<Document>> =>
    restService.get<PagedListResponse<Document>>(
      DOCUMENTS_API.GET_CLIENT_DOCUMENTS(projectId),
      {
        ...params,
        eventAttachment: excludeTranscripts
      }
    ),
  getExpertDocuments: (
    projectId: string,
    params: ApiPaginatedRequest
  ): Observable<PagedListResponse<Document>> =>
    restService.get<PagedListResponse<Document>>(
      DOCUMENTS_API.GET_EXPERT_DOCUMENTS(projectId),
      {
        ...params
      }
    ),
  getDocument: (projectId: string, documentId: string): Observable<Document> =>
    restService.get(DOCUMENTS_API.GET_DOCUMENT(projectId, documentId)),
  getNotesNDocs: (
    projectId: string,
    limit = MAX_PAGE_SIZE
  ): Observable<PagedListResponse<NoteOrDoc>> =>
    restService.get<PagedListResponse<NoteOrDoc>>(
      ADMIN_API.GET_PROJECT_NOTES_N_DOCS(projectId),
      { limit }
    ),
  shareDocument: (
    projectId: string,
    chatId: string,
    updateRequest: DocumentStatusChangeRequest
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      DOCUMENTS_API.CHAT_DOCUMENT_SHARE(projectId, chatId),
      updateRequest
    ),
  deleteDocument: (
    projectId: string,
    documentId: string
  ): Observable<DeletedResponse> =>
    restService.delete(DOCUMENTS_API.DELETE_DOCUMENT(projectId, documentId)),
  renameDocument: (
    projectId: string,
    documentId: string,
    fileName: string
  ): Observable<SuccessResponse> =>
    restService.put(DOCUMENTS_API.RENAME_DOCUMENT_URL(projectId, documentId), {
      fileName
    }),
  getAdminDocumentStatus: (
    projectId: string,
    documentId: string
  ): Observable<ListResponse<ExpertDocument>> =>
    restService.get<ListResponse<ExpertDocument>>(
      ADMIN_API.GET_ADMIN_DOCUMENT_STATUS(projectId, documentId)
    ),
  uploadFile: (
    endpointURL: string,
    file: FormData,
    uploadSubject?: Subject<number>
  ): Observable<Document> =>
    restService.post(endpointURL, file, undefined, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (uploadSubject) {
          uploadSubject.next(percentCompleted);
        } else {
          of(0);
        }
      }
    }),
  uploadClientLogo: (clientId: string, file: FormData): Observable<Document> =>
    uploadFile(CLIENTS_API.LOGO_SAVE(clientId), file),
  uploadUserProfileImage: (
    userId: string,
    file: FormData
  ): Observable<Document> =>
    uploadFile(USERS_API.ADD_USER_PROFILE_IMAGE(userId), file),
  uploadAdminProjectDocument: (
    projectId: string,
    file: FormData,
    uploadSubject?: Subject<number>
  ): Observable<Document> =>
    uploadFile(
      DOCUMENTS_API.CREATE_ADMIN_DOCUMENT(projectId),
      file,
      uploadSubject
    ),
  uploadClientProjectDocument: (
    projectId: string,
    file: FormData,
    uploadSubject?: Subject<number>
  ): Observable<Document> =>
    uploadFile(
      DOCUMENTS_API.CREATE_CLIENT_DOCUMENT(projectId),
      file,
      uploadSubject
    ),
  uploadExpertProjectDocument: (
    projectId: string,
    file: FormData,
    uploadSubject?: Subject<number>
  ): Observable<Document> =>
    uploadFile(
      DOCUMENTS_API.CREATE_EXPERT_DOCUMENT(projectId),
      file,
      uploadSubject
    )
};

const uploadFile = (
  endpointURL: string,
  file: FormData,
  uploadSubject?: Subject<number>
): Observable<Document> =>
  restService.post(endpointURL, file, undefined, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (uploadSubject) {
        uploadSubject.next(percentCompleted);
      } else {
        of(0);
      }
    }
  });
