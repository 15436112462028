import { CustomerSurveyCompletionStatus } from "@arbolus-technologies/models/common";
import { ICellRendererParams } from "ag-grid-community";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";

export const StatusCellRenderer: React.FC<ICellRendererParams> = ({ data }) => {
  const { t } = useTranslation("customerSurveyTable");

  if (!data?.completionStatus) {
    return null;
  }

  if (
    data?.completionStatus === CustomerSurveyCompletionStatus.PENDING_APPROVAL
  ) {
    return <Tag color="success">{t("newAnswers")}</Tag>;
  }

  return <span>- -</span>;
};

export default StatusCellRenderer;
