import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import React from "react";

import { AntDSelectValueType } from "@arbolus-technologies/antDComponents";
import { User } from "@arbolus-technologies/api";

import { ManagerSelector } from "./ManagerSelector";

export const ManagersSelectorList: React.FC = () => {
  const form = Form.useFormInstance();
  const { managers = [] } = Form.useWatch([], form) ?? {};

  const handleOnChangeManagers = (
    values: AntDSelectValueType,
    searchedManagers: User[]
  ) => {
    const selectedManagers = getSelectedManagers(
      values,
      managers,
      searchedManagers
    );
    form.setFieldsValue({
      ["managers"]: selectedManagers.map((manager) => ({
        firstName: manager.firstName,
        lastName: manager.lastName,
        id: manager.id
      }))
    });
  };

  const getSelectedManagers = (
    values: AntDSelectValueType,
    selectedManagers: User[],
    searchedManagers: User[]
  ) => {
    const updatedManagers: User[] = [];
    (values as LabeledValue[]).forEach((manager) => {
      const selectedManager =
        selectedManagers.find((item) => item.id === manager.value) ??
        searchedManagers.find((item) => item.id === manager.value);
      if (selectedManager) {
        updatedManagers.push(selectedManager);
      }
    });

    return updatedManagers;
  };

  return (
    <ManagerSelector
      mode="multiple"
      onChange={handleOnChangeManagers}
      initialValue={managers.map((manager: User) => {
        return {
          label: `${manager.firstName} ${manager.lastName}`,
          value: manager.id,
          key: manager.id
        };
      })}
    />
  );
};
