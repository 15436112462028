import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ICellRendererParams } from "ag-grid-community";
import { Button } from "antd";
import React from "react";

export interface ActionRendererProps extends Partial<ICellRendererParams> {
  onClickAction: (id: string) => void;
}

export const ActionRenderer: React.FC<ActionRendererProps> = ({
  onClickAction,
  data
}) => {
  return (
    <Button
      type="default"
      size="small"
      onClick={() => onClickAction(data.id)}
      icon={<AntDIcon name="delete" />}
    />
  );
};
