import React from "react";
import { useTranslation } from "react-i18next";

import { Referral, Slot } from "@arbolus-technologies/models/common";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import { SlotsActionButtons } from "./SlotsActionButtons";
import { SlotsByDay } from "./SlotsByDay";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ClipboardService } from "@arbolus-technologies/api";
import { Flex } from "antd";
import { DefaultFormatDateService } from "../../Services/Referrals/FormatDateService";
import styles from "./ReferralSlotsDetail.module.scss";
import { formatSlotMoment, groupSlotsByDay } from "./ReferralSlotsDetailHelper";

interface ReferralSlotsDetailProps {
  referral: Referral;
  slots: Slot[];
  context: ReferralGridContext;
}

export const ReferralSlotsDetail: React.FC<ReferralSlotsDetailProps> = ({
  referral,
  slots,
  context
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "availability" });
  const slotsByDay = React.useMemo(
    () => groupSlotsByDay(slots, context.timezone),
    [slots, context.timezone]
  );

  const copyAll = () => {
    const allCopyStringArr = slotsByDay.flatMap((slotDat) => {
      const dayOfDate = DefaultFormatDateService.formatDayMoment(
        slotDat[0].startTime
      );
      return slotDat.map((slot) => `${dayOfDate}: ${formatSlotMoment(slot)}`);
    });

    ClipboardService.copyToClipboard(allCopyStringArr.join("\n"));
  };

  return (
    <div className={styles.container}>
      {slots.length > 0 && (
        <Flex gap={8} style={{ marginBottom: "8px" }}>
          <span>{t("allTimeSlots")}</span>
          <AntDIcon name="copy" onClick={copyAll} tooltip={t("copy")} />
        </Flex>
      )}
      <SlotsByDay referral={referral} slots={slots} {...context} />
      <div className="d-flex align-items-center">
        {!slots.length && (
          <span className={styles.noSlots}>{t("noSlots")}</span>
        )}
        <div className="ml-auto flex-row-16">
          <SlotsActionButtons referral={referral} {...context} />
        </div>
      </div>
      {/* border from table is eating padding and this nasty stuff works ¯\(°_o)/¯ */}
      <div style={{ height: "4px" }} />
    </div>
  );
};
