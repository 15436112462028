import i18next from "i18next";
import { Epic, ofType } from "redux-observable";
import { Observable, forkJoin, of } from "rxjs";
import {
  catchError,
  filter,
  flatMap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap
} from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  ApiError,
  CIQError,
  CompleteValidatorErrors,
  CreatedResponse,
  ErrorResponse,
  somethingWentWrongLabel
} from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import {
  CANOPY_CHANGE_STATUS,
  CREATE_CANOPY,
  CREATE_CANOPY_QUESTION,
  CREATE_CANOPY_SUCCESS,
  CreateCanopyQuestionAction,
  DELETE_CANOPY,
  DELETE_CANOPY_QUESTION,
  DeleteCanopyQuestionAction,
  EDIT_CANOPY,
  EDIT_CANOPY_COMPLETED,
  EDIT_CANOPY_QUESTION,
  EDIT_CANOPY_SUCCESS,
  EXIT_FROM_CREATE_CANOPY,
  EditCanopyQuestionAction,
  GET_CANOPY_CLIENT,
  GET_CANOPY_CLIENT_QUESTIONS,
  GET_PUBLIC_CANOPY,
  GET_QUESTION,
  GetQuestionAction,
  NOTIFY_EDIT_CANOPY,
  REDIRECT_TO_CANOPY,
  REDIRECT_TO_PROJECT
} from "../actions/CanopyBuilderActionTypes";
import { CanopyBuilderStoreActions } from "../actions/CanopyBuilderActions";
import {
  CanopyBuilderAppState,
  CanopyBuilderStoreAction,
  CanopyBuilderStoreDependencies,
  ICanopyService
} from "../models/definitions";

const getCanopyByClient: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_CLIENT)),
    switchMap(({ payload: { canopyId } }) =>
      canopyService.getCanopy(canopyId).pipe(
        mergeMap((canopy) =>
          of(
            CanopyBuilderStoreActions.getCanopyClientSuccess(canopy),
            CanopyBuilderStoreActions.getCanopyClientQuestions(canopyId)
          )
        ),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(CanopyBuilderStoreActions.getCanopyClientFailure(error));
        })
      )
    )
  );

const getPublicCanopy: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, routing, history }) =>
  action$.pipe(
    filter(isOfType(GET_PUBLIC_CANOPY)),
    switchMap(({ payload: { canopyId } }) =>
      canopyService.getPublicCanopyApi(canopyId).pipe(
        map((canopy) =>
          CanopyBuilderStoreActions.getPublicCanopySuccess(canopy)
        ),
        catchError((error) => {
          if (error.status === "1190") {
            history.replace(routing.canopyPausedPublic());
          }
          return of(CanopyBuilderStoreActions.getPublicCanopyFailure(error));
        })
      )
    )
  );

const deleteCanopy: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(DELETE_CANOPY)),
    switchMap(({ payload: { canopyData, projectId } }) =>
      canopyService.deleteCanopy(canopyData).pipe(
        mergeMap((canopyDeletedResponse) =>
          of(
            CanopyBuilderStoreActions.deleteCanopySuccess(
              canopyDeletedResponse.id
            ),
            CanopyBuilderStoreActions.redirectToProject(projectId)
          )
        ),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(CanopyBuilderStoreActions.deleteCanopyFailure(error));
        })
      )
    )
  );

const redirectToProject: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState
> = (action$, _, { history, notificationService, routing }) =>
  action$.pipe(
    filter(isOfType(REDIRECT_TO_PROJECT)),
    tap(({ payload: { projectId } }) =>
      history.replace(routing.projectRoute(projectId))
    ),
    mergeMap(() => {
      notificationService.showSuccess(
        i18next.t("canopy:canopyDeletedSuccessfully")
      );
      return of(CanopyBuilderStoreActions.exitFromCreateCanopy());
    })
  );

const createCanopy: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(CREATE_CANOPY)),
    switchMap(({ payload: { canopyDataRequest, canopyDocuments } }) =>
      canopyService.createCanopy(canopyDataRequest).pipe(
        mergeMap(({ id }: CreatedResponse) =>
          of(CanopyBuilderStoreActions.createCanopySuccess(id, canopyDocuments))
        ),
        catchError((error: CompleteValidatorErrors) => {
          const canopyError = new ApiError(error);
          notificationService.showError(canopyError.errorMessages.join("\n"));
          return of(CanopyBuilderStoreActions.createCanopyFailure(error));
        })
      )
    )
  );

const uploadDocuments = (
  type: string,
  canopyId: string,
  canopyDocuments: FormData[] = [],
  documentsToRemove: string[] = [],
  canopyService: ICanopyService
) => {
  const observableBatch: Observable<CreatedResponse>[] = [];
  canopyDocuments.forEach((document: FormData) =>
    observableBatch.push(
      canopyService.uploadDocumentToCanopy(canopyId, document)
    )
  );
  if (type === EDIT_CANOPY_SUCCESS) {
    documentsToRemove.forEach((documentId: string) =>
      observableBatch.push(
        canopyService.removeDocumentFromCanopy({ documentId })
      )
    );
  }
  return observableBatch;
};

const uploadDocumentsToCanopy: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(CREATE_CANOPY_SUCCESS)),
    switchMap(({ payload: { canopyId, canopyDocuments = [] } }) => {
      if (canopyDocuments.length === 0) {
        return of(CanopyBuilderStoreActions.redirectToCanopy(canopyId, true));
      }
      const observableBatch = uploadDocuments(
        CREATE_CANOPY_SUCCESS,
        canopyId,
        canopyDocuments,
        [],
        canopyService
      );
      return forkJoin(observableBatch).pipe(
        map(() => CanopyBuilderStoreActions.redirectToCanopy(canopyId, true)),
        takeUntil(action$.pipe(ofType(EXIT_FROM_CREATE_CANOPY))),
        catchError((error: CompleteValidatorErrors) => {
          const canopyError = new ApiError(error);
          notificationService.showError(canopyError.errorMessages.join("\n"));
          return of(
            CanopyBuilderStoreActions.redirectToCanopy(canopyId, false)
          );
        })
      );
    })
  );

const redirectToCanopy: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState
> = (action$, _, { history, notificationService, routing }) =>
  action$.pipe(
    filter(isOfType(REDIRECT_TO_CANOPY)),
    tap(({ payload: { canopyId } }) =>
      history.push(routing.canopyMainPageRoute(canopyId))
    ),
    mergeMap(({ payload: { uploadSuccess } }) => {
      if (uploadSuccess === true) {
        notificationService.showSuccess(
          i18next.t("canopy:canopyCreatedSuccessfully")
        );
      } else {
        notificationService.showError(
          i18next.t("canopy:canopyCreatedWithErrors")
        );
      }

      return of(CanopyBuilderStoreActions.exitFromCreateCanopy());
    })
  );

const notifyEditCanopy: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { notificationService }) =>
  action$.pipe(
    filter(isOfType(NOTIFY_EDIT_CANOPY)),
    mergeMap(({ payload: { canopyId } }) => {
      notificationService.showSuccess(
        i18next.t("canopy:canopyEditedSuccessfully")
      );
      return of(CanopyBuilderStoreActions.getCanopyClient(canopyId));
    })
  );

const editCanopy: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, state$, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(EDIT_CANOPY)),
    switchMap(
      ({
        payload: { canopyDataRequest, canopyDocuments, documentsToRemove }
      }) =>
        canopyService.editCanopy(canopyDataRequest).pipe(
          mergeMap(() =>
            of(
              CanopyBuilderStoreActions.editCanopySuccess(
                state$.value.canopyBuilder.canopy.id,
                canopyDocuments,
                documentsToRemove
              )
            )
          ),
          catchError(
            (error: ErrorResponse<CIQError> | CompleteValidatorErrors) => {
              notificationService.showApiErrors(error);
              return of(CanopyBuilderStoreActions.editCanopyFailure(error));
            }
          )
        )
    )
  );

const uploadDocumentsToCanopyEdit: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(EDIT_CANOPY_SUCCESS)),
    switchMap(
      ({
        payload: { canopyId, canopyDocuments = [], documentsToRemove = [] }
      }) => {
        if (canopyDocuments.length === 0 && documentsToRemove.length === 0) {
          return of(CanopyBuilderStoreActions.notifyEditCanopy(canopyId));
        }
        const observableBatch: Observable<CreatedResponse>[] = uploadDocuments(
          EDIT_CANOPY_SUCCESS,
          canopyId,
          canopyDocuments,
          documentsToRemove,
          canopyService
        );
        return forkJoin(observableBatch).pipe(
          map(() => CanopyBuilderStoreActions.editCanopyCompleted(canopyId)),
          takeUntil(action$.pipe(ofType(EDIT_CANOPY_COMPLETED))),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showError(i18next.t(somethingWentWrongLabel));
            return of(
              CanopyBuilderStoreActions.uploadDocumentsToCanopyFailed()
            );
          })
        );
      }
    )
  );

const editCanopyCompleted: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { notificationService }) =>
  action$.pipe(
    filter(isOfType(EDIT_CANOPY_COMPLETED)),
    mergeMap(({ payload: { canopyId } }) => {
      notificationService.showSuccess(
        i18next.t("canopy:canopyEditedSuccessfully")
      );
      return of(CanopyBuilderStoreActions.getCanopyClient(canopyId));
    })
  );

const getCanopyClientQuestions: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_CLIENT_QUESTIONS)),
    switchMap(({ payload: { canopyId } }) =>
      canopyService.getCanopyQuestions(canopyId).pipe(
        map((questions) =>
          CanopyBuilderStoreActions.getCanopyClientQuestionsSuccess(
            questions.items
          )
        ),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(
            CanopyBuilderStoreActions.getCanopyClientQuestionsFailure(error)
          );
        })
      )
    )
  );

const changeCanopyStatus: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, history, notificationService, routing }) =>
  action$.pipe(
    filter(isOfType(CANOPY_CHANGE_STATUS)),
    switchMap(({ payload: { canopyId, status } }) =>
      canopyService.changeCanopyStatus(canopyId, status).pipe(
        flatMap(() => {
          if (status === CANOPY_STATUS.PAUSED) {
            notificationService.showSuccess(
              i18next.t("canopyLaunch:canopyPausedSuccess")
            );
            history.replace(routing.canopyDetailsPageRoute(canopyId));
            return of(CanopyBuilderStoreActions.canopyChangeStatusSuccess());
          } else {
            notificationService.showSuccess(
              i18next.t("canopyLaunch:canopyLaunchSuccess")
            );
            history.replace(routing.canopyDetailsPageRoute(canopyId));
            return of(CanopyBuilderStoreActions.canopyChangeStatusSuccess());
          }
        }),
        catchError((error: ErrorResponse<CIQError>) => {
          if (error.status === "500") {
            notificationService.showError(i18next.t(somethingWentWrongLabel));
          }
          return of(CanopyBuilderStoreActions.canopyChangeStatusFailure(error));
        })
      )
    )
  );

const createCanopyQuestions: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(CREATE_CANOPY_QUESTION)),
    switchMap(({ payload: createData }: CreateCanopyQuestionAction) =>
      canopyService.createCanopyQuestion(createData).pipe(
        flatMap((createdResponse: CreatedResponse) => {
          notificationService.showSuccess(
            i18next.t("canopy:canopyQuestionCreated")
          );
          return of(
            CanopyBuilderStoreActions.createCanopyQuestionSuccess(
              createdResponse.id,
              createData.text,
              createData.details,
              createData.type,
              createData.order
            )
          );
        }),
        catchError(
          (error: ErrorResponse<CIQError> | CompleteValidatorErrors) => {
            notificationService.showApiErrors(error);
            return of(
              CanopyBuilderStoreActions.createCanopyQuestionFailure(error)
            );
          }
        )
      )
    )
  );

const getQuestion: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService }) =>
  action$.pipe(
    filter(isOfType(GET_QUESTION)),
    switchMap(({ payload: { questionId } }: GetQuestionAction) =>
      canopyService.getQuestionFromApi(questionId).pipe(
        map((res) => CanopyBuilderStoreActions.getQuestionSuccess(res)),
        catchError((error: ErrorResponse<CIQError>) =>
          of(CanopyBuilderStoreActions.getQuestionFailure(error))
        )
      )
    )
  );

const updateCanopyQuestion: Epic<
  CanopyBuilderStoreAction,
  CanopyBuilderStoreAction,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(EDIT_CANOPY_QUESTION)),
    switchMap(({ payload: editData }: EditCanopyQuestionAction) =>
      canopyService.editCanopyQuestion(editData).pipe(
        flatMap(() => {
          notificationService.showSuccess(
            i18next.t("canopy:canopyQuestionUpdated")
          );
          return of(
            CanopyBuilderStoreActions.editCanopyQuestionSuccess(
              editData.text,
              editData.questionId || "",
              editData.details,
              editData.type
            )
          );
        }),
        catchError((error: ErrorResponse<CIQError>) =>
          of(CanopyBuilderStoreActions.editCanopyQuestionFailure(error))
        )
      )
    )
  );

const deleteCanopyQuestion: Epic<
  CanopyBuilderStoreAction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  CanopyBuilderAppState,
  CanopyBuilderStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(DELETE_CANOPY_QUESTION)),
    switchMap(({ payload: { questionId } }: DeleteCanopyQuestionAction) =>
      canopyService.deleteCanopyQuestion(questionId).pipe(
        flatMap(() => {
          notificationService.showSuccess(
            i18next.t("canopy:canopyQuestionDeleted")
          );

          return of(
            CanopyBuilderStoreActions.deleteCanopyQuestionSuccess(questionId),
            PanelStoreActions.closePanel(PanelId.EditCanopyQuestion)
          );
        }),
        catchError((error: ErrorResponse<CIQError>) =>
          of(CanopyBuilderStoreActions.deleteCanopyQuestionFailure(error))
        )
      )
    )
  );

export const CanopyBuilderEpics = [
  getCanopyByClient,
  getPublicCanopy,
  deleteCanopy,
  createCanopy,
  editCanopy,
  getCanopyClientQuestions,
  createCanopyQuestions,
  getQuestion,
  uploadDocumentsToCanopy,
  changeCanopyStatus,
  redirectToCanopy,
  redirectToProject,
  notifyEditCanopy,
  uploadDocumentsToCanopyEdit,
  editCanopyCompleted,
  updateCanopyQuestion,
  deleteCanopyQuestion
];
