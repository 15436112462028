import { push } from "connected-react-router";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { PublicRouteConstants } from "../../../../../constants";
import { RegisterStages } from "../../../../../constants/auth";
import { AppAction } from "../../../../../store/actions";
import { OnboardSelectionBody } from "./OnboardSelectionBody";

interface OnboardSelectionProps extends WithTranslation {
  from?: string;
  onOptionSelect: (newStage: RegisterStages) => void;
  navigateToLogin: () => void;
}

class OnboardSelection extends React.PureComponent<OnboardSelectionProps, {}> {
  static defaultProps = {
    navigateToLogin: (): void => {}
  };

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("register");
  }

  render(): JSX.Element {
    const { navigateToLogin, onOptionSelect, from } = this.props;

    return (
      <OnboardSelectionBody
        navigateToLogin={navigateToLogin}
        onOptionSelect={onOptionSelect}
        from={from}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToLogin: (): void => {
    const currentQueryString = window.location.search;
    dispatch(
      push({
        pathname: PublicRouteConstants.LOGIN,
        search: currentQueryString
      })
    );
  }
});

export default withTranslation("register")(
  connect(null, mapDispatchToProps)(OnboardSelection)
);
