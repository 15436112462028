import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { Drawer } from "@arbolus-technologies/ui/components";

import { FilterTitle } from "./FilterTitle/FilterTitle";

import styles from "./FilterWrapper.module.scss";

export const FilterWrapper: React.FC<{
  title: string;
  tooltipText?: string;
  canClearFilter?: boolean;
  onClear?: () => void;
  children: React.ReactNode;
  filterCounter: number;
  isCollapsible?: boolean;
  isByDefaultOpen?: boolean;
}> = ({
  title,
  tooltipText,
  canClearFilter,
  onClear,
  children,
  filterCounter,
  isCollapsible = true,
  isByDefaultOpen
}) => {
  const { t } = useTranslation("discoverPage");

  return (
    <Drawer
      openByDefault={!isCollapsible || isByDefaultOpen}
      titleChildren={() => (
        <FilterTitle
          title={title}
          tooltipText={tooltipText}
          filterCounter={filterCounter}
        />
      )}
      padding="16px"
    >
      <div className={styles.children}>{children}</div>
      {canClearFilter && (
        <Button type="tertiary" onClick={onClear} text={t("clearFilter")} />
      )}
    </Drawer>
  );
};
