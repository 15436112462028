import { Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";

import {
  CIQError,
  Compliance as ComplianceType,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  ProjectAnswer,
  ProjectAnswersResponse,
  ProjectService,
  ReferralDetail,
  ToasterService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { Loader } from "@arbolus-technologies/ui/components";

import {
  DefaultRejectFeedbackExpert,
  RejectFeedbackExpert
} from "../../../../Components/RejectFeedback/RejectFeedbackExpert";
import { REFERRAL_EXPERT_TABS, REFERRAL_EXPERT_TABS_TYPE } from "../../utils";
import { ExpertInfo } from "../ExpertInfo/ExpertInfo";
import { TabsSection } from "./TabsSection/TabsSection";
import { Warnings } from "./Warnings/Warnings";

import styles from "./ReferralsTableExpertProfile.module.scss";

interface ReferralsTableExpertProfileProps {
  expertId: string;
  projectId: string;
  referralId: string;
  onReferralRemoval?: (referralId: string) => void;
  showProfileOnly?: boolean;
  expertService?: typeof ExpertService;
  projectService?: typeof ProjectService;
  feedbackExpert?: RejectFeedbackExpert;
  notificationService?: ToasterService;
}

export const ReferralsTableExpertProfile: React.FC<
  ReferralsTableExpertProfileProps
> = ({
  expertId,
  projectId,
  referralId,
  showProfileOnly = false,
  onReferralRemoval,
  expertService = ExpertService,
  projectService = ProjectService,
  feedbackExpert = DefaultRejectFeedbackExpert,
  notificationService = DefaultToasterService
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [expertInfo, setExpertInfo] = useState<ExpertDetail | null>(null);
  const [referral, setReferral] = useState<ReferralDetail | null>(null);
  const [screeningInfo, setScreeningInfo] = useState<ProjectAnswer[]>([]);
  const [complianceAnswers, setComplianceAnswers] = useState<ComplianceType[]>(
    []
  );
  const [editNote, setEditNote] = useState(false);
  const [activeTab, setActiveTab] = useState<REFERRAL_EXPERT_TABS_TYPE>(
    REFERRAL_EXPERT_TABS.PROFILE
  );
  const [isVerified, setIsVerified] = useState(false);

  const clientId = useSelector(ProjectNxSelector.projectClientId());
  const currentUser = useSelector(CacheSelector.userAdminProfile());

  // biome-ignore lint/correctness/useExhaustiveDependencies: This effect should only run once as we don't need to add dependencies here.
  useEffect(() => {
    if (showProfileOnly) {
      getExpertInfo();
    } else {
      const promises = [
        expertService.getExpertById({
          expertId,
          clientId,
          projectId,
          includeRateCard: true
        }),
        projectService.getReferral(projectId, referralId),
        expertService
          .getAnswers(expertId, projectId)
          .pipe(catchError(() => of(null))),
        expertService.getComplianceAnswers(expertId, projectId)
      ];

      setIsLoading(true);
      forkJoin(promises).subscribe(
        ([expert, referral, screeningInfo, complianceAnswers]) => {
          const expertDetail = expert as ExpertDetail;
          setExpertInfo(expertDetail);
          setIsVerified(expertDetail.isVerified);

          setReferral(referral as ReferralDetail);

          screeningInfo
            ? setScreeningInfo((screeningInfo as ProjectAnswersResponse).items)
            : setScreeningInfo([]);

          setComplianceAnswers(
            (complianceAnswers as { items: ComplianceType[] }).items
          );

          setIsLoading(false);
        },
        (err: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(err);
          setIsLoading(false);
        }
      );
    }
  }, []);

  const getExpertInfo = () => {
    expertService
      .getExpertById({
        expertId
      })
      .subscribe(
        (expert: ExpertDetail) => {
          setExpertInfo(expert);
          setIsLoading(false);
        },
        (err: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(err);
          setIsLoading(false);
        }
      );
  };

  const handleCloseSidePanel = () => {
    dispatch(PanelStoreActions.closePanel(PanelId.ReferralExpertPanel));
  };

  if (isLoading) {
    return <Loader isFull />;
  }

  if (!expertInfo) {
    return null;
  }

  const handleRejectExpert = (): void => {
    handleCloseSidePanel();

    feedbackExpert.setData({
      projectId,
      referralId,
      expert: {
        ...expertInfo.user,
        id: "",
        expertId,
        doNotContactStatus: expertInfo.doNotContactStatus
      }
    });
    dispatch(PanelStoreActions.openPanel(PanelId.RejectFeedback));
  };

  const handleSaveNote = (newNote?: string): void => {
    if (!referral) {
      return;
    }

    setReferral({
      ...referral,
      note: {
        ...referral.note,
        // @ts-ignore
        note: newNote,
        noteUser: currentUser,
        noteDate: new Date().toISOString()
      }
    });
    setEditNote(false);
  };

  const handleUpdateScreeningAnswers = (answers: ProjectAnswer[]): void => {
    setScreeningInfo(answers);
  };

  const handleUpdateComplianceAnswers = (answers: ComplianceType[]): void => {
    setComplianceAnswers(answers);
  };

  const handleMoveToAvailabilityTab = () => {
    setActiveTab(REFERRAL_EXPERT_TABS.SCHEDULING);
  };

  return (
    <Flex vertical gap={24} className={styles.container}>
      <ExpertInfo
        expert={expertInfo}
        referral={referral}
        isVerified={isVerified}
        projectId={projectId}
        showProfileOnly={showProfileOnly}
        onReferralRemoval={onReferralRemoval}
        onEditNote={() => {
          setActiveTab(REFERRAL_EXPERT_TABS.PROFILE);
          setEditNote(true);
        }}
        onRejectExpert={handleRejectExpert}
        onMoveToAvailabilityTab={handleMoveToAvailabilityTab}
      />

      <Warnings
        expertInfo={expertInfo}
        status={referral?.status}
        review={referral?.review}
        showProfileOnly={showProfileOnly}
      />

      <TabsSection
        expertInfo={expertInfo}
        screeningInfo={screeningInfo}
        complianceAnswers={complianceAnswers}
        referral={referral}
        activeTab={activeTab}
        showProfileOnly={showProfileOnly}
        editNote={editNote}
        setEditNote={setEditNote}
        handleUpdateScreeningAnswers={handleUpdateScreeningAnswers}
        handleUpdateComplianceAnswers={handleUpdateComplianceAnswers}
        handleSaveNote={handleSaveNote}
        handleSetActiveTab={setActiveTab}
        onIsVerified={setIsVerified}
      />
    </Flex>
  );
};
