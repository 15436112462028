import { ICellRendererParams } from "ag-grid-community";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

export interface LastUpdateProps
  extends Partial<ICellRendererParams<{ lastUpdate: string }>> {}

export function LastUpdateRenderer({ data }: LastUpdateProps) {
  const { t } = useTranslation("userMonitoringListPage");

  if (!data?.lastUpdate) {
    return <Typography.Text type="secondary">-</Typography.Text>;
  }

  return <Typography.Text type="secondary">{data.lastUpdate}</Typography.Text>;
}
