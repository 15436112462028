import { Card, Flex, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { MAX_PAGE_SIZE_20 } from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";

import { MoveToShortlistButton } from "./MoveToShortlist/MoveToShortlistButton";

interface ExpertListFooterProps {
  angles: AngleModel[];
  totalExpertsFound: number;
  selectedExpertsIds: string[];
  selectedCautionExperts: { id: string; name: string }[];
  isConfirmReferralsLoading: boolean;
  onShortlistConfirmed: (selectedExpertsIds: string[]) => void;
  handleSetIsConfirmReferralsLoading: (isLoading: boolean) => void;
}

export const ExpertListFooter: React.FC<ExpertListFooterProps> = ({
  angles,
  totalExpertsFound,
  selectedExpertsIds,
  selectedCautionExperts,
  isConfirmReferralsLoading,
  onShortlistConfirmed,
  handleSetIsConfirmReferralsLoading
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const currentPage = useSelector(
    ProjectNxSelector.getDiscoverExpertCurrentPage()
  );
  const pageSize = useSelector(ProjectNxSelector.getDiscoverExpertPageSize());

  return (
    <Card size="small">
      <Flex justify="space-between" align="center">
        <Pagination
          total={totalExpertsFound}
          showTotal={(total) => t("totalExperts", { count: total })}
          defaultPageSize={MAX_PAGE_SIZE_20}
          pageSize={pageSize}
          defaultCurrent={1}
          showSizeChanger
          onChange={(page, pageSize) =>
            dispatch(
              ProjectNxStoreActions.setDiscoverExpertCurrentPage(page, pageSize)
            )
          }
          current={currentPage}
        />
        {isAdmin && totalExpertsFound > 4 && (
          <MoveToShortlistButton
            disabled={selectedExpertsIds.length === 0}
            selectedExpertsIds={selectedExpertsIds}
            selectedCautionExperts={selectedCautionExperts}
            onShortlistConfirmed={onShortlistConfirmed}
            handleSetIsConfirmReferralsLoading={
              handleSetIsConfirmReferralsLoading
            }
            isConfirmReferralsLoading={isConfirmReferralsLoading}
            angles={angles}
          />
        )}
      </Flex>
    </Card>
  );
};
