/* spell-checker: disable */

import {
  profilea,
  profileb,
  profilec,
  profiled,
  profilee,
  rectanglea,
  rectangleb,
  rectanglec,
  rectangled,
  rectanglee
} from "../../../assets";

import styles from "./ImageBox.module.scss";

const ImageBox = (): JSX.Element => (
  <div className={styles.imageBoxLeftContainer}>
    <img className={styles.profilea} src={profilea} alt="" />
    <img className={styles.profileb} src={profileb} alt="" />
    <img className={styles.profilec} src={profilec} alt="" />
    <img className={styles.profiled} src={profiled} alt="" />
    <img className={styles.profilee} src={profilee} alt="" />
    <img className={styles.rectanglea} src={rectanglea} alt="" />
    <img className={styles.rectangleb} src={rectangleb} alt="" />
    <img className={styles.rectanglec} src={rectanglec} alt="" />
    <img className={styles.rectangled} src={rectangled} alt="" />
    <img className={styles.rectanglee} src={rectanglee} alt="" />
    <div className={styles.triangleBottomRight} />
  </div>
);
export default ImageBox;
