import { ClientModel } from "./Clients";
import { ConfirmedEmailExpertModel, UserModel } from "./User";
import { IEntity } from "./interfaces";
import { DateString } from "./types";

export interface AngleModel {
  id: string;
  title: string;
  color: string;
}

export interface ExpertTaglineModel extends ConfirmedEmailExpertModel {
  tagline: string;
  phoneNumber: string | null;
  lastPublicCompanyExpDate: DateString | null;
}

export interface ProjectModel extends IEntity {
  inProject: boolean;
  name: string;
  state: ProjectState;
  client: ClientModel;
  created: string;
  classification: ClassificationType;
  classificationDescription?: string;
  arbolusContact: UserModel;
}

export interface ProjectDraftModel extends IEntity {
  inProject: boolean;
  name: string;
  client: ClientModel;
  created: string;
  arbolusContact: UserModel;
}

export enum ProjectManagerRoleType {
  PrimaryLead = "PrimaryLead",
  SecondaryLead = "SecondaryLead"
}

export enum ProjectState {
  Draft = "Draft",
  Active = "Active",
  Archive = "Archive"
}

export enum STATUS_COLORS {
  active = "green",
  draft = "amber",
  archived = "grey",
  archive = "grey"
}

export enum ClassificationType {
  Other = "Other",
  Strategy = "Strategy",
  DueDiligence = "DueDiligence",
  BusinessDevelopmentProposal = "BusinessDevelopmentProposal",
  MarketResearchScoping = "MarketResearchScoping",
  Panel = "Panel",
  Survey = "Survey",
  Trial = "Trial",
  LongTermEngagement = "LongTermEngagement",
  DiscoveryEvaluation = "DiscoveryEvaluation",
  ValueCreation = "ValueCreation"
}
