export enum NAVIGATION_DIRECTION_ENUM {
  PREVIOUS = "previous",
  NEXT = "next"
}

export enum CUSTOMER_OF_FILTER_KEYS {
  PERSONA = "persona",
  SPEND = "spend",
  ADOPTION_STAGE = "adoptionStage",
  NPS = "nps",
  RENEWAL_INTENT = "renewalIntent"
}
