import { matchPath } from "react-router";
import { OutputSelector, createSelector } from "reselect";

import {
  CANOPY_DETAILS,
  PROJECT_CREATE_EVENT
} from "@arbolus-technologies/routes";

import { AuthRouteConstants } from "../../../constants";
import {
  DRAFT_PROJECT,
  EDIT_USER,
  ENDORSEMENT,
  NEW_PROJECT,
  NEW_USER,
  WELCOME
} from "../../../constants/navigation/authRoutes";
import {
  CANOPY_MAIN_PAGE,
  CANOPY_PAUSED
} from "../../../constants/navigation/canopyRoutes";
import {
  EDIT_PROJECT,
  PROJECT_EVENT_TRANSCRIPT,
  PROJECT_EXPERTS,
  PROJECT_REFER_EXPERT,
  PROJECT_TRANSCRIPTS
} from "../../../constants/navigation/projectRoutes";
import { PUBLIC_ROUTES } from "../../../constants/navigation/publicRoutes";
import { AppState } from "../../../store/reducers";

const navigationBarVisibleSelector = (): OutputSelector<
  AppState,
  boolean,
  (res: AppState) => boolean
> =>
  createSelector<AppState, AppState, boolean>(
    (state: AppState) => state,
    (appState: AppState) => {
      // collapse navbar on  full screen pages
      const isFullScreenRoutes = matchPath(appState.router.location.pathname, {
        path: [
          NEW_PROJECT,
          PROJECT_CREATE_EVENT,
          EDIT_PROJECT,
          NEW_USER,
          EDIT_USER,
          WELCOME,
          ENDORSEMENT,
          PROJECT_REFER_EXPERT,
          PROJECT_EVENT_TRANSCRIPT,
          PROJECT_TRANSCRIPTS,
          DRAFT_PROJECT,
          CANOPY_MAIN_PAGE,
          CANOPY_DETAILS,
          CANOPY_PAUSED,
          ...[PROJECT_EXPERTS]
        ]
      });

      if (isFullScreenRoutes) {
        return false;
      }

      // collapse navbar on invalid routes
      const isAppRoute = matchPath(appState.router.location.pathname, {
        path: [...AuthRouteConstants.AUTH_ROUTES, ...PUBLIC_ROUTES],
        exact: true
      });
      if (!isAppRoute) {
        return false;
      }

      return true;
    }
  );

const navigationHeaderVisibleSelector = (): OutputSelector<
  AppState,
  boolean,
  (res: AppState) => boolean
> =>
  createSelector<AppState, AppState, boolean>(
    (state: AppState) => state,
    (appState: AppState) => {
      // exclude full screen pages
      const isFullScreenRoutes = matchPath(appState.router.location.pathname, {
        path: [
          DRAFT_PROJECT,
          NEW_PROJECT,
          PROJECT_CREATE_EVENT,
          EDIT_PROJECT,
          NEW_USER,
          EDIT_USER,
          PROJECT_REFER_EXPERT,
          PROJECT_EVENT_TRANSCRIPT,
          PROJECT_TRANSCRIPTS,
          CANOPY_MAIN_PAGE,
          CANOPY_DETAILS
        ]
      });
      if (isFullScreenRoutes) {
        return false;
      }

      return true;
    }
  );

export { navigationBarVisibleSelector, navigationHeaderVisibleSelector };
