import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  AnswerMultiChoice,
  AnswerVideo,
  MixPanelEventNames,
  MixpanelPages,
  NpsTypeViewerByExpert,
  PageTracker,
  ReportExpertButton,
  ShortTextTypeViewerByExpert,
  ValueTypeViewerByExpert,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import {
  ANSWER_TYPE,
  CanopyParamUrlTypes,
  EXPERT_CANOPY_STATUS
} from "@arbolus-technologies/models/canopy";
import {
  Answer,
  COMPLIANCE_STEPS,
  ExpertProfile
} from "@arbolus-technologies/models/canopy-panels";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import {
  CANOPY_EXPERTS_ROUTE,
  CANOPY_INDIVIDUAL_RESPONSE_FROM_EXPERT_ROUTE,
  CANOPY_SINGLE_QUESTION_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import { Header } from "@arbolus-technologies/ui/canopy-panels";
import { DncInfoBox, HR, Loader } from "@arbolus-technologies/ui/components";

import { ExpertCompliance } from "./ExpertCompliance/ExpertCompliance";
import { ExpertDetailsHeader } from "./ExpertDetailsHeader";

import styles from "./ExpertDetails.module.scss";

interface ExpertDetailsProps {
  notificationService?: ToasterService;
  canopyService?: typeof CanopyService;
}

export const ExpertDetails: React.FC<ExpertDetailsProps> = ({
  notificationService = new ToasterService(),
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyV2");
  const history = useHistory();

  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.CanopyPerExpertTimeSpent
  );
  const { expertId, canopyId } = useParams<CanopyParamUrlTypes>();
  const expertName = useRef<string>("");

  const [expertAnswers, setExpertAnswers] = useState<Answer[]>([]);
  const [expert, setExpert] = useState<ExpertProfile | null>(null);
  const [playingVideoId, setPlayingVideoId] = useState<string>("");
  const [isExpertAnswersLoading, setIsExpertAnswersLoading] =
    useState<boolean>(false);

  const canopyData = useSelector(CanopyV2Selector.canopyData());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const getCanopyExpertAnswers = useCallback(() => {
    if (expertId) {
      setIsExpertAnswersLoading(true);
      canopyService.getCanopyExpertAnswers(expertId, canopyId).subscribe(
        (response) => {
          setExpertAnswers(response.answers);
          setIsExpertAnswersLoading(false);
          setExpert({ ...response.expert, status: response.status });
          expertName.current = response.expert.fullName!;
        },
        () => {
          setIsExpertAnswersLoading(false);
        }
      );
    }
  }, [canopyId, canopyService, expertId]);

  useEffect(() => {
    getCanopyExpertAnswers();
  }, [getCanopyExpertAnswers]);

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime({
        canopyId: canopyData?.id,
        canopyName: canopyData?.title,
        expertId,
        expertName: expertName.current // used ref as the expert name is not set during declaration
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShareLink = (answerId: string) => {
    notificationService.showInfo(t("copiedToClipboard"));
    if (canopyData) {
      navigator.clipboard.writeText(
        CANOPY_INDIVIDUAL_RESPONSE_FROM_EXPERT_ROUTE(canopyData.id, answerId)
      );
    }
  };

  const redirectToQuestion = (questionId: string) => {
    if (canopyData) {
      history.push(CANOPY_SINGLE_QUESTION_ROUTE(canopyData.id, questionId));
    }
  };

  const handleClickConfirm = (status: COMPLIANCE_STEPS) => {
    if (canopyData) {
      canopyService
        .setComplianceReviewStatus(
          canopyId,
          expertId,
          status === COMPLIANCE_STEPS.APPROVED,
          expertAnswers.map((expertAnswer) => expertAnswer.answerId)
        )
        .subscribe(
          () => {
            notificationService.showSuccess(t("reviewSubmittedSuccessfully"));
            const expertStatus =
              status === COMPLIANCE_STEPS.APPROVED
                ? EXPERT_CANOPY_STATUS.COMPLETE
                : EXPERT_CANOPY_STATUS.REJECTED;
            setExpert({ ...expert!, status: expertStatus });
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
          }
        );
    }
  };

  const showExpertCompliance =
    isAdmin && canopyData!.hasComplianceCheck && expert !== null;

  return (
    <PageTracker page={MixpanelPages.CanopyExpertsDeepDive}>
      <Header
        backButton={{
          onClick: () => history.push(CANOPY_EXPERTS_ROUTE(canopyData?.id!)),
          title: t("experts")
        }}
        rightButton={
          !isAdmin &&
          expert && (
            <ReportExpertButton
              expertName={expert.fullName!}
              expertStatus={expert.status!}
            />
          )
        }
      />
      <div className={styles.container}>
        {isExpertAnswersLoading && <Loader />}
        {!isExpertAnswersLoading && expert && (
          <>
            <ExpertDetailsHeader
              expert={expert}
              showExpertCompliance={showExpertCompliance}
            />
            <DncInfoBox
              doNotContactStatus={expert.doNotContactStatus}
              doNotContactStatusDescription={
                expert.doNotContactStatusDescription
              }
              doNotContactStatusCategory={expert.doNotContactStatusCategory}
              isAdmin={isAdmin}
            />
            <HR margin={{ top: 0, bottom: 0 }} />
            {showExpertCompliance && (
              <ExpertCompliance
                disabled={
                  expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
                }
                expertCanopyStatus={expert.status!}
                handleClickConfirm={handleClickConfirm}
              />
            )}

            <div className={styles.expertAnswers}>
              {expertAnswers.length === 0 && t("noAnswers")}
              {expertAnswers.map((expertAnswer) => {
                const { answerId, created, question, type } = expertAnswer;
                const { questionId, details, title, sortOrder } = question;

                return (
                  <React.Fragment key={answerId}>
                    {{
                      [ANSWER_TYPE.VIDEO]: (
                        <AnswerVideo
                          answer={{
                            id: expertAnswer.answerId,
                            transcript: expertAnswer.transcript ?? "",
                            created,
                            downloadUrl: expertAnswer.downloadUrl,
                            isSmartTranscript: !!expertAnswer.isSmartTranscript
                          }}
                          question={{
                            id: questionId,
                            text: title,
                            details,
                            sortOrder
                          }}
                          handleShareLink={handleShareLink}
                          redirectToQuestion={redirectToQuestion}
                          playingVideoId={playingVideoId}
                          setPlayingVideoId={setPlayingVideoId}
                        />
                      ),
                      [ANSWER_TYPE.MULTIPLE_CHOICE]: (
                        <AnswerMultiChoice
                          answer={{
                            id: answerId,
                            created
                          }}
                          expertId={expertId}
                          question={{
                            id: questionId,
                            text: title,
                            details,
                            sortOrder
                          }}
                          handleShareLink={handleShareLink}
                          redirectToQuestion={redirectToQuestion}
                          optionChoices={expertAnswer.optionChoices ?? []}
                        />
                      ),
                      [ANSWER_TYPE.VALUE]: (
                        <ValueTypeViewerByExpert
                          question={{
                            id: questionId,
                            text: title,
                            details,
                            sortOrder
                          }}
                          multiRatingChoices={
                            expertAnswer.multiRatingChoices ?? []
                          }
                          value={expertAnswer.value ?? null}
                          valuePreferencesType={
                            expertAnswer.valuePreferencesType!
                          }
                          valuePreferencesRange={
                            expertAnswer.valuePreferencesRange!
                          }
                          created={created}
                        />
                      ),
                      [ANSWER_TYPE.SHORT_TEXT]: (
                        <ShortTextTypeViewerByExpert
                          question={{
                            id: questionId,
                            text: title,
                            details,
                            sortOrder
                          }}
                          shortText={expertAnswer.shortText!}
                          created={expertAnswer.created}
                          redirectToQuestion={redirectToQuestion}
                        />
                      ),
                      [ANSWER_TYPE.NPS]: (
                        <NpsTypeViewerByExpert
                          question={{
                            id: questionId,
                            text: title,
                            details,
                            sortOrder
                          }}
                          npsValue={expertAnswer.npsValue!}
                          created={expertAnswer.created}
                        />
                      )
                    }[type] || <span>{t("answerTypeNotFound")}</span>}
                  </React.Fragment>
                );
              })}
            </div>
          </>
        )}
      </div>
    </PageTracker>
  );
};
