import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { TimezoneOption } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

interface TimezoneSelectorProps {
  selectedTimezone?: TimezoneOption;
  onChange: (selectedTimezone: TimezoneOption) => void;
  placeholder?: string;
}

export const TimezoneSelector: React.FC<TimezoneSelectorProps> = ({
  selectedTimezone,
  onChange,
  placeholder
}) => {
  const { t } = useTranslation("timezoneSelector");

  const timezones = useSelector(CacheSelector.timezones());

  return (
    <Select
      showSearch
      optionFilterProp="label"
      options={timezones}
      onChange={onChange}
      placeholder={placeholder ?? t("placeholder")}
      value={selectedTimezone}
      labelInValue={true}
    />
  );
};
