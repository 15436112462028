import type { CompanyBase } from "../Company";
import type { ConfirmedEmailUserModel } from "../User";
import type { DateString } from "../types";

export enum CustomerSurveyCompletionStatus {
  NO_ANSWERS = "NoAnswers",
  PENDING_APPROVAL = "PendingApproval",
  COMPLETED = "Completed"
}

export interface CustomerSurvey {
  id: string;
  title: string;
  created: DateString;
  owner: ConfirmedEmailUserModel;
  companies: CustomerSurveyCompany[];
  completionStatus: CustomerSurveyCompletionStatus;
}

export interface CustomerSurveyCompany extends CompanyBase {
  visible: boolean;
}
