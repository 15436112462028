import { Button, Checkbox, Flex, Form } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AntDSelectMenu,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  ADOPTION_STAGE_OPTIONS,
  DiscoverFilterOption,
  DiscoverFilterType,
  NPS_OPTIONS,
  PERSONA_OPTIONS,
  RENEWAL_INTENT_OPTIONS,
  SPEND_OPTIONS
} from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { useDiscoverCustomerFilter } from "../../../../Hooks/Discover/useDiscoverCustomersFilter";
import { useDiscoverFilters } from "../../../../Hooks/Discover/useDiscoverFilters";
import { FilterWrapper } from "../FilterWrapper/FilterWrapper";

import styles from "./CustomerFilters.module.scss";

interface CustomersFilterProps {
  notificationService?: ToasterService;
}

export const CustomersFilter: React.FC<CustomersFilterProps> = ({
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("customerOfFilter");
  const [form] = Form.useForm();

  const customers: DiscoverFilterOption[] =
    Form.useWatch("customers", form) ?? [];

  const { selectedOptions, setSelectedOptions } = useDiscoverFilters({
    filterType: DiscoverFilterType.Customers
  });
  const {
    getCustomersSearchResults,
    handleCustomerFiltersChange,
    handleClearFilters,
    filterOptions,
    handleUpdateList,
    urlChangeOnCustomerFiltersUpdate
  } = useDiscoverCustomerFilter();

  const customerSubFilters = useSelector(
    ProjectNxSelector.getCustomerSubFilters()
  );

  useEffect(() => {
    form.setFieldsValue({ ...customerSubFilters });
  }, [form, customerSubFilters]);

  useEffect(() => {
    form.setFieldsValue({ customers: selectedOptions });
  }, [form, selectedOptions]);

  const handleUpdateCustomers = (values: AntDSelectValueType) => {
    const customers = values as DiscoverFilterOption[];
    form.setFieldsValue({ ["customers"]: values });

    if (selectedOptions.length > 0 && customers.length === 0) {
      setSelectedOptions([]);
      urlChangeOnCustomerFiltersUpdate({
        customers: [],
        persona: [],
        spend: [],
        adoptionStage: [],
        nps: [],
        renewalIntent: []
      });
    }
  };

  const handleSelectAll = () => {
    const newSelectedOptions = filterOptions.filter(
      (option) =>
        !customers.some(
          (selectedOption) => selectedOption.value === option.value
        )
    );
    handleUpdateCustomers([...customers, ...newSelectedOptions]);
  };

  const handleClear = () => {
    if (selectedOptions.length > 0) {
      handleClearFilters();
    } else {
      form.setFieldsValue({
        customers: [],
        persona: [],
        spend: [],
        adoptionStage: [],
        nps: [],
        renewalIntent: []
      });
    }
  };

  const onFinishFailed = () => {
    notificationService.showError(t("invalidForm"));
  };

  return (
    <FilterWrapper
      title={t("customersFilterTitle")}
      filterCounter={selectedOptions.length}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={handleCustomerFiltersChange}
        onFinishFailed={onFinishFailed}
      >
        <Flex style={{ width: "100%" }} vertical>
          <Form.Item label={t("customerOf")} name="customers">
            <AntDSelectMenu
              options={filterOptions}
              placeholder={t("customersFilterPlaceholder")}
              mode="multiple"
              labelInValue
              onChange={handleUpdateCustomers}
              onGetItems={getCustomersSearchResults}
              handleClearList={() => handleUpdateList([])}
              onSelectAll={handleSelectAll}
            />
          </Form.Item>

          <Form.Item label={t("persona")} name="persona">
            <Checkbox.Group
              options={PERSONA_OPTIONS}
              className={styles.checkbox}
            />
          </Form.Item>

          <Form.Item label={t("spend")} name="spend">
            <Checkbox.Group
              options={SPEND_OPTIONS}
              className={styles.checkbox}
            />
          </Form.Item>

          <Form.Item label={t("adoptionStage")} name="adoptionStage">
            <Checkbox.Group
              options={ADOPTION_STAGE_OPTIONS}
              className={styles.checkbox}
            />
          </Form.Item>

          <Form.Item label={t("nps")} name="nps">
            <Checkbox.Group options={NPS_OPTIONS} className={styles.checkbox} />
          </Form.Item>

          <Form.Item label={t("renewalIntent")} name="renewalIntent">
            <Checkbox.Group
              options={RENEWAL_INTENT_OPTIONS}
              className={styles.checkbox}
            />
          </Form.Item>

          <Flex justify="space-between">
            <Button size="small" onClick={handleClear}>
              {t("clear")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="small"
              disabled={customers.length === 0}
            >
              {t("apply")}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </FilterWrapper>
  );
};
