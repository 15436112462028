import {
  AntDDownloadButton,
  AntDIcon
} from "@arbolus-technologies/antDComponents";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Button, Card, Flex, Popconfirm, Tag, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { convertDates, handleOnDocumentClicked } from "./CalendarAntDHelpers";
import { EventCalendarItem } from "./types";

const { Title, Text } = Typography;

interface SelectedEventCardProps {
  selectedEvent?: EventCalendarItem;
  isEventPast: boolean;
  onEditEvent: () => void;
  onJoinCall: () => void;
  onDeleteEvent: () => void;
  projectId: string;
}

const SelectedEventCard: React.FC<SelectedEventCardProps> = ({
  selectedEvent,
  isEventPast,
  onEditEvent,
  onJoinCall,
  onDeleteEvent,
  projectId
}) => {
  const { t } = useTranslation("calendarAntDPage");
  const dateConverted = () => {
    if (selectedEvent) {
      return convertDates(selectedEvent?.startTime, selectedEvent?.endTime);
    }
  };
  return (
    <Card style={{ flex: "1 1 25%" }}>
      <Flex gap="16px" vertical>
        {selectedEvent && (
          <>
            <Title level={4}>{selectedEvent?.title}</Title>
            <Flex gap={8} vertical style={{ marginBottom: "16px" }}>
              <Flex gap={8} align="center">
                <AntDIcon
                  name="calendar_today"
                  color={ARBOLUS_COLORS.colorIcon}
                />
                {dateConverted()!.date}
              </Flex>
              <Flex gap={8} align="center">
                <AntDIcon name="schedule" color={ARBOLUS_COLORS.colorIcon} />
                {dateConverted()!.hours}
              </Flex>
              <Flex gap={8} align="center">
                <AntDIcon name="schedule" color={ARBOLUS_COLORS.colorIcon} />
                {selectedEvent?.timezone.displayText}
              </Flex>
            </Flex>
            <Flex wrap gap={4} justify="space-between">
              <Popconfirm
                title={t("deleteEventMsg")}
                okText={t("delete")}
                cancelText={t("cancel")}
                onConfirm={onDeleteEvent}
                placement="left"
              >
                <Button
                  type="default"
                  disabled={isEventPast}
                  icon={<AntDIcon name="delete" />}
                />
              </Popconfirm>

              <Flex gap={8}>
                <Button
                  type="default"
                  onClick={onEditEvent}
                  disabled={isEventPast}
                >
                  {t("editEvent")}
                </Button>
                <Button
                  type="primary"
                  onClick={onJoinCall}
                  disabled={isEventPast}
                >
                  {t("joinCall")}
                </Button>
              </Flex>
            </Flex>
            <Flex wrap gap={8} vertical>
              <Text strong>{t("guests")}</Text>
              <Flex gap={4} wrap>
                {selectedEvent?.eventGuests
                  .sort((a, b) =>
                    a.userId === selectedEvent?.organizer.id ? -1 : 1
                  )
                  .map((guest) => (
                    <Tag
                      key={guest.email}
                      color={
                        guest.userId === selectedEvent?.organizer.id
                          ? "gold"
                          : "default"
                      }
                    >
                      {`${guest.user.firstName} ${guest.user.lastName}`}
                    </Tag>
                  ))}
              </Flex>
            </Flex>
            <Flex wrap gap={8} vertical>
              <Text strong>{t("transcript")}</Text>
              <Flex gap={2} vertical>
                {selectedEvent.transcribe ? (
                  <Flex align="center" gap={4}>
                    <AntDIcon
                      name="check"
                      color={ARBOLUS_COLORS.bColorAccentGreenDark}
                    />
                    {t("transcriptEnabled")}
                  </Flex>
                ) : (
                  <Flex align="center" gap={4}>
                    <AntDIcon name="close" />
                    {t("transcriptDisabled")}
                  </Flex>
                )}
                {selectedEvent.humanTranscribe ? (
                  <Flex align="center" gap={4}>
                    <AntDIcon
                      name="check"
                      color={ARBOLUS_COLORS.bColorAccentGreenDark}
                    />
                    {t("smartTranscriptEnabled")}
                  </Flex>
                ) : (
                  <Flex align="center" gap={4}>
                    <AntDIcon name="close" />
                    {t("smartTranscriptDisabled")}
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex wrap gap={8} vertical>
              <Text strong>{t("notes")}</Text>
              <Flex gap={2} wrap>
                {selectedEvent?.notes}
              </Flex>
            </Flex>
            <Flex wrap gap={8} vertical>
              <Text strong>{t("attachments")}</Text>
              <Flex gap={2} wrap>
                {selectedEvent?.eventAttachments.map((attachment) => (
                  <AntDDownloadButton
                    key={attachment.id}
                    fileName={attachment.fileName}
                    handleOnClick={() =>
                      handleOnDocumentClicked(attachment.id, projectId)
                    }
                  />
                ))}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Card>
  );
};

export default SelectedEventCard;
