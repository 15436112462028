import moment from "moment";
import { Handlers, createReducer } from "reduxsauce";

import { ReferralSummary } from "@arbolus-technologies/api";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";

import { ClientTotalSpend } from "../../../models/client";
import { EventsDuration } from "../../../models/event";
import { Member } from "../../../models/project";
import { User } from "../../../models/user";
import { StatusReferral } from "../../../models/view/candidatePicker";
import { RBServiceManager } from "../../../services";
import { AppAction } from "../../../store/actions";
import { LOGOUT_USER_SUCCESS } from "../../auth/store/actions/LoginActionTypes";
import {
  ADD_PROJECT_MEMBER,
  AddProjectMemberAction,
  COMPLETE_CANDIDATE_REVIEWAL,
  EXIT_FROM_PROJECT,
  GET_PROJECT,
  GET_PROJECT_BASE_DATA_SUCCESS,
  GET_PROJECT_CLIENT_DATA_SUCCESS,
  GET_PROJECT_FAILURE,
  GET_PROJECT_SUCCESS,
  GetProjectBaseDataSuccessAction,
  GetProjectClientDataSuccessAction,
  GetProjectSuccessAction,
  REFETCH_PROJECT_REFERRALS_DATA,
  REFETCH_PROJECT_REFERRALS_DATA_FAILURE,
  REFETCH_PROJECT_REFERRALS_DATA_SUCCESS,
  REFETCH_REFERRAL_SUMMARY,
  REFETCH_REFERRAL_SUMMARY_FAILURE,
  REFETCH_REFERRAL_SUMMARY_SUCCESS,
  REMOVE_PROJECT_MEMBER,
  RefetchReferralDataSuccessAction,
  RefetchReferralSummarySuccessAction,
  RemoveProjectMemberSuccessAction,
  UPDATE_CANDIDATE_REFERRAL_STATUS,
  UPDATE_PROJECT_EVENT_DURATION,
  UPDATE_PROJECT_STATE,
  UPDATE_PROJECT_STATE_FAILURE,
  UPDATE_PROJECT_STATE_SUCCESS,
  UpdateCandidateReferralStatusAction,
  UpdateEventDurationAction,
  UpdateProjectStateSuccessAction
} from "./actions/ProjectActionTypes";

export interface ProjectReducerState {
  isProjectLoading: boolean;
  isClientDataLoading: boolean;
  projectId: string;
  projectName?: string;
  projectState: string;
  isProjectStateUpdating: boolean;
  modified: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  projectRefreshError: any;
  members: Member[];
  totalSpend?: ClientTotalSpend;
  eventsDuration: EventsDuration;
  hasCompliance: boolean;
  hasScreeningQuestions: boolean;
  invitationUrl?: string;

  referrals: StatusReferral[];
  isReferralsLoading: boolean;
  referralSummary?: ReferralSummary;
  isReferralSummaryLoading: boolean;

  timezone?: string;

  defaultEventGuests: User[];
}

export const initialState: ProjectReducerState = {
  isProjectLoading: false,
  isClientDataLoading: false,
  projectId: "",
  projectName: "",
  modified: {} as Date,
  projectState: "",
  isProjectStateUpdating: false,
  projectRefreshError: null,
  referrals: [],
  isReferralsLoading: false,
  isReferralSummaryLoading: false,
  members: [],
  hasCompliance: false,
  hasScreeningQuestions: false,
  eventsDuration: {} as EventsDuration,
  totalSpend: undefined,
  referralSummary: undefined,
  timezone: undefined,
  defaultEventGuests: []
};

const handleGetProject = (state = initialState): ProjectReducerState => ({
  ...state,
  ...initialState,
  isProjectLoading: true,
  isClientDataLoading: RBServiceManager.valueSelector(true, false)
});

const handleGetProjectSuccess = (
  state = initialState,
  { payload }: GetProjectSuccessAction
): ProjectReducerState => ({
  ...state,
  projectName: payload.projectName,
  modified: payload.modified,
  projectState: payload.projectState,
  projectId: payload.projectId,
  hasCompliance: payload.hasCompliance,
  hasScreeningQuestions: payload.hasScreeningQuestions,
  invitationUrl: payload.invitationUrl,
  timezone: payload.timezone,
  defaultEventGuests: payload.defaultEventGuests
});

const handleGetProjectFailure = (
  state = initialState
): ProjectReducerState => ({
  ...state,
  isProjectLoading: false,
  isClientDataLoading: false
});

const handleGetProjectBaseDataSuccess = (
  state = initialState,
  { payload }: GetProjectBaseDataSuccessAction
): ProjectReducerState => {
  const { eventsDuration, members } = payload;

  return {
    ...state,
    isProjectLoading: false,
    members,
    eventsDuration
  };
};

const handleGetProjectClientDataSuccess = (
  state = initialState,
  { payload }: GetProjectClientDataSuccessAction
): ProjectReducerState => ({
  ...state,
  isClientDataLoading: false,
  referralSummary: payload.referralSummary,
  referrals: payload.referrals,
  totalSpend: payload.totalSpend
});

const handleRefetchReferralSummary = (
  state = initialState
): ProjectReducerState => ({
  ...state,
  isReferralSummaryLoading: true
});

const handleRefetchReferralSummarySuccess = (
  state = initialState,
  { payload: { referralSummary } }: RefetchReferralSummarySuccessAction
): ProjectReducerState => ({
  ...state,
  isReferralSummaryLoading: false,
  referralSummary
});

const handleRefetchReferralSummaryFailure = (
  state = initialState
): ProjectReducerState => ({
  ...state,
  isReferralSummaryLoading: false
});

const handleCandidateReferralStatus = (
  state = initialState,
  { payload }: UpdateCandidateReferralStatusAction
): ProjectReducerState => {
  const nextReferralsState = state.referrals.map((referral) => {
    if (referral.referralId === payload.referralId) {
      // eslint-disable-next-line no-param-reassign
      referral.status = payload.status;
    }
    return referral;
  });

  if (state.referralSummary) {
    let nextReferralSummary = state.referralSummary;

    if (
      state.referralSummary.review > 0 &&
      state.referralSummary.candidate > 0
    ) {
      if (payload.status === REFERRAL_STATES.REJECT) {
        nextReferralSummary = {
          ...state.referralSummary,
          review: state.referralSummary.review - 1,
          candidate: state.referralSummary.candidate - 1,
          reject: (state.referralSummary?.reject || 0) + 1
        };
      }

      if (payload.status === REFERRAL_STATES.ACCEPT) {
        nextReferralSummary = {
          ...state.referralSummary,
          review: state.referralSummary.review - 1,
          candidate: state.referralSummary.candidate - 1,
          accept: (state.referralSummary?.accept || 0) + 1
        };
      }
    }

    return {
      ...state,
      referralSummary: nextReferralSummary,
      referrals: nextReferralsState
    };
  }

  return {
    ...state
  };
};

const handleExitFromProject = (): ProjectReducerState => ({
  ...initialState
});

const handleCompleteCandidateReviewal = (
  state = initialState
): ProjectReducerState => ({
  ...state,
  referrals: [
    ...state.referrals.filter((r) => r.status === REFERRAL_STATES.CANDIDATE)
  ]
});

const handleAddMemberToProject = (
  state = initialState,
  action: AddProjectMemberAction
): ProjectReducerState => {
  const { member } = action.payload;
  const { members } = state;

  const allMembers = [...members];
  allMembers.push(member);

  return {
    ...state,
    members: allMembers
  };
};

const handleRemoveMemberFromProject = (
  state = initialState,
  action: RemoveProjectMemberSuccessAction
): ProjectReducerState => {
  const { members } = state;
  const {
    payload: { memberId }
  } = action;

  return {
    ...state,
    members: members.filter((m) => m.id !== memberId)
  };
};

const handleUpdateProjectEventDuration = (
  state = initialState,
  { payload }: UpdateEventDurationAction
): ProjectReducerState => {
  const { eventsDuration } = state;
  if (moment(payload.eventStart).isBefore(eventsDuration.start)) {
    return {
      ...state,
      eventsDuration: {
        ...eventsDuration,
        start: payload.eventStart
      }
    };
  }
  if (moment(payload.eventStart).isAfter(eventsDuration.end)) {
    return {
      ...state,
      eventsDuration: {
        ...eventsDuration,
        end: payload.eventStart
      }
    };
  }
  return {
    ...state
  };
};

const handleUpdateProjectState = (
  state = initialState
): ProjectReducerState => ({
  ...state,
  isProjectStateUpdating: true
});

const handleUpdateProjectStateSuccess = (
  state = initialState,
  { payload: { projectState } }: UpdateProjectStateSuccessAction
): ProjectReducerState => ({
  ...state,
  isProjectStateUpdating: false,
  projectState
});

const handleUpdateProjectStateFailure = (
  state = initialState
): ProjectReducerState => ({
  ...state,
  isProjectStateUpdating: false
});

const handleLogoutUserSuccess = (): ProjectReducerState => initialState;

const handleRefetchReferralsData = (
  state = initialState
): ProjectReducerState => ({
  ...state,
  isReferralsLoading: true
});

const handleRefetchReferralsDataSuccess = (
  state = initialState,
  { payload }: RefetchReferralDataSuccessAction
): ProjectReducerState => ({
  ...state,
  isReferralsLoading: false,
  referrals: payload.referrals
});

const handleRefetchReferralsDataFailure = (
  state = initialState
): ProjectReducerState => ({
  ...state,
  isReferralsLoading: false
});

export const handlers: Handlers<ProjectReducerState, AppAction> = {
  [ADD_PROJECT_MEMBER]: handleAddMemberToProject,
  [COMPLETE_CANDIDATE_REVIEWAL]: handleCompleteCandidateReviewal,
  [EXIT_FROM_PROJECT]: handleExitFromProject,
  [GET_PROJECT]: handleGetProject,
  [GET_PROJECT_BASE_DATA_SUCCESS]: handleGetProjectBaseDataSuccess,
  [GET_PROJECT_CLIENT_DATA_SUCCESS]: handleGetProjectClientDataSuccess,
  [GET_PROJECT_FAILURE]: handleGetProjectFailure,
  [GET_PROJECT_SUCCESS]: handleGetProjectSuccess,
  [LOGOUT_USER_SUCCESS]: handleLogoutUserSuccess,
  [REFETCH_PROJECT_REFERRALS_DATA]: handleRefetchReferralsData,
  [REFETCH_PROJECT_REFERRALS_DATA_SUCCESS]: handleRefetchReferralsDataSuccess,
  [REFETCH_PROJECT_REFERRALS_DATA_FAILURE]: handleRefetchReferralsDataFailure,
  [REFETCH_REFERRAL_SUMMARY]: handleRefetchReferralSummary,
  [REFETCH_REFERRAL_SUMMARY_FAILURE]: handleRefetchReferralSummaryFailure,
  [REFETCH_REFERRAL_SUMMARY_SUCCESS]: handleRefetchReferralSummarySuccess,
  [REMOVE_PROJECT_MEMBER]: handleRemoveMemberFromProject,
  [UPDATE_CANDIDATE_REFERRAL_STATUS]: handleCandidateReferralStatus,
  [UPDATE_PROJECT_EVENT_DURATION]: handleUpdateProjectEventDuration,
  [UPDATE_PROJECT_STATE]: handleUpdateProjectState,
  [UPDATE_PROJECT_STATE_FAILURE]: handleUpdateProjectStateFailure,
  [UPDATE_PROJECT_STATE_SUCCESS]: handleUpdateProjectStateSuccess
};

export default createReducer(initialState, handlers);
