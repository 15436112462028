import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ExpertDetail,
  PROJECT_REFERRAL_STATE,
  ReviewReviewStatus
} from "@arbolus-technologies/api";
import { REFERRAL_REJECT_REASON } from "@arbolus-technologies/models/project";
import { DncInfoBox } from "@arbolus-technologies/ui/components";

interface WarningsProps {
  expertInfo: ExpertDetail;
  status?: PROJECT_REFERRAL_STATE;
  review?: ReviewReviewStatus;
}

export const Warnings: React.FC<WarningsProps> = ({
  expertInfo,
  status,
  review
}) => {
  const { t } = useTranslation("referralsTableExpertProfile");

  const getExpertRejectDescription = () => {
    if (review?.rejectDescription) {
      const rejectDescription = review?.rejectDescription;

      const reason = Object.values(REFERRAL_REJECT_REASON).find(
        (reason) => reason === rejectDescription.reason
      );

      const rejectReason = t(`rejectExpert:${reason}`);
      return `${rejectReason}: ${rejectDescription.description}`;
    }
    return "";
  };

  return (
    <>
      <DncInfoBox
        doNotContactStatus={expertInfo.doNotContactStatus}
        doNotContactStatusDescription={expertInfo.doNotContactStatusDescription}
        doNotContactStatusCategory={expertInfo.doNotContactStatusCategory}
        isAdmin
      />
      {status === PROJECT_REFERRAL_STATE.REJECT && (
        <Alert
          message={t("expertRejected")}
          description={getExpertRejectDescription()}
          type="error"
          showIcon
        />
      )}
    </>
  );
};
