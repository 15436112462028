import { PaginatedResponseWithStatus } from "@arbolus-technologies/api";
import {
  CustomerSubFilters,
  DiscoverExpert,
  DiscoverFilterOption,
  DiscoverFilterType,
  ExpertDiscoverFilters,
  FilterTimeSelectorOptions
} from "@arbolus-technologies/models/project";

import { ExpertDiscover } from "../../models/definitions";
import { PREFIX } from "../ProjectActionTypes";

export const SET_DISCOVER_FILTER = `${PREFIX}_SET_DISCOVER_FILTER`;
export const SET_DISCOVER_FILTER_PRE_SELECTIONS = `${PREFIX}_SET_DISCOVER_FILTER_PRE_SELECTIONS`;
export const SET_COMPANY_FILTER_PERIOD = `${PREFIX}_SET_COMPANY_FILTER_PERIOD`;
export const SET_WORK_HISTORY_LOCATION_FILTER_PERIOD = `${PREFIX}_SET_WORK_HISTORY_LOCATION_FILTER_PERIOD`;
export const SET_INDUSTRY_FILTER_PERIOD = `${PREFIX}_SET_INDUSTRY_FILTER_PERIOD`;
export const SET_KEYWORDS_SEARCH_OPTIONS = `${PREFIX}_SET_KEYWORDS_SEARCH_OPTIONS`;
export const SET_FILTER_QUERY = `${PREFIX}_SET_FILTER_QUERY`;
export const TOGGLE_RECOMMENDATIONS = `${PREFIX}_TOGGLE_RECOMMENDATIONS`;
export const TOGGLE_DNC_EXPERTS = `${PREFIX}_TOGGLE_DNC_EXPERTS`;
export const SET_COMPANY_IN_PAST_MONTHS = `${PREFIX}_SET_COMPANY_IN_PAST_MONTHS`;

// Same action as in old project store
export const REFETCH_PROJECT_REFERRALS_DATA = `${PREFIX}_CLIENT_REFETCH_PROJECT_REFERRALS_DATA`;

export interface SetDiscoverFilterAction {
  type: typeof SET_DISCOVER_FILTER;
  payload: {
    filterType: DiscoverFilterType;
    filters: DiscoverFilterOption[];
  };
}

export interface SetDiscoverFilterPreSelectionsAction {
  type: typeof SET_DISCOVER_FILTER_PRE_SELECTIONS;
  payload: {
    filterType: DiscoverFilterType;
    filters: DiscoverFilterOption[];
  };
}

export const SET_ALL_DISCOVER_FILTERS = `${PREFIX}_SET_ALL_DISCOVER_FILTERS`;
export interface SetAllDiscoverFiltersAction {
  type: typeof SET_ALL_DISCOVER_FILTERS;
  payload: {
    filters: ExpertDiscoverFilters;
  };
}

export const SET_DISCOVER_EXPERT_ALL_FILTERS = `${PREFIX}_SET_DISCOVER_EXPERT_ALL_FILTERS`;
export interface SetDiscoverExpertAllFiltersAction {
  type: typeof SET_DISCOVER_EXPERT_ALL_FILTERS;
  payload: { expertDiscover: ExpertDiscover };
}

export const CLEAR_DISCOVER_FILTER = `${PREFIX}_CLEAR_DISCOVER_FILTER`;
export const CLEAR_KEYWORDS_SEARCH_OPTIONS = `${PREFIX}_CLEAR_KEYWORDS_SEARCH_OPTIONS`;
export interface ClearDiscoverFilterAction {
  type: typeof CLEAR_DISCOVER_FILTER;
  payload: {
    filterType: DiscoverFilterType;
  };
}

export const CLEAR_ALL_DISCOVER_FILTERS = `${PREFIX}_CLEAR_ALL_DISCOVER_FILTERS`;
export interface ClearAllDiscoverFiltersAction {
  type: typeof CLEAR_ALL_DISCOVER_FILTERS;
  payload: { displayRecommendations: boolean };
}

export interface ClearKeywordsSearchOptionsAction {
  type: typeof CLEAR_KEYWORDS_SEARCH_OPTIONS;
  payload: {};
}

export interface SetCompanyFilterPeriodAction {
  type: typeof SET_COMPANY_FILTER_PERIOD;
  payload: {
    companyFilterPeriod: FilterTimeSelectorOptions;
  };
}

export interface SetCompanyInPastMonthsAction {
  type: typeof SET_COMPANY_IN_PAST_MONTHS;
  payload: {
    companyInPastMonths: number;
  };
}

export interface SetWorkHistoryLocationFilterPeriodAction {
  type: typeof SET_WORK_HISTORY_LOCATION_FILTER_PERIOD;
  payload: {
    workHistoryLocationFilterPeriod: FilterTimeSelectorOptions;
  };
}

export interface SetIndustryFilterPeriodAction {
  type: typeof SET_INDUSTRY_FILTER_PERIOD;
  payload: {
    industryFilterPeriod: FilterTimeSelectorOptions;
  };
}

export interface SetKeywordsSearchOptionsAction {
  type: typeof SET_KEYWORDS_SEARCH_OPTIONS;
  payload: {
    keywordsSearchOptions: string[];
  };
}

export interface SetFilterQueryAction {
  type: typeof SET_FILTER_QUERY;
  payload: {
    filterType: DiscoverFilterType;
    query: string;
  };
}

export interface ToggleRecommendationsAction {
  type: typeof TOGGLE_RECOMMENDATIONS;
  payload: {
    displayRecommendations: boolean;
  };
}

export interface ToggleDncExpertsAction {
  type: typeof TOGGLE_DNC_EXPERTS;
  payload: {
    hideDncExperts: boolean;
  };
}

export interface RefetchReferralDataAction {
  type: typeof REFETCH_PROJECT_REFERRALS_DATA;
  payload: {
    projectId: string;
  };
}

export const SET_DISCOVER_EXPERT_CURRENT_PAGE = `${PREFIX}_SET_DISCOVER_EXPERT_CURRENT_PAGE`;
export interface SetDiscoverExpertCurrentPageAction {
  type: typeof SET_DISCOVER_EXPERT_CURRENT_PAGE;
  payload: {
    currentPage: number;
    pageSize: number;
  };
}

export const SET_DISCOVER_EXPERT_SEARCH_TERM = `${PREFIX}_SET_DISCOVER_EXPERT_SEARCH_TERM`;
export interface SetDiscoverExpertSearchTermAction {
  type: typeof SET_DISCOVER_EXPERT_SEARCH_TERM;
  payload: {
    searchTerm: string;
  };
}

export const SET_DISCOVER_EXPERT_LIST = `${PREFIX}_SET_DISCOVER_EXPERT_LIST`;
export interface SetDiscoverExpertListAction {
  type: typeof SET_DISCOVER_EXPERT_LIST;
  payload: { expertsList: PaginatedResponseWithStatus<DiscoverExpert> | null };
}

export const SET_DISCOVER_EXPERT_LIST_LOADING = `${PREFIX}_SET_DISCOVER_EXPERT_LIST_LOADING`;
export interface SetDiscoverExpertListLoadingAction {
  type: typeof SET_DISCOVER_EXPERT_LIST_LOADING;
  payload: { isLoading: boolean };
}

export const SET_CUSTOMER_SUB_FILTERS = `${PREFIX}_SET_CUSTOMER_SUB_FILTERS`;
export interface SetCustomerSubFiltersAction {
  type: typeof SET_CUSTOMER_SUB_FILTERS;
  payload: {
    customerSubFilters: CustomerSubFilters;
  };
}
