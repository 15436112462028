import { Button, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ARBOLUS_COMPLIANCE,
  PLATFORM_AGREEMENT,
  PRIVACY_POLICY
} from "@arbolus-technologies/features/common";
import { LinkWrapper } from "@arbolus-technologies/ui/components";

import styles from "./LinksAndSocials.module.scss";

const { Text } = Typography;

export const LinksAndSocials: React.FC = () => {
  const { t } = useTranslation("linksAndSocials");

  return (
    <Flex justify="space-between" align="center" className={styles.container}>
      <Flex gap={24} align="center" className={styles.linksContainer}>
        <LinkWrapper to={ARBOLUS_COMPLIANCE} isExternal target="_blank">
          <Button type="link" size="small" style={{ paddingLeft: 0 }}>
            {t("compliance")}
          </Button>
        </LinkWrapper>
        <LinkWrapper to={PRIVACY_POLICY} isExternal target="_blank">
          <Button type="link" size="small">
            {t("privacyPolicy")}
          </Button>
        </LinkWrapper>
        <LinkWrapper to={PLATFORM_AGREEMENT} isExternal target="_blank">
          <Button type="link" size="small">
            {t("termsOfUse")}
          </Button>
        </LinkWrapper>
      </Flex>
    </Flex>
  );
};
