import { Flex } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import {
  DateString,
  ExpertWorkHistory
} from "@arbolus-technologies/models/common";
import { KEYWORDS_SEARCH_OPTIONS } from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { WorkHistoryCollapse } from "@arbolus-technologies/ui/components";

import { NoWorkHistory } from "./NoWorkHistory";

interface WorkHistoryListProps {
  workHistories: ExpertWorkHistory[];
  workHistoryLastUpdateDate?: DateString | null;
}

export const WorkHistoryList: React.FC<WorkHistoryListProps> = ({
  workHistories,
  workHistoryLastUpdateDate
}) => {
  const discoverFilters = useSelector(ProjectNxSelector.discoverFilters());
  const keywordsList = useSelector(ProjectNxSelector.keywordsList());
  const isWorkHistoryTitleOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.WORK_HISTORIES_TITLE
    )
  );
  const isWorkHistoryCompanyOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.WORK_HISTORIES_COMPANY
    )
  );

  return (
    <Flex vertical gap={8}>
      {workHistories.length === 0 && <NoWorkHistory />}
      {workHistories.length > 0 && (
        <WorkHistoryCollapse
          workHistories={workHistories}
          keywordsList={keywordsList}
          discoverFilters={discoverFilters}
          highlightWorkHistoryTitle={isWorkHistoryTitleOptionSelected}
          highlightWorkHistoryCompany={isWorkHistoryCompanyOptionSelected}
          workHistoryLastUpdateDate={workHistoryLastUpdateDate}
        />
      )}
    </Flex>
  );
};
