import { Button } from "arbolus-ui-components";
import { find } from "lodash";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CustomersService,
  DefaultToasterService
} from "@arbolus-technologies/api";
import { CustomerSurveyCompany } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";

import { getKeysOfSelectedCheckboxes } from "../../../utils/form";
import { VendorsSelection } from "../VendorsSelection";

import styles from "./ChooseVendorsStep.module.scss";

export function ChooseVendorsStep({
  next,
  vendors,
  setVendors
}: {
  next: () => void;
  vendors: CustomerSurveyCompany[];
  setVendors: Function;
}) {
  const currentUser = useSelector(CacheSelector.currentUserSelector());
  const { t } = useTranslation("expertSurvey");
  const { surveyId } = useParams<{ surveyId: string }>();

  const defaultValues = Object.fromEntries(
    vendors.map((vendor) => [vendor.id, false])
  );
  const methods = useForm({ defaultValues });
  const noCompanySelected = find(methods.watch(), Boolean) === undefined;

  const [isLoading, setIsLoading] = useState(false);

  function handleExtraVendorsConfirmed(vendors: CustomerSurveyCompany[]) {
    setVendors((previousVendors: CustomerSurveyCompany[]) => [
      ...previousVendors,
      ...vendors
    ]);
    vendors.forEach((vendor) => methods.setValue(vendor.id, true));
  }

  const selectedCompaniesIds = vendors.map((vendor) => vendor.id);

  function handleSubmit() {
    const checkboxStateByCompanyId: Record<string, boolean> =
      methods.getValues();

    setVendors((previousVendors: CustomerSurveyCompany[]) =>
      previousVendors.filter(
        (vendor) => checkboxStateByCompanyId[vendor.id] === true
      )
    );

    setIsLoading(true);
    CustomersService.createInsight({
      expertId: currentUser.loggedInId,
      vendorCompanyIds: getKeysOfSelectedCheckboxes(checkboxStateByCompanyId),
      surveyId,
      sourceType: "Survey"
    }).subscribe(
      () => {
        next();
      },
      (error) => {
        DefaultToasterService.showApiErrors(error);
      }
    );
  }

  if (isLoading) return <Loader isFull isCentered />;

  return (
    <div className={styles.vendorsStep}>
      <FormProvider {...methods}>
        <VendorsSelection
          title={t("chooseVendorsStepTitle")}
          options={vendors.map((vendor) => ({
            label: vendor.name,
            name: vendor.id
          }))}
          selectedCompaniesIds={selectedCompaniesIds}
          onExtraVendorsConfirmed={handleExtraVendorsConfirmed}
        />
      </FormProvider>
      <Button
        text={t("nextButton")}
        onClick={handleSubmit}
        disabled={noCompanySelected}
      />
    </div>
  );
}
