import { Button } from "arbolus-ui-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { DateString, SelectOption } from "@arbolus-technologies/models/common";
import {
  ExternalExpertFormEnum,
  ExternalExpertFormValues
} from "@arbolus-technologies/models/expert";
import {
  CustomSelect,
  InputController
} from "@arbolus-technologies/ui/components";
import { DATE_AND_TIME_FORMAT } from "@arbolus-technologies/utils";

import styles from "./ContactInfo.module.scss";

interface ContactInfoProps {
  lastContactInfoDate: DateString | null;
  isFormDisabled: boolean;
  notificationService?: ToasterService;
}

export const ContactInfo: React.FC<ContactInfoProps> = ({
  lastContactInfoDate,
  isFormDisabled,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("externalExpertForm");

  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext<ExternalExpertFormValues>();

  const {
    fields: emailChoices,
    prepend: emailPrepend,
    remove: emailRemove
  } = useFieldArray({
    control,
    name: ExternalExpertFormEnum.PROJECT_PIPELINE_EMAILS,
    keyName: "id"
  });

  const {
    fields: phoneChoices,
    prepend: phonePrepend,
    remove: phoneRemove
  } = useFieldArray({
    control,
    name: ExternalExpertFormEnum.PROJECT_PIPELINE_PHONES,
    keyName: "id"
  });

  const [showCustomEmail, setShowCustomEmail] = useState(false);
  const [showCustomPhone, setShowCustomPhone] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState<SelectOption>(
    emailChoices[0] as SelectOption
  );
  const [defaultPhone, setDefaultPhone] = useState<SelectOption>(
    phoneChoices[0] as SelectOption
  );

  useEffect(() => {
    setDefaultEmail(emailChoices[0] as SelectOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailChoices.length]);

  useEffect(() => {
    setDefaultPhone(phoneChoices[0] as SelectOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneChoices.length]);

  const handleEmailUpdate = () => {
    const email = watch("customEmail") ?? "";

    if ((emailChoices as SelectOption[]).find((e) => e.value === email)) {
      notificationService.showError(t("emailAlreadyExists"));
      return;
    }

    emailPrepend({ label: email, value: email });
    setValue("customEmail", "");
    setShowCustomEmail(false);
    notificationService.showSuccess(t("emailAdded"));
  };

  const handlePhoneUpdate = () => {
    let phoneNumber = watch("customPhone") ?? "";
    phoneNumber = phoneNumber ? `+${phoneNumber}` : "";

    if ((phoneChoices as SelectOption[]).find((e) => e.value === phoneNumber)) {
      notificationService.showError(t("phoneAlreadyExists"));
      return;
    }

    phonePrepend({ label: phoneNumber, value: phoneNumber });
    setValue("customPhone", "");
    setShowCustomPhone(false);
    notificationService.showSuccess(t("phoneAdded"));
  };

  const handleEmailChange = (value: string) => {
    const index = (emailChoices as SelectOption[]).findIndex(
      (email) => email.value === value
    )!;
    const selectedEmail = emailChoices[index];
    emailRemove(index);
    emailPrepend(selectedEmail);
    setDefaultEmail(selectedEmail as SelectOption);
  };

  const handlePhoneChange = (value: string) => {
    const index = (phoneChoices as SelectOption[]).findIndex(
      (phone) => phone.value === value
    )!;
    const selectedPhone = phoneChoices[index];
    phoneRemove(index);
    phonePrepend(selectedPhone);
    setDefaultPhone(selectedPhone as SelectOption);
  };

  return (
    <div className={styles.contactContainer}>
      <h3 className={styles.title}>{t("contacts")}</h3>
      <div className={styles.emailAndPhoneContainer}>
        <div className={styles.inputContainer}>
          <Label className={styles.label}>{t("primaryEmail")}</Label>
          <div className={styles.email}>
            <CustomSelect
              options={emailChoices as SelectOption[]}
              noOptionsMessage={""}
              displayIcon
              defaultValue={defaultEmail}
              value={defaultEmail}
              onSelectChange={handleEmailChange}
              disabled={isFormDisabled}
            />
            {showCustomEmail ? (
              <div className={styles.custom}>
                <InputController
                  name={ExternalExpertFormEnum.CUSTOM_EMAIL}
                  error={errors.customEmail}
                  control={control}
                  placeholder={t("customEmail")}
                  classnames={styles.input}
                />
                <Button
                  type="secondary"
                  text={t("add")}
                  onClick={handleEmailUpdate}
                  disabled={!watch("customEmail") || !!errors.customEmail}
                />
              </div>
            ) : (
              <Button
                type="tertiary"
                text={t("customEmail")}
                endIcon="add"
                onClick={() => setShowCustomEmail(true)}
                disabled={isFormDisabled}
              />
            )}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <Label className={styles.label}>{t("primaryPhone")}</Label>
          <div className={styles.phone}>
            <CustomSelect
              options={phoneChoices as SelectOption[]}
              noOptionsMessage={""}
              displayIcon
              value={defaultPhone}
              onSelectChange={handlePhoneChange}
              disabled={isFormDisabled}
            />
            {showCustomPhone ? (
              <div className={styles.custom}>
                <InputController
                  name={ExternalExpertFormEnum.CUSTOM_PHONE}
                  error={errors.customPhone}
                  control={control}
                  placeholder={t("customPhone")}
                  classnames={styles.input}
                  type="number"
                />
                <Button
                  type="secondary"
                  text={t("add")}
                  onClick={handlePhoneUpdate}
                  disabled={!watch("customPhone") || !!errors.customPhone}
                />
              </div>
            ) : (
              <Button
                type="tertiary"
                text={t("customPhone")}
                endIcon="add"
                onClick={() => setShowCustomPhone(true)}
                disabled={isFormDisabled}
              />
            )}
          </div>
        </div>
        {lastContactInfoDate && (
          <p className={styles.lastContactInfo}>
            {t("lastContactInfo", {
              date: moment(lastContactInfoDate).format(DATE_AND_TIME_FORMAT)
            })}
          </p>
        )}
      </div>
    </div>
  );
};
