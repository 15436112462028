import moment from "moment";
import React, { useEffect } from "react";
import { FieldError, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";

import { AngleToSend } from "@arbolus-technologies/api";
import {
  ClientUserSelector,
  angleColors
} from "@arbolus-technologies/features/common";
import {
  ProjectAnglesSelector,
  ProjectAnglesStoreActions
} from "@arbolus-technologies/stores/project-angles-store";
import {
  ColorPickerController,
  InputController,
  MultiTextInputController,
  RadioButtonsController,
  RichTextEditorController
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import { ViewEditAngleFooter } from "../../ViewEditAngleFooter";
import {
  ANGLE_COLOR,
  ANGLE_TITLE,
  AnglesDetailsBase,
  EXPERT_COUNT,
  EXPERT_DESCRIPTION,
  INCLUDE_SCREENING_QUESTION,
  OWNER,
  QUESTIONS
} from "./types";

import styles from "./AngleDetailsForm.module.scss";

const TITLE_DUPLICATED_CODE_ERROR = "1177";

interface AngleDetailsFormProps {
  isEdit: boolean;
  clientId?: string;
  projectId: string;
  isAdmin?: boolean;
}

export const AngleDetailsForm: React.FC<AngleDetailsFormProps> = ({
  clientId,
  isEdit,
  projectId,
  isAdmin = false
}) => {
  const { t } = useTranslation("viewEditAngle");
  const dispatch = useDispatch();
  const angleSelected = useSelector(ProjectAnglesSelector.selectedAngle());
  const isCreateAngleLoading = useSelector(
    ProjectAnglesSelector.isCreateAngleLoading()
  );
  const isEditAngleLoading = useSelector(
    ProjectAnglesSelector.isEditAngleLoading()
  );
  const isDisabled = useSelector(
    ProjectAnglesSelector.isFormCreateEditAngleDisabled()
  );
  const createAngleErrors = useSelector(
    ProjectAnglesSelector.createAngleErrors()
  );
  const editAngleError = useSelector(ProjectAnglesSelector.editAngleErrors());

  const { expertDetailsModifiedBy, expertDetailsModifiedOn } = angleSelected;

  const {
    control,
    formState: { errors },
    watch,
    reset,
    handleSubmit
  } = useFormContext<AnglesDetailsBase>(); // retrieve all hook methods

  const questionsMethod = useFieldArray({
    control,
    name: QUESTIONS
  });

  useEffect(() => {
    if (editAngleError.message) {
      reset();
    }
  }, [editAngleError, reset]);

  const handleUpdate = (data: AnglesDetailsBase) => {
    const angleId = angleSelected?.id;
    const questions = data.questions?.map((q) => q.question);

    const angleToSend: AngleToSend = {
      ...data,
      projectId,
      ownerId: data[OWNER].id,
      questions: data[INCLUDE_SCREENING_QUESTION] ? questions! : [""],
      color: data[ANGLE_COLOR]!.toUpperCase()
    };

    if (isEdit) {
      if (angleId)
        dispatch(ProjectAnglesStoreActions.editAngle(angleId, angleToSend));

      dispatch(ProjectAnglesStoreActions.setFormCreateEditAngleDisabled());
    } else {
      dispatch(ProjectAnglesStoreActions.addNewAngle(angleToSend, projectId));
    }
  };

  const isLoading = isCreateAngleLoading || isEditAngleLoading;

  return (
    <div className={styles.container}>
      <div className={styles.horizontalInputsContainer}>
        <div className={styles.angleTitleContainer}>
          <Label className={styles.label}>{t("angleTitle")}</Label>
          <div>
            <InputController
              name={ANGLE_TITLE}
              error={errors[ANGLE_TITLE]}
              control={control}
              disabled={isDisabled}
              placeholder={t("titlePlaceholder")}
              required
              newError={
                createAngleErrors.status === TITLE_DUPLICATED_CODE_ERROR
                  ? createAngleErrors.message
                  : undefined
              }
            />
          </div>
        </div>
        <div>
          <Label className={styles.labelColor}>{t("labelColor")}</Label>
          <ColorPickerController
            name={ANGLE_COLOR}
            control={control}
            colors={isEdit ? [angleSelected?.color!] : angleColors}
            disabled={isEdit}
            triangle={"hide"}
          />
        </div>
      </div>
      <div className={styles.idealExpertsContainer}>
        <Label className={styles.label}>{t("idealExperts")}</Label>
        <RichTextEditorController
          name={EXPERT_DESCRIPTION}
          error={errors[EXPERT_DESCRIPTION]}
          control={control}
          placeholder={t("descriptionPlaceholder")}
          disabled={isDisabled}
        />
      </div>
      <div className={styles.horizontalInputsContainer}>
        <div className={styles.inputWrapper}>
          <Label className={styles.label}>{t("howMany")}</Label>
          <InputController
            name={EXPERT_COUNT}
            error={errors[EXPERT_COUNT]}
            control={control}
            placeholder={t("howManyPlaceholder")}
            required
            type="number"
            onlyInteger
            disabled={isDisabled}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label className={styles.label}>{t("owner")}</Label>
          <ClientUserSelector
            name={OWNER}
            control={control}
            error={errors.owner as FieldError}
            placeholderText={t("selectOwner")}
            clientId={clientId!}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className={styles.inputQuestionsContainer}>
        {!isEdit && (
          <>
            <Label className={styles.questionLabel}>
              {t("screeningQuestions")}
            </Label>
            <RadioButtonsController
              name={INCLUDE_SCREENING_QUESTION}
              control={control}
              onConfirmText={t("yesIdo")}
              onDenyText={t("noIdoNot")}
            />
            {watch(INCLUDE_SCREENING_QUESTION) && (
              <MultiTextInputController
                arrMethods={questionsMethod}
                watch={watch}
                control={control}
                errors={errors}
              />
            )}
          </>
        )}
      </div>
      {expertDetailsModifiedOn && (
        <div className={styles.informLastUpdated}>
          {t("anglesDetailsLastUpdated", {
            date: moment(expertDetailsModifiedOn).format("DD MMM YYYY"),
            userName: displayUserName({
              firstName: expertDetailsModifiedBy?.firstName!,
              lastName: expertDetailsModifiedBy?.lastName!,
              email: ""
            })
          })}
        </div>
      )}
      <ViewEditAngleFooter
        canEdit={isAdmin}
        isEdit={isEdit}
        isLoading={isLoading}
        isDisabled={isDisabled}
        editAction={() =>
          dispatch(ProjectAnglesStoreActions.setFormCreateEditAngleEnabled())
        }
        handleSubmit={handleSubmit(handleUpdate)}
      />
    </div>
  );
};
