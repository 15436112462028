import { Card, Typography } from "antd";

import { Endorsement } from "@arbolus-technologies/api";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { EndorsementCardTitle } from "./EndorsementCardTitle";

const { Text } = Typography;

export function EndorsementCard({ endorsement }: { endorsement: Endorsement }) {
  return (
    <Card
      key={endorsement.created}
      title={<EndorsementCardTitle endorsement={endorsement} />}
    >
      <blockquote>{endorsement.quote}</blockquote>
      <Text code>{formatDate(endorsement.created!, APP_DATE_FORMAT)}</Text>
    </Card>
  );
}
