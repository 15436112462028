import { Button, Flex, Radio, RadioChangeEvent, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AntDAvatar, AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  DefaultToasterService,
  SurveyExpert,
  ToasterService,
  TransactionsService
} from "@arbolus-technologies/api";
import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { User } from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import styles from "./ModifySurveyExpertOwnerSidePanel.module.scss";

const { Title, Text } = Typography;

interface ModifySurveyExpertOwnerSidePanelProps {
  onUpdateOwner: () => void;
  transactionService?: typeof TransactionsService;
  notificationService?: ToasterService;
}

export const ModifySurveyExpertOwnerSidePanel: React.FC<
  ModifySurveyExpertOwnerSidePanelProps
> = ({
  onUpdateOwner,
  transactionService = TransactionsService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("modifySurveyExpertOwnerSidePanel");
  const dispatch = useDispatch();
  const arbolusTeam = useSelector(ProjectNxSelector.arbolusTeam());

  const allUsers: User[] = [
    ...(arbolusTeam?.projectPrimaryLead
      ? [arbolusTeam.projectPrimaryLead]
      : []),
    ...(arbolusTeam?.projectSecondaryLead
      ? [arbolusTeam.projectSecondaryLead]
      : []),
    ...(arbolusTeam?.standardManagers ? arbolusTeam.standardManagers : [])
  ];

  useEffect(() => {
    setOwnerList(allUsers);
  }, []);

  const [ownerList, setOwnerList] = useState<User[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<User>();
  const [isSaving, setIsSaving] = useState(false);

  const modifySurveyExpertOwnerParams: SurveyExpert[] = useSelector(
    PanelSelector.panelData(PanelId.ModifySurveyExpertOwner)
  );

  const handleSelectOwner = (e: RadioChangeEvent) => {
    setSelectedOwner(e.target.value);
  };

  const handleCloseSidePanel = () => {
    dispatch(PanelStoreActions.closePanel(PanelId.ModifySurveyExpertOwner));
  };

  const handleOnConfirm = () => {
    if (selectedOwner) {
      const transactionIds = modifySurveyExpertOwnerParams.map(
        (modifySurveyExpertOwner) => modifySurveyExpertOwner.transactionId
      );

      setIsSaving(true);
      transactionService
        .modifyOwner(selectedOwner.id, transactionIds)
        .subscribe(
          () => {
            notificationService.showSuccess(t("modifyOwnerSuccess"));
            handleCloseSidePanel();
            setIsSaving(false);
            onUpdateOwner();
          },
          () => {
            notificationService.showError(t("modifyOwnerError"));
            handleCloseSidePanel();
            setIsSaving(false);
          }
        );
    }
  };

  return (
    <SlidePanel
      panelId={PanelId.ModifySurveyExpertOwner}
      width={SIDE_PANEL_SIZE._400}
      noPadding
      hideHeader
    >
      <Flex
        gap={12}
        justify="space-between"
        align="center"
        className={styles.header}
      >
        <Flex gap={12} align="center">
          <AntDIcon name="close" fontSize="20px" />
          <Title level={4}>{t("modifyOwner")}</Title>
        </Flex>
        <Flex gap={8}>
          <Button onClick={handleCloseSidePanel} disabled={isSaving}>
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            onClick={handleOnConfirm}
            disabled={!selectedOwner || isSaving}
          >
            {t("save")}
          </Button>
        </Flex>
      </Flex>
      <Radio.Group onChange={handleSelectOwner} className={styles.content}>
        <Flex vertical gap={16}>
          {ownerList.map((owner) => (
            <Radio key={owner.id} value={owner} className={styles.card}>
              <AntDAvatar
                size="small"
                profileImageUrl={owner.profileImageUrl}
                firstName={owner.firstName}
                lastName={owner.lastName}
              />
            </Radio>
          ))}
        </Flex>
      </Radio.Group>
    </SlidePanel>
  );
};
