import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ICellRendererParams } from "ag-grid-community";
import { Tag, Typography } from "antd";
import styles from "./AlternativeCompaniesRenderer.module.scss";

export interface AlternativeCompaniesRendererProps
  extends Partial<ICellRendererParams<{ alternativeCompanies: string[] }>> {
  isCompanyData?: boolean;
}

export function AlternativeCompaniesRenderer({
  data
}: AlternativeCompaniesRendererProps) {
  if (!data?.alternativeCompanies || data.alternativeCompanies.length === 0)
    return <Typography.Text type="secondary">-</Typography.Text>;

  const { alternativeCompanies } = data;

  return (
    <div className={styles.container}>
      <Tag className={styles.tag}>
        {alternativeCompanies[0]} <AntDIcon name="add" />
      </Tag>
    </div>
  );
}
