// Sentry initialization should be imported first!
import "./sentry";

import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { useTracking } from "react-tracking";

import {
  restService,
  restServiceV2,
  restServiceV3
} from "@arbolus-technologies/api";
import {
  AllMainModals,
  MixPanelEventNames,
  MixPanelProperties,
  MixpanelEvent,
  addEventInitialization,
  trackEvent,
  trackPageView
} from "@arbolus-technologies/features/common";
import { setupAgGridEnterprise } from "@arbolus-technologies/ui/components";
import { DISABLE_MIXPANEL } from "@arbolus-technologies/utils";

import { Toaster } from "sonner";
import { AppConstants } from "./constants";
import { MIXPANEL_PROJECT_ID } from "./constants/app";
import AppContainer from "./containers/app/AppContainer";
import ErrorBoundary from "./containers/app/components/errorBoundary/ErrorBoundary";
import { AppStoreActions } from "./containers/app/store";
import { LoginStoreActions } from "./containers/auth/store";
import NavBarProvider from "./contexts/navBar/NavBarProvider";
import AccessControlProvider from "./contexts/roleBasedAccess/AccessControlProvider";
import initializeStore from "./store";

export const appStore = initializeStore();

const kickOutUser = (): void => {
  appStore.dispatch(LoginStoreActions.logoutUser());
  localStorage.removeItem(AppConstants.LOCALSTORAGE.CULTURE);
};
restService.configure(kickOutUser);
restServiceV2.configure(kickOutUser);
restServiceV3.configure(kickOutUser);

// Value for a vh unit
const vh = window.innerHeight * 0.01;
// Set --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// Resize event to re-calculate
window.addEventListener("resize", () => {
  const vh1 = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh1}px`);
});

// Listen logout/login event from other tabs
window.addEventListener("storage", (e) => {
  if (
    e.key === AppConstants.LOCALSTORAGE.LAST_LOGIN &&
    e.oldValue !== e.newValue
  ) {
    window.location.reload();
  }
});

setupAgGridEnterprise();

// LOCAL_DEV_SERVER provided by webpack.config.js
// @ts-ignore
const IS_LOCAL_DEV_SERVER = LOCAL_DEV_SERVER;

const App: React.FC = () => {
  const { Track } = useTracking(
    {},
    {
      dispatch: (data: MixpanelEvent) => {
        const mixPanelProperties: MixPanelProperties = {
          ...data.properties,
          page: data.page
        };

        if (data.dispatchOnMount) {
          trackPageView(mixPanelProperties);
        } else {
          trackEvent(data.eventName, mixPanelProperties);
        }
      }
    }
  );

  useEffect(function initializeMixpanel() {
    if (!MIXPANEL_PROJECT_ID) return;

    mixpanel.init(MIXPANEL_PROJECT_ID, {
      persistence: "localStorage",
      track_pageview: true,
      debug: process.env.NODE_ENV !== "production",
      record_sessions_percent: 25
    });
    // Disabling it locally to not waste session replays
    if (DISABLE_MIXPANEL || IS_LOCAL_DEV_SERVER) {
      mixpanel.opt_out_tracking();
    }

    // Set time taken for Page Load (redirect to Dashboard) in seconds
    addEventInitialization(MixPanelEventNames.InitialRender);
  }, []);

  useEffect(() => {
    appStore.dispatch(AppStoreActions.getFeatureFlags());
  }, []);

  return (
    <ErrorBoundary>
      <Track>
        <Provider store={appStore}>
          <AccessControlProvider>
            <NavBarProvider>
              <AppContainer />
              <AllMainModals />
            </NavBarProvider>
          </AccessControlProvider>
        </Provider>
      </Track>
      <Toaster visibleToasts={5} />
    </ErrorBoundary>
  );
};

export default App;
