import { Observable } from "rxjs";

import { call_put } from "../SimpleRestService";
import { API_URL_V1, USERS_API } from "../constants/api";
import { INBOX_NOTIFICATION_CATEGORY } from "../enums/notificationEnums";
import {
  ApiNonPaginatedResponse,
  ApiPaginatedResponse,
  DeletedResponse,
  Response,
  SuccessResponse
} from "../models/api";
import { InboxNotification } from "../models/notifications";
import {
  FederateLoginResponse,
  LinkedInRegisterRequest,
  NotificationsGetRequest,
  ProjectWithNotifications,
  PusherBatchAuthenticateRequest,
  PusherBatchAuthenticateResponse,
  RegisterRequest,
  ResetPasswordWithToken,
  UpdateUserPayload,
  UpdatedUserProfileResponse,
  UserCredentials,
  UserEntryRequest,
  UserLoginOpenIdResponse,
  UserLoginResponse,
  UserNotificationCountsResponse,
  UserNotificationSettingsUpdateRequest,
  UserPasswordReset,
  UserProfile,
  UserRegisterResponse
} from "../models/user";
import { restService } from "../restService";

export const UserService = {
  authenticateUser: (
    userCredentials: UserCredentials
  ): Observable<UserLoginResponse> =>
    restService.post<UserLoginResponse>(USERS_API.LOGIN(), userCredentials),

  authenticateUserOpenId: (
    domain: string
  ): Observable<UserLoginOpenIdResponse> =>
    restService.get<UserLoginOpenIdResponse>(USERS_API.LOGIN_OPEN_ID(domain)),

  getUserProfile: (): Observable<UserProfile> =>
    restService.get<UserProfile>(USERS_API.GET_PROFILE()),

  logoutUser: (): Observable<Response<never>> =>
    restService.post<Response<never>>(USERS_API.LOGOUT(), {}),

  pusherAuthenticateAll: (
    pusherAuthenticateRequest: PusherBatchAuthenticateRequest
  ): Observable<PusherBatchAuthenticateResponse> =>
    restService.post<PusherBatchAuthenticateResponse>(
      USERS_API.PUSHER_AUTHENTICATE(),
      pusherAuthenticateRequest
    ),

  updateProfile: ({ id, ...payload }: UpdateUserPayload & { id: string }) =>
    restService.put<UpdatedUserProfileResponse>(
      USERS_API.UPDATE_PROFILE(id),
      payload
    ),

  updateAdminProfile: (
    userId: string,
    userProfile: UserEntryRequest
  ): Observable<SuccessResponse> =>
    restService.put(USERS_API.UPDATE_PROFILE(userId), userProfile),

  resetPasswordRequestEmail: (
    email: string,
    recaptchaToken: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(USERS_API.REQUEST_RESET_PASSWORD(), {
      email,
      recaptchaToken
    }),

  changeUserPassword: (
    passwordResetRequest: UserPasswordReset
  ): Observable<SuccessResponse> =>
    restService.put(USERS_API.USER_PASSWORD_CHANGE(), passwordResetRequest),

  getNotificationCounts: (
    projectId?: string | null
  ): Observable<UserNotificationCountsResponse> =>
    restService.get<UserNotificationCountsResponse>(
      USERS_API.GET_NOTIFICATION_CATEGORIES(),
      { projectId, adminNotification: true }
    ),

  getNotifications: (
    notificationsRequest: NotificationsGetRequest
  ): Observable<ApiPaginatedResponse<InboxNotification>> =>
    restService.get<ApiPaginatedResponse<InboxNotification>>(
      USERS_API.GET_NOTIFICATIONS(),
      {
        ...notificationsRequest
      }
    ),

  getProjectsWithNotifications: (): Observable<
    ApiNonPaginatedResponse<ProjectWithNotifications>
  > =>
    restService.get<ApiNonPaginatedResponse<ProjectWithNotifications>>(
      USERS_API.GET_NOTIFICATIONS_PROJECTS()
    ),

  markNotificationsAsRead: (
    notificationIds: string[]
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(USERS_API.GET_NOTIFICATIONS(), {
      notificationIds
    }),

  markNotificationsAsDone: (
    notificationIds: string[]
  ): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      USERS_API.GET_NOTIFICATIONS(),
      {},
      { notificationIds }
    ),

  isUserMatchesSearchCriteria: (
    firstName: string,
    lastName: string,
    email: string,
    query: string
  ): boolean => {
    const lowerCasedQuery = query.toLocaleLowerCase();

    return (
      (firstName && firstName.toLowerCase().includes(lowerCasedQuery)) ||
      (lastName && lastName.toLowerCase().includes(lowerCasedQuery)) ||
      (firstName &&
        lastName &&
        `${firstName} ${lastName}`.toLowerCase().includes(lowerCasedQuery)) ||
      email.toLowerCase().includes(lowerCasedQuery)
    );
  },

  resetPasswordWithToken: (
    resetUser: ResetPasswordWithToken
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(USERS_API.RESET_PASSWORD(), resetUser),

  federatedLogin: (
    code: string,
    state: string
  ): Observable<FederateLoginResponse> =>
    restService.post<FederateLoginResponse>(USERS_API.OPEN_ID(), {
      code,
      state,
      adminRedirect: true
    }),

  updateUserNotificationSettings: (
    userNotificationSettings: UserNotificationSettingsUpdateRequest
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      USERS_API.UPDATE_NOTIFICATIONS_SETTINGS(),
      userNotificationSettings
    ),

  sendFcmToken: (
    fcmToken: string,
    adminToken = true
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(USERS_API.SEND_FCM_TOKEN(), {
      token: fcmToken,
      adminToken
    }),

  updateStarredExpert: (expertId: string): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      USERS_API.UPDATE_STARRED_EXPERT(expertId),
      {}
    ),

  deleteStarredExpert: (expertId: string): Observable<SuccessResponse> =>
    restService.delete<SuccessResponse>(
      USERS_API.DELETE_STARRED_EXPERT(expertId),
      {}
    ),

  clearAllNotifications: (): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(USERS_API.CLEAR_ALL_NOTIFICATIONS()),

  clearSectionNotifications: (
    notificationType: INBOX_NOTIFICATION_CATEGORY
  ): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      USERS_API.CLEAR_SECTION_NOTIFICATIONS(),
      { notificationType }
    ),

  acceptTermsAndConditions: (termId: string): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      USERS_API.ACCEPT_TERMS_AND_CONDITIONS(termId),
      { termId }
    ),

  userRegistration: (
    registerRequest: RegisterRequest
  ): Observable<UserRegisterResponse> =>
    restService.get(USERS_API.REGISTER(), registerRequest),

  linkedinRegister: (payload: LinkedInRegisterRequest) =>
    call_put(API_URL_V1 + USERS_API.LINKEDIN_REGISTER, payload)
};
