import { Form, Select } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { EmployedStatus } from "@arbolus-technologies/models/common";

import { EMPLOYED_STATUS_OPTIONS } from "../../helpers/constants";

const { Option } = Select;

const employmentTypes = [
  EmployedStatus.Employed,
  EmployedStatus.Unemployed,
  EmployedStatus.Retired,
  EmployedStatus.Independent
];

export const EmploymentTypeSelector: React.FC = () => {
  const { t } = useTranslation("employmentTypeSelector");
  const form = Form.useFormInstance();

  const value = form.getFieldValue("employedStatus");
  const handleOnChange = (value: EmployedStatus) => {
    form.setFieldsValue({ employedStatus: value });
    handleEmployedStatusChanged(value);
  };

  const handleEmployedStatusChanged = useCallback(
    (value: EmployedStatus) => {
      if (EMPLOYED_STATUS_OPTIONS.includes(value)) {
        form.resetFields(["title", "company", "country"]);
      } else {
        form.setFieldsValue({
          title: null,
          company: null,
          country: null
        });
        if (value === EmployedStatus.Retired) {
          form.setFieldValue("isCurrentWork", true);
        }
      }

      form.setFieldValue("employedStatus", value);
    },
    [form]
  );

  return (
    <Select
      onChange={handleOnChange}
      defaultValue={value ? t(value) : t("placeholder")}
      notFoundContent={t("noOptionsMessage ")}
    >
      {employmentTypes.map((status) => (
        <Option key={status} value={status}>
          {t(status)}
        </Option>
      ))}
    </Select>
  );
};
