import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./AssignedAngle.module.scss";

interface AssignedAngleProps {
  angle: { title: string; color: string };
}

export const AssignedAngle: React.FC<AssignedAngleProps> = ({ angle }) => (
  <div className={styles.wrapper}>
    <div
      className={styles.angleContainer}
      style={{ border: `1px solid ${angle.color}` }}
    >
      <div className={styles.icon} style={{ backgroundColor: angle.color }}>
        <Icon
          color={ARBOLUS_COLORS.bColorBaseDark}
          fontSize={"16px"}
          name={"zoom_in_map"}
          filled={true}
        />
      </div>
      <span className={styles.text}>{angle.title}</span>
    </div>
  </div>
);
