import { Flex, Image, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  DASHBOARD,
  PublicRoutes,
  SURVEY_LANDING_QUALIFIED
} from "@arbolus-technologies/routes";
import {
  arbolusLogo,
  surveyLandingQualified,
  surveyLandingTrustpilot
} from "@arbolus-technologies/theme";

import styles from "./SurveyLandingQualifiedPage.module.scss";

const { Text, Title } = Typography;

export const SurveyLandingQualifiedPage: React.FC = () => {
  const { t } = useTranslation("surveyLandingQualifiedPage");
  const { trackRender, trackClick } = useArbolusTracking();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const surveyExpertId = searchParams.get("surveyExpertId");

  useEffect(() => {
    trackRender(MixPanelEventNames.SurveySignUpLanding);
  }, []);

  if (!surveyExpertId) {
    return <Redirect to={{ pathname: DASHBOARD }} />;
  }

  return (
    <Flex className={styles.container}>
      <Flex className={styles.leftContainer}>
        <Flex vertical justify="center" align="center" gap={32}>
          <Flex align="flex-start" className={styles.imageContainer}>
            <Image
              src={arbolusLogo}
              alt="Arbolus logo"
              width={106}
              preview={false}
            />
          </Flex>
          <Flex vertical gap={24}>
            <Flex vertical>
              <Title level={3}>{t("thankYouTitle1")}</Title>
              <Title level={3} className={styles.thankYouTitle}>
                {t("thankYouTitle2")}
              </Title>
            </Flex>
            <Flex vertical gap={16}>
              <Text>{t("thankYouText1")}</Text>
              <Text>{t("thankYouText2")}</Text>
            </Flex>
          </Flex>
          <Flex
            gap={8}
            justify="flex-start"
            className={styles.buttonsContainer}
          >
            <AntDButtonLink
              type="primary"
              pathname={PublicRoutes.REGISTER}
              search={`?hash=&redirect=/payment-method&search=?surveyExpertId=${surveyExpertId}`}
              text={t("createAccount")}
              onClick={() =>
                trackClick(MixPanelEventNames.SurveySignUpLandingCreateAccount)
              }
            />
            <AntDButtonLink
              pathname={PublicRoutes.LOGIN}
              search={`?hash=&redirect=/payment-method&search=?surveyExpertId=${surveyExpertId}`}
              text={t("logIn")}
              state={{ from: SURVEY_LANDING_QUALIFIED }}
              onClick={() =>
                trackClick(MixPanelEventNames.SurveySignUpLandingLogin)
              }
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical className={styles.rightContainer}>
        <Flex vertical>
          <Flex vertical>
            <Title level={2} className={styles.arbolusTitle}>
              {t("arbolusTitle")}
            </Title>
            <Title level={2} className={styles.knowledgeDigitalTitle}>
              {t("knowledgeDigitalTitle")}
            </Title>
          </Flex>
          <Flex className={styles.imagesContainer}>
            <Image
              src={surveyLandingQualified}
              alt="Arbolus landing qualified"
              height={600}
              preview={false}
              rootClassName={styles.surveyLandingQualifiedImageRoot}
              className={styles.surveyLandingQualifiedImage}
            />
            <Image
              src={surveyLandingTrustpilot}
              alt="Arbolus landing trustpilot"
              preview={false}
              rootClassName={styles.trustpilotImageContainer}
              className={styles.trustpilotImage}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
