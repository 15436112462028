import { Button, Flex, Form, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ProjectAnswer,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DangerouslySetInnerHTML,
  ScreeningQuestionConstraints,
  TiptapEditorMemoized
} from "@arbolus-technologies/ui/components";
import { emptyParagraphTag, utilService } from "@arbolus-technologies/utils";

import styles from "./Screening.module.scss";

const { Text } = Typography;

interface ScreeningFormProps {
  screeningInfo: ProjectAnswer[];
  expertId: string;
  onUpdateScreeningAnswers: (answers: ProjectAnswer[]) => void;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ScreeningForm: React.FC<ScreeningFormProps> = ({
  screeningInfo,
  expertId,
  onUpdateScreeningAnswers,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralScreening");
  const { projectId } = useParams<{ projectId: string }>();
  const [form] = Form.useForm();
  const { screeningQuestions } = Form.useWatch([], form) ?? {
    screeningQuestions: []
  };

  const isAnyAnswered = screeningQuestions.some(
    (item: ProjectAnswer) =>
      item.answer !== "" && item.answer !== emptyParagraphTag
  );

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = (values: { screeningQuestions: ProjectAnswer[] }) => {
    const newAnswers = values.screeningQuestions.map((item: ProjectAnswer) => ({
      questionId: item.questionId,
      answer: emptyParagraphTag === item.answer?.trim() ? "" : item.answer
    }));

    setIsSaving(true);
    expertService.updateAnswers(expertId, projectId, newAnswers).subscribe(
      () => {
        notificationService.showSuccess(t("updateSuccess"));
        onUpdateScreeningAnswers(screeningQuestions);
        setIsSaving(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsSaving(false);
      }
    );
  };

  const handleAnswerUpdate = (content: string, index: number) => {
    const updatedAnswer = { ...screeningQuestions[index], answer: content };
    const updatedAnswers = { ...screeningQuestions, [index]: updatedAnswer };
    form.setFieldsValue({ ["screeningQuestions"]: updatedAnswers });
  };

  const onFinishFailed = () => {
    notificationService.showError(t("invalidForm"));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      initialValues={{ screeningQuestions: screeningInfo }}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      disabled={isSaving}
    >
      <Flex vertical gap={24}>
        <Form.List name="screeningQuestions">
          {(fields) =>
            fields.map((field, index) => (
              <Form.Item
                key={field.key}
                label={
                  <Flex gap={4}>
                    <Text>{`${index + 1}.`}</Text>
                    <DangerouslySetInnerHTML
                      text={screeningInfo[index].question}
                    />
                  </Flex>
                }
                name={[field.name, "answer"]}
                rules={[
                  () => ({
                    validator(_, value) {
                      const htmlStrippedText =
                        utilService.getStrippedTextFromHtml(value ?? "");

                      if (
                        htmlStrippedText.length >
                        ScreeningQuestionConstraints.MAX_ANSWER_LENGTH
                      ) {
                        return Promise.reject(
                          new Error(
                            t("answerMaxLength", {
                              length:
                                ScreeningQuestionConstraints.MAX_ANSWER_LENGTH
                            })
                          )
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <TiptapEditorMemoized
                  placeholder={t("screeningQuestionPlaceholder")}
                  onChange={(content) => handleAnswerUpdate(content, index)}
                  hasError={false}
                  initialEditorState={screeningInfo[index].answer ?? ""}
                  maxLength={ScreeningQuestionConstraints.MAX_ANSWER_LENGTH}
                  textAreaClassName={styles.textArea}
                />
              </Form.Item>
            ))
          }
        </Form.List>
        <Form.Item>
          <Flex justify="flex-end">
            <Button
              type="primary"
              htmlType="submit"
              disabled={isSaving || !isAnyAnswered}
            >
              {t("save")}
            </Button>
          </Flex>
        </Form.Item>
      </Flex>
    </Form>
  );
};
