import {
  CompanyDataModel,
  CompanyDataRequest,
  MonitoringListService,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

export class CompanyDataSource implements IServerSideDatasource {
  private readonly _vendorCompanyId: string;

  constructor(vendorCompanyId: string) {
    this._vendorCompanyId = vendorCompanyId;
  }

  getRows(params: IServerSideGetRowsParams<CompanyDataModel, never>): void {
    const { success, fail, request } = params;

    const queryParams = this.getQueryParams(request);

    MonitoringListService.getCompanyData({
      ...queryParams,
      vendorCompanyId: this._vendorCompanyId
    })
      .then((response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      })
      .catch(fail);
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): CompanyDataRequest {
    const { startRow, endRow } = request;

    const queryParams: CompanyDataRequest = {
      limit: endRow! - startRow!,
      offset: startRow!,
      sort: [
        { orderBy: "status", orderDirection: SORT_DIRECTION.DESCENDING },
        { orderBy: "lastUpdate", orderDirection: SORT_DIRECTION.DESCENDING }
      ]
    };

    return queryParams;
  }
}
