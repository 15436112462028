import { Handlers, createReducer } from "reduxsauce";

import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";

import {
  ARCHIVE_CANOPY,
  ArchiveCanopyAction,
  GET_CANOPIES,
  GET_CANOPIES_FAILURE,
  GET_CANOPIES_SUCCESS,
  GetCanopiesSuccessAction
} from "./actions/ProjectCanopyActionTypes";
import { ProjectCanopyReducerState } from "./definitions";

export const initialState: ProjectCanopyReducerState = {
  isCanopiesLoading: true,
  canopies: []
};

const handleGetCanopies = (
  state = initialState
): ProjectCanopyReducerState => ({
  ...state,
  isCanopiesLoading: true
});

const handleGetCanopiesSuccess = (
  state = initialState,
  { payload }: GetCanopiesSuccessAction
): ProjectCanopyReducerState => ({
  ...state,
  canopies: payload.canopies,
  isCanopiesLoading: false
});

const handleGetCanopiesFailure = (
  state = initialState
): ProjectCanopyReducerState => ({
  ...state,
  isCanopiesLoading: false
});

const handleArchiveCanopy = (
  state = initialState,
  { payload }: ArchiveCanopyAction
): ProjectCanopyReducerState => ({
  ...state,
  canopies: state.canopies.map((canopy) =>
    canopy.id === payload.canopyId
      ? { ...canopy, status: CANOPY_STATUS.ARCHIVED }
      : canopy
  )
});

// Current `Handlers` definition not good enough for proper typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<ProjectCanopyReducerState, any> = {
  [GET_CANOPIES]: handleGetCanopies,
  [GET_CANOPIES_SUCCESS]: handleGetCanopiesSuccess,
  [GET_CANOPIES_FAILURE]: handleGetCanopiesFailure,
  [ARCHIVE_CANOPY]: handleArchiveCanopy
};

export const ProjectCanopyReducer = createReducer(initialState, handlers);
