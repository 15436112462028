import { Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

interface RemoveCompanyModalProps {
  isOpen: boolean;
  handleOk: () => void;
  setIsOpen: (isOpen: boolean) => void;
}

export function RemoveCompanyModal({
  isOpen,
  handleOk,
  setIsOpen
}: RemoveCompanyModalProps) {
  const { t } = useTranslation("removeCompanyModal");

  return (
    <Modal
      title={t("removeCompanyTitle")}
      open={isOpen}
      onOk={handleOk}
      onCancel={() => {
        setIsOpen(false);
      }}
      okText={t("removeCompanyOk")}
      okType="primary"
      okButtonProps={{
        danger: true
      }}
    >
      <Text>{t("removeCompanyText")}</Text>
    </Modal>
  );
}
