/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  CIQUser,
  ListClientMember,
  USERS_MAX_PAGINATION_PAGES,
  USER_PAGE_SIZE
} from "@arbolus-technologies/api";
import { UserName } from "@arbolus-technologies/features/common";
import {
  CIQPagination,
  TableTextFilter,
  TanstackTable
} from "@arbolus-technologies/ui/components";

import { PermissionsFilter } from "../Permissions/PermissionsFilter/PermissionsFilter";
import { UserActions } from "../UserActions/UserActions";

import styles from "./UserTable.module.scss";

export interface UserTableProps {
  onSendOnboardingEmail?: (userId: string) => void;
  onAssignSlidePanel: (id: string) => void;
  onSearch: (searchText: string) => void;
  onPageChange: (nextPage: number) => void;
  onUserNameClick?: (user: CIQUser) => void;
  isLoading: boolean;
  totalCount: number;
  users: CIQUser[] | ListClientMember[];
  isClientTeamMember?: boolean;
  onlyAssignAction?: boolean;
  onFilter?: (filterIds: string[]) => void;
  selectedPermissionIds?: string[];
}

const tableClasses = {
  head: { thead: styles.thead, tr: styles.tr, th: styles.th },
  body: { tbody: styles.tbody, tr: styles.tr, td: styles.td },
  noData: styles.noData
};

export const UserTable: React.FC<UserTableProps> = ({
  onSendOnboardingEmail,
  onAssignSlidePanel,
  onSearch,
  onPageChange,
  onUserNameClick,
  isLoading,
  totalCount,
  users,
  isClientTeamMember = false,
  onlyAssignAction = false,
  onFilter,
  selectedPermissionIds = []
}) => {
  const { t } = useTranslation("usersList");

  const [usersList, setUsersList] = useState<CIQUser[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    onPageChange(nextPage);
  };

  const handleSearch = (searchText: string) => {
    setCurrentPage(0);
    onSearch(searchText);
  };

  const handleFilter = (permissionIds: string[]) => {
    setCurrentPage(0);
    onFilter?.(permissionIds);
  };

  const columnDefinitions = [
    {
      name: "userName",
      component: (props: any) => (
        <TableTextFilter
          {...props}
          displayName={t("name")}
          inputClassName={styles.inputClassName}
          onChange={handleSearch}
        />
      ),
      cellComponent: ({ row }: { row: CIQUser }) => (
        <UserName
          row={row}
          onClick={() => {
            if (onUserNameClick) {
              onUserNameClick(row);
            }
          }}
        />
      ),
      size: "38%"
    },
    {
      name: "title",
      component: () => <div>{t("jobTitle")}</div>,
      cellComponent: ({ row }: { row: CIQUser }) => (
        <div className={styles.rowTitle}>{row.title}</div>
      ),
      size: isMobile ? "25%" : "38%"
    },
    {
      name: "activeProjectCount",
      component: () => <div>{t("projects")}</div>,
      cellComponent: ({ row }: { row: CIQUser }) => (
        <>
          <i className="status-dot active" />
          {row.activeProjectCount}
        </>
      ),
      size: isMobile ? "20%" : "12%"
    },
    {
      name: "actions",
      component: () => <div>{t("action")}</div>,
      cellComponent: ({ row }: { row: CIQUser }) => (
        <UserActions
          row={row}
          onSendOnboardingEmail={onSendOnboardingEmail}
          showAssignSlidePanel={onAssignSlidePanel}
          isClientTeamMember={isClientTeamMember}
          onlyAssignAction={onlyAssignAction}
        />
      ),
      size: isMobile ? "17%" : "12%"
    }
  ];

  return (
    <>
      <div className={styles.usersList}>
        {onFilter && (
          <div className={styles.filters}>
            <PermissionsFilter
              onFilter={handleFilter}
              selectedPermissionIds={selectedPermissionIds}
            />
          </div>
        )}
        <TanstackTable
          defaultData={usersList}
          columnDefinitions={columnDefinitions}
          isLoading={isLoading}
          classnames={tableClasses}
        />
      </div>
      {usersList.length > 0 && (
        <CIQPagination
          limit={USER_PAGE_SIZE}
          pageCount={USERS_MAX_PAGINATION_PAGES}
          currentPage={currentPage}
          totalCount={totalCount}
          onSetPage={handlePageChange}
        />
      )}
    </>
  );
};
