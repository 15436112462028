import { Button, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ARBOLUS_COMPLIANCE,
  ARBOLUS_INSTAGRAM,
  ARBOLUS_LINKEDIN,
  ARBOLUS_PAGE,
  PLATFORM_AGREEMENT,
  PRIVACY_POLICY
} from "@arbolus-technologies/features/common";
import { instagramLogo, linkedInLogo } from "@arbolus-technologies/theme";
import { LinkWrapper } from "@arbolus-technologies/ui/components";

import styles from "./LinksAndSocials.module.scss";

const { Text } = Typography;

export const LinksAndSocials: React.FC = () => {
  const { t } = useTranslation("linksAndSocials");

  return (
    <Flex justify="space-between" align="center" className={styles.container}>
      <Flex gap={24} align="center" className={styles.linksContainer}>
        <LinkWrapper to={ARBOLUS_COMPLIANCE} isExternal target="_blank">
          <Button type="link" size="small">
            {t("compliance")}
          </Button>
        </LinkWrapper>
        <LinkWrapper to={PRIVACY_POLICY} isExternal target="_blank">
          <Button type="link" size="small">
            {t("privacyPolicy")}
          </Button>
        </LinkWrapper>
        <LinkWrapper to={PLATFORM_AGREEMENT} isExternal target="_blank">
          <Button type="link" size="small">
            {t("termsOfUse")}
          </Button>
        </LinkWrapper>
      </Flex>
      <Flex gap={10} align="center">
        <LinkWrapper to={ARBOLUS_PAGE} isExternal target="_blank">
          <Text>www.arbolus.com</Text>
        </LinkWrapper>
        <LinkWrapper to={ARBOLUS_LINKEDIN} isExternal target="_blank">
          <img src={linkedInLogo} alt="LinkedIn logo" height={18} />
        </LinkWrapper>
        <LinkWrapper to={ARBOLUS_INSTAGRAM} isExternal target="_blank">
          <img src={instagramLogo} alt="Instagram logo" height={18} />
        </LinkWrapper>
      </Flex>
    </Flex>
  );
};
