import clsx from "clsx";
import React, { useState } from "react";
import SimpleBar from "simplebar-react";

import { INBOX_NOTIFICATION_ACTION } from "../../../../../constants/notifications";
import useContentPanelContext from "../../../../../contexts/contentPanel/UseContentPanelContext";
import WrappedExpertProfileHolder, {
  ExpertProfileMode
} from "./ExpertProfileHolder";

interface ExpertProfilePanelProps {
  projectId: string;
  expertId: string;
  referralId: string;
  initialAction?: INBOX_NOTIFICATION_ACTION;
  showAvailabilityTab?: boolean;
}

const ExpertProfilePanel: React.FC<ExpertProfilePanelProps> = ({
  expertId,
  referralId,
  projectId,
  initialAction,
  showAvailabilityTab = false
}): JSX.Element => {
  const [isProfileFetched, setIsProfileFetched] = useState<boolean>(false);
  const { closePanel } = useContentPanelContext();

  const isComplianceReview =
    initialAction === INBOX_NOTIFICATION_ACTION.COMPLIANCE;
  const previewMode = isComplianceReview
    ? ExpertProfileMode.COMPLIANCE
    : ExpertProfileMode.PREVIEW;

  return (
    <SimpleBar
      className="content-panel-body expert-profile-body no-header simplebar-light"
      style={{ overflowX: "hidden" }}
    >
      <div
        className={clsx("panel-header", {
          ready: isProfileFetched
        })}
      >
        <div className="btn-close" onClick={closePanel}>
          <i className="ciq-icon ciq-close" />
        </div>
      </div>
      <div className="panel-body">
        <div className="middle-container">
          {/* @ts-ignore */}
          <WrappedExpertProfileHolder
            projectId={projectId}
            expertId={expertId}
            referralId={referralId}
            onPanelClose={closePanel}
            onProfileFetched={(): void => setIsProfileFetched(true)}
            usageMode={previewMode}
            showAvailabilityTab={showAvailabilityTab}
          />
        </div>
      </div>
    </SimpleBar>
  );
};

export default ExpertProfilePanel;
