import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  Endorsement,
  ErrorResponse,
  ExpertDetail,
  ExpertService
} from "@arbolus-technologies/api";

import {
  ExpertNotificationDetails,
  ExpertProfileFormDetails
} from "../../helpers/types";

export function useProfileDetails(expertDetails: ExpertNotificationDetails) {
  const { t } = useTranslation("expertEditProfile");

  const [expertProfile, setExpertProfile] =
    useState<ExpertProfileFormDetails | null>(null);
  const [endorsements, setEndorsements] = useState<Endorsement[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getExpertDetails = useCallback(() => {
    ExpertService.getExpertById({
      expertId: expertDetails.expertId!,
      includeRateCard: true
    }).subscribe(
      (expert: ExpertDetail) => {
        const expertForm = getExpertFormFormatted(expert);
        setEndorsements(expert.endorsements);
        setExpertProfile(expertForm);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        DefaultToasterService.showError(error.message);
        setIsLoading(false);
      }
    );
  }, []);

  const updateExpertExperience = useCallback((background: string) => {
    const { id, overview, linkedinProfile, experienceLevel } = expertProfile!;

    ExpertService.updateExpertProfile(
      id,
      overview!,
      experienceLevel!,
      ["", ""],
      linkedinProfile,
      "",
      background
    ).subscribe(
      () => {
        setExpertProfile((prevState) => ({ ...prevState!, background }));
        DefaultToasterService.showSuccess(t("experienceUpdateSuccess"));
      },
      () => {
        DefaultToasterService.showError(t("unableToSaveDetails"));
      }
    );
  }, []);

  return {
    getExpertDetails,
    updateExpertExperience,
    isLoading,
    endorsements,
    expertProfile
  };
}

const getExpertFormFormatted = (
  expert: ExpertDetail
): ExpertProfileFormDetails => {
  const {
    id,
    user: { firstName, lastName, phoneNumber, profileImageUrl, email, title },
    linkedinProfile,
    overview,
    experienceLevel,
    rateCards,
    background
  } = expert;

  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    profileImageUrl,
    linkedinProfile,
    title,
    background,
    overview,
    experienceLevel,
    hourlyRate: rateCards && rateCards[0]?.price,
    isoCurrencyCode: rateCards && rateCards[0]?.isoCurrencyCode
  };
};
