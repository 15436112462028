import { SURVEY_PROVIDERS } from "@arbolus-technologies/models/survey";

const {
  GOOGLE_FORMS,
  INC_QUERY,
  INTELLI_SURVEY,
  OTHER,
  QUALTRICS,
  ANTARES_ADVISOR_GROUP,
  WALR
} = SURVEY_PROVIDERS;

// TO_DO: only INC_QUERY formatting has been confirmed, other providers are faked
const surveyLinkRecord: Record<
  SURVEY_PROVIDERS,
  (surveyLink: string, surveyId: string) => string
> = {
  [INC_QUERY]: (surveyLink, surveyId) => `${surveyLink}?id=${surveyId}`,
  [QUALTRICS]: (surveyLink, surveyId) => `${surveyLink}`,
  [INTELLI_SURVEY]: (surveyLink, surveyId) =>
    `${surveyLink}&trans_id=${surveyId}`,
  [ANTARES_ADVISOR_GROUP]: (surveyLink, surveyId) =>
    `${surveyLink}?list=arbolus_PL&A_ID=${surveyId}`,
  [WALR]: (surveyLink, surveyId) => `${surveyLink}?P1=${surveyId}`,
  [GOOGLE_FORMS]: (surveyLink, surveyId) => `${surveyLink}`,
  [OTHER]: (surveyLink, surveyId) => `${surveyLink}`
};

export const buildSurveyLinkWithCustomId = (
  surveyLink: string,
  surveyId: string,
  provider: SURVEY_PROVIDERS
): string => {
  // Fallback to default logic if the provider is not in the mapping
  const buildLink =
    surveyLinkRecord[provider] || surveyLinkRecord[SURVEY_PROVIDERS.OTHER];

  return buildLink(surveyLink, surveyId);
};
