import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectAnglesSelector } from "@arbolus-technologies/stores/project-angles-store";
import { SearchInput } from "@arbolus-technologies/ui/components";

import { AnglesList } from "../../../AnglesList/AnglesList";

import styles from "./MoveToShortlistPanel.module.scss";

interface MoveToShortlistPanelProps {
  onConfirm: (selectedAngle: AngleModel) => void;
  selectedExpertAmount: number;
}

export const MoveToShortlistPanel: React.FC<MoveToShortlistPanelProps> = ({
  onConfirm,
  selectedExpertAmount
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();

  const angles = useSelector(ProjectAnglesSelector.anglesCardsSelector());
  const [filteredAngles, setFilteredAngles] = useState<AngleModel[]>(angles);
  const [selectedAngle, setSelectedAngle] = useState<AngleModel>();

  const closePanel = () => {
    setSelectedAngle(undefined);
    dispatch(PanelStoreActions.closePanel(PanelId.MoveToShortlist));
  };

  const handleConfirm = () => {
    closePanel();
    onConfirm(selectedAngle!);
  };

  const filterAngles = (query: string) => {
    setFilteredAngles(
      angles.filter((angle) =>
        angle.title.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  return (
    <SlidePanel
      panelId={PanelId.MoveToShortlist}
      title={t("moveToShortlistPanelTitle")}
      customCloseRequest={() => setSelectedAngle(undefined)}
    >
      <div className={styles.panelBody}>
        <div className={styles.header}>
          <h3>
            {t("moveToShortlistPanelDescription", { selectedExpertAmount })}
          </h3>
          <SearchInput onQueryChange={filterAngles} hasSearchIcon />
        </div>

        <div className={styles.angles}>
          <AnglesList
            onAngleClick={setSelectedAngle}
            angles={filteredAngles}
            selectedAngles={[selectedAngle?.id ?? ""]}
          />
        </div>

        <div className={styles.footer}>
          <Button
            text={t("moveToShortlistPanelCancelButton")}
            type="tertiary"
            onClick={closePanel}
          />
          <Button
            text={t("moveToShortlistPanelApplyButton")}
            onClick={handleConfirm}
            disabled={!selectedAngle}
          />
        </div>
      </div>
    </SlidePanel>
  );
};
