import { Flex } from "antd";
import React from "react";

import { AntDIcon, IconName } from "@arbolus-technologies/antDComponents";
import { PROJECT_REFERRAL_STATE } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./ExpertInfo.module.scss";

interface AvatarExpertStatusIconInfoProps {
  referralStatus: PROJECT_REFERRAL_STATE;
}

export const AvatarExpertStatusIconInfo: React.FC<
  AvatarExpertStatusIconInfoProps
> = ({ referralStatus }) => {
  const getExpertStatusIconInfo = () => {
    const isShortlist = referralStatus === PROJECT_REFERRAL_STATE.SHORTLIST;
    const isCandidate = referralStatus === PROJECT_REFERRAL_STATE.CANDIDATE;
    const isAccepted = referralStatus === PROJECT_REFERRAL_STATE.ACCEPT;

    if (isShortlist) {
      return {
        color: ARBOLUS_COLORS.bColorWarning,
        iconName: "circle"
      };
    }
    if (isCandidate) {
      return {
        color: ARBOLUS_COLORS.candidateIconBackground,
        iconName: "equal"
      };
    }
    if (isAccepted) {
      return {
        color: ARBOLUS_COLORS.bColorSuccess,
        iconName: "check"
      };
    }
    return {
      color: ARBOLUS_COLORS.bColorError,
      iconName: "close"
    };
  };

  const expertStatusIconInfo = getExpertStatusIconInfo();

  return (
    <Flex
      justify="center"
      align="center"
      className={styles.referralIcon}
      style={{
        backgroundColor: expertStatusIconInfo.color
      }}
    >
      <AntDIcon
        name={expertStatusIconInfo.iconName as IconName}
        color={ARBOLUS_COLORS.bColorBaseWhite}
      />
    </Flex>
  );
};
