import { Button } from "arbolus-ui-components";
import { useTranslation } from "react-i18next";

import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { Slot } from "@arbolus-technologies/utils";

import styles from "./TimeSlotSection.module.scss";

interface TimeSlotSectionProps {
  availabilitySlots?: Slot[];
  doNotContactStatus?: DO_NOT_CONTACT_STATUS;
  renderAvailabilityTab?: () => void;
}

export const TimeSlotSection = ({
  availabilitySlots,
  doNotContactStatus,
  renderAvailabilityTab
}: TimeSlotSectionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.expertAvailability}>
      <div className={styles.titleButtonContainer}>
        <h3>{t("common:timeSlotButton:callScheduling")}</h3>
        <Button
          text={t("common:timeSlotButton:book")}
          disabled={
            !availabilitySlots?.length ||
            doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
          }
          onClick={renderAvailabilityTab}
        />
      </div>
      <div className={styles.availabilityContainer}>
        <div className={styles.richContent}>
          {t("common:timeSlotButton:checkExpertAvailabilityContent")}
        </div>
      </div>
    </div>
  );
};
