import { Document, EventAttachmentModel } from "@arbolus-technologies/api";
import { Drawer } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { AttachmentsPanelBody } from "./AttachmentsPanelBody/AttachmentsPanelBody";
import { AttachmentsPanelFooter } from "./AttachmentsPanelFooter/AttachmentsPanelFooter";

interface AttachmentsPanelProps {
  attachments: EventAttachmentModel[];
  preselectedAttachments: EventAttachmentModel[];
  handleSelectPreselectedAttachment: (attachment: Document) => void;
  handleAddAttachments: () => void;
  isOpen: boolean;
  handleCloseAttachmentsPanel: () => void;
  projectId: string;
  isUploading: boolean;
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  handleRefetchDocuments: (shouldRefetch: boolean) => void;
  refetchDocuments: boolean;
}

export const AttachmentsPanel: React.FC<AttachmentsPanelProps> = ({
  attachments,
  preselectedAttachments,
  handleSelectPreselectedAttachment,
  handleAddAttachments,
  isOpen,
  handleCloseAttachmentsPanel,
  projectId,
  isUploading,
  fileInputRef,
  handleRefetchDocuments,
  refetchDocuments
}) => {
  const { t } = useTranslation("attachmentsPanel");

  return (
    <Drawer
      title={t("attachments")}
      onClose={handleCloseAttachmentsPanel}
      open={isOpen}
      destroyOnClose
      styles={{
        footer: {
          padding: "16px 24px"
        },
        body: {
          overflow: "hidden"
        }
      }}
      footer={
        <AttachmentsPanelFooter
          preselectedAttachments={preselectedAttachments}
          handleAddAttachments={handleAddAttachments}
          isUploading={isUploading}
          fileInputRef={fileInputRef}
        />
      }
    >
      <AttachmentsPanelBody
        projectId={projectId}
        attachments={attachments}
        preselectedAttachments={preselectedAttachments}
        handleSelectPreselectedAttachment={handleSelectPreselectedAttachment}
        handleRefetchDocuments={handleRefetchDocuments}
        refetchDocuments={refetchDocuments}
      />
    </Drawer>
  );
};
