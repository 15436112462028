import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Flex } from "antd";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { EventAttachmentModel } from "@arbolus-technologies/api";
import { acceptFileTypes } from "@arbolus-technologies/models/documents";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

interface AttachmentsPanelFooterProps {
  handleAddAttachments: () => void;
  isUploading: boolean;
  preselectedAttachments: EventAttachmentModel[];
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
}

export const AttachmentsPanelFooter: React.FC<AttachmentsPanelFooterProps> = ({
  handleAddAttachments,
  isUploading,
  preselectedAttachments,
  fileInputRef
}) => {
  const { t } = useTranslation("attachmentsPanel");

  return (
    <Flex gap={12} align="center" justify="flex-end">
      <Button
        disabled={isUploading}
        icon={
          <AntDIcon
            name="upload"
            fontSize="16px"
            color={ARBOLUS_COLORS.bColorBaseBlack}
          />
        }
        iconPosition="start"
        onClick={() => fileInputRef.current?.click()}
        loading={isUploading}
      >
        {t("upload")}
      </Button>

      <input
        type="file"
        id="uploadFile"
        name="uploadFile"
        multiple
        accept={acceptFileTypes}
        ref={fileInputRef}
        style={{ display: "none" }}
      />

      <Button
        type="primary"
        onClick={handleAddAttachments}
        disabled={preselectedAttachments.length === 0}
      >
        {t("addSelection")}
      </Button>
    </Flex>
  );
};
