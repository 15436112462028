import { Card, Flex, Form, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { EVENT_FORM } from "@arbolus-technologies/models/project";
import { Attachments } from "./Attachments/Attachments";

const { Title, Text } = Typography;
const { ATTACHMENTS } = EVENT_FORM;

interface EventAttachmentsProps {
  projectId: string;
}

export const EventAttachments: React.FC<EventAttachmentsProps> = ({
  projectId
}) => {
  const { t } = useTranslation("eventForm");

  return (
    <Card>
      <Flex vertical gap={16}>
        <Title level={5}>{t("attachments")}</Title>
        <Flex vertical>
          <Text>{t("attachmentsDescription")}</Text>
          <Text>{t("attachmentsMaxSize")}</Text>
        </Flex>
        <Form.Item name={ATTACHMENTS} style={{ marginBottom: 0 }}>
          <Attachments projectId={projectId} />
        </Form.Item>
      </Flex>
    </Card>
  );
};
