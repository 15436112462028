import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { Button, Flex, Typography } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { STEPS } from "../../../../models";

const mapping = {
  [STEPS.SelectUser]: "selectUser",
  [STEPS.SelectCompany]: "selectCompany",
  [STEPS.CreateCompany]: "addCompany"
};

interface SlidePanelTitleProps {
  slidePanelStep: STEPS;
  submitDisabled?: boolean;
  setSlidePanelStep: (step: STEPS) => void;
  cleanSearchTerm: () => void;
  setInitialState: () => void;
  postRequest: () => void;
}

export const SlidePanelTitle: React.FC<SlidePanelTitleProps> = ({
  slidePanelStep,
  submitDisabled = false,
  setSlidePanelStep,
  cleanSearchTerm,
  setInitialState,
  postRequest
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("createCompanyMonitoringList");

  const headerButtonTitle = useCallback(() => {
    switch (slidePanelStep) {
      case STEPS.SelectUser:
        return "next";
      case STEPS.SelectCompany:
        return "add";
      case STEPS.CreateCompany:
        return "create";
      default:
        return "next";
    }
  }, [slidePanelStep]);

  const onSubmitButtonClick = useCallback(() => {
    switch (slidePanelStep) {
      case STEPS.SelectUser:
        cleanSearchTerm();
        setSlidePanelStep(STEPS.SelectCompany);
        break;
      case STEPS.SelectCompany:
      case STEPS.CreateCompany:
        postRequest();
        break;
      default:
        setSlidePanelStep(STEPS.SelectUser);
        break;
    }
  }, [slidePanelStep, setSlidePanelStep, cleanSearchTerm]);

  const closePanel = useCallback(() => {
    dispatch(PanelStoreActions.closePanel(PanelId.AddMonitoringListCompany));
    setInitialState();
    cleanSearchTerm();
  }, [setInitialState, cleanSearchTerm, dispatch]);

  return (
    <Flex justify="space-between" align="center">
      <Typography.Title level={5}>
        {t(mapping[slidePanelStep])}
      </Typography.Title>
      <Flex gap={8}>
        <Button onClick={closePanel}>{t("cancel")}</Button>
        <Button
          type="primary"
          onClick={onSubmitButtonClick}
          disabled={submitDisabled}
          htmlType="submit"
        >
          {t(headerButtonTitle())}
        </Button>
      </Flex>
    </Flex>
  );
};
