/* eslint-disable @typescript-eslint/no-empty-function */
import clsx from "clsx";
import { push } from "connected-react-router";
import { ChangeEvent, Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Col, Input, InputGroup, InputGroupAddon, Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import { ClientUserSlidePanel } from "@arbolus-technologies/features/users";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { Loader } from "@arbolus-technologies/ui/components";

import { MAX_PAGE_SIZE } from "../../../../../constants/api";
import { NEW_USER } from "../../../../../constants/navigation/authRoutes";
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../../../../constants/timer";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_TEAM_MANAGEMENT_PANEL
} from "../../../../../constants/ui";
import { UserConstraints } from "../../../../../constants/validation";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../../contexts/contentPanel/ContentPanelContext";
import AccessManager from "../../../../../contexts/roleBasedAccess/AccessManager";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { Member } from "../../../../../models/project";
import { LoggedInUser, User } from "../../../../../models/user";
import { ProjectMember } from "../../../../../models/view/project";
import { ProjectService, UtilsService } from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { AppState } from "../../../../../store/reducers";
import { CIQInfiniteScroll, CIQNoResults } from "../../../../app/components";
import { AuthSelector } from "../../../../auth/store";
import { ProjectSelector, ProjectStoreActions } from "../../../store";
import MemberList from "./MemberList";

const notification = new ToasterService();

interface TeamPanelStoreProps {
  projectId?: string;
  loggedInUser: LoggedInUser;
  projectMembers: ProjectMember[];
  clientName?: string;
  clientId?: string;
}

interface TeamPanelProps extends TeamPanelStoreProps, WithTranslation {
  onPanelClose: () => void;
  addMember: (member: Member) => void;
  removeMember: (memberId: string) => void;
  openClientUserSlidePanel: () => void;
  navigateToNewUserPage: (projectId: string, email: string) => void;
}

interface TeamPanelState {
  candidateMembers: ProjectMember[];
  searchQuery: string;
  isLoading: boolean;
  hasMoreCandidates: boolean;
  loadingUsers: string[];
  isDirectLoading: boolean;
  emailQuery: string;
  selectedUser: User | null;
}

export enum MEMBER_TYPE {
  CLIENT,
  PROJECT
}

class TeamPanel extends Component<TeamPanelProps, TeamPanelState> {
  static defaultProps = {
    projectMembers: [],
    loggedInUser: {} as LoggedInUser,
    addMember: (): void => {},
    removeMember: (): void => {},
    openClientUserSlidePanel: (): void => {},
    navigateToNewUserPage: (): void => {}
  };

  constructor(props: TeamPanelProps) {
    super(props);
    this.state = {
      candidateMembers: [],
      searchQuery: "",
      isLoading: false,
      hasMoreCandidates: true,
      loadingUsers: [],
      isDirectLoading: false,
      emailQuery: "",
      selectedUser: null
    };
  }

  componentWillUnmount(): void {
    this.projectCandidateMembersSubscription?.unsubscribe();
    this.addMemberSubscription?.unsubscribe();
    this.removeMemberSubscription?.unsubscribe();
    this.directInviteMemberSubscription?.unsubscribe();
    if (this.searchQueryDebounceTimeout) {
      clearTimeout(this.searchQueryDebounceTimeout);
    }
  }

  private projectCandidateMembersSubscription?: Subscription;

  private removeMemberSubscription?: Subscription;

  private addMemberSubscription?: Subscription;

  private directInviteMemberSubscription?: Subscription;

  private initialMemberCount: number | undefined;

  private searchQueryDebounceTimeout: number | undefined;

  fetchCandidateMembers = (): void => {
    const { projectId } = this.props;
    const { searchQuery, candidateMembers } = this.state;

    this.setState({ isLoading: true });

    this.projectCandidateMembersSubscription =
      ProjectService.getProjectCandidates(
        projectId!,
        searchQuery,
        candidateMembers.length,
        this.initialMemberCount || MAX_PAGE_SIZE
      ).subscribe(
        (response) => {
          const allCandidates = candidateMembers.concat(
            response.items.map((c) => ({
              email: c.email,
              firstName: c.firstName!,
              lastName: c.lastName!,
              profileImageUrl: c.profileImageUrl,
              userId: c.id,
              emailConfirmed: c.emailConfirmed,
              isCiqManager: c.isCiqManager,
              clientId: c.clientId
            }))
          );
          this.setState({
            candidateMembers: allCandidates,
            isLoading: false,
            hasMoreCandidates: response.pagination.count > allCandidates.length,
            emailQuery: UtilsService.validateEmail(searchQuery.trim())
              ? searchQuery.trim()
              : ""
          });
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          this.setState({
            isLoading: false
          });
        }
      );
  };

  handleClearQueryClicked = (): void => {
    this.setState(
      {
        searchQuery: "",
        emailQuery: "",
        isLoading: false
      },
      () => this.projectCandidateMembersSubscription?.unsubscribe()
    );
  };

  handleRemoveMember = (memberId: string, userId: string): void => {
    const { projectId, removeMember } = this.props;
    const { loadingUsers } = this.state;

    this.setState({ loadingUsers: loadingUsers.concat(userId) });

    this.removeMemberSubscription = ProjectService.removeProjectMember(
      projectId!,
      memberId
    ).subscribe(
      (response) => {
        if (response.deleted) {
          this.setState(
            (prevState) => ({
              loadingUsers: prevState.loadingUsers.filter((id) => id !== userId)
            }),
            () => removeMember(memberId)
          );
        }
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        this.setState((prevState) => ({
          loadingUsers: prevState.loadingUsers.filter((id) => id !== userId)
        }));
      }
    );
  };

  handleClientMemberAdd = (userId: string): void => {
    const { projectId, addMember } = this.props;
    const { candidateMembers, loadingUsers } = this.state;

    this.setState({ loadingUsers: loadingUsers.concat(userId) });

    this.addMemberSubscription = ProjectService.addProjectMember(
      projectId!,
      userId
    ).subscribe(
      ({ id }) => {
        const addedMember = candidateMembers.find((m) => m.userId === userId);
        const {
          firstName,
          lastName,
          email,
          profileImageUrl,
          emailConfirmed,
          isCiqManager,
          clientId
        } = addedMember!;
        addMember({
          id,
          userId,
          user: {
            id: userId,
            firstName,
            lastName,
            email,
            profileImageUrl,
            emailConfirmed,
            isCiqManager,
            clientId
          }
        } as Member);
        this.setState((prevState) => ({
          candidateMembers: prevState.candidateMembers.filter(
            (member) => member.userId !== userId
          ),
          loadingUsers: prevState.loadingUsers.filter((uId) => uId !== userId)
        }));
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        this.setState((prevState) => ({
          loadingUsers: prevState.loadingUsers.filter((id) => id !== userId)
        }));
      }
    );
  };

  handleDirectInvite = (
    contentPanelContext: ContentPanelContextProps
  ): void => {
    const { projectId, navigateToNewUserPage } = this.props;
    const { searchQuery } = this.state;
    contentPanelContext.closePanel();
    navigateToNewUserPage(projectId!, searchQuery.toLowerCase());
  };

  handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const nextQuery = event.target.value;
    this.setState(
      {
        searchQuery: nextQuery
      },
      () => {
        if (this.searchQueryDebounceTimeout)
          clearTimeout(this.searchQueryDebounceTimeout);

        this.projectCandidateMembersSubscription?.unsubscribe();

        if (nextQuery.length) {
          this.searchQueryDebounceTimeout = setTimeout(
            () => {
              this.setState(
                {
                  candidateMembers: []
                },
                this.fetchCandidateMembers
              );
            },
            nextQuery.length === 1 ? 0 : SEARCH_DEBOUNCE_TIMEOUT
          );
        } else {
          this.setState({
            isLoading: false
          });
        }
      }
    );
  };

  handleInitialFetch = (memberCount?: number): void => {
    this.initialMemberCount = memberCount;
  };

  handleBottomReached = (): void => {
    const { hasMoreCandidates, isLoading, searchQuery } = this.state;
    if (searchQuery !== "" && hasMoreCandidates && !isLoading) {
      this.fetchCandidateMembers();
    }
  };

  renderEmailOrMessage = (
    contentPanelContext: ContentPanelContextProps
  ): JSX.Element => {
    const { isDirectLoading, searchQuery, emailQuery } = this.state;
    const { projectMembers, t } = this.props;
    const projectMembersEmail: string[] = projectMembers.map((m) => m.email);

    const filteredEmails = projectMembersEmail.filter(
      (m) => m === searchQuery.trim().toLocaleLowerCase()
    );

    if (filteredEmails.length) {
      return <CIQNoResults message={t("userExists")} />;
    }

    if (emailQuery) {
      if (searchQuery.length > UserConstraints.MAX_EMAIL_LENGTH) {
        return (
          <CIQNoResults
            message={t("maxEmailLength", {
              length: UserConstraints.MAX_EMAIL_LENGTH
            })}
          />
        );
      }
      if (
        searchQuery.length < UserConstraints.MAX_EMAIL_LENGTH &&
        !projectMembersEmail.includes(searchQuery)
      ) {
        return (
          <AccessManager
            permission="projectTeam:inviteTeamByEmail"
            deniedElement={<CIQNoResults message={t("inviteByName")} />}
          >
            <div
              className={clsx("invite-item", {
                disabled: isDirectLoading
              })}
              onClick={(): void => {
                this.handleDirectInvite(contentPanelContext);
              }}
            >
              <i className="ciq-icon ciq-plus" />
              <div className="invite-name">{emailQuery.toLowerCase()}</div>
            </div>
          </AccessManager>
        );
      }
    }
    return (
      <AccessManager
        permission="projectTeam:inviteTeamByEmail"
        deniedElement={<CIQNoResults message={t("inviteByName")} />}
      >
        <CIQNoResults message={t("inviteByEmailMessage")} />
      </AccessManager>
    );
  };

  renderMembers = (
    contentPanelContext: ContentPanelContextProps
  ): JSX.Element => {
    const { loadingUsers, searchQuery, candidateMembers, isLoading } =
      this.state;

    const { projectMembers, loggedInUser } = this.props;

    if (searchQuery) {
      if (candidateMembers.length === 0 && !isLoading) {
        return this.renderEmailOrMessage(contentPanelContext);
      }
      return (
        <MemberList
          memberType={MEMBER_TYPE.CLIENT}
          members={candidateMembers}
          onAddMember={this.handleClientMemberAdd}
          loadingUsers={loadingUsers}
        />
      );
    }

    return (
      <MemberList
        memberType={MEMBER_TYPE.PROJECT}
        members={projectMembers}
        onRemoveMember={this.handleRemoveMember}
        loadingUsers={loadingUsers}
        currentUserId={loggedInUser.id}
        onClientUserSelected={(user: ProjectMember) => {
          this.setState(
            { selectedUser: { ...user, id: user.userId } as User },
            () => {
              this.props.openClientUserSlidePanel();
            }
          );
        }}
      />
    );
  };

  renderTopSearchBar = (isNameSearchOnly = false): JSX.Element => {
    const { searchQuery } = this.state;
    const { t } = this.props;
    return (
      <div className="top-container">
        <div className="search-filter-container">
          <Row className="search-filter-bar">
            <Col className="search-column">
              <InputGroup className="search-input-white">
                <Input
                  autoComplete="off"
                  value={searchQuery}
                  placeholder={t(
                    isNameSearchOnly ? "inviteWithName" : "inviteWithEmailName"
                  )}
                  name="searchQuery"
                  onChange={this.handleSearchQueryChange}
                />
                <InputGroupAddon addonType="append">
                  <span
                    onClick={this.handleClearQueryClicked}
                    className={clsx("ciq-icon", {
                      "ciq-search": !searchQuery,
                      "ciq-close-circle": searchQuery
                    })}
                  />
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render(): JSX.Element {
    const { isLoading, selectedUser } = this.state;
    const { t, clientId, clientName } = this.props;

    return (
      <Media queries={APP_DEVICE_MEDIA_QUERIES}>
        {(matches): JSX.Element => (
          <ContentPanelContextConsumer>
            {(contentPanelContext: ContentPanelContextProps): JSX.Element => (
              <div className="project-members-container">
                <div className="content-panel-body">
                  <div className="panel-header">
                    <AccessManager permission="projectTeam:teamPanelTitle">
                      <h2>{t("projectTeam")}</h2>
                    </AccessManager>
                    <AccessManager permission="projectTeam:teamPanelExpertTitle">
                      <h2>{t("clientTeam")}</h2>
                    </AccessManager>

                    <div
                      className="btn-close"
                      onClick={contentPanelContext.closePanel}
                    >
                      <i className="ciq-icon ciq-close" />
                    </div>
                  </div>
                </div>
                <div className="panel-body">
                  <AccessManager permission="projectTeam:inviteTeam">
                    <AccessManager
                      permission="projectTeam:inviteTeamByEmail"
                      deniedElement={this.renderTopSearchBar(true)}
                    >
                      {this.renderTopSearchBar()}
                    </AccessManager>
                  </AccessManager>
                </div>

                <div className="user-list-container">
                  <CIQInfiniteScroll
                    style={{
                      maxHeight: UI_TEAM_MANAGEMENT_PANEL.PANEL_LIST_HEIGHT,
                      height: UI_TEAM_MANAGEMENT_PANEL.PANEL_LIST_HEIGHT,
                      overflowX: "hidden"
                    }}
                    elementHeight={UI_TEAM_MANAGEMENT_PANEL.TEAM_MEMBER_ELEMENT_HEIGHT(
                      matches
                    )}
                    className=" users-list simplebar-light"
                    onBottomReached={this.handleBottomReached}
                    onInitialFetch={this.handleInitialFetch}
                  >
                    {this.renderMembers(contentPanelContext)}
                    {isLoading && <Loader />}
                  </CIQInfiniteScroll>
                </div>
                <ClientUserSlidePanel
                  user={selectedUser}
                  clientName={clientName}
                  clientId={clientId}
                />
              </div>
            )}
          </ContentPanelContextConsumer>
        )}
      </Media>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  TeamPanelProps,
  TeamPanelStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  loggedInUser: AuthSelector.authUserSelector(),
  projectMembers: ProjectSelector.projectTeamMembersSelector(),
  clientId: AuthSelector.authClientIdSelector(),
  clientName: AuthSelector.authClientNameSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  addMember: (member: Member): AppAction =>
    dispatch(ProjectStoreActions.addMemberToProject(member)),
  removeMember: (memberId: string): AppAction => {
    dispatch(ProjectStoreActions.removeMemberFromProject(memberId));
  },
  openClientUserSlidePanel: () => {
    dispatch(PanelStoreActions.openPanel(PanelId.ClientProfile));
  },
  navigateToNewUserPage: (projectId: string, email: string): AppAction => {
    dispatch(push(NEW_USER, { projectId, email }));
  }
});

export default withTranslation("teamManagementPanel")(
  connect(mapStateToProps, mapDispatchToProps)(TeamPanel)
);
