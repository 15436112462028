import { Button, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface CompanyNotFoundProps {
  setSlidePanelStep: () => void;
}
export const CompanyNotFound: React.FC<CompanyNotFoundProps> = ({
  setSlidePanelStep
}) => {
  const { t } = useTranslation("createCompanyMonitoringList");
  return (
    <Flex justify="space-between" align="center">
      <Typography.Text type="secondary">{t("companyNotFound")}</Typography.Text>
      <Button type="primary" onClick={setSlidePanelStep}>
        {t("createCompany")}
      </Button>
    </Flex>
  );
};
