import mixpanel from "mixpanel-browser";

import { DetailedLoggedInUser } from "@arbolus-technologies/stores/cache";
import { DISABLE_MIXPANEL } from "@arbolus-technologies/utils";

import { MixPanelEvent } from "./enums";
import { MixPanelProperties, MixpanelUserData } from "./types";

// track Event in Mixpanel
export const trackEvent = (
  eventName: MixPanelEvent,
  properties?: MixPanelProperties
): void => {
  if (DISABLE_MIXPANEL) return;

  mixpanel.track(eventName, properties);
};

// track Page in Mixpanel
export const trackPageView = (properties?: MixPanelProperties): void => {
  if (DISABLE_MIXPANEL) return;
  mixpanel.track_pageview(properties);
};

// Attach a time event to the tracking event with the same Event Name.
export const addEventInitialization = (eventName: MixPanelEvent): void => {
  if (DISABLE_MIXPANEL) return;
  mixpanel.time_event(eventName);
};

// link user to the id
export const identifyUser = (id: string): void => {
  if (DISABLE_MIXPANEL) return;
  mixpanel.identify(id);
};

// set User Details in order to link all events to a single user and identify that user with their details
export const setUserProfileDetails = (userDetails: MixpanelUserData): void => {
  if (DISABLE_MIXPANEL) return;

  const {
    loggedInId,
    accountType,
    clientId,
    firstName,
    lastName,
    email,
    title
  } = userDetails;

  // set User profile details
  const user = {
    $accountType: accountType,
    $clientId: clientId,
    $email: email,
    $loggedInId: loggedInId,
    $name: `${firstName} ${lastName}`,
    $title: title
  };
  mixpanel.people.set(user);

  // set Super properties that will be automatically attached to every single event
  mixpanel.register({ loggedInId, accountType, clientId });
};

// Reset the mixpanel user details on logout
export const resetMixpanelOnLogout = (): void => {
  if (DISABLE_MIXPANEL) return;
  mixpanel.reset();
};

export function convertUserDataForMixpanel(
  userData: DetailedLoggedInUser
): MixpanelUserData {
  const {
    loggedInId,
    accountType,
    clientId,
    firstName,
    lastName,
    email,
    title
  } = userData;

  return {
    loggedInId,
    accountType,
    clientId,
    firstName,
    lastName,
    email,
    title
  };
}
