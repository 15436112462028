import { List } from "antd";
import { useMemo } from "react";

import { UserCompanyItem } from "@arbolus-technologies/api";

import { AddNewCompanyButton } from "./AddNewCompanyButton";
import { CompanyItem } from "./CompanyItem";
import { LoadMoreButton } from "./LoadMoreButton";

import styles from "./CompanyList.module.scss";

interface CompanyListProps {
  companies: UserCompanyItem[];
  isLoading: boolean;
  totalCount: number;
  searchTerm: string;
  getCompanies: () => void;
  onCompanyCreated: (company: UserCompanyItem) => void;
  selectedCompanies: UserCompanyItem[];
  toggleCompany: (company: UserCompanyItem, checked: boolean) => void;
}

export const CompanyList: React.FC<CompanyListProps> = ({
  companies,
  isLoading,
  totalCount,
  searchTerm,
  getCompanies,
  onCompanyCreated,
  selectedCompanies,
  toggleCompany
}) => {
  const showLoadMoreButton =
    !searchTerm && !isLoading && companies.length < totalCount;

  const isExactMatch = useMemo(
    () =>
      companies.some(
        (company) => company.name.toLowerCase() === searchTerm.toLowerCase()
      ),
    [companies, searchTerm]
  );

  const showAddCompanyButton = searchTerm && !isExactMatch;

  return (
    <List
      header={
        showAddCompanyButton && (
          <AddNewCompanyButton
            searchTerm={searchTerm}
            onCompanyCreated={onCompanyCreated}
          />
        )
      }
      dataSource={companies}
      renderItem={(item: UserCompanyItem) => (
        <CompanyItem
          key={item.id}
          item={item}
          isChecked={
            !!selectedCompanies.find(
              (selectedCompany) => selectedCompany.id === item.id
            )
          }
          onCheckboxChange={toggleCompany}
        />
      )}
      loadMore={showLoadMoreButton && <LoadMoreButton onClick={getCompanies} />}
      className={styles.list}
      loading={isLoading}
    />
  );
};
