import { Avatar, Card, Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";

import { RequestReport } from "../../Components/RequestReport/RequestReport";
import { CompanyData } from "../../models";
import { DataBoxFields } from "./DataBoxFields";

export function CompanyDataBox({ companyData }: { companyData: CompanyData }) {
  const { t } = useTranslation("companyData");

  const {
    vendorCompanyId,
    vendorCompanyLogoUrl,
    vendorCompanyName,
    vendorCompanyWebsite
  } = companyData;

  return (
    <Card>
      <Flex gap={47} align="center">
        <Flex gap={8}>
          <Avatar size="large" src={vendorCompanyLogoUrl}>
            {vendorCompanyLogoUrl ?? <AntDIcon name="business" />}
          </Avatar>
          <Flex vertical>
            <Typography.Text strong>{vendorCompanyName}</Typography.Text>
            <Typography.Text style={{ fontSize: "12px" }}>
              {`www.${vendorCompanyWebsite}`}
            </Typography.Text>
          </Flex>
        </Flex>
        <Flex justify="space-between" flex={1} align="center">
          <DataBoxFields companyData={companyData} />

          <RequestReport
            companyId={vendorCompanyId}
            companyName={vendorCompanyName}
          />
        </Flex>
      </Flex>
    </Card>
  );
}
