import { LocationDescriptorObject } from "history";

import { BASE_URL } from "@arbolus-technologies/utils";

export const EVENT_PANEL_HASH = "eventId";

export const PROJECT_BASE_ROUTE = "/project";
const SINGLE_PROJECT_BASE = `${PROJECT_BASE_ROUTE}/:projectId`;
export const PROJECT_SHORTLIST = `${SINGLE_PROJECT_BASE}/shortlist`;
export const PROJECT_CANDIDATE = `${SINGLE_PROJECT_BASE}/candidate`;
export const PROJECT_SCHEDULING = `${SINGLE_PROJECT_BASE}/scheduling`;
export const PROJECT_BOOKED = `${SINGLE_PROJECT_BASE}/booked`;
export const PROJECT_REJECTED = `${SINGLE_PROJECT_BASE}/rejected`;
export const PROJECT_CREATE_EVENT = `${SINGLE_PROJECT_BASE}/event`;
export const PROJECT_EDIT_EVENT = `${SINGLE_PROJECT_BASE}/event/:eventId`;

export const PROJECT_DRAFT_BASE_ROUTE = (): LocationDescriptorObject => ({
  pathname: "/draft"
});

export const MAIN_PROJECTS_ROUTE = (): LocationDescriptorObject => ({
  pathname: "/projects"
});

export const PROJECT_DRAFTS_ROUTE = (): LocationDescriptorObject => ({
  pathname: "/project-drafts"
});

export const PROJECT_ROUTE = (projectId: string): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}`
});

export const PROJECT_CREATE_EVENT_ROUTE = (
  projectId: string,
  search?: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/event`,
  search
});

export const PROJECT_EDIT_EVENT_ROUTE = (
  projectId: string,
  eventId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/event/${eventId}`
});

export const PROJECT_ROUTE_BY_ANGLE = (
  projectId: string,
  angleId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}`,
  search: `angleId=${angleId}`
});

export const PROJECT_CHAT_BASE = `${SINGLE_PROJECT_BASE}/chat`;
export const PROJECT_CHAT_BASE_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/chat`;

export const PROJECT_CHAT = `${SINGLE_PROJECT_BASE}/chat/:chatId`;
export const PROJECT_CHAT_ROUTE = (projectId: string, chatId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/chat/${chatId}`;

export const PROJECT_CALENDAR_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/calendar`
});

export const PROJECT_CALENDAR_ROUTE_OLD = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/calendar/old`
});

export const PROJECT_FILES_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/files`
});

export const PROJECT_ALL_REFERRALS = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/experts`,
  search: "referralState=AllExperts"
});

export const PROJECT_ALL_REFERRALS_REVIEW = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/experts`,
  search: "referralState=AllExperts&reviewExperts=true"
});

export const PROJECT_SCHEDULE_REFERRALS_PANEL = (
  projectId: string,
  search?: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/experts`,
  search
});

export const PROJECT_SHORTLISTED_REFERRALS = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/shortlist`
});

export const PROJECT_CANDIDATE_REFERRALS = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/experts`,
  search: "referralState=Candidate"
});

export const PROJECT_APPROVED_REFERRALS = (
  projectId: string,
  filterByType?: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/experts`,
  search: "referralState=Accept",
  state: filterByType ? { sortStatus: filterByType } : null
});

export const PROJECT_REJECTED_REFERRALS = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/experts`,
  search: "referralState=Reject"
});

export const PROJECT_RECOMMENDED_REFERRALS = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/experts`,
  search: "referralState=Recommendations"
});

export const PROJECT_BOOK = (projectId: string): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/experts`,
  search: "referralState=Book"
});

export const PROJECT_EVENT_ROUTE = (
  projectId: string,
  eventId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}`,
  hash: `eventId=${eventId}`
});

export const EVENT_PANEL_ROUTE = (
  eventId: string,
  projectId?: string
): string =>
  `${
    projectId
      ? PROJECT_CALENDAR_ROUTE(projectId).pathname
      : window.location.pathname
  }#${EVENT_PANEL_HASH}=${eventId}`;

// Transcript routes
export const PROJECT_TRANSCRIPTS_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/transcripts`
});

export const PROJECT_TRANSCRIPT = (
  projectId: string,
  transcriptId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/files/transcript/${transcriptId}`
});
export const PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE = (
  projectId: string,
  transcriptId: string
): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/files/transcript/${transcriptId}`;

export const PROJECT_EDIT_ROUTE = (
  projectId: string
): LocationDescriptorObject => {
  const route = PROJECT_ROUTE(projectId);
  return {
    pathname: `${route.pathname}/edit`
  };
};

export const PROJECT_CANOPIES_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/canopies`;

export const PROJECT_SURVEYS_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/survey-list`;

export const PROJECT_CREATE_SURVEY_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/survey`;

export const PROJECT_EDIT_SURVEY_ROUTE = (
  projectId: string,
  surveyId: string
): string => `${PROJECT_BASE_ROUTE}/${projectId}/survey/${surveyId}`;

export const CLIENT_DASHBOARD_ROUTE = (
  clientId: string
): LocationDescriptorObject => ({
  pathname: `/client/${clientId}`
});

export const PROJECT_BRIEF_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/brief`
});

export const PROJECT_SPEND_TRACKER_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/spend-tracker`
});

export const PROJECT_TEAM_MEMBERS = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}`,
  hash: "members"
});

export const PROJECT_AVAILABILITY_ROUTE = (
  projectId: string,
  requireAdditionalTimeSlots = false
): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/availability?requireAdditionalTimeSlots=${requireAdditionalTimeSlots}`;

export const PROJECT_DISCOVER_BASE = `${SINGLE_PROJECT_BASE}/discover`;
export const PROJECT_DISCOVER_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/discover`;

export const PROJECT_SPEND_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/spend`
});

export const PROJECT_SHORTLIST_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/shortlist`
});

export const PROJECT_CANDIDATES_ROUTE = (
  projectId: string,
  search?: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/candidate`,
  search
});

export const PROJECT_SCHEDULING_ROUTE = (
  projectId: string,
  search?: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/scheduling`,
  search
});

export const PROJECT_BOOKED_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/booked`
});

export const PROJECT_REJECTED_ROUTE = (
  projectId: string
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/rejected`
});

export const PROJECT_REFER_EXPERT = `${SINGLE_PROJECT_BASE}/refer-expert`;
export const PROJECT_REFER_EXPERT_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/refer-expert`;

export const PROJECT_EXPERT_AVAILABILITY_ROUTE = (
  projectId: string,
  expertId: string,
  expertName?: string,
  requireAdditionalTimeSlots = false
): LocationDescriptorObject => ({
  pathname: `${PROJECT_BASE_ROUTE}/${projectId}/expert/${expertId}/availability`,
  search: requireAdditionalTimeSlots
    ? `requireAdditionalTimeSlots=${requireAdditionalTimeSlots}`
    : undefined,
  state: { expertName }
});

export const EXTERNAL_SOURCING_BASE = `${SINGLE_PROJECT_BASE}/external-sourcing`;
export const EXTERNAL_SOURCING_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/external-sourcing`;

export const PROJECT_EXPERTS_ROUTE = (
  projectId: string,
  referralState = ""
): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/experts?referralState=${referralState}`;

export const EXPERT_PROJECT_AVAILABILITY_ROUTE = (
  projectId: string,
  requireAdditionalTimeSlots = false
): string =>
  `${BASE_URL}${PROJECT_BASE_ROUTE}/${projectId}/availability?requireAdditionalTimeSlots=${requireAdditionalTimeSlots}`;

export const EDIT_AVAILABILITY_UPDATE_ROUTE = (
  isAdmin: boolean,
  projectId: string
): string =>
  isAdmin
    ? PROJECT_ROUTE(projectId).pathname!
    : PROJECT_CALENDAR_ROUTE(projectId).pathname!;
