import { LocationDescriptorObject } from "history";
import i18next from "i18next";
import moment from "moment";

import { Country } from "@arbolus-technologies/api";
import { convertValueToCurrencyFormat } from "./Currency";

export const maxLengthString = (text: string, maxLength: number): string =>
  text.length >= maxLength
    ? text.substring(0, maxLength) + String.fromCharCode(8230)
    : text;

export const fullName = (name: string, lastName: string): string =>
  `${name} ${lastName}`;

export const getFullNameOrEmailOrId = (
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null
): string => {
  const fullName = [firstName, lastName].filter(Boolean).join(" ").trim();
  return fullName || email || id;
};

export const emptyParagraphTag = "<p></p>";

export const displayUserName = (
  user: Partial<{
    firstName: string;
    lastName: string;
    email: string;
    fullName: string;
  }>,
  isFullName = true
): string => {
  const { firstName, lastName, email, fullName } = user || {};
  if (fullName) return fullName;
  if (isFullName) {
    return firstName && lastName
      ? `${firstName} ${lastName}`
      : firstName || lastName || email || "";
  }
  return firstName || lastName || email || "";
};

export const displayTotalAmount = (
  isCredit: boolean,
  totalProjectSpent: {
    amount: number;
    currencyCode: string | null;
    minimumFractionDigits?: number;
  }
): string => {
  if (isCredit) {
    return `${Number(totalProjectSpent.amount).toFixed(2)} ${i18next.t(
      "common:Credit:credit",
      {
        count: totalProjectSpent.amount
      }
    )}`;
  }

  if (totalProjectSpent.currencyCode) {
    return convertValueToCurrencyFormat(
      totalProjectSpent.amount,
      totalProjectSpent.currencyCode,
      totalProjectSpent.minimumFractionDigits
    );
  }

  return "";
};

interface HighlightWords {
  text: string;
  tags: Array<string | undefined>;
  highlightColor?: string;
  boldedWords?: boolean;
}

export const highlightWords = (highlightWords: HighlightWords): string => {
  const {
    tags,
    text,
    highlightColor = "#96BFFF",
    boldedWords = false
  } = highlightWords;
  if (text.length === 0 || tags.length === 0) return text;

  const escapedTags = tags
    .filter((tag) => tag !== undefined)
    .map((tag) => tag?.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"))
    .join("|");

  const tagRegex = new RegExp(`(?:\\b|,)(${escapedTags})(?:\\b|,)?`, "gi");

  const highlightedText = text.replace(tagRegex, (match) => {
    const sanitizedMatch = match.replace(/\\/g, ""); // Remove any backslashes introduced
    return `<mark style="background-color:${highlightColor}; font-weight:${
      boldedWords ? 600 : "inherit"
    }">${sanitizedMatch}</mark>`;
  });

  return highlightedText;
};

export const isValueTruthy = <TValue>(
  value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined;

export const locationObjectToString = (
  location: LocationDescriptorObject
): string => {
  const { pathname, search, hash } = location;
  const queryString = search
    ? `?${new URLSearchParams(search).toString()}`
    : "";

  // Include the hash in the final string if it exists
  const hashString = hash ? `#${hash}` : "";

  return `${pathname}${queryString}${hashString}`;
};

export const extractBoolEnvVariable = (
  envVariable: string
): boolean | undefined => {
  const variableValue = process.env[envVariable];
  if (typeof variableValue !== "string") return undefined;
  return JSON.parse(variableValue);
};

export const isProdEnv = (): boolean =>
  extractBoolEnvVariable("NX_PUBLIC_PRODUCTION_BUILD") === true;

export const isStagingEnv = (): boolean =>
  extractBoolEnvVariable("NX_PUBLIC_STAGING_BUILD") === true;

export const isDevEnv = (): boolean =>
  extractBoolEnvVariable("NX_PUBLIC_PRODUCTION_BUILD") !== true &&
  extractBoolEnvVariable("NX_PUBLIC_STAGING_BUILD") !== true;

export const DISABLE_MIXPANEL =
  extractBoolEnvVariable("NX_PUBLIC_DISABLE_MIXPANEL") === true;

export const BAIN_CLIENT_ID = process.env["NX_PUBLIC_BAIN_ID"];
export function isBainClient(clientId: string): boolean {
  return clientId === BAIN_CLIENT_ID;
}

// Left for compatibility with old components (ciq-admin and ciq-client legacy)
export const WORKSPACE_TYPES = {
  WORKSPACE: "Workspace",
  CLIENT_SUPPORT: "ClientSupport",
  EXPERT_SUPPORT: "ExpertSupport"
};

export const joinStringsWithSeparator = (
  inputArray: (string | null | undefined)[],
  separator = "-"
): string => inputArray.filter((value) => value != null).join(` ${separator} `);

export function initialsFromFullName(fullName: string) {
  let initials = fullName;
  const nameSplit = initials.split(/\s/);
  // Only in case name is more than one word
  if (nameSplit.length > 1) {
    initials = nameSplit
      .map((part) => part.substring(0, 1).toUpperCase())
      .filter((v) => !!v)
      .slice(0, 2)
      .join("");
  } else {
    initials = initials.substring(0, 1).toUpperCase();
  }
  return initials;
}

export const getMetadataCountryFromThreeLetterCode = (
  metadataCountries: Country[],
  threeLetterCode?: string
) => {
  return metadataCountries.find(
    (metadataCountry) => metadataCountry.threeLetterCode === threeLetterCode
  );
};

export const formatDateToUTC = (
  date?: Date | string | moment.Moment | null
): string | undefined => {
  if (!date) return;

  // If it's a Moment object, convert to UTC ISO string
  if (moment.isMoment(date)) {
    return encodeURIComponent(date.utc().toISOString());
  }

  // Convert to Date and ensure it's valid
  const parsedDate = new Date(date);
  if (Number.isNaN(parsedDate.getTime())) return;

  return encodeURIComponent(parsedDate.toISOString());
};
