import { Trans, useTranslation } from "react-i18next";

import styles from "./RequestConfirmationContent.module.scss";

export const RequestConfirmationContent: React.FC = () => {
  const { t } = useTranslation("userMonitoringListPage");

  return (
    <div className={styles.requestConfirmationContent}>
      <p>
        <Trans
          i18nKey="userMonitoringListPage:requestModalContent1"
          components={[<strong key={0} />]}
        />
      </p>
      <p className={styles.confirmationDisclaimer}>
        {t("requestModalContent2")}
      </p>
    </div>
  );
};
