/* eslint-disable @typescript-eslint/no-empty-function */
import { Button } from "arbolus-ui-components";
import { push } from "connected-react-router";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import SimpleBar from "simplebar-react";

import {
  EventService,
  LIST_EVENTS_ORDER_BY,
  ListEventsParams,
  LoggedInUser,
  SORT_DIRECTION,
  TimeZone,
  User
} from "@arbolus-technologies/api";
import {
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import {
  APP_TRACKING_ROUTES,
  DO_NOT_CONTACT_STATUS,
  SelectOption
} from "@arbolus-technologies/models/common";
import { PROJECT_AVAILABILITY_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";

import { MAX_PAGE_SIZE } from "../../../../constants/api";
import {
  PROJECT_NEW_EVENT_ROUTE,
  PROJECT_ROUTE
} from "../../../../constants/navigation/projectRoutes";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_CALENDAR_PAGE,
  UI_WINDOW_HEIGHT
} from "../../../../constants/ui";
import ContentPanelEvents, {
  ContentPanelEvent,
  ContentPanelEventType
} from "../../../../contexts/contentPanel/ContentPanelEvents";
import {
  NavBarContextConsumer,
  NavBarContextProps
} from "../../../../contexts/navBar/NavBarContext";
import AccessManager from "../../../../contexts/roleBasedAccess/AccessManager";
import { BaseEvent, Event, EventsDuration } from "../../../../models/event";
import { UtilsService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { AppSelector } from "../../../app/store";
import DayHeader from "../../components/calendar/dayHeader/DayHeader";
import EventItem from "../../components/calendar/eventItem/EventItem";
import MonthHeader from "../../components/calendar/monthHeader/MonthHeader";
import { ProjectSelector } from "../../store";

interface CalendarPageStoreProps {
  projectId: string;
  projectTimezone: string;
  timezones: Map<string, TimeZone>;
  eventsDuration: EventsDuration;
  projectName: string;
  currentTimeZone?: SelectOption;
  loggedInUser: LoggedInUser;
}
interface CalendarPageProps extends CalendarPageStoreProps {
  navigateToAnotherRoute: (
    tabRouteName: string,
    params?: Record<string, string>
  ) => void;
  navigateToBase: (tabRouteName: string) => void;
}

interface CalendarPageState {
  events: BaseEvent[];
  hasMoreHistory: boolean;
  isHistoryLoading: boolean;
  historyPage: number;
  isFutureLoading: boolean;
  hasMoreFuture: boolean;
  futurePage: number;
}

type CalendarPageIntersectProps = CalendarPageProps & WithTranslation;

class CalendarPage extends React.Component<
  CalendarPageIntersectProps,
  CalendarPageState
> {
  static defaultProps = {
    projectId: "",
    projectName: "",
    projectTimezone: "",
    timezones: new Map<string, TimeZone>(),
    navigateToAnotherRoute: (): void => {},
    eventsDuration: {} as EventsDuration,
    navigateToBase: (): void => {},
    currentTimeZone: {} as SelectOption
  };

  constructor(props: CalendarPageIntersectProps) {
    super(props);
    this.state = {
      events: [],
      isHistoryLoading: false,
      hasMoreHistory: true,
      isFutureLoading: false,
      historyPage: 0,
      futurePage: 0,
      hasMoreFuture: true
    };
  }

  componentDidMount(): void {
    this.fetchEvent();
    this.scrollRef.current.addEventListener(
      "wheel",
      this.handleCalendarMouseWheel
    );

    this.eventUpdateSubscription = ContentPanelEvents.contentPanelEventSubject
      .pipe(
        filter(({ eventType }) =>
          [ContentPanelEventType.DELETE_EVENT].includes(eventType)
        ),
        filter(({ itemId }) => {
          const { events } = this.state;
          return events.filter((e) => e.id === itemId).length > 0;
        })
      )
      .subscribe(this.handleEventUpdate);
  }

  componentDidUpdate(
    preProps: CalendarPageProps,
    prevState: CalendarPageState
  ): void {
    const { events, isFutureLoading } = this.state;

    if (prevState.events.length !== events.length) {
      const isFutureUpdate = !isFutureLoading && prevState.isFutureLoading;

      if (!isFutureUpdate) {
        this.scrollRef.current.scrollTop =
          this.scrollRef.current.scrollHeight - this.previousScrollHeight;
      }

      const todayElement = document.getElementById("todayElement");

      if (prevState.futurePage === 0 && todayElement) {
        this.scrollRef.current.scrollTop = todayElement.offsetTop - 10;
      }
    }
  }

  componentWillUnmount(): void {
    this.fetchEventSubscription?.unsubscribe();
    this.scrollRef.current.removeEventListener(
      "wheel",
      this.handleCalendarMouseWheel
    );
    this.eventUpdateSubscription?.unsubscribe();
  }

  private fetchEventSubscription?: Subscription;

  private eventUpdateSubscription?: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private scrollRef = createRef<any>();

  private previousScrollHeight = 0;

  private currentTimestamp: Moment = moment();

  private deleteCountFromHistory = 0;

  private deleteCountFromFuture = 0;

  fetchEvent = (isHistory = false): void => {
    const { projectId, eventsDuration, timezones } = this.props;
    const { events, historyPage, futurePage } = this.state;
    const startTime = isHistory
      ? this.currentTimestamp.subtract(1, "day")
      : this.currentTimestamp;
    const startTimeISO = UtilsService.convertActiveZoneToUTC(
      startTime.toDate()
    );

    const isLoadingKey = isHistory ? "isHistoryLoading" : "isFutureLoading";
    const pageKey = isHistory ? "historyPage" : "futurePage";
    const hasMoreKey = isHistory ? "hasMoreHistory" : "hasMoreFuture";
    this.previousScrollHeight = this.scrollRef.current.scrollHeight;

    this.setState<never>({
      [isLoadingKey]: true
    });

    const eventsPaginatedRequest: ListEventsParams = {
      startDate: isHistory ? new Date(2000, 1, 1).toISOString() : startTimeISO,
      endDate: isHistory ? startTimeISO : eventsDuration.end,
      offset:
        (isHistory ? historyPage : futurePage) * MAX_PAGE_SIZE -
        (isHistory ? this.deleteCountFromHistory : this.deleteCountFromFuture),
      orderDirection: isHistory
        ? SORT_DIRECTION.DESCENDING
        : SORT_DIRECTION.ASCENDING,
      orderBy: LIST_EVENTS_ORDER_BY.StartTime,
      limit: MAX_PAGE_SIZE
    };
    this.fetchEventSubscription = EventService.listEvents(
      projectId!,
      eventsPaginatedRequest
    ).subscribe(({ items }) => {
      const mapped = items.map((e) => ({
        ...e,
        organizer: {} as User,
        location: "",
        eventGuests: [],
        timezone: timezones.get(e.timezone)!,
        startTime: new Date(e.startTime),
        endTime: new Date(e.endTime)
      }));
      const processedEvents = this.reprocessEvents(isHistory, events, mapped);

      this.setState<never>(
        {
          [isLoadingKey]: false,
          [pageKey]: isHistory ? historyPage + 1 : futurePage + 1,
          [hasMoreKey]: items.length !== 0,
          events: processedEvents
        },
        () => this.setCalendarMonthHeader()
      );
    });
  };

  setCalendarMonthHeader = (): void => {
    const { scrollTop, offsetTop } = this.scrollRef.current;
    const scrolledUp = scrollTop + offsetTop;
    const monthHeaders =
      this.scrollRef.current.getElementsByClassName("month-header");
    const monthHeadersItems = Array.prototype.slice.call(monthHeaders);
    const nearestNode = _.maxBy(
      monthHeadersItems.filter((e) => e.offsetTop - scrolledUp <= 0),
      (e) => e.offsetTop - scrolledUp
    );

    if (nearestNode) {
      document.getElementById("calendar-month")!.innerText =
        nearestNode.innerText;
    }
  };

  reprocessEvents = (
    isHistory: boolean,
    current: BaseEvent[],
    newEvents: BaseEvent[]
  ): BaseEvent[] => {
    const existingEvents = current.map((e) => e.id);

    // Filter existing events results due to bidirectional querying
    // eslint-disable-next-line no-param-reassign
    newEvents = _.filter(newEvents, (e) => !existingEvents.includes(e.id));

    // Filter out event elapsed more than single day
    const multipleDayEvents = _.filter(
      newEvents,
      (e) =>
        !moment(UtilsService.convertUTCToActiveZone(e.endTime)).isSame(
          UtilsService.convertUTCToActiveZone(e.startTime),
          "d"
        )
    );

    // Filter multiple day events before adding fake elements
    const multipleDayEventIds = multipleDayEvents.map((m) => m.id);
    // eslint-disable-next-line no-param-reassign
    newEvents = _.filter(newEvents, (e) => !multipleDayEventIds.includes(e.id));

    // Create fake event element for multiple day events
    multipleDayEvents.forEach((multipleDayEvent) => {
      const startMoment = moment(
        UtilsService.convertUTCToActiveZone(multipleDayEvent.startTime)
      );
      const endMoment = moment(
        UtilsService.convertUTCToActiveZone(multipleDayEvent.endTime)
      );
      const eventDurationByDays = endMoment
        .clone()
        .startOf("day")
        .diff(startMoment.startOf("day"), "day");
      const offsetFromLastStart = Math.ceil(
        endMoment.diff(endMoment.clone().startOf("day"), "day", true)
      );

      let currentFakeMoment = startMoment.clone().startOf("day");
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < eventDurationByDays + offsetFromLastStart; i++) {
        const fakeEvent = _.clone(multipleDayEvent);

        if (startMoment.isBefore(currentFakeMoment)) {
          fakeEvent.startTime = currentFakeMoment.startOf("day").toDate();
        }

        if (endMoment.isAfter(currentFakeMoment.endOf("day"))) {
          fakeEvent.endTime = currentFakeMoment.endOf("day").toDate();
        }

        newEvents.push(fakeEvent);
        currentFakeMoment = currentFakeMoment.add(1, "day");
      }
    });

    const nextEvents = isHistory
      ? newEvents.reverse().concat(current)
      : current.concat(newEvents);

    return this.processStaticEvents(nextEvents);
  };

  processStaticEvents = (existingEvent: BaseEvent[]): BaseEvent[] => {
    const isTodayEvents =
      _.filter(existingEvent, (e) =>
        moment(UtilsService.convertUTCToActiveZone(e.startTime)).isSame(
          moment(),
          "date"
        )
      ).length !== 0;
    const isTomorrowEvents =
      _.filter(existingEvent, (e) =>
        moment(UtilsService.convertUTCToActiveZone(e.startTime)).isSame(
          moment().add(1, "day"),
          "date"
        )
      ).length !== 0;

    if (!isTodayEvents) {
      const todayEvent = {} as Event;
      todayEvent.startTime = new Date();
      existingEvent.push(todayEvent);
    }

    if (!isTomorrowEvents) {
      const tomorrowEvent = {} as Event;
      tomorrowEvent.startTime = moment().add(1, "day").toDate();
      existingEvent.push(tomorrowEvent);
    }

    return _.sortBy(existingEvent, (e) => moment(e.startTime).format("x"));
  };

  handleEventUpdate = ({ itemId }: ContentPanelEvent): void => {
    const { events } = this.state;
    const event = events.find((e) => e.id === itemId);
    if (event) this.handleEventDelete(event);
  };

  handleCreateEventPressed = (): void => {
    const { navigateToAnotherRoute, projectId } = this.props;
    navigateToAnotherRoute(PROJECT_NEW_EVENT_ROUTE(projectId), {
      from: APP_TRACKING_ROUTES.PROJECT_CALENDAR
    });

    trackEvent(MixPanelEventNames.CreateEvent, {
      action: MixPanelActions.Initialized,
      from: APP_TRACKING_ROUTES.PROJECT_CALENDAR
    });
  };

  handleAvailabilityButtonPressed = (): void => {
    const { navigateToAnotherRoute, projectId } = this.props;
    navigateToAnotherRoute(PROJECT_AVAILABILITY_ROUTE(projectId));
  };

  handleCalendarTopReached = (): void => {
    const { isHistoryLoading, hasMoreHistory } = this.state;

    if (!isHistoryLoading && hasMoreHistory) {
      this.fetchEvent(true);
    }
  };

  handleCalendarBottomReached = (): void => {
    const { isFutureLoading, hasMoreFuture } = this.state;

    if (!isFutureLoading && hasMoreFuture) {
      this.fetchEvent();
    }
  };

  handleCalendarMouseWheel = (event: React.WheelEvent): void => {
    event.stopPropagation();
    const { offsetHeight, scrollHeight } = this.scrollRef.current;

    if (offsetHeight < scrollHeight) {
      this.handleCalendarScroll();
    } else if (event.deltaY < 0) {
      this.handleCalendarTopReached();
    } else if (event.deltaY > 0) {
      this.handleCalendarBottomReached();
    }
  };

  handleCalendarScroll = (): void => {
    const { firstChild, lastChild, scrollTop, offsetTop, offsetHeight } =
      this.scrollRef.current;

    const topEdge = firstChild.offsetTop + offsetTop;
    const bottomEdge = lastChild.offsetTop + lastChild.offsetHeight;
    const scrolledUp = scrollTop + offsetTop;
    const scrolledDown = scrolledUp + offsetHeight;

    this.setCalendarMonthHeader();

    if (scrolledDown >= bottomEdge) {
      this.handleCalendarBottomReached();
    } else if (scrolledUp <= topEdge) {
      this.handleCalendarTopReached();
    }
  };

  handleEventDelete = (event: BaseEvent): void => {
    const { events } = this.state;

    const startMoment = UtilsService.convertUTCToActiveZone(event.startTime);
    if (moment(startMoment).isSameOrAfter(this.currentTimestamp)) {
      this.deleteCountFromFuture += 1;
    } else {
      this.deleteCountFromHistory += 1;
    }

    let nextEvents = events.filter((e) => event.id !== e.id);
    nextEvents = this.processStaticEvents(nextEvents);

    this.setState({
      events: nextEvents
    });
  };

  handleProjectNameClicked = (): void => {
    const { navigateToBase, projectId } = this.props;
    navigateToBase(PROJECT_ROUTE(projectId));
  };

  renderCalendar = (): JSX.Element[][] => {
    const { timezones, projectTimezone, currentTimeZone } = this.props;
    const { events } = this.state;
    const projectTimezoneOption = timezones.get(projectTimezone);

    return events.map((event, index) => {
      const { startTime, id } = event;

      const elementId = id || UtilsService.generateUUID();
      const firstIndex = index === 0;
      const lastIndex = index === events.length - 1;
      const isRealEvent = Boolean(event.id);

      const previousEvent = events[firstIndex ? index : index - 1];

      const nextEvent = events[lastIndex ? index : index + 1];

      const startMoment = moment(
        UtilsService.convertUTCToActiveZone(startTime)
      );
      const prevStartMoment = moment(
        UtilsService.convertUTCToActiveZone(previousEvent.startTime)
      );
      const nextStartMoment = moment(
        UtilsService.convertUTCToActiveZone(nextEvent.startTime)
      );
      const isNextEventSameMonth = nextStartMoment.isSame(startMoment, "month");
      const isNextEventSameDate = nextStartMoment.isSame(startMoment, "date");

      const isToday = startMoment.isSame(moment(), "day");
      const isTomorrow = startMoment.isSame(moment().add(1, "day"), "day");

      let isSameMonth = true;
      let isSameDay = true;
      if (!firstIndex) {
        isSameMonth = startMoment.isSame(prevStartMoment, "month");
        isSameDay = startMoment.isSame(prevStartMoment, "day");
      }

      const isMoreEventForDay =
        _.filter(
          events,
          (e) =>
            moment(UtilsService.convertUTCToActiveZone(e.startTime)).isSame(
              UtilsService.convertUTCToActiveZone(event.startTime),
              "date"
            ) && isRealEvent
        ).length !== 0;

      const content: JSX.Element[] = [];
      if (firstIndex || !isSameMonth) {
        content.push(
          <MonthHeader
            key={`month-header-${event.id}`}
            isFirstEvent={firstIndex}
            event={event}
          />
        );
      }
      if (firstIndex || !isSameDay) {
        content.push(
          <DayHeader
            key={`day-header-${event.id}`}
            isMoreEventForDay={isMoreEventForDay}
            isNextEventSameMonth={isNextEventSameMonth}
            isTomorrow={isTomorrow}
            isToday={isToday}
            event={event}
          />
        );
      }
      if (isRealEvent) {
        content.push(
          <EventItem
            timeZone={
              projectTimezoneOption
                ? {
                    label: projectTimezoneOption.displayText,
                    value: projectTimezoneOption.id,
                    customLabel: projectTimezoneOption.displayText.slice(
                      0,
                      projectTimezoneOption.displayText.indexOf(" ")
                    )
                  }
                : currentTimeZone
            }
            key={`event-item-${elementId}`}
            event={event}
            isNextEventSameDate={isNextEventSameDate}
            isNextEventSameMonth={isNextEventSameMonth}
            isLastIndex={lastIndex}
            isRealEvent={isRealEvent}
          />
        );
      }

      return content;
    });
  };

  render(): JSX.Element {
    const { projectName, t, loggedInUser } = this.props;
    const { isFutureLoading, isHistoryLoading } = this.state;

    const isLoggedInUserExpert = !!loggedInUser.expertId;
    const isExpertDnc =
      isLoggedInUserExpert &&
      loggedInUser.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

    return (
      <Media queries={APP_DEVICE_MEDIA_QUERIES}>
        {(matches): JSX.Element => (
          <NavBarContextConsumer>
            {({ isProjectActionsActive }: NavBarContextProps): JSX.Element => {
              const containerHeight =
                UI_CALENDAR_PAGE.HEADER_HEIGHT(matches) +
                (isProjectActionsActive && !matches.large
                  ? UI_CALENDAR_PAGE.TABS_ELEMENT_HEIGHT_MOBILE
                  : 0);
              const calendarHeight =
                UI_CALENDAR_PAGE.CALENDER_EXCESS_HEIGHT(matches) +
                (isProjectActionsActive && !matches.large
                  ? UI_CALENDAR_PAGE.TABS_ELEMENT_HEIGHT_MOBILE
                  : 0);
              return (
                <div
                  style={{
                    minHeight: `calc(${UI_WINDOW_HEIGHT} - ${containerHeight}px)`,
                    maxHeight: `calc(${UI_WINDOW_HEIGHT} - ${containerHeight}px)`
                  }}
                  className="calendar-page-body page-content-body simplebar-light"
                >
                  <div className="calendar-container m-0 row">
                    <Row className="header-container m-0">
                      <div className="left-container">
                        <h3 onClick={this.handleProjectNameClicked}>
                          {projectName}
                        </h3>
                        <h1 id="calendar-month">{t("loading")}</h1>
                      </div>
                      <div className="right-container">
                        {!isExpertDnc && (
                          <AccessManager permission="calendarPage:updateAvailability">
                            <Button
                              type="secondary"
                              onClick={this.handleAvailabilityButtonPressed}
                              text={t("availability")}
                            />
                          </AccessManager>
                        )}
                        <AccessManager permission="calendarPage:createEvent">
                          <Button
                            onClick={this.handleCreateEventPressed}
                            text={t("event")}
                            startIcon="add"
                          />
                        </AccessManager>
                      </div>
                    </Row>
                  </div>
                  <SimpleBar
                    style={{
                      height: `calc(${UI_WINDOW_HEIGHT} - ${calendarHeight}px )`
                    }}
                    onScroll={this.handleCalendarScroll}
                    className="calendar-body simplebar-transparent"
                    scrollableNodeProps={{
                      ref: this.scrollRef
                    }}
                  >
                    {isHistoryLoading && <Loader />}
                    {this.renderCalendar()}
                    {isFutureLoading && <Loader />}
                  </SimpleBar>
                </div>
              );
            }}
          </NavBarContextConsumer>
        )}
      </Media>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  CalendarPageProps,
  CalendarPageStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  projectName: ProjectSelector.projectNameSelector(),
  projectTimezone: ProjectSelector.projectTimezoneSelector(),
  timezones: AppSelector.appTimezoneMapSelector(),
  eventsDuration: ProjectSelector.projectEventsDurationSelector(),
  currentTimeZone: AppSelector.appGuessCurrentTimeZoneSelector(),
  loggedInUser: CacheSelector.loggedInUser()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToAnotherRoute: (
    tabRouteName: string,
    params?: Record<string, string>
  ): AppAction => dispatch(push(tabRouteName, params)),
  navigateToBase: (tabRouteName: string): AppAction => {
    dispatch(push(tabRouteName));
  }
});

export default withTranslation("calenderPage")(
  connect(mapStateToProps, mapDispatchToProps)(CalendarPage)
);
