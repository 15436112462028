import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { DateString } from "@arbolus-technologies/models/common";
import { WorkHistory } from "@arbolus-technologies/models/expert";

import { WorkHistoryCard } from "./WorkHistoryCard";

const { Text } = Typography;

interface WorkHistoriesProps {
  workHistories: WorkHistory[];
  lastPublicCompanyExpDate: DateString | null;
}

export const WorkHistories: React.FC<WorkHistoriesProps> = ({
  workHistories,
  lastPublicCompanyExpDate
}) => {
  const { t } = useTranslation("expertPanelWorkHistory");

  return (
    <>
      <Text strong>{t("workHistory")}</Text>
      {workHistories?.map((workHistory, index) => (
        <WorkHistoryCard
          key={workHistory.id}
          workHistoryItem={workHistory}
          lastPublicCompanyExpDate={lastPublicCompanyExpDate}
          index={index}
        />
      ))}
    </>
  );
};
