export enum FORM_MODE {
  CREATE = "create",
  EDIT = "edit"
}

export enum ANSWER_TYPE {
  VIDEO = "Video",
  MULTIPLE_CHOICE = "MultipleChoice",
  VALUE = "Value",
  SHORT_TEXT = "ShortText",
  NPS = "Nps"
}

export enum ANSWER_PREFERENCES_TYPE {
  EXACT = "exact",
  UNLIMITED = "unlimited",
  RANGE = "range"
}

export enum ANSWER_PREFERENCES_TYPE_VALUE {
  NUMBER = "Number",
  PERCENTAGE = "Percentage",
  MULTI_RATING = "MultiRating"
}

export enum CANOPY_STATUS {
  DRAFT = "Draft",
  ACTIVE = "Active",
  PAUSED = "Paused",
  ARCHIVED = "Archived",
  GENERATING = "Generating",
  GENERATING_FAILED = "GeneratingFailed"
}

export enum CreateEditQuestionFields {
  ID = "id",
  ORDER = "order",
  QUESTION = "question",
  DETAILS = "details",
  ANSWER_TYPE = "answerType",
  ANSWER_PREFERENCES_TYPE = "answerPreferencesType",
  ANSWER_PREFERENCES_TYPE_VALUE = "answerPreferencesTypeValue",
  ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE = "answerPreferencesTypeValueNumberInitialRange",
  ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE = "answerPreferencesTypeValueNumberFinalRange",
  ANSWER_PREFERENCES_NUMBER_EXACT = "answerPreferencesNumberExact",
  ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE = "answerPreferencesNumberInitialRange",
  ANSWER_PREFERENCES_NUMBER_FINAL_RANGE = "answerPreferencesNumberFinalRange",
  ANSWER_CHOICES = "answerChoices",
  ANSWER_PREFERENCES_TYPE_VALUE_MULTI_RATING_CHOICES = "answerPreferencesTypeValueMultiRatingChoices"
}

export enum VIDEO_TYPE {
  WEBM = "webm",
  MP4 = "mp4",
  MPEG = "mpeg",
  QUICKTIME = "quicktime"
}

export enum VIDEO_MODE_ENUM {
  PLAY = "play",
  RECORD = "record"
}

export enum STATUS_COLORS {
  active = "green",
  draft = "amber",
  paused = "red",
  archive = "grey",
  archived = "grey",
  generating = "grey",
  generatingFailed = "orange"
}

export enum CanopyPageType {
  UserDashboard = "UserDashboard",
  ProjectDashboard = "ProjectDashboard",
  CanopyList = "CanopyList"
}

export enum EXPERT_CANOPY_STATUS {
  IN_REVIEW = "InReview",
  REJECTED = "Rejected",
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete"
}
