import {
  COMPANY_TYPE,
  CompanyBase,
  DateString
} from "@arbolus-technologies/models/common";

import { RELATIONSHIP_TYPE } from "../enums/companiesEnums";
import { CompaniesPaginatedResponse } from "./api";

export interface CompanyRelationship {
  parent?: CompanyBase;
  type: RELATIONSHIP_TYPE;
}

export interface Company extends CompanyBase {
  type?: COMPANY_TYPE;
  expertCount?: number;
  isDnc?: boolean;
  linkedinUrl?: string;
  linkedinUrn?: string;
  website?: string;
  relationship: CompanyRelationship;
  doNotContactUpdatedUserId?: string | null;
  doNotContactUpdatedDate?: DateString | null;
  doNotContactUpdatedByUserFullName?: string | null;
  doNotContactKeywords?: string[];
  industryId?: string;
  insightsCount?: number;
}

export interface UserCompanyItem {
  id: string;
  name: string;
  logoUrl: string;
  insightsCount: number;
}

export type GetCompaniesResponse = CompaniesPaginatedResponse<Company>;

export type GetUserCompaniesResponse =
  CompaniesPaginatedResponse<UserCompanyItem>;

export enum LIST_COMPANIES_ORDER_BY {
  Name = "Name",
  Website = "Website",
  Type = "Type",
  RelationshipType = "Relationship",
  Industry = "Industry",
  LinkedinUrn = "LinkedinUrn",
  DoNotContact = "DoNotContact",
  Actions = "Actions"
}

export type UngroupCompanyResponse = Omit<Company, "relationship"> & {
  relationship: RELATIONSHIP_TYPE;
};
