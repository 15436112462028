import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import React from "react";

import { agGridArbolusIcons } from "./AgGridArbolusIcons";

type ArbolusGridProps<TData> = AgGridReactProps<TData> & {
  setup?: (api: GridApi<TData>) => void;
};

const onGridSizeChanged = ({ api }: GridReadyEvent): void => {
  api.sizeColumnsToFit();
};

export const ArbolusGrid = <TData,>({
  setup,
  onGridReady,
  ...props
}: ArbolusGridProps<TData>): JSX.Element => {
  const internalOnGridReady = React.useCallback(
    (event: GridReadyEvent<TData>): void => {
      setup?.(event.api);
      onGridReady?.(event);
    },
    [onGridReady, setup]
  );

  return (
    <div className="ag-theme-alpine ag-theme-arbolus h-100 with-floating-filter">
      <AgGridReact
        onGridReady={internalOnGridReady}
        colResizeDefault="shift"
        icons={agGridArbolusIcons}
        onGridSizeChanged={onGridSizeChanged}
        // Prop spread location matters - we'll always override cacheBlockSize
        {...props}
        cacheBlockSize={
          props.rowModelType === "serverSide"
            ? (props.cacheBlockSize ?? 30)
            : undefined
        }
      />
    </div>
  );
};
