import { Flex, Typography } from "antd";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import styles from "./AnyQuestions.module.scss";

const { Title } = Typography;

export const AnyQuestions: React.FC = () => {
  const { t } = useTranslation("anyQuestions");

  const supportEmail = () => (
    <Title level={4} className={styles.supportEmail}>
      <Trans i18nKey="supportEmail">
        Please email{" "}
        <a
          href="mailto:survey_support@arbolus.com"
          className={styles.supportEmailLink}
        >
          survey_support@arbolus.com
        </a>
      </Trans>
    </Title>
  );

  return (
    <Flex vertical gap={24} className={styles.container}>
      <Title level={3} className={styles.title}>
        {t("anyQuestions")}
      </Title>
      {supportEmail()}
    </Flex>
  );
};
