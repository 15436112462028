export enum SurveyMixpanelEventNames {
  SurveyLandingPage = "survey_landing_page",
  SurveyStartSurveyButton = "survey_start_survey_button",
  SurveyAcceptAndProceedButton = "survey_accept_and_proceed_button",
  SurveySignIn = "survey_sign_in",
  SurveySignUp = "survey_sign_up",
  SurveyPaymentProceed = "survey_payment_proceed",
  SurveyPaymentGiftCardThankYou = "survey_payment_gift_card_thank_you",
  SurveyPaymentGiftCardProfile = "survey_payment_gift_card_profile",
  SurveyBankTransferPayments = "survey_bank_transfer_payments",
  SurveyViewAll = "survey_view_all",
  SurveyViewAllDetails = "survey_view_all_details",
  SurveySignUpLanding = "survey_sign_up_landing",
  SurveySignUpLandingCreateAccount = "survey_sign_up_landing_create_account ",
  SurveySignUpLandingLogin = "survey_sign_up_landing_login ",
  SurveyTerminatedThankYouPage = "survey_terminated_thank_you_page ",
  SurveyTerminatedCreateAccount = "survey_terminated_create_account"
}
