import { Button, Flex, Tag, Tooltip, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import {
  SURVEY_STATUS,
  SURVEY_STATUS_COLORS
} from "@arbolus-technologies/models/survey";
import { PROJECT_EDIT_SURVEY_ROUTE } from "@arbolus-technologies/routes";
import { BoxWithChildren } from "@arbolus-technologies/ui/components";
import { getElapsedTimePeriod } from "@arbolus-technologies/utils";

import styles from "../SurveyGrayBox.module.scss";

const { Title, Text } = Typography;

interface SurveyCardProps {
  id: string;
  title: string;
  status: SURVEY_STATUS;
  created?: string;
}

export const SurveyCard: React.FC<SurveyCardProps> = ({
  id,
  status,
  title,
  created
}) => {
  const { t } = useTranslation("surveyCard");
  const { projectId } = useParams<{ projectId: string }>();

  const { elapsedTimeUnits, elapsedTimeName } = getElapsedTimePeriod(
    created ? new Date(created) : null
  );

  const recentAnswersTimePeriod = (): string => {
    if (elapsedTimeUnits === 0) {
      return t("created", { time: elapsedTimeName });
    } else {
      return t("createdAgo", { unit: elapsedTimeUnits, time: elapsedTimeName });
    }
  };

  return (
    <BoxWithChildren>
      <Flex vertical gap={16} className={styles.card}>
        <Tag color={SURVEY_STATUS_COLORS[status]} className={styles.tag}>
          {status}
        </Tag>
        <Flex
          vertical
          justify="space-between"
          gap={16}
          className={styles.details}
        >
          <Title level={5} ellipsis={{ rows: 2 }}>
            <Tooltip title={title}>{title}</Tooltip>
          </Title>
          <Flex justify="space-between" align="center">
            <Text type="secondary">{recentAnswersTimePeriod()}</Text>
            <Link to={PROJECT_EDIT_SURVEY_ROUTE(projectId, id)}>
              <Button>{t("details")}</Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </BoxWithChildren>
  );
};
