import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CustomersService,
  DefaultToasterService
} from "@arbolus-technologies/api";
import { CustomerSurveyCompany } from "@arbolus-technologies/models/common";

import { getKeysOfSelectedCheckboxes } from "../../../utils/form";
import { VendorsSelection } from "../VendorsSelection";
import { GiveDetailsFooter } from "./GiveDetailsFooter/GiveDetailsFooter";

export function AlternativesForm({
  surveyId,
  customerId,
  defaultAlternatives,
  previousCompetitors,
  next
}: {
  surveyId: string;
  customerId: string;
  defaultAlternatives: CustomerSurveyCompany[];
  previousCompetitors: string[];
  next: () => void;
}) {
  const { t } = useTranslation("expertSurvey");
  const defaultValues = Object.fromEntries(
    defaultAlternatives.map((vendor) => [
      vendor.id,
      previousCompetitors.includes(vendor.id)
    ])
  );
  const [alternatives, setAlternatives] =
    useState<CustomerSurveyCompany[]>(defaultAlternatives);

  const methods = useForm({ defaultValues });

  function handleAlternativesConfirmed(
    newAlternatives: CustomerSurveyCompany[]
  ) {
    setAlternatives((previousAlternatives) => [
      ...previousAlternatives,
      ...newAlternatives
    ]);
    newAlternatives.forEach((vendor) => methods.setValue(vendor.id, true));
  }

  function updateCompetitors() {
    CustomersService.updateSurveyCompetitors(surveyId, {
      customerId,
      competitors: getKeysOfSelectedCheckboxes(methods.getValues())
    }).subscribe(
      () => {
        next();
      },
      (error) => {
        DefaultToasterService.showApiErrors(error);
      }
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <VendorsSelection
          title={t("alternativesQuestion")}
          options={alternatives.map((vendor) => ({
            label: vendor.name,
            name: vendor.id
          }))}
          // Previously picked alternatives
          selectedCompaniesIds={alternatives.map((company) => company.id)}
          onExtraVendorsConfirmed={handleAlternativesConfirmed}
        />
      </FormProvider>
      <GiveDetailsFooter onSubmitSurvey={updateCompetitors} />
    </>
  );
}
