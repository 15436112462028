/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/static-property-placement */
import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import { goBack, replace } from "connected-react-router";
import { Field, Form, Formik, FormikProps } from "formik";
import { unionWith } from "lodash";
import moment from "moment";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import SimpleBar from "simplebar-react";

import {
  ExpertsListPageTab,
  PROJECT_EVENT_PLATFORM,
  PROJECT_REFERRAL_STATE,
  ProjectService,
  ReferralDetail,
  ReferralSummary,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixpanelPages,
  PageTracker,
  generateEventName
} from "@arbolus-technologies/features/common";
import { EventAvailabilities } from "@arbolus-technologies/features/projects";
import {
  APP_TRACKING_ROUTES,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE,
  SelectOption
} from "@arbolus-technologies/models/common";
import {
  PageState,
  PagesSelector,
  PagesStoreActions
} from "@arbolus-technologies/stores/pages";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  CustomSwitch,
  DatePicker,
  Loader
} from "@arbolus-technologies/ui/components";
import { DATE_TIME_FORMAT, utilService } from "@arbolus-technologies/utils";

import { CONTENT_PANEL } from "../../../../constants/app";
import {
  NEW_USER,
  PROJECTS
} from "../../../../constants/navigation/authRoutes";
import {
  PROJECT_CALENDAR_ROUTE,
  PROJECT_EXPERTS_ROUTE,
  PROJECT_ROUTE
} from "../../../../constants/navigation/projectRoutes";
import { UI_WINDOW_HEIGHT } from "../../../../constants/ui";
import { GUEST_INVITE_MAX_COUNT } from "../../../../constants/validation";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../contexts/contentPanel/ContentPanelContext";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { Document } from "../../../../models/documents";
import { Event, EventGuest, SaveEventRequest } from "../../../../models/event";
import { Member } from "../../../../models/project";
import { User } from "../../../../models/user";
import { CreateEventRouterData } from "../../../../models/view/event";
import { EventService, UtilsService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import {
  CIQFormInput,
  CIQSelect,
  CIQTimePicker
} from "../../../app/components";
import { AppSelector } from "../../../app/store";
import { AuthSelector } from "../../../auth/store";
import EventFileTag from "../../components/events/EventFileTag";
import EventGuestTag from "../../components/events/EventGuestTag";
import { CIQFileAttacher, CIQGuestsInvite } from "../../components/panels";
import { ProjectSelector, ProjectStoreActions } from "../../store";
import { EventValidationSchema } from "./EventFormSchema";
import { EventPageTrackComponent } from "./EventPageTrackComponent";

import arbolusLogo from "../../../../assets/images/logos/logo-tree-blue.png";

import "react-datepicker/dist/react-datepicker.css";

const notification = new ToasterService();

interface EventFormValues extends Event {
  startDate: Date;
  endDate: Date;
}

interface EventPageStoreProps {
  projectId: string;
  projectName: string;
  projectTimezone: string;
  authUser: User;
  members: Member[];
  currentTimeZone?: SelectOption;
  timeZones: SelectOption[];
  createEventRouterData?: CreateEventRouterData;
  isTranscriptEnabled?: boolean;
  defaultEventGuests: User[];
  previousPageState: PageState;
}

interface EventPageProps extends EventPageStoreProps {
  eventId?: string;
  eventPageUuid?: string;
  navigateToProjects: () => void;
  navigateToProject: (projectId: string) => void;
  navigateBack: () => void;
  navigateToAnotherTab: (tabRoute: string) => void;
  navigateToNewUser: (
    email: string,
    projectId: string,
    eventData: EventFormValues
  ) => void;
  updateEventDuration: (eventStart: string) => void;
  reloadReferralsSummary: (summary: ReferralSummary) => void;
}

interface EventPageState {
  invitedEmails: string[];
  isLoading: boolean;
  isSaveLoading: boolean;
  referral?: ReferralDetail;
}

type EventPageIntersectProps = EventPageProps &
  WithTranslation &
  RouteComponentProps<
    { projectId: string },
    StaticContext,
    { from: string } | undefined
  >;

class EventPage extends React.Component<
  EventPageIntersectProps,
  EventPageState
> {
  static defaultProps: EventPageProps = {
    projectId: "",
    projectName: "",
    projectTimezone: "",
    authUser: {} as User,
    members: [],
    timeZones: [],
    currentTimeZone: {} as SelectOption,
    navigateToProjects: (): void => {},
    navigateToProject: (projectId: string): void => {},
    navigateBack: (): void => {},
    navigateToAnotherTab: (): void => {},
    navigateToNewUser: (): void => {},
    updateEventDuration: (): void => {},
    isTranscriptEnabled: true,
    defaultEventGuests: [],
    previousPageState: {},
    reloadReferralsSummary: (): void => {}
  };

  constructor(props: EventPageIntersectProps) {
    super(props);

    this.state = {
      invitedEmails: [props.authUser.email],
      isLoading: false,
      isSaveLoading: false
    };
  }

  componentDidMount(): void {
    this.initializeEventAction();
  }

  componentWillUnmount(): void {
    this.getEventSubscription?.unsubscribe();
    this.createEventSubscription?.unsubscribe();
    this.saveEventSubscription?.unsubscribe();
  }

  private formikRef: FormikProps<EventFormValues> | null = null;

  private eventData: Event = {} as Event;

  private getEventSubscription?: Subscription;

  private createEventSubscription?: Subscription;

  private saveEventSubscription?: Subscription;

  initializeEventAction(): void {
    const { eventId, t } = this.props;

    if (eventId) {
      this.fetchEvent();
      return;
    }

    this.fetchReferral();

    const {
      projectTimezone,
      currentTimeZone,
      createEventRouterData,
      timeZones,
      authUser,
      defaultEventGuests,
      previousPageState
    } = this.props;
    const { invitedEmails } = this.state;

    let eventGuests: EventGuest[] = defaultEventGuests
      .filter((d) => d.id !== authUser.id)
      .map((d) => ({
        email: d.email,
        user: d
      }));

    // Add an expert e.g. via "Arrange a call" button in "Expert's availability"
    if (createEventRouterData) {
      const { expert } = createEventRouterData;
      if (expert?.email) {
        eventGuests = [...eventGuests, { email: expert.email, user: expert }];
      }

      // IF greater equal Max count. this condition exclude the current user
      if (eventGuests.length >= GUEST_INVITE_MAX_COUNT) {
        notification.showError(
          t("maxGuestCountReached", { limit: GUEST_INVITE_MAX_COUNT })
        );
        // Reduce one to keep space for current user
        eventGuests = eventGuests.slice(0, GUEST_INVITE_MAX_COUNT - 1);
      }
    }

    const invitedGuestEmails = invitedEmails.concat(
      eventGuests.map((e) => e.email)
    );

    // In case of navigating back (from e.g. new user page) load the previous data
    if (previousPageState.eventData) {
      this.eventData = previousPageState.eventData;
      this.eventData.eventGuests = unionWith(
        this.eventData.eventGuests,
        eventGuests,
        (originalUser, anotherUser) => originalUser.email === anotherUser.email
      );
    } else {
      this.eventData = {
        title: createEventRouterData?.title || "",
        startTime: createEventRouterData?.startDate as Date,
        endTime: createEventRouterData?.endDate as Date,
        meetingPlatform: PROJECT_EVENT_PLATFORM.CONNECT,
        timezone: {
          id:
            createEventRouterData?.timezone ||
            projectTimezone ||
            currentTimeZone?.value ||
            timeZones[0].value,
          displayText: "",
          offset: 0
        },
        eventGuests,
        organizer: {} as User,
        location: "",
        notes: "",
        id: "",
        eventAttachments: [],
        transcribe: true,
        humanTranscribe: false
      };
    }

    this.setState({
      isLoading: false,
      isSaveLoading: false,
      invitedEmails: invitedGuestEmails
    });
  }

  fetchEvent = (): void => {
    const { projectId, navigateToProjects, authUser, eventId, t } = this.props;
    this.setState({ isLoading: true });
    this.getEventSubscription = EventService.getEvent(
      projectId,
      eventId!
    ).subscribe(
      (e: Event) => {
        const iamOrganizer = authUser.id === e.organizer.id;
        const isEventPassed = UtilsService.isTimeBeforeLocalTime(e.endTime);
        const isEventControl = iamOrganizer && !isEventPassed;

        if (!isEventControl) {
          notification.showError(t("unableEdit"));
          navigateToProjects();
          return;
        }

        const { startTime, timezone, eventGuests, endTime, meetingPlatform } =
          e;

        const eventGuestEmails = eventGuests.map((g) => g.email);
        this.eventData = {
          ...e,
          meetingPlatform: meetingPlatform || PROJECT_EVENT_PLATFORM.CONNECT,
          startTime: UtilsService.convertUTCToTimeZone(startTime, timezone.id),
          endTime: UtilsService.convertUTCToTimeZone(endTime, e.timezone.id),
          eventGuests: eventGuests.filter((g) => g.email !== authUser.email),
          expertHasMinimumCallTimeRate: e.expertHasMinimumCallTimeRate ?? false
        };

        this.setState({
          isLoading: false,
          isSaveLoading: false,
          invitedEmails: eventGuestEmails
        });
      },
      () => this.handleNavigateToBase()
    );
  };

  fetchReferral = (): void => {
    const {
      location: { search },
      match,
      navigateToProject,
      t
    } = this.props;
    if (!search) {
      return;
    }

    const searchParams = new URLSearchParams(search);
    const referralId = searchParams.get("referralId");
    if (!referralId) {
      return;
    }

    const projectId = match.params.projectId;

    ProjectService.getReferral(projectId, referralId).subscribe((referral) => {
      const bookableReferral =
        referral.activeReferral &&
        referral.application.subStatus === REFERRAL_SUB_STATE.ACCEPT &&
        referral.review.subStatus === REFERRAL_SUB_STATE.ACCEPT &&
        referral.status === PROJECT_REFERRAL_STATE.ACCEPT &&
        (!referral.complianceRequired ||
          referral.compliance.subStatus === REFERRAL_COMPLIANCE_STATE.ACCEPT);
      if (!bookableReferral) {
        notification.showError(t("unableToCreateEvent"));
        navigateToProject(projectId);
        return;
      }

      this.setState({
        referral
      });
      this.handleGuestAdd({
        email: referral.expert.email,
        user: referral.expert as User
      });
    });
  };

  saveEvent = ({
    title,
    startDate,
    startTime,
    endDate,
    endTime,
    eventAttachments,
    eventGuests,
    location,
    timezone,
    notes,
    meetingPlatform,
    transcribe,
    humanTranscribe
  }: EventFormValues): void => {
    const {
      eventId,
      projectId,
      authUser,
      createEventRouterData,
      updateEventDuration,
      reloadReferralsSummary,
      t
    } = this.props;

    let guests = eventGuests.map((g) => g.email);
    if (!guests.includes(authUser.email)) {
      guests = guests.concat(authUser.email);
    }
    const eventRequest: SaveEventRequest = {
      title,
      startTime: EventService.mergeDateTimeWithTimeZone(
        startDate,
        startTime,
        timezone.id
      ),
      endTime: EventService.mergeDateTimeWithTimeZone(
        endDate,
        endTime,
        timezone.id
      ),
      attachments: eventAttachments.map((a) => a.id),
      guests,
      location,
      notes,
      timezone: timezone.id,
      meetingPlatform,
      workspaceId: createEventRouterData?.workspaceId,
      transcribe,
      humanTranscribe
    };

    this.setState({ isSaveLoading: true });
    if (eventId) {
      this.saveEventSubscription = EventService.saveEvent(
        projectId,
        eventId,
        eventRequest
      ).subscribe(
        () => {
          updateEventDuration(UtilsService.convertActiveZoneToUTC(startTime));
          notification.showSuccess(t("saveSuccess"));
          this.setState({ isSaveLoading: false });
          this.handleBackNavigation();
        },
        (error: ErrorResponse<CIQError>) => {
          this.setState({ isSaveLoading: false });
          notification.showApiErrors(error);
        }
      );
    } else {
      this.createEventSubscription = EventService.createEvent(
        projectId,
        eventRequest
      )
        .pipe(switchMap(() => ProjectService.getReferralSummary(projectId)))
        .subscribe(
          (summary: ReferralSummary) => {
            updateEventDuration(UtilsService.convertActiveZoneToUTC(startTime));
            reloadReferralsSummary(summary);

            notification.showSuccess(t("createSuccess"));
            this.setState({ isSaveLoading: false });
            this.handleBackNavigation();
          },
          (error: ErrorResponse<CIQError>) => {
            this.setState({ isSaveLoading: false });
            notification.showApiErrors(error);
          }
        );
    }
  };

  handleNavigateToBase = (): void => {
    const { projectId, navigateToAnotherTab } = this.props;
    navigateToAnotherTab(PROJECT_ROUTE(projectId));
  };

  handleNavigateToCalendar = (): void => {
    const { projectId, navigateToAnotherTab } = this.props;
    navigateToAnotherTab(PROJECT_CALENDAR_ROUTE(projectId));
  };

  handleGuestAdd = (eventGuest: EventGuest, addedFromEmail = false): void => {
    if (addedFromEmail) {
      this.props.navigateToNewUser(
        eventGuest.email,
        this.props.projectId,
        this.formikRef?.values!
      );
      return;
    }

    const { invitedEmails } = this.state;

    this.setState({
      invitedEmails: invitedEmails.concat(eventGuest.email)
    });

    if (this.formikRef) {
      const currentList = this.formikRef.values.eventGuests;
      this.formikRef.setFieldValue(
        "eventGuests",
        currentList.concat(eventGuest)
      );
    }
  };

  handleAttachmentPanelActionClick = (documents: Document[]): void => {
    if (this.formikRef) {
      const currentList = this.formikRef.values.eventAttachments;
      this.formikRef.setFieldValue(
        "eventAttachments",
        currentList.concat(documents)
      );
    }
  };

  handleBackNavigation = (): void => {
    const { createEventRouterData, navigateBack } = this.props;

    if (
      createEventRouterData?.workspaceId ||
      createEventRouterData?.from === APP_TRACKING_ROUTES.BOOK
    ) {
      navigateBack();
    } else {
      this.handleNavigateToCalendar();
    }
  };

  renderInviteGuestsPanel = (invitedEmails: string[]): JSX.Element => {
    const { projectId, t } = this.props;

    return (
      <ContentPanelContextConsumer>
        {(contentPanelContext: ContentPanelContextProps): JSX.Element => (
          <Button
            size="sm"
            color="secondary"
            className="with-icon"
            onClick={(): void => {
              const maxLimit = invitedEmails.length >= GUEST_INVITE_MAX_COUNT;

              maxLimit
                ? notification.showError(
                    t("maxGuestCountReached", {
                      limit: GUEST_INVITE_MAX_COUNT
                    })
                  )
                : contentPanelContext.setContent(
                    <CIQGuestsInvite
                      key={CONTENT_PANEL.EVENT_GUEST_INVITE}
                      projectId={projectId}
                      invitedEmails={invitedEmails}
                      onGuestAdded={this.handleGuestAdd}
                      closePanel={contentPanelContext.closePanel}
                    />,
                    true
                  );
            }}
          >
            <span className="ciq-icon ciq-plus" />
            {t("invite")}
          </Button>
        )}
      </ContentPanelContextConsumer>
    );
  };

  renderForm = ({
    handleBlur,
    setFieldValue,
    setFieldError,
    values,
    errors,
    touched,
    isValid,
    dirty,
    setValues,
    setFieldTouched
  }: FormikProps<EventFormValues>): JSX.Element => {
    const {
      projectId,
      projectName,
      projectTimezone,
      authUser,
      timeZones,
      t,
      eventId,
      createEventRouterData,
      isTranscriptEnabled,
      defaultEventGuests,
      location: { search }
    } = this.props;
    const { isSaveLoading, invitedEmails, referral } = this.state;
    const { title, expertHasMinimumCallTimeRate } = this.eventData;

    const isEditEvent = eventId !== undefined;
    const {
      eventGuests,
      eventAttachments,
      startDate,
      startTime,
      endDate,
      endTime,
      timezone
    } = values;
    const disableSaveButton = isEditEvent ? !(dirty && isValid) : !isValid;

    if (referral && !values.title && !touched.title) {
      setFieldValue(
        "title",
        generateEventName(
          `${referral.expert.firstName} ${referral.expert.lastName}`,
          projectName
        )
      );
    }

    const handleGuestRemove = (email: string): void => {
      const { invitedEmails } = this.state;
      const currentList = eventGuests;

      setFieldValue(
        "eventGuests",
        currentList.filter((guest) => guest.email !== email)
      );
      this.setState({
        invitedEmails: invitedEmails.filter((e) => e !== email)
      });
    };

    const handleRemoveFile = (docId: string): void => {
      setFieldValue(
        "eventAttachments",
        eventAttachments.filter((d) => d.id !== docId)
      );
    };

    const handleTimeZoneChange = (value: string): void => {
      setFieldValue("timezone", { id: value, displayText: "" });
    };

    const handleStartDateTimeChange = (
      fieldName: "startDate" | "startTime",
      value: Date,
      error: string
    ): void => {
      if (!value) {
        setFieldError(fieldName, error);
      }

      // eslint-disable-next-line no-param-reassign
      values[fieldName] = value;
      const { startDate: CurrentStartDate, startTime: CurrentStartTime } =
        values;

      if (CurrentStartDate && CurrentStartTime) {
        const startDateTime = utilService.mergeDateTime(
          CurrentStartDate,
          CurrentStartTime
        );

        const updateDateTime = moment(startDateTime).add(1, "hour").toDate();

        setValues({
          ...values,
          startDate: startDateTime,
          startTime: startDateTime,
          endDate: updateDateTime,
          endTime: updateDateTime
        }).then(() => {
          setFieldTouched(fieldName, true);
        });
      } else {
        setFieldValue(fieldName, value);
      }
    };

    const handleEndDateTimeChange = (
      fieldName: "endDate" | "endTime",
      value: Date,
      error: string
    ): void => {
      if (!value) {
        setFieldError(fieldName, error);
      }

      // eslint-disable-next-line no-param-reassign
      values[fieldName] = value;
      const { endDate: CurrentEndDate, endTime: CurrentEndTime } = values;

      if (CurrentEndTime && CurrentEndDate) {
        const endDateTime = utilService.mergeDateTime(
          CurrentEndDate,
          CurrentEndTime
        );

        setValues({
          ...values,
          endDate: endDateTime,
          endTime: endDateTime
        }).then(() => {
          setFieldTouched(fieldName, true);
        });
      } else {
        setFieldValue(fieldName, value);
      }
    };

    const handleFreeTranscript = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setFieldValue("transcribe", e.target.checked);
    };

    const handleHumanTranscript = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setFieldValue("humanTranscribe", e.target.checked);
      if (e.target.checked) {
        setFieldValue("transcribe", true);
      }
    };

    const handleSelectedSlot = (startTime: Date, endTime: Date): void => {
      const momentStart = moment.tz(startTime, projectTimezone);
      const momentEnd = moment.tz(endTime, projectTimezone);
      const startDate = new Date(momentStart.format("YYYY-MM-DD HH:mm:ss"));
      const endDate = new Date(momentEnd.format("YYYY-MM-DD HH:mm:ss"));
      setFieldTouched("startTime", true);
      setFieldTouched("startDate", true);
      setFieldTouched("endTime", true);
      setFieldTouched("endDate", true);
      setValues({
        ...values,
        startDate,
        endDate,
        startTime: startDate,
        endTime: endDate
      });
    };

    const eventTitle = createEventRouterData
      ? t("newEvent")
      : title || t("newEvent");

    const searchParams = new URLSearchParams(search);
    const from = searchParams.get("from") || undefined;

    const hasDateError =
      (errors.startDate && touched.startDate) ||
      (errors.startTime && touched.startTime) ||
      (errors.endDate && touched.endDate) ||
      (errors.endTime && touched.endTime);

    return (
      <PageTracker page={MixpanelPages.Event} from={from}>
        <Form>
          <div className="event-body">
            <div className="body-title">
              <h1>{eventTitle}</h1>
            </div>
            <section className="section form-section">
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="input_modal">{t("eventTitle")}</Label>
                    <Field
                      autoComplete="off"
                      name="title"
                      type="text"
                      component={CIQFormInput}
                      placeholder={t("eventTitlePlaceholder")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {referral && (
                <Row>
                  <Col>
                    <EventAvailabilities
                      referral={referral}
                      onSlotSelected={handleSelectedSlot}
                      projectTimezone={projectTimezone}
                    />
                  </Col>
                </Row>
              )}
              <Row className="time-row">
                <Col md={5}>
                  <div className="date-time-container">
                    <FormGroup
                      className={clsx("date", {
                        "is-invalid": errors.startDate && touched.startDate
                      })}
                    >
                      <Label for="input_modal">{t("starts")}</Label>
                      <DatePicker
                        onBlur={handleBlur("startDate")}
                        selectedDate={startDate}
                        minDate={
                          new Date(
                            moment.tz(timezone.id).format(DATE_TIME_FORMAT)
                          )
                        }
                        onDateChanged={(date: Date): void =>
                          handleStartDateTimeChange(
                            "startDate",
                            date,
                            t("startDateRequired")
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup
                      className={clsx("btn-input-group time", {
                        "is-invalid": errors.startTime && touched.startTime
                      })}
                    >
                      <Label for="input_modal">&nbsp;</Label>
                      <CIQTimePicker
                        onBlur={handleBlur("startTime")}
                        timeIntervals={30}
                        selectedTime={startTime}
                        onTimeChanged={(date: Date): void =>
                          handleStartDateTimeChange(
                            "startTime",
                            date,
                            t("startTimeRequired")
                          )
                        }
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="date-time-container">
                    <FormGroup
                      className={clsx("date", {
                        "is-invalid": errors.endDate && touched.endDate
                      })}
                    >
                      <Label for="input_modal">{t("ends")}</Label>
                      <DatePicker
                        selectedDate={endDate}
                        onBlur={handleBlur("endDate")}
                        minDate={values.startDate}
                        onDateChanged={(date: Date): void =>
                          handleEndDateTimeChange(
                            "endDate",
                            date,
                            t("endDateRequired")
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup
                      className={`btn-input-group time ${
                        errors.endTime && touched.endTime && "is-invalid"
                      }`}
                    >
                      <Label for="input_modal">&nbsp;</Label>
                      <CIQTimePicker
                        onBlur={handleBlur("endTime")}
                        timeIntervals={30}
                        selectedTime={endTime}
                        onTimeChanged={(date: Date): void =>
                          handleEndDateTimeChange(
                            "endTime",
                            date,
                            t("endTimeRequired")
                          )
                        }
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col className="p-0" md={2}>
                  <div className="date-time-container">
                    <FormGroup
                      className={clsx("btn-input-group timezone", {
                        "is-invalid": errors.timezone && touched.timezone
                      })}
                    >
                      <Label for="input_modal">{t("projectTimezone")}</Label>

                      <CIQSelect
                        noOptionsMessage={t("noTimezone")}
                        options={timeZones}
                        onSelectChange={handleTimeZoneChange}
                        defaultValue={timeZones.find(
                          (tz) => tz.value === timezone.id
                        )}
                        isSearchable
                        disabled={
                          !!createEventRouterData?.timezone || !!referral
                        }
                      />
                    </FormGroup>
                  </div>
                </Col>
                {hasDateError && (
                  <div className="error-container">
                    <ul className="error-list">
                      {errors.startDate && (
                        <li className="error-text">{errors.startDate}</li>
                      )}
                      {errors.startTime && (
                        <li className="error-text">{errors.startTime}</li>
                      )}
                      {errors.endDate && (
                        <li className="error-text">{errors.endDate}</li>
                      )}
                      {errors.endTime && errors.endDate !== errors.endTime && (
                        <li className="error-text">{errors.endTime}</li>
                      )}
                    </ul>
                  </div>
                )}
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup
                    className={clsx("btn-input-group mb-0", {
                      "is-invalid": errors.eventGuests && touched.eventGuests
                    })}
                  >
                    <Label for="input_modal">{t("guest")}</Label>
                    <div className="tag-list">
                      <EventGuestTag
                        isCreator
                        key={authUser.email}
                        guest={{
                          email: authUser.email,
                          user: authUser
                        }}
                      />
                      {values.eventGuests.map((guest: EventGuest) => {
                        const ciqManagerEmails = defaultEventGuests
                          .filter((guest) => guest.ciqManager)
                          .map((guest) => guest.email);

                        return (
                          <EventGuestTag
                            onRemove={
                              ciqManagerEmails.includes(guest.email)
                                ? undefined
                                : handleGuestRemove
                            }
                            key={guest.email}
                            guest={guest}
                          />
                        );
                      })}
                      {this.renderInviteGuestsPanel(invitedEmails)}
                    </div>
                    <Label className="invalid-feedback">
                      {/* @ts-ignore */}
                      {errors.eventGuests}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </section>
            {isTranscriptEnabled && (
              <section className="section option-section">
                <div className="section-header">
                  <h3>{t("options")}</h3>
                </div>
                <Row className="free-transcript-option">
                  <Col md="12">
                    <FormGroup>
                      <div className="transcript-toggle">
                        <Label className="form-label" for="input_modal">
                          {t("freeTranscript")}
                        </Label>
                        <CustomSwitch
                          checked={values.transcribe}
                          name="transcribe"
                          onChange={handleFreeTranscript}
                          disabled={values.humanTranscribe}
                        />
                      </div>
                      <p className="option-detail">
                        {t("freeTranscriptDetail")}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="free-transcript-option">
                  <Col md="12">
                    <FormGroup>
                      <div className="transcript-toggle">
                        <Label className="form-label" for="input_modal">
                          {t("humanTranscript")}
                        </Label>
                        <CustomSwitch
                          checked={values.humanTranscribe}
                          name="humanTranscribe"
                          onChange={handleHumanTranscript}
                        />
                      </div>
                      <p className="option-detail">
                        {t("humanTranscriptDetail")}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </section>
            )}
            <section className="section option-section">
              <Row>
                <Col md={12}>
                  <FormGroup
                    className={clsx("btn-input-group", {
                      "is-invalid": errors.location && touched.location
                    })}
                  >
                    <Label for="input_modal">{t("location")}</Label>
                    <Field
                      autoComplete="off"
                      name="location"
                      component={CIQFormInput}
                      type="text"
                      placeholder={t("enterAddress")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup
                    className={`btn-input-group ${
                      errors.notes && touched.notes && "is-invalid"
                    }`}
                  >
                    <Label for="input_modal">{t("notes")}</Label>
                    <Field
                      autoComplete="off"
                      name="notes"
                      type="textarea"
                      placeholder={t("littleInfo")}
                      component={CIQFormInput}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup
                    className={clsx("btn-input-group mb-0", {
                      "is-invalid": errors.eventAttachments
                    })}
                  >
                    <Label for="input_modal">{t("attachment")}</Label>
                    <div className="tag-list">
                      {eventAttachments.map((f) => (
                        <EventFileTag
                          document={f}
                          key={f.id}
                          onRemoveClicked={handleRemoveFile}
                        />
                      ))}
                      <ContentPanelContextConsumer>
                        {(
                          contentPanelContext: ContentPanelContextProps
                        ): JSX.Element => (
                          <Button
                            size="sm"
                            color="secondary"
                            className="with-icon"
                            onClick={(): void =>
                              contentPanelContext.setContent(
                                <CIQFileAttacher
                                  key={CONTENT_PANEL.FILE_PICKER}
                                  actionText="Add"
                                  headerText="Attach Files"
                                  onActionClicked={
                                    this.handleAttachmentPanelActionClick
                                  }
                                  onPanelClose={contentPanelContext.closePanel}
                                  onUploadStateChanged={
                                    contentPanelContext.setPanelLock
                                  }
                                  projectId={projectId}
                                  excludedIds={eventAttachments.map(
                                    (a) => a.id
                                  )}
                                  excludeTranscripts
                                />,
                                true
                              )
                            }
                          >
                            <span className="ciq-icon ciq-plus" />
                            {t("add")}
                          </Button>
                        )}
                      </ContentPanelContextConsumer>
                    </div>
                    <Label className="invalid-feedback">
                      {/* @ts-ignore */}
                      {errors.eventAttachments}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </section>
            <Row className="submit-row">
              <Col md={12}>
                <>
                  {(createEventRouterData?.expert?.hasMinimumCallTimeRate ||
                    expertHasMinimumCallTimeRate) && (
                    <div className={"warningWrapper"}>
                      <Icon
                        name="info"
                        fontSize="16px"
                        color={ARBOLUS_COLORS.bColorBasePurple}
                      />
                      <span className={"rateChargedWarning"}>
                        {t("rateChargedWarning")}
                      </span>
                    </div>
                  )}
                  <Button
                    size="lg"
                    color="primary"
                    type="submit"
                    className="btn-bold"
                    disabled={disableSaveButton || isSaveLoading}
                  >
                    {t("save")}
                  </Button>
                </>
              </Col>
            </Row>
          </div>
        </Form>
      </PageTracker>
    );
  };

  renderHeader = (): JSX.Element => {
    const { projectName, t } = this.props;

    return (
      <Row className="event-header">
        <Col>
          <div className="pagination-content">
            <span onClick={this.handleNavigateToBase}>{projectName}</span>
          </div>
        </Col>
        <Col>
          <div className="right-button">
            <Button className="btn-link" onClick={this.handleBackNavigation}>
              {t("cancel")}
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  render(): JSX.Element {
    const { isLoading, isSaveLoading } = this.state;

    const { eventGuests, startTime, endTime, meetingPlatform } = this.eventData;

    const preferredPlatform = meetingPlatform || PROJECT_EVENT_PLATFORM.CONNECT;

    const { location } = this.props;

    return isLoading || !eventGuests ? (
      <Loader isFull />
    ) : (
      <>
        <EventPageTrackComponent
          isEventCreated={isSaveLoading}
          from={location.state?.from}
        />
        {isSaveLoading && <Loader isFull />}
        <SimpleBar
          className="new-event-page simplebar-light"
          style={{ maxHeight: UI_WINDOW_HEIGHT }}
        >
          <div className="w-100">
            <div className="event-container ">
              <div className="full-page-logo">
                <img src={arbolusLogo} alt="logo" />
              </div>
              {this.renderHeader()}
              <Formik<EventFormValues>
                initialValues={{
                  ...this.eventData,
                  meetingPlatform: preferredPlatform,
                  startDate: startTime,
                  endDate: endTime
                }}
                innerRef={(instance): void => {
                  this.formikRef = instance;
                }}
                validateOnChange
                validateOnMount
                validateOnBlur
                validationSchema={EventValidationSchema}
                onSubmit={this.saveEvent}
              >
                {this.renderForm}
              </Formik>
            </div>
          </div>
        </SimpleBar>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  EventPageProps,
  EventPageStoreProps
>({
  projectName: ProjectSelector.projectNameSelector(),
  projectId: ProjectSelector.projectIdSelector(),
  projectTimezone: ProjectSelector.projectTimezoneSelector(),
  members: ProjectSelector.projectMembersSelector(),
  authUser: AuthSelector.authUserSelector(),
  currentTimeZone: AppSelector.appGuessCurrentTimeZoneSelector(),
  timeZones: AppSelector.appTimeZoneSelector(),
  createEventRouterData: ProjectSelector.createEventInitDataSelector(),
  isTranscriptEnabled: AuthSelector.authClientIsTranscriptEnabledSelector(),
  defaultEventGuests: ProjectSelector.defaultEventGuestsSelector(),
  previousPageState: (state, ownProps) =>
    ownProps.eventPageUuid
      ? PagesSelector.getPageState(ownProps.eventPageUuid)(state)
      : {}
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToProjects: (): AppAction => dispatch(replace(PROJECTS)),
  navigateToProject: (projectId: string): AppAction =>
    dispatch(
      replace(PROJECT_EXPERTS_ROUTE(projectId, ExpertsListPageTab.Accept))
    ),
  navigateBack: (): AppAction => dispatch(goBack()),
  navigateToAnotherTab: (tabRoute: string): AppAction =>
    dispatch(replace(tabRoute)),
  navigateToNewUser: (
    email: string,
    projectId: string,
    eventData: EventFormValues
  ): AppAction => {
    const uuid = utilService.generateUUID();
    dispatch(PagesStoreActions.savePageState(uuid, { eventData }));
    dispatch(replace(NEW_USER, { projectId, eventPageUuid: uuid, email }));
  },
  updateEventDuration: (eventStart: string): AppAction =>
    dispatch(ProjectStoreActions.updateEventDuration(eventStart)),
  reloadReferralsSummary: (summary: ReferralSummary) =>
    dispatch(ProjectExpertsStoreActions.getProjectSummarySuccess(summary))
});

export default withRouter(
  // @ts-ignore
  withTranslation("eventPage")(
    // @ts-ignore
    connect(mapStateToProps, mapDispatchToProps)(EventPage)
  )
);
