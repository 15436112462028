import {
  FilterModel,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

import {
  SORT_DIRECTION,
  SurveyExpertListRequest,
  SurveyService,
  getMultiSortParams
} from "@arbolus-technologies/api";

export class SurveyExpertTableDataSource implements IServerSideDatasource {
  private readonly _surveyId: string;

  constructor(surveyId: string) {
    this._surveyId = surveyId;
  }

  getRows(params: IServerSideGetRowsParams): void {
    const { success, fail, request } = params;

    SurveyService.getSurveyExperts(
      this._surveyId,
      getMultiSortParams(this.getQueryParams(request))
    ).subscribe({
      next: (response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      },
      error: () => fail()
    });
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): SurveyExpertListRequest {
    const { filterModel, sortModel } = request;

    const { offset, limit } = this.getPagination(request);

    // Keys equal to column IDs in column definitions
    const {
      fullName = {},
      email = {},
      surveyExpertId = {},
      completionDate = {},
      paymentMethod = {},
      amount = {}
    } = filterModel as FilterModel;

    const queryParams: SurveyExpertListRequest = {
      fullName: fullName.filter,
      email: email.filter,
      surveyExpertId: surveyExpertId.filter,
      completionDate: completionDate.filter,
      paymentMethod: paymentMethod.values,
      offset,
      limit,
      amount: amount.values,
      sort:
        sortModel?.length > 0
          ? sortModel.map(({ colId, sort }) => ({
              orderBy: colId,
              orderDirection: sort as SORT_DIRECTION
            }))
          : []
    };

    return queryParams;
  }

  private getPagination(request: IServerSideGetRowsRequest) {
    const { startRow, endRow } = request;
    const queryParams = {
      limit: endRow! - startRow!,
      offset: startRow!
    };
    return queryParams;
  }
}
