import { Pagination } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  FindExpertStatus,
  FindExpertStatusEnum
} from "@arbolus-technologies/models/project";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import {
  LoaderOrComponent,
  NoResultsPlaceholder
} from "@arbolus-technologies/ui/components";

import { useDiscoverExperts } from "../../../Hooks/Discover/useDiscoverExperts";
import { ExpertListContainer } from "./ExpertListContainer/ExpertListContainer";
import { InitialSearchPlaceholder } from "./InitialSearchPlaceholder/InitialSearchPlaceholder";
import { RecommendationsPlaceholder } from "./RecommendationsPlaceholder/RecommendationsPlaceholder";
import { MoveToShortlistButton } from "./ResultsAndActions/MoveToShortlistButton";
import { ResultsAndActions } from "./ResultsAndActions/ResultsAndActions";

import { MAX_PAGE_SIZE_20 } from "@arbolus-technologies/api";
import styles from "./ExpertList.module.scss";

interface ExpertListProps {
  projectId: string;
  projectName: string;
}

export const ExpertList: React.FC<ExpertListProps> = ({
  projectId,
  projectName
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();

  const {
    expertListPaginated,
    handleSelectExpert,
    selectedExpertsIds,
    expertsCount,
    angles,
    fetchExpertList,
    clearSelectedExperts,
    selectedCautionExperts,
    handleToggleSelectAllExperts,
    areAllExpertsSelected,
    handleSetIsConfirmReferralsLoading,
    isConfirmReferralsLoading
  } = useDiscoverExperts({ projectId });

  const [shortlistedExpertsIds, setShortlistedExpertsIds] = useState<string[]>(
    []
  );

  const isDiscoverExpertLoading = useSelector(
    ProjectNxSelector.getIsDiscoverExpertLoading()
  );
  const currentPage = useSelector(
    ProjectNxSelector.getDiscoverExpertCurrentPage()
  );
  const pageSize = useSelector(ProjectNxSelector.getDiscoverExpertPageSize());

  useEffect(() => {
    projectName && fetchExpertList();

    return () => {
      dispatch(ProjectNxStoreActions.setDiscoverExpertList(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName]);

  const areActionsDisabled = selectedExpertsIds.length === 0;
  const totalExpertsFound = expertListPaginated?.pagination.count ?? 0;
  const expertListPaginatedStatus =
    expertListPaginated?.resultStatus as FindExpertStatus;
  const hasNoExperts = expertListPaginated === null;

  const handleShortlistConfirmed = useCallback(
    (selectedExpertsIds: string[]) => {
      setShortlistedExpertsIds((previousIds) => [
        ...previousIds,
        ...selectedExpertsIds
      ]);
      clearSelectedExperts();
    },
    [clearSelectedExperts]
  );

  const expertListScreens = {
    [FindExpertStatusEnum.OK]: (
      <ExpertListContainer
        expertListPaginated={expertListPaginated!}
        handleSelectExpert={handleSelectExpert}
        projectId={projectId}
        projectName={projectName}
        selectedExpertsIds={selectedExpertsIds}
        shortlistedExpertsIds={shortlistedExpertsIds}
        angles={angles}
      />
    ),
    [FindExpertStatusEnum.RESULTS_NOT_FOUND]: (
      <NoResultsPlaceholder
        noResultsTitle={t("noResultsTitle")}
        noResultsText={t("noResultsText")}
      />
    ),
    [FindExpertStatusEnum.EXPERT_RECOMMENDATIONS_NOT_GENERATED]: (
      <RecommendationsPlaceholder />
    )
  };

  return (
    <div className={styles.expertsColumn}>
      <ResultsAndActions
        totalExpertsAvailable={expertsCount}
        totalExpertsFound={totalExpertsFound}
        selectedExpertsIds={selectedExpertsIds}
        selectedCautionExperts={selectedCautionExperts}
        onShortlistConfirmed={handleShortlistConfirmed}
        handleToggleSelectAllExperts={handleToggleSelectAllExperts}
        areAllExpertsSelected={areAllExpertsSelected}
        handleSetIsConfirmReferralsLoading={handleSetIsConfirmReferralsLoading}
        isConfirmReferralsLoading={isConfirmReferralsLoading}
        angles={angles}
      />
      <div className={styles.expertList}>
        <LoaderOrComponent isLoading={isDiscoverExpertLoading} isFull>
          {hasNoExperts ? (
            <InitialSearchPlaceholder />
          ) : (
            expertListScreens[expertListPaginatedStatus]
          )}
        </LoaderOrComponent>
      </div>
      <div className={styles.paginationAndButtonContainer}>
        <div className={styles.paginationContainer}>
          <Pagination
            total={totalExpertsFound}
            showTotal={(total) => t("totalExperts", { count: total })}
            defaultPageSize={MAX_PAGE_SIZE_20}
            defaultCurrent={1}
            showSizeChanger
            onChange={(page, pageSize) =>
              dispatch(
                ProjectNxStoreActions.setDiscoverExpertCurrentPage(
                  page,
                  pageSize
                )
              )
            }
            current={currentPage}
            pageSize={pageSize}
          />
        </div>
        {totalExpertsFound > 4 && (
          <div className={styles.buttonPagination}>
            <MoveToShortlistButton
              disabled={areActionsDisabled}
              selectedExpertsIds={selectedExpertsIds}
              selectedCautionExperts={selectedCautionExperts}
              onShortlistConfirmed={handleShortlistConfirmed}
              handleSetIsConfirmReferralsLoading={
                handleSetIsConfirmReferralsLoading
              }
              isConfirmReferralsLoading={isConfirmReferralsLoading}
              angles={angles}
            />
          </div>
        )}
      </div>
    </div>
  );
};
