import { Card, Divider, Statistic } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { LandingSurvey } from "@arbolus-technologies/api";
import { SURVEY_LANDING_DATE_FORMAT } from "@arbolus-technologies/utils";

interface SurveyStatisticsProps {
  survey: LandingSurvey;
}

export const SurveyStatistics: React.FC<SurveyStatisticsProps> = ({
  survey
}) => {
  const { t } = useTranslation("surveyStatistics");
  const { minDuration, maxDuration, compensationAmount, deadlineDate } = survey;

  return (
    <Card bordered={false}>
      <Statistic
        title={t("duration")}
        value={t("durationAmount", {
          minDuration,
          maxDuration
        })}
        prefix={<AntDIcon name="schedule" fontSize="24px" />}
        valueStyle={{
          display: "flex",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 600
        }}
      />
      <Divider />
      <Statistic
        title={t("compensation")}
        value={t("compensationAmount", {
          compensationAmount: compensationAmount.toFixed(2)
        })}
        prefix={<AntDIcon name="attach_money" fontSize="24px" />}
        valueStyle={{
          display: "flex",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 600
        }}
      />
      <Divider />
      <Statistic
        title={t("deadline")}
        value={moment(deadlineDate).format(SURVEY_LANDING_DATE_FORMAT)}
        prefix={<AntDIcon name="calendar_today" fontSize="24px" />}
        valueStyle={{
          display: "flex",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 600
        }}
      />
    </Card>
  );
};
