import { ColDef } from "ag-grid-community";
import i18next from "i18next";

import { AdminExpertListResponseItem } from "@arbolus-technologies/api";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { EXPERT_PROFILE_ROUTE } from "@arbolus-technologies/routes";
import {
  fixedWidth,
  floatingFilter
} from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { COUNTRY_CODES_TO_NAMES, ISOCountryCode } from "../../countryCodes";
import { DNCStatusTag } from "./DNCStatusTag/DNCStatusTag";
import { ExpertName } from "./ExpertName";
import { OnboardedMarker } from "./OnboardedMarker/OnboardedMarker";

const COLUMN_IDS = {
  EXPERT: "expertName",
  COUNTRY: "isoCountryCode",
  PHONE: "phoneNumber",
  EMAIL: "email",
  LAST_ACTIVE_DATE: "lastActiveDate",
  TITLE: "title",
  DNC: "doNotContactStatus"
} as const;

export const COLUMN_DEFINITIONS: ColDef<AdminExpertListResponseItem>[] = [
  {
    field: "isOnboarded",
    headerName: "",
    sortable: false,
    cellRenderer: OnboardedMarker,
    ...fixedWidth(56)
  },
  {
    colId: COLUMN_IDS.EXPERT,
    headerName: i18next.t("expertTablePage:expertHeader"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      maxNumConditions: 1,
      buttons: ["reset"]
    },
    cellRenderer: ExpertName,
    cellClass: "cursor-pointer",
    minWidth: 320,
    onCellClicked: ({ data }) =>
      window.open(EXPERT_PROFILE_ROUTE(data!.id), "_blank"),
    ...floatingFilter,
    suppressFloatingFilterButton: false
  },
  {
    field: COLUMN_IDS.TITLE,
    headerName: i18next.t("expertTablePage:headlineHeader"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      maxNumConditions: 1,
      buttons: ["reset"]
    },
    minWidth: 256,
    tooltipField: COLUMN_IDS.TITLE,
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    resizable: true
  },
  {
    field: COLUMN_IDS.COUNTRY,
    headerName: i18next.t("expertTablePage:countryHeader"),
    valueFormatter: (params: { value: ISOCountryCode }) =>
      COUNTRY_CODES_TO_NAMES[params.value],
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      buttons: ["reset"],
      values: Object.keys(COUNTRY_CODES_TO_NAMES),
      valueFormatter: (params: { value: ISOCountryCode }) =>
        COUNTRY_CODES_TO_NAMES[params.value]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 100
  },
  {
    field: COLUMN_IDS.PHONE,
    headerName: i18next.t("expertTablePage:phoneHeader"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["equals"],
      maxNumConditions: 1,
      buttons: ["reset"]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    minWidth: 140,
    maxWidth: 400
  },
  {
    field: COLUMN_IDS.EMAIL,
    headerName: i18next.t("expertTablePage:emailHeader"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
      maxNumConditions: 1,
      buttons: ["reset"]
    },
    minWidth: 256,
    ...floatingFilter,
    suppressFloatingFilterButton: false
  },
  {
    field: COLUMN_IDS.LAST_ACTIVE_DATE,
    headerName: i18next.t("expertTablePage:lastActiveDate"),
    suppressHeaderMenuButton: true,
    cellStyle: ({ value }) => (value ? null : { fontStyle: "italic" }),
    valueFormatter: ({ value }) =>
      value == null
        ? i18next.t("expertTablePage:noActiveDate")
        : formatDate(value, APP_DATE_FORMAT),
    ...fixedWidth(128)
  },
  {
    field: COLUMN_IDS.DNC,
    headerName: i18next.t("expertTablePage:dncHeader"),
    sortable: false,
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      buttons: ["reset"],
      values: [
        DO_NOT_CONTACT_STATUS.CAUTION,
        DO_NOT_CONTACT_STATUS.OK,
        DO_NOT_CONTACT_STATUS.DNC
      ]
    },
    ...floatingFilter,
    suppressFloatingFilterButton: false,
    cellClass: "cursor-pointer",
    cellRenderer: DNCStatusTag,
    ...fixedWidth(120)
  }
];

export const DEFAULT_COLUMN_DEFINITION: ColDef = {
  suppressMovable: true,
  menuTabs: ["filterMenuTab"],
  minWidth: 70
};
