import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  ArbolusGPTButton,
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  Question,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  CANOPY_MOBILE_MENU_ROUTE,
  CANOPY_QUESTIONS_ROUTE,
  CANOPY_SINGLE_QUESTION_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyV2Selector,
  CanopyV2StoreActions
} from "@arbolus-technologies/stores/canopy-V2";
import { Header } from "@arbolus-technologies/ui/canopy-panels";
import { InfoBox, Loader } from "@arbolus-technologies/ui/components";

import { ExportAnswersButton } from "../../../Components/Buttons/ExportAnswersButton/ExportAnswersButton";

import styles from "./QuestionsList.module.scss";

export const QuestionsList: React.FC = () => {
  const { t } = useTranslation("canopyV2");
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackClick } = useArbolusTracking();

  const loggedUserMail = useSelector(CacheSelector.userProfile()).email;
  const canopyQuestions = useSelector(CanopyV2Selector.canopyQuestions());
  const canopyData = useSelector(CanopyV2Selector.canopyData());
  const isCanopyQuestionsLoading = useSelector(
    CanopyV2Selector.isCanopyQuestionsLoading()
  );
  const canopyExpertsCount = useSelector(CanopyV2Selector.canopyExpertsCount());

  useEffect(() => {
    if (canopyData) {
      dispatch(CanopyV2StoreActions.getCanopyQuestions(canopyData.id));
    }
  }, [canopyData, dispatch]);

  useEffect(() => {
    dispatch(CanopyV2StoreActions.resetCanopyQuestion());
  }, [dispatch]);

  const renderHeaderButton = (): JSX.Element | undefined => {
    if (canopyData) {
      return (
        <div className={styles.btnContainer}>
          <ArbolusGPTButton
            canopyPath={`${CANOPY_QUESTIONS_ROUTE(canopyData.id)} | ${
              canopyData.title
            }>`}
            onTrack={() =>
              trackClick(MixPanelEventNames.CanopyQuestionsArbolusGPT, {
                loggedUserMail
              })
            }
            projectId={canopyData.projectId}
          />
          <ExportAnswersButton
            canopyTitle={canopyData.title}
            canopyId={canopyData.id}
            isQuestionsPage
          />
        </div>
      );
    }
  };

  return (
    <PageTracker page={MixpanelPages.CanopyQuestions}>
      <Header
        title={t("questions")}
        backButton={
          isMobile && canopyData
            ? {
                onClick: () =>
                  history.push(CANOPY_MOBILE_MENU_ROUTE(canopyData.id)),
                title: t("back")
              }
            : undefined
        }
        actionButton={renderHeaderButton()}
      />
      <div className={styles.container}>
        {canopyExpertsCount?.complete === 0 && (
          <InfoBox
            title={t("noResponse")}
            description={t("noResponseDesc")}
            infoBoxType="warning"
            iconName="schedule"
          />
        )}
        {isCanopyQuestionsLoading ? (
          <Loader />
        ) : (
          canopyQuestions.map((question) => (
            <Question
              key={question.id}
              question={question}
              toAnswer={CANOPY_SINGLE_QUESTION_ROUTE(
                canopyData?.id ?? "",
                question.id
              )}
            />
          ))
        )}
      </div>
    </PageTracker>
  );
};
