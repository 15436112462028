import * as moment from "moment";

import { EventModel } from "../Events";
import { PENDING_GATEKEEPING_ENUM } from "./Referral";
import { Slot } from "./Slot";
import { BOOKED_CALL_STATUS } from "./enums";

export class CallEvent {
  public readonly id: string;
  public readonly title: string;
  public readonly startTime: Date;
  public readonly endTime: Date;
  public readonly timezone: string;
  public readonly slot: Slot;
  public readonly pendingGateKeepingTypes: PENDING_GATEKEEPING_ENUM[];

  constructor(
    data: EventModel,
    pendingGateKeepingTypes?: PENDING_GATEKEEPING_ENUM[]
  ) {
    this.id = data.id;
    this.title = data.title;
    this.startTime = new Date(data.startTime);
    this.endTime = new Date(data.endTime);
    this.timezone = data.timezone;
    this.slot = Slot.fromObject({
      startTime: data.startTime,
      endTime: data.endTime
    });
    this.pendingGateKeepingTypes = pendingGateKeepingTypes ?? [];
  }

  public get isInFuture(): boolean {
    const now = moment.utc();
    const eventStart = moment.utc(this.startTime);

    return eventStart.isAfter(now);
  }

  public get callStatus(): BOOKED_CALL_STATUS {
    if (this.isInFuture) {
      if (this.pendingGateKeepingTypes.length > 0) {
        return BOOKED_CALL_STATUS.ATTENTION_REQUIRED;
      }
      return BOOKED_CALL_STATUS.UPCOMING_CALLS;
    }
    return BOOKED_CALL_STATUS.CALLS_DONE;
  }
}
