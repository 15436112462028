import { OutputSelector, createSelector } from "reselect";

import { PaginatedResponseWithStatus } from "@arbolus-technologies/api";
import {
  CustomerSubFilters,
  DiscoverExpert,
  ExpertDiscoverFilters,
  ExpertDiscoverFiltersQuery,
  FilterTimeSelectorOptions,
  KEYWORDS_SEARCH_OPTIONS
} from "@arbolus-technologies/models/project";

import {
  ProjectNxAppState,
  ProjectNxReducerState
} from "../models/definitions";
import { ProjectStateSelector } from "./ProjectStateSelector";

export function discoverFilters(): OutputSelector<
  ProjectNxAppState,
  ExpertDiscoverFilters,
  (state: ProjectNxReducerState) => ExpertDiscoverFilters
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.filters
  );
}

export function discoverFiltersPreSelections(): OutputSelector<
  ProjectNxAppState,
  ExpertDiscoverFilters,
  (state: ProjectNxReducerState) => ExpertDiscoverFilters
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.filtersPreSelection
  );
}

export function companyFilterPeriod(): OutputSelector<
  ProjectNxAppState,
  FilterTimeSelectorOptions,
  (state: ProjectNxReducerState) => FilterTimeSelectorOptions
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.companyFilterPeriod
  );
}

export function companyInPastMonths(): OutputSelector<
  ProjectNxAppState,
  number | undefined,
  (state: ProjectNxReducerState) => number | undefined
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.companyInPastMonths
  );
}

export function workHistoryLocationFilterPeriod(): OutputSelector<
  ProjectNxAppState,
  FilterTimeSelectorOptions,
  (state: ProjectNxReducerState) => FilterTimeSelectorOptions
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.workHistoryLocationFilterPeriod
  );
}

export function industryFilterPeriod(): OutputSelector<
  ProjectNxAppState,
  FilterTimeSelectorOptions,
  (state: ProjectNxReducerState) => FilterTimeSelectorOptions
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.industryFilterPeriod
  );
}

export function keywordsSearchOptions(): OutputSelector<
  ProjectNxAppState,
  string[],
  (state: ProjectNxReducerState) => string[]
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.keywordsFields
  );
}

export function keywordsList(): OutputSelector<
  ProjectNxAppState,
  string[],
  (state: ProjectNxReducerState) => string[]
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) => {
      const regex = /"([^"]+)"|\b(\w+)\b/g;
      const keywordsList: string[] = [];
      let match: RegExpExecArray | null = null;

      const selectedOptions =
        projectReducerState.expertDiscover.filters["keywords"];
      const inputString = selectedOptions[0]?.value ?? "";
      const operators = new Set(["AND", "OR", "NOT"]);

      while ((match = regex.exec(inputString)) !== null) {
        if (match[1]) {
          keywordsList.push(match[1]);
        } else if (match[2] && !operators.has(match[2].toUpperCase())) {
          keywordsList.push(match[2]);
        }
      }

      return keywordsList;
    }
  );
}

export function isKeywordSearchOptionSelected(
  searchOption: KEYWORDS_SEARCH_OPTIONS
): OutputSelector<
  ProjectNxAppState,
  boolean,
  (state: ProjectNxReducerState) => boolean
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.keywordsFields.includes(searchOption)
  );
}

export function filtersQuery(): OutputSelector<
  ProjectNxAppState,
  ExpertDiscoverFiltersQuery,
  (state: ProjectNxReducerState) => ExpertDiscoverFiltersQuery
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.filtersQuery
  );
}

export function getDiscoverExpertSearchTerm(): OutputSelector<
  ProjectNxAppState,
  string,
  (state: ProjectNxReducerState) => string
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.searchTerm
  );
}

export function getDiscoverExpertList(): OutputSelector<
  ProjectNxAppState,
  PaginatedResponseWithStatus<DiscoverExpert> | null,
  (
    state: ProjectNxReducerState
  ) => PaginatedResponseWithStatus<DiscoverExpert> | null
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.expertsList ?? null
  );
}

export function getDiscoverExpertCurrentPage(): OutputSelector<
  ProjectNxAppState,
  number,
  (state: ProjectNxReducerState) => number
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.currentPage
  );
}

export function getDiscoverExpertPageSize(): OutputSelector<
  ProjectNxAppState,
  number,
  (state: ProjectNxReducerState) => number
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.pageSize
  );
}

export function getIsDiscoverExpertLoading(): OutputSelector<
  ProjectNxAppState,
  boolean,
  (state: ProjectNxReducerState) => boolean
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.isLoading
  );
}

export function hideDncExperts(): OutputSelector<
  ProjectNxAppState,
  boolean,
  (state: ProjectNxReducerState) => boolean
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.hideDncExperts
  );
}

export function getCustomerSubFilters(): OutputSelector<
  ProjectNxAppState,
  CustomerSubFilters,
  (state: ProjectNxReducerState) => CustomerSubFilters
> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.expertDiscover.customerSubFilters
  );
}
