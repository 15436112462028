import { useDispatch, useSelector } from "react-redux";

import { ExpertSurveyPage } from "@arbolus-technologies/features/customers";
import { ExpertInactive } from "@arbolus-technologies/features/expert-profile";

import { AuthSelector, LoginStoreActions } from "../../auth/store";
import { withAuthAccess } from "../components";

const ProtectedSurveyPage: React.FC = () => {
  const dispatch = useDispatch();
  const logout = () => dispatch(LoginStoreActions.logoutUser());
  const authUser = useSelector(AuthSelector.authUserSelector());
  const currentUserIsInactiveExpert = useSelector(
    AuthSelector.currentUserIsInactiveExpert()
  );

  // We're very specific null, undefined or empty string is not the same as true/false
  if (currentUserIsInactiveExpert) {
    return <ExpertInactive expertId={authUser.expertId!} logout={logout} />;
  }
  return <ExpertSurveyPage />;
};

export const ExpertSurveyWithAuth = withAuthAccess(ProtectedSurveyPage);
