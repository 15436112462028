import { Alert, Button, Checkbox, CheckboxProps, Flex, Space } from "antd";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { forkJoin } from "rxjs";

import {
  AdminService,
  CIQError,
  Compliance as ComplianceType,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  PROJECT_REFERRAL_STATE,
  ProjectAnswer,
  ProjectService,
  ReferralDetail,
  ToasterService,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import { REFERRAL_REJECT_REASON } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { DncInfoBox, Loader } from "@arbolus-technologies/ui/components";

import {
  DefaultRejectFeedbackExpert,
  RejectFeedbackExpert
} from "../../../../Components/RejectFeedback/RejectFeedbackExpert";
import ProjectExpertEvents from "../../constants/ProjectExpertEvents";
import {
  REFERRAL_EXPERT_TABS,
  REFERRAL_EXPERT_TABS_TYPE,
  getReferralProfileTabs
} from "../../utils";
import { Compliance } from "../Compliance/Compliance";
import { ExpertInfo } from "../ExpertInfo/ExpertInfo";
import { Profile } from "../Profile/Profile";
import { Scheduling } from "../Scheduling/Scheduling";
import { Screening } from "../Screening/Screening";

import styles from "./ReferralsTableExpertProfile.module.scss";

interface ReferralsTableExpertProfileProps {
  expertId: string;
  projectId: string;
  referralId: string;
  onReferralRemoval: (referralId: string) => void;
  expertService?: typeof ExpertService;
  projectService?: typeof ProjectService;
  adminService?: typeof AdminService;
  feedbackExpert?: RejectFeedbackExpert;
  notificationService?: ToasterService;
}

export const ReferralsTableExpertProfile: React.FC<
  ReferralsTableExpertProfileProps
> = ({
  expertId,
  projectId,
  referralId,
  onReferralRemoval,
  expertService = ExpertService,
  projectService = ProjectService,
  adminService = AdminService,
  feedbackExpert = DefaultRejectFeedbackExpert,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralsTableExpertProfile");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [expertInfo, setExpertInfo] = useState<ExpertDetail | null>(null);
  const [referral, setReferral] = useState<ReferralDetail | null>(null);
  const [screeningInfo, setScreeningInfo] = useState<ProjectAnswer[]>([]);
  const [complianceAnswers, setComplianceAnswers] = useState<ComplianceType[]>(
    []
  );
  const [isLinkedinVerified, setIsLinkedinVerified] = useState(false);
  const [addNewNote, setAddNewNote] = useState(false);
  const [activeTab, setActiveTab] = useState<REFERRAL_EXPERT_TABS_TYPE>(
    REFERRAL_EXPERT_TABS.PROFILE
  );

  const clientId = useSelector(ProjectNxSelector.projectClientId());
  const currentUser = useSelector(CacheSelector.userAdminProfile());
  const hasCompliance = useSelector(
    ProjectNxSelector.projectHasComplianceSelector()
  );

  useEffect(() => {
    const promises = [
      expertService.getExpertById({
        expertId,
        clientId,
        projectId,
        includeRateCard: true
      }),
      projectService.getReferral(projectId, referralId),
      expertService.getAnswers(expertId, projectId),
      expertService.getComplianceAnswers(expertId, projectId)
    ];

    setIsLoading(true);
    forkJoin(promises).subscribe(
      ([expert, referral, screeningInfo, complianceAnswers]) => {
        setExpertInfo(expert as ExpertDetail);
        setReferral(referral as ReferralDetail);
        setScreeningInfo((screeningInfo as { items: ProjectAnswer[] }).items);
        setComplianceAnswers(
          (complianceAnswers as { items: ComplianceType[] }).items
        );
        setIsLoading(false);
      },
      (err: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(err);
        setIsLoading(false);
      }
    );
  }, []);

  const handleCloseSidePanel = () => {
    dispatch(PanelStoreActions.closePanel(PanelId.ReferralExpertPanel));
  };

  const handleLinkedinVerification: CheckboxProps["onChange"] = (e) => {
    setIsLinkedinVerified(e.target.checked);

    adminService.updateExpertIsVerified(expertId, e.target.checked).subscribe(
      () => {
        notificationService.showSuccess(t("linkedinStatusUpdated"));
      },
      (err: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(err);
      }
    );
  };

  const updateReferralState = (
    state: UpdateReferralRequest,
    isFastTrack?: boolean
  ): void => {
    projectService.updateReferralState(projectId, referralId, state).subscribe(
      (response) => {
        if (isFastTrack) {
          ProjectExpertEvents.fastTrackReferral(referralId, response);
        } else {
          ProjectExpertEvents.updateReferral(referralId, response);
        }
        notificationService.showSuccess(t("updateSuccess"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    );
  };

  if (isLoading) {
    return <Loader isFull />;
  }

  if (!referral || !expertInfo) {
    return null;
  }

  const handleRejectExpert = (): void => {
    handleCloseSidePanel();

    feedbackExpert.setData({
      projectId,
      referralId,
      expert: {
        ...expertInfo.user,
        id: "",
        expertId,
        doNotContactStatus: expertInfo.doNotContactStatus
      }
    });
    dispatch(PanelStoreActions.openPanel(PanelId.RejectFeedback));
  };

  const handleSaveNote = (newNote?: string): void => {
    setReferral({
      ...referral,
      note: {
        ...referral.note,
        // @ts-ignore
        note: newNote,
        noteUser: currentUser,
        noteDate: new Date().toISOString()
      }
    });
    setAddNewNote(false);
  };

  const handleUpdateScreeningAnswers = (answers: ProjectAnswer[]): void => {
    setScreeningInfo(answers);
  };

  const handleUpdateComplianceAnswers = (answers: ComplianceType[]): void => {
    setComplianceAnswers(answers);
  };

  const renderTabsPage = {
    [REFERRAL_EXPERT_TABS.PROFILE]: (
      <Profile
        expert={expertInfo}
        note={referral.note}
        addNewNote={addNewNote}
        referralId={referral.id}
        onSaveNote={handleSaveNote}
      />
    ),
    [REFERRAL_EXPERT_TABS.SCREENING]: (
      <Screening
        screeningInfo={screeningInfo}
        expertId={expertId}
        referralStatus={referral.status}
        doNotContactStatus={expertInfo.doNotContactStatus}
        onUpdateScreeningAnswers={handleUpdateScreeningAnswers}
      />
    ),
    [REFERRAL_EXPERT_TABS.COMPLIANCE]: (
      <Compliance
        complianceInfo={complianceAnswers}
        applicationStatus={referral.application.subStatus}
        complianceStatus={referral.compliance.subStatus}
        chaperoneCall={referral.chaperoneCall}
        complianceNote={referral.complianceNote}
        complianceUpdatedBy={referral.compliance.updateUser}
        complianceUpdateDate={referral.compliance.updateDate}
        doNotContactStatus={expertInfo.doNotContactStatus}
        expertId={expertId}
        onUpdateComplianceAnswers={handleUpdateComplianceAnswers}
      />
    ),
    [REFERRAL_EXPERT_TABS.SCHEDULING]: (
      <Scheduling referral={referral} expert={expertInfo} />
    )
  };

  let expertRejectDescription = "";
  if (referral.review.rejectDescription) {
    const rejectDescription = referral.review.rejectDescription;

    const reason = Object.values(REFERRAL_REJECT_REASON).find(
      (reason) => reason === rejectDescription.reason
    );

    const rejectReason = i18next.t(`rejectExpert:${reason}`);
    expertRejectDescription = `${rejectReason}: ${rejectDescription.description}`;
  }

  const REFERRAL_EXPERT_TABS_ACTIVE = getReferralProfileTabs(
    referral.application.subStatus === REFERRAL_SUB_STATE.REJECT,
    screeningInfo.length > 0,
    hasCompliance
  );

  const handleMoveToAvailabilityTab = () => {
    setActiveTab(REFERRAL_EXPERT_TABS.SCHEDULING);
  };

  return (
    <Flex vertical gap={24} className={styles.container}>
      <ExpertInfo
        expert={expertInfo}
        referral={referral}
        onReferralRemoval={onReferralRemoval}
        onAddNote={() => {
          setAddNewNote(true);
        }}
        onRejectExpert={handleRejectExpert}
        updateReferralState={updateReferralState}
        onMoveToAvailabilityTab={handleMoveToAvailabilityTab}
      />
      <DncInfoBox
        doNotContactStatus={expertInfo.doNotContactStatus}
        doNotContactStatusDescription={expertInfo.doNotContactStatusDescription}
        doNotContactStatusCategory={expertInfo.doNotContactStatusCategory}
        isAdmin
      />
      {referral.status === PROJECT_REFERRAL_STATE.REJECT && (
        <Alert
          message={t("expertRejected")}
          description={expertRejectDescription}
          type="error"
          showIcon
        />
      )}
      <Flex justify="space-between" align="center">
        <Space.Compact>
          {REFERRAL_EXPERT_TABS_ACTIVE.map((tabKey) => (
            <Button
              key={tabKey}
              type={tabKey === activeTab ? "primary" : "default"}
              onClick={() => setActiveTab(tabKey)}
            >
              {t(tabKey)}
            </Button>
          ))}
        </Space.Compact>
        <Checkbox
          onChange={handleLinkedinVerification}
          checked={isLinkedinVerified}
        >
          {t("linkedinVerified")}
        </Checkbox>
      </Flex>
      {renderTabsPage[activeTab]}
    </Flex>
  );
};
