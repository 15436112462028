import { Button, Flex, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  ARBOLUS_ABOUT_US,
  ARBOLUS_TRUSTPILOT
} from "@arbolus-technologies/features/common";
import { LinkWrapper } from "@arbolus-technologies/ui/components";

import styles from "./SurveyInformation.module.scss";

const { Text, Title } = Typography;

export const SurveyInformation: React.FC = () => {
  const { t } = useTranslation("surveyInformation");

  useEffect(() => {
    //Trustpilot widget script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Flex gap={48} className={styles.informationSectionContainer}>
      <Flex vertical gap={16} className={styles.informationSection}>
        <Title level={4}>{t("whoIsArbolusTitle")}</Title>
        <Text>{t("whoIsArbolusDescription")}</Text>
        <LinkWrapper to={ARBOLUS_ABOUT_US} isExternal target="_blank">
          <Button type="default" size="large">
            {t("learnMore")}
          </Button>
        </LinkWrapper>
      </Flex>
      <Flex vertical gap={16} className={styles.informationSection}>
        <Title level={4}>{t("howItWorksTitle")}</Title>
        <Flex gap={8} align="center">
          <div className={styles.listNumber}>1</div>
          <Text>{t("howItWorksText1")}</Text>
        </Flex>
        <Flex gap={8} align="center">
          <div className={styles.listNumber}>2</div>
          <Text>{t("howItWorksText2")}</Text>
        </Flex>
        <Flex gap={8} align="center">
          <div className={styles.listNumber}>3</div>
          <Text>{t("howItWorksText3")}</Text>
        </Flex>
      </Flex>
      <Flex vertical gap={16} className={styles.informationSection}>
        <Title style={{ paddingLeft: "24px" }} level={4}>
          {t("ratedExcellentTitle")}
        </Title>
        <LinkWrapper to={ARBOLUS_TRUSTPILOT} isExternal target="_blank">
          <div
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="61521e27b1baf4001d934a71"
            data-style-height="150px"
            data-style-width="100%"
          >
            <a
              href="https://www.trustpilot.com/review/arbolus.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Trustpilot
            </a>
          </div>
        </LinkWrapper>
      </Flex>
    </Flex>
  );
};
