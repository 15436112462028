import { Button, Flex } from "antd";
import clsx from "clsx";
import { Field, Form, Formik, FormikProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { FormGroup, InputGroup, Label } from "reactstrap";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";

import { CIQError, ErrorResponse } from "../../../../../models/api";
import { UserService } from "../../../../../services";
import { CIQPasswordGuide } from "../../../../app/components";
import CustomPasswordInput from "../../../../app/components/CustomPasswordInput";
import { PasswordValidationSchema } from "./PasswordFormSchema";

const notification = new ToasterService();

interface EditUserPasswordState {
  isLoading: boolean;
}

interface EditPasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

type EditUserPasswordIntersectProps = WithTranslation;

class EditUserPassword extends React.Component<
  EditUserPasswordIntersectProps,
  EditUserPasswordState
> {
  constructor(props: EditUserPasswordIntersectProps) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentWillUnmount(): void {
    this.updateUserPasswordSubscription?.unsubscribe();
  }

  private formRef: FormikProps<EditPasswordFormValues> | null = null;

  private updateUserPasswordSubscription?: Subscription;

  updateUserPassword = (values: EditPasswordFormValues): void => {
    const { t } = this.props;

    this.setState({
      isLoading: true
    });

    const { currentPassword, newPassword, confirmNewPassword } = values;
    this.updateUserPasswordSubscription = UserService.changeUserPassword({
      currentPassword,
      newPassword,
      confirmNewPassword
    }).subscribe(
      () => {
        notification.showSuccess(t("passwordUpdateSuccess"));
        this.formRef?.resetForm();

        this.setState({
          isLoading: false
        });
      },
      (error: ErrorResponse<CIQError>) => {
        this.setState(
          {
            isLoading: false
          },
          () => notification.showError(error.message)
        );
      }
    );
  };

  renderPasswordForm = ({
    values,
    errors,
    touched,
    dirty,
    isValid
  }: FormikProps<EditPasswordFormValues>): JSX.Element => {
    const { t } = this.props;
    const { isLoading } = this.state;

    const passwordSaveButtonDisabled = !(isValid && dirty) || isLoading;

    return (
      <Form style={{ maxWidth: "720px", margin: "0 auto" }}>
        <FormGroup
          className={clsx({
            "is-invalid": errors.currentPassword && touched.currentPassword
          })}
        >
          <Label className="space-between">{t("currentPassword")}</Label>
          <InputGroup className="append">
            <Field
              placeholder={t("currentPassword")}
              name="currentPassword"
              type="password"
              component={CustomPasswordInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup
          className={clsx({
            "is-invalid": errors.newPassword && touched.newPassword
          })}
        >
          <Label className="space-between">{t("newPassword")}</Label>
          <InputGroup className="append">
            <Field
              placeholder={t("newPassword")}
              name="newPassword"
              type="password"
              component={CustomPasswordInput}
            />
          </InputGroup>
          <CIQPasswordGuide password={values.newPassword} />
        </FormGroup>
        <FormGroup
          className={clsx({
            "is-invalid":
              errors.confirmNewPassword && touched.confirmNewPassword
          })}
        >
          <Label className="space-between">{t("confirmPassword")}</Label>
          <InputGroup className="append">
            <Field
              placeholder={t("confirmPassword")}
              name="confirmNewPassword"
              type="password"
              component={CustomPasswordInput}
            />
          </InputGroup>
        </FormGroup>
        <Flex justify="flex-end">
          <Button
            htmlType="submit"
            type="primary"
            disabled={passwordSaveButtonDisabled}
            size="large"
          >
            {t("changePassword")}
          </Button>
        </Flex>
      </Form>
    );
  };

  render(): JSX.Element {
    return (
      <>
        <Formik<EditPasswordFormValues>
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: ""
          }}
          validationSchema={PasswordValidationSchema}
          enableReinitialize
          validateOnChange
          validateOnBlur
          innerRef={(instance): void => {
            this.formRef = instance;
          }}
          onSubmit={this.updateUserPassword}
        >
          {this.renderPasswordForm}
        </Formik>
      </>
    );
  }
}

export default withTranslation("userProfilePage")(EditUserPassword);
